/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from './components/individual/onboarding/login/Login';
import SignupPage from './components/individual/onboarding/signup/Signup';
import SetUsernamePage from './components/individual/onboarding/stepper/setUsername/SetUsername';
import OtpPage from './components/individual/onboarding/stepper/otp/Otp';
import LoadingPage from './components/individual/onboarding/loading/Loading';
import StepperPage from './components/individual/onboarding/stepper/Stepper';
import DashBoard from './components/individual/dashboard/DashBoard';
import SelectItemPage from './components/individual/sendThanks/selectItem/SelectItem';
import SelectProductPage from './components/individual/sendThanks/selectItem/SelectProduct';
import RedeemCommon from './components/individual/redeemThanks/RedeemThanks/RedeemCommon';
import ContactCommon from './components/individual/contactThanks/ContactCommon';

import ForgotPasswordPage from './components/individual/onboarding/forgotPassword/ForgotPassword';
import ResetPasswordPage from './components/individual/onboarding/resetPassword/ResetPassword';
import RecipentInformationPage from './components/individual/sendThanks/recipientInformation/RecipientInformation';
import ThanksSuccessPage from './components/individual/sendThanks/thanksSuccess/ThanksSuccess';
import DonatePage from './components/individual/redeemThanks/Donate/Donate';
import RedeemPage from './components/individual/redeemThanks/Redeem/Redeem';
import ProfilePage from './components/individual/account/profile/Profile';
import MerchantPlanPage from './components/merchant/plans/Plans';
import MerchantOnboardingPage from './components/merchant/onboarding/Onboarding';
import MerchantBusinessDetailsPage from './components/merchant/onboarding/businessDetails/BusinessDetails';
import MerchantPaymentPage from './components/merchant/onboarding/payment/Payment';
import MerchantPersonalDetailsPage from './components/merchant/onboarding/personalDetails/PersonalDetails';
import ProtectedRoute from './ProtectedRoute';
import RedeemWine from './components/individual/redeemThanks/redeemwine/RedeemWine';
import MerchantDashboardPage from './components/merchant/dashboard/Dashboard';
import PayItForward from './components/individual/payitforward/PayItForward';
import MerchantStoreLocationsPage from './components/merchant/store/locations/Locations';
import MerchantStoreEmployeesPage from './components/merchant/store/employees/Employees';
import MerchantTransactionsPage from './components/merchant/transactions/transactions/Transactions';
import MerchantProfileBusinessDetailsPage from './components/merchant/profile/businessDetails/BusinessDetails';
import MerchantProfilePage from './components/merchant/profile/Profile';
import RedeemNow from './components/individual/redeemThanks/redeemwine/redeemnow/RedeemNow';
import RedeemedWine from './components/individual/redeemThanks/redeemwine/redeemed/RedeemedWine';
import SuspendCoffee from './components/individual/payitforward/suspendcoffee/SuspendCoffee';
import MerchantPayoutPage from './components/merchant/payout/payout/Payout';
import ForwardThanks from './components/individual/payitforward/forwardthanks/forwardthanks';
import ForwardThanksSuccess from './components/individual/payitforward/forwardthanks/forwardthankssuccess';
import TransactionHistory from './components/individual/dashboard/viewall/TransactionHisotry';
import OtpAuth from './components/individual/onboarding/OtpAuth/OtpAuth';
import AppreciLocation from './components/individual/mapview/MapView';
import MerchantPayoutDetailsPage from './components/merchant/payout/details/Details';
import MerchantTransactionDetailPage from './components/merchant/transactions/details/Details';
import SelectProduct from './components/individual/redeemThanks/RedeemBeer/SelectProduct';
import RedeemDetails from './components/individual/redeemThanks/RedeemBeer/redeemDetails/RedeemDetails';
import RedeemedBeer from './components/individual/redeemThanks/RedeemBeer/redeemedbeer/RedeemedBeer';
import PublicProfilePage from './components/individual/onboarding/publicProfile/publicProfile';
import Impersonate from './components/individual/account/accountimporsonate/accountimporsonate';
import OrganisationDetailsPage from './components/organisation/onboarding/details/Details';
import OrganisationInviteEmployeesPage from './components/organisation/onboarding/inviteEmployees/InviteEmployees';
import OrganisationPlanPage from './components/organisation/onboarding/plans/Plans';
import OrganisationDashboard from './components/organisation/organisationDashboard/OrganisationDashboard';
import TopUp from './components/organisation/organisationTopup/OrganisationTopup';
import EmployeeDashboard from './components/organisation/employee/organisationemployeedashboard/Employeedashboard';
import OrganisationPayment from './components/organisation/organisationTopup/organisationPayment/OrganisationPayment';
import OrganisationReviewScreen from './components/organisation/organisationTopup/organisationReview/OrganisationReviewScreen';
import TopUpSuccess from './components/organisation/organisationTopup/organisationSuccess/OrganisarionSuccessScreen';
import OrganisationUsersPage from './components/organisation/users/Users';
import OrganisationMilestonesPage from './components/organisation/milestones/Milestones';
import OrganisationCreateMilestonesPage from './components/organisation/milestones/CreateMilestone';
import OrganisationRecipentInformationPage from './components/organisation/sendThanks/recipientInformation/RecipientInformation';
import OrganisationSendThanksPaymentOptionsPage from './components/organisation/sendThanks/organisationSendThanksPayment/OrganisationSendThanksPayment';
import OrganisationSendThanksReviewThanksPage from './components/organisation/sendThanks/organisationSendThanksReview/OrganisationSendThanksReviewScreen';
import OrganisationThanksSuccessPage from './components/organisation/sendThanks/thanksSuccess/ThanksSuccess';
import EmployeeTransactionHistory from './components/organisation/employee/employeetransactionhistory/EmployeeTransactionhistory';
import AdminTransactionHistory from './components/organisation/organisationtransactionhistory/AdminTransactionHistory';
import OrganisationRedeemCreditSelectItemPage from './components/organisation/employee/credits/redeem/allocateSelectItem/AllocateSelectItem';
import OrganisationRedeemCreditThanksSuccessPage from './components/organisation/employee/credits/redeem/allocateThanksSuccess/AllocateThanksSuccess';
import OrganisationSendThanksRedeemReviewThanksPage from './components/organisation/employee/credits/redeem/organisationRedeemReview/OrganisationRedeemReview';
import OrganisationAllocateRecipentInformationPage from './components/organisation/employee/credits/allocate/allocateRecipientInformation/AllocateRecipientInformation';
import OrganisationAllocateSuccessPage from './components/organisation/employee/credits/allocate/allocateThanksSuccess/AllocateThanksSuccess';
import OrganisationAllocateReviewThanksPage from './components/organisation/employee/credits/allocate/organisationAllocateReview/OrganisationAllocateReview';
import OrganisationRedeemCoffee from './components/organisation/organisationredeem/organisationredeemcoffee/OrganisationRedeemCoffee';
import OrganisationRedeemWine from './components/organisation/organisationredeem/organisationredeemwine/OrganisationRedeemWine';
import OrganisationRedeemNow from './components/organisation/organisationredeem/organisationredeemwine/OrganisationRedeemNow';
import OrganisationRedeemedWine from './components/organisation/organisationredeem/organisationredeemwine/OrganisationRedeemdWine';
import OrganisationSelectProduct from './components/organisation/organisationredeem/organisationredeembeer/OrganisationSelectItem';
import OrganisationRedeemDetails from './components/organisation/organisationredeem/organisationredeembeer/OrganisationRedeemDetails';
import OrganisationBeerRedeemd from './components/organisation/organisationredeem/organisationredeembeer/OrganisationRedeemedBeer';
import OrganisationPayItForward from './components/organisation/organisationpayitforward/OrganisationPayitForward';
import OrganisationForwardThanks from './components/organisation/organisationpayitforward/OrganisationForwardThanks';
import OrganisationForwardThanksSuccess from './components/organisation/organisationpayitforward/OrganisationPayitForwardSuccess';
import OrganisationRedeemThanks from './components/organisation/organisationredeem/organisationredeem/OrganisationRedeem';
import organisationEmployeeRecipientInformation from './components/organisation/employee/sendThanks/organisationEmployeeRecipientInformation/organisationEmployeeRecipientInformation';
import organisationEmployeeSendThanksPayment from './components/organisation/employee/sendThanks/organisationEmployeeSendThanksPayment/organisationEmployeeSendThanksPayment';
import organisationEmployeeSendThanksReview from './components/organisation/employee/sendThanks/organisationEmployeeSendThanksReview/organisationEmployeeSendThanksReview';
import organisationEmployeeThanksSuccess from './components/organisation/employee/sendThanks/organisationEmployeeThanksSuccess/organisationEmployeeThanksSuccess';
import OrganisationAdminAllocateReview from './components/organisation/admin/allocate/allocateAdminReview/allocateAdminReview';
import OrganisationAdminAllocateRecipentInformationPage from './components/organisation/admin/allocate/allocateAdminRecipientInformation/AllocateAdminRecipientInformation';
import OrganisationAdminAllocateSuccessPage from './components/organisation/admin/allocate/allocateAdminThanksSuccess/AllocateAdminThanksSuccess';
import OrganisationAdminProfilePage from './components/organisation/admin/profile/Profile';
import RedeemAnonymous from './components/anonymous/redeem/RedeemAnonymous';
import PersonalLayout from './common/component/layout/PersonalLayout';
import AnonymousLayout from './common/component/anonymouslayout/AnonymousLayout';
import OrganisationLayout from './common/component/layout/OrganisationLayout';
import EmployeeLayout from './common/component/layout/EmployeeLayout';
import MarketingPage from './components/organisation/marketing/MarketingPage';
import Payment from './components/individual/sendThanks/paymentOptions/Payment';
import ReviewPurchase from './components/individual/sendThanks/reviewThanks/ReviewPurchase';
import NewRedeem from './components/anonymous/redeem/newredeem/newRedeem';
import SelectAnonymousItemPage from './components/anonymous/send/selectGiftType/SelectGiftItem';
import AnonRecipientInformation from './components/anonymous/send/anonrecipientInformation/AnonRecipientInformation';
import AnonPayment from './components/anonymous/send/anonpayment/AnonPayment';
import AnonThanksSuccess from './components/anonymous/send/anonthankssucccess/AnonThanksSuccess';
import ReactGA from 'react-ga';
import PreviewGift from './components/organisation/milestones/PreviewGift';

const history = createBrowserHistory();
ReactGA.initialize('UA-157363852-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  // check user is authenticated
  const checkUserIsAuthenticatedOrNot = () => {
    let data = JSON.parse(localStorage.getItem('userData'));
    if (data && data?.status === 'Activated') {
      return <Redirect from="/" to="/dashboard" />;
    } else {
      return <Redirect from="/" to="/login" />;
    }
  };

  return (
    <React.Fragment>
      <Router history={history}>
        <div className="p-0 m-0 w-100">
          <Switch>
            <Route exact path="/">
              {checkUserIsAuthenticatedOrNot}
            </Route>
            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/otp-auth" component={OtpAuth} />
            <Route path="/redeemthanks" component={RedeemCommon} />
            <Route path="/contactthanks" component={ContactCommon} />
            <Route path="/users/forgot-password" component={ForgotPasswordPage} />
            <Route path="/users/resetPassword" component={ResetPasswordPage} />
            <Route path="/public-profile/:username" component={PublicProfilePage} />
            <Route path="/impersonate" component={Impersonate} />
            <Route path="/redeem-anonymous" component={RedeemAnonymous} />
            <Route path="/newredeem" component={NewRedeem} />
            <Route path="/anonymousselectitem" component={SelectAnonymousItemPage} />
            <Route path="/anonrecipientInformation" component={AnonRecipientInformation} />
            <Route path="/anonpayment" component={AnonPayment} />
            <Route path="/anonthankssuccess" component={AnonThanksSuccess} />

            <ProtectedRoute path="/set-username" component={SetUsernamePage} />
            <ProtectedRoute path="/otp" component={OtpPage} />
            <ProtectedRoute path="/loading" component={LoadingPage} />
            <ProtectedRoute path="/user-details" component={StepperPage} />
            <ProtectedRoute path="/dashboard" component={DashBoard} />
            <ProtectedRoute
              path="/selectProduct"
              component={SelectProductPage}
              layout={PersonalLayout}
              layoutProps={{ menu: 'Sendthanks' }}
            />
            <ProtectedRoute
              path="/selectItem"
              component={SelectItemPage}
              layout={PersonalLayout}
              layoutProps={{ menu: 'Sendthanks' }}
            />
            <ProtectedRoute path="/recipientInformation" component={RecipentInformationPage} />
            <ProtectedRoute
              path="/payment"
              component={Payment}
              layout={PersonalLayout}
              layoutProps={{ menu: 'Sendthanks' }}
            />
            <ProtectedRoute
              path="/reviewThanks"
              component={ReviewPurchase}
              layout={PersonalLayout}
              layoutProps={{ menu: 'Sendthanks' }}
            />
            <ProtectedRoute path="/thanksSuccess" component={ThanksSuccessPage} />
            <ProtectedRoute path="/profile" component={ProfilePage} />
            <ProtectedRoute path="/merchant/plans" component={MerchantPlanPage} />
            <ProtectedRoute path="/merchant/onboarding/merchantDetails" component={MerchantOnboardingPage} />
            <ProtectedRoute path="/merchant/onboarding/business" component={MerchantBusinessDetailsPage} />
            <ProtectedRoute path="/merchant/onboarding/payment" component={MerchantPaymentPage} />
            <ProtectedRoute path="/merchant/onboarding/personal" component={MerchantPersonalDetailsPage} />
            <ProtectedRoute path="/merchant/dashboard" component={MerchantDashboardPage} />
            <ProtectedRoute path="/merchant/locations" component={MerchantStoreLocationsPage} />
            <ProtectedRoute exact path="/merchant/payout" component={MerchantPayoutPage} />
            <ProtectedRoute path="/merchant/employees" component={MerchantStoreEmployeesPage} />
            <ProtectedRoute exact path="/merchant/transactions" component={MerchantTransactionsPage} />
            <ProtectedRoute path="/merchant/profile" component={MerchantProfilePage} />
            <ProtectedRoute path="/merchant/profile/business" component={MerchantProfileBusinessDetailsPage} />
            <ProtectedRoute exact path="/merchant/payout/:id/:date/:payoutId" component={MerchantPayoutDetailsPage} />
            <ProtectedRoute exact path="/merchant/transaction/:id" component={MerchantTransactionDetailPage} />
            <ProtectedRoute path="/donate" component={DonatePage} />
            <ProtectedRoute path="/redeem" component={RedeemPage} />
            <ProtectedRoute path="/redeemwine" component={RedeemWine} />
            <ProtectedRoute path="/previewgift" component={PreviewGift} />
            <ProtectedRoute path="/payitforward" component={PayItForward} />
            <ProtectedRoute path="/redeemnow" component={RedeemNow} />
            <ProtectedRoute path="/redeemedwine" component={RedeemedWine} />
            <ProtectedRoute path="/suspendcoffee" component={SuspendCoffee} />
            <ProtectedRoute path="/forwardthanks" component={ForwardThanks} />
            <ProtectedRoute path="/forwardsuccess" component={ForwardThanksSuccess} />
            <ProtectedRoute path="/transactionhistory" component={TransactionHistory} />
            {/*<ProtectedRoute path="/appreci-locations" component={AppreciLocation} />*/}
            <ProtectedRoute path="/select-product" component={SelectProduct} />
            <ProtectedRoute path="/redeem-details" component={RedeemDetails} />
            <ProtectedRoute path="/redeemedbeer" component={RedeemedBeer} />
            <ProtectedRoute path="/organisation/details" component={OrganisationDetailsPage} />
            <ProtectedRoute path="/organisation/invite" component={OrganisationInviteEmployeesPage} />
            <ProtectedRoute path="/organisation/plans" component={OrganisationPlanPage} />
            <ProtectedRoute path="/organisation/dashboard" component={OrganisationDashboard} />
            <ProtectedRoute path="/organisation/topup" component={TopUp} />
            <ProtectedRoute path="/employee/dashboard" component={EmployeeDashboard} />
            <ProtectedRoute path="/organisation/paymentOptions" component={OrganisationPayment} />
            <ProtectedRoute path="/organisation/review" component={OrganisationReviewScreen} />
            <ProtectedRoute path="/topup/success" component={TopUpSuccess} />
            <ProtectedRoute path="/organisation/users" component={OrganisationUsersPage} />
            <ProtectedRoute path="/organisation/milestones" component={OrganisationMilestonesPage} />
            <ProtectedRoute path="/organisation/createMilestone" component={OrganisationCreateMilestonesPage} />
            <ProtectedRoute
              path="/organisation/send/recipientInformation"
              component={OrganisationRecipentInformationPage}
            />
            <ProtectedRoute path="/organisation/redeemthanks" component={OrganisationRedeemThanks} />
            <ProtectedRoute
              path="/organisation/send/selectProduct"
              component={SelectProductPage}
              layout={OrganisationLayout}
              layoutProps={{ role: 'admin' }}
            />
            <ProtectedRoute
              path="/organisation/send/selectItem"
              component={SelectItemPage}
              layout={OrganisationLayout}
              layoutProps={{ role: 'admin' }}
            />
            <ProtectedRoute path="/organisation/send/payment" component={OrganisationSendThanksPaymentOptionsPage} />
            <ProtectedRoute
              path="/organisation/marketing"
              component={MarketingPage}
              layout={OrganisationLayout}
              layoutProps={{ role: 'admin' }}
            />
            <ProtectedRoute path="/organisation/send/reviewThanks" component={OrganisationSendThanksReviewThanksPage} />
            <ProtectedRoute path="/organisation/send/thanksSuccess" component={OrganisationThanksSuccessPage} />
            <ProtectedRoute path="/employee/transactionhistory" component={EmployeeTransactionHistory} />
            <ProtectedRoute path="/admin/transactionhistory" component={AdminTransactionHistory} />
            <ProtectedRoute path="/organisation/redeem/selectItem" component={OrganisationRedeemCreditSelectItemPage} />
            <ProtectedRoute
              path="/organisation/redeem/thanksSuccess"
              component={OrganisationRedeemCreditThanksSuccessPage}
            />
            <ProtectedRoute
              path="/organisation/redeem/review"
              component={OrganisationSendThanksRedeemReviewThanksPage}
            />
            <ProtectedRoute
              path="/organisation/allocate/recipientInformation"
              component={OrganisationAllocateRecipentInformationPage}
            />
            <ProtectedRoute path="/organisation/allocate/review" component={OrganisationAllocateReviewThanksPage} />
            <ProtectedRoute path="/organisation/allocate/success" component={OrganisationAllocateSuccessPage} />
            <ProtectedRoute
              path="/organisation/admin/allocate/recipientInformation"
              component={OrganisationAdminAllocateRecipentInformationPage}
            />
            <ProtectedRoute path="/organisation/admin/allocate/review" component={OrganisationAdminAllocateReview} />
            <ProtectedRoute
              path="/organisation/admin/allocate/success"
              component={OrganisationAdminAllocateSuccessPage}
            />
            <ProtectedRoute
              path="/organisation/redeem/thanksSuccess"
              component={OrganisationRedeemCreditThanksSuccessPage}
            />
            <ProtectedRoute path="/organisation/redeemcoffee" component={OrganisationRedeemCoffee} />
            <ProtectedRoute path="/organisation/redeemwine" component={OrganisationRedeemWine} />
            <ProtectedRoute path="/organisation/redeemnow" component={OrganisationRedeemNow} />
            <ProtectedRoute path="/organisation/redeemedwine" component={OrganisationRedeemedWine} />
            <ProtectedRoute path="/organisation/selectproduct" component={OrganisationSelectProduct} />
            <ProtectedRoute path="/organisation/redeem-details" component={OrganisationRedeemDetails} />
            <ProtectedRoute path="/organisation/redeemedbeer" component={OrganisationBeerRedeemd} />
            <ProtectedRoute path="/organisation/payitforward" component={OrganisationPayItForward} />
            <ProtectedRoute path="/organisation/forwardthanks" component={OrganisationForwardThanks} />
            <ProtectedRoute path="/organisation/forwardsuccess" component={OrganisationForwardThanksSuccess} />
            <ProtectedRoute
              path="/organisation/employee/recipientInformation"
              component={organisationEmployeeRecipientInformation}
            />
            <ProtectedRoute
              path="/organisation/employee/selectProduct"
              component={SelectProductPage}
              layout={EmployeeLayout}
              layoutProps={{ role: 'employee', menu: 'Sendthanks' }}
            />
            <ProtectedRoute
              path="/organisation/employee/selectItem"
              component={SelectItemPage}
              layout={EmployeeLayout}
              layoutProps={{ role: 'employee', menu: 'Sendthanks' }}
            />
            <ProtectedRoute path="/organisation/employee/payment" component={organisationEmployeeSendThanksPayment} />
            <ProtectedRoute path="/organisation/employee/review" component={organisationEmployeeSendThanksReview} />
            <ProtectedRoute path="/organisation/employee/thanksSuccess" component={organisationEmployeeThanksSuccess} />
            <ProtectedRoute path="/organisation/admin/profile" component={OrganisationAdminProfilePage} />
          </Switch>
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
