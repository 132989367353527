/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// MOMENT
import Moment from 'react-moment';
import { Modal } from 'react-bootstrap';
// STRIPE
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';

import OrganisationSidebar from '../../organisationsidebar/organisationsidebar';
import OrganisationHeader from '../../organisationheader/organisationHeader';
import paymentService from '../../../../services/PaymentService';
import Toast from '../../../../services/ToasterService';
import { orgSendThanksDetails, orgResetSendThanksDetails } from '../../../../store/reducers/organisation/sendThanks';
import sendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import OrganisationService from '../../../../services/OrganisationService';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import IconMiscCard from '../../../../assets/images/common/icons-misc-card.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import GoolePay from '../../../../assets/images/common/google-pay-mark-800.png';
import AddCardForms from '../../../../common/component/modal/AddCardForms';
import UsernameSuccessImage from '../../../../assets/images/common/icons-check-filled-green.png';
import ReviewPurchase from '../../../individual/sendThanks/reviewThanks/ReviewPurchase';
import OrganisationSendThanksReviewScreen from '../organisationSendThanksReview/OrganisationSendThanksReviewScreen';
import SummaryPurchase from '../../../common/Summary';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function OrganisationSendThanksPaymentOptionsPage(props) {
  const currentPath = window.location.pathname;
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showDeleteModalHandler = () => setShowDeleteModal(true);
  const hideDeleteModalHandler = () => setShowDeleteModal(false);

  const { productsCard, details } = props.multiProduct;
  const paymentInformation = props.getPaymentInformation;

  const [savedCardsList, setSavedCardsList] = useState([]);
  const [defaultCardList, setDefaultCardList] = useState([]);
  let [choosenCardId, setChoosenCardId] = useState();
  const [cardLastNumber, setCardLastNumber] = useState();
  const [cardExpiryDate, setCardExpiryDate] = useState();
  const [cardBrand, setCardBrand] = useState();
  const [googlepaySelect, setGooglepaySelect] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState();

  const [creditBalance, setCreditBalance] = useState();
  const [availableCredit, setAvailableCredit] = useState(false);
  const [selectedProductPrice, setSelectedProductPrice] = useState();
  const [checkCreditIsAvailableOrNot, setCheckCreditIsAvailableOrNot] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [cardDetails, setCardDetails] = useState();
  const [paymentType, setPaymentType] = useState();
  const [selectedCardDetails, setSelectedCardsDetails] = useState();
  const [clientSecrets, setClientSecrets] = useState();
  const [tileWidth, setTileWidth] = useState();
  const { width } = useWindowDimensions();

  let priceId = productsCard?.prices ? productsCard?.prices[0]?.priceId : productsCard.priceId;
  let checkCardRemoved = false;

  useEffect(() => {
    if (width > 500) {
      setTileWidth(250);
    } else {
      setTileWidth(250);
    }
  }, [width]);

  // useEffect(() => {
  //   // sessionStorage.setItem('PaymentType', 'availableCredit');
  //   // setPaymentType('availableCredit')
  //   loadExistingCardsList();
  //   getCreditBalance();
  //   let priceValue = JSON.parse(sessionStorage.getItem('price'));
  //   setSelectedProductPrice(priceValue);

  //   if (Number(selectedProductPrice) * Number(paymentInformation.quantity) > creditBalance?.balance) {
  //     setCheckCreditIsAvailableOrNot(true);
  //   } else {
  //     setCheckCreditIsAvailableOrNot(false);
  //   }

  //   setAvailableCredit(true);
  //   window.scrollTo(0, 0);
  // }, []);

  useEffect(() => {
    checkIfUserSelectedPaymentCard();
  }, [savedCardsList]);

  // Checking page reload
  useEffect(() => {
    window.onbeforeunload = function (event) {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const getCreditBalance = () => {
    OrganisationService.getCreditsBalance()
      .then(response => {
        if (response) {
          let data = response['data'];

          let priceValue = JSON.parse(sessionStorage.getItem('price'));
          setSelectedProductPrice(priceValue);

          if (Number(priceValue) * Number(paymentInformation.quantity) > data.balance) {
            setCheckCreditIsAvailableOrNot(true);
          } else {
            setCheckCreditIsAvailableOrNot(false);
          }
          setCreditBalance(data);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          Toast(errorMessage, 'error');
        }
      });
  };
  // Check selected a card
  const checkIfUserSelectedPaymentCard = () => {
    if (savedCardsList.length > 0) {
      savedCardsList.map((res, index) => {
        if (sendThanksSchema.sendThanksData?.paymentSource?.cardid === res.id) {
          setChoosenCardId(res.id);
          setCardLastNumber(res.last4);
          setCardExpiryDate(res.expiresOn);
          setCardBrand(res.brand);
        }
      });
    }
  };
  // load existing cards list
  const loadExistingCardsList = () => {
    let googlePayCard = sessionStorage.getItem('PaymentType') ? sessionStorage.getItem('PaymentType') : 'Card';
    if (googlePayCard === 'GooglePay') {
      setGooglepaySelect(true);
    } else if (googlePayCard === 'Card') {
      setGooglepaySelect(false);
    }

    paymentService
      .getListOfCards(true)
      .then(response => {
        if (response) {
          let data = response['data'];
          setPaymentType('availableCredit');
          setSavedCardsList(data);
          let defaultCardData = data.filter(data => {
            return data.isDefault === true;
          });
          for (let i = 0; i < data.length; i++) {
            if (data[i].isDefault) {
              setSelectedCardsDetails({
                ...selectedCardDetails,
                cardLastNumber: data[i].last4,
                choosedCardId: data[i].id,
                cardExpiryDate: data[i].expiresOn,
                productDetails: sendThanksSchema.sendThanksData,
                productType: details?.type,
                priceId: priceId,
                amount: paymentInformation?.amount,
                brand: data[i].brand,
              });
            }
          }

          if (
            (defaultCardData.length > 0 && sendThanksSchema?.sendThanksData?.paymentSource?.cardid === '') ||
            sendThanksSchema?.sendThanksData?.paymentSource?.cardid === undefined
          ) {
            if (googlePayCard === 'Card') {
              setChoosenCardId(defaultCardData[0].id);
            }
          }
          setDefaultCardList(defaultCardData);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          Toast(errorMessage, 'error');
        }
      });
  };

  const deleteCard = index => {
    showDeleteModalHandler();
    setSelectedCardIndex(index);
  };

  const reviewBtnHandler = () => {
    if (choosenCardId !== undefined) {
      sessionStorage.setItem('PaymentType', 'Card');
      setPaymentType('Card');

      sendThanksSchema.sendThanksData.paymentSource.cardid = choosenCardId;
      props.paymentInformation(sendThanksSchema);
      props.history.push({
        pathname: '/organisation/send/reviewThanks',
        state: {
          cardLastNumber: cardLastNumber,
          choosedCardId: choosenCardId,
          cardExpiryDate: cardExpiryDate,
          brand: cardBrand,
          productDetails: sendThanksSchema.sendThanksData,
          productType: details?.type,
          priceId: priceId,
          amount: paymentInformation.amount,
        },
      });

      setSelectedCardsDetails({
        cardLastNumber: cardLastNumber,
        choosedCardId: choosenCardId,
        cardExpiryDate: cardExpiryDate,
        productDetails: sendThanksSchema.sendThanksData,
        productType: details?.type,
        priceId: priceId,
        amount: paymentInformation?.amount,
        brand: cardBrand,
      });
    } else if (googlepaySelect === true) {
      sessionStorage.setItem('PaymentType', 'GooglePay');
      setPaymentType('GooglePay');

      props.history.push({
        pathname: '/organisation/send/reviewThanks',
        state: {
          Type: 'Google Pay',
          productDetails: sendThanksSchema.sendThanksData,
          productType: details?.type,
          priceId: priceId,
          amount: paymentInformation.amount,
        },
      });
    } else if (availableCredit === true) {
      sessionStorage.setItem('PaymentType', 'availableCredit');
      setPaymentType('availableCredit');

      props.history.push({
        pathname: '/organisation/send/reviewThanks',
        state: {
          Type: 'AvailableCredit',
          productDetails: sendThanksSchema.sendThanksData,
          productType: details?.type,
          priceId: priceId,
          amount: paymentInformation.amount,
        },
      });
    } else {
      Toast('Please add a card for payment', 'error');
    }
  };

  // Updating default card
  const setAsDefaultBtnHandler = index => {
    let data = savedCardsList[index];

    let cardData = {
      id: data.id,
      expiresOn: data.expiresOn,
      setAsDefault: true,
    };

    paymentService
      .updateCard(data.id, cardData, true)
      .then(response => {
        if (response.status === 200) {
          loadExistingCardsList();
          Toast('Your default card is changed successfully', 'success');
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseFieldErrors) {
          if (errorResponseFieldErrors.length > 0) {
            errorResponseFieldErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Removing card
  const cardDeleteBtnHandler = () => {
    if (checkCardRemoved === false) {
      checkCardRemoved = true;
      let cardId = savedCardsList[selectedCardIndex].id;

      paymentService
        .deleteCard(cardId, true)
        .then(response => {
          loadExistingCardsList();
          checkIfUserSelectedPaymentCard();
          Toast('Card removed successfully', 'success');

          hideDeleteModalHandler();

          if (cardId === choosenCardId) {
            setChoosenCardId(undefined);
          }

          setTimeout(() => {
            checkCardRemoved = false;
          }, 2000);
        })
        .catch(error => {
          if (error?.response) {
            let errorResponse = error?.response['data'];
            let errorMessage = errorResponse?.globalErrors[0].message;
            Toast(errorMessage, 'error');
          }
        });
    }
  };

  const cancelBtnHandler = () => {
    props.resetData();
    props.history.push({
      pathname: '/organisation/dashboard',
    });
  };

  // Google pay
  const googlePayBtnHandler = () => {
    setGooglepaySelect(!googlepaySelect);
    setChoosenCardId(undefined);
    setAvailableCredit(false);
    sessionStorage.setItem('PaymentType', 'GooglePay');
    setCheckCreditIsAvailableOrNot(false);
    setPaymentType('GooglePay');
  };

  // Available credits
  const availableCreditBtnHandler = () => {
    setAvailableCredit(!availableCredit);
    setChoosenCardId(undefined);
    setGooglepaySelect(false);
    sessionStorage.setItem('PaymentType', 'availableCredit');
    setPaymentType('availableCredit');

    if (Number(selectedProductPrice) * Number(paymentInformation.quantity) > creditBalance?.balance) {
      setCheckCreditIsAvailableOrNot(true);
    } else {
      setCheckCreditIsAvailableOrNot(false);
    }
  };

  return (
    <>
      <div className=" m-0 p-0 row ">
        <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column  ">
          {currentPath === '/organisation/employee/payment' ? (
            <OrganisationSidebar menu="Sendthanks" />
          ) : (
            <OrganisationSidebar menu="Organisation" />
          )}
        </div>

        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half ">
          <OrganisationHeader role="admin" />
          <div className="d-flex m-2 mx-4">
            <Link to="/organisation/send/recipientInformation">
              <span className="back-btn pr-2 pt-2 pb-2 m-4 pl-0 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
                <img src={IconsBackImage} height="30px" width="30px" />
                Back
              </span>
            </Link>
          </div>
          <SummaryPurchase tileWidth={tileWidth} />
          {/* <div className="m-0 p-0 row">
            <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12 mb-4 d-flex flex-column right_half">
              <div className="p-0 m-0 row my-lg-0">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="border-0  border-radius-5">
                    <div className="card-body">
                      <span className="color-black-pearl font-32 font-sofia-pro-light pl-2">Select payment</span>
                      <div className="mt-3 col-lg-12 main-anon-payment col-md-12 col-sm-12 px-0">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <div className="d-flex justify-content-between my-3 pt-4 pb-3">
                            <div className="w-50 text-center cursor-pointer" onClick={() => setPaymentMethod(0)}>
                              <div className="pb-2 monteserrat-normal font-16 light-grey ">
                                Add Debit Card/Credit Card
                              </div>
                              {paymentMethod == 0 ? <div className="border-bottom-grey-blue"></div> : null}
                            </div>

                            <div className="w-50 text-center cursor-pointer" onClick={() => setPaymentMethod(1)}>
                              <div className="pb-2 monteserrat-normal font-16 light-grey ">
                                Saved Cards
                                {savedCardsList.length !== 0 && (
                                  <img src={UsernameSuccessImage} className="pl-2" alt="Filled Green Icon" />
                                )}
                              </div>
                              {paymentMethod == 1 ? <div className="border-bottom-grey-blue"></div> : null}
                            </div>
                          </div>
                          {paymentMethod == 0 ? (
                            <>
                              <div class="mb-3">
                                <Elements stripe={stripePromise}>
                                  <ElementsConsumer>
                                    {({ elements, stripe }) => (
                                      <AddCardForms
                                        setChoosenCardId={setChoosenCardId}
                                        elements={elements}
                                        stripe={stripe}
                                        setCardDetails={setCardDetails}
                                        asCompany={true}
                                        // senderEmail={senderEmail}
                                        // userName={senderUserName}
                                        //   hideModal={() => setShow(false)}
                                        loadExistingList={loadExistingCardsList}
                                        // setCheckCard={setCheckCard}
                                      />
                                    )}
                                  </ElementsConsumer>
                                </Elements>
                              </div>
                            </>
                          ) : (
                            <div className="p-0 m-0 row">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="border-0  border-radius-5">
                                  <div className="payment-main-card">
                                    <div>
                                      <div className="my-2 d-flex justify-content-between">
                                        <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                          Default payment method
                                        </span>
                                      </div>

                                      {defaultCardList.length !== 0 &&
                                        defaultCardList.map((data, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="mt-3 p-2 d-flex justify-content-between align-items-center border-faded-pink bg-lavender-blush border-radius-5"
                                            >
                                              <div className="payment-options-radio__item">
                                                <label className="font-14 font-sofia-pro-light color-black-pearl ">
                                                  <span className="pl-2">
                                                    {' '}
                                                    {data.brand} •••• •••• •••• {data.last4} |
                                                    <span>
                                                      <Moment format="MM/YY">{data.expiresOn}</Moment>
                                                    </span>
                                                  </span>
                                                  <img className="pl-1" src={IconMiscCard} alt="" />
                                                </label>
                                              </div>
                                              <span className="font-14 font-sofia-pro-light color-gunmetal">
                                                Default card
                                              </span>
                                            </div>
                                          );
                                        })}

                                      <div className="mt-4">
                                        <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                          Your saved credit and debit cards
                                        </span>
                                        {savedCardsList.length !== 0 &&
                                          savedCardsList.map((data, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="mt-3 p-2 justify-content-between align-items-center card-details-main"
                                              >
                                                <div className="payment-options-radio__item">
                                                  <input
                                                    type="radio"
                                                    id={'savedCard' + index}
                                                    name={'savedCard' + index}
                                                    value={data.id}
                                                    checked={data.id === choosenCardId && googlepaySelect === false}
                                                    onChange={e => {
                                                      setChoosenCardId(e.target.value);
                                                      setCardLastNumber(data.last4);
                                                      setCardExpiryDate(data.expiresOn);
                                                      setGooglepaySelect(false);
                                                      sessionStorage.setItem('PaymentType', 'Card');
                                                      setPaymentType('Card');
                                                      setAvailableCredit(false);
                                                    }}
                                                  />
                                                  <label className="font-14 font-sofia-pro-light color-black-pearl card-number">
                                                    <span className="pl-2">
                                                      {' '}
                                                      {data.brand} •••• •••• •••• {data.last4} |{' '}
                                                      <span>
                                                        <Moment format="MM/YY">{data?.expiresOn}</Moment>
                                                      </span>
                                                    </span>
                                                    <img className="pl-1 card-icon" src={IconMiscCard} />
                                                  </label>
                                                </div>
                                                <div className="card-buttons">
                                                  <button
                                                    type="button"
                                                    className="mr-2 p-2 btn btn-sm border-0  shadow-none"
                                                    onClick={setAsDefaultBtnHandler.bind(this, index)}
                                                  >
                                                    <span className="font-14 font-sofia-pro-regular color-primary">
                                                      Set as default
                                                    </span>
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="p-2 btn btn-sm border-pale-pink color-primary  shadow-none"
                                                    onClick={deleteCard.bind(this, index)}
                                                  >
                                                    <span className="font-14 font-sofia-pro-regular color-primary">
                                                      Delete
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        {savedCardsList.length === 0 ? (
                                          <p className="my-4 text-center font-18 font-sofia-pro-medium">
                                            No data found
                                          </p>
                                        ) : null}
                                        <div className="mt-4">
                                          <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                            Add a Payment Method
                                          </span>
                                        </div>
                                        <div className="mt-3 p-2 d-flex justify-content-between align-items-center">
                                          <div
                                            className="p-2 border-primary-color payment-options-radio__item border-radius-5 cursor-pointer"
                                            // onClick={() => setShow(true)}
                                          >
                                            <label
                                              className="font-14 font-sofia-pro-light color-black-pearl cursor-pointer"
                                              onClick={() => {
                                                // setShow(true);
                                                setPaymentMethod(0);
                                              }}
                                            >
                                              <span className="pl-2"> Add a debit or credit card </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {creditBalance !== undefined && (
                                  <div className="payment-options-radio__item">
                                    <input
                                      type="radio"
                                      id="ritema1"
                                      name="ritem1"
                                      value="ropt6"
                                      onClick={availableCreditBtnHandler}
                                      checked={availableCredit === true}
                                    />
                                    <label
                                      className="font-14 font-sofia-pro-light color-black-pearl cursor-pointer"
                                      onClick={availableCreditBtnHandler}
                                    >
                                      <span className="pl-2"> Available Credits : ${creditBalance.balance}</span>
                                    </label>
                                  </div>
                                )}
                                {checkCreditIsAvailableOrNot === true && (
                                  <span className="pl-2 font-14 font-sofia-pro-light color-black-pearl">
                                    Credit amount is insufficient to buy the product
                                  </span>
                                )}
                              </div>

                              <div className="col-lg-2">&nbsp;</div>
                            </div>
                          )}
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 mb-5">
                          <OrganisationSendThanksReviewScreen
                            selectedCardDetails={selectedCardDetails}
                            paymentType={paymentType}
                            setClientSecrets={setClientSecrets}
                            // addedCard={cardDetails} checkCard={checkCard}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <Modal
          className="pt-4 border-0
         modal-background-shadow payment-modal"
          size="md"
          show={showDeleteModal}
          onHide={hideDeleteModalHandler}
          animation={false}
        >
          <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Delete card?</span>
            <button type="button" className="close modal-close-icon" onClick={hideDeleteModalHandler}>
              <Close />
            </button>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
            <p className="font-16 font-sofia-pro-light color-nobel">
              Are you sure you want to delete this payment method?
            </p>
            <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
              <button
                className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                onClick={hideDeleteModalHandler}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                onClick={cardDeleteBtnHandler}
              >
                Delete
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

// MAPPING - SEND THANKS DATA

const mapStateToProps = state => {
  return {
    multiProduct: state.multiProduct,
    getPaymentInformation: state.organisationSendThanks.orgSendThanksData,
  };
};

// DISPATCH  - SEND THANKS DATA

const mapDispatchToProps = dispatch => {
  return {
    paymentInformation: data => dispatch(orgSendThanksDetails(data)),
    resetData: () => dispatch(orgResetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSendThanksPaymentOptionsPage);
