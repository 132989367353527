/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import axios from 'axios';
import AuthSliderNew from '../../../../common/AuthSlider/AuthSliderNew';
import Button from '../../../../common/Button/Button';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';
import { EmailRegexValue, AusMobileRegexValue } from '../../../../utils/RegexPatterns';
import Toast from '../../../../services/ToasterService';
import http from '../../../../services/HttpService';
import './Login.scss';
import ReactGA from 'react-ga';

import { Logo, Google, Facebook, HidePasswordImage, ShowPasswordImage, ErrorImage } from '../../../../assets/images';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LoginPage = () => {
  const Anonymous = JSON.parse(sessionStorage.getItem('redeemInfo'));
  let query = useQuery();
  let queryEmail;

  const history = useHistory();

  const [show, setshow] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const { height, width } = useWindowDimensions();

  const [authType, setAuthType] = useState({
    authMethod: 'byemail',
    protectType: 'password',
  });

  const togglePassword = () => {
    setshow(!show);
  };

  const validate = values => {
    let errors = {};

    if (authType.authMethod === 'byemail') {
      let re = EmailRegexValue;

      if (!values.email) {
        errors.email = 'Required';
      } else if (!re.test(values.email)) {
        errors.email = 'Invalid email format';
      }
    } else {
      let re = AusMobileRegexValue;
      let phoneString = values.phone.toString();
      let firstTwoChar = phoneString.slice(0, 2);
      if (!values.phone) {
        errors.phone = 'Required';
      }
    }
    if (authType.protectType === 'password') {
      if (!values.password) {
        errors.password = 'Required';
      } else if (values.password.length < 8) {
        errors.password = ' Please enter at least 8 characters';
      }
    }
    return errors;
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    queryEmail = query.get('email');
    formik.setFieldValue('email', queryEmail);
    // window.location.reload();
    // sessionStorage.clear();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (Anonymous?.giftEmail) {
      formik.setFieldValue('email', Anonymous?.giftEmail);
    } else if (Anonymous?.giftPhone) {
      setAuthType({
        authMethod: 'byphone',
        protectType: 'otp',
      });
      formik.setFieldValue('phone', Anonymous?.giftPhone);
    }
  }, []);

  if (sessionStorage.getItem('Anonymous') === 'anonymous') {
    sessionStorage.setItem('greeting', false);
  } else {
    sessionStorage.setItem('greeting', true);
  }

  const [active, setActive] = useState(sessionStorage.getItem('greeting'));
  const greeting = () => {
    setTimeout(() => {
      setActive(sessionStorage.setItem('greeting', false));
    }, 5000);
  };

  // For social login
  useEffect(() => {
    // console.log('use effect')

    //const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');
    const errordescription = query.get('errordescription');
    const user = JSON.parse(localStorage.getItem('userData'));
    const redirectUrl = query.get('redirectUrl');

    console.log('redirectUrl', redirectUrl, 'token', token, 'email', email, 'user', user);

    if (user?.userKey) {
      axios.defaults.headers.common['userKey'] = user?.userKey;
      if (user?.roles?.includes('CompanyAdmin')) {
        history.push('/organisation/dashboard');
      } else if (user?.roles?.includes('CompanyUser')) {
        history.push('/employee/dashboard');
      } else {
        history.push('/dashboard');
      }
    }

    if (token && email) {
      const socialBody = {
        email: email,
      };
      http
        .post(`/UserSessions/bytoken/${token}`, socialBody)
        .then(function (response) {
          let responseData = response['data'];
          localStorage.setItem('userData', JSON.stringify(responseData));
          axios.defaults.headers.common['userKey'] = responseData.userKey;
          const userActivatedStatus = responseData.status;
          if (userActivatedStatus === 'Registered') {
            sendOTPToExistingAccount();
          } else if (
            (userActivatedStatus === 'Activated' &&
              responseData.userName !== null &&
              responseData.firstName === null) ||
            responseData.firstName === '' ||
            responseData.lastName === null ||
            responseData.lastName === ''
          ) {
            history.replace(
              {
                pathname: '/user-details?auth_type=bytoken',
                state: {
                  screenValue: 1,
                  checkFirstAndLastNameAvailableOrNot: true,
                },
              },
              true
            );
            window.location.reload();
          } else if (
            (userActivatedStatus === 'Activated' && responseData.userName === null) ||
            responseData.userName === ''
          ) {
            history.replace(
              {
                pathname: '/user-details?auth_type=bytoken',
                state: { screenValue: 1 },
              },
              true
            );
          } else {
            Toast('Login Success', 'success');
            if (sessionStorage.getItem('Anonymous') === 'anonymous') {
              history.replace('/redeemthanks', true);
            } else {
              if (redirectUrl !== null) {
                history.replace(redirectUrl, true);
              } else {
                if (user?.roles?.includes('CompanyAdmin')) {
                  history.replace('/organisation/dashboard', true);
                } else if (user?.roles?.includes('CompanyUser')) {
                  history.replace('/employee/dashboard', true);
                } else {
                  history.replace('/dashboard', true);
                }
                // history.replace('/dashboard', true);
              }
            }
          }
        })
        .catch(error => {
          setShowLoader(false);
          console.log('error ', error);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);

          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
    if (errordescription) {
      Toast(errordescription, 'error');
    }
  }, []);

  // Login request
  const onSubmit = values => {
    sessionStorage.setItem('merchantOnboard', false);

    const errordescription = query.get('errordescription');

    const redirectUrl = query.get('redirectUrl');

    toast.dismiss();

    setShowLoader(true);

    const data = values;
    let errorMessage;
    data.deviceId = '';
    data.cookieless = true;
    data.acceptTermsConditions = true;
    data.region = 'AU';
    let url = `/UserSessions/${authType.authMethod}`;
    if (authType.protectType === 'otp') {
      url = `Users/tokens`;
      data.phone = values.phone;
      data.region = 'AU';
      data.type = 'onetimepassword';
      delete data.deviceId;
      delete data.cookieless;
      delete data.acceptTermsConditions;
      delete data.email;
      delete data.password;
    }

    http
      .post(url, data)
      .then(function (response) {
        setShowLoader(false);
        let responseData = response['data'];

        if (authType.protectType === 'otp') {
          sessionStorage.setItem('temp_userData', JSON.stringify(data));
          history.replace(`/otp-auth?auth_type=${authType.authMethod}&prected=otp`, true);
        } else {
          localStorage.setItem('userData', JSON.stringify(responseData));
          axios.defaults.headers.common['userKey'] = responseData.userKey;
          let userActivatedStatus = responseData.status;
          if (userActivatedStatus === 'Registered') {
            sendOTPToExistingAccount();
          } else if (
            (userActivatedStatus === 'Activated' &&
              responseData.userName !== null &&
              responseData.firstName === null) ||
            responseData.firstName === '' ||
            responseData.lastName === null ||
            responseData.lastName === ''
          ) {
            history.replace(
              {
                pathname: '/user-details?auth_type=bytoken',
                state: {
                  screenValue: 1,
                  checkFirstAndLastNameAvailableOrNot: true,
                },
              },
              true
            );
            window.location.reload();
          } else if (
            (userActivatedStatus === 'Activated' && responseData.userName === null) ||
            responseData.userName === ''
          ) {
            history.replace(
              {
                pathname: `/user-details?auth_type=${authType.authMethod}`,
                state: { screenValue: 1 },
              },
              true
            );
            window.location.reload();
          } else if (userActivatedStatus === 'Activated') {
            Toast('Login Success', 'success');
            if (sessionStorage.getItem('Anonymous') === 'anonymous') {
              history.replace('/redeemthanks', true);
            } else {
              // Handling link error
              if (errordescription) {
                let socialMedia = sessionStorage.getItem('socialMedia');

                if (socialMedia === 'google') {
                  let userData = JSON.parse(localStorage.getItem('userData'));

                  let userKey = urlencode(userData?.userKey);

                  window.location.href = `${process.env.REACT_APP_BASE_URL}/UserSessions/byoauth/google/link?redirectUrl=/login&userkey=${userKey}`;
                } else if (socialMedia === 'facebook') {
                  let userData = JSON.parse(localStorage.getItem('userData'));

                  let userKey = urlencode(userData?.userKey);

                  window.location.href = `${process.env.REACT_APP_BASE_URL}/UserSessions/byoauth/Facebook/link?redirectUrl=/login&userkey=${userKey}`;
                }
              } else {
                if (redirectUrl !== null) {
                  history.replace(redirectUrl, true);
                } else {
                  let user = JSON.parse(localStorage.getItem('userData'));
                  // history.push("/dashboard");
                  if (user?.roles?.includes('CompanyAdmin')) {
                    history.replace('/organisation/dashboard', true);
                  } else if (user?.roles?.includes('CompanyUser')) {
                    history.replace('/employee/dashboard', true);
                  } else {
                    history.replace('/dashboard', true);
                  }
                  // history.replace('/dashboard', true);
                }
              }
            }
          }
        }
      })
      .catch(error => {
        console.log('error ', error);
        setShowLoader(false);
        if (error) {
          let errorResponseFieldErrors = error.response?.data.fieldErrors;
          let errorResponseGlobalErrors = error.response?.data.globalErrors;
          if (error) {
            let errorResponse = error.response?.data;
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);

          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        }
      });
  };

  // For url encode
  const urlencode = str => {
    var symbols = {
      '@': '%40',
      '%26amp%3B': '%26',
      '*': '%2A',
      '+': '%2B',
      '/': '%2F',
      '%26lt%3B': '%3C',
      '%26gt%3B': '%3E',
    };
    return escape(str).replace(/([@*+/]|%26(amp|lt|gt)%3B)/g, function (m) {
      return symbols[m];
    });
  };

  // For sending OTP
  const sendOTPToExistingAccount = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    const data = {
      type: 'onetimepassword',
    };
    if (authType.authMethod === 'byemail') {
      data.email = userData.email;
    }
    if (authType.authMethod === 'byphone') {
      data.region = 'AU';
      data.phone = userData.phone;
    }

    http
      .post('/users/tokens', data)
      .then(function (response) {
        history.push(`/user-details?auth_type=${authType.authMethod}`);
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      password: '',
    },
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  const toggleAuthType = () => {
    const { authMethod } = authType;
    setAuthType({
      authMethod: authMethod === 'byphone' ? 'byemail' : 'byphone',
      protectType: authMethod === 'byemail' ? 'otp' : 'password',
    });
  };

  const toggleSigninType = () => {
    setAuthType({
      ...authType,
      protectType: 'password',
    });
  };

  const toggleOTPType = () => {
    setAuthType({
      ...authType,
      protectType: 'otp',
    });
  };

  const validateNumber = e => {
    const re = /^[0-9 +\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      formik.setFieldValue('phone', e.target.value);
    }
  };

  const { authMethod, protectType } = authType;

  return (
    <div className="p-0 m-0 row">
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-7">
        <AuthSliderNew />
      </div>
      <div
        className={
          width <= 768
            ? 'p-0 m-0 px-4 col-sm-12 col-md-12 col-lg-5 mt-md-5 pt-md-5 my-4 d-flex flex-column align-items-center justify-content-center signup-right__half'
            : 'p-0 m-0 px-4 col-sm-12 col-md-12 col-lg-5 d-flex flex-column align-items-center justify-content-center'
        }
      >
        <div className="mx-2 w-100">
          <div
            className={
              width <= 1300
                ? 'mt-2 d-flex justify-content-between px-lg-3 px-md-3'
                : 'mt-4 d-flex justify-content-between px-lg-3 px-md-3'
            }
          >
            <Logo />
            <Link to={'/signup'}>
              <button className="btn btn-outline border-primary-color color-primary border-radius-5 font-sofia-pro-regular">
                Create account
              </button>
            </Link>
          </div>
          <div className={width <= 1300 ? 'pt-2 justify-content-start px-md-3' : 'mt-4 justify-content-start px-md-3'}>
            <span
              className={
                width > 1280
                  ? 'color-black-pearl font-40 font-sofia-pro-bold'
                  : 'color-black-pearl font-35 font-sofia-pro-bold'
              }
            >
              Sign in
            </span>
          </div>
          <div className={width <= 1300 ? 'w-100 px-md-3' : 'pt-2 w-100 px-md-3'}>
            <form onSubmit={formik.handleSubmit}>
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">
                  {authMethod === 'byemail' ? 'Email address' : 'Phone number'}
                </label>
                <span
                  onClick={toggleAuthType}
                  className="float-right font-sofia-pro-regular font-14 color-primary toggle-span"
                >
                  {authMethod === 'byemail' ? 'User phone number instead' : 'User email instead'}
                </span>
                {authMethod === 'byemail' ? (
                  <>
                    <input
                      type="email"
                      className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle shadow-none"
                      name="email"
                      placeholder="Enter your email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle"
                      name="phone"
                      placeholder="Enter phone number"
                      onChange={validateNumber}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              {authMethod === 'byphone' && protectType === 'otp' ? (
                <p
                  onClick={toggleSigninType}
                  className="d-flex justify-content-end font-sofia-pro-regular font-14 color-primary toggle-span"
                >
                  Sign in via Password
                </p>
              ) : (
                <div className="p-0 m-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Password</label>
                  {authMethod === 'byphone' ? (
                    <span
                      onClick={toggleOTPType}
                      className="float-right font-sofia-pro-regular font-14 color-primary toggle-span"
                    >
                      Sign in via OTP
                    </span>
                  ) : (
                    <></>
                  )}
                  <div className="input-group">
                    <input
                      type={show ? 'text' : 'password'}
                      className="form-control h-60 font-sofia-pro-regular login-password__placeholder border-light-periwinkle  login-password__input"
                      name="password"
                      placeholder="Enter your password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />

                    <div className="input-group-append login-input__append__design">
                      {show ? (
                        <ShowPasswordImage
                          className="p-0 m-0 login-password__icon__position"
                          onClick={togglePassword}
                        />
                      ) : (
                        <HidePasswordImage
                          className="p-0 m-0 login-password__icon__position"
                          onClick={togglePassword}
                        />
                      )}
                    </div>
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="p-0 m-0 pt-2 d-flex justify-content-end">
                {/* <div className="d-flex justify-content-between align-items-center">
                  <input type="checkbox" className="mr-2" />
                  <label className="p-0 m-0 font-sofia-pro-regular font-14 color-gunmetal">
                    Remember me
                  </label>
                </div> */}
                <Link to="/users/forgot-password">
                  <span className="font-sofia-pro-regular font-14 color-primary">Forgot password?</span>
                </Link>
              </div>
              <div className="w-100 pt-3">
                {/* <button
                   onClick={greeting}
                   type="submit"
                   className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                 >
                   Sign in
               </button> */}

                <Button
                  classNameValue="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                  children="Sign in"
                  showLoader={showLoader}
                  onClick={() => greeting}
                />
              </div>
            </form>

            {/* Social Accounts */}
            {/*<div className="my-3 pt-2 login-or__container">*/}
            {/*  <span className="font-sofia-pro-medium color-grey-blue font-14">OR</span>*/}
            {/*</div>*/}
            {/*<div className="text-center">*/}
            {/*  <span className="color-black-pearl font-sofia-pro-regular font-20">Sign in using social accounts</span>*/}
            {/*</div>*/}
            {/*<div className=" d-flex justify-content-center align-items-center">*/}
            {/*  <a*/}
            {/*    href={`${process.env.REACT_APP_BASE_URL}/UserSessions/byoauth/google?redirectUrl=/login`}*/}
            {/*    onClick={() => {*/}
            {/*      sessionStorage.setItem('socialMedia', 'google');*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Google className="mr-3" />*/}
            {/*  </a>*/}
            {/*  <a*/}
            {/*    href={`${process.env.REACT_APP_BASE_URL}/UserSessions/byoauth/facebook?redirectUrl=/login`}*/}
            {/*    onClick={() => {*/}
            {/*      sessionStorage.setItem('socialMedia', 'facebook');*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Facebook className="mr-3" />*/}
            {/*  </a>*/}
            {/*</div>*/}

            <div className="text-center mt-2">
              <span
                className={
                  width > 1280
                    ? 'color-grey-blue font-14 font-sofia-pro-regular'
                    : 'color-grey-blue font-11 font-sofia-pro-regular'
                }
              >
                By continuing you agree to Appreci’s
                <a
                  href="https://appreci.io/pages/terms-and-conditions"
                  target="_blank"
                  className="pb-1 text-decoration-none border-bottom-grey-blue cursor-pointer color-grey-blue"
                >
                  {' '}
                  Terms & Conditions
                </a>{' '}
                and
                <a
                  href="https://appreci.io/pages/privacy-policy"
                  target="_blank"
                  className="pb-1 text-decoration-none border-bottom-grey-blue cursor-pointer color-grey-blue"
                >
                  {' '}
                  Privacy Policy
                </a>
              </span>
            </div>
          </div>
          {/* <Link to={"/anonymousselectitem"} className='bg-primary-color color-white w-50 text-center py-3' >Send Gift Directly</Link> */}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
