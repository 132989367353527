/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import OrganisationHeader from '../../../../organisationheader/organisationHeader';
import { toast } from 'react-toastify';
import './AllocateThanksSuccess.scss';
import { connect } from 'react-redux';
import { orgResetSendThanksDetails } from '../../../../../../store/reducers/organisation/sendThanks';
import { ReactComponent as WineIllustraionImage } from '../../../../../../assets/images/send_thanks/components-illustrations-wine.svg';
import { ReactComponent as CoffeeIllustraionImage } from '../../../../../../assets/images/send_thanks/coffee-illustration.svg';
import { ReactComponent as BeerIllustrationImage } from '../../../../../../assets/images/send_thanks/components-illustrations-beer.svg';
import { ReactComponent as GiftIllustrationImage } from '../../../../../../assets/images/send_thanks/components-illustrations-gift.svg';
import { ReactComponent as ClaimFailureImage } from '../../../../../../assets/images/send_thanks/components-illustrations-claim-failure.svg';
import OrganisationSidebar from '../../../../organisationsidebar/organisationsidebar';

function OrganisationRedeemCreditThanksSuccessPage(props) {
  const productType = props.location.state.productType;

  let userDetails = props.getRecipients;

  let checkErrorState = props.location.state.type;

  const history = useHistory();

  useEffect(() => {}, []);

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const sendAgainBtnHandler = () => {
    history.push('/organisation/redeem/selectItem');
    // <Link to={{
    //     pathname: '/recipientInformation',
    //     state: { searchName: [val['userName']] }
    //   }}
    //   >
    // history.push({
    //     pathname: '/recipientInformation',
    //     state: {
    //         searchName: [val['userName']]
    //     }
    // })
    props.resetData();
  };

  const homeBtnHandler = () => {
    props.resetData();
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 py-2 d-flex h-100 flex-column  ">
        <OrganisationSidebar menu="Credits" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half h-100vh">
        <OrganisationHeader role="employee" />
        <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="mt-4 col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 mt-2 card border-0  border-radius-5">
              <div className="card-body">
                {checkErrorState !== 'errorState' && (
                  <div className="my-2 d-flex justify-content-center">
                    {productType === 'Wine' ? <WineIllustraionImage /> : null}
                    {productType === 'Coffee' ? <CoffeeIllustraionImage /> : null}
                    {productType === 'Beer' ? <BeerIllustrationImage /> : null}
                    {productType === 'GiftCard' ? <GiftIllustrationImage /> : null}
                  </div>
                )}

                {checkErrorState === 'errorState' && (
                  <div className="my-2 d-flex justify-content-center">
                    <ClaimFailureImage />
                  </div>
                )}

                {checkErrorState !== 'errorState' && (
                  <div className="my-2 d-flex flex-column justify-content-center align-items-center">
                    <span className="font-24 font-sofia-pro-regular color-black-pearl">
                      You have successfully redeemed your product.
                    </span>
                    <p className="font-14 font-sofia-pro-light color-charcoal-grey text-center">
                      To cancel this transaction, you can do it through transactions history before user has redeemed
                      the product and before expiry date is achieved
                    </p>
                  </div>
                )}

                {checkErrorState === 'errorState' && (
                  <div className="my-2 d-flex flex-column justify-content-center align-items-center">
                    <span className="font-24 font-sofia-pro-regular color-black-pearl">
                      You do not have sufficient credits to redeem this gift
                    </span>
                    <p className="font-14 font-sofia-pro-light color-charcoal-grey text-center">
                      Go to top up and add credits to your account, you can then redeem credits to a gift of your choice
                    </p>
                  </div>
                )}

                {checkErrorState !== 'errorState' && (
                  <div className="p-0 m-0 mt-3 row text-center">
                    <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                    <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                      <button
                        type="submit"
                        className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                        onClick={sendAgainBtnHandler}
                      >
                        Redeem again
                      </button>
                    </div>
                    <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                      <Link to="/employee/dashboard">
                        <button
                          className="w-180 h-60 p-0 m-0  border-radius-5  bg-transparent font-16 color-primary border-faded-pink font-sofia-pro-semi-bold outline-none"
                          onClick={homeBtnHandler}
                        >
                          Home
                        </button>
                      </Link>
                    </div>
                    <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                  </div>
                )}

                {checkErrorState === 'errorState' && (
                  <div className="p-0 m-0 mt-3 row text-center">
                    <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                    <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                      <Link to="/organisation/topup">
                        <button
                          type="submit"
                          className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                        >
                          Top up now
                        </button>
                      </Link>
                    </div>
                    <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                      <Link to="/organisation/dashboard">
                        <button
                          className="w-180 h-60 p-0 m-0  border-radius-5  bg-transparent font-16 color-primary border-faded-pink font-sofia-pro-semi-bold outline-none"
                          onClick={homeBtnHandler}
                        >
                          Home
                        </button>
                      </Link>
                    </div>
                    <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    getRecipients: state.organisationSendThanks.orgSendThanksData,
  };
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(orgResetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationRedeemCreditThanksSuccessPage);
