/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';

// Merchants
const getMerchantDetails = () => {
  return httpService.get('/Merchants/current');
};

const updateMerchantDetails = data => {
  return httpService.put('/Merchants/current', data);
};

const createNewCompany = data => {
  return httpService.post('/Companies', data);
};

const getCurrentUserFromBackend = () => {
  return httpService.get('/Users/current');
};

// Dashboard
const exportRedemptons = () => {
  return httpService.post('/Merchants/current/redemptions');
};

// Locations
const createNewStoreLocation = data => {
  return httpService.post('/merchants/current/locations', data);
};

// Store
const getStoresList = () => {
  return httpService.get('/merchants/current/locations');
};

const getStore = id => {
  return httpService.get('/merchants/current/locations/' + id);
};

const updateStore = (id, data) => {
  return httpService.put('/merchants/current/locations/' + id, data);
};

const deleteStore = id => {
  return httpService.delete('/merchants/current/locations/' + id);
};

// Employees
const createNewCompanyUser = data => {
  return httpService.post('/companies/current/users', data);
};

const getCompanyUsersList = () => {
  return httpService.get('/companies/current/users');
};

const getCompanyUser = id => {
  return httpService.get('/companies/current/users/' + id);
};

const updateCompanyUser = (id, data) => {
  return httpService.put('/companies/current/users/' + id, data);
};

const deleteCompanyUser = id => {
  return httpService.delete('/companies/current/users/' + id);
};

// Payout

const getPayoutList = page => {
  return httpService.get(`/Merchants/current/payouts?pageNumber=${page}`);
};

const getPayoutDetails = (id, page) => {
  return httpService.get(`Merchants/current/redemptions?payoutId=${id}&pageNumber=${page}`);
};

// Transactions
const getTransactionDetails = id => {
  return httpService.get(`Merchants/current/redemptions?locationId=${id}`);
};

// Profile

const updateMerchantPassword = data => {
  return httpService.patch('/Users/current', data);
};

const getCompanyDetails = () => {
  return httpService.get('/Companies/current');
};

const updateCompanyDetails = data => {
  return httpService.put('/Companies', data);
};

const updateEmailDetails = data => {
  return httpService.patch(`/Users/current`, data);
};

export default {
  getMerchantDetails,
  updateMerchantDetails,
  createNewCompany,
  getCurrentUserFromBackend,
  createNewStoreLocation,
  getStoresList,
  getStore,
  updateStore,
  deleteStore,
  getPayoutList,
  createNewCompanyUser,
  getCompanyUsersList,
  getCompanyUser,
  updateCompanyUser,
  deleteCompanyUser,
  exportRedemptons,
  updateMerchantPassword,
  getCompanyDetails,
  updateCompanyDetails,
  getPayoutDetails,
  getTransactionDetails,
  updateEmailDetails,
};
