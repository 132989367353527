/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import * as ReactBootstrap from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import OrganisationSidebar from '../organisationsidebar/organisationsidebar.js';
import OrganisationHeader from '../organisationheader/organisationHeader';
import EditUserPage from './EditUser';
import Button from '../../../common/Button/Button';
import Toast from '../../../services/ToasterService';
import OrganisationService from '../../../services/OrganisationService';
import './Users.scss';
import '../../merchant/store/employees/Employees.scss';
import { ReactComponent as DeleteClose } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/common/icons-misc-search.svg';
import { ReactComponent as FolderIcon } from '../../../assets/images/common/icons-folder.svg';
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import { ReactComponent as EditUser } from '../../../assets/images/common/edit-user.svg';
import { ReactComponent as DeleteUser } from '../../../assets/images/common/delete-user.svg';
import { ReactComponent as Allocate } from '../../../assets/images/common/allocate_dollar.svg';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Close } from '../../../assets/images/common/close-modal.svg';
import SampleCSVFormatImage from '../../../assets/images/common/sample_csv_staff.png';
import CSV from '../../../common/csv/employees.csv';
import DropZone from '../../../common/Upload/Dropzone.js';
import OrganisationHeaderMobile from '../organisationheader/organisationHeaderMobile.js';

const initialValues = {
  email: null,
};

const OrganisationUsersPage = () => {
  const [show, setShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState();

  const history = useHistory();

  const [usersList, setUsersList] = useState([]);

  let [existingUsersList, setExistingUsersList] = useState([]);

  const [checkIsDataLoadedOrNot, setCheckIsDataLoadedOrNot] = useState(false);

  const [fileName, setFileName] = useState(null);

  const [checkFileList, setCheckFileList] = useState(false);

  const [fileData, setFileData] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedUserIndex, setSelectedUserIndex] = useState();

  const [searchText, setSearchText] = useState('');

  const [showLoader, setShowLoader] = useState(false);

  const [disableResendInviteBtn, setDisableResendInviteBtn] = useState(false);

  const [showSampleFormat, setShowSampleFormat] = useState(false);

  const [userIndex, setUserIndex] = useState();

  const [emailClicked, setEmailClicked] = useState(true);

  const [csvClicked, setCsvClicked] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const currentDate = new Date();

  const handleAcceptedFiles = files => {
    const file = files[0];
    setFileData(file.data);
    setFileName(file.name);
  };

  const handleRejectedFiles = files => {
    const file = files[0];
    setFileData(null);
    setFileName(null);
  };

  const handleClose = () => {
    setShow(false);
    setFileName(null);
    setFileData(null);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleCloseSampleFormat = () => setShowSampleFormat(false);
  const handleShowSampleFormat = () => {
    setShowSampleFormat(true);
  };

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  const showDeleteModalHandler = () => setShowDeleteModal(true);
  const hideDeleteModalHandler = () => setShowDeleteModal(false);

  useEffect(() => {
    getUsersList();
    return {};
  }, []);

  const getUsersList = () => {
    OrganisationService.getUsersList()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCheckIsDataLoadedOrNot(true);
          if (data.length === 0) {
            setUsersList([]);
          } else {
            setUsersList(data);
            setExistingUsersList(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const onSubmit = values => {
    toast.dismiss();

    if (values.email !== null || fileData !== null) {
      let data = {
        csvemail: null,
        csvfilebase64: null,
      };
      if (checkFileList === false) {
        data.csvemail = values.email;
        data.csvfilebase64 = null;
      } else if (checkFileList === true) {
        data.csvemail = null;
        data.csvfilebase64 = fileData;
      }

      OrganisationService.uploadEmployeesFileData(data)
        .then(response => {
          if (response) {
            Toast('Your invitation has been sent successfully', 'success');
            handleClose();
            getUsersList();
          }
        })
        .catch(error => {
          if (error) {
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (error) {
              let errorResponse = error.response['data'];
              console.log('error response field errors ', errorResponse);
            }
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors?.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  Toast(msg.message, 'error');
                });
              }
            }
          }
        });
    } else {
      Toast('Either text csv or file csv must be submitted', 'error');
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const getusersListFile = e => {
    setFileName(e.target.files[0].name);
    let base64Data = '';
    getBase64(e.target.files[0], result => {
      base64Data = result.split(',')[1];
      setFileData(base64Data);
    });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const getUserData = (id, index) => {
    setUserIndex(index);
    setEditModalId(id);
    OrganisationService.getUser(id).then(response => {
      if (response) {
        let data = response['data'];
        handleEditModalShow();

        formik.setFieldValue('firstName', data.firstName);
        formik.setFieldValue('lastName', data.lastName);
        formik.setFieldValue('email', data.email);
        formik.setFieldValue('phone', data.phone);
        formik.setFieldValue('role', data.role);
      }
    });
  };

  const deleteEmployeeData = index => {
    console.log('---index----', index);
    showDeleteModalHandler();
    setSelectedUserIndex(index);
  };

  const handleChange = value => {
    setSearchText(value);
    searchEmployee(value);
  };

  const searchEmployee = value => {
    setUsersList(existingUsersList);

    const excludeColumns = ['lastLoginOn'];

    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setUsersList(existingUsersList);
    else {
      const filteredData = existingUsersList.filter(item => {
        return Object.keys(item).some(key =>
          item[key] !== null
            ? excludeColumns.includes(key)
              ? false
              : item[key].toString().toLowerCase().includes(lowercasedValue)
            : null
        );
      });
      setUsersList(filteredData);
    }
  };

  const modalDeleteUserBtnHandler = () => {
    let id = usersList[selectedUserIndex].id;
    OrganisationService.deleteUser(id)
      .then(response => {
        if (response) {
          if (usersList[selectedUserIndex].firstName !== null && usersList[selectedUserIndex].lastName !== null) {
            Toast(
              `${usersList[selectedUserIndex].firstName} ${usersList[selectedUserIndex].lastName} has been removed from your organisation`,
              'success'
            );
          } else {
            Toast(`User has been removed from your organisation`, 'success');
          }
          hideDeleteModalHandler();
          getUsersList();
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const resendInviteBtnHandler = index => {
    let data = usersList[index];
    OrganisationService.resendInvite(data)
      .then(response => {
        if (response) {
          setDisableResendInviteBtn(true);
          Toast(`We’ve resent the link to the provided email address.`, 'success');
          setTimeout(() => {
            setDisableResendInviteBtn(false);
          }, 50000);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const navigateToAllocatePage = index => {
    history.push(`/organisation/admin/allocate/recipientInformation?userId=${usersList[index]?.id}`);
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const checkStoreListDataAvailaleOrNot = () => {
    return (
      <div className="mobile-background">
        <div className="p-0 m-0 row mt-4 my-2 mx-4 px-2 d-flex justify-content-between">
          <span className="font-sofia-pro-regular font-24 color-black-pearl">Users</span>
          <div className="d-flex">
            {windowWidth <= 767 ? (
              <div className="input-group input-group-sm">
                <div className="input-group-prepend employee-search__icon">
                  <div className="pl-2">
                    <SearchIcon />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Search users"
                  className="mr-2 h-48 form-control form-input-placeholder border-0 bg-white employee-search__input shadow-none"
                  value={searchText}
                  onChange={e => handleChange(e.target.value)}
                />
              </div>
            ) : (
              <div className="input-group input-group-sm">
                <div className="input-group-prepend employee-search__icon">
                  <div className="pl-2">
                    <SearchIcon />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Search users"
                  className="mr-2 h-48 form-control form-input-placeholder border-0 bg-white employee-search__input shadow-none"
                  value={searchText}
                  onChange={e => handleChange(e.target.value)}
                />
              </div>
            )}
            <button
              className="d-flex justify-content-center align-items-center btn w-180 bg-primary-color color-white font-sofia-pro-semi-bold font-16 shadow-none"
              onClick={handleShow}
            >
              <span>Invite</span>
            </button>
          </div>
        </div>

        {windowWidth <= 767 ? (
          // Mobile layout
          usersList?.length > 0 &&
          checkIsDataLoadedOrNot === true &&
          usersList.map((user, index) => (
            <div className="cards-area" key={index}>
              <div className="card card-user">
                <div className="card-user-body">
                <div className="user-card-headers">
                  <div className='d-flex'>
                    <div className='d-flex flex-column'>
                      <div className="font-sofia-pro-regular font-14 card-headings">Owner</div>
                      <div className="font-sofia-pro-regular font-14 card-headings">Access</div>
                      <div className="font-sofia-pro-regular font-14 card-headings">Status</div>
                      <div className="font-sofia-pro-regular font-14 card-headings user-event">Event</div>
                      <div className="font-sofia-pro-regular font-14 card-headings user-event">Event Date</div>
                    </div>
                    <div className='d-flex flex-column align-items-start'>
                      <div className="user-profile">
                        {user.profilePhotoPath === null ? (
                          <ProfilePic className="rounded-circle dashboard_oval" />
                        ) : (
                          <img src={user.profilePhotoPath + '?h=80&w=80'} className="rounded-circle dashboard_oval" />
                        )}

                        <h5 className="font-sofia-pro-semi-bold font-14 card-title">
                          {user.firstName} {user.lastName}
                          <p className="font-sofia-pro-light font-12 color-grey-blue mt-1 card-title">{user.email}</p>
                        </h5>
                      </div>
                      <div className="font-sofia-pro-semi-bold font-14 card-title" style={{marginBottom: '1.75rem'}}>{user.role === 'CompanyAdmin' ? 'Admin' : 'Employee'}</div>
                      <div className="font-sofia-pro-semi-bold font-14 card-title" style={{marginBottom: '1.75rem'}}>{user.status}</div>
                      <div className="font-sofia-pro-semi-bold font-14 card-title" style={{marginBottom: '1.75rem'}}>
                      {
                        user.milestones
                        .filter(milestone => {
                          const milestoneDate = new Date(milestone.date);
                          milestoneDate.setHours(0, 0, 0, 0); // Remove the time component
                          currentDate.setHours(0, 0, 0, 0);
                          return milestoneDate >= currentDate
                        }) // Filter milestones with future dates
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        [0]?.type || ''
                      }
                      </div>
                      <div className="font-sofia-pro-semi-bold font-14 card-title" style={{marginBottom: '1.75rem'}}>
                      {
                        user.milestones
                        .filter(milestone => {
                          const milestoneDate = new Date(milestone.date);
                          milestoneDate.setHours(0, 0, 0, 0); // Remove the time component
                          currentDate.setHours(0, 0, 0, 0);
                          return milestoneDate >= currentDate
                        }) // Filter milestones with future dates
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        [0]?.date ? new Date(
                          user.milestones
                        .filter(milestone => {
                          const milestoneDate = new Date(milestone.date);
                          milestoneDate.setHours(0, 0, 0, 0); // Remove the time component
                          currentDate.setHours(0, 0, 0, 0);
                          return milestoneDate >= currentDate
                        }) // Filter milestones with future dates
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        [0]?.date
                        )?.toLocaleDateString('en-GB') : ''
                      }
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                <div className="d-flex align-items-end justify-content-end footer-btns mb-3">
                  <EditUser className="cursor-pointer" onClick={getUserData.bind(this, user.id, index)} />
                  <DeleteUser className="cursor-pointer ml-2" onClick={deleteEmployeeData.bind(this, index)} />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="my-2 row">
            <div class="col"></div>
            <div className="col-11 card dashboard-card__redeemthanks my-3 table-responsive border-0 ">
              <div className="row">
                {/* <div className="table-responsive"> */}
                  <table className="table col-12" style={{ height: '100%' }}>
                    <thead>
                      <tr className="mx-2">
                        <td scope="col" className="font-14 font-sofia-pro table_headings">
                          Owner
                          {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                        </td>
                        <td scope="col" className="font-14 font-sofia-pro table_headings">
                          Access
                          {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                        </td>
                        <td scope="col" className="font-14 font-sofia-pro table_headings">
                          Status
                          {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                        </td>
                        <td scope="col" className="font-14 font-sofia-pro table_headings">
                          Upcoming Event
                          {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                        </td>
                        <td scope="col" className="font-14 font-sofia-pro table_headings">
                          Event Date
                          {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                        </td>
                        <td scope="col"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {usersList?.length > 0 &&
                        checkIsDataLoadedOrNot === true &&
                        usersList.map((user, index) => {
                          return (
                            <tr key={index}>
                              <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center">
                                    {user['profilePhotoPath'] === null ? (
                                      <ProfilePic className="rounded-circle dashboard_oval" />
                                    ) : (
                                      <img
                                        src={user['profilePhotoPath'] + '?h=80&w=80'}
                                        className="rounded-circle dashboard_oval"
                                      />
                                    )}
                                    <div className="pl-2 d-flex flex-column">
                                      <span
                                        data-tip={`${user.firstName}{user.lastName}`}
                                        data-for="userFirstname"
                                        className="d-block"
                                      >
                                        {' '}
                                        {user.firstName} {user.lastName}{' '}
                                      </span>

                                      <span
                                        data-tip={user.email}
                                        data-for="userEmail"
                                        className="d-block user__text__ellipsis color-grey-blue font-sofia-pro-light font-12"
                                      >
                                        {user.email}
                                      </span>
                                      {user?.email?.length > 10 && <ReactTooltip id="userEmail" />}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="pt-3 font-sofia-pro-regular font-14 color-black-pearl">
                                {user.role === 'CompanyAdmin' && <span className="pl-1">Admin</span>}
                                {user.role === 'CompanyUser' && <span className="pl-1">Employee</span>}
                              </td>
                              <td className="pt-3 font-sofia-pro-regular font-14 color-black-pearl" key={index}>
                                {/* <p
                            className={
                              user.status === 'Activated'
                                ? 'p-0 m-0 store-locations__active'
                                : 'p-0 m-0 store-locations__inactive'
                            }
                          ></p> */}
                                <span className="pl-1">{user.status}</span>
                              </td>
                              <td className="pt-3 font-sofia-pro-regular font-14 color-black-pearl" key={index}>
                                  <span className="pl-1">
                                  {
                                    user.milestones
                                    .filter(milestone => {
                                      const milestoneDate = new Date(milestone.date);
                                      milestoneDate.setHours(0, 0, 0, 0); // Remove the time component
                                      currentDate.setHours(0, 0, 0, 0);
                                      return milestoneDate >= currentDate
                                    }) // Filter milestones with future dates
                                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                                    [0]?.type || ''
                                  }
                                  </span>
                              </td>
                              <td className="pt-3 font-sofia-pro-regular font-14 color-black-pearl" key={index}>
                              <div className="d-flex justify-content-between">
                                <span className="pl-1">
                                {
                                  user.milestones
                                  .filter(milestone => {
                                    const milestoneDate = new Date(milestone.date);
                                    milestoneDate.setHours(0, 0, 0, 0); // Remove the time component
                                    currentDate.setHours(0, 0, 0, 0);
                                    return milestoneDate >= currentDate
                                  }) // Filter milestones with future dates
                                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                                  [0]?.date ? new Date(
                                    user.milestones
                                  .filter(milestone => {
                                    const milestoneDate = new Date(milestone.date);
                                    milestoneDate.setHours(0, 0, 0, 0); // Remove the time component
                                    currentDate.setHours(0, 0, 0, 0);
                                    return milestoneDate >= currentDate
                                  }) // Filter milestones with future dates
                                  .sort((a, b) => new Date(a.date) - new Date(b.date))
                                  [0]?.date
                                  )?.toLocaleDateString('en-GB') : ''
                                }
                                </span>
                                <div>
                                  {user?.status === 'Activated' && user?.role === 'CompanyUser' && (
                                    <>
                                      <ReactTooltip place="bottom" effect="solid" />
                                      <span data-tip="Allocate credit">
                                        <Allocate
                                          onClick={navigateToAllocatePage.bind(this, index)}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <EditUser
                                    className="cursor-pointer table-edit"
                                    onClick={getUserData.bind(this, user.id, index)}
                                  />
                                  <DeleteUser
                                    className="cursor-pointer"
                                    onClick={deleteEmployeeData.bind(this, index)}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                {/* </div> */}
                <div class="col"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12  my-4 d-flex  flex-column ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      {windowWidth <= 767 ? (
        <>
          <div className="notification-bell">
            <OrganisationHeaderMobile role="admin" />
          </div>
          <div className="d-flex flex-column flex-end">{checkStoreListDataAvailaleOrNot()}</div>
        </>
      ) : (
        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh">
          <OrganisationHeader role="admin" />
          {checkStoreListDataAvailaleOrNot()}
        </div>
      )}
      <Modal
        className="pt-4 border-0
           modal-background-shadow employee-modal"
        size="lg"
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <div className="position-relative">
          <Logo className="d-flex justify-content-center position-absolute" style={{ width: '100%', top: '10px' }} />
          <button
            type="button"
            className="border-0 position-absolute top-0"
            style={{ background: 'transparent', top: '10px', right: '10px' }}
            onClick={handleClose}
          >
            <Close />
          </button>
        </div>
        <div className="modal-invite-inner">
          <Modal.Header className="p-0 m-0 px-3 pt-5 pb-2 border-0">
            <div className="d-flex flex-column">
              <span className="font-24 color-black-pearl font-sofia-pro-semi-bold text-center">
                Invite your employees
              </span>
              <p className="mt-2 font-sofia-pro color-gunmetal invite_paragraph">
                Discover the benefits of Appreci by inviting employees, Allocate thanks to your employees and start
                appreciating today!
              </p>
            </div>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
            <form className="mt-3" onSubmit={formik.handleSubmit}>
              <div className="p-0 m-0 row">
                <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12">
                  <div className="organisation__user-tabs">
                    <div
                      className={`d-flex ${
                        emailClicked ? 'left-active' : 'left'
                      } font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center`}
                      onClick={() => {
                        setCheckFileList(false);
                        setFileData(null);
                        setFileName(null);
                        setEmailClicked(true);
                        setCsvClicked(false);
                      }}
                    >
                      {/* Left content */}
                      <span>Add by email</span>
                    </div>
                    <div
                      className={`d-flex ${
                        csvClicked ? 'right-active' : 'right'
                      } font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center`}
                      onClick={() => {
                        setCheckFileList(true);
                        formik.setFieldValue('email', '');
                        setCsvClicked(true);
                        setEmailClicked(false);
                      }}
                    >
                      {/* Right content */}
                      <span>Add from CSV</span>
                    </div>
                  </div>
                  {emailClicked && (
                    <>
                      <div style={{ marginBottom: '-2%' }}>
                        <span className="pl-3 font-14 font-sofia-pro-light color-gunmetal">Email</span>
                      </div>
                      <div className="p-0 m-3 p-3 card border-0 bg-alice-blue">
                        <textarea
                          cols="20"
                          rows="8"
                          className="border-0 form-control font-sofia-pro-light organisation__user-textarea-input"
                          placeholder="name@example.com, name2@example.com"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        ></textarea>
                      </div>
                      <span className="my-1 pl-3 font-14 font-sofia-pro-light color-gunmetal">
                        Note: Separate the email using comma( , )
                      </span>
                    </>
                  )}
                  {csvClicked && (
                    <>
                      <p className="mt-3 pl-3 font-14 font-sofia-pro-light color-gunmetal">
                        You can upload CSV file. Include column headers firstname, lastname,email and role.
                        <a
                          className="color-primary cursor-pointer"
                          onClick={handleShowSampleFormat}
                          href={CSV}
                          download="cvs staff"
                        >
                          Here is the sample file format.
                        </a>
                      </p>
                      <div className="p-0 m-3 card border-0">
                        <label htmlFor="file-input">
                          <div className="invite__employee-upload-style">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <FolderIcon />
                              {fileName === null && (
                                <DropZone
                                  rejectedFiles={handleRejectedFiles}
                                  acceptedFiles={handleAcceptedFiles}
                                  multiple={false}
                                  supportText="Must be a CSV file"
                                />
                              )}
                              {fileName !== null && (
                                <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                  <u>{fileName}</u>
                                </p>
                              )}
                            </div>
                            <input
                              id="file-input"
                              type="file"
                              accept=".csv"
                              onChange={getusersListFile}
                              onClick={event => {
                                event.target.value = null;
                              }}
                            />
                          </div>
                        </label>
                      </div>
                    </>
                  )}
                  <div className="d-flex justify-content-center" style={{ width: '100%' }}>
                    <Button
                      classNameValue="mr-3 ml-3 my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white btn_cancel_invite"
                      children="Invite"
                      showLoader={showLoader}
                      onClick={() => onSubmit}
                    />
                    <button
                      className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-transparent font-14 font-sofia-pro-semi-bold color-gunmetal btn_cancel_invite"
                      style={{ border: '1px solid #B5C3CC' }}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        className="w-100 pt-4 border-0 modal-background-shadow employee-modal"
        backdrop="static"
        size="md"
        show={editModalShow}
        onHide={handleEditModalClose}
        animation={false}
      >
        <div className="position-relative">
          <Logo className="d-flex justify-content-center position-absolute" style={{ width: '100%', top: '10px' }} />
          <button
            type="button"
            className="border-0 position-absolute top-0"
            style={{ background: 'transparent', top: '10px', right: '10px' }}
            onClick={handleEditModalClose}
          >
            <Close />
          </button>
        </div>
        <div className="modal-inner">
          <Modal.Header className="p-0 m-0 px-3 pt-5 pb-2 border-0 d-flex justify-content-center align-items-center">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold text-center">Edit employee</span>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
            <EditUserPage
              id={editModalId}
              closeModal={handleEditModalClose}
              loadUsersList={getUsersList}
              deleteEmployeeData={deleteEmployeeData}
              index={userIndex}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        className="pt-4 border-0
          modal-background-shadow employee-modal d-flex align-items-center"
        size="lg"
        show={showDeleteModal}
        backdrop="static"
        onHide={hideDeleteModalHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              Are you sure you want to remove {usersList[selectedUserIndex]?.firstName}{' '}
              {usersList[selectedUserIndex]?.lastName} from your organisation?
            </span>
            <span className="font-14 font-sofia-pro-light color-black-pearl">
              {usersList[selectedUserIndex]?.firstName} {usersList[selectedUserIndex]?.lastName} will no longer have
              access to any of the products or services on the platform
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={hideDeleteModalHandler}
          >
            <DeleteClose />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={() => {
                modalDeleteUserBtnHandler();
                handleEditModalClose();
              }}
            >
              Remove
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={hideDeleteModalHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
        show={showSampleFormat}
        onHide={handleCloseSampleFormat}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 border-0">
          <button
            type="button"
            className="close switch-to-account-modal__close__icon"
            onClick={handleCloseSampleFormat}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-1 pb-5 border-0 text-center">
          <h4 className="p-2 font-sofia-pro-regular color-black-pearl">Sample CSV format</h4>
          <div className="mt-2">
            <img src={SampleCSVFormatImage} className="mw-100" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrganisationUsersPage;
