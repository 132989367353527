/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *

 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory, withRouter } from 'react-router-dom';
import { ReactComponent as Search } from '../../../assets/images/dashboard/icons/icons-search.svg';
import { ReactComponent as CloseImage } from '../../../assets/images/common/icons-close-border.svg';
import { useFormik } from 'formik';
import httpService from '../../../services/HttpService';
import PlaceColor from '../../../assets/images/common/icons-location-colour.png';
import PeopleWhite from '../../../assets/images/common/icons-user-white.png';
import PlaceWhite from '../../../assets/images/common/icons-location-white.png';
import PeopleColor from '../../../assets/images/common/icons-user-colour.png';
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import Button from '../../../common/Button/Button';
import '../../../common/Header/Header.scss';
import { ReactComponent as SearchIcon } from '../../../assets/images/common/icons-misc-search.svg';

const OrganisationSearcheBar = props => {
  const [searchData, setSearchData] = useState([]);
  const [searchPlaceData, setSearchPlaceData] = useState([]);
  const [peopleSelect, setPeopleSelect] = useState(true);
  const [placeSelect, setPlaceSelect] = useState(false);
  const [clickedValue, setClickedValue] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [userRole, setUserRole] = useState(null);
  let userData1 = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    userData1.roles.forEach((data, index) => {
      setUserRole(data);
    });
  }, []);

  const GetUserSearchResult = () => {
    setShowLoader(true);
    setClickedValue(true);
    let data = {
      search: formik.values.userName,
    };

    httpService
      .post('Users/search', data)
      .then(res => {
        setShowLoader(false);
        setSearchData(res['data']);
      })
      .catch(err => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getGeoLocation();
  }, []);
  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  };
  let currentPath = usePathname();

  const getGeoLocation = () => {
    const geolocation = window.navigator && window.navigator.geolocation;

    geolocation.getCurrentPosition(
      position => {
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
      },

      error => {}
    );
  };
  const history = useHistory();
  const GetDirection = index => {
    showMap(searchPlaceData[index]['latitude'], searchPlaceData[index]['longitude']);
  };

  function showMap(lat, lng) {
    var url = 'https://maps.google.com/?q=' + lat + ',' + lng;
    window.open(url);
  }
  const GetPlaceSearchResult = () => {
    setClickedValue(true);
    if (lat !== null && long !== null && lat !== undefined && long !== undefined) {
      httpService
        .get(
          `Locations?latitude=${lat}&longitude=${long}&distanceInMetres=10000000&productType=coffee&region=AU& search=${formik.values.userName}&pageSize=1000`
        )
        .then(res => {
          setSearchPlaceData(res['data']['data']);
        })
        .catch(err => {});
    }
  };

  const initialValues = {
    userName: null,
  };
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
  });

  const handleKeyDown = e => {
    if (e.key === 'Enter' && formik.values.userName.trim().length > 0) {
      GetUserSearchResult();
      GetPlaceSearchResult();
    }
  };

  const PeopleSwitch = () => {
    setPeopleSelect(true);
    setPlaceSelect(false);
  };
  const PlaceSwitch = () => {
    setPeopleSelect(false);
    setPlaceSelect(true);
  };
  const RedeemClicked = index => {
    history.push(`/organisation/redeemthanks?locationId=${searchPlaceData[index]['id']}`);
    sessionStorage.setItem('CafeName', searchPlaceData[index]['name']);
    setClickedValue(false);
  };

  const SendClicked = (index, props) => {
    if (searchPlaceData !== '' && null) {
      sessionStorage.setItem('CafeName', searchPlaceData[index]['name']);
    }
    if (currentPath === '/organisation/send/recipientInformation') {
      history.push(`/organisation/send/recipientInformation?user=${searchData[index]['name']}`);
    }
    setClickedValue(false);
  };

  const SearcheResult = () => {
    return (
      <div className="header-search__result d-flex flex-column">
        <div className="d-flex align-items-start mx-4 my-3">
          <button
            className={peopleSelect === true ? 'header-search__selected border-0' : 'header-search__unselected '}
            onClick={PeopleSwitch}
          >
            <img src={peopleSelect === true ? PeopleWhite : PeopleColor} />
            <span
              className={
                peopleSelect === true
                  ? 'font-sofia-pro-regular header-search__textselected '
                  : 'font-sofia-pro-regular header-search__textunselected'
              }
            >
              People
            </span>
          </button>
          {userRole === 'CompanyUser' ? (
            <button
              className={`bg-white-smoke
                ${placeSelect === true ? 'header-search__selected border-0 mx-2' : 'header-search__unselected mx-2'}
              `}
              onClick={PlaceSwitch}
              disabled
            >
              <img src={placeSelect === true ? PlaceWhite : PlaceColor} />
              <span
                className={
                  placeSelect === true
                    ? 'font-sofia-pro-regular header-search__textselected '
                    : 'font-sofia-pro-regular header-search__textunselected'
                }
              >
                Place
              </span>
            </button>
          ) : null}
        </div>
        <div className="header-search__resultline my-3" />
        <div className="d-flex mx-3  my-lg-0 my-3">
          <span className="header-search__results-found font-sofia-pro-medium">
            {peopleSelect === true ? searchData.length : placeSelect === true ? searchPlaceData.length : ''} results
            found
          </span>
        </div>
        {peopleSelect === true
          ? searchData.map((val, index) => {
              return (
                <div key={index}>
                  <div className="d-flex  justify-content-between align-items-center flex-row m-3">
                    <div className="d-flex">
                      <div>
                        {val['profilePhotoUrl'] != null ? (
                          <img src={val['profilePhotoUrl'] + '?h=40&w=40'} className="rounded-circle" />
                        ) : (
                          <ProfilePic className="rounded-circle header-search__Ovalimage" />
                        )}
                      </div>

                      <div className="d-flex flex-column">
                        <span className="header-search__results-found font-sofia-pro-regular mx-3">
                          {val['fullName']}
                        </span>
                        <span className="header-search__results-foundusername font-sofia-pro-regular mx-3">
                          {val['userName']}
                        </span>
                      </div>
                    </div>

                    {userRole === 'CompanyAdmin' ? (
                      <Link
                        to={{
                          pathname: '/organisation/send/recipientInformation',
                          state: { searchName: [val['userName']] },
                        }}
                      >
                        <button
                          className="header-search__selectbutton border-0"
                          onClick={SendClicked.bind(this, index, props)}
                        >
                          <span className="font-sofia-pro-medium header-search__Sendtext">Send</span>
                        </button>
                      </Link>
                    ) : userRole === 'CompanyUser' ? (
                      <Link
                        to={{
                          pathname: '/organisation/employee/recipientInformation',
                          state: { searchName: [val['userName']] },
                        }}
                      >
                        <button
                          className="header-search__selectbutton border-0"
                          onClick={SendClicked.bind(this, index, props)}
                        >
                          <span className="font-sofia-pro-medium header-search__Sendtext">Send</span>
                        </button>
                      </Link>
                    ) : null}
                  </div>
                  <div className="header-search__resultline " />
                </div>
              );
            })
          : placeSelect === true
          ? searchPlaceData.map((value, index) => {
              return (
                <div key={index}>
                  <div className="d-flex flex-row mx-2 justify-content-between my-2">
                    <div className="d-flex flex-row">
                      {value['merchant']['logoUrl'] === null || '' ? (
                        <div className="header-search__ovalavailablelocation rounded-circle mx-1" />
                      ) : (
                        <div className=".dashboard-oval__availablelocationimage  mx-1">
                          <img src={value['merchant']['logoUrl'] + '?h=40&w=40'} className="redeem-caff__imageround" />
                        </div>
                      )}
                      <div className="d-flex flex-column justify-content-center">
                        <span className="header-search__Manly-Cafe font-sofia-pro-semi-bold mx-2">
                          {value['merchant']['name']}
                        </span>

                        <span className="dashboard-text__kmawayGetDi font-sofia-pro-light mx-2">
                          <button className="border-0 bg-transparent" onClick={GetDirection.bind(this, index)}>
                            <span className="dashboard-text__kmawayGetDitextstyle-1 font-sofia-pro-regular">
                              Get Directions
                            </span>
                          </button>
                        </span>
                      </div>
                    </div>
                    <div>
                      {userRole === 'CompanyUser' ? (
                        <button
                          className="header-search__selectbutton border-0 dashboard-button align-items-center justify-content-center"
                          onClick={RedeemClicked.bind(this, index)}
                        >
                          <span className="font-sofia-pro-medium header-search__Sendtext d-flex">Redeem</span>
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="dashboard-line my-1" />
                </div>
              );
            })
          : ''}
      </div>
    );
  };

  useEffect(() => {
    const searchTime = setTimeout(() => {
      SearchClicked();
    }, 1500);

    return () => {
      clearTimeout(searchTime);
    };
  }, [formik.values.userName]);

  const SearchClicked = () => {
    if (formik.values.userName?.trim().length > 0) {
      GetUserSearchResult();
      GetPlaceSearchResult();
    }
    if (formik.values.userName?.trim().length > 0 && lat !== undefined && long !== undefined) {
      GetPlaceSearchResult();
    }
  };

  const SearchCloseBtn = () => {
    setClickedValue(false);
    formik.setFieldValue('userName', '');
  };

  return (
    <>
      <div className={'my-2 mx-2 border-0 dashboard-button  dashboard-search_rectangle'}>
        <div className="p-0 m-0 mx-2 ">
          <form onSubmit={formik.handleSubmit} id="form-search-name" className={'d-flex align-items-center'}>
            {/* <div className="w-100">
              <input
                type="text"
                className={`bg-transparent p-0 form-control shadow-none dashboard-search dashboard-user  font-sofia-pro-light header-search__input__style`}
                placeholder="Search users"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.userName}
                name="userName"
                onKeyDown={handleKeyDown}
              />
            </div> */}
            <div className="input-group input-group-sm">
              <div className="employee-search__icon bg-transparent border-0">
                <SearchIcon />
              </div>
              <input
                type="text"
                className={`bg-transparent p-0 form-control shadow-none dashboard-search dashboard-user  font-sofia-pro-light header-search__input__style`}
                placeholder="Search users"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.userName}
                name="userName"
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="d-flex">
              {/* <button className="bg-transparent border-0" onClick={SearchClicked}>
                <Search />
              </button> */}
              <Button
                textLoading={false}
                classNameValue="bg-transparent border-0"
                onClick={SearchCloseBtn}
                showLoader={showLoader}
              >
                <CloseImage />
              </Button>
            </div>
          </form>
        </div>
      </div>
      {clickedValue === true ? (
        <div className="d-flex justify-content-start ">
          <SearcheResult />
        </div>
      ) : null}
    </>
  );
};

export default withRouter(OrganisationSearcheBar);
