/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import 'moment-timezone';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../common/Button/Button.js';
import Toast from '../../../services/ToasterService.js';
import useWindowDimensions from '../../../common/Responsive/WindowDimensions';
import './Milestones.scss';
import ErrorImage from '../../../assets/images/error-icons/icons-error-info.png';
import CreateMilestoneHeader from './CreateMilestoneHeader.js';
import httpService from '../../../services/HttpService.js';
import ItemCheckedImage from '../../../assets/images/common/icons-check-filled-pink.png';
import AddRecipients from './AddRecipients.js';
import Message from './Message';
import TwoBrand from './TwoBrand.js';
import CustomDropdown from '../../../common/CustomDropDown/CustomDropdown.js';
import OrganisationService from '../../../services/OrganisationService';
import SendThanksService from '../../../services/SendThanksService';
import QuantityBox from './QuantityBox.js';
import GiftSelector from './GiftSelector.js';
import { useHistory } from 'react-router-dom';



const initialValues = {
  milestoneType: 'e.g. Birthday',
  occasion: '',
  milestoneTitle: '',
  gift: '',
  quantity: '',
  brand: {},
  item: {},
  priceId: '',
  total: '',
  sendVia: '',
  searchData: [],
  recipients: [{employee: '', date: ''}],
  employees: [],
  selectedUsers: [],
  message: {
    type: '',
    message: '',
    storedFileUrl: '',
    storedFile: '',
    storedFileSecret: ''
  },
  giftType: '',
  giftFrequency: '',
  ending: '',
};

const validate = values => {
  let errors = {};

  if (values.milestoneType == "e.g. Birthday") {
    errors.milestoneType = 'Required';
  }

  if (!values.milestoneTitle) {
    errors.milestoneTitle = 'Required';
  } else if (values.milestoneTitle.length > 25) {
    errors.milestoneTitle = 'Characters length should not exceed 25';
  }

  if (!values.brand) {
    errors.brand = 'Required';
  }

  if (!values.item) {
    errors.item = 'Required';
  }

  if (!values.quantity) {
    errors.quantity = 'Required';
  }

  values.recipients.forEach((recipient, index) => {
    if (!recipient.employee) {
      errors.recipients = errors.recipients || [];
      errors.recipients[index].employee = 'Required';
    }
    if (!recipient.date) {
      errors.recipients = errors.recipients || [];
      errors.recipients[index].date = 'Required';
    }
  });

  if (!values.message.message) {
    errors.message = 'Required';
  } else if (values.message.length > 25) {
    errors.message = 'Characters length should not exceed 25';
  }

  return errors;
};

const OrganisationCreateMilestonesPage = () => {
  const history = useHistory()
  const [checkFileList, setCheckFileList] = useState(false);

  const [fileData, setFileData] = useState(null);

  const [showLoader, setShowLoader] = useState(false);

  const [nextClicked, setNextClicked] = useState(false);

  const [drinks, setDrinks] = useState([]);

  const [sweets, setSweets] = useState([]);

  const [giftCards, setGiftCards] = useState([]);

  const [hampers, setHampers] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState('allProducts');

  const [selectedImg, setSelectedImg] = useState({});

  const [back, setBack] = useState(false);

  const [addRecipientsShow, setAddRecipientsShow] = useState(false);
  const [showMessageScreen, setShowMessageScreen] = useState(false);

  const [includeMsg, setIncludeMsg] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [newMilestone, setNewMilestone] = useState([]);
  const [modals, setModals] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
 
  const [selectedProductOption, setSelectedProductOption] = useState();
  const [checkGiftCardIsClicked, setCheckGiftCardIsClicked] = useState(false);

  const handleShowMessageScreen = () => {
    setShowMessageScreen(true);
    setAddRecipientsShow(false);
    setIncludeMsg(false);

    const combinedArray = formik.values.selectedUsers.map(dataItem => {
      const recipient = formik.values.recipients.find(
        recipient => recipient.employee === `${dataItem.firstName} ${dataItem.lastName}`
      );
      if (recipient) {
        const { employee, date } = recipient;
        return {
          ...dataItem,
          milestones: [{ id: 0, date }],
        };
      }
      // return dataItem;
    });

    formik.setFieldValue('employees', combinedArray);
  };

  const [tileWidth, setTileWidth] = useState();

  const { width } = useWindowDimensions();

  const options = ['Birthday', 'Anniversary', 'Other Milestone'];

  useEffect(() => {
    window.scrollTo(0, 0);
    loadProduct();
    // return () => {
    //   props.resetData();
    // };
  }, []);

  useEffect(() => {
    if (products.length > 0) {
      loadProductsTypes();
    }
  }, [products]);

  const handleAddRecipientsModalClose = () => setAddRecipientsShow(false);
  const handleAddRecipientsShow = (val, quantity) => {
    setAddRecipientsShow(true);
    setSelectedImg({});
    if (val) {
      formik.setFieldValue('brand', val);
      formik.setFieldValue('priceId', val?.prices[0]?.priceId);
      if(quantity !== undefined) {
        formik.setFieldValue('total', `$${val.prices[0]?.price * quantity}`);
      }
    }
  };

  const handleNextClicked = () => {
    setNextClicked(true);
    setSelectedCategory('allProducts');
  };

  const handleBack = () => {
    setBack(true);
    setSelectedCategory('allProducts');
    setSelectedImg({});
  };

  const handleMsgBack = () => {
    setAddRecipientsShow(true);
    setShowMessageScreen(false);
  };

  const handleCategoryClicked = category => {
    setSelectedCategory(category);
  };

  const getCreditBalance = () => {
    OrganisationService.getCreditsBalance()
      .then(response => {
        if (response) {
          let data = response['data'];
          localStorage.setItem('avaialbleBalance', parseFloat(data.balance).toFixed(2));
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          toast.error(errorMessage);
        }
      });
  };

  const loadProduct = async () => {
    SendThanksService.getListOfProducts('AU', 'Unknown', true)
      .then(res => {
        setProducts(res?.data);
      })
      .catch(error => {
        console.log('error ', error);
      });
  };

  const loadProductsTypes = async () => {
    httpService
      .get(`/products/types`)
      .then(res => {
        const productTypesWithPurchase = res?.data?.filter(productType =>
          products.some(product => product?.type === productType?.type && product?.features === 'Purchase')
        );
        let drinksArr = [];
        let sweetsArr = [];
        let giftCardsArr = [];
        let hampersArr = [];
        productTypesWithPurchase?.map((item, index) => {
          if (item?.category == "Drinks") {
            drinksArr.push({ ...item, productTypeIndex: index });
          } else if (item.category === 'Sweet tooth') {
            sweetsArr.push({ ...item, productTypeIndex: index });
          } else if (item.category === 'Gift cards') {
            giftCardsArr.push({ ...item, productTypeIndex: index });
          } else if (item.category === 'Hampers') {
            hampersArr.push({ ...item, productTypeIndex: index });
          }
        });
        setDrinks(drinksArr);
        setSweets(sweetsArr);
        setGiftCards(giftCardsArr);
        setHampers(hampersArr);
        setProductTypes(productTypesWithPurchase);
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response?.data.globalErrors;
        let errorResponse = error.response?.data;
        if (errorResponse?.globalErrors[0]) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const selectedItem = index => {
    setSelectedProductOption(index);
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
    if (productTypes[index].type === 'GiftCard') {
      setCheckGiftCardIsClicked(true);
    } else {
      setCheckGiftCardIsClicked(false);
    }
  };

  const onImgClick = (value, index) => {
    formik.setFieldValue('gift', value);
    setBack(false);
    setSelectedImg(value);
    setSelectedCategory('');
    selectedItem(index);
  };

  useEffect(() => {
    console.log('---formikValues----', formik?.values);
  });

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const updateBulkUsers = employees => {
    let data = { employees: [] };
    for (let i = 0; i < employees.length; i++) {

      data.employees.push({id: employees[i].id, date: employees[i].milestones[0].date});
    }
      OrganisationService.updateBulkEmployees(employees[0].milestones[0].id, data)
        .then(response => {
          if (response) {
            setShowLoader(false);
            formik.setFieldValue("employees", []);
            formik.setFieldValue('selectedUsers', []);
            formik.setFieldValue('recipients', [{employee: '', date: ''}]);
            formik.setFieldValue('searchData', []);
            history.push("/organisation/milestones");
          }
        })
        .catch(error => {
          setShowLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors?.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                // Toast(msg.message, 'error');
              });
            }
          }
        });
  };

  const onSubmit = values => {
    toast.dismiss();

    setShowLoader(true);

    let createData = {
      type: values?.milestoneType,
      title: values?.milestoneTitle
    }

    let patchData = {
      priceId: values?.priceId,
      quantity: parseInt(values?.quantity),
      amount: parseFloat(values?.total.substring(1)),
      repeatCount: values?.repeatCount,
      message: values?.message?.type !== "" ? {
        type: values?.message?.type,
        message: values?.message?.message,
        storedFileUrl: values?.message?.storedFileUrl,
        storedFile: values?.message?.storedFile,
        storedFileSecret: values?.message?.storedFileSecret
      }: null
    };

    OrganisationService.createMilestone(createData)
      .then(response => {
        if (response) {
          OrganisationService.updateMilestone(response.data.id, patchData)
          .then(response => {
            if(response) {
              setShowLoader(false);
              setNewMilestone(response?.data);
              for (let i = 0; i < formik.values.employees.length; i++) {
                formik.values.employees[i].milestones[0].id = response?.data?.id;
              }
              updateBulkUsers(formik.values.employees);
            }
          })
          .catch(error => {
            setShowLoader(false);
            let errorResponseFieldErrors = error?.response['data']?.fieldErrors;
            let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
            if (error) {
              let errorResponse = error?.response['data'];
              console.log('error response field errors ', errorResponse);
            }
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors?.length > 0) {
                errorResponseGlobalErrors?.forEach(msg => {
                  Toast(msg?.message, 'error');
                });
              }
            }
          });
          // props.closeModal();
          // props.loadUsersList();
          Toast('Your milestone has been created successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error?.response['data']?.fieldErrors;
        let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
        if (error) {
          let errorResponse = error?.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors?.forEach(msg => {
              Toast(msg?.message, 'error');
            });
          }
        }
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validate,
  });

  useEffect(() => {
    getCreditBalance();
  }, []);

  useEffect(() => {
    if (width > 500) {
      setTileWidth(250);
    } else {
      setTileWidth(250);
    }
  }, [width]);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className=" m-0 p-0 row ">
      <div
        className={`p-0 m-0 col-sm-12 col-md-12 col-lg-12 d-flex flex-column right_half ${
          (showMessageScreen && includeMsg) || showSummary
            ? ''
            : !nextClicked || showMessageScreen
            ? 'h-100vh'
            : ''
        }`}
      >
        <CreateMilestoneHeader />
        {!nextClicked ? (
          <>
            <div className="d-flex flex-column position-relative" style={{ marginTop: '80px' }}>
              <span className="font-sofia-pro-regular font-32 color-black-pearl text-center">
                Create your milestone
              </span>
              <p className="mt-2 px-3 font-sofia-pro color-gunmetal invite_paragraph">
                Choose your milestone and never forget to send your gratitude again
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Modal
                className="pt-4 create-modal"
                // backdrop="static"
                show
                animation={false}
              >
                <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                  <form className="mt-3">
                    <div className="p-0 b-0 pb-1 form-group">
                      <label className="font-sofia-pro-regular color-charcoal font-16">Select a milestone</label>
                      <CustomDropdown
                        options={options}
                        name="milestoneType"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.milestoneType}
                        defaultValue={'e.g. Birthday'}
                      />
                      {formik.touched.milestoneType && formik.errors.milestoneType ? (
                        <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.milestoneType}
                        </div>
                      ) : null}
                    </div>
                    {formik.values.milestoneType === 'Other Milestone' && (
                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">What’s the occasion?</label>
                        <input
                          type="text"
                          className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                          name="occasion"
                          placeholder="e.g. Christmas"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.occasion}
                        />
                        {formik.touched.occasion && formik.errors.occasion ? (
                          <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.occasion}
                          </div>
                        ) : null}
                      </div>
                    )}
                    <div className="p-0 b-0 pb-1 form-group">
                      <label className="font-sofia-pro-regular color-charcoal font-16">Milestone title</label>
                      <input
                        type="text"
                        className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                        name="milestoneTitle"
                        placeholder="e.g. Work Anniversary"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.milestoneTitle}
                      />
                      {formik.touched.milestoneTitle && formik.errors.milestoneTitle ? (
                        <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.milestoneTitle}
                        </div>
                      ) : null}
                    </div>
                    <div className="w-100">
                      <Button
                        classNameValue="w-100 my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                        children="Next"
                        showLoader={showLoader}
                        onClick={handleNextClicked}
                        // disabled={formik.values.milestoneTitle && formik.values.milestoneTitle.length < 25 ? false : true}
                        disabled={formik.values.milestoneTitle && formik.values.milestoneTitle.length < 25 && formik.values.milestoneType !== "e.g. Birthday" ? false : true}
                      />
                    </div>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </>
        ) : (
          <>
            {(selectedCategory !== '' && nextClicked) || back ? (
              <GiftSelector
                selectedCategory={selectedCategory}
                showLoader={showLoader}
                windowWidth={windowWidth}
                selectedImg={selectedImg}
                tileWidth={tileWidth}
                drinks={drinks}
                sweets={sweets}
                giftCards={giftCards}
                hampers={hampers}
                handleCategoryClicked={handleCategoryClicked}
                onImgClick={onImgClick}
              />
            ) : (
              
              <>
                {Object.keys(selectedImg).length > 0 && (
                    <TwoBrand
                      handleAddRecipientsShow={handleAddRecipientsShow}
                      showLoader={showLoader}
                      handleBack={handleBack}
                      selectedImg={[selectedImg]}
                      formik={formik}
                      tileWidth={tileWidth}
                      selectedProductOption={selectedProductOption}
                      checkGiftCardIsClicked={checkGiftCardIsClicked}
                      productTypes={productTypes}
                    />
                  )}
              </>
            )}
            {addRecipientsShow ? (
              <>
              {formik.values.quantity ? <QuantityBox quantity={formik.values.quantity} total={formik.values.total} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
                <div className="d-flex flex-column position-relative" style={{ marginTop: '80px' }}>
                  <span className="font-sofia-pro-regular font-32 color-black-pearl text-center">
                    Add your recipients
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{height: modals.length<= 1 ? "78vh" : ""}}>
                  {/* <Modal
                className="record-modal"
                // backdrop="static"
                show={addRecipientsShow}
                animation={false}
              >
              <Modal.Body className="p-1 m-0 px-4 border-0"> */}
                  <div className='px-4 py-3 mb-3 recipient-modal'>
                    <AddRecipients
                      handleShowMessageScreen={handleShowMessageScreen}
                      formik={formik}
                      modals={modals}
                      setModals={setModals}
                    />
                  </div>
                  {/* <AddRecipients handleShowMessageScreen={handleShowMessageScreen} handleRecipientsBack={handleRecipientsBack}/> */}
                  {/* </Modal.Body>
              </Modal> */}
                </div>
              </>
            ) : (
              ''
            )}
            {showMessageScreen ? (
              <Message
                handleMsgBack={handleMsgBack}
                includeMsg={includeMsg}
                setIncludeMsg={setIncludeMsg}
                formik={formik}
                showSummary={showSummary}
                setShowSummary={setShowSummary}
                tileWidth={tileWidth}
                setSelectedCategory={setSelectedCategory}
                setNextClicked={setNextClicked}
                setShowMessageScreen={setShowMessageScreen}
                setAddRecipientsShow={setAddRecipientsShow}
                onSubmit={onSubmit}
              />
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default OrganisationCreateMilestonesPage;
