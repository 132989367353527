import React, {useCallback, useState, useEffect, useRef} from 'react';
import {ReactComponent as SendMessage} from '../../../assets/images/common/send-message-icon.svg';
import {ReactComponent as NoMessage} from '../../../assets/images/common/no-message-icon.svg';
import {ReactComponent as Record} from '../../../assets/images/common/record-icon.svg';
import ItemCheckedImage from '../../../assets/images/common/icons-check-filled-pink.png';
import Button from '../../../common/Button/Button.js';
import { toast } from 'react-toastify';
import { EmailRegexValue } from '../../../utils/RegexPatterns';
import RecordVideo from '../../../common/RecordVideo/RecordVideo';
import RecordAudio from '../../../common/RecordAudio/RecordAudio';
import VideoDropzone from '../../../common/Upload/VideoDropzone';
import { ReactComponent as FolderIcon } from '../../../assets/images/common/icons-folder.svg';
import Preferences from './Preferences';
import Summary from './Summary';
import { ReactComponent as DeleteClose } from '../../../assets/images/dashboard/icons/icons-close-pink.svg';
import QuantityBox from './QuantityBox.js';
import http from '../../../services/HttpService';


const Message = ({handleMsgBack, setIncludeMsg, includeMsg, formik, showSummary, setShowSummary, tileWidth, setSelectedCategory, setShowMessageScreen, setAddRecipientsShow, onSubmit}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const [audioModel, setAudioModel] = useState(false);
    const [videoModel, setVideoModel] = useState(false);
    const [audioData, setAudioData] = useState();
    const [videoData, setVideoData] = useState();
    const [messageType, setMessageType] = useState(1);
    const [showMessageBox, setshowMessageBox] = useState(false);
    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [showPreferences, setShowPreferences] = useState(false);
    const [msg, setMsg] = useState(true);
    const [txtMsg, setTxtMsg] = useState(false);

    const [audioFile, setAudioFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [thumpFile, setThumpFile] = useState(null);
    const [thumpFileName, setThumpFileName] = useState(null);
    const [loadingUploadBtn, setLoadingUploadBtn] = useState(false);
    const [existingMediaData, setExistingMediaData] = useState();
    const [checkVideoIsUploaded, setCheckVideoIsUploaded] = useState(false);

    const [showMoreRecipients, setShowMoreRecipients] = useState(false);

    const toggleShowMore = () => {
      setShowMoreRecipients(!showMoreRecipients);
    };

    const recipientsToDisplay = showMoreRecipients
    ? formik.values.recipients
    : formik.values.recipients.slice(0, 2);

    const userData = JSON.parse(localStorage.getItem("userData"));
    
    const audioModalRef = useRef(null);
    const videoModalRef = useRef(null);

    const skipMsg = () => {
      setShowPreferences(true);
    }

    const loadVideo = file =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject('Invalid video. Please select a video file.');
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

    // Getting cover image for uploaded video
  const getVideoCover = (file, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', ex => {
        reject('error when loading video file', ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject('video is too short.');
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas');
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            'image/jpeg',
            0.95 /* quality */
          );
        });
      });
    });
  };


    const blobToFile = (theBlob, fileName) => {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      theBlob.name = fileName;
      theBlob.lastModifiedDate = Date.now();
  
      return theBlob;
    };

    // Getting uploaded media
  const getusersListFile = async e => {
    formik.setFieldValue('messageValue', null);
    let fileName = e.currentTarget.files[0].name;
    var allowedExtensions = /(\.mov|\.mp4|\.mp3|\.AVI|\.WEBM|\.WAV|\.AVC)$/i;
    if (!allowedExtensions.exec(fileName)) {
      toast.error('Please upload file having extensions .mov/.mp4/.mp3/.AVI/.WEBM/.WAV only.');
      return false;
    } else {
      let file = e.currentTarget.files[0];
      const video = await loadVideo(file);
      let duration = video.duration;
      if (duration <= 15) {
        var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV|\.AVC)$/i;
        if (!allowedExtensionsForVideo.exec(fileName)) {
          let userData = JSON.parse(localStorage.getItem('userData'));
          let audioFileName = `${Date.now()}_${userData.userName}_audio.mp3`;
          setAudioFile(file);
          setFileName(audioFileName);
          setLoadingUploadBtn(false);
        } else {
          const cover = await getVideoCover(file, 1.5);
          // print out the result image blob
          let userData = JSON.parse(localStorage.getItem('userData'));
          let videoFileName = `${Date.now()}_${userData.userName}_video.mpeg`;
          let thumpFileName = `${Date.now()}_${userData.userName}_image.jpg`;
          var myThumpFile = blobToFile(cover, thumpFileName);
          setThumpFile(myThumpFile);
          setVideoFile(file);
          setThumpFileName(thumpFileName);
          setFileName(videoFileName);
          setLoadingUploadBtn(false);
        }
      } else {
        toast.error('Please upload a 15 sec duration video');
      }
    }
  };
    // Uploading media data
  const uploadBtnHandler = () => {
    if (fileName !== null) {
      setLoadingUploadBtn(true);
      var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV|\.mpeg|\.AVC)$/i;
      if (!allowedExtensionsForVideo.exec(fileName)) {
        var formData = new FormData();
        formData.append('fileName', fileName);
        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myAudioFile = new File([audioFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myAudioFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                let data = response['data'];
                setLoadingUploadBtn(false);
                setAudioData(data);
                // hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setVideoData(undefined);
              })
              .catch(error => {
                if (error) {
                  setLoadingUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setLoadingUploadBtn(false);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      } else {
        var formData = new FormData();
        formData.append('fileName', fileName);
        if(thumpFile && thumpFileName !== null) {
          formData.append('imagePreviewFile', thumpFile, thumpFileName)
        }

        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myVideoFile = new File([videoFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myVideoFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                setLoadingUploadBtn(false);
                let data = response['data'];
                setVideoData(data);
                // hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setAudioData(undefined);
                setShowPreferences(true);
                setShowSummary(false);
                setMsg(false);
                setIncludeMsg(false);
              })
              .catch(error => {
                if (error) {
                  setLoadingUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setLoadingUploadBtn(false);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      }
    } else {
      toast.error('Please upload a file');
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

    useEffect(() => {
        if (selectedOption == "message") {
          const timeoutId = setTimeout(() => {
            // Navigate to the NextScreen component
            setSelectedOption('');
            setIncludeMsg(true);
            setMsg(false);
          }, 1000); // 1000ms delay before navigation
    
          return () => clearTimeout(timeoutId); // Clear the timeout if component unmounts or selectedOption changes
        } else if(selectedOption == "no message"){
          const timeoutId = setTimeout(() => {
            // Navigate to the NextScreen component
            setSelectedOption('');
            setIncludeMsg(false);
            skipMsg();
            setMsg(false);
          }, 1000); // 1000ms delay before navigation
    
          return () => clearTimeout(timeoutId); // Clear the timeout if component unmounts or selectedOption changes
        }
    }, [selectedOption]);
    
    const onImgClick = (value) => {
      setSelectedOption(value);
    }

    const handleTxtMsg = (value) => {
      setTxtMsg(value);

      value ? formik.setFieldValue('message.type', "text") : formik.setFieldValue('message.type', "video")
    }
    const messageButtonHandler = () => {
      setTxtMsg(true);
      setshowMessageBox(!showMessageBox);
      setMessageType(1);
      formik.setFieldValue('message.type', "text")
    };
  
    // Text message handler
  
    const textMessageCancelHandler = () => {
      setTxtMsg(false);
      setMessageType(1);
      setAudioData(undefined);
      setVideoData(undefined);
      setExistingMediaData(undefined);
  
      setshowMessageBox(!showMessageBox);
  
      formik.setFieldValue('message.type', "video")
      formik.setFieldValue('message.message', "")
    };

    const handleShowSummary =() => {
      setShowSummary(true); 
      setShowPreferences(false);
      setMsg(false);
      setIncludeMsg(false)
    }

    const handleSummaryBack = () => {
      setShowSummary(false);
      setShowPreferences(true);
      setMsg(false);
      setIncludeMsg(false);
    }
    const handlePreferencesBack = () => {
      setShowPreferences(false);
      setMsg(false);
      setIncludeMsg(true)
    }

    const handleShowPreferences = () => {
      if(!txtMsg) {
        uploadBtnHandler();
      } else {
        setShowPreferences(true);
        setShowSummary(false);
        setMsg(false);
        setIncludeMsg(false);
      }
    }

    const selectAudio = useCallback(() => {
      setshowMessageBox(true);
      setAudioModel(false);
    });

    const selectVideo = useCallback(() => {
      setshowMessageBox(true);
      setVideoModel(false);
    });

    const toggleVideoModal = useCallback(event => {
      setVideoModel(!videoModel);
  
      if (videoModel === false) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(() => {})
          .catch(err => {
            toast.error('Please enable microphone and camera access');
          });
      }
    });

    const toggleAudioModal = useCallback(event => {
      setAudioModel(!audioModel);
  
      if (audioModel === false) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {})
          .catch(err => {
            toast.error('Please enable microphone access');
          });
      }
    });

    const audioResponse = data => {
      setMessageType(3);
      formik.setFieldValue('messageValue', null);
      setVideoData(undefined);
      if (data !== undefined && data !== null) {
        setVideoData(undefined);
        setAudioData(data);
      }
    };

    const videoResponse = data => {
      setMessageType(2);
      formik.setFieldValue('messageValue', null);
      setAudioData(undefined);
      if (data !== undefined && data !== null) {
        setVideoData(data);
        formik.setFieldValue('message.type', "video");
        formik.setFieldValue('message.storedFileUrl', data.url);
        formik.setFieldValue('message.storedFile', data.guid);
        formik.setFieldValue('message.storedFileSecret', data.fileSecret);
        setFileName(data.fileName);
        setAudioData(undefined);
      }
    };

    const handleAcceptedFiles = (files) => {
      const file = files[0];
      formik.setFieldValue('message.type', "video");
      formik.setFieldValue('message.storedFile', file.name);
      setFileData(file.data);
      setFileName(file.name);
    };
  
    const handleRejectedFiles = (files) => {
      const file = files[0];
      setFileData(null);
      setFileName(null);
    }

    const hideFileHandler = () => {
      setFileName(null);
    }

    return (
        <div>
          {!includeMsg ? (
            <>
            {msg && !showPreferences &&<>
            {formik.values.quantity ? <QuantityBox quantity={formik.values.quantity} total={formik.values.total} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
              <div className='d-flex flex-column position-relative' style={{marginTop: '80px'}}>
                <span className="px-3 font-sofia-pro-regular font-32 color-black-pearl text-center">Would you like to include a <br/> message?</span>
              </div>
              <div className='d-flex justify-content-center align-items-center'>
                {selectedOption === "message" ? (
                  <div className={`position-relative`}>
                    <img src={ItemCheckedImage} height="25px" width="25px" className="select-msg_checked_icon" />
                  </div>
                ) : ''}
                <div className={`msg-box-style d-flex flex-column justify-content-center align-items-center ${selectedOption === "message" ? 'border-primary-color-2': ''}`} onClick={()=> {onImgClick("message")}}>
                  <SendMessage/>
                  <span className="font-sofia-pro-regular font-16 color-black-pearl text-center">Yes, include a message</span>
                </div>
                {selectedOption === "no message" ? (
                  <div className={`position-relative`}>
                    <img src={ItemCheckedImage} height="25px" width="25px" className="select-msg_checked_icon" />
                  </div>
                ) : ''}
                <div className={`msg-box-style d-flex flex-column justify-content-center align-items-center ${selectedOption === "no message" ? 'border-primary-color-2': ''}`} onClick={()=> {onImgClick("no message")}}>
                  <NoMessage/>
                  <span className="font-sofia-pro-regular font-16 color-black-pearl text-center">No, skip this step</span>
                </div>
              </div>
            </>}
            {showPreferences && !msg && !showSummary && <Preferences formik={formik} showLoader={showLoader} handleBack= {handlePreferencesBack} handleShowSummary={handleShowSummary}/>}
            {!showPreferences && !msg && showSummary && <Summary formik={formik} showLoader={showLoader} handleBack={handleSummaryBack} tileWidth = {tileWidth} setSelectedCategory={setSelectedCategory} setShowSummary={setShowSummary} setShowMessageScreen={setShowMessageScreen} setAddRecipientsShow={setAddRecipientsShow} setIncludeMsg={setIncludeMsg} setSelectedOption={setSelectedOption} setShowPreferences={setShowPreferences} onSubmit={onSubmit}/>}
            </>
          ) : (
          <>
          {formik.values.quantity ? <QuantityBox quantity={formik.values.quantity} total={formik.values.total} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
          <div className='d-flex flex-column position-relative' style={{marginTop: '80px'}}>
            <span className="font-sofia-pro-regular font-32 color-black-pearl text-center">Include a message</span>
          </div>
          <div className='mt-3 mb-5 d-flex justify-content-center align-items-center flex-column flex-wrap'>
            <div className='d-flex coffee-box-style justify-content-center'>
              <div className='d-flex flex-column align-items-start flex-wrap w-100'>
                <form className="mt-3 w-100" onSubmit={formik.handleSubmit}>
                  <div className="p-0 b-0 pb-1 form-group">
                      <input
                        type="text"
                        className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                        placeholder="hi, @firstname"
                        value={`hi, ${recipientsToDisplay.length == 1 ? recipientsToDisplay[0].employee.split(' ')[0] : 'Team'}`}
                        style={{pointerEvents: 'none'}}
                      />
                  </div>
                  {!txtMsg ?
                  <>
                  <div className="p-0 b-0 pb-1 form-group d-flex justify-content-between align-items-center">
                      <label className="font-sofia-pro-regular color-charcoal font-16">Message</label>
                      <div className='d-flex justify-content-between align-items-center'>
                          <div className='record-box d-flex justify-content-center align-items-center cursor-pointer' onClick={toggleVideoModal}>
                              <Record/>
                              <div className='font-sofia-pro-regular font-14' style={{marginLeft: '1rem'}}>Record</div>
                          </div>
                          <div className='txt-box d-flex justify-content-center align-items-center font-sofia-pro color-primary font-14 cursor-pointer' onClick={() => messageButtonHandler()}>Switch to text</div>
                      </div>
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                      <div className='w-100 d-flex flex-column justify-content-center align-items-center upload-box position-relative'>
                      {fileName === null && <VideoDropzone rejectedFiles= {handleRejectedFiles} acceptedFiles={handleAcceptedFiles} multiple={false} getusersListFile={getusersListFile} />}
                      {fileName !== null && (
                        <>
                        <DeleteClose onClick={hideFileHandler} className="cursor-pointer close-delete"/>
                        <FolderIcon />
                        <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                          <u>{fileName}</u>
                        </p>
                        </>
                      )}
                      </div>
                  </div>
                  </>: <>
                  <div className="p-0 b-0 pb-1 form-group d-flex justify-content-between align-items-center">
                      <label className="font-sofia-pro-regular color-charcoal font-16">Message</label>
                      <div className='d-flex justify-content-between align-items-center'>
                          <div className='txt-box d-flex justify-content-center align-items-center font-sofia-pro color-primary font-14 cursor-pointer' onClick={() => textMessageCancelHandler()}>Switch to video</div>
                      </div>
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                      {/* <div className='w-100 d-flex flex-column justify-content-center align-items-center upload-box'>
                      <input placeholder='Enter your message'/>
                      </div> */}
                      {/* <div className='w-100 d-flex flex-column justify-content-center align-items-center upload-box'>
                      </div> */}
                      <div className='w-100 position-relative txtMsg-box'>
                        <textarea
                          className='full-width-textarea'
                          placeholder='Enter your message'
                          name='message.message'
                          value={formik.values.message.message}
                          onChange={formik.handleChange}
                          maxLength={100}
                        ></textarea>
                        <div className='char-count'>{`${formik.values.message.message.length}/100`}</div>
                      </div>
                  </div>
                  </>}
                  
                  <div className="p-0 b-0 pb-1 d-flex justify-content-start">
                    <p className='font-sofia-pro-regular font-14 color-gunmetal'>
                      Recipients {recipientsToDisplay.map((recipient, index) => (
                        `${index === 0 ? '' : ','}${recipient.employee}`
                      ))}
                    </p>
                    {formik.values.recipients.length > 2 && (
                      <p className='font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={toggleShowMore}>
                        {showMoreRecipients ? `- ${formik.values.recipients.length - 2} less` : `+ ${formik.values.recipients.length - 2} more`}
                      </p>
                    )}
                  </div>
                  <div className="d-flex justify-content-between w-100">
                      <Button
                      classNameValue="twoBrand-back-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn font-16 font-sofia-pro-semi-bold"
                      children="Back"
                      showLoader={showLoader}
                      onClick={handleMsgBack}
                      />
                      <Button
                      classNameValue="twoBrand-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                      children="Next"
                      showLoader={loadingUploadBtn}
                      onClick={handleShowPreferences}
                      disabled={formik.values.message.message || fileName ? false: true}
                      />
                  </div>
                </form>
                <RecordAudio
                  ref={audioModalRef}
                  selectAudio={selectAudio}
                  audioModel={audioModel}
                  toggleAudioModal={toggleAudioModal}
                  toggleVideoModal={toggleVideoModal}
                  audioResponse={audioResponse}
                />
                <RecordVideo
                  ref={videoModalRef}
                  selectVideo={selectVideo}
                  videoModel={videoModel}
                  toggleAudioModal={toggleAudioModal}
                  toggleVideoModal={toggleVideoModal}
                  videoResponse={videoResponse}
                />
              </div>
          </div>
          </div>

          </>
          )}
        </div>
      );
      
      
}

export default Message;
