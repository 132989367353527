/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import http from '../../../../../services/HttpService';
import LocationService from '../../../../../services/LocationService';
import * as RegexPattern from '../../../../../utils/RegexPatterns';
import ErrorImage from '../../../../../assets/images/error-icons/icons-error-info.png';
import Header from '../../../../../common/Header/Header';
import AccountDetailsService from '../../../../../services/AccountDetailsServices';
import Button from '../../../../../common/Button/Button';
import SideBar from '../../../../../common/Sidebar/Sidebar';
import ButtonBack from '../../../../../common/Button/ButtonBack';
import './RedeemDetails.scss';


const PersonalDetails = ({setRedeemedGift}) => {
  const httpService = http;
  const history = useHistory();
  const ProductId = sessionStorage.getItem('GiftProductId');
  const Quantity = sessionStorage.getItem('SelectedQuantity');
  const [addressSelect, setAddressSelect] = useState(false);
  const [address, setAddress] = useState();
  const [query, setQuery] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const autoCompleteRef = useRef(null);
  const TransactionId = sessionStorage.getItem('TransactionId')

  const [account, setAccount] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AccountDetailsService.GetACCOuntDetails().then(res => {
      setAccount(res.data);
      formik.values.email = res.data['email'];
      formik.values.mobile = res.data['phone'];
    });
  }, []);

  useEffect(() => {
    if (account['email'] !== null && account['email'] !== undefined) {
      formik.values.email = account['email'].trim();
    }
    if (account['phone'] !== null && account['phone'] !== undefined) {
      formik.values.mobile = account['phone'].trim();
    }
  }, []);

  const initialValues = {
    email: null,
    mobile: null,
    street: null,
    suburb: null,
    state: null,
    postcode: null,
    country: null,
    query: null
  };

  // use google map for address auto completion
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, [query]);

  const validate = values => {
    const errors = {};
    let ph = RegexPattern.MobileRegexValue;
    let re = RegexPattern.EmailRegexValue;

    if (!values.email) {
      errors.email = 'Required';
    } else if (!re.test(values.email)) {
      errors.email = 'Invalid email format';
    }

    if (values.mobile === null) {
      formik.setFieldValue('mobile', '+61402981808');
    }else if (!ph.test(values.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }

    if (!values.postcode) {
      errors.postcode = 'Required';
    }

    if (!values.street) {
      errors.street = 'Required';
    }

    if (!values.suburb) {
      errors.suburb = 'Required';
    }

    if (!values.state) {
      errors.state = 'Required';
    }

    if (!values.query){
      errors.query = 'Required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: RedeemBeer
  });

  // spliting location based on limit
  useEffect(()=>{
    if (address !== undefined && addressSelect !== true) {
      formik.values.street = LocationService.getLocationAddress(address).street;
      formik.values.suburb = LocationService.getLocationAddress(address).city;
      formik.values.state = LocationService.getLocationAddress(address).state;
      formik.values.postcode = LocationService.getLocationAddress(address).postCode;
    }
  },[address, formik, addressSelect])


  let autoComplete = [];

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.setComponentRestrictions({
      country: ['au']
    });
    autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
  }

  function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setAddress(autoComplete.getPlace());
    setAddressSelect(true);
  }

  function RedeemBeer() {
    let data = Address;

    if (
      formik.values.mobile !== null &&
      formik.values.mobile !== undefined &&
      formik.values.mobile !== '' &&
      formik.values.email !== null &&
      formik.values.email !== undefined &&
      formik.values.email !== '' &&
      ProductId !== null &&
      ProductId !== undefined &&
      formik.values.postcode !== null &&
      formik.values.postcode !== undefined &&
      formik.values.postcode.length > 0 &&
      formik.values.street !== null &&
      formik.values.street !== undefined &&
      formik.values.street.length > 0 &&
      formik.values.suburb !== null &&
      formik.values.suburb !== undefined &&
      formik.values.suburb.length > 0 &&
      formik.values.state !== null &&
      formik.values.state !== undefined &&
      formik.values.suburb.length > 0 &&
      formik.values.mobile.trim().length > 0
    ) {
      setShowLoader(true);
      httpService
        .post(`Gifts/${TransactionId}/redeem`, data)
        .then(res => {
          history.push('/redeemedbeer');
          setRedeemedGift(true)
          window.scrollTo(0, 0);
          setShowLoader(false);
        })
        .catch(error => {
          setShowLoader(false);
          let errorResponseGlobalErrors = error.response?.data.globalErrors;
          let errorResponse = error.response?.data;
          if (errorResponse?.globalErrors[0]) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          } else if (errorResponse?.fieldErrors) {
            if (errorResponse.fieldErrors['delivery.Phone']) {
              toast.error(errorResponse.fieldErrors['delivery.Phone'][0]);
            } else if (errorResponse.fieldErrors['delivery.Email']) {
              toast.error(errorResponse.fieldErrors['delivery.Email'][0]);
            }
          } else {
            console.log(error);
          }
        });
    }else if (formik.values.mobile !== null && formik.values.mobile !== undefined) {
      if (!formik.values.mobile.match(RegexPattern.AusMobileRegexValue)) {
        toast.error('Please enter a valid mobile number');
      }
    }else if (formik.values.email !== null && formik.values.mobile !== undefined) {
      if (!formik.values.email.match(RegexPattern.EmailRegexValue)) {
        toast.error('Please enter a valid email');
      }
    }else if (formik.values.email === null || formik.values.email === undefined) {
      toast.error('Email is required');
    } else if (formik.values.mobile === null || formik.values.mobile === undefined || formik.values.mobile === '') {
      formik.setFieldValue('mobile', '+61402981808');
    }  else if (
      formik.values.street === null ||
      formik.values.street === undefined ||
      formik.values.street.length <= 0
    ) {
      toast.error('Please add a street');
    } else if (
      formik.values.postcode === null ||
      formik.values.postcode === undefined ||
      formik.values.postcode.length <= 0
    ) {
      toast.error('Please add a postcode');
    } else if (
      formik.values.suburb === null ||
      formik.values.suburb === undefined ||
      formik.values.suburb.length <= 0
    ) {
      toast.error('Please add a suburb');
    } else if (formik.values.state === null || formik.values.state === undefined || formik.values.state.length <= 0) {
      toast.error('Please add a state');
    }
  }

  let Address;
  useEffect(() => {
    Address = {
      quantity: Quantity,
      rangeId: ProductId,
      delivery: {
        email: formik.values.email,
        phone: formik.values.mobile,
        address: {
          street: formik.values.street,
          suburb: formik.values.suburb,
          state: formik.values.state,
          postcode: formik.values.postcode,
          country: 'Australia',
          region: 'AU'
        }
      }
    };
  });

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0  col-lg-2 col-sm-12 my-4  ">
        <SideBar menu="Redeemthanks" />
      </div>
      <div
        className={
          addressSelect === false
            ? ' p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half'
            : ' p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half h-100vh'
        }
      >
        <Header value="Redeemed" />
        <ButtonBack
          from='redeem-details'
          to='select-product'
        />

        <span className="personaldetail-text__Enteryourdetails font-sofia-pro-light mx-3">Enter your details</span>
        <div className="d-flex flex-column addressdetails-rectangle__big m-3">
          <div className=' m-0 p-0 row '>
            <div
              className='p-0 m-0   d-flex flex-column'
            >
              <form onSubmit={formik.handleSubmit}>
                <div className='d-flex flex-column addressdetails-rectangle__big'>
                  <span className="personaldetails-text__Personal-details font-sofia-pro-semi-bold m-3">Personal details</span>

                  <div className="mx-3 d-flex flex-column ">
                    <label className="personaldetails-text__headername font-sofia-pro-regular ">Email address</label>
                    <div className={`personaldetails-input d-flex  align-items-center my-1 ${formik.touched.email && formik.errors.email ? 'border-primary-color':''}`}>
                      <input
                        placeholder="Eg: hello@sanjaytarani.com"
                        type="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        defaultValue={account['email']}
                        className="border-0 mx-1 w-100"
                        autoComplete="off"
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  <div className="mx-3 d-flex flex-column mt-3">
                    <label className="personaldetails-text__headername font-sofia-pro-regular ">Mobile number</label>
                    <div className={`personaldetails-input d-flex  align-items-center my-1 ${formik.touched.mobile && formik.errors.mobile ? 'border-primary-color':''}`}>
                      <input
                        className="border-0 mx-1 w-100"
                        placeholder='Eg: 0478983456'
                        type='mobile'
                        name='mobile'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}
                        autoComplete='off'
                      />
                    </div>
                    {formik.touched.mobile && formik.errors.mobile ? (
                      <div className='mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular'>
                        <img className='mr-1' src={ErrorImage} /> {formik.errors.mobile}
                      </div>
                    ) : null}
                  </div>

                  <span className="personaldetails-text__Personal-details font-sofia-pro-semi-bold mx-3 mt-3 mb-1">Delivery details</span>
                  {addressSelect !== false ? (
                    <div>
                      <div className="mx-3 d-flex flex-column mt-3">
                        <label className="personaldetails-text__headername font-sofia-pro-regular ">Street name</label>
                        <div className={`personaldetails-input d-flex  align-items-center my-1 ${formik.touched.street && formik.errors.street ? 'border-primary-color':''}`}>
                          <input
                            type='text'
                            placeholder='Eg: The Corso  '
                            className="border-0 mx-1 w-100"
                            name='street'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.street}
                            autoComplete='off'
                          />
                        </div>
                        {formik.touched.street && formik.errors.street ? (
                          <div className='mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular'>
                            <img className='mr-1' src={ErrorImage} /> {formik.errors.street}
                          </div>
                        ) : null}
                      </div>

                      <div className="mx-3 d-flex flex-column mt-3">
                        <label className="personaldetails-text__headername font-sofia-pro-regular ">Suburb</label>
                        <div className={`personaldetails-input d-flex  align-items-center my-1 ${formik.touched.suburb && formik.errors.suburb ? 'border-primary-color':''}`}>
                          <input
                            type='text'
                            name='suburb'
                            className="border-0 mx-1 w-100"
                            placeholder='Eg: Manly'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.suburb}
                            autoComplete='off'
                          />
                        </div>
                        {formik.touched.suburb && formik.errors.suburb ? (
                          <div className='mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular'>
                            <img className='mr-1' src={ErrorImage} /> {formik.errors.suburb}
                          </div>
                        ) : null}
                      </div>

                      <div className="mx-3 d-flex flex-column mt-3">
                        <label className="personaldetails-text__headername font-sofia-pro-regular ">State / Province / Region</label>
                        <div className={`personaldetails-input d-flex  align-items-center my-1 ${formik.touched.state && formik.errors.state ? 'border-primary-color':''}`}>
                          <input
                            placeholder='Eg: NSW '
                            className="border-0 mx-1 w-100"
                            type='text'
                            name='state'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                            autoComplete='off'
                          />
                        </div>
                        {formik.touched.state && formik.errors.state ? (
                          <div className='mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular'>
                            <img className='mr-1' src={ErrorImage} /> {formik.errors.state}
                          </div>
                        ) : null}
                      </div>

                      <div className="mx-3 d-flex flex-column mt-3">
                        <label className="personaldetails-text__headername font-sofia-pro-regular ">Postcode</label>
                        <div className={`personaldetails-input d-flex  align-items-center my-1 ${formik.touched.postcode && formik.errors.postcode ? 'border-primary-color':''}`}>
                          <input
                            placeholder='Eg: 2122'
                            className="border-0 mx-1 w-100"
                            type='text'
                            name='postcode'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.postcode}
                            autoComplete='off'
                          />
                        </div>
                        {formik.touched.postcode && formik.errors.postcode ? (
                          <div className='mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular'>
                            <img className='mr-1' src={ErrorImage} /> {formik.errors.postcode}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className='d-flex flex-column align-items-end'>

                      <div className="mx-3 d-flex flex-column mt-3">
                        <div className={`personaldetails-input d-flex  align-items-center my-1 ${formik.touched.query && formik.errors.query ? 'border-primary-color':''}`}>
                          <input
                            className="border-0 mx-1 w-100"
                            ref={autoCompleteRef}
                            name='query'
                            onChange={e => setQuery(e.target.value)}
                            placeholder='Eg: 6, The Corso Manly, Sydney, NSW, 2122'
                            value={query}
                            autoComplete='off'
                          />
                        </div>

                        {formik.touched.query && formik.errors.query ? (
                          <div className='mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular'>
                            <img className='mr-1' src={ErrorImage} /> {formik.errors.query}
                          </div>
                        ) : null}
                      </div>

                      <button
                        className='redeemnow-button__entermanualy m-3 '
                        onClick={() => {
                          setAddressSelect(!addressSelect);
                        }}
                      >
                        <span className='redeemnow-text__enterman font-sofia-pro-semi-bold  '>Enter Manually</span>
                      </button>
                    </div>

                  )}
                  <Button
                    classNameValue={'border-0 addressdetails-button__proceed m-3 bg-primary-color color-white'}
                    showLoader={showLoader}
                    onClick={RedeemBeer}
                  >
                    <span className='redeem-text__proceed font-sofia-pro-semi-bold'>Redeem</span>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
