/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import TourImage1 from '../../../assets/images/dashboard/tour/merchant-tour-1.png';
import TourImage2 from '../../../assets/images/dashboard/tour/merchant-tour-2.png';
import TourImage3 from '../../../assets/images/dashboard/tour/merchant-tour-3.png';
import TourImage4 from '../../../assets/images/dashboard/tour/merchant-tour-4.png';
import TourImage5 from '../../../assets/images/dashboard/tour/merchant-tour-5.png';

import './Dashboard.scss';

const MerchantTourPage = props => {
  const Home = () => {
    return (
      <div>
        <div className="p-0 m-0 row">
          <div className="p-0 m-0 p-4 px-5 col-sm-4 col-md-4 col-lg-4 d-flex flex-column justify-content-between">
            <div>
              <span className="font-sofia-pro-bold font-24 color-black-pearl">Take tour</span>
              <ul className="p-0 m-0 m-4 list-style-none">
                <li className="mt-2 d-flex font-sofia-pro-semi-bold font-16 color-primary">
                  <div className="merchant-dashboard__selected__menu"></div>
                  <span className="merchant-dashboard__selected__menu__label">Home</span>
                </li>
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Transactions</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Payouts</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Store</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Account</li>
              </ul>
            </div>
            <div className="text-center mb-2">
              <span className="color-gunmetal font-16  font-sofia-pro-regular" onClick={props.handleClose}>
                Don’t show again
              </span>
            </div>
          </div>
          <div className="p-0 m-0 p-4 col-sm-8 col-md-8 col-lg-8 bg-alice-blue border-right-radius-40">
            <div>
              <img src={TourImage1} height="250px" />
              <div className="mt-3">
                <span className="font-18 font-sofia-pro-bold color-black-pearl">Dashboard</span>
                <p className="mt-2 font-14 font-sofia-pro-light color-black-pearl">
                  A high level view of activity on the platform, keep a track of new customers, transactions and much
                  more.
                </p>
              </div>
              <div>
                <button
                  className="btn bg-primary-color h-50 w-90 color-white font-sofia-pro-light font-16 border-radius-22 shadow-none"
                  onClick={props.nextBtnHandler.bind(this, 1)}
                >
                  Next
                </button>
                <span
                  className="ml-4 font-sofia-pro-light font-16 color-black-pearl cursor-pointer"
                  onClick={props.handleClose}
                >
                  Skip
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Transactions = () => {
    console.log('transactions ');
    return (
      <div>
        <div className="p-0 m-0 row">
          <div className="p-0 m-0 p-4 px-5 col-sm-4 col-md-4 col-lg-4 d-flex flex-column justify-content-between">
            <div>
              <span className="font-sofia-pro-bold font-24 color-black-pearl">Take tour</span>
              <ul className="p-0 m-0 m-4 list-style-none">
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Home</li>
                <li className="mt-2 d-flex font-sofia-pro-semi-bold font-16 color-primary">
                  <div className="merchant-dashboard__selected__menu"></div>
                  <span className="merchant-dashboard__selected__menu__label">Transactions</span>
                </li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Payouts</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Store</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Account</li>
              </ul>
            </div>
            <div className="text-center mb-2">
              <span className="color-gunmetal font-16  font-sofia-pro-regular" onClick={props.handleClose}>
                Don’t show again
              </span>
            </div>
          </div>
          <div className="p-0 m-0 p-4 col-sm-8 col-md-8 col-lg-8 bg-alice-blue border-right-radius-40">
            <img src={TourImage2} height="250px" />
            <div className="mt-3">
              <span className="font-18 font-sofia-pro-bold color-black-pearl">Transactions</span>
              <p className="mt-2 font-14 font-sofia-pro-light color-black-pearl">
                Keeping track of transaction activity was never this easier, total count and granular view of all the
                transactions.
              </p>
            </div>
            <div>
              <button
                className="btn bg-primary-color h-50 w-90 color-white font-sofia-pro-light font-16 border-radius-22 shadow-none"
                onClick={props.nextBtnHandler.bind(this, 2)}
              >
                Next
              </button>
              <span
                className="ml-4 font-sofia-pro-light font-16 color-black-pearl cursor-pointer"
                onClick={props.handleClose}
              >
                Skip
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Payouts = () => {
    return (
      <div>
        <div className="p-0 m-0 row">
          <div className="p-0 m-0 p-4 px-5 col-sm-4 col-md-4 col-lg-4 d-flex flex-column justify-content-between">
            <div>
              <span className="font-sofia-pro-bold font-24 color-black-pearl">Take tour</span>
              <ul className="p-0 m-0 m-4 list-style-none">
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Home</li>
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Transactions</li>
                <li className="mt-2 d-flex font-sofia-pro-semi-bold font-16 color-primary">
                  <div className="merchant-dashboard__selected__menu"></div>
                  <span className="merchant-dashboard__selected__menu__label">Payouts</span>
                </li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Store</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Account</li>
              </ul>
            </div>
            <div className="text-center mb-2">
              <span className="color-gunmetal font-16  font-sofia-pro-regular" onClick={props.handleClose}>
                Don’t show again
              </span>
            </div>
          </div>
          <div className="p-0 m-0 p-4 col-sm-8 col-md-8 col-lg-8 bg-alice-blue border-right-radius-40">
            <img src={TourImage3} height="250px" />
            <div className="mt-3">
              <span className="font-18 font-sofia-pro-bold color-black-pearl">Payouts</span>
              <p className="mt-2 font-14 font-sofia-pro-light color-black-pearl">
                Know when you’re being paid, daily, weekly or monthly. Customise the way payout cycle works
              </p>
            </div>
            <div>
              <button
                className="btn bg-primary-color h-50 w-90 color-white font-sofia-pro-light font-16 border-radius-22 shadow-none"
                onClick={props.nextBtnHandler.bind(this, 3)}
              >
                Next
              </button>
              <span
                className="ml-4 font-sofia-pro-light font-16 color-black-pearl cursor-pointer"
                onClick={props.handleClose}
              >
                Skip
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Store = () => {
    return (
      <div>
        <div className="p-0 m-0 row">
          <div className="p-0 m-0 p-4 px-5 col-sm-4 col-md-4 col-lg-4 d-flex flex-column justify-content-between">
            <div>
              <span className="font-sofia-pro-bold font-24 color-black-pearl">Take tour</span>
              <ul className="p-0 m-0 m-4 list-style-none">
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Home</li>
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Transactions</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Payouts</li>
                <li className="mt-2 d-flex font-sofia-pro-semi-bold font-16 color-primary">
                  <div className="merchant-dashboard__selected__menu"></div>
                  <span className="merchant-dashboard__selected__menu__label">Store</span>
                </li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Account</li>
              </ul>
            </div>
            <div className="text-center mb-2">
              <span className="color-gunmetal font-16  font-sofia-pro-regular" onClick={props.handleClose}>
                Don’t show again
              </span>
            </div>
          </div>
          <div className="p-0 m-0 p-4 col-sm-8 col-md-8 col-lg-8 bg-alice-blue border-right-radius-40">
            <img src={TourImage4} height="250px" />
            <div className="mt-3">
              <span className="font-18 font-sofia-pro-bold color-black-pearl">Store</span>
              <p className="mt-2 font-14 font-sofia-pro-light color-black-pearl">
                Add store locations available in different part of the city or state, add employees to share
                responsibilities and much more.
              </p>
            </div>
            <div>
              <button
                className="btn bg-primary-color h-50 w-90 color-white font-sofia-pro-light font-16 border-radius-22 shadow-none"
                onClick={props.nextBtnHandler.bind(this, 4)}
              >
                Next
              </button>
              <span
                className="ml-4 font-sofia-pro-light font-16 color-black-pearl cursor-pointer"
                onClick={props.handleClose}
              >
                Skip
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Account = () => {
    return (
      <div>
        <div className="p-0 m-0 row">
          <div className="p-0 m-0 p-4 px-5 col-sm-4 col-md-4 col-lg-4 d-flex flex-column justify-content-between">
            <div>
              <span className="font-sofia-pro-bold font-24 color-black-pearl">Take tour</span>
              <ul className="p-0 m-0 m-4 list-style-none">
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Home</li>
                <li className="mt-2 font-sofia-pro-light  font-16 color-black-pearl">Transactions</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Payouts</li>
                <li className="mt-2 font-sofia-pro-light font-16 color-black-pearl">Store</li>
                <li className="mt-2 d-flex font-sofia-pro-semi-bold font-16 color-primary">
                  <div className="merchant-dashboard__selected__menu"></div>
                  <span className="merchant-dashboard__selected__menu__label">Account</span>
                </li>
              </ul>
            </div>
            <div className="text-center mb-2">
              <span className="color-gunmetal font-16  font-sofia-pro-regular" onClick={props.handleClose}>
                Don’t show again
              </span>
            </div>
          </div>
          <div className="p-0 m-0 p-4 col-sm-8 col-md-8 col-lg-8 bg-alice-blue border-right-radius-40">
            <img src={TourImage5} height="250px" />
            <div className="mt-3">
              <span className="font-18 font-sofia-pro-bold color-black-pearl">Account</span>
              <p className="mt-2 font-14 font-sofia-pro-light color-black-pearl">
                Update your business, payment and personal information from account. Adjust your payouts to daily,
                weekly or monthly based on your preference.
              </p>
            </div>
            <div>
              <button
                className="btn bg-primary-color h-50 w-90 color-white font-sofia-pro-light font-16 border-radius-22 shadow-none"
                onClick={props.nextBtnHandler.bind(this, 5)}
              >
                I’m done
              </button>
              <span
                className="ml-4 font-sofia-pro-light font-16 color-black-pearl cursor-pointer"
                onClick={props.handleClose}
              >
                Skip
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {props.menuIndex === 0 && Home()}
      {props.menuIndex === 1 && Transactions()}
      {props.menuIndex === 2 && Payouts()}
      {props.menuIndex === 3 && Store()}
      {props.menuIndex === 4 && Account()}
    </div>
  );
};

export default MerchantTourPage;
