/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Stepper from '../../../../common/Stepper/Stepper';
import OtpPage from './otp/Otp';
import SetUsernamePage from './setUsername/SetUsername';
import UserDetailsPage from './userDetails/UserDetails';
import SharePage from './share/Share';
import './Stepper.scss';
import IconsCheckFilledImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import { ReactComponent as Logo } from '../../../../assets/images/logo/logo-colour.svg';


class StepperPage extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: 'Verify account',
          icon: IconsCheckFilledImage,
        },
        {
          title: 'User details',
          icon: IconsCheckFilledImage,
        },
        {
          title: 'Create username',
          icon: IconsCheckFilledImage,
        },
        {
          title: 'Share',
          icon: IconsCheckFilledImage,
        },
      ],
      currentStep: 0,
      isNameNotAvailable: false,
    };
    this.onClickNext = this.onClickNext.bind(this);
  }

  componentDidMount() {
    const { match, location, history } = this.props;

    if (location.state) {
      console.log('location state ', location.state);
      if (location.state.screenValue === 1) {
        // window.location.reload();
        this.onClickNext();
      }
      if (location.state.checkFirstAndLastNameAvailableOrNot) {
        this.setState({
          isNameNotAvailable: true,
        });
      }
    }
  }

  onClickNext() {
    const { steps, currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  }

  render() {
    const { steps, currentStep } = this.state;

    return (
      <div className="bg-alice-blue h-100vh">
        <div className="p-0 m-0 p-3 pl-4 bg-white">
          <Link to={'/login'}>
            <Logo />
          </Link>
        </div>
        <div className="p-0 m-0 row pt-3  d-flex justify-content-center">
          <div className="p-0 m-0 col-lg-3">&nbsp;</div>
          <div className="m-0 card col-sm-12 col-md-6 col-lg-6 border-0">
            <Stepper steps={steps} activeStep={currentStep} />
          </div>
          <div className="p-0 m-0 col-lg-3">&nbsp;</div>
        </div>
        <div className="mx-2">
          {this.state.currentStep === 0 ? (
            <div>
              <OtpPage step={1} moveToNextScreen={this.onClickNext} />
            </div>
          ) : (
            ''
          )}
          {this.state.currentStep === 1 ? (
            <div>
              <UserDetailsPage
                isNameNotAvailable={this.state.isNameNotAvailable}
                step={3}
                moveToNextScreen={this.onClickNext}
              />
            </div>
          ) : (
            ''
          )}
          {this.state.currentStep === 2 ? (
            <div>
              <SetUsernamePage step={3} moveToNextScreen={this.onClickNext} />
            </div>
          ) : (
            ''
          )}
          {this.state.currentStep === 3 ? (
            <div>
              <SharePage moveToNextScreen={this.onClickNext} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

export default StepperPage;
