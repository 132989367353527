/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { Bar } from 'react-chartjs-2';
import { Modal } from 'react-bootstrap';
import OrganisationSidebar from '../organisationsidebar/organisationsidebar.js';
import OrganisationHeader from '../organisationheader/organisationHeader';
import DashboardService from '../../../services/DashboardService';
import SendThanksService from '../../../services/SendThanksService';
import httpService from '../../../services/HttpService';
import './OrganisationDashboard.scss';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as Components1 } from '../../../assets/images/dashboard/icons/components-illustrations-empty-state-2.svg';
import { ReactComponent as Components2 } from '../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';
import { ReactComponent as CaretDown } from '../../../assets/images/dashboard/icons/icons-caret-down.svg';
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import useWindowDimensions from '../../../common/Responsive/WindowDimensions';
import AccountDetailsServices from '../../../services/AccountDetailsServices.js';
import { toast } from 'react-toastify';

const OrganisationDashboard = () => {
  const { width } = useWindowDimensions();

  let userData = JSON.parse(localStorage.getItem('userData'));
  let data = { fullname: userData.fullName, type: 'FullName' };
  const Name = data.fullname;
  const [active, setActive] = useState(JSON.parse(sessionStorage.getItem('greeting')));
  const [dashboardData, setDashboardData] = useState([]);
  const [product, setProduct] = useState([]);
  const [showGraph, setshowGraph] = useState('daily');
  const [sentData, setsentData] = useState(null);
  const [label, setLable] = useState([]);
  const [btnShow, setBtnShow] = useState(false);
  const [dayShow, setdayShow] = useState(true);
  const [monthShow, setmonthShow] = useState(false);
  const [yearShow, setyearShow] = useState(false);
  const [selectFile, setSelectFile] = useState(null);
  const [selectSecretFile, setSelectSecretFile] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [audioVideoUrl, setAudioVideoUrl] = useState(null);
  const [availableCredits, setAvailableCredits] = useState(null);
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    httpService.get('Companies/current').then(res => {
      setAccountData(res.data);
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setActive(false);
      sessionStorage.setItem('greeting', false);
    }, 5000);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    AccountDetailsServices.GetACCOuntDetails()
      .then(res => {
        if (res) {
          // setIsDoxAI(res.data.isDoxAI);
          let user = JSON.parse(localStorage.getItem('userData'));
          user['isDoxAI'] = res?.data?.isDoxAI;
          console.log('user', user)
          localStorage.setItem('userData', JSON.stringify(user));

          // setUserData(res['data']);
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  }, []);

  useEffect(() => {
    if (selectFile && selectSecretFile != null) {
      GetStoredFile();
    }
  });

  const YearlyShow = () => {
    setBtnShow(!btnShow);
    GraphCall('yearly');
    setmonthShow(false);
    setdayShow(false);
    setyearShow(true);
  };
  const MonthlyShow = () => {
    setBtnShow(!btnShow);
    GraphCall('monthly');
    setmonthShow(true);
    setdayShow(false);
    setyearShow(false);
  };
  const DailyShow = () => {
    setBtnShow(!btnShow);
    GraphCall('daily');
    setmonthShow(false);
    setdayShow(true);
    setyearShow(false);
  };

  const GetStoredFile = () => {
    httpService
      .get(`StoredFiles/${selectFile}`, {
        headers: {
          fileSecret: selectSecretFile,
        },
      })
      .then(res => {
        setAudioVideoUrl(res.data['url']);
      });
  };

  const GetAvailableBalance = () => {
    httpService.get('companies/current/credits').then(res => {
      setAvailableCredits(res.data['balance']);
    });
  };

  const timeShow = () => {
    setBtnShow(!btnShow);
  };

  useEffect(() => {
    DashboardDataList();
    GetAvailableBalance();
    GraphCall(showGraph);
    SendServiceServiceList();
  }, []);

  const GraphCall = count => {
    DashboardService.organisationGetGraphDataDaily(count).then(res => {
      setsentData(
        res.data['sent'].map(val => {
          return val['number'];
        })
      );
      setLable(
        res.data['sent'].map(val => {
          if (count === 'daily') {
            return Moment(val['date']).format('DD/M/YY');
          } else if (count === 'yearly') {
            return Moment(val['date']).format('YYYY');
          } else if (count === 'monthly') {
            return Moment(val['date']).format('MMMM');
          }
        })
      );
    });
  };
  const DashboardDataList = () => {
    httpService
      .get('Dashboards/organisation')
      .then(res => {
        setDashboardData(res.data);
      })
      .catch(error => {
        if (error) {
          console.log(error);
        }
      });
  };

  product.map(value => {
    if (dashboardData['lastThreeTransactions'] !== null && dashboardData['lastThreeTransactions'] !== undefined) {
      dashboardData['lastThreeTransactions'].map((val, index) => {
        if (value['price']?.priceId === val['priceId']) {
          dashboardData['lastThreeTransactions'][index]['Type'] = value['type'];
        }
      });
    }
  });

  const SendServiceServiceList = () => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        setProduct(
          res.data.map(val => {
            return val;
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  const AudioVideoMessagePopup = props => {
    return (
      <Modal {...props} size="md" centered className="redeemwine-background_popup" animation={false} backdrop="static">
        <Modal.Body className="my-3">
          <button className="border-0 bg-transparent redeemthanks-cancel__button " onClick={props.onHide}>
            <Close />
          </button>
          <div className=" transactionhistory-card__message d-flex align-items-center justify-content-center ">
            {messageType === 'Audio' ? (
              <audio controls className="">
                <source src={audioVideoUrl} type="audio/mp3" className="reseemthanks-audio__style" />
              </audio>
            ) : messageType === 'Video' ? (
              <video controls width="150">
                <source src={audioVideoUrl} className="reseemthanks-audio__style" />
              </video>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const Graph = () => {
    const data = {
      labels: label,

      datasets: [
        {
          label: 'Sent',
          data: sentData,
          backgroundColor: '#e76fb5',
        },
      ],
    };

    const options = {
      hover: { mode: null },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              padding: 10,
            },
            gridLines: {
              color: '#e9ebf1',
              drawTicks: false,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              padding: 10,
              fontSize: 12,
            },
            gridLines: {
              color: '#e9ebf1',
              drawTicks: false,
            },
          },
        ],
      },
      legend: false,
      animation: false,
      cornerRadius: 12,

      tooltips: {
        backgroundColor: '#ffffff',
        titleFontColor: '#231f20',
        titleFontStyle: 'font-sofia-pro-regular',
        bodyFontColor: '#8597a0',
        bodyFontStyle: 'font-sofia-pro-light',
        titleFontSize: 14,

        callbacks: {
          title: function (tooltipItems, data) {
            return tooltipItems[0]['value'];
          },
          label: function (tooltipItems, data) {
            return data.datasets[tooltipItems['datasetIndex']]['label'];
          },
        },
      },
    };

    return (
      <div>
        <Bar data={data} options={options} height={262} width={686} />
      </div>
    );
  };
  const AudioMessageShow = index => {
    setSelectFile(dashboardData['lastThreeTransactions'][index]['message']['storedFile']);
    setSelectSecretFile(dashboardData['lastThreeTransactions'][index]['message']['storedFileSecret']);
    setMessageType(dashboardData['lastThreeTransactions'][index]['message']['type']);
    setShowModal(true);
  };

  const Transaction = () => {
    if (
      dashboardData['lastThreeTransactions'] !== null &&
      dashboardData['lastThreeTransactions'] !== undefined &&
      dashboardData['lastThreeTransactions'].length > 0
    ) {
      return (
        <div className="p-0 m-0 row mx-3">
          <div className=" col-sm-12 col-md-12 col-lg-8 ">
            <div className=" border-0 dashboard-transaction_card">
              <div className="d-flex flex-row justify-content-between  py-2">
                <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
                <Link className=" my-1 px-2 py-1" style={{ textDecoration: 'none' }} to="/admin/transactionhistory">
                  <span className="dashboard-text_viewall font-sofia-pro-regular ">View all</span>
                </Link>
              </div>
              <div className="dashboard-line__latesttransaction my-2" />
              <div className="table-responsive ">
                <table className="table">
                  <thead>
                    <tr>
                      <td scope="col" className="font-sofia-pro-light  border-0">
                        User information
                      </td>
                      <td scope="col" className="font-sofia-pro-light  border-0">
                        Date sent
                      </td>
                      <td scope="col" className="font-sofia-pro-light border-0 ">
                        Type
                      </td>
                      <td scope="col" className="font-sofia-pro-light border-0 ">
                        Product
                      </td>

                      <td scope="col" className="font-sofia-pro-light border-0">
                        Note
                      </td>
                    </tr>
                  </thead>

                  <tbody>
                    <div className="dashboard-line__latesttransaction " />

                    {dashboardData['lastThreeTransactions'].map((value, index) => {
                      var stilUtc = Moment.utc(value['date']).toDate();
                      var local = Moment(stilUtc).local();
                      return (
                        <tr key={index}>
                          <td scope="row" className="border-0">
                            <div className="d-flex flex-row  ">
                              {value['user'] !== null ? (
                                value['user']['profilePhotoUrl'] === null ? (
                                  <ProfilePic className="dashboard-oval_transaction " />
                                ) : (
                                  <img
                                    src={value['user']['profilePhotoUrl'] + '?h=15&w=15'}
                                    className="dashboard-image__Oval"
                                  />
                                )
                              ) : (
                                <ProfilePic className="dashboard-oval_transaction " />
                              )}
                              <div className="d-flex flex-column">
                                <span className="dashboard-name_transaction px-2 font-sofia-pro-medium">
                                  {value['user'] !== null
                                    ? value['user']['fullName'] != ''
                                      ? value['user']['fullName']
                                      : 'Unknown'
                                    : value['companyName'] !== null
                                    ? value['companyName']
                                    : 'Unknown'}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="border-0 my-2">
                            <span className="dashboard-date_transaction  font-sofia-pro-light ">
                              {Moment(local).format('DD/MM/YYYY')}
                            </span>
                          </td>
                          <td className="border-0 ">
                            {' '}
                            <div className="d-flex flex-row ">{value['type']}</div>
                          </td>
                          <td className="border-0">
                            {' '}
                            <span className="dashboard-coffee font-sofia-pro-regular mx-1 ">
                              {value['priceId'] !== null ? value['Type'] : 'Credit'}
                            </span>
                          </td>
                          <td className="border-0">
                            <span className="dashboard-heythanks font-sofia-pro-light  dashboard-table_notes">
                              <span className="dashboard-coffee font-sofia-pro-regular mx-1 ">
                                {value['message'] != null ? (
                                  value['message']['type'] === 'Text' ? (
                                    <span>{value['message']['message']}</span>
                                  ) : value['message']['type'] === 'Video' ? (
                                    <>
                                      <button
                                        className="bg-transparent border-0"
                                        onClick={AudioMessageShow.bind(this, index)}
                                      >
                                        <span className="redeemthanks-button__textvideo font-sofia-pro-regular">
                                          Video message
                                        </span>
                                      </button>
                                      <AudioVideoMessagePopup show={showModal} onHide={() => setShowModal(false)} />
                                    </>
                                  ) : value['message']['type'] === 'Audio' ? (
                                    <>
                                      <button
                                        className="bg-transparent border-0"
                                        onClick={AudioMessageShow.bind(this, index)}
                                      >
                                        <span className="redeemthanks-button__textvideo font-sofia-pro-regular dashboard-table_notes">
                                          Audio message
                                        </span>
                                      </button>
                                      <AudioVideoMessagePopup show={showModal} onHide={() => setShowModal(false)} />
                                    </>
                                  ) : (
                                    <span className="font-sofia-pro-regular text-break">Unknown</span>
                                  )
                                ) : (
                                  'No message'
                                )}
                              </span>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-0 m-0 row">
          <div className=" col-sm-12 col-md-6 col-lg-8 ">
            <div className="card border-0">
              <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
              <div className="dashboard-components1">
                <Components2 />
              </div>
              <div className="dashboard-components1 my-2">
                <span className="dashboard__trysendingyourfir font-sofia-pro-medium my-2">
                  Try sending your first gift,
                  <Link style={{ textDecoration: 'none' }} to="/organisation/send/recipientInformation">
                    <span className="dashboard-text__style1">Try now</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const ChartState = () => {
    if (sentData === null) {
      return (
        <div>
          <div className="row mx-2 ">
            <span className="dashboard-redeemed__thankschar p-2 font-sofia-pro-medium ">Redeemed Chart</span>
          </div>
          <div className="dashboard-components1">
            <Components1 />
          </div>
          <div className="dashboard-components1">
            <span className="dashboard__uhohnochartsyet font-sofia-pro-medium">Uh-oh,no charts yet!</span>
            <div className="my-2">
              <span className="dashboard__trysendingthanks font-sofia-pro-light">
                Try sending thanks to your mate <span>&#128521;</span>
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="row my-2 ">
            <span className="dashboard-redeemed__thankschar p-2 font-sofia-pro-medium  mx-3 ">Redeemed Chart</span>
            <div className="d-flex flex-column flex-lg-row my-3 mx-3 mx-lg-4 justify-content-between">
              <div className="d-flex">
                <div className="d-flex flex-row ">
                  <div className="border-radius-5 dashboard-chart__sent my-1 mx-2" />
                  <span className="dasboard-chart__Senttext font-sofia-pro-light">Sent</span>
                </div>
              </div>
              <div className=" mx-lg-2 mx-1 my-lg-0 my-1 d-flex">
                {btnShow === false ? (
                  <button className="dashboard-button__daily " onClick={timeShow}>
                    <div className="justify-content-around row">
                      <span className="font-sofia-pro-light dashbord-text__Daily mx-2">
                        {dayShow === true
                          ? 'Daily'
                          : monthShow === true
                          ? 'Monthly'
                          : yearShow === true
                          ? 'Yearly'
                          : ''}
                      </span>

                      <CaretDown />
                    </div>
                  </button>
                ) : (
                  <div className="dashboard-buttom__graphClicked ">
                    <button className="border-0 bg-transparent w-100" onClick={timeShow}>
                      <div className="justify-content-around row">
                        <span className="font-sofia-pro-light dashbord-text__Daily mx-2">
                          {dayShow === true
                            ? 'Daily'
                            : monthShow === true
                            ? 'Monthly'
                            : yearShow === true
                            ? 'Yearly'
                            : ''}
                        </span>

                        <CaretDown />
                      </div>
                    </button>
                    <div className="dashboard-line__graph" />
                    {monthShow === true ? (
                      <button className="border-0 bg-transparent" onClick={DailyShow}>
                        <div className="justify-content-ariund">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Daily</span>
                        </div>
                      </button>
                    ) : (
                      <button className="border-0 bg-transparent" onClick={MonthlyShow}>
                        <div className="justify-content-between">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Monthly</span>
                        </div>
                      </button>
                    )}

                    <div className="dashboard-line__graph" />
                    {yearShow === true ? (
                      <button className="border-0 bg-transparent" onClick={DailyShow}>
                        <div className="justify-content-ariund">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Daily</span>
                        </div>
                      </button>
                    ) : (
                      <button className="border-0 bg-transparent" onClick={YearlyShow}>
                        <div className="justify-content-between">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Yearly</span>
                        </div>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard-chart ">
            <Graph />
          </div>
        </div>
      );
    }
  };
  return (
    <div className=" m-0 p-0 row ">
      {console.log("staging deployment check.")}
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half ">
        <OrganisationHeader role="admin" />
        <div className="mx-3 my-1">
          {accountData === null ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : accountData['logoPath'] === null ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : (
            <img src={accountData['logoPath'] + '?h=80&w=80'} className="rounded-circle dashboard_oval" />
          )}
          <span className="font-sofia-pro-bold font-18 mx-2">{accountData === null ? null : accountData['name']}</span>
        </div>
        <div className="d-flex flex-column my-4">
          {active === true ? (
            <span className=" font-sofia-pro-light dashboard-name__goodmorning my-2 mx-3">Welcome {Name} ! </span>
          ) : null}
        </div>
        {width > 700 ? (
          <div className="p-0 m-0 row my-2 mx-3">
            <div className=" col-sm-12 col-md-6 col-lg-4 ">
              <div className="card px-2 border-0 pt-sm-2 pt-md-2 dashboard-mobile_card dashboard-card_hieght">
                <span className="dashboard__availablethanks font-sofia-pro-medium mx-2 my-lg-2 mx-md-0 my-md-0">
                  Available balance
                </span>
                <span className="organisationdashboard__available_thanksvalue font-sofia-pro-medium mx-2  my-lg-0  my-md-3">
                  {dashboardData['thanksCredit'] !== undefined ? '$' + dashboardData['thanksCredit'] : '0'}
                </span>
              </div>
            </div>
            <div className=" col-sm-12 col-md-6 col-lg-4 dashboard-mobile_card">
              <div className="card px-2 border-0 pt-sm-2 pt-md-2 dashboard-card_hieght">
                <span className="dashboard__availablethanks font-sofia-pro-medium mx-2 my-2">Transactions</span>
                <span className="organisationdashboard__available_thanksvalue font-sofia-pro-medium mx-2 my-lg-0  my-md-2">
                  {dashboardData !== undefined ? dashboardData['thanksSent'] : '0'}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row mx-1 justify-content-around">
            <div className="d-flex card border-0 flex-column dashboard-mobile__card">
              <span className=" font-sofia-pro-medium dashboard-mobile__dashboardData mx-2">Available redemptions</span>
              <span className={' font-sofia-pro-medium mx-2 '}>
                {dashboardData['thanksCredit'] !== undefined ? '$' + dashboardData['thanksCredit'] : '0'}
              </span>
            </div>
            <div className="d-flex flex-column  card border-0  dashboard-mobile__card">
              <span className=" font-sofia-pro-medium font-9 dashboard-mobile__dashboardData mx-2">Transactions</span>
              <span className=" font-sofia-pro-medium mx-2 my-3">
                {dashboardData !== undefined ? dashboardData['thanksSent'] : '0'}
              </span>
            </div>
          </div>
        )}
        <div className=" p-0 m-0 row mx-3">
          <div className=" col-sm-12 col-md-6 col-lg-8 my-4">
            <div className="dashboard-chart border-0 ">
              <ChartState />
            </div>
          </div>
          <div className="  col-sm-12 col-md-6 col-lg-4 d-flex flex-column dashboard-mobile__topactioncard my-1"></div>
        </div>
        <div>
          <Transaction />
        </div>
      </div>
    </div>
  );
};

export default OrganisationDashboard;
