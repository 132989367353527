/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useEffect, useMemo, useReducer, useRef, useState, useImperativeHandle } from 'react';
import { Modal, Button, Container, Row, Col, ModalBody } from 'react-bootstrap';
import RecordVideStore from '../../store/reducers/RecordVideoStore';
import {
  AudioModalClose,
  MikeIcon,
  CameraIcon,
  SendVideoIcon,
  StopRecord,
  RestartRecord,
  PlayIcon,
  RecordVideoIcon,
} from '../../assets/images';
import VideoProvider from './VideoProvider';
import Timer from '../Timer/Timer';
import Loader from '../Loader/Loader';
import http from '../../services/HttpService';
import './RecordVideo.scss';

const initialState = {
  showModal: false,
  stopModal: false,
  recordState: 'init',
  recorder: null,
  video: null,
  thumpUrl: null,
  thumpImg: null,
  loading: false,
};

const RecordVideo = React.forwardRef(({ ...props }, ref) => {
  const [state, dispatch] = useReducer(RecordVideStore, initialState);

  const [pausedTime, setPausedTime] = useState(15);

  const videoRef = useRef(null);

  const fileRef = useRef(null);

  const [devices, setDevices] = useState([]);

  const [videoConstraints, setVideoConstraints] = useState();

  let ResolutionsToCheck = [
    { width: 160, height: 120 },
    { width: 320, height: 180 },
    { width: 320, height: 240 },
    { width: 640, height: 360 },
    { width: 640, height: 480 },
    { width: 768, height: 576 },
    { width: 1024, height: 576 },
    { width: 1280, height: 720 },
    { width: 1280, height: 768 },
    { width: 1280, height: 800 },
    { width: 1280, height: 900 },
    { width: 1280, height: 1000 },
    { width: 1920, height: 1080 },
    { width: 1920, height: 1200 },
    { width: 2560, height: 1440 },
    { width: 3840, height: 2160 },
    { width: 4096, height: 2160 },
  ];

  let left = 0;
  let right = ResolutionsToCheck.length;
  let selectedWidth;
  let selectedHeight;
  let mid;

  const handleDevices = React.useCallback(
    mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  );

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
      return;
    }

    if (left > right) {
      console.log('Selected Height:Width = ', selectedWidth, ':', selectedHeight);
      return;
    }

    mid = Math.floor((left + right) / 2);

    let constraints = {
      audio: true,
      video: {
        minWidth: ResolutionsToCheck[mid].width,
        minHeight: ResolutionsToCheck[mid].height,
        maxWidth: ResolutionsToCheck[mid].width,
        maxHeight: ResolutionsToCheck[mid].height,
        facingMode: 'user',
        deviceId: devices[0]?.deviceId,
      },
    };

    setVideoConstraints(constraints);

    const elementVideo = videoRef.current;

    if (state.showModal) {
      switch (state.recordState) {
        case 'init':
          break;
        case 'started':
          if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
              if (elementVideo.paused) {
                elementVideo.srcObject = stream;
                elementVideo.play();
              }
            });
          } else {
            alert('Device not supported!');
          }
          break;
        case 'paused':
        case 'resumed':
          if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
              if (elementVideo.paused) {
                elementVideo.srcObject = stream;
                elementVideo.play();
              }
            });
          } else {
            alert('Device not supported!');
          }
          break;
        case 'stopped':
          if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
              if (elementVideo) {
                if (elementVideo.paused) {
                  elementVideo.srcObject = null;
                  elementVideo.src = null;

                  let audioStream = stream.getAudioTracks();
                  let videoStream = stream.getVideoTracks();
                  elementVideo.play();
                  audioStream.forEach(track => {
                    track.stop();
                  });
                  videoStream.forEach(track => {
                    track.stop();
                  });
                }

                const video = videoRef.current;
                if (video) {
                  video.srcObject = null;
                  video.src = null;
                }
              }
            });
          } else {
            alert('Device not supported!');
          }
          break;
        default:
          break;
      }
    }
    return {};
  }, [state.recordState]);

  useEffect(() => {
    dispatch({ type: 'toggle_modal', data: props.videoModel });
  }, [props.videoModel]);

  const triggerRecording = async () => {
    switch (state.recordState) {
      case 'init':
      case 'stopped':
      case 'selected':
        const VideoRecorder = await VideoProvider(videoRef);
        dispatch({
          type: 'record_state_start',
          data: { state: 'started', recorder: VideoRecorder },
        });
        break;
      case 'started':
      case 'resumed':
        dispatch({
          type: 'record_stop_confirmation',
          data: { modal: true, recordState: 'paused' },
        });
        break;
      default:
        break;
    }
  };

  useMemo(() => {
    switch (state.recordState) {
      case 'started':
        state.recorder.clearChunk();
        state.recorder.start();
        console.log('started');
        setTimeout(() => {
          let vid = videoRef.current;
          const canvas = document.createElement('canvas'); // create a canvas
          const ctx = canvas.getContext('2d'); // get its context
          canvas.width = vid.videoWidth; // set its size to the one of the video
          canvas.height = vid.videoHeight;
          ctx.drawImage(vid, 0, 0); // the video
          dispatch({
            type: 'set_thump_url',
            data: canvas.toDataURL('image/png'),
          });
          canvas.toBlob(
            function (blob) {
              dispatch({ type: 'set_thump_image', data: blob });
            },
            'image/jpeg',
            0.95
          );
        }, 2000);
        break;
      case 'paused':
        state.recorder.pause();
        break;
      case 'resumed':
        state.recorder.resume();
        break;
      case 'stopped':
        break;
      default:
        break;
    }
  }, [state.recordState]);

  const confirmStopRecording = async () => {
    const video = await state.recorder.stop();
    const elementVideo = videoRef.current;
    elementVideo.pause();
    elementVideo.currentTime = 0;
    dispatch({
      type: 'record_state_stop',
      data: { state: 'stopped', video: video, stopModal: false },
    });
    setPausedTime(15);
    stopRecording();
  };

  const selectVideo = () => {
    if (videoRef.current !== null) {
      dispatch({ type: 'select_video', data: { recordState: 'selected' } });
    }
  };

  const closeStopModal = () => {
    // const video = await state.re`corder.stop();
    // const elementVideo = videoRef.current;
    // elementVideo.pause();
    // elementVideo.currentTime = 0;
    // dispatch({ type: 'record_state_stop', data: { state: 'stopped', video: video, stopModal: false } });
    dispatch({
      type: 'record_stop_confirmation',
      data: { stopModal: false, recordState: 'resumed' },
    });
    // stopRecording();
  };

  const continueBtnHandler = async () => {
    dispatch({
      type: 'record_stop_confirmation',
      data: { stopModal: false, recordState: 'resumed' },
    });
  };

  const removeVideo = () => {
    setPausedTime(15);
    dispatch({ type: 'select_video', data: { recordState: 'init' } });
  };

  const storeTimer = time => {
    setPausedTime(time);
  };

  const switchMediatype = e => {
    props.toggleAudioModal(e);
    props.toggleVideoModal(e);
  };

  useImperativeHandle(ref, () => ({
    selectVideoPlayer: selectVideo,
    recordState: state.recordState,
    thumpUrl: state.thumpUrl,
  }));

  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.name = fileName;
    theBlob.lastModifiedDate = Date.now();

    // var a = document.createElement("a");
    //         document.body.appendChild(a);
    //         a.style = "display: none";
    // var url = window.URL.createObjectURL(theBlob);
    // a.href = url;
    // a.download = fileName;
    // a.click();
    // window.URL.revokeObjectURL(url);

    return theBlob;
  };

  const selectUpload = () => {
    dispatch({ type: 'trigger_loader', data: true });
    let userData = JSON.parse(localStorage.getItem('userData'));
    let videoFileName = `${Date.now()}_${userData.userName}_video.mpeg`;

    let thumpFileName = `${Date.now()}_${userData.userName}_image.jpg`;
    var myThumpFile = blobToFile(state.thumpImg, thumpFileName);

    var formData = new FormData();
    formData.append('fileName', videoFileName);
    formData.append('imagePreviewFile', myThumpFile, thumpFileName);
    // for (var value of formData.values()) {
    //     console.log(value);
    // }

    //var myVideoFile = blobToFile(state.video.videoBlob, videoFileName);
    //console.log(fileRef.current.files[0]);

    http
      .post('/StoredFiles', formData, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then(initResponse => {
        let resp = initResponse.data;
        var myVideoFile = new File([state.video.videoBlob], videoFileName, {
          lastModified: Date.now(),
        });
        //console.log(fileRef.current.files[0]);
        //console.log(myVideoFile);

        var formDataUpdate = new FormData();
        formDataUpdate.append('fileName', myVideoFile, videoFileName);
        // for (var value of formDataUpdate.values()) {
        //     console.log(value);
        // }
        http
          .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
              fileSecret: resp.fileSecret,
            },
          })
          .then(response => {
            dispatch({ type: 'trigger_loader', data: false });
            props.videoResponse(response.data);
            props.selectVideo();
            stopRecording();
          })
          .catch(error => {
            let errorResponse = error.response['data'];
            dispatch({ type: 'trigger_loader', data: false });
            console.log(errorResponse);
          });
      })
      .catch(error => {
        let errorResponse = error.response['data'];
        dispatch({ type: 'trigger_loader', data: false });
        console.log(errorResponse);
      });
  };

  const stopRecording = () => {
    setPausedTime(15);

    const elementVideo = videoRef.current;
    if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(videoConstraints).then(stream => {
        if (elementVideo) {
          if (elementVideo.paused) {
            elementVideo.srcObject = null;
            elementVideo.src = null;

            let audioStream = stream.getAudioTracks();
            let videoStream = stream.getVideoTracks();
            elementVideo.play();
            audioStream.forEach(track => {
              track.stop();
            });
            videoStream.forEach(track => {
              track.stop();
            });
          }

          const video = videoRef.current;
          if (video) {
            video.srcObject = null;
            video.src = null;
          }
        }
      });
    } else {
      alert('Device not supported!');
    }
  };

  const videoModalCloseBtnHandler = () => {
    props.toggleVideoModal();
    const elementVideo = videoRef.current;
    if (elementVideo) {
      elementVideo.srcObject = null;
      elementVideo.src = null;
    }

    // if (navigator.mediaDevices.getUserMedia !== null) {
    //     console.log('media devices ', navigator.mediaDevices.getUserMedia());
    //
    // }

    stopRecording();

    // dispatch({ type: 'record_state_stop', data: { state: 'stopped', stopModal: false } });

    // dispatch({ type: 'record_state_stop', data: { state: 'stopped', video: null } });

    // console.log('element video ', videoRef.current);

    // console.log('window stream ref ', window.streamReference)

    // elementVideo.stop();

    // if (!window.streamReference) return;

    // window.streamReference.getAudioTracks().forEach(function (track) {
    //     track.stop();
    // });

    // window.streamReference.getVideoTracks().forEach(function (track) {
    //     track.stop();
    // });

    // window.streamReference = null;

    // if ('mediaDevices' in navigator && navigator.mediaDevices.getUserMedia) {
    //     navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
    //         const stream = mediaStream;
    //         stream.srcObject = null;
    //         stream.src = null;
    //         mediaStream.getAudioTracks()[0].stop();
    //         mediaStream.getVideoTracks()[0].stop();
    //     })
    // } else {
    //     alert('Device not supported!');
    // }

    // if (!window.streamReference) return;

    // window.streamReference.getAudioTracks().forEach(function (track) {
    //     track.stop();
    // });

    // window.streamReference.getVideoTracks().forEach(function (track) {
    //     track.stop();
    // });

    // window.streamReference = null;
  };

  return (
    <>
      {state.loading && <Loader />}
      <Modal
        show={state.showModal}
        id="video_modal"
        className="
        modal-background-shadow"
      >
        <Modal.Body>
          <Button variant={'none'} type="button" className="close btn-model-close" onClick={videoModalCloseBtnHandler}>
            <AudioModalClose />
          </Button>
          {
            {
              init: <video ref={videoRef} muted className="stream_video"></video>,
              started: (
                <>
                  <video ref={videoRef} muted className="stream_video"></video>
                  <Timer
                    confirmStopRecording={confirmStopRecording}
                    pausedTime={pausedTime}
                    storeTimer={storeTimer}
                    recordState={state.recordState}
                  />
                </>
              ),
              paused: <video ref={videoRef} muted className="stream_video"></video>,
              resumed: (
                <>
                  <video ref={videoRef} muted className="stream_video"></video>
                  <Timer
                    confirmStopRecording={confirmStopRecording}
                    pausedTime={pausedTime}
                    storeTimer={storeTimer}
                    recordState={state.recordState}
                  />
                </>
              ),
              stopped: (
                <>
                  <video ref={videoRef} muted className="stream_video"></video>

                  <div
                    className="video-list"
                    style={{
                      backgroundImage: `url(${state.thumpUrl})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                  >
                    <Button variant={'none'} type="button" className="close" onClick={removeVideo}>
                      <AudioModalClose />
                    </Button>
                    <SendVideoIcon className="mike" />
                    <PlayIcon className="play" onClick={selectVideo} />
                    <p className="selector" onClick={selectUpload}>
                      Select
                    </p>
                  </div>
                </>
              ),
              selected: (
                <video src={state.video ? state.video.videoUrl : ''} className="preview_video" controls></video>
              ),
            }[state.recordState]
          }
        </Modal.Body>
        <Modal.Footer className="record-video-footer">
          {/* <input type="file" name="uploadfile" ref={fileRef}/> */}
          <div className="rec-toggle-btn">
            <Button variant="none" className="rec-active-btn">
              <CameraIcon />
            </Button>
            <Button
              variant="none"
              className="rect-inactive-btn"
              disabled={state.recordState === 'started' ? true : false}
              onClick={switchMediatype}
            >
              <MikeIcon />
            </Button>
          </div>
          <div className="modal-center-action">
            {
              {
                init: (
                  <Button
                    variant="none"
                    className="bg-primary-color color-white record-button-mike"
                    onClick={triggerRecording}
                  >
                    <RecordVideoIcon className="size-40" />
                  </Button>
                ),
                started: <StopRecord className="record-button-stop" onClick={triggerRecording} />,
                paused: <StopRecord className="record-button-stop" onClick={triggerRecording} />,
                resumed: <StopRecord className="record-button-stop" onClick={triggerRecording} />,
                stopped: (
                  <Button variant="none" className="bg-secondary-color color-white record-button-mike-disabled">
                    <SendVideoIcon />
                  </Button>
                ),
                selected: (
                  <>
                    <Button variant="none" className="color-primary" onClick={triggerRecording}>
                      {' '}
                      <RestartRecord /> Record again{' '}
                    </Button>
                    <Button
                      variant="none"
                      className="bg-primary-color color-white"
                      style={{ position: 'absolute', right: '15px' }}
                      onClick={selectUpload}
                    >
                      Send
                    </Button>
                  </>
                ),
              }[state.recordState]
            }
          </div>
        </Modal.Footer>
      </Modal>
      <Modal show={state.stopModal} id="record_stop_modal" centered className="modal-background-shadow">
        <Modal.Body className="">
          <Button variant={'none'} type="button" className="close btn-model-close" onClick={closeStopModal}>
            <AudioModalClose />
          </Button>
          <p className="font-weight-bold">Are you sure you want to stop recording?</p>
          <Button
            type="button"
            variant="none"
            className="bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none"
            onClick={confirmStopRecording}
          >
            Finish
          </Button>
          <Button
            type="button"
            variant="none"
            className="color-gray-pearl font-sofia-pro-semi-bold font-16 outline-none"
            onClick={continueBtnHandler}
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default RecordVideo;
