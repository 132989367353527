/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import { Router, Link } from 'react-router-dom';
import OrganisationHeader from '../../organisationheader/organisationHeader';
import OrganisationSidebar from '../../organisationsidebar/organisationsidebar';
import './OrganisationSuccess.scss';
import { ReactComponent as TopUpSuccessLogo } from '../../../../assets/images/common/components-illustrations-credits.svg';

const TopUpSuccess = () => {
  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column  ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half">
        <OrganisationHeader role="admin" />
        <div className="d-flex my-5 justify-content-center">
          <div className="Topup-success__card border-0  d-flex flex-column  align-items-center m-3">
            <TopUpSuccessLogo />
            <span className="font-sofia-pro-regular opup-success__You-have-successfull ">
              Credits added successfully
            </span>

            <span className="font-sofia-pro-light Topup-success__To-cancel-this-trans-Copy  m-3 ">
              You can now allocate credits to your employees or redeem them from the credits menu.
            </span>
            <div className="d-flex my-3">
              <Link to="/organisation/topup">
                <button className="Topup-success__button border-0  mx-2">
                  <span className="font-sofia-pro-semi-bold Topup-success__Topupmore      ">Top up more</span>
                </button>
              </Link>
              <Link to="/organisation/dashboard">
                <button className="Topup-success__homebutton ">
                  <span className="font-sofia-pro-semi-bold Topup-success__home">Home</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpSuccess;
