/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import StackedBar from '../../individual/dashboard/StackChart';
import SideBar from '../../../common/Sidebar/Sidebar';
import Header from '../../../common/Header/Header';
import { Modal } from 'react-bootstrap';
import MerchantService from '../../../services/MerchantService';
import DashboardService from '../../../services/DashboardService';
import moment from 'moment';
import Moment from 'react-moment';
import MerchantTourPage from './MerchanTour';
import SendThanksService from '../../../services/SendThanksService';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './Dashboard.scss';
import { DateRange } from 'react-date-range';
import { ReactComponent as Components1 } from '../../../assets/images/dashboard/icons/components-illustrations-empty-state-2.svg';
import { ReactComponent as Components2 } from '../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';
import { ReactComponent as DatePickerInActive } from '../../../assets/images/common/icons-misc-date-inactive.svg';
import { ReactComponent as DatePickerActive } from '../../../assets/images/common/icons-misc-date-active.svg';
import IconCheckFilled from '../../../assets/images/common/icons-check-filled-pink.png';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const MerchantDashboardPage = props => {
  const [merchantMenuIndex, setMerchantMenuIndex] = useState(0);

  const [merchantDashboardData, setMerchantDashboardData] = useState();

  const [merchantWidgetData, setMerchantWidgetData] = useState();

  const [selectedFilter, setSelectedFilter] = useState('week');

  const [productsList, setProductsList] = useState();

  const [grpahData, setGraphData] = useState([]);

  const [selectedGraphFilterValue, setSelectedGraphFilterValue] = useState();

  const [graphLabel, selectedGraphLabel] = useState([]);

  const [customDatePicker, setCustomDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  const [show, setShow] = useState(false);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseDatePicker = () => setShowDatePicker(false);
  const handleShowDatePicker = () => setShowDatePicker(true);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleCloseSuccessPopup = () => setShowSuccessPopup(false);
  const handleShowSuccessPopup = () => setShowSuccessPopup(true);

  const history = useHistory();

  let userData = JSON.parse(localStorage.getItem('userData'));
  let data = { fullname: userData.fullName, type: 'FullName' };
  const Name = data.fullname;

  useEffect(() => {
    if (props?.history?.location?.state?.showMerchantPopup === true) {
      handleShowSuccessPopup();
    }
    let checkMerchantIsOnboarded = JSON.parse(sessionStorage.getItem('merchantOnboard'));
    if (Boolean(checkMerchantIsOnboarded) === true) {
      handleShow();
      loadMerchantTourData(0);
      sessionStorage.setItem('merchantOnboard', false);
    }

    // sessionStorage.setItem('merchantOnboard', true);

    loadMerchantDashboardData();
    getGraphData();
    window.scrollTo(0, 0);
  }, []);

  const loadMerchantDashboardData = () => {
    DashboardService.getMerchantDashboardData()
      .then(response => {
        if (response) {
          let data = response['data'];

          SendThanksService.getListOfProducts('AU').then(response => {
            if (response) {
              let productData = response['data'];
              setProductsList(data);
              if (productData?.length > 0) {
                productData.forEach((product, index) => {
                  data?.lastThreeRedemptions.forEach((redemption, index) => {
                    if (product?.price?.priceId === redemption?.priceId) {
                      redemption['type'] = product?.type;
                    }
                  });
                });
              }

              setMerchantDashboardData(data);
            }
          });
          setMerchantWidgetData(data?.widgets);
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const merchantTourNextBtnHandler = index => {
    if (index !== 5) {
      setMerchantMenuIndex(index);
    } else {
      handleClose();
    }
  };

  const exportDataBtnHandler = event => {
    if (event.target.value === 'csv') {
      MerchantService.exportRedemptons()
        .then(response => {
          let data = response['data'];
          csvJSON(data);
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);

          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  function csvJSON(csv) {
    var lines = csv.split('\n');

    var result = [];

    var headers = lines[0].split(',');

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(',');

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }
    return JSONToCSVConvertor(result, headers);
  }

  const JSONToCSVConvertor = (JSONData, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

    var CSV = '';

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = '';

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ',';
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = '';

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + '\r\n';
    }

    if (CSV === '') {
      alert('Invalid data');
      return;
    }

    //Generate a file name
    var fileName = 'appreci_redemptions_' + moment(new Date()).format('YYYY-MM-DD');
    //this will remove the blank-spaces from the title and replace it with an underscore

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement('a');
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = 'visibility:hidden';
    link.download = fileName + '.csv';

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loadMerchantTourData = MenuIndex => {
    setMerchantMenuIndex(MenuIndex);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const widgetFilterBtnHandler = type => {
    setSelectedFilter(type);

    let from, to;

    if (type === 'week') {
      from = moment().startOf('week').toDate();
      to = moment().endOf('week').toDate();
    } else if (type === 'month') {
      from = moment().startOf('month').toDate();
      to = moment().endOf('month').toDate();
    } else if (type === 'year') {
      from = moment().startOf('year').toDate();
      to = moment().endOf('year').toDate();
    } else if (type === 'custom') {
      handleShowDatePicker();
    }

    from = moment(from).format('YYYY-MM-DD');
    to = moment(to).format('YYYY-MM-DD');

    DashboardService.getMerchantDashboardWidget(from, to)
      .then(response => {
        if (response) {
          let data = response['data'];
          setMerchantWidgetData(data);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const getGraphData = e => {
    let type = e === undefined ? 'Daily' : e.target.value;
    setSelectedGraphFilterValue(type);
    DashboardService.getMerchantDashboardGraphData(type).then(response => {
      if (response) {
        let data = response['data'];
        setGraphData(
          data.map(val => {
            return val['number'];
          })
        );
        // setGraphData(data);

        let labels = [];
        if (type === 'Daily') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('DD/M/YY'));
          });
        } else if (type === 'Yearly') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('YYYY'));
          });
        } else if (type === 'Monthly') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('MMMM'));
          });
        }

        selectedGraphLabel(labels);
      }
    });
  };

  const customDatePickerEventHandler = e => {
    setCustomDatePicker([e.selection]);

    let from = moment(e.selection.startDate).format('YYYY-MM-DD');
    let to = moment(e.selection.endDate).format('YYYY-MM-DD');

    if (from !== to) {
      handleCloseDatePicker();
      DashboardService.getMerchantDashboardWidget(from, to)
        .then(response => {
          if (response) {
            let data = response['data'];
            setMerchantWidgetData(data);
          }
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);

          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  const handleSuccessPopupBtnHandler = () => {
    handleCloseSuccessPopup();
    history.push('/dashboard');
    sessionStorage.setItem('merchantOnboard', true);
  };

  const Transaction = () => {
    if (merchantDashboardData?.lastThreeRedemptions.length === 0) {
      return (
        <div className="p-0 m-0 row">
          <div className=" col-sm-12 col-md-6 col-lg-8 ">
            <div className="card border-0">
              <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
              <div className="dashboard-components1">
                <Components2 />
              </div>
              <div className="dashboard-components1 my-2">
                <span className="dashboard__trysendingyourfir font-sofia-pro-medium my-2">
                  Try sending your first transaction
                  {/* <span className="dashboard-text__style1">Try now</span> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-0 m-0 row m-3">
          <div className=" col-sm-12 col-md-12 col-lg-12">
            <div className="card border-0 dashboard-transaction_card">
              <div className="d-flex flex-row justify-content-between  py-2">
                <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
                <Link className=" my-1 px-2 py-1" style={{ textDecoration: 'none' }} to="/merchant/transactions">
                  <span className="p-2 d-flex justify-content-center align-items-center dashboard-text_viewall font-sofia-pro-regular  ">
                    View all
                  </span>
                </Link>
              </div>
              <div className="dashboard-line__latesttransaction my-2" />
              <div className="p-0 m-0 row mx-3 mb-3">
                <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12 ">
                  <div className="card border-0 dashboard-transaction_card">
                    <div className="table-responsive ">
                      <table className="table dashboard-table_headcolor border-bottom-pale-grey">
                        <thead style={{ backgroundColor: '#fff2fa ' }}>
                          <tr className="mx-2">
                            <td scope="col" className="font-sofia-pro-semi-bold">
                              Transaction ID
                            </td>
                            <td scope="col" className="font-sofia-pro-semi-bold  ">
                              Date
                            </td>
                            <td scope="col" className="font-sofia-pro-semi-bold  ">
                              Location
                            </td>
                            <td scope="col" className="font-sofia-pro-semi-bold ">
                              Product
                            </td>
                            <td scope="col" className="font-sofia-pro-semi-bold ">
                              Price
                            </td>
                            <td scope="col" className="font-sofia-pro-semi-bold ">
                              Quantity
                            </td>
                            <td scope="col" className="font-sofia-pro-semi-bold ">
                              Total
                            </td>
                            <td scope="col" className="font-sofia-pro-semi-bold ">
                              Payout Id
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          {merchantDashboardData?.lastThreeRedemptions.map((redemption, index) => {
                            return (
                              <tr key={index}>
                                <td scope="row">
                                  <span className="font-14 font-sofia-pro-regular color-black-pearl">
                                    {redemption.transferId}
                                  </span>
                                </td>
                                <td>
                                  <span className="font-14 font-sofia-pro-regular color-black-pearl">
                                    <Moment format="DD/M/YYYY">{redemption.redeemedOn}</Moment>
                                  </span>
                                </td>
                                <td>
                                  <Link
                                    className="color-primary text-decoration-none"
                                    to={`/merchant/transaction/${redemption.locationId}`}
                                  >
                                    <span className="font-14 font-sofia-pro-regular color-primary cursor-pointer">
                                      {redemption.location}
                                    </span>
                                  </Link>
                                </td>
                                <td>
                                  <span className="font-14 font-sofia-pro-regular color-black-pearl">
                                    {redemption.type}
                                  </span>
                                </td>
                                <td>
                                  <span className="font-14 font-sofia-pro-regular color-black-pearl">
                                    ${redemption.amount}
                                  </span>
                                </td>
                                <td>
                                  <span className="font-14 font-sofia-pro-regular color-black-pearl">
                                    {redemption.quantity}
                                  </span>
                                </td>
                                <td>
                                  <span className="font-14 font-sofia-pro-regular color-black-pearl">
                                    ${redemption.total}
                                  </span>
                                </td>
                                <td>
                                  <Link
                                    className="color-primary text-decoration-none"
                                    to={`/merchant/payout/${redemption.payoutId}/${redemption.redeemedOn}/${redemption.payoutStripeId}`}
                                  >
                                    <span className="font-14 font-sofia-pro-regular color-primary  cursor-pointer">
                                      {redemption.payoutStripeId}
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  function ChartState() {
    if (grpahData?.length === 0) {
      return (
        <div>
          <div className="row mx-2 ">
            <span className="dashboard-redeemed__thankschar p-2 font-sofia-pro-medium ">Redeemed Chart</span>
          </div>
          <div className="dashboard-components1">
            <Components1 />
          </div>
          <div className="dashboard-components1">
            <span className="dashboard__uhohnochartsyet font-sofia-pro-medium">Uh-oh,no charts yet!</span>
            <div className="my-2">
              <span className="dashboard__trysendingthanks font-sofia-pro-light">
                Try sending thanks to your mate <span>&#128521;</span>
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-2">
          <div className="p-0 m-0 row justify-content-between align-items-center">
            <span className="p-0 m-0 col-lg-6 col-md-10 font-sofia-pro-medium font-20 ">Daily Payout</span>
            <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-end ">
              <div className="my-1">
                <div className="my-1">
                  <select
                    className="form-control shadow-none border-cloudy-blue font-14 font-sofia-pro-light color-grey-blue"
                    value={selectedGraphFilterValue}
                    onChange={getGraphData}
                  >
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-chart">
            <StackedBar data={grpahData} labels={graphLabel} />
          </div>
        </div>
      );
    }
  }
  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar menu="Merchant" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half ">
        <Header menu="Merchant" />
        <div className="p-0 m-0 row mt-4 my-2 mx-4 px-2 d-flex justify-content-between">
          <div className="d-flex merchant-dashboard-filter__btn">
            <button
              type="button"
              className={
                selectedFilter === 'week'
                  ? 'mr-2 font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none'
                  : 'mr-2 font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none'
              }
              onClick={widgetFilterBtnHandler.bind(this, 'week')}
            >
              This week
            </button>
            <button
              type="button"
              className={
                selectedFilter === 'month'
                  ? 'mr-2 font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none'
                  : 'mr-2 font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none'
              }
              onClick={widgetFilterBtnHandler.bind(this, 'month')}
            >
              This month
            </button>
            <button
              type="button"
              className={
                selectedFilter === 'year'
                  ? 'mr-2 font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none'
                  : 'mr-2 font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none'
              }
              onClick={widgetFilterBtnHandler.bind(this, 'year')}
            >
              This Year
            </button>
            <button
              type="button"
              className={
                selectedFilter === 'custom'
                  ? 'mr-2 d-flex justify-content-center align-items-center font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none'
                  : 'mr-2 d-flex justify-content-center align-items-center font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none'
              }
              onClick={widgetFilterBtnHandler.bind(this, 'custom')}
            >
              {selectedFilter === 'custom' ? <DatePickerActive /> : <DatePickerInActive />}
              <span className="pl-1">Custom</span>
            </button>
          </div>
          <div>
            <select
              id="export_option"
              className="bg-transparent border-0 shadow-none color-black-pearl font-14 font-sofia-pro-regular merchant-export__option"
              style={{ width: '70px' }}
              onChange={exportDataBtnHandler}
            >
              <option value selected disabled hidden style={{ display: 'none' }}>
                Export
              </option>
              <option value="csv">Export .csv</option>
              <option value="xls">
                {/* <ExportXLS /> */}
                Export .xls
              </option>
            </select>
          </div>
        </div>
        <div className="p-0 m-0 row my-4 mx-3 ">
          {merchantWidgetData?.map((widget, index) => {
            return (
              <div className=" col-sm-12 col-md-4 col-lg-4 " key={index}>
                <div className="card px-2 border-0 pt-sm-2 pt-md-2 dashboard-mobile_card dashboard-card_hieght">
                  <span className="dashboard__availablethanks font-sofia-pro-medium mx-2 my-lg-2 mx-md-0 my-md-0">
                    {widget.name}
                  </span>
                  <span className="dashboard__available_thanksvalue font-sofia-pro-medium mx-2 ">
                    {widget?.name === 'Total' && <span>$</span>}
                    {widget.number}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className=" p-0 m-0 row mx-3">
          <div className=" col-sm-12 col-md-6 col-lg-8 my-4">
            <div className="card border-0 ">
              <ChartState />
            </div>
          </div>
        </div>
        <Transaction />
      </div>

      <Modal
        className="border-0 merchant-dashboard__tour__modal modal-background-shadow"
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <MerchantTourPage
          menuIndex={merchantMenuIndex}
          handleClose={handleClose}
          nextBtnHandler={merchantTourNextBtnHandler}
        />
      </Modal>

      <Modal
        className="border-0 merchant-dashboard__tour__modal modal-background-shadow"
        size="md"
        show={showDatePicker}
        onHide={handleCloseDatePicker}
      >
        <DateRange
          editableDateInputs={true}
          onChange={customDatePickerEventHandler}
          moveRangeOnFirstSelection={false}
          ranges={customDatePicker}
        />
      </Modal>

      <Modal
        backdrop="static"
        className="border-0 merchant-success__modal modal-background-shadow"
        size="md"
        show={showSuccessPopup}
        onHide={handleCloseSuccessPopup}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <span className="font-16 color-primary font-sofia-pro-semi-bold">
            <img src={IconCheckFilled} height="25px" width="25px" />
            <span className="pl-1">ACCOUNT CREATED</span>
          </span>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <p className="font-16 font-sofia-pro-light color-black-pearl">
            Your merchant account has been created and is sent to our team for verification.
          </p>
          <p className="p-0 m-0">
            {' '}
            You can continue using your personal account and once your merchant account is verified, we’ll send you the
            next steps.
          </p>
          <span className="p-0 m-0 font-sofia-pro-medium font-14 color-black-pearl">
            Note: Accounts are activated within 24-48 hours
          </span>
          <div className=" p-0 m-0 mt-4 mb-2 d-flex justify-content-start align-items-center">
            <button
              className="p-0 m-0 py-2 w-120 h-50 border-primary-color-2 border-radius-5 bg-transparent font-16 color-primary font-sofia-pro-semi-bold outline-none  shadow-none"
              onClick={handleSuccessPopupBtnHandler}
            >
              Ok got it
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MerchantDashboardPage;
