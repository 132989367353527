/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HttpService from '../../../../services/HttpService';
import './publicProfile.scss';
import { ReactComponent as Logo } from '../../../../assets/images/logo/logo-colour.svg';
import { ReactComponent as ProfilePic } from '../../../../assets/images/common/user-profile.svg';
import { ReactComponent as AppreciationPic } from '../../../../assets/images/common/components-illustrations-donation.svg';

function PublicProfilePage(props) {
  const history = useHistory();

  const [userData, setUserData] = useState();

  const publicProfileSendBtnHandler = () => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    if (userData !== null && userData !== undefined) {
      history.push('/recipientInformation');
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    const userName = props.match.params.username;
    getUserInfo(userName);
    window.scrollTo(0, 0);
  }, []);

  // Getting user info
  const getUserInfo = name => {
    let data = {
      username: name,
    };
    HttpService.post('/users/search', data)
      .then(response => {
        if (response) {
          let data = response['data'];
          console.log('res ', data[0]);
          setUserData(data[0]);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
        }
        console.log('error filed errors ', errorResponseFieldErrors.length);
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const logout = () => {
    sessionStorage.clear();
    sessionStorage.clear();
    toast.success('You’ve been logged out successfully.');
    history.push('/login');
  };

  return (
    <div className="p-0 m-0 h-100vh bg-alice-blue">
      <div className="p-3 w-100 d-flex justify-content-between bg-white">
        <Logo />
        {!JSON.parse(localStorage.getItem('userData')) ? (
          <div className="d-flex">
            <Link to="/login">
              <button className="btn color-pimary font-sofia-pro-medium font-18 color-primary">Login</button>
            </Link>
            <Link to="/signup">
              {' '}
              <button className="btn bg-alice-blue color-black-pearl font-sofia-pro-medium font-18">
                Create account
              </button>
            </Link>
          </div>
        ) : (
          <div className="d-flex">
            <button className="btn bg-alice-blue color-black-pearl font-sofia-pro-medium font-18" onClick={logout}>
              Logout
            </button>
          </div>
        )}
      </div>
      {userData?.length !== 0 && userData !== undefined && (
        <div className="d-flex flex-column justify-content-between bg-alice-blue">
          <div className="mx-5">
            <div className="p-3 mt-3 d-flex flex-row card border-0 border-radius-5 bg-white">
              <div className="w-100 d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div className="pl-xl-3">
                    {userData?.profilePhotoUrl !== null && (
                      <img
                        className="rounded-circle"
                        src={userData?.profilePhotoUrl + '?h=100'}
                        height="100px"
                        width="100px"
                      />
                    )}
                    {userData?.profilePhotoUrl === null && <ProfilePic />}
                  </div>
                  <div className="pl-3 d-flex flex-column">
                    <span className="color-nero font-sofia-pro-semi-bold font-32">{userData?.fullName}</span>
                    <span className="color-gunmetal font-sofia-pro-regular font-18">
                      {process.env.REACT_APP_BASE_PUBLIC_URL}/public-profile/
                      {userData?.userName}
                    </span>
                  </div>
                </div>
                <div>
                  <button
                    className="btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 w-150 h-60"
                    onClick={publicProfileSendBtnHandler}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>

            <div className="p-3 mt-3 d-flex flex-column align-items-center justify-content-center card border-0 border-radius-5 bg-white">
              <div>
                <AppreciationPic />
              </div>
              <p className="text-center font-18 font-sofia-pro-medium color-black-pearl">
                Share your appreciation in spontaneous, tangible and <br /> thoughtful ways with Appreci
              </p>
              <p className="text-center font-14 font-sofia-pro-light oslo-grey">
                To people who desire deeper bonds in their relationships. Appreci is for turning thoughtful moments into{' '}
                <br /> immeasurable impact. Appreci is an everyday tool for people to convert their feelings into
                actions.{' '}
              </p>
            </div>
          </div>
          <div className="mx-5 my-2 d-flex justify-content-between">
            <div>
              <span className="text-center font-14 font-sofia-pro-light color-charcoal-grey">©Appreci</span>
            </div>
            <div>
              <a
                href="https://appreci.io/about-us/"
                target="_blank"
                className="pb-1 text-decoration-none  cursor-pointer "
              >
                {' '}
                <span className="pr-2 text-center font-14 font-sofia-pro-regular color-charcoal-grey">About</span>{' '}
              </a>
              <a
                href="https://appreci.io/privacy-policy"
                target="_blank"
                className="pb-1 text-decoration-none  cursor-pointer "
              >
                <span className="pl-2 text-center font-14 font-sofia-pro-regular color-charcoal-grey">
                  Privacy policy
                </span>
              </a>
            </div>
          </div>
        </div>
      )}
      {userData?.length === 0 ||
        (userData === undefined && (
          <div className="h-100 d-flex flex-column justify-content-between bg-alice-blue">
            <div className="mx-5">
              <div className="p-3 mt-3 d-flex text-center card border-0 border-radius-5 bg-white">
                <p className="text-center font-32 font-sofia-pro-medium color-black-pearl">No user data found</p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default PublicProfilePage;
