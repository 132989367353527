/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Router, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  useStripe,
  useElements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

import { connect } from 'react-redux';
import { orgSendThanksDetails, orgResetSendThanksDetails } from '../../../../store/reducers/organisation/sendThanks';
import sendThanksSchema, * as SendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import * as RegexPatterns from '../../../../utils/RegexPatterns';
import OrganisationHeader from '../../organisationheader/organisationHeader';
import OrganisationSidebar from '../../organisationsidebar/organisationsidebar';
import paymentService from '../../../../services/PaymentService';
import Toast from '../../../../services/ToasterService';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import IconMiscCard from '../../../../assets/images/common/icons-misc-card.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import GoolePay from '../../../../assets/images/common/google-pay-mark-800.png';
import { ReactComponent as EditMilestoneInActive } from '../../../../assets/images/common/edit-user.svg';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function OrganisationPayment(props) {
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const showDeleteModalHandler = () => setShowDeleteModal(true);
  const hideDeleteModalHandler = () => setShowDeleteModal(false);
  const [savedCardsList, setSavedCardsList] = useState([]);
  const [defaultCardList, setDefaultCardList] = useState([]);
  let [choosenCardId, setChoosenCardId] = useState();
  const [cardLastNumber, setCardLastNumber] = useState();
  const [cardExpiryDate, setCardExpiryDate] = useState();
  const [cardBrand, setCardBrand] = useState();
  const [googlepaySelect, setGooglepaySelect] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState();
  let checkCardRemoved = false;

  useEffect(() => {
    loadExistingCardsList();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    checkIfUserSelectedPaymentCard();
  }, [savedCardsList]);
  // { googlepaySelect === true ? (sessionStorage.setItem('PaymentType', 'GooglePay')) : (sessionStorage.setItem('PaymentType', 'Card')) }

  const checkIfUserSelectedPaymentCard = () => {
    if (savedCardsList.length > 0) {
      savedCardsList.map((res, index) => {
        // if (sendThanksSchema.sendThanksData?.paymentSource?.cardid === res.id) {
          setChoosenCardId(res.id);
          setCardLastNumber(res.last4);
          setCardExpiryDate(res.expiresOn);
          setCardBrand(res.brand);
        // }
      });
    }
  };

  const loadExistingCardsList = () => {
    let clickedValue;
    if (props?.history?.location?.state !== undefined) {
      clickedValue = props?.history?.location?.state.googlepaySelect;
    }
    let googlePayCard = sessionStorage.getItem('PaymentType') ? sessionStorage.getItem('PaymentType') : 'Card';
    if (googlePayCard === 'GooglePay' && clickedValue === true) {
      setGooglepaySelect(true);
    } else if (googlePayCard === 'Card') {
      setGooglepaySelect(false);
    }

    paymentService
      .getListOfCards(true)
      .then(response => {
        if (response) {
          let data = response['data'];
          setSavedCardsList(data);
          let defaultCardData = data.filter(data => {
            return data.isDefault === true;
          });
          if (
            (defaultCardData.length > 0 && sendThanksSchema?.sendThanksData?.paymentSource?.cardid === '') ||
            sendThanksSchema?.sendThanksData?.paymentSource?.cardid === undefined
          ) {
            if (googlePayCard === 'Card') {
              setChoosenCardId(defaultCardData[0].id);
            }
          }
          setDefaultCardList(defaultCardData);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          Toast(errorMessage, 'error');
        }
      });
  };

  const deleteCard = index => {
    showDeleteModalHandler();
    setSelectedCardIndex(index);
  };

  const reviewBtnHandler = () => {
    if (choosenCardId !== undefined) {
      sessionStorage.setItem('PaymentType', 'Card');
      sendThanksSchema.sendThanksData.paymentSource.cardid = choosenCardId;
      props.paymentInformation(sendThanksSchema);
      props.history.push({
        pathname: '/organisation/review',
        state: {
          cardLastNumber: cardLastNumber,
          choosedCardId: choosenCardId,
          cardExpiryDate: cardExpiryDate,
          brand: cardBrand,
        },
      });
    } else if (googlepaySelect === true) {
      sessionStorage.setItem('PaymentType', 'GooglePay');
      props.history.push({
        pathname: '/organisation/review',
        state: {
          Type: 'Google Pay',
        },
      });
    } else {
      Toast('Please add a card for payment', 'error');
    }
  };

  const setAsDefaultBtnHandler = index => {
    let data = savedCardsList[index];

    let cardData = {
      id: data.id,
      expiresOn: data.expiresOn,
      setAsDefault: true,
    };

    paymentService
      .updateCard(data.id, cardData, true)
      .then(response => {
        if (response.status === 200) {
          loadExistingCardsList();
          Toast('Your default card is changed successfully', 'success');
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseFieldErrors) {
          if (errorResponseFieldErrors.length > 0) {
            errorResponseFieldErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const cardDeleteBtnHandler = () => {
    if (checkCardRemoved === false) {
      checkCardRemoved = true;
      let cardId = savedCardsList[selectedCardIndex].id;

      paymentService
        .deleteCard(cardId, true)
        .then(response => {
          loadExistingCardsList();
          checkIfUserSelectedPaymentCard();
          Toast('Card removed successfully', 'success');

          hideDeleteModalHandler();

          if (cardId === choosenCardId) {
            setChoosenCardId(undefined);
          }

          setTimeout(() => {
            checkCardRemoved = false;
          }, 2000);
        })
        .catch(error => {
          if (error?.response) {
            let errorResponse = error?.response['data'];
            let errorMessage = errorResponse?.globalErrors[0].message;
            Toast(errorMessage, 'error');
          }
        });
    }
  };

  const chooseCardBtnHandler = index => {
    // setChoosenCardId(savedCardsList[index].id);
    // setCardBrand(savedCardsList[index].brand)
  };

  const onPurgeStoredState = () => {
    // const { dispatch } = props;   // Grab a ref to the mapped dispatch method
    // console.log("dispatch ", dispatch);
    // // Create and dispatch the action which will cause redux-persist to purge
    // dispatch({
    //     type: PURGE,
    //     key: "appreci-send-thanks",    // Whatever you chose for the "key" value when initialising redux-persist in the **persistCombineReducers** method - e.g. "root"
    //     result: () => null              // Func expected on the submitted action.
    // });
    // persistStore(props).purge();
  };

  const cancelBtnHandler = () => {
    props.resetData();
    // onPurgeStoredState();
    props.history.push({
      pathname: '/organisation/dashboard',
    });
  };

  const googlePayBtnHandler = () => {
    setGooglepaySelect(!googlepaySelect);
    setChoosenCardId(undefined);
    sessionStorage.setItem('PaymentType', 'GooglePay');
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column  ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half ">
        <OrganisationHeader role="admin" />
        <div className="d-flex m-2 mx-4">
          <Link to="/organisation/topup">
            <span className="p-2 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 card border-0  border-radius-5">
              <div className="card-body">
                <span className="font-32 font-sofia-pro-light color-black-pearl">Payment Options</span>

                <div>
                  <div className="my-2 d-flex justify-content-between">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Default payment method</span>
                    {/* <span className="font-14 font-sofia-pro-regular color-primary">Change preference</span> */}
                  </div>

                  <div className='d-flex align-items-center'>
                    {savedCardsList.length !== 0 &&
                        savedCardsList.map((data, index) => {
                          return (
                            <div key={index} className="mt-3 p-2 d-flex justify-content-between align-items-center">
                              <div className="payment-options-radio__item">
                                <input
                                  type="radio"
                                  // id={'savedCard' + index}
                                  // name={'savedCard' + index}
                                  // value={data.id}
                                  checked={true}
                                />
                                <label className="font-14 font-sofia-pro-light color-black-pearl ">
                                  <span className="pl-2">
                                    {' '}
                                    {data.brand} •••• •••• •••• {data.last4} |{' '}
                                    <span>
                                      <Moment format="MM/YY">{data.expiresOn}</Moment>
                                    </span>
                                  </span>
                                  <img className="pl-1" src={IconMiscCard} />
                                </label>
                              </div>
                              {/* <div>
                                <button
                                  type="button"
                                  className="mr-2 p-2 btn btn-sm border-0  shadow-none"
                                  onClick={setAsDefaultBtnHandler.bind(this, index)}
                                >
                                  <span className="font-14 font-sofia-pro-regular color-primary">Set as default</span>
                                </button>
                                <button
                                  type="button"
                                  className="p-2 btn btn-sm border-pale-pink color-primary  shadow-none"
                                  onClick={deleteCard.bind(this, index)}
                                >
                                  <span className="font-14 font-sofia-pro-regular color-primary">Delete</span>
                                </button>
                              </div> */}
                            </div>
                          );
                        })}
                    {savedCardsList.length === 0 ? (
                      <p className="my-4 text-center font-18 font-sofia-pro-medium">No data found</p>
                    ) : null}
                    
                    <EditMilestoneInActive className="cursor-pointer mt-3" onClick={handleShow}/>
                  </div>
                  {/* {defaultCardList.length !== 0 &&
                    defaultCardList.map((data, index) => {
                      return (
                        <div
                          key={index}
                          className="mt-3 p-2 d-flex justify-content-between align-items-center border-faded-pink bg-lavender-blush border-radius-5"
                        >
                          <div className="payment-options-radio__item">
                            <label className="font-14 font-sofia-pro-light color-black-pearl ">
                              <span className="pl-2">
                                {' '}
                                {data.brand} •••• •••• •••• {data.last4} |
                                <span>
                                  <Moment format="MM/YY">{data.expiresOn}</Moment>
                                </span>
                              </span>
                              <img className="pl-1" src={IconMiscCard} />
                            </label>
                          </div>
                          <span className="font-14 font-sofia-pro-light color-gunmetal">Default card</span>
                        </div>
                      );
                    })} */}

                  {/* <div className="mt-4">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                      Your saved credit and debit cards
                    </span>
                    {savedCardsList.length !== 0 &&
                      savedCardsList.map((data, index) => {
                        return (
                          <div key={index} className="mt-3 p-2 d-flex justify-content-between align-items-center">
                            <div className="payment-options-radio__item">
                              <input
                                type="radio"
                                id={'savedCard' + index}
                                name={'savedCard' + index}
                                value={data.id}
                                checked={data.id === choosenCardId && googlepaySelect === false}
                                onChange={e => {
                                  setChoosenCardId(e.target.value);
                                  setCardLastNumber(data.last4);
                                  setCardExpiryDate(data.expiresOn);
                                  setGooglepaySelect(false);
                                  sessionStorage.setItem('PaymentType', 'Card');
                                }}
                              />
                              <label className="font-14 font-sofia-pro-light color-black-pearl ">
                                <span className="pl-2">
                                  {' '}
                                  {data.brand} •••• •••• •••• {data.last4} |{' '}
                                  <span>
                                    <Moment format="MM/YY">{data.expiresOn}</Moment>
                                  </span>
                                </span>
                                <img className="pl-1" src={IconMiscCard} />
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    {savedCardsList.length === 0 ? (
                      <p className="my-4 text-center font-18 font-sofia-pro-medium">No data found</p>
                    ) : null}
                    <div className="mt-4">
                      <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Add a Payment Method</span>
                    </div>
                    <div className="mt-3 p-2 d-flex justify-content-between align-items-center">
                      <div
                        className="p-2 border-primary-color payment-options-radio__item border-radius-5 cursor-pointer"
                        onClick={handleShow}
                      >
                        <label
                          className="font-14 font-sofia-pro-light color-black-pearl cursor-pointer"
                          onClick={handleShow}
                        >
                          <span className="pl-2"> Add a debit or credit card </span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
                  <button
                    className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                    onClick={cancelBtnHandler}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                    onClick={reviewBtnHandler}
                  >
                    Review
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
        </div>
      </div>
      <Modal
        className="pt-4 border-0
         modal-background-shadow payment-modal"
        size="md"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Add a card</span>
          <button type="button" className="close modal-close-icon" onClick={handleClose}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <AddCardForm
                  elements={elements}
                  stripe={stripe}
                  hideModal={handleClose}
                  loadExistingList={loadExistingCardsList}
                />
              )}
            </ElementsConsumer>
          </Elements>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0
         modal-background-shadow payment-modal"
        size="md"
        show={showDeleteModal}
        onHide={hideDeleteModalHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Delete card?</span>
          <button type="button" className="close modal-close-icon" onClick={hideDeleteModalHandler}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <p className="font-16 font-sofia-pro-light color-nobel">
            Are you sure you want to delete this payment method?
          </p>
          <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={hideDeleteModalHandler}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={cardDeleteBtnHandler}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    getPaymentInformation: state.organisationSendThanks.orgSendThanksData,
  };
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    paymentInformation: data => dispatch(orgSendThanksDetails(data)),
    resetData: () => dispatch(orgResetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationPayment);

// Adding a new card

const AddCardForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardElemetError, setCardElementError] = useState('');
  const [cardExpiryError, setCardExpiryError] = useState('');
  const [cardCVCError, setCardCVCError] = useState('');
  const initialValues = {
    postcode: null,
  };
  const [disableBtn, setDisableBtn] = useState(false);

  let checkNewCardAdded = false;

  const saveCardDetails = async event => {
    if (checkNewCardAdded === false) {
      checkNewCardAdded = true;

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        Toast('Please add card details', 'error');
        return;
      }

      // Block native form submission.
      event.preventDefault();

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardNumberElement);
      const cardDateElement = elements.getElement(CardExpiryElement);
      const cardCVVElement = elements.getElement(CardCvcElement);

      // Use your card Element with other Stripe.js APIs
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        Toast('Please add the valid card details', 'error');
      } else if (formik.values.postcode === null) {
        Toast('Please add a postcode', 'error');
      } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
        Toast('Please add a valid postcode', 'error');
      } else {
        let tokenId = token.id;
        let data = {
          token: tokenId,
          setAsDefault: false,
          region: 'AU',
          postcode: formik.values.postcode,
        };
          paymentService
            .deleteCards(true)
            .then(response => {
              if (response) {
                Toast('All cards deleted successfully', 'success');
                paymentService
                  .addNewCard(data, true)
                  .then(response => {
                    if (response) {
                      console.log("---resp===", response);
                      // setChoosenCardId(response?.data?.id);
                      Toast('New card added successfully', 'success');
                      formik.values.postcode = '';
                      setTimeout(() => {
                        props.loadExistingList();
                        props.hideModal();
                        checkNewCardAdded = false;
                      }, 1000);
                      // handleClose();
                    }
                  })
                  .catch(error => {
                    if (error) {
                      let errorResponseGlobalErrors = error.response['data'].globalErrors;
                      if (errorResponseGlobalErrors) {
                        if (errorResponseGlobalErrors.length > 0) {
                          errorResponseGlobalErrors.forEach(msg => {
                            console.log('error global response message ', msg);
                            Toast(msg.message, 'error');
                          });
                        }
                      }
                    }
                  });
              }
            })
            .catch(error => {
              if (error) {
                let errorResponseGlobalErrors = error.response['data'].globalErrors;
                if (errorResponseGlobalErrors) {
                  if (errorResponseGlobalErrors.length > 0) {
                    errorResponseGlobalErrors.forEach(msg => {
                      console.log('error global response message ', msg);
                      Toast(msg.message, 'error');
                    });
                  }
                }
              }
            });
      }
    }
  };

  const handleChange = e => {
    if (e.elementType === 'cardNumber') {
      setCardElementError(e?.error?.message);
    } else if (e.elementType === 'cardNumber' && e.complete === true) {
      setCardElementError('');
    }

    if (e.elementType === 'cardExpiry') {
      setCardExpiryError(e?.error?.message);
    } else if (e.elementType === 'cardExpiry' && e.complete === true) {
      setCardExpiryError('');
    }

    if (e.elementType === 'cardCvc') {
      setCardCVCError(e?.error?.message);
    } else if (e.elementType === 'cardCvc' && e.complete === true) {
      setCardCVCError('');
    }
  };
  const validate = values => {
    const errors = {};

    if (values.postcode === null) {
      errors.postcode = 'Please add a  postcode';
    }

    return errors;
  };

  const handleDisable = () => {
    if (cardElemetError !== '' || cardExpiryError !== '' || cardCVCError !== '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validate,
  });

  return (
    <div>
      <div className="mt-3">
        <span className="font-16 color-charcoal font-sofia-pro-regular">Card Number</span>
        <CardNumberElement className="payment-option__card" onChange={handleChange} />
        <span className="font-12 color-charcoal font-sofia-pro-regular">{cardElemetError}</span>
      </div>
      <div className="mt-3">
        <span className="font-16 color-charcoal font-sofia-pro-regular">Expiry month / Year</span>
        <CardExpiryElement className="payment-option__expiry_element" onChange={handleChange} />
        <span className="font-12 color-charcoal font-sofia-pro-regular">{cardExpiryError}</span>
      </div>
      <div className="p-0 m-0 row mt-3">
        <div className="p-0 pr-1 col-sm-12 col-md-12 col-lg-12">
          <div>
            <span className="font-16 color-charcoal font-sofia-pro-regular">CVV</span>
            <CardCvcElement className="payment-option__cvv" onChange={handleChange} />
            <span className="font-12 color-charcoal font-sofia-pro-regular">{cardCVCError}</span>
          </div>
        </div>

        {/* <div className="p-0 col-sm-12 col-md-12 col-lg-6">
                    <span className="font-16 color-charcoal font-sofia-pro-regular">Postcode</span>
                    <input type="number" className="form-control h-60 shadow-none" />
                </div> */}
      </div>
      <div className="mt-3 d-flex flex-column">
        <span className="font-16 color-charcoal font-sofia-pro-regular">Postcode</span>
        <input
          className="payment-option__expiry_element"
          placeholder="Eg: 2122"
          name="postcode"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.postcode}
        />
        {formik.errors.postcode ? (
          <span className="font-12 color-charcoal font-sofia-pro-regular">{formik.errors.postcode}</span>
        ) : null}
      </div>
      <div className="my-2">
        <button
          className="w-150 btn bg-primary-color color-white h-44 font-sofia-pro-semi-bold font-16 outline-none"
          type="button"
          disabled={disableBtn}
          onClick={saveCardDetails}
        >
          Save
        </button>
      </div>
    </div>
  );
};
