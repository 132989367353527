/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import './Details.scss';
import { useFormik } from 'formik';
import { geocodeByLatLng, geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import MerchantService from '../../../../services/MerchantService';
import LocationService from '../../../../services/LocationService';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';

const initialValues = {
  name: '',
  description: '',
  isActive: false,
  isPublic: false,
};

const validate = values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  if (!values.description) {
    errors.description = 'Required';
  }

  return errors;
};

const EditStoreLocationPage = props => {
  const [editModalShow, setEditModalShow] = useState(false);

  const [storeData, setStoreData] = useState({});

  const [addressData, setAddressData] = useState({
    latitude: 0,
    longitude: 0,
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  });

  const [markerLocation, setMarkerLocation] = useState({
    lat: -33.86736585831316,
    lng: 151.2096683067536,
  });

  const [showLoader, setShowLoader] = useState(false);

  const mapStyles = {
    height: '50vh',
    width: '100%',
  };

  const [defaultCenter, setDefaultCenter] = useState({
    lat: -33.86736585831316,
    lng: 151.2096683067536,
  });

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  useEffect(() => {
    //  getMerchantStoreList();
    getStoreData(props.id);
  }, []);

  const onClick = event => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const position = { lat, lng };

    setMarkerLocation({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    });

    // initGeocoder(event);

    geocodeByLatLng(position).then(response => {
      const address = response[0].formatted_address;
      let city, state, country, postCode, street;
      for (let i = 0; i < response[0].address_components.length; i++) {
        for (let j = 0; j < response[0].address_components[i].types.length; j++) {
          switch (response[0].address_components[i].types[j]) {
            case 'locality':
              city = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
            case 'administrative_area_level_1':
              state = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
            case 'country':
              country = response[0].address_components[i].long_name
                ? response[0].address_components[i].short_name
                : null;
              break;
            case 'postal_code':
              postCode = response[0].address_components[i].long_name
                ? response[0].address_components[i].long_name
                : null;
              break;
            case 'route':
              street = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
          }
        }
      }
      // console.log(address);
      setAddressData({
        latitude: lat,
        longitude: lng,
        state: state,
        postcode: postCode,
        country: country,
        street: street,
        suburb: city,
      });

      formik.setFieldValue('description', address);
    });
  };

  const onSubmit = values => {
    setShowLoader(true);

    addressData['latitude'] = markerLocation.lat;
    addressData['longitude'] = markerLocation.lng;

    let data = values;

    data['address'] = addressData;

    delete data.description;

    MerchantService.updateStore(props.id, data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          props.closeModal();
          props.loadStoreData(props.id);
          Toast('Your changes to the store location have been saved successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate,
  });

  const getStoreData = id => {
    MerchantService.getStore(props.id).then(response => {
      if (response) {
        let data = response['data'];
        setMarkerLocation({
          lat: data.latitude,
          lng: data.longitude,
        });
        setDefaultCenter({
          lat: data.latitude,
          lng: data.longitude,
        });
        geocodeByAddress(data?.address).then(results => {
          let addressData = LocationService.getLocationAddress(results[0]);
          setAddressData({
            street: addressData.street,
            state: addressData.state,
            postcode: addressData.postCode,
            country: addressData.country,
            suburb: addressData.city,
          });
        });
        setStoreData(data);
        handleEditModalShow();

        formik.setFieldValue('name', data.name);
        formik.setFieldValue('description', data.address);
        formik.setFieldValue('isActive', data.isActive);
        formik.setFieldValue('isPublic', data.isPublic);
      }
    });
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 col-sm-12 col-md-6 col-lg-6">
        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Cafe name *</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle"
              name="name"
              placeholder="Eg: Manly Guild"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>
            <small className="pl-2 font-sofia-pro-regular color-charcoal font-10">
              (Click on the map to choose the address)
            </small>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle "
              name="description"
              placeholder="Eg: 6 The Corso, Manly, Sydney, NSW"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              disabled
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.description}
              </div>
            ) : null}
          </div>
          <div className="d-flex">
            <label className="transaction-location__container">
              <input
                type="checkbox"
                name="isActive"
                value={formik.values.isActive}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.isActive === true ? 'checked' : ''}
              />
              <span className="transaction-location__checkmark"></span>
            </label>

            <span className="font-14 font-sofia-pro-light color-gunmetal">Allow users to redeem thanks</span>
          </div>
          <div className="d-flex mt-2">
            <label className="transaction-location__container">
              <input
                type="checkbox"
                name="isPublic"
                value={formik.values.isPublic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.isPublic === true ? 'checked' : ''}
              />
              <span className="transaction-location__checkmark"></span>
            </label>

            <span className="font-14 font-sofia-pro-light color-gunmetal">Show cafe location on map</span>
          </div>
          <div className="d-flex mt-2">
            {/* <button type="submit" className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white">
                            Save
                                     </button> */}
            <Button
              classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
              children="Save"
              showLoader={showLoader}
              onClick={() => onSubmit}
            />
          </div>
        </form>
      </div>
      <div className="p-0 m-0 p-3 col-sm-12 col-md-6 col-lg-6">
        <div style={{ width: '100%' }}>
          {/* <LoadScript
                        googleMapsApiKey='AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs'
                    > */}
          <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter} onClick={onClick}>
            <Marker position={markerLocation} />
          </GoogleMap>
          {/* </LoadScript> */}
        </div>
      </div>
    </div>
  );
};

export default EditStoreLocationPage;
