/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Extracting details from google map response

const getLocationAddress = data => {
  let city, state, country, postCode, street, subpremise, street_number;
  for (let i = 0; i < data?.address_components.length; i++) {
    for (let j = 0; j < data?.address_components[i].types.length; j++) {
      switch (data?.address_components[i].types[j]) {
        case 'locality':
          city = data?.address_components[i].long_name ? data?.address_components[i].long_name : null;
          break;
        case 'administrative_area_level_1':
          state = data?.address_components[i].long_name ? data?.address_components[i].long_name : null;
          break;
        case 'country':
          country = data?.address_components[i].long_name ? data?.address_components[i].short_name : null;
          break;
        case 'postal_code':
          postCode = data?.address_components[i].long_name ? data?.address_components[i].long_name : null;
          break;
        case 'route':
          street = data?.address_components[i].long_name ? data?.address_components[i].long_name : null;
          break;
        case 'subpremise':
          subpremise = data?.address_components[i].long_name ? data?.address_components[i].long_name : null;
          break;
        case 'street_number':
          street_number = data?.address_components[i].long_name ? data?.address_components[i].long_name : null;
          break;
      }
    }
  }

  if (street_number === undefined) {
    street_number = '';
  }

  if (subpremise === undefined) {
    subpremise = '';
  }

  let address = {
    street: subpremise + '' + street_number + ' ' + street,
    city: city,
    state: state,
    country: country,
    postCode: postCode,
  };

  return address;
};

export default {
  getLocationAddress,
};
