/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const productDetailsAction = 'PRODUCT_DETAILS_ACTION';
const oneMoreToCardAction = 'ONE_MORE_TO_CARD_ACTION';
const oneMinusToCardAction = 'ONE_MINUS_TO_CARD_ACTION';
const resetCardAction = 'RESET_CARD_ACTION';
const addQuantityAction = 'ADD_QUANTITY';

let initialState = {
  details: {
    type:"Coffee"

  },
  productsCard: {},
  total: 0
};

export const addQuantityDispatch = (data) => ({
  type: addQuantityAction,
  payload: data
})

export const productDetailsDispatch = (data) => ({
  type: productDetailsAction,
  payload: data
});

export const oneMoreToCard = (data) => ({
  type: oneMoreToCardAction,
  payload: data
});

export const oneMinusToCard = (data) => ({
  type: oneMinusToCardAction,
  payload: data
});

export const resetCard = () => ({
  type: resetCardAction,
  payload: initialState
});

export const addProductCardReducer = persistReducer(
  {
    storage,
    key: 'appreci-product-card',
    whitelist: ['productCard']
  },
  (state = initialState, action) => {
    switch (action.type) {
      case productDetailsAction:
        return {
          ...state,
          details: { ...state.details, ...action.payload }
        };
      case oneMoreToCardAction:
        if (action?.payload?.prices?.length) {
          state.total += +action.payload?.prices[0].price;
        } else {
          state.total += +action.payload.price;
        }
        return {
          ...state,
          productsCard: {
            ...action.payload,
            quantity: state.productsCard.quantity ? ++state.productsCard.quantity : 1
          }
        };
      case oneMinusToCardAction:
        let productsMinus;
        if (state.productsCard.quantity > 1) {
          productsMinus = {
            ...state,
            productsCard: {
              ...state.productsCard,
              quantity: --state.productsCard.quantity
            },
            total: action?.payload?.prices?.length ?
              +state.total - +action.payload.prices[0].price:
              +state.total - +action.payload.price
          };
        } else {
          productsMinus = {
            ...state,
            productsCard: {
              ...state.productsCard,
              quantity: 0
            },
            total: 0
          };
        }
        return { ...productsMinus };
      case resetCardAction:
        return {
          ...state,
          productsCard: {},
          total: 0
        };
      case addQuantityAction: 
        if (action?.payload?.prices?.length) {
          state.total = action.payload?.prices[0].price * action.payload.quantity;
        } else {
          state.total = action.payload.price * action.payload.quantity;
        }
        return {
          ...state,
          productsCard: {
            ...action.payload
          }
        };
      default:
        return state;
    }
  }
);
