/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import MerchantProfileBusinessDetailsPage from './businessDetails/BusinessDetails';
import MerchantProfilePaymentPage from './payment/Payment';
import MerchantProfilePersonalDetailsPage from './personalDetails/PersonalDetails';
import MerchantProfilePasswordPage from './password/Password';
import Header from '../../../common/Header/Header';
import './Profile.scss';
import BackIcon from '../../../assets/images/common/icons-back.png';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';

function MerchantProfilePage(props) {
  const [profileMenuIndex, setProfileMenuIndex] = useState();

  const profileMenBtnHandler = index => {
    setProfileMenuIndex(index);
  };

  useEffect(() => {
    setProfileMenuIndex(0);
  }, []);

  return (
    <div>
      <div className="bg-alice-blue">
        <div className="p-2 d-flex justify-content-between bg-white">
          <Logo />
          <Header />
        </div>
        <div className="p-0 m-0 row">
          <div className="p-0 m-0 col-sm-12 col-md-4 col-lg-4">
            <div className="p-0 m-0 row">
              <div className="p-0 m-0 col-lg-2">&nbsp;</div>

              <div className="p-0 m-0 mt-2 col-sm-12 col-md-5 col-lg-4">
                <Link to="/merchant/dashboard">
                  <button className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none">
                    <img src={BackIcon} height="30px" />
                    Back
                  </button>
                </Link>
              </div>
              <div className="p-0 m-0 mt-5 col-sm-12 col-md-7 col-lg-6">
                <ul className="p-0 m-0 my-3 list-style-none">
                  <li
                    className={
                      profileMenuIndex === 0
                        ? 'mt-3 font-16 color-primary font-sofia-pro-semi-bold cursor-pointer'
                        : 'mt-3 font-16 color-gunmetal font-sofia-pro-light cursor-pointer'
                    }
                    onClick={profileMenBtnHandler.bind(this, 0)}
                  >
                    Business details
                  </li>
                  <li
                    className={
                      profileMenuIndex === 1
                        ? 'mt-3 font-16 color-primary font-sofia-pro-semi-bold cursor-pointer'
                        : 'mt-3 font-16 color-gunmetal font-sofia-pro-light cursor-pointer'
                    }
                    onClick={profileMenBtnHandler.bind(this, 1)}
                  >
                    Payment
                  </li>
                  <li
                    className={
                      profileMenuIndex === 2
                        ? 'mt-3 font-16 color-primary font-sofia-pro-semi-bold cursor-pointer'
                        : 'mt-3 font-16 color-gunmetal font-sofia-pro-light cursor-pointer'
                    }
                    onClick={profileMenBtnHandler.bind(this, 2)}
                  >
                    Personal details
                  </li>
                  <li
                    className={
                      profileMenuIndex === 3
                        ? 'mt-3 font-16 color-primary font-sofia-pro-semi-bold cursor-pointer'
                        : 'mt-3 font-16 color-gunmetal font-sofia-pro-light cursor-pointer'
                    }
                    onClick={profileMenBtnHandler.bind(this, 3)}
                  >
                    Password
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-0 m-0 mt-5 col-sm-12 col-md-8 col-lg-8">
            <div className="p-0 m-0 row">
              <div className="p-0 m-0 my-3 col-sm-12 col-md-10 col-lg-8">
                <div className="p-0 m-0 card border-0">
                  {profileMenuIndex === 0 && <MerchantProfileBusinessDetailsPage />}
                  {profileMenuIndex === 1 && <MerchantProfilePaymentPage />}
                  {profileMenuIndex === 2 && <MerchantProfilePersonalDetailsPage />}
                  {profileMenuIndex === 3 && <MerchantProfilePasswordPage />}
                </div>
              </div>
              <div className="p-0 m-0 col-sm-12 col-md-2 col-lg-4">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantProfilePage;
