import React from 'react';
import ItemCheckedImage from '../../../assets/images/common/icons-check-filled-pink.png';

const EmptyBox = ({subProduct, product, price, className, style, label, onProductClick, isSelected, onClicked, isProductSelected }) => {
    const handleClick = () => {
        if(product) {
          onProductClick(product);
        } else {
          onProductClick(subProduct);
        }
      };
      const containerStyle = {
        ...style,
        height: 'auto',
      };

      const first_part = label.split(",")[0];
      const extracted_text = label.split(",")[1]
      // const first_part = label.split(" - ")[0]
      console.log("----first_part----", first_part);
      return (
        <>
          {product ? (
            <div
              className={`${className} ${isProductSelected ? 'border-primary-color-2' : ''}`}
              style={style}
              onClick={() => {
                  handleClick();
                  onClicked();
              }}
            >
              {isProductSelected && (
                <div className={`position-relative w-50`}>
                  <img src={ItemCheckedImage} height="25px" width="25px" className="select-item_checked" />
                </div>
              )}
              <div className='d-flex flex-column align-items-start' style={{ margin: '5px' }}>
                <label className="font-sofia-pro-regular color-charcoal font-14">{label}</label>
                <p className='font-sofia-pro-semi-light color-charcoal font-14'>From <b>{price}</b></p>
              </div>
            </div>
          ) : subProduct ? (
            <div
              className={`${className} ${isSelected ? 'border-primary-color-2' : ''}`}
              style={containerStyle}
              onClick={() => {
                  handleClick();
                  onClicked();
              }}
            >
              {isSelected && (
                <div className={`position-relative w-50`}>
                  <img src={ItemCheckedImage} height="25px" width="25px" className="select-item_checked" />
                </div>
              )}
              {/* <div className='d-flex flex-column justify-content-start pb-4'> */}
                <div className='d-flex align-items-start position-relative pb-3' style={{ margin: '5px' }}>
                  <label className="font-sofia-pro-regular color-charcoal font-14" style={{ maxWidth: '78%', wordWrap: 'break-word' }}>{first_part}</label>
                  <div className='position-absolute' style={{ right: '8px', top: 0 }}>
                    <p className="font-sofia-pro-regular color-charcoal font-14" style={{ marginLeft: '28%', marginBottom: 0 }}>
                      <b>{price}</b>
                    </p>
                  </div>
                </div>
                {/* <p className='font-sofia-pro-regular font-14 ml-1' style={{color: '#384348'}}>{extracted_text}</p> */}
              {/* </div> */}
            </div>
          ) : null}
        </>
      )
};

export default EmptyBox;
