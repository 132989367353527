/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { Bar } from 'react-chartjs-2';
import './RoundedBarCorners';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import useWindowDimensions from '../../../common/Responsive/WindowDimensions';
import SideBar from '../../../common/Sidebar/Sidebar';
import SliderAutorotate from '../../../common/component/SliderAutorotate';
import Header from '../../../common/Header/Header';
import DashboardService from '../../../services/DashboardService';
import SendThanksService from '../../../services/SendThanksService';
import { resetSendThanksDetails } from '../../../store/reducers/individual/sendThanks/sendThanks';
import { productDetailsDispatch } from '../../../store/reducers/multiProduct/anonMultiProducts';
import httpService from '../../../services/HttpService';
import { useDispatch } from 'react-redux';
import './DashBoard.scss';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as SendThanksActive } from '../../../assets/images/dashboard/icons/icons-top-actions-send-thanks.svg';
import { ReactComponent as Components1 } from '../../../assets/images/dashboard/icons/components-illustrations-empty-state-2.svg';
import { ReactComponent as Components2 } from '../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';
import { ReactComponent as RedeemActive } from '../../../assets/images/dashboard/icons/icons-main-navigation-gift-active.svg';
import { ReactComponent as CaretDown } from '../../../assets/images/dashboard/icons/icons-caret-down.svg';
import { ReactComponent as Sent } from '../../../assets/images/dashboard/icons/icons-sent.svg';
import { ReactComponent as Recive } from '../../../assets/images/dashboard/icons/icons-received.svg';
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import WineGreen from '../../../assets/images/send_thanks/Wine green.png';
import WineYellow from '../../../assets/images/send_thanks/Wine yellow.png';
import WinePink from '../../../assets/images/send_thanks/Wine pink.png';
import BeerBlue from '../../../assets/images/send_thanks/Beer blue.png';
import BeerGray from '../../../assets/images/send_thanks/Beer gray.png';
import BeerGreen from '../../../assets/images/send_thanks/Beer green.png';
import BeerPink from '../../../assets/images/send_thanks/Beer pink.png';
import BeerRed from '../../../assets/images/send_thanks/Beer red.png';
import ChocolateGray from '../../../assets/images/send_thanks/Chocolate gray.png';
import ChocolateWhite from '../../../assets/images/send_thanks/Chocolate white.png';
import SkipImage from '../../../assets/images/send_thanks/Skip.png';
import Gift from '../../../assets/images/send_thanks/gift.gif';
import NotificationList from '../../../common/Notification/NotificationList';
import SiteLogo from '../../../assets/images/logo/appreci-sqaure-logo.svg';
// ReactGA.pageview(window.location.pathname + window.location.search);

const DashBoard = () => {
  let userData = JSON.parse(localStorage.getItem('userData'));
  const Name = userData['firstName'] + ' ' + userData['lastName'];
  const [active, setActive] = useState(JSON.parse(sessionStorage.getItem('greeting')));
  const [dashboardData, setDashboardData] = useState([]);
  const [product, setProduct] = useState([]);
  const [showGraph, setshowGraph] = useState('daily');
  const [recivedData, setrecivedData] = useState([]);
  const [sentData, setsentData] = useState([]);
  const [redeemData, setredeemData] = useState([]);
  const [label, setLable] = useState([]);
  const [btnShow, setBtnShow] = useState(false);
  const [dayShow, setdayShow] = useState(true);
  const [monthShow, setmonthShow] = useState(false);
  const [yearShow, setyearShow] = useState(false);
  const [selectFile, setSelectFile] = useState(null);
  const [selectSecretFile, setSelectSecretFile] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [audioVideoUrl, setAudioVideoUrl] = useState(null);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      setActive(false);
      sessionStorage.setItem('greeting', false);
    }, 3000);
  });
  useEffect(() => {
    if (selectFile && selectSecretFile != null) {
      GetStoredFile();
    }
  });
  useEffect(() => {
    DashboardDataList();
    SendServiceServiceList();
    GraphCall(showGraph);
    dispatch(resetSendThanksDetails(undefined));
    dispatch(productDetailsDispatch({ type: 'Coffee' }));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // graph time change function
  const YearlyShow = () => {
    setBtnShow(!btnShow);
    GraphCall('yearly');
    setmonthShow(false);
    setdayShow(false);
    setyearShow(true);
  };

  const MonthlyShow = () => {
    setBtnShow(!btnShow);
    GraphCall('monthly');
    setmonthShow(true);
    setdayShow(false);
    setyearShow(false);
  };
  const DailyShow = () => {
    setBtnShow(!btnShow);
    GraphCall('daily');
    setmonthShow(false);
    setdayShow(true);
    setyearShow(false);
  };
  //get secret file key for video and audio message
  const GetStoredFile = () => {
    httpService
      .get(`StoredFiles/${selectFile}`, {
        headers: {
          fileSecret: selectSecretFile,
        },
      })
      .then(res => {
        setAudioVideoUrl(res.data['url']);
      });
  };

  const timeShow = () => {
    setBtnShow(!btnShow);
  };

  const GraphCall = count => {
    DashboardService.getGraphDataDaily(count).then(res => {
      setrecivedData(
        res.data['received'].map(val => {
          return val['number'];
        })
      );
      setredeemData(
        res.data['redeemed'].map(val => {
          return val['number'];
        })
      );
      setsentData(
        res.data['sent'].map(val => {
          return val['number'];
        })
      );
      setLable(
        res.data['received'].map(val => {
          if (count === 'daily') {
            return Moment(val['date']).format('DD/M/YY');
          } else if (count === 'yearly') {
            return Moment(val['date']).format('YYYY');
          } else if (count === 'monthly') {
            return Moment(val['date']).format('MMMM');
          }
        })
      );
    });
  };

  const DashboardDataList = () => {
    httpService
      .get('Dashboards/individual')
      .then(res => {
        setDashboardData(res.data);
      })
      .catch(error => {
        if (error) {
          console.log(error);
        }
      });
  };
  // added product data to dashboard data
  product.map(value => {
    if (dashboardData['lastThreeTransactions'] !== null && dashboardData['lastThreeTransactions'] !== undefined) {
      dashboardData['lastThreeTransactions'].map((val, index) => {
        if (value['price']?.priceId === val?.priceId) {
          dashboardData['lastThreeTransactions'][index]['Type'] = value['type'];
        }
      });
    }
  });
  const SendServiceServiceList = () => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        setProduct(
          res.data.map(val => {
            return val;
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  const AudioVideoMessagePopup = props => {
    return (
      <Modal {...props} size="lg" centered className="redeemwine-background_popup" animation={false} backdrop="static">
        <Modal.Body className="my-3">
          <button className="border-0 bg-transparent redeemthanks-cancel__button " onClick={props.onHide}>
            <Close />
          </button>
          <div className=" transactionhistory-card__message d-flex align-items-center justify-content-center ">
            {messageType === 'Audio' ? (
              <audio controls className="">
                <source src={audioVideoUrl} type="audio/mp3" className="reseemthanks-audio__style" />
              </audio>
            ) : messageType === 'Video' ? (
              <video controls width="150">
                <source src={audioVideoUrl} className="reseemthanks-audio__style" />
              </video>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const Graph = () => {
    const data = {
      labels: label,

      datasets: [
        {
          label: 'Redeemed',
          data: redeemData,
          backgroundColor: '#c8d7ea',
        },
        {
          label: 'Received',
          data: recivedData,
          backgroundColor: '#ffb3e3',
        },
        {
          label: 'Sent',
          data: sentData,
          backgroundColor: '#e76fb5',
        },
      ],
    };

    const options = {
      hover: { mode: null },
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              padding: 10,
            },
            gridLines: {
              color: '#e9ebf1',
              drawTicks: false,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              padding: 10,
              fontSize: 12,
            },
            gridLines: {
              color: '#e9ebf1',
              drawTicks: false,
            },
          },
        ],
      },
      legend: false,
      animation: false,
      cornerRadius: 12,

      tooltips: {
        backgroundColor: '#ffffff',
        titleFontColor: '#231f20',
        titleFontStyle: 'font-sofia-pro-regular',
        bodyFontColor: '#8597a0',
        bodyFontStyle: 'font-sofia-pro-light',
        titleFontSize: 14,

        callbacks: {
          title: function (tooltipItems, data) {
            return tooltipItems[0]['value'];
          },
          label: function (tooltipItems, data) {
            return data.datasets[tooltipItems['datasetIndex']]['label'];
          },
        },
      },
    };

    return (
      <div>
        <Bar data={data} options={options} height={262} width={686} />
      </div>
    );
  };
  const AudioMessageShow = index => {
    setSelectFile(dashboardData['lastThreeTransactions'][index]['message']['storedFile']);
    setSelectSecretFile(dashboardData['lastThreeTransactions'][index]['message']['storedFileSecret']);
    setMessageType(dashboardData['lastThreeTransactions'][index]['message']['type']);
    setShowModal(true);
  };

  const Transaction = () => {
    if (
      dashboardData['lastThreeTransactions'] !== null &&
      dashboardData['lastThreeTransactions'] !== undefined &&
      dashboardData['lastThreeTransactions'].length > 0
    ) {
      return (
        <div className=" border-0 dashboard-transaction_card">
          <div className="d-flex flex-row justify-content-between  py-2">
            <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
            <Link className=" my-1 px-2 py-1" style={{ textDecoration: 'none' }} to="/transactionhistory">
              <span className="dashboard-text_viewall font-sofia-pro-regular ">View all</span>
            </Link>
          </div>
          <div className="dashboard-line__latesttransaction my-2" />
          <div className="table-responsive-sm table-responsive-md ">
            <table className="table">
              <thead>
                <tr>
                  <td scope="col" className="font-sofia-pro-light  border-0">
                    User information
                  </td>
                  <td scope="col" className="font-sofia-pro-light  border-0">
                    Date sent
                  </td>
                  <td scope="col" className="font-sofia-pro-light border-0 ">
                    Type
                  </td>
                  <td scope="col" className="font-sofia-pro-light border-0 ">
                    Product
                  </td>

                  <td scope="col" className="font-sofia-pro-light border-0">
                    Note
                  </td>
                </tr>
              </thead>

              <tbody>
                <div className="dashboard-line__latesttransaction " />

                {dashboardData['lastThreeTransactions'].map((value, index) => {
                  var stilUtc = Moment.utc(value['date']).toDate();
                  var local = Moment(stilUtc).local();
                  return (
                    <tr key={index}>
                      <td scope="row" className="border-0">
                        <div className="d-flex flex-row  ">
                          {value['user'] !== null ? (
                            value['user']['profilePhotoUrl'] === null ? (
                              <ProfilePic className="dashboard-oval_transaction " />
                            ) : (
                              <img
                                src={value['user']['profilePhotoUrl'] + '?h=15&w=15'}
                                className="dashboard-image__Oval"
                              />
                            )
                          ) : (
                            <ProfilePic className="dashboard-oval_transaction " />
                          )}
                          {value['user'] !== null ? (
                            <div className="d-flex flex-column">
                              <span className="dashboard-name_transaction px-2 font-sofia-pro-medium">
                                {value['user']['fullName'] != '' ? value['user']['fullName'] : 'Unknown'}
                              </span>
                            </div>
                          ) : value['companyName'] !== null ? (
                            <div className="d-flex flex-column">
                              <span className="dashboard-name_transaction px-2 font-sofia-pro-medium">
                                {value['companyName']}
                              </span>
                            </div>
                          ) : (
                            'Unknown'
                          )}
                        </div>
                      </td>
                      <td className="border-0 my-2">
                        <span className="dashboard-date_transaction  font-sofia-pro-light ">
                          {Moment(local).format('DD/MM/YYYY')}
                        </span>
                      </td>
                      <td className="border-0 ">
                        {' '}
                        <div className="d-flex flex-row ">
                          {value['type'] === 'GiftSent' ? (
                            <>
                              <Sent />
                              <span className="dashboard-date_transaction px-2 font-sofia-pro-light">Sent</span>
                            </>
                          ) : value['type'] === 'GiftReceived' ? (
                            <>
                              <Recive />
                              <span className="dashboard-date_transaction px-2 font-sofia-pro-light">Received</span>
                            </>
                          ) : value['type'] === 'GiftRedeemed' ? (
                            <>
                              <span className="dashboard-date_transaction px-2 font-sofia-pro-light">Redeemed</span>
                            </>
                          ) : value['type'] === 'GiftPurchase' ? (
                            <>
                              <Sent />
                              <span className="dashboard-date_transaction px-2 font-sofia-pro-light">Sent</span>
                            </>
                          ) : value['type'] === 'GiftCancelled' ? (
                            <span className="dashboard-date_transaction px-2 font-sofia-pro-light">Cancelled</span>
                          ) : (
                            value['type']
                          )}
                        </div>
                      </td>
                      <td className="border-0">
                        {' '}
                        <span className="dashboard-coffee font-sofia-pro-regular mx-1 ">
                          {value['priceId'] !== null ? value['Type'] : '-'}
                        </span>
                      </td>
                      <td className="border-0">
                        <span className="dashboard-heythanks font-sofia-pro-light  dashboard-table_notes">
                          <span className="dashboard-coffee font-sofia-pro-regular mx-1 ">
                            {value['message'] != null ? (
                              value['message']['type'] === 'Text' ? (
                                value['message']['message'] !== null &&
                                value['message']['message'].trim().length !== 0 ? (
                                  <span>{value['message']['message']}</span>
                                ) : (
                                  <span>No message</span>
                                )
                              ) : value['message']['type'] === 'Video' ? (
                                <>
                                  <button
                                    className="bg-transparent border-0"
                                    onClick={AudioMessageShow.bind(this, index)}
                                  >
                                    <span className="redeemthanks-button__textvideo font-sofia-pro-regular">
                                      Video message
                                    </span>
                                  </button>
                                  <AudioVideoMessagePopup show={showModal} onHide={() => setShowModal(false)} />
                                </>
                              ) : value['message']['type'] === 'Audio' ? (
                                <>
                                  <button
                                    className="bg-transparent border-0"
                                    onClick={AudioMessageShow.bind(this, index)}
                                  >
                                    <span className="redeemthanks-button__textvideo font-sofia-pro-regular dashboard-table_notes">
                                      Audio message
                                    </span>
                                  </button>
                                  <AudioVideoMessagePopup show={showModal} onHide={() => setShowModal(false)} />
                                </>
                              ) : (
                                <span className="font-sofia-pro-regular text-break">Unknown</span>
                              )
                            ) : (
                              'No message'
                            )}
                          </span>
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card border-0">
          <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
          <div className="dashboard-components1">
            <Components2 />
          </div>
          <div className="dashboard-components1 my-2">
            <span className="dashboard__trysendingyourfir font-sofia-pro-medium my-2">
              Try sending your first gift,
              <Link style={{ textDecoration: 'none' }} to="/recipientInformation">
                <span className="dashboard-text__style1">Try now</span>
              </Link>
            </span>
          </div>
        </div>
      );
    }
  };
  const ChartState = () => {
    if (
      dashboardData['lastThreeTransactions'] === null ||
      dashboardData['lastThreeTransactions'] === undefined ||
      dashboardData['lastThreeTransactions'].length === 0
    ) {
      return (
        <div>
          <div className="row mx-2 ">
            <span className="dashboard-redeemed__thankschar p-2 font-sofia-pro-medium ">Redeemed Chart</span>
          </div>
          <div className="dashboard-components1">
            <Components1 />
          </div>
          <div className="dashboard-components1">
            <span className="dashboard__uhohnochartsyet font-sofia-pro-medium">Uh-oh,no charts yet!</span>
            <div className="my-2">
              <span className="dashboard__trysendingthanks font-sofia-pro-light">
                Try sending thanks to your mate <span>&#128521;</span>
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="row my-2  ">
            <span className="dashboard-redeemed__thankschar p-2 font-sofia-pro-medium  mx-5 ">Redeemed Chart</span>
            <div className="d-flex flex-column flex-lg-row my-3 mx-3 mx-lg-4">
              <div className="d-flex">
                <div className="d-flex flex-row ">
                  <div className="border-radius-5 dashboard-chart__sent my-1 mx-2" />
                  <span className="dasboard-chart__Senttext font-sofia-pro-light">Sent</span>
                </div>
                <div className="d-flex flex-row mx-2">
                  <div className="border-radius-5 dashboard-chart__Recived my-1 mx-2" />
                  <div className="d-flex ">
                    <span className="dasboard-chart__Senttext font-sofia-pro-light ">Received</span>
                  </div>
                </div>
                <div className="d-flex flex-row ">
                  <div className="border-radius-5 dashboard-chart__Redeemed my-1 mx-2" />
                  <span className="dasboard-chart__Senttext font-sofia-pro-light">Redeemed</span>
                </div>
              </div>
              <div className=" mx-lg-2 mx-1 my-lg-0 my-1 d-flex">
                {btnShow === false ? (
                  <button className="dashboard-button__daily " onClick={timeShow}>
                    <div className="justify-content-around row">
                      <span className="font-sofia-pro-light dashbord-text__Daily mx-2">
                        {dayShow === true
                          ? 'Daily'
                          : monthShow === true
                          ? 'Monthly'
                          : yearShow === true
                          ? 'Yearly'
                          : ''}
                      </span>

                      <CaretDown />
                    </div>
                  </button>
                ) : (
                  <div className="dashboard-buttom__graphClicked ">
                    <button className="border-0 bg-transparent w-100" onClick={timeShow}>
                      <div className="justify-content-around row">
                        <span className="font-sofia-pro-light dashbord-text__Daily mx-2">
                          {dayShow === true
                            ? 'Daily'
                            : monthShow === true
                            ? 'Monthly'
                            : yearShow === true
                            ? 'Yearly'
                            : ''}
                        </span>

                        <CaretDown />
                      </div>
                    </button>
                    <div className="dashboard-line__graph" />
                    {monthShow === true ? (
                      <button className="border-0 bg-transparent" onClick={DailyShow}>
                        <div className="justify-content-ariund">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Daily</span>
                        </div>
                      </button>
                    ) : (
                      <button className="border-0 bg-transparent" onClick={MonthlyShow}>
                        <div className="justify-content-between">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Monthly</span>
                        </div>
                      </button>
                    )}

                    <div className="dashboard-line__graph" />
                    {yearShow === true ? (
                      <button className="border-0 bg-transparent" onClick={DailyShow}>
                        <div className="justify-content-ariund">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Daily</span>
                        </div>
                      </button>
                    ) : (
                      <button className="border-0 bg-transparent" onClick={YearlyShow}>
                        <div className="justify-content-between">
                          <span className="font-sofia-pro-light dashbord-text__Daily mx-2">Yearly</span>
                        </div>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard-chart ">
            <Graph />
          </div>
        </div>
      );
    }
  };
  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex flex-column">
        <SideBar menu="Home" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half ">
        <Header />
        <div className="d-flex flex-column my-4">
          {active === true ? (
            <span className=" font-sofia-pro-light dashboard-name__goodmorning my-2 mx-3">Welcome {Name} ! </span>
          ) : null}
        </div>
        {width > 700 ? (
          <div className="p-0 m-0 row my-2 mx-3">
            <div className=" col-sm-12 col-md-4 col-lg-4 ">
              <Link to="/redeemthanks" style={{ textDecoration: 'none' }}>
                <div
                  className={`card px-2 border-0 pt-sm-2 pt-md-2  dashboard-card_hieght  ${
                    dashboardData['thanksAvailable'] > 0 && 'justify-content-between'
                  }`}
                >
                  <span className={'dashboard__availablethanks font-sofia-pro-medium mx-2 my-lg-2 mx-md-0 my-md-0'}>
                    Gifts Available
                  </span>
                  <div className={`d-flex justify-content-between`}>
                    <span className={'dashboard__available_thanksvalue font-sofia-pro-medium mx-2 '}>
                      {dashboardData['thanksAvailable']}
                    </span>
                    <div>
                      {dashboardData['thanksAvailable'] > 0 && <img src={Gift} alt="" className="gift-img_100" />}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className=" col-sm-12 col-md-4 col-lg-4 ">
              <div className={'card px-2 border-0 pt-sm-2 pt-md-2  dashboard-card_hieght'}>
                <span className={'dashboard__availablethanks font-sofia-pro-medium mx-2 my-lg-2 mx-md-0 my-md-0'}>
                  Redeemed
                </span>
                <span className={'dashboard__available_thanksvalue font-sofia-pro-medium mx-2 my-lg-0 my-md-4'}>
                  {dashboardData['thanksRedeemed']}
                </span>
              </div>
            </div>
            <div className=" col-sm-12 col-md-4 col-lg-4 dashboard-mobile_card">
              <div className={'card px-2 border-0 pt-sm-2 pt-md-2  dashboard-card_hieght'}>
                <span className={'dashboard__availablethanks font-sofia-pro-medium mx-2 my-lg-2 mx-md-0 my-md-0'}>
                  Sent
                </span>
                <span className={'dashboard__available_thanksvalue font-sofia-pro-medium mx-2 my-lg-0 my-md-4'}>
                  {dashboardData['thanksSent']}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-row mx-1 justify-content-around">
            <Link to="/redeemthanks" style={{ textDecoration: 'none' }}>
              <div className="d-flex card border-0 flex-column dashboard-mobile__card">
                <span className=" font-sofia-pro-medium dashboard-mobile__dashboardData mx-2">Gifts Available</span>
                <div className={`d-flex justify-content-between`}>
                  <span className={' font-sofia-pro-medium mx-2 my-3  '}>{dashboardData['thanksAvailable']}</span>
                  <div>{dashboardData['thanksAvailable'] > 0 && <img src={Gift} alt="" className="gift-img_50" />}</div>
                </div>
              </div>
            </Link>
            <div className="d-flex flex-column  card border-0 dashboard-mobile__card">
              <span className=" font-sofia-pro-medium dashboard-mobile__dashboardData mx-2">Redeemed</span>
              <span className={' font-sofia-pro-medium mx-2 my-3 '}>{dashboardData['thanksRedeemed']}</span>
            </div>
            <div className="d-flex flex-column  card border-0  dashboard-mobile__card">
              <span className=" font-sofia-pro-medium font-9 dashboard-mobile__dashboardData mx-2">Sent</span>
              <span className=" font-sofia-pro-medium mx-2 my-3">{dashboardData['thanksSent']}</span>
            </div>
          </div>
        )}
        {width <= 600 && (
          <div className="card my-4 border-0 p-2 mx-3">
            <span className="dashboard__topactiontext mx-1 my-1 font-sofia-pro-medium mx-3">Top actions</span>
            <div className="mx-2 my-3 top-action-buttons">
              <div>
                <Link to="/selectItem" className=" px-2" style={{ textDecoration: 'none' }}>
                  <button className="dashboard-card_send w-100  dashboard-button">
                    <SendThanksActive />
                    <span className="dashboard__sendthanks font-sofia-pro-medium">Send</span>
                  </button>
                </Link>
              </div>
              <div className="my-2">
                <Link to="/redeemthanks" className="mx-2 my-3" style={{ textDecoration: 'none' }}>
                  <button className="dashboard-card_redeem  w-100 dashboard-button">
                    <RedeemActive />
                    <span className="dashboard__sendthanks font-sofia-pro-medium">Redeem</span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex row w-100 p-0 m-0">
          <div className="col-12 col-sm-12 col-md-12 col-lg-8 row p-0 m-0 ">
            <div className="col-12 col-sm-12  my-4">
              <div className="dashboard-chart border-0 my-2 ">
                <Tabs defaultActiveKey="notifications" id="redeemedChart-tab-notifications" className="mb-3 tabClass">
                  <Tab eventKey="notifications" title="Notifications">
                    <div className=" h-50  border-0 position-sticky overflow-auto">
                      <NotificationList role="personal" />
                    </div>
                  </Tab>
                  <Tab eventKey="redeemedChart" title="Redeemed chart">
                    <ChartState />
                  </Tab>
                </Tabs>
              </div>
              <div className="dashboard-chart border-0 ">
                <Transaction />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 row p-0 m-0">
            <div className="  col-sm-12 col-md-12 col-lg-12 d-flex flex-column dashboard-mobile__topactioncard ">
              {width > 600 && (
                <div className="card my-4 border-0 p-2">
                  <span className="dashboard__topactiontext mx-1 my-1 font-sofia-pro-medium mx-3">Top actions</span>
                  <div className="mx-2 my-3">
                    <div>
                      <Link to="/selectItem" className=" px-2" style={{ textDecoration: 'none' }}>
                        <button className="dashboard-card_send px-5 py-2 w-100  dashboard-button">
                          <SendThanksActive />
                          <span className="dashboard__sendthanks font-sofia-pro-medium">Send</span>
                        </button>
                      </Link>
                    </div>
                    <div className="my-2">
                      <Link to="/redeemthanks" className="mx-2 my-3" style={{ textDecoration: 'none' }}>
                        <button className="dashboard-card_redeem w-100 dashboard-button">
                          <RedeemActive />
                          <span className="dashboard__sendthanks font-sofia-pro-medium">Redeem</span>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              
              {/* <div className="card border-0">
                <div className='monteserrat-bold'>Announcements</div>
                <div className="bd-grey-color2 mx-3 px-2 py-4">
                  <div className="font-sofia-pro-bold">
                    <img src={SiteLogo} alt="" className="site-logo-appreci mr-2" />
                    Birthday Remainder
                  </div>
                  <div className="font-sofia-pro-medium pt-3">Hey! Mike,</div>
                  <div className="d-flex line-height-30 font-sofia-pro-light">
                    <div>
                      Did you know that Sarah is having a birthday in 14 days? Appreci can help you with a birthday gift
                    </div>
                    <div>
                      <ProfilePic height={40} />
                    </div>
                  </div>
                  <div className="py-4 font-sofia-pro-light">
                    Would you like Apprecito help you arrange a gift for Sarah?
                  </div>
                  <div>
                    <button className='w-90 btn h-40 font-sofia-pro-semi-bold font-16'>Cancel</button>
                    <button className='w-90 btn bg-primary-color color-white h-40 font-sofia-pro-semi-bold font-16 outline-none'>Send</button>
                  </div>
                </div>
              </div> */}
              <div className="card my-4 border-0 p-2">
                <span className="dashboard__topactiontext mx-1 my-1 font-sofia-pro-medium mx-3">Gift Options</span>
                <SliderAutorotate
                  images={[
                    WineGreen,
                    WineYellow,
                    WinePink,
                    BeerBlue,
                    BeerGray,
                    BeerGreen,
                    BeerPink,
                    BeerRed,
                    ChocolateGray,
                    ChocolateWhite,
                    SkipImage,
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
