/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Router, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import httpService from '../../../services/HttpService';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import FalilureImage from '../../../assets/images/redeem_thanks/image/components-illustrations-claim-failure.png';
import ClaimSuccessImage from '../../../assets/images/redeem_thanks/image/components-illustrations-claim-success.png';
import { ReactComponent as ClaimCode } from '../../../assets/images/redeem_thanks/icons/icons-side-menu-claim-code.svg';

const OrganisationClaimCodePage = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [claimClicked, setclaimClicked] = useState(false);
  const Input = () => {
    return (
      <input
        placeholder="Your code goes here "
        autoFocus={true}
        onChange={formik.handleChange}
        name="code"
        value={formik.values.code}
        className="border-0 p-3"
      />
    );
  };
  const initialValues = {
    code: '',
  };
  const CloseBoth = () => {
    setclaimClicked(false);
    setShowSuccess(false);
  };
  const YesClaim = () => {
    if (formik.values.code.length > 0) {
      httpService
        .put(`Gifts/bycode/${formik.values.code}/claim`)
        .then(res => {
          setShowSuccess(true);
        })
        .catch(error => {
          setShowError(true);
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    } else if (formik.values.code.length === 0) {
      toast.error('Please enter a code');
    }
  };
  const ClaimCodeButton = props => {
    return (
      <Modal
        {...props}
        size="md"
        centered
        className="header-background_popup redeem-coffee__modal"
        animation={false}
        backdrop="static"
        autoFocus={false}
      >
        <Modal.Body>
          <span className="header-text__Claim-a-code2 font-sofia-pro-medium">Claim a code</span>

          <button className="border-0 header-button__cancelicongreen" onClick={() => setclaimClicked(false)}>
            <Close />
          </button>

          <div className="my-3">
            <span className="font-sofia-pro-regular header-text__entercode">Enter your code below</span>
            <div className="header-Rectangle card d-flex justify-content-center my-1">
              <Input />
            </div>
            <div className="row mx-2">
              <button className="border-0 header-button__Claim my-3" onClick={YesClaim}>
                <span className="font-sofia-pro-semi-bold header-text__claim">Claim</span>
              </button>
              <button
                className="border-0 bg-transparent mx-3"
                onClick={() => {
                  setclaimClicked(false);
                }}
              >
                <span className="font-sofia-pro-medium header-text__Cancel">Cancel</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const ClaimSuccess = prop => {
    return (
      <Modal
        {...prop}
        size="md"
        centered
        className="header-background_popup redeem-coffee__modal"
        animation={false}
        backdrop="static"
      >
        <Modal.Body className="d-flex flex-column justify-content-center">
          <div className="d-flex justify-content-center">
            <img src={ClaimSuccessImage} />
          </div>

          <button className="header-button__canceliconsuccess border-0 w-0" onClick={CloseBoth}>
            <Close />
          </button>

          <span className="font-sofia-pro-regular header-text__Code-claimed-success">Code claimed successfully!</span>
          <span className="font-sofia-pro-light header-text__Your-thanks-balance">
            Your thanks balance is updated, go forth and send thanks to your loved ones now
          </span>
          <div class="row m-4">
            <Link to="recipientInformation">
              <button className="header-buttton__tryagain border-0">
                <span className="font-sofia-pro-semi-bold header-text__claim">Send now</span>
              </button>
            </Link>

            <Link to="/dashboard">
              <button className="header-button__home bg-transparent mx-2">
                <span className="font-sofia-pro-semi-bold header-text__home ">Home</span>
              </button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const ClaimError = props => {
    return (
      <div>
        <Modal
          {...props}
          size="md"
          centered
          animation={false}
          backdrop="static"
          style={{ overflow: 'hidden' }}
          className="header-background_popup redeem-coffee__modal"
        >
          <Modal.Body className="d-flex justify-content-center flex-column">
            <div className="d-flex justify-content-center">
              <img src={FalilureImage} />
              <button className="border-0 header-button__canceliconerror " onClick={() => setShowError(false)}>
                <Close />
              </button>
            </div>
            <span className="font-sofia-pro-regular header-text__Uh-oh">Uh oh!</span>
            <span className="font-sofia-pro-light header-text__Something-went-wrong ">
              Something went wrong! Please try entering the code once again
            </span>

            <div class="row m-4">
              <button
                className="header-buttton__tryagain border-0"
                onClick={() => {
                  setShowError(false);
                }}
              >
                <span className="font-sofia-pro-semi-bold header-text__claim">Try again</span>
              </button>

              <Link to="/dashboard">
                <button className="header-button__home bg-transparent mx-2">
                  <span className="font-sofia-pro-semi-bold header-text__home ">Home</span>
                </button>
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };
  const ClaimClicked = () => {
    return (
      <>
        <button className="bg-transparent border-0 " onClick={() => setclaimClicked(true)}>
          <ClaimCode />
          <span className="font-sofia-pro-regular  header-text__Claim-a-code mx-0 mx-lg-2 ">Claim a code</span>
        </button>
        <ClaimCodeButton show={claimClicked} onHide={() => setclaimClicked(false)} />
        <ClaimError show={showError} onHide={() => setShowError(false)} />
        <ClaimSuccess show={showSuccess} onHide={() => setShowSuccess(false)} />
      </>
    );
  };
  const formik = useFormik({
    initialValues,
  });

  return <ClaimClicked />;
};

export default OrganisationClaimCodePage;
