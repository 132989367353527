/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import BackIcon from '../../../assets/images/common/icons-back.png';
import Stepper from '../../../common/Stepper/Stepper';
import MerchantBusinessDetailsPage from './businessDetails/BusinessDetails';
import MerchantPaymentPage from './payment/Payment';
import MerchantPersonalDetailsPage from './personalDetails/PersonalDetails';
import IconsCheckFilledImage from '../../../assets/images/common/icons-check-filled-pink.png';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';

class MerchantOnboardingPage extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: 'Business details',
          icon: IconsCheckFilledImage,
        },
        {
          title: 'Personal details',
          icon: IconsCheckFilledImage,
        },
        {
          title: 'Payment',
          icon: IconsCheckFilledImage,
        },
      ],
      currentStep: 0,
      showBackBtnModal: false,
    };
    this.onClickNext = this.onClickNext.bind(this);
  }

  showBackBtnModalHandler = () => this.setState({ showBackBtnModal: true });
  hideBackBtnModalHandler = () => this.setState({ showBackBtnModal: false });

  componentDidMount() {
    const { location } = this.props;

    if (location.state) {
      if (location.state.screenValue === 1) {
        this.onClickNext();
      }
    }
  }

  // Next btn handler
  onClickNext() {
    const { steps, currentStep } = this.state;
    this.setState({
      currentStep: currentStep + 1,
    });
  }

  // Back btn handler
  backBtnHandler() {
    const { steps, currentStep } = this.state;

    if (this.state.currentStep > 0) {
      this.setState({
        currentStep: currentStep - 1,
      });
    } else if (this.state.currentStep === 0) {
      // history.push('/merchant/plans')
    }
  }

  render() {
    const { steps, currentStep } = this.state;
    return (
      <div className="bg-alice-blue">
        <div className="p-2">
          <Logo />
        </div>
        <div className="mx-lg-5 mx-md-2 my-2 p-lg-4 p-md-2 bg-white">
          <div className="p-0 m-0 row">
            <div className="col-md-2 col-lg-3">&nbsp;</div>
            <div className="col-sm-12 col-md-8 col-lg-6">
              <div className="mb-3">
                {this.state.currentStep === 0 && (
                  <button
                    className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none"
                    onClick={this.showBackBtnModalHandler.bind(this)}
                  >
                    <img src={BackIcon} height="30px" />
                    Back
                  </button>
                )}
                {this.state.currentStep > 0 && (
                  <button
                    className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none"
                    onClick={this.showBackBtnModalHandler.bind(this)}
                  >
                    <img src={BackIcon} height="30px" />
                    Back
                  </button>
                )}
              </div>
              <div>
                <Stepper steps={steps} activeStep={currentStep} />
              </div>

              <div>
                {this.state.currentStep === 0 ? (
                  <div>
                    <MerchantBusinessDetailsPage step={1} moveToNextScreen={this.onClickNext} />
                  </div>
                ) : (
                  ''
                )}
                {this.state.currentStep === 1 ? (
                  <div>
                    <MerchantPersonalDetailsPage step={2} moveToNextScreen={this.onClickNext} />
                  </div>
                ) : (
                  ''
                )}
                {this.state.currentStep === 2 ? (
                  <div>
                    <MerchantPaymentPage step={3} moveToNextScreen={this.onClickNext} />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="col-md-2 col-lg-3">&nbsp;</div>
          </div>
          <Modal
            className="pt-4 border-0
         modal-background-shadow switch-to-account-modal"
            show={this.state.showBackBtnModal}
            onHide={this.hideBackBtnModalHandler}
            animation={false}
          >
            <Modal.Header className="p-0 m-0 border-0">
              <button
                type="button"
                className="close switch-to-account-modal__close__icon"
                onClick={this.hideBackBtnModalHandler}
              >
                <Close />
              </button>
            </Modal.Header>
            <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
              <p className="font-16 font-sofia-pro-semi-bold color-black-pearl">Are you sure you want to exit?</p>
              <div className="w-100 p-0 m-0 d-flex justify-content-start align-items-center">
                {this.state.currentStep === 0 && (
                  <Link to={'/merchant/plans'}>
                    <button
                      type="submit"
                      className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                    >
                      Yes
                    </button>
                  </Link>
                )}
                {this.state.currentStep > 0 && (
                  <Link to={'/merchant/onboarding/business'}>
                    <button
                      type="submit"
                      className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                    >
                      Yes
                    </button>
                  </Link>
                )}
                <button
                  className="p-0 m-0 ml-2 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                  onClick={this.hideBackBtnModalHandler}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default MerchantOnboardingPage;
