/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import OrganisationService from '../../../../../services/OrganisationService';
import Toast from '../../../../../services/ToasterService';
import CommonService from '../../../../../services/CommonService';
import Button from '../../../../../common/Button/Button';
import './AdminAccountDetails.scss';
import { ReactComponent as ProfilePic } from '../../../../../assets/images/common/user-profile.svg';
import ErrorImage from '../../../../../assets/images/error-icons/icons-error-info.png';

const initialValues = {
  name: '',
};

const validate = values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 25) {
    errors.name = 'Characters length should not exceed 25';
  }

  return errors;
};

function OrganisationAdminProfileAccountDetailsPage(props) {
  const [userData, setUserData] = useState();

  const [showLoader, setShowLoader] = useState(false);

  const [logoFileData, setLogoFileData] = useState();

  const [logoFileImage, setLogoFileImage] = useState();

  const [logoFileName, setLogoFileName] = useState();

  useEffect(() => {
    getUserData();
    window.scrollTo(0, 0);
  }, []);

  // Get organisation user details
  const getUserData = () => {
    OrganisationService.getOrganisationDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setUserData(data);
          formik.setFieldValue('name', data.name !== undefined ? data.name : null);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Update details
  const onSubmit = values => {
    setShowLoader(true);

    let data = {
      Name: values.name === null ? '' : values.name,
      features: 'Organisation',
      logoFileBase64: logoFileData !== undefined ? logoFileData : null,
      logoFileName: logoFileName !== undefined ? logoFileName : null,
    };

    OrganisationService.updateOrganisation(data)
      .then(response => {
        setShowLoader(false);
        if (response) {
          setUserData(response['data']);
          Toast('Your changes have been saved successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Profile picture handler
  const profilePictureBtnHandler = event => {
    let logoBase64 = '';
    let fileName = event.target.files[0].name;
    let checkValidImage = CommonService.validateImage(fileName);

    setLogoFileName(fileName);

    let profileBase64 = '';
    if (checkValidImage) {
      let logoImageUrl = URL.createObjectURL(event.target.files[0]);
      setLogoFileImage(logoImageUrl);
      getBase64(event.target.files[0], result => {
        profileBase64 = result.split(',')[1];
        profileBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData(profileBase64);
      });
    } else {
      Toast('Only jpg/jpeg and png files are allowed!', 'warning');
    }
  };

  const removeImageBtnHandler = () => {
    setLogoFileImage();
    setLogoFileData('');
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  // Base 64 conversion
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <div>
      <div className="p-0 m-0 p-4 card border-0 h-100">
        <div className="d-flex justify-content-start align-items-center">
          <span className="font-sofia-pro-semi-bold font-24 color-black-pearl">Edit Account</span>
        </div>
        <span className="font-sofia-pro-light font-14 color-black-pearl">Update your company information</span>

        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="h-100">
            <div className="d-flex align-items-center">
              {logoFileImage === undefined && userData?.logoPath !== null && (
                <img className="rounded-circle" src={userData?.logoPath + '?h=75'} height="75px" width="75px" />
              )}
              {logoFileImage === undefined && userData?.logoPath === null && <ProfilePic height="75px" width="75px" />}
              {logoFileImage !== undefined && (
                <img src={logoFileImage} height="75px" width="75px" className="rounded-circle" />
              )}
              <div className="pl-2 d-flex">
                <input
                  type="file"
                  id="individual__profile__logo__input"
                  className="ml-4 w-180 account-profile-custom-file-input border-radius-5"
                  onChange={profilePictureBtnHandler}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="p-0 b-0 mt-2 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Company Name</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle"
              name="name"
              placeholder="Eg: Appreci"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className="d-flex mt-2">
            <Button
              classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
              children="Save"
              showLoader={showLoader}
              onClick={() => onSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default OrganisationAdminProfileAccountDetailsPage;
