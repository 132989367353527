/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getLatLng, geocodeByAddress } from 'react-google-places-autocomplete';
import SearchLocationInput from '../../../../common/GooglePlaces/PlacesSearch';
import Dropzone from '../../../../common/Upload/Dropzone';
import DocDropzone from '../../../../common/Upload/DocDropzone';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import * as MerchantOnboardingSchema from '../../../../utils/Schema/merchantOnboarding';
import merchantService from '../../../../services/MerchantService';
import Stepper from '../../../../common/Stepper/Stepper';
import MerchantService from '../../../../services/MerchantService';
import LocationService from '../../../../services/LocationService';
import CommonService from '../../../../services/CommonService';
import * as RegexPattern from '../../../../utils/RegexPatterns';
import './PersonalDetails.scss';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import BackIcon from '../../../../assets/images/common/icons-back.png';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import IconsCheckFilledImage from '../../../../assets/images/common/icons-check-filled-pink.png';

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  dob: '',
  idNumber: '',
};

const validate = values => {
  let errors = {};

  let re = RegexPattern.MobileRegexValue;

  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.trim() === '') {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.trim() === '') {
    errors.lastName = 'Required';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!re.test(values.phone)) {
    errors.phone = 'Invalid phone format';
  } else if (values.phone.trim() === '') {
    errors.phone = 'Invalid phone format';
  }

  if (!values.dob) {
    errors.dob = 'Required';
  } else {
    let d = moment(values.dob).isBetween('1900-01-01', new Date());
    if (d === false) {
      errors.dob = 'Invalid date';
    }
  }

  if (!values.idNumber) {
    errors.idNumber = 'Required';
  } else if (values.idNumber.trim() === '') {
    errors.idNumber = 'Required';
  }

  return errors;
};

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

function MerchantPersonalDetailsPage(props) {
  const history = useHistory();

  let currentPath = usePathname();

  const [dob, setDob] = useState();
  const [checkIsOwner, setCheckIsOwner] = useState(true);
  const [photoId, setPhotoId] = useState();
  const [addressVerification, setAddressVerification] = useState();
  const [photoIdFile, setPhotoIdFile] = useState([]);
  const [photoIdBackFile, setPhotoIdBackFile] = useState([]);
  const [photoIdBackFileName, setPhotoIdBackFileName] = useState();
  const [addressVerificationFile, setAddressVerificationFile] = useState([]);
  const [photoFileName, setPhotoFileName] = useState();
  const [addressVerificationFileName, setAddressVerificationFileName] = useState();
  const [ExistingMerchantDetails, setExistingMerchantDetails] = useState();
  const [value, setValue] = useState(null);

  const [showLoader, setShowLoader] = useState(false);

  const [existingAddress, setExistingAddress] = useState();

  const [addressData, setAddressData] = useState({
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  });

  const [addressCoordinates, setAddressCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [steps, setSteps] = useState([
    {
      title: 'Business details',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Personal details',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Payment',
      icon: IconsCheckFilledImage,
    },
  ]);

  const [currentStep, setCurrentStep] = useState(1);

  const errors = props?.location?.state?.errors;

  const [showBackBtnModal, setShowShowBackBtnModal] = useState(false);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleCloseSuccessPopup = () => setShowSuccessPopup(false);
  const handleShowSuccessPopup = () => setShowSuccessPopup(true);

  const showBackBtnModalHandler = () => setShowShowBackBtnModal(true);
  const hideBackBtnModalHandler = () => setShowShowBackBtnModal(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMerchantDetails();
  }, []);

  // Get current user details
  const getCurrentUserDetails = () => {
    MerchantService.getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'];
          formik.setFieldValue('firstName', data.firstName !== null ? data.firstName : '');
          formik.setFieldValue('lastName', data.last !== null ? data.lastName : '');
          formik.setFieldValue('phone', data.phone !== null ? data.phone : '');
          formik.setFieldValue('email', data.email !== null ? data.email : '');
          formik.setFieldValue('idNumber', data.idNumber !== null ? data.idNumber : '');
          setCheckIsOwner(Boolean(true));
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        console.log('error filed errors ', errorResponseFieldErrors);
        if (errorResponseFieldErrors?.length > 0) {
          errorResponseFieldErrors?.forEach(msg => {
            Toast(msg, 'error');
          });
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Load existing address
  const loadExistingAddress = data => {
    geocodeByAddress(data)
      .then(results => {
        if (results) {
          getLatitudeLongitude(results[0]);

          let addressData = LocationService.getLocationAddress(results);

          if (addressData.street === undefined) {
            Toast('Please add street address', 'error');
            return;
          }

          if (addressData.city === undefined) {
            Toast('Please add city address', 'error');
            return;
          }

          if (addressData.state === undefined) {
            Toast('Please add state address', 'error');
            return;
          }

          if (addressData.country === undefined) {
            Toast('Please add country address', 'error');
            return;
          }

          if (addressData.postCode === undefined) {
            Toast('Please add post code address', 'error');
            return;
          }
          setAddressData({
            street: addressData.street,
            suburb: addressData.city,
            state: addressData.state,
            country: addressData.country,
            postcode: addressData.postCode,
          });
        }
      })
      .catch(error => console.error(error));
  };

  // Get merchant details
  const getMerchantDetails = () => {
    MerchantService.getMerchantDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setExistingMerchantDetails(data);

          if (data?.representative?.id === null) {
            getCurrentUserDetails();
          } else {
            formik.setFieldValue(
              'firstName',
              data?.representative?.firstName !== null ? data?.representative?.firstName : ''
            );
            formik.setFieldValue(
              'lastName',
              data?.representative?.lastName !== null ? data?.representative?.lastName : ''
            );
            formik.setFieldValue('phone', data?.representative?.phone !== null ? data?.representative?.phone : '');
            formik.setFieldValue('email', data?.representative?.email !== null ? data?.representative?.email : '');
            formik.setFieldValue(
              'idNumber',
              data?.representative?.idNumber !== null ? data?.representative?.idNumber : ''
            );
            formik.setFieldValue(
              'dob',
              data?.representative?.dateOfBirth !== null
                ? moment(data?.representative?.dateOfBirth).format('YYYY-MM-DD')
                : ''
            );
            setCheckIsOwner(Boolean(data?.representative?.isOwner));
            if (
              data?.representative?.address !== null &&
              data?.representative?.address?.street !== null &&
              data?.representative?.address?.suburb !== null &&
              data?.representative?.address?.state !== null &&
              data?.representative?.address?.country !== null &&
              data?.representative?.address?.postcode !== null
            ) {
              let address =
                data?.representative?.address?.street +
                ' ' +
                data?.representative?.address?.suburb +
                ' ' +
                data?.representative?.address?.state +
                ' ' +
                data?.representative?.address?.country +
                ' ' +
                data?.representative?.address?.postcode;
              setExistingAddress(address);
              loadExistingAddress(address);
            } else {
              setExistingAddress();
            }
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        console.log('error filed errors ', errorResponseFieldErrors);
        if (errorResponseFieldErrors?.length > 0) {
          errorResponseFieldErrors?.forEach(msg => {
            Toast(msg, 'error');
          });
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Submitting personal details
  const onSubmit = values => {
    toast.dismiss();

    setShowLoader(true);

    MerchantOnboardingSchema.merchantOnboardingData.representative = {
      id: ExistingMerchantDetails.representative.id !== '' ? ExistingMerchantDetails.representative.id : null,
      isOwner: true,
      role: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      phone: '',
      region: 'AU',
      address: {
        latitude: 0,
        longitude: 0,
        street: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
      },
      photoId: {
        frontFileBase64: '',
        backFileBase64: '',
      },
      addressVerification: {
        frontFileBase64: '',
        backFileBase64: null,
      },
      verificationStatus: 'None',
    };
    MerchantOnboardingSchema.merchantOnboardingData.representative.firstName = values.firstName.trim();
    MerchantOnboardingSchema.merchantOnboardingData.representative.lastName = values.lastName.trim();
    MerchantOnboardingSchema.merchantOnboardingData.representative.phone = values.phone.trim();
    MerchantOnboardingSchema.merchantOnboardingData.representative.idNumber = values.idNumber.trim();
    MerchantOnboardingSchema.merchantOnboardingData.representative.isOwner = Boolean(checkIsOwner);
    MerchantOnboardingSchema.merchantOnboardingData.representative.dateOfBirth = values.dob;

    MerchantOnboardingSchema.merchantOnboardingData.representative.photoId.frontFileBase64 =
      photoIdFile.length !== 0 ? photoIdFile : null;
    MerchantOnboardingSchema.merchantOnboardingData.representative.photoId.backFileBase64 =
      photoIdBackFile.length !== 0 ? photoIdBackFile : null;
    MerchantOnboardingSchema.merchantOnboardingData.representative.addressVerification.frontFileBase64 =
      addressVerificationFile.length !== 0 ? addressVerificationFile : null;
    MerchantOnboardingSchema.merchantOnboardingData.representative.role =
      checkIsOwner === true ? 'company' : 'individual';
    MerchantOnboardingSchema.merchantOnboardingData.representative.email = values.email.trim();

    MerchantOnboardingSchema.merchantOnboardingData.representative.address.street = addressData.street;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.suburb = addressData.suburb;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.state = addressData.state;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.country = addressData.country;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.postcode = addressData.postcode;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.country = addressData.country;

    MerchantOnboardingSchema.merchantOnboardingData.representative.address.latitude = addressCoordinates.latitude;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.longitude = addressCoordinates.longitude;

    MerchantOnboardingSchema.merchantOnboardingData.company = null;
    MerchantOnboardingSchema.merchantOnboardingData.payout = null;
    MerchantOnboardingSchema.merchantOnboardingData.bankAccount = null;

    MerchantOnboardingSchema.merchantOnboardingData['updateTermsAgreement'] = true;

    merchantService
      .updateMerchantDetails(MerchantOnboardingSchema.merchantOnboardingData)
      .then(response => {
        if (response) {
          if (errors?.length > 0) {
            handleShowSuccessPopup();
          } else {
            history.push({
              pathname: '/merchant/onboarding/payment',
              state: {
                role: 'Merchant',
              },
            });
          }

          setShowLoader(false);
        }
      })
      .catch(error => {
        console.log('error ', error);
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Handling photo file
  const photoChangeHandler = files => {
    if (Array.from(files).length > 2) {
      Toast(`Cannot upload files more than 2`, 'warning');

    } else {
      let fileName = files[0].name;
      let checkValidFile = CommonService.validateFile(fileName);

      if (checkValidFile) {
        let photoBase64 = '';
        setPhotoFileName(files[0].name);
        getBase64(files[0], result => {
          photoBase64 = result.replace('data:image/png;base64,', '');
          photoBase64 = /,(.+)/.exec(result)[1];
          setPhotoIdFile(photoBase64);
        });
      } else {
        Toast('Only pdf, docx, jpg and png files are allowed!', 'warning');
      }

      if (files[1]) {
        let backFileName = files[1].name;
        let checkValidBackFile = CommonService.validateFile(backFileName);
        if (checkValidBackFile) {
          let photoBackFileBase64 = '';
          setPhotoIdBackFileName(files[1].name);
          getBase64(files[1], result => {
            photoBackFileBase64 = result.replace('data:image/png;base64,', '');
            photoBackFileBase64 = /,(.+)/.exec(result)[1];
            setPhotoIdBackFile(photoBackFileBase64);
          });
        } else {
          Toast('Only pdf, docx, jpg and png files are allowed!', 'warning');
        }
      }
    }
  };

  const rejectedProofFilesHandler = rejectedFiles => {
    if (rejectedFiles?.length > 0) {
      rejectedFiles.forEach((rejectedFile, index) => {
        rejectedFile.errors.forEach(error => {
          Toast(error.code + ' - ' + error.message, 'error');
        });
      });
    }
  };

  // Handling address verification file
  const addressVerificationChangeHandler = files => {
    let addressImageUrl = URL.createObjectURL(files[0]);
    let fileName = files[0].name;
    let checkValidFile = CommonService.validateFile(fileName);
    if (checkValidFile) {
      setAddressVerificationFileName(files[0].name);
      let addressVerificationBase64 = '';
      getBase64(files[0], result => {
        addressVerificationBase64 = result.replace('data:image/png;base64,', '');
        addressVerificationBase64 = /,(.+)/.exec(result)[1];
        setAddressVerificationFile(addressVerificationBase64);
      });
    } else {
      Toast('Only pdf, docx, jpg and png files are allowed!', 'warning');
    }
  };

  const onValueChange = e => {
    setCheckIsOwner(e.target.value);
  };

  // Clearing verification image
  const clearAddressVerificationImage = () => {
    setAddressVerification();
    setAddressVerificationFile();
    setAddressVerificationFileName();
  };

  // Clearing photo image
  const clearPhotoImage = () => {
    setPhotoId('');
    setPhotoFileName('');
  };

  const clearPhotoBackFileImage = () => {
    setPhotoIdBackFileName('');
    setPhotoIdBackFile('');
  };

  // Converting base64
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  // Get address data
  const getAddress = data => {
    setValue(data);

    getLatitudeLongitude(data);

    let addressData = LocationService.getLocationAddress(data);

    if (addressData.street === undefined) {
      Toast('Please add street address', 'error');
      return;
    }

    if (addressData.city === undefined) {
      Toast('Please add city address', 'error');
      return;
    }

    if (addressData.state === undefined) {
      Toast('Please add state address', 'error');
      return;
    }

    if (addressData.country === undefined) {
      Toast('Please add country address', 'error');
      return;
    }

    if (addressData.postCode === undefined) {
      Toast('Please add post code address', 'error');
      return;
    }
    setAddressData({
      street: addressData.street,
      suburb: addressData.city,
      state: addressData.state,
      country: addressData.country,
      postcode: addressData.postCode,
    });
  };

  const getLatitudeLongitude = data => {
    getLatLng(data).then(({ lat, lng }) => {
      setAddressCoordinates({
        latitude: lat,
        longitude: lng,
      });
    });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    setShowLoader(false);
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const backBtnHandler = () => {
    showBackBtnModalHandler();
  };

  const handleSuccessPopupBtnHandler = () => {
    handleCloseSuccessPopup();
    history.push('/dashboard');
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  // loading existing details
  const loadPersonalDetailsForm = () => {
    // Based on path handling conditions
    // One is for stepper and another One is for individual page
    if (currentPath === '/merchant/onboarding/merchantDetails') {
      return (
        <div>
          <div>
            {errors?.length > 0 && (
              <div className="p-2 my-1 border-radius-5 border-grey-blue">
                <span className="font-sofia-pro-regular color-reddish font-16">Note</span>

                <ul>
                  {errors?.map((msg, index) => {
                    return <li className="font-sofia-pro-regular color-reddish font-14">{msg}</li>;
                  })}
                </ul>
              </div>
            )}
            <div className="p-0 m-0 p-3 card">
              <span className="font-sofia-pro-semi-bold font-18 color-black-pearl">Personal Details</span>
              <form className="mt-3" onSubmit={formik.handleSubmit}>
                <div className="p-0 b-0 pb-1 form-group d-flex ">
                  <label className="d-flex justify-content-center align-items-center personal-details-container">
                    <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">I’m the owner </span>
                    <input
                      type="radio"
                      checked="checked"
                      name="radio"
                      value="true"
                      checked={checkIsOwner === 'true'}
                      onChange={onValueChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label className="ml-4 d-flex justify-content-center align-items-center personal-details-container">
                    <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">I’m an employee</span>
                    <input
                      type="radio"
                      name="radio"
                      value="false"
                      checked={checkIsOwner === 'false'}
                      onChange={onValueChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>

                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">First name *</label>
                  <input
                    type="text"
                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                    name="firstName"
                    placeholder="Eg: your first name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                  />

                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>
                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Last name *</label>
                  <input
                    type="text"
                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                    name="lastName"
                    placeholder="Eg: your last name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />

                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>
                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">License ID *</label>
                  <input
                    type="text"
                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                    name="idNumber"
                    placeholder="Eg: your license id"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.idNumber}
                  />

                  {formik.touched.idNumber && formik.errors.idNumber ? (
                    <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.idNumber}
                    </div>
                  ) : null}
                </div>
                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>

                  {existingAddress === undefined && (
                    <SearchLocationInput loadAddress={getAddress} data={existingAddress} />
                  )}
                  {existingAddress !== undefined && (
                    <SearchLocationInput loadAddress={getAddress} data={existingAddress} />
                  )}
                </div>
                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Date of birth *</label>
                  <input
                    type="date"
                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                    name="dob"
                    placeholder="13-02-1990"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.dob}
                    max={moment().format('YYYY-MM-DD')}
                  />

                  {formik.touched.dob && formik.errors.dob ? (
                    <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.dob}
                    </div>
                  ) : null}
                </div>

                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Phone *</label>
                  <input
                    type="text"
                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                    name="phone"
                    placeholder="Eg: 1234567890"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />

                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
                    </div>
                  ) : null}
                </div>

                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Email *</label>
                  <input
                    type="text"
                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                    name="email"
                    placeholder="Eg: example@domain.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />

                  {formik.touched.email && formik.errors.email ? (
                    <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="p-0 b-0 pb-1 mt-2 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">
                    Photo ID (Optional)
                    <span className="d-block font-sofia-pro-light font-14 color-black-pearl">
                      Accepted documents: Passport or Drivers licence
                    </span>
                  </label>
                  <label className="position-relative w-100">
                    <div className="upload-file-style">
                      <DocDropzone
                        acceptedFiles={photoChangeHandler}
                        rejectedFiles={rejectedProofFilesHandler}
                        maxLength={2}
                        supportText={'Supports: PDF, WORD, PNG, JPEG file | Multiple documents supported '}
                        multiple={true}
                      ></DocDropzone>
                    </div>
                  </label>
                  {photoFileName && (
                    <div className="mt-2 d-flex justify-content-between">
                      <div className="personal-details-image-name__text__ellipsis">
                        <span
                          data-tip
                          data-for="photofFileImageName"
                          className="font-14 font-sofia-pro-medium color-black-pearl "
                        >
                          {photoFileName}
                        </span>
                        <ReactTooltip
                          id="photofFileImageName"
                          html={true}
                          className="business-details-image-name__tooltip"
                        >
                          {photoFileName}
                        </ReactTooltip>
                      </div>
                      <button
                        type="button"
                        className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                        onClick={clearPhotoImage}
                      >
                        Clear
                      </button>
                    </div>
                  )}

                  {photoIdBackFileName && (
                    <div className="mt-2 d-flex justify-content-between">
                      <div className="personal-details-image-name__text__ellipsis">
                        <span
                          data-tip
                          data-for="photofFileBackImageName"
                          className="font-14 font-sofia-pro-medium color-black-pearl "
                        >
                          {photoIdBackFileName}
                        </span>
                        <ReactTooltip
                          id="photofFileBackImageName"
                          html={true}
                          className="business-details-image-name__tooltip"
                        >
                          {photoIdBackFileName}
                        </ReactTooltip>
                      </div>
                      <button
                        type="button"
                        className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                        onClick={clearPhotoBackFileImage}
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </div>
                <div className="p-0 b-0 pb-1 mt-2 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">
                    Address Verification (Optional)
                    <span className="d-block font-sofia-pro-light font-14 color-black-pearl">
                      Accepted documents: Drivers licence or Photo ID or current electricity bill
                    </span>
                  </label>

                  <label className="position-relative w-100">
                    <div className="upload-file-style">
                      <DocDropzone
                        acceptedFiles={addressVerificationChangeHandler}
                        rejectedFiles={rejectedProofFilesHandler}
                        maxLength={1}
                        supportText={'Supports: PDF, WORD, PNG, JPEG file'}
                        multiple={false}
                      ></DocDropzone>
                    </div>
                  </label>
                  {addressVerificationFileName && (
                    <div className="mt-2 d-flex justify-content-between">
                      <div className="personal-details-image-name__text__ellipsis">
                        <span
                          data-tip
                          data-for="addressVerificationImageName"
                          className="font-14 font-sofia-pro-medium color-black-pearl "
                        >
                          {addressVerificationFileName}
                        </span>
                        <ReactTooltip
                          id="addressVerificationImageName"
                          html={true}
                          className="business-details-image-name__tooltip"
                        >
                          {addressVerificationFileName}
                        </ReactTooltip>
                      </div>
                      <button
                        type="button"
                        className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                        onClick={clearAddressVerificationImage}
                      >
                        Clear
                      </button>
                    </div>
                  )}
                </div>

                {/* <button type="submit" className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white">
                                    Proceed
                    </button> */}
                <Button
                  classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                  children="Proceed"
                  showLoader={showLoader}
                  onClick={() => onSubmit}
                />
              </form>
            </div>
          </div>
          <Modal
            className="pt-4 border-0
         modal-background-shadow switch-to-account-modal"
            show={showBackBtnModal}
            backdrop="static"
            onHide={hideBackBtnModalHandler}
            animation={false}
          >
            <Modal.Header className="p-0 m-0 border-0">
              <button
                type="button"
                className="close switch-to-account-modal__close__icon"
                onClick={hideBackBtnModalHandler}
              >
                <Close />
              </button>
            </Modal.Header>
            <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
              <p className="font-16 font-sofia-pro-semi-bold color-black-pearl">Are you sure you want to exit?</p>
              <div className="w-100 p-0 m-0 d-flex justify-content-start align-items-center">
                <Link to={'/merchant/onboarding/business'}>
                  <button
                    type="submit"
                    className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                  >
                    Yes
                  </button>
                </Link>
                <button
                  className="p-0 m-0 ml-2 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                  onClick={hideBackBtnModalHandler}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    } else {
      return (
        <div className="bg-alice-blue">
          <div className="p-2">
            <Logo />
          </div>
          <div className="mx-lg-5 mx-md-2 my-2 p-lg-4 p-md-2 bg-white">
            <div className="p-0 m-0 row">
              <div className="col-md-2 col-lg-3">&nbsp;</div>
              <div className="col-sm-12 col-md-8 col-lg-6">
                <div className="mb-3">
                  <button
                    className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none"
                    onClick={backBtnHandler}
                  >
                    <img src={BackIcon} height="30px" />
                    Back
                  </button>
                </div>

                <div>
                  <Stepper steps={steps} activeStep={currentStep} />
                </div>
                {errors?.length > 0 && (
                  <div className="p-2 my-1 border-radius-5 border-grey-blue">
                    <span className="font-sofia-pro-regular color-reddish font-16">Note</span>

                    <ul>
                      {errors?.map((msg, index) => {
                        return <li className="font-sofia-pro-regular color-reddish font-14">{msg}</li>;
                      })}
                    </ul>
                  </div>
                )}

                <div>
                  <div className="p-0 m-0 p-3 card">
                    <span className="font-sofia-pro-semi-bold font-18 color-black-pearl">Personal Details</span>
                    <form className="mt-3" onSubmit={formik.handleSubmit}>
                      <div className="p-0 b-0 pb-1 form-group d-flex ">
                        <label className="d-flex justify-content-center align-items-center personal-details-container">
                          <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">I’m the owner </span>
                          <input
                            type="radio"
                            checked="checked"
                            name="radio"
                            value="true"
                            checked={checkIsOwner === 'true'}
                            onChange={onValueChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="ml-4 d-flex justify-content-center align-items-center personal-details-container">
                          <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">I’m an employee</span>
                          <input
                            type="radio"
                            name="radio"
                            value="false"
                            checked={checkIsOwner === 'false'}
                            onChange={onValueChange}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>

                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">First name *</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="firstName"
                          placeholder="Eg: your first name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />

                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
                          </div>
                        ) : null}
                      </div>
                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">Last name *</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="lastName"
                          placeholder="Eg: your last name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />

                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
                          </div>
                        ) : null}
                      </div>
                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">License ID *</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="idNumber"
                          placeholder="Eg: your license id"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.idNumber}
                        />

                        {formik.touched.idNumber && formik.errors.idNumber ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.idNumber}
                          </div>
                        ) : null}
                      </div>
                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>
                        {/* <SearchLocationInput loadAddress={getAddress} data={''} /> */}
                        {existingAddress === undefined && (
                          <SearchLocationInput loadAddress={getAddress} data={existingAddress} />
                        )}
                        {existingAddress !== undefined && (
                          <SearchLocationInput loadAddress={getAddress} data={existingAddress} />
                        )}
                      </div>
                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">Date of birth *</label>
                        {/* <input
                                                    type="date"
                                                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                                                    name="email"
                                                    placeholder="13-02-1990"
                                                    onChange={event => setDob(event.target.value)}
                                                /> */}

                        <input
                          type="date"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="dob"
                          placeholder="13-02-1990"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dob}
                          max={moment().format('YYYY-MM-DD')}
                        />

                        {formik.touched.dob && formik.errors.dob ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.dob}
                          </div>
                        ) : null}
                      </div>

                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">Phone *</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="phone"
                          placeholder="Eg: 1234567890"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />

                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
                          </div>
                        ) : null}
                      </div>

                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">Email *</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="email"
                          placeholder="Eg: example@domain.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />

                        {formik.touched.email && formik.errors.email ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                          </div>
                        ) : null}
                      </div>

                      <div className="p-0 b-0 pb-1 mt-2 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">
                          Photo ID (Optional)
                          <span className="d-block font-sofia-pro-light font-14 color-black-pearl">
                            Accepted documents: Passport or Drivers licence
                          </span>
                        </label>

                        <label className="position-relative w-100">
                          <div className="upload-file-style">
                            {/* <input type="file" className="upload-docs__logo" onChange={photoChangeHandler} onClick={(event) => {
                                                            event.target.value = null
                                                        }} multiple />
                                                        <div className="d-flex flex-column align-items-center">
                                                            <img src={MiscImage} className="mb-1" height="80px" width="80px" />
                                                            <span className="font-16 font-sofia-pro-semi-bold color-thatch-green">Drop your file here, or
                                                            <span className="font-16 font-sofia-pro-semi-bold color-primary">&nbsp;browse</span>
                                                            </span>
                                                            <span className="font-12 font-sofia-pro-medium color-bouquet">Supports: pdf, word file | Multiple documents supported</span>
                                                        </div> */}
                            <DocDropzone
                              acceptedFiles={photoChangeHandler}
                              rejectedFiles={rejectedProofFilesHandler}
                              maxLength={2}
                              supportText={'Supports: PDF, WORD, PNG, JPEG file | Multiple documents supported '}
                              multiple={true}
                            ></DocDropzone>
                          </div>
                        </label>
                        {photoFileName && (
                          <div className="mt-2 d-flex justify-content-between">
                            <div className="personal-details-image-name__text__ellipsis">
                              <span
                                data-tip
                                data-for="photofFileImageName"
                                className="font-14 font-sofia-pro-medium color-black-pearl "
                              >
                                {photoFileName}
                              </span>
                              <ReactTooltip
                                id="photofFileImageName"
                                html={true}
                                className="business-details-image-name__tooltip"
                              >
                                {photoFileName}
                              </ReactTooltip>
                            </div>
                            <button
                              type="button"
                              className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                              onClick={clearPhotoImage}
                            >
                              Clear
                            </button>
                          </div>
                        )}

                        {photoIdBackFileName && (
                          <div className="mt-2 d-flex justify-content-between">
                            <div className="personal-details-image-name__text__ellipsis">
                              <span
                                data-tip
                                data-for="photofFileBackImageName"
                                className="font-14 font-sofia-pro-medium color-black-pearl "
                              >
                                {photoIdBackFileName}
                              </span>
                              <ReactTooltip
                                id="photofFileBackImageName"
                                html={true}
                                className="business-details-image-name__tooltip"
                              >
                                {photoIdBackFileName}
                              </ReactTooltip>
                            </div>
                            <button
                              type="button"
                              className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                              onClick={clearPhotoBackFileImage}
                            >
                              Clear
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="p-0 b-0 pb-1 mt-2 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">
                          Address Verification (Optional)
                          <span className="d-block font-sofia-pro-light font-14 color-black-pearl">
                            Accepted documents: Drivers licence or Photo ID or current electricity bill
                          </span>
                        </label>

                        <label className="position-relative w-100">
                          <div className="upload-file-style">
                            {/* <input type="file" className="upload-docs__logo" onChange={addressVerificationChangeHandler} onClick={(event) => {
                                                            event.target.value = null
                                                        }} />
                                                        <div className="d-flex flex-column align-items-center">
                                                            <img src={MiscImage} className="mb-1" height="80px" width="80px" />
                                                            <span className="font-16 font-sofia-pro-semi-bold color-thatch-green">Drop your file here, or
                                            <span className="font-16 font-sofia-pro-semi-bold color-primary">&nbsp;browse</span>
                                                            </span>
                                                            <span className="font-12 font-sofia-pro-medium color-bouquet">Supports: pdf, word file</span>
                                                        </div> */}
                            <DocDropzone
                              acceptedFiles={addressVerificationChangeHandler}
                              rejectedFiles={rejectedProofFilesHandler}
                              maxLength={1}
                              supportText={'Supports: PDF, WORD, PNG, JPEG file'}
                              multiple={false}
                            ></DocDropzone>
                          </div>
                        </label>
                        {addressVerificationFileName && (
                          <div className="mt-2 d-flex justify-content-between">
                            <div className="personal-details-image-name__text__ellipsis">
                              <span
                                data-tip
                                data-for="addressVerificationImageName"
                                className="font-14 font-sofia-pro-medium color-black-pearl "
                              >
                                {addressVerificationFileName}
                              </span>
                              <ReactTooltip
                                id="addressVerificationImageName"
                                html={true}
                                className="business-details-image-name__tooltip"
                              >
                                {addressVerificationFileName}
                              </ReactTooltip>
                            </div>
                            <button
                              type="button"
                              className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                              onClick={clearAddressVerificationImage}
                            >
                              Clear
                            </button>
                          </div>
                        )}
                      </div>

                      {/* <button type="submit" className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white">
                                                Proceed
                        </button> */}

                      <Button
                        classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                        children="Proceed"
                        showLoader={showLoader}
                        onClick={() => onSubmit}
                      />
                    </form>
                  </div>
                </div>
                <Modal
                  className="pt-4 border-0  modal-background-shadow switch-to-account-modal"
                  backdrop="static"
                  show={showBackBtnModal}
                  onHide={hideBackBtnModalHandler}
                  animation={false}
                >
                  <Modal.Header className="p-0 m-0 border-0">
                    <button
                      type="button"
                      className="close switch-to-account-modal__close__icon"
                      onClick={hideBackBtnModalHandler}
                    >
                      <Close />
                    </button>
                  </Modal.Header>
                  <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                    <p className="font-16 font-sofia-pro-semi-bold color-black-pearl">Are you sure you want to exit?</p>
                    <div className="w-100 p-0 m-0 d-flex justify-content-start align-items-center">
                      <Link to={'/merchant/onboarding/business'}>
                        <button
                          type="submit"
                          className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                        >
                          Yes
                        </button>
                      </Link>
                      <button
                        className="p-0 m-0 ml-2 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                        onClick={hideBackBtnModalHandler}
                      >
                        Cancel
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>

                <Modal
                  className="border-0 merchant-success__modal modal-background-shadow"
                  backdrop="static"
                  size="md"
                  show={showSuccessPopup}
                  onHide={handleCloseSuccessPopup}
                >
                  <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
                    <span className="font-16 color-primary font-sofia-pro-semi-bold">
                      <img src={IconsCheckFilledImage} height="25px" width="25px" />
                      <span className="pl-1">ACCOUNT UPDATED</span>
                    </span>
                  </Modal.Header>
                  <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                    <p className="font-16 font-sofia-pro-light color-black-pearl">
                      You have successfully resubmitted the missing documents and our team will verify them.
                    </p>
                    <p className="font-16 font-sofia-pro-light color-black-pearl">
                      You can continue using your personal account and once your merchant account is verified, we'll
                      send you the next steps.
                    </p>
                    <span className="p-0 m-0 font-sofia-pro-medium font-14 color-black-pearl">
                      Note: It may take up to 5 days to get the account activated.
                    </span>
                    <div className=" p-0 m-0 mt-4 mb-2 d-flex justify-content-start align-items-center">
                      <button
                        className="p-0 m-0 py-2 w-120 h-50 border-primary-color-2 border-radius-5 bg-transparent font-16 color-primary font-sofia-pro-semi-bold outline-none  shadow-none"
                        onClick={handleSuccessPopupBtnHandler}
                      >
                        Ok got it
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return <div className="bg-alice-blue">{loadPersonalDetailsForm()}</div>;
}

export default MerchantPersonalDetailsPage;
