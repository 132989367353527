/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import { geocodeByLatLng, geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { toast } from 'react-toastify';
import MerchantService from '../../../../services/MerchantService';
import LocationService from '../../../../services/LocationService';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import SearchLocationInput from '../../../../common/GooglePlaces/PlacesSearch';
import './Locations.scss';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';

const initialValues = {
  name: '',
};

const validate = values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  return errors;
};

const EditStoreLocationPage = props => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs',
  });

  const [editModalShow, setEditModalShow] = useState(false);

  const [storeData, setStoreData] = useState({});

  const [addressData, setAddressData] = useState({
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  });

  const [addressCoordinates, setAddressCoordinates] = useState();

  const [existingAddress, setExistingAddress] = useState();

  const [markerLocation, setMarkerLocation] = useState({
    lat: -23.442,
    lng: 144.249,
  });

  const [showLoader, setShowLoader] = useState(false);

  const [checkUserRedeemStore, setCheckUserRedeemStore] = useState(true);

  const [checkUserStorePublicalyAvailable, setCheckUserStorePublicalyAvailable] = useState(true);

  const [checkUserRedeemOption, setCheckUserRedeemOption] = useState(true);

  const [showRedeemOptionPopup, setShowRedeemOptionPopup] = useState(false);

  const [showPublicalyAvailablePopup, setShowPublicalyAvailablePopup] = useState(false);

  const mapStyles = {
    height: '50vh',
    width: '100%',
  };

  const [defaultCenter, setDefaultCenter] = useState({
    lat: -23.442,
    lng: 144.249,
  });

  const showRedeemModalPopupHandler = () => {
    setShowRedeemOptionPopup(true);
  };
  const hideRedeemModalPopupHandler = () => setShowRedeemOptionPopup(false);

  const showPublicalyAvailableModalPopupHandler = () => {
    setShowPublicalyAvailablePopup(true);
  };
  const hidePublicalyAvailableModalPopupHandler = () => setShowPublicalyAvailablePopup(false);

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  useEffect(() => {
    getStoreData(props.id);
  }, []);

  const onClickMap = event => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const position = { lat, lng };

    setMarkerLocation({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    });

    setDefaultCenter({
      lat: lat,
      lng: lng,
    });

    // initGeocoder(event);

    geocodeByLatLng(position).then(response => {
      const address = response[0].formatted_address;
      let city, state, country, postCode, street;
      for (let i = 0; i < response[0].address_components.length; i++) {
        for (let j = 0; j < response[0].address_components[i].types.length; j++) {
          switch (response[0].address_components[i].types[j]) {
            case 'locality':
              city = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
            case 'administrative_area_level_1':
              state = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
            case 'country':
              country = response[0].address_components[i].long_name
                ? response[0].address_components[i].short_name
                : null;
              break;
            case 'postal_code':
              postCode = response[0].address_components[i].long_name
                ? response[0].address_components[i].long_name
                : null;
              break;
            case 'route':
              street = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
          }
        }
      }
      // console.log(address);
      setAddressData({
        latitude: lat,
        longitude: lng,
        state: state,
        postcode: postCode,
        country: country,
        street: street,
        suburb: city,
      });

      setExistingAddress(address);
    });
  };

  // Update location
  const onSubmit = values => {
    setShowLoader(true);

    toast.dismiss();

    let data = values;

    data['address'] = addressData;

    data['isActive'] = checkUserRedeemOption;

    data['isPublic'] = checkUserStorePublicalyAvailable;

    data['address']['latitude'] = markerLocation.lat;

    data['address']['longitude'] = markerLocation.lng;

    MerchantService.updateStore(props.id, data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          props.closeModal();
          props.loadMerchantList();
          Toast('Your changes to the store location have been saved successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  // Get address data
  const getAddress = data => {
    // setValue(data);
    getLatitudeLongitude(data);

    let addressData = LocationService.getLocationAddress(data);

    if (addressData.street === undefined) {
      Toast('Please add street address', 'error');
      return;
    }

    if (addressData.city === undefined) {
      Toast('Please add city address', 'error');
      return;
    }

    if (addressData.state === undefined) {
      Toast('Please add state address', 'error');
      return;
    }

    if (addressData.country === undefined) {
      Toast('Please add country address', 'error');
      return;
    }

    if (addressData.postCode === undefined) {
      Toast('Please add post code address', 'error');
      return;
    }

    setAddressData({
      street: addressData.street,
      suburb: addressData.city,
      state: addressData.state,
      country: addressData.country,
      postcode: addressData.postCode,
    });
  };

  // Get latitude and longitude
  const getLatitudeLongitude = data => {
    getLatLng(data).then(({ lat, lng }) => {
      // companyData.company.address.latitude = lat;
      // companyData.company.address.longitude = lng;
      setMarkerLocation({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      });

      setDefaultCenter({
        lat: lat,
        lng: lng,
      });
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate,
  });

  // Get store data
  const getStoreData = id => {
    MerchantService.getStore(props.id).then(response => {
      if (response) {
        let data = response['data'];
        loadExistingAddress(data?.address);

        handleEditModalShow();

        handleEditModalClose();

        handleEditModalShow();

        formik.setFieldValue('name', data.name);
        setCheckUserRedeemOption(data.isActive);
        setCheckUserStorePublicalyAvailable(data.isPublic);
        setExistingAddress(data.address);
      }
    });
  };

  const loadExistingAddress = data => {
    geocodeByAddress(data)
      .then(results => {
        if (results) {
          getLatitudeAndLongitude(results[0]);

          let addressData = LocationService.getLocationAddress(results[0]);

          if (addressData.street === undefined) {
            Toast('Please add street address', 'error');
            return;
          }

          if (addressData.city === undefined) {
            Toast('Please add city address', 'error');
            return;
          }

          if (addressData.state === undefined) {
            Toast('Please add state address', 'error');
            return;
          }

          if (addressData.country === undefined) {
            Toast('Please add country address', 'error');
            return;
          }

          if (addressData.postCode === undefined) {
            Toast('Please add post code address', 'error');
            return;
          }
          setAddressData({
            street: addressData.street,
            suburb: addressData.city,
            state: addressData.state,
            country: addressData.country,
            postcode: addressData.postCode,
          });
        }
      })
      .catch(error => console.error(error));
  };

  const getLatitudeAndLongitude = data => {
    getLatLng(data).then(result => {
      if (result) {
        setAddressCoordinates({
          latitude: result.lat,
          longitude: result.lng,
        });

        setMarkerLocation({
          lat: parseFloat(result.lat),
          lng: parseFloat(result.lng),
        });

        setDefaultCenter({
          lat: result.lat,
          lng: result.lng,
        });
      }
    });
  };

  const onValueChangeRedeemCheckboxOption = e => {
    setCheckUserRedeemOption(e.target.checked);

    if (e.target.checked === false) {
      showRedeemModalPopupHandler();
    }
  };

  const onValueChangeForUserPublicAvailable = e => {
    setCheckUserStorePublicalyAvailable(e.target.checked);
    if (e.target.checked === false) {
      showPublicalyAvailableModalPopupHandler();
    }
  };

  const cancelRedeemModalPopupHandler = () => {
    setCheckUserRedeemOption(true);
    hideRedeemModalPopupHandler();
  };

  const cancelPublicalyAvailableModalPopupHandler = () => {
    setCheckUserStorePublicalyAvailable(true);
    hidePublicalyAvailableModalPopupHandler();
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 col-sm-12 col-md-6 col-lg-6">
        <form
          className="mt-3"
          onSubmit={formik.handleSubmit}
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Cafe name *</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle"
              name="name"
              placeholder="Eg: Manly Guild"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.name}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>
            {isLoaded === true && existingAddress !== undefined && (
              <SearchLocationInput loadAddress={getAddress} data={existingAddress} setIsMapLoaded={true} />
            )}
          </div>
          <div className="d-flex">
            <label className="merchant-location__container">
              <input
                type="checkbox"
                name="redeemOption"
                checked={checkUserRedeemOption === true}
                onChange={onValueChangeRedeemCheckboxOption}
              />
              <span className="merchant-location__checkmark"></span>
            </label>

            <span className="font-14 color-gunmetal font-sofia-pro-regular">Allow users to redeem thanks</span>
          </div>
          <div className="d-flex mt-2">
            <label className="merchant-location__container">
              <input
                type="checkbox"
                name="publicalyAvailable"
                checked={checkUserStorePublicalyAvailable === true}
                onChange={onValueChangeForUserPublicAvailable}
              />
              <span className="merchant-location__checkmark"></span>
            </label>

            <span className="font-14 color-gunmetal font-sofia-pro-regular">Show cafe location on map</span>
          </div>
          <div className="d-flex mt-2">
            <Button
              classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
              children="Save"
              showLoader={showLoader}
              onClick={() => onSubmit}
            />
          </div>
        </form>
      </div>
      <div className="p-0 m-0 p-3 col-sm-12 col-md-6 col-lg-6">
        <div style={{ width: '100%' }}>
          {isLoaded === true ? (
            <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter} onClick={onClickMap}>
              <Marker position={markerLocation} />
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
      </div>

      <Modal
        className="pt-4 border-0
         modal-background-shadow employee-modal"
        size="md"
        backdrop="static"
        show={showRedeemOptionPopup}
        onHide={hideRedeemModalPopupHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              {' '}
              Are you sure you do not want users to redeem at your store?
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={cancelRedeemModalPopupHandler}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={hideRedeemModalPopupHandler}
            >
              Ok
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={cancelRedeemModalPopupHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0
         modal-background-shadow employee-modal"
        size="md"
        backdrop="static"
        show={showPublicalyAvailablePopup}
        onHide={hidePublicalyAvailableModalPopupHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              Are you sure your store should not be visible on the map?
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={cancelPublicalyAvailableModalPopupHandler}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={hidePublicalyAvailableModalPopupHandler}
            >
              Ok
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={cancelPublicalyAvailableModalPopupHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditStoreLocationPage;
