/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState,useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import http from '../../../../services/HttpService';
import OTPInput from '../../../../common/OTP/OTPReader';
import './OtpAuth.scss';
import UsernameSuccessImage from '../../../../assets/images/common/icons-check-filled-green.png';
import { Logo } from '../../../../assets/images/';
import ReactGA from 'react-ga';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function OtpAuth() {
  const history = useHistory();

  let query = useQuery();

  const [OTP, setOTP] = useState('');
  const [checkResendBtnIsClicked, setcheckResendBtnIsClicked] = useState(false);

  // Verify OTP
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const handleNextBtn = () => {
    toast.dismiss();
    const redirectUrl = query.get('redirectUrl');
    if (OTP.length === 4) {
      let userData = JSON.parse(sessionStorage.getItem('temp_userData'));
      const requestData = {
        region: 'AU',
        phone: userData.phone,
      };
      http
        .post(`/UserSessions/bycode/${OTP}`, requestData)
        .then(response => {
          sessionStorage.removeItem('temp_userData');
          let responseData = response['data'];
          const userActivatedStatus = responseData.status;
          localStorage.setItem('userData', JSON.stringify(responseData));
          axios.defaults.headers.common['userKey'] = responseData.userKey;
          toast.success('Login Success', {autoClose: 1000});
          // history.push("/dashboard");
          if (
            (userActivatedStatus === 'Activated' &&
              responseData.userName !== null &&
              responseData.firstName === null) ||
            (responseData.firstName === '' && responseData.lastName === null) ||
            responseData.lastName === ''
          ) {
            history.push({
              pathname: '/user-details?auth_type=bytoken',
              state: {
                screenValue: 1,
                checkFirstAndLastNameAvailableOrNot: true,
              },
            });
            window.location.reload();
          } else if (
            (userActivatedStatus === 'Activated' && responseData.userName === null) ||
            responseData.userName === ''
          ) {
            history.push({
              pathname: '/user-details?auth_type=bytoken',
              state: { screenValue: 1 },
            });
          } else {
            toast.success('Login Success', {autoClose: 1000});
            if (sessionStorage.getItem('Anonymous') === 'anonymous') {
              history.push('/redeemthanks');
            } else {
              if (redirectUrl !== null) {
                history.push(redirectUrl);
              } else {
                history.push('/dashboard');
              }
            }
          }
        })
        .catch(error => {
          if (error.response) {
            let errorResponse = error.response['data'];
            let errorMessage = errorResponse.globalErrors[0].message;
            toast.error(errorMessage);
          }
        });
    } else {
      toast.error('OTP length must be equal to 4 digit');
    }
  };

  // Resend OTP
  const handleResendBtn = () => {
    toast.dismiss();
    let userData = JSON.parse(sessionStorage.getItem('temp_userData'));

    let data = {
      phone: userData.phone,
      type: 5,
      region: 'AU',
    };
    http
      .post('/users/tokens', data)
      .then(response => {
        if (response.status === 200) {
          setcheckResendBtnIsClicked(true);

          setTimeout(() => {
            setcheckResendBtnIsClicked(false);
          }, 5000);
        }
      })
      .catch(error => {
        let errorResponse = error.response['data'];
        let errorMessage = errorResponse.globalErrors[0].message;
        toast.error(errorMessage);
      });
  };

  const submitOTP = e => {
    if (e.key === 'Enter') handleNextBtn();
  };

  return (
    <div className="bg-alice-blue h-100vh">
      <div className="p-0 m-0 p-3 pl-4 bg-white">
        <Link to={'/login'}>
          <Logo />
        </Link>
      </div>
      <div className="p-0 m-0">
        <div className="mt-3 d-flex justify-content-center align-items-center bg-alice-blue">
          <div className="p-0 m-0">
            <div className="card border-0 p-4">
              <span className="px-2 color-charcoal-grey font-32 font-sofia-pro-light text-center">
                Please enter OTP
              </span>
              <p className="mt-2 oslo-grey font-sofia-pro-light font-16 text-center">
                We have sent an OTP to the registered phone number
              </p>
              <div className="mt-2 my-2 d-flex justify-content-between align-items-baseline">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  OTPLength={4}
                  otpType="number"
                  disabled={false}
                  inputClassName="otp-input__box font-sofia-pro-regular otp__placeholder border-light-periwinkle font-45 border-radius-5"
                  onKeyDown={submitOTP}
                />
              </div>
              <div className="mt-2">
                <button
                  className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                  onClick={handleNextBtn}
                >
                  Verify
                </button>
              </div>
              {checkResendBtnIsClicked === true ? (
                <div className="mt-2">
                  <img src={UsernameSuccessImage} /> &nbsp;
                  <span className="font-14 font-sofia-pro-regular color-gunmetal">
                    We’ve resent the code to your phone number
                  </span>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="mt-2 text-center">
              <span className="font-14 font-sofia-pro-regular color-charcoal-grey">
                Didn’t receive code?{' '}
                <span className="font-14 font-sofia-pro-regular color-primary cursor-pointer" onClick={handleResendBtn}>
                  Resend
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpAuth;
