/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import merchantService from '../../../../services/MerchantService';
import './Loading.scss';
import { ReactComponent as Logo } from '../../../../assets/images/logo/logo-colour.svg';
import { ReactComponent as LogoMark } from '../../../../assets/images/common/logo-mark.svg';
import { ReactComponent as EmojiIcon } from '../../../../assets/images/common/emoji.svg';
import FlagIcon from '../../../../assets/images/common/flag.png';
import CoffeeImage from '../../../../assets/images/common/coffee.png';
import ReactGA from 'react-ga';

function LoadingPage(props) {
  const history = useHistory();

  const [titleText, setTitleText] = useState('Brewing the platform. Please wait….');
  const [subTitleText, setSubtitleText] = useState('Coffee drinkers tend to live longer');
  const [checkOrganisationOrNot, setCheckOrganisationOrNot] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    // Changing text for organisation
    if (props?.history?.location?.state?.role === 'Organisation') {
      setCheckOrganisationOrNot(true);
      setTitleText('Caffeine loading…Please wait');
      setSubtitleText('Brazil grows the most coffee in the world');
    }
  }, []);

  useEffect(() => {
    getCurrentUser();
    setTimeout(() => {
      if (props?.history?.location?.state?.role === 'Merchant') {
        setCheckOrganisationOrNot(false);
        history.push({
          pathname: '/merchant/dashboard',
          state: {
            showMerchantPopup: true,
          },
        });
      } else if (props?.history?.location?.state?.role === 'Organisation') {
        setCheckOrganisationOrNot(true);
        setTitleText('Caffeine loading…Please wait');
        setSubtitleText('Brazil grows the most coffee in the world');
        history.push({
          pathname: '/organisation/topup',
          state: {
            showMerchantPopup: true,
          },
        });
      } else {
        setCheckOrganisationOrNot(false);
        // sessionStorage.setItem('newUser', true);
        if (sessionStorage.getItem('Anonymous') === 'anonymous') {
          history.push('/redeemthanks');
        } else {
          history.push('/dashboard');
        }
      }
    }, 3000);
  }, []);
  if (sessionStorage.getItem('Anonymous') === 'anonymous') {
    sessionStorage.setItem('greeting', false);
  } else {
    sessionStorage.setItem('greeting', true);
  }
  // Updating user details in session storage
  const getCurrentUser = () => {
    merchantService
      .getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'];
          let userData = JSON.parse(localStorage.getItem('userData'));
          userData['firstName'] = data?.firstName;
          userData['lastName'] = data?.lastName;
          userData['fullName'] = data?.fullName;
          userData['userName'] = data?.userName;
          userData['roles'] = data?.roles;
          localStorage.setItem('userData', JSON.stringify(userData));
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        // loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              // toast.error(msg.message);
            });
          }
        }
      });
  };

  return (
    <div className="p-0 m-0">
      <div className="p-0 m-0 p-3 pl-4">
        <Link to={'/login'}>
          <Logo />
        </Link>
      </div>
      <div className="d-flex flex-column justify-content-around align-items-center bg-alice-blue loading__div__height">
        <div className="p-0 m-0 mt-4 d-flex flex-column justify-content-between">
          <div className="d-flex flex-column align-items-center">
            <LogoMark />
            <span className="my-3 font-sofia-pro-italic color-charcoal-grey">{titleText}</span>
          </div>
        </div>
        <div className="mt-4 text-center">
          <div className="p-3 m-0 card border-0 d-flex flex-row justify-content-between align-items-center loading-card__width bg-very-light-pink">
            <div className="d-flex flex-column align-items-start">
              <span className="color-black-pearl font-sofia-pro-semi-bold font-16">Coffee fact:</span>
              <span className="d-flex align-items-center color-black-pearl font-sofia-pro-light font-14">
                {' '}
                {subTitleText}
                {checkOrganisationOrNot === false && <EmojiIcon />}
                {checkOrganisationOrNot === true && <img className="p-0 m-0 pl-1" height="15px" src={FlagIcon} />}
              </span>
            </div>
            <div>
              <img className="p-0 m-0 loading-coffee__image" src={CoffeeImage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;
