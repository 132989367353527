import { Button, Overlay, Tooltip } from 'react-bootstrap';
import React, { useRef, useState } from 'react';
import { ReactComponent as Close } from '../../assets/images/dashboard/icons/icons-close-white.svg';
import useWindowDimensions from '../Responsive/WindowDimensions';

const TooltipYesNo = (
  {
    children,
    body,
    id,
    handleYes,
    placement,
    close = true
  }
) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const { width } = useWindowDimensions();

  return (
    <>
      <button className={`border-0 bg-transparent  modal-close-btn`} ref={target} onClick={() => setShow(!show)}>
        {children}
      </button>
      <Overlay onHide={() => setShow(false)} target={target.current} show={show}
               placement={width > 500 ? placement : 'left'} rootClose={true}>
        <Tooltip id={id} className={`custom-tooltip bg-pale-mavue-1 p-3 border-radius-17 border-0 ${width < 500 && 'custom-tooltip_250'}`}>
          {close &&
          <button
            type='button'
            className='close switch-to-account-modal__close__icon position-absolute close-tooltip'
            onClick={() => setShow(false)}
          >
            <Close />
          </button>
          }
          {body}
          <div className='justify-content-center d-flex'>
            <Button variant='link' onClick={handleYes}>Yes</Button>
            <Button variant='link' onClick={() => setShow(!show)}>no</Button>
          </div>
        </Tooltip>
      </Overlay>
    </>
  );
};

export default TooltipYesNo;