import React, { useState, useEffect } from 'react';
import './CustomDropdown.scss'; // Import your custom CSS
import { ReactComponent as DropdownIcon } from '../../assets/images/common/dropdown-icon.svg'; // Import your SVG icon component

const CustomDropdown = ({ options, name, value, onChange, onBlur, className, defaultValue}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || ''); // Use defaultValue here

  useEffect(() => {
    if (value !== selectedOption) {
      setSelectedOption(value);
    }
  }, [value, selectedOption]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = option => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const updatedOptions = defaultValue ? [defaultValue, ...options] : options;

  return (
    <div
      className={`${
        className ? className : 'custom-dropdown'
      } d-flex justify-content-center align-items-center form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none`}
    >
      <div className="custom-dropdown-icon" onClick={toggleDropdown}>
        <DropdownIcon />
      </div>
      <select
        name={name}
        onChange={event => {
          onChange(event);
          handleOptionChange(event.target.value);
        }}
        onBlur={onBlur}
        value={selectedOption}
      >
        {updatedOptions.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomDropdown;
