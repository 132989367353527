/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as WhyOrganisationImage } from '../../../../assets/images/account/why-organisation.svg';
import BackIcon from '../../../../assets/images/common/icons-back.png';
import { ReactComponent as Checkmark } from '../../../../assets/images/common/checkmark.svg';
import { ReactComponent as Close } from '../../../../assets/images/common/close.svg';
import { Link } from 'react-router-dom';
import './Plans.scss';
import BudgetRange from './BudgetRange';

const plans = [
  {
    name: 'ENTERPRISE',
    headcount: '100+',
    benefits: [
      {
        name: 'Company Brand',
        isIncluded: true,
      },
      {
        name: 'Unlimited Sending',
        isIncluded: true,
      },
      {
        name: 'Bulk Sending',
        isIncluded: true,
      },
      {
        name: 'Team Budgets',
        isIncluded: true,
      },
      {
        name: 'Gift Cancellation',
        isIncluded: true,
      },
      {
        name: 'Multi-media messages',
        isIncluded: true,
      },
      {
        name: 'Gift Cards',
        isIncluded: true,
        // comingSoon: true,
      },
      {
        name: 'Concierge Service',
        isIncluded: true,
      },
      {
        name: 'Unlimited Contacts',
        isIncluded: true,
      },
      {
        name: 'API integration',
        isIncluded: true,
        // comingSoon: true,
      },
      {
        name: 'Zapier',
        isIncluded: true,
      },
      {
        name: 'Milestone Recognition',
        isIncluded: true,
      },
    ],
  },
];

function OrganisationPlanPage(props) {
  return (
    <div className="w-100 bg-alice-blue">
      <div className="p-2">
        <Logo />
      </div>
      <div className="mx-lg-5 my-2 p-4 pb-5 bg-white">
        <Link to={'/dashboard'}>
          <button className="ml-3  mb-2 btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none">
            <img src={BackIcon} height="30px" />
            Back
          </button>
        </Link>
        <div className="p-0 m-0 card border-0">
          <div className="mx-lg-3 mx-md-3 row">
            <div className="p-4 col-sm-12 col-md-6 col-lg-5 bg-pale-mavue-1">
              <div className="d-flex flex-column">
                <span className="font-32 font-sofia-pro-regular color-black-pearl">Why</span>
                <span className="font-32 font-sofia-pro-bold color-primary "> Organisation account?</span>
              </div>
              <div className="d-flex flex-column">
                <span className="font-16 font-sofia-pro-light color-charcoal-grey">
                  Organisation account helps you to appreciate your employees and customers
                </span>
                <span className="mt-2 font-16 font-sofia-pro-light color-charcoal-grey">
                  Employees can redeem and send thanks within the organisation and outside to customers or other
                  registered users
                </span>
              </div>
              <div className="mt-5">
                <WhyOrganisationImage className="pr-2 d-flex justify-content-center" height="215px" width="auto" />
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-7">
              <div className="plans-section">
                <div className="d-flex flex-column mb-3">
                  <span className="font-32 font-sofia-pro-bold color-primary">Corporate Features</span>
                </div>

                <div className="m-0 p-0 row">
                  {plans.map(plan => (
                    <div className={`text-center plan-card col-xs-6`}>
                      <ul className="mt-2 list-unstyled font-sofia-pro-light font-16 color-charcoal-grey line-hight-24 text-left">
                        {plan.benefits.map(benefit => (
                          <li>
                            {benefit.isIncluded ? <Checkmark /> : <Close />}{' '}
                            <span className="plan-text">{benefit.name}</span>{' '}
                            {benefit.comingSoon ? (
                              <span className="font-12 color-cool-green">(coming soon)</span>
                            ) : null}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                {/* <BudgetRange /> */}
                <Link className="text-decoration-none" to="/organisation/details">
                  <button
                    className="w-100 shadow-none d-flex justify-content-center align-items-center btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                    style={{ marginTop: '7rem' }}
                  >
                    Create account
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganisationPlanPage;
