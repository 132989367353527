const RecordVideoStore = (state, action) => {
  switch (action.type) {
    case 'toggle_modal':
      state.showModal = action.data;
      return { ...state };
      break;
    case 'record_state_start':
      state.recordState = action.data.state;
      state.recorder = action.data.recorder;
      return { ...state };
      break;
    case 'record_state_stop':
      state.recordState = action.data.state;
      state.video = action.data.video;
      state.stopModal = action.data.stopModal;
      return { ...state };
      break;
    case 'record_stop_confirmation':
      state.stopModal = action.data.modal;
      state.recordState = action.data.recordState;
      return { ...state };
      break;
    case 'select_video':
      state.recordState = action.data.recordState;
      return { ...state };
      break;
    case 'set_thump_url':
      state.thumpUrl = action.data;
      return { ...state };
      break;
    case 'set_thump_image':
      state.thumpImg = action.data;
      return { ...state };
      break;
    case 'trigger_loader':
      state.loading = action.data;
      return { ...state };
      break;
    default:
      break;
  }
};

export default RecordVideoStore;
