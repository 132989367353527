import React, { useState, useEffect } from 'react';
import Button from '../../common/Button/Button';
import { ReactComponent as EditIcon } from '../../assets/images/common/edit-user.svg';
import { ReactComponent as Tick } from '../../assets/images/common/tick-box.svg';
import { ReactComponent as TickEmpty } from '../../assets/images/common/tick-empty.svg';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../../assets/images/common/close-modal.svg';
import { Link } from 'react-router-dom';
import OrganisationService from '../../services/OrganisationService';
import { toast } from 'react-toastify';
import BudgetRange from '../organisation/milestones/BudgetRange';
import Toast from '../../services/ToasterService';
import QuantityBox from '../organisation/milestones/QuantityBox';
import sendThanksSchema, * as SendThanksSchema from '../../utils/Schema/sendThanksSchema';
import paymentService from '../../services/PaymentService';
import { connect, useSelector, useDispatch } from 'react-redux';
import SendThanksService from '../../services/SendThanksService';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  useStripe,
  useElements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import * as RegexPatterns from '../../utils/RegexPatterns';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import { sendThanksDetails, resetSendThanksDetails } from '../../store/reducers/individual/sendThanks/sendThanks';
import { SpaceCompactItemContext } from 'antd/es/space/Compact';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Summary = ({tileWidth}) => {

  const stripe = useStripe();
  const history = useHistory();
  const location = useLocation();

  const { productsCard, details } = useSelector(state => state.multiProduct);

  console.log("===productsCard===", productsCard);
  console.log("===details1===", details);
  const [tickClick, setTickClick] = useState(false);
  const [topup, setTopUp] = useState(false);

  const [availableCreditBalance, setAvailableCreditBalance] = useState(localStorage.getItem('avaialbleBalance'));
  const [autoTopUpSelected, setAutoTopUpSelected] = useState(null);
  const [userData, setUserData] =useState({});


  // const { productsCard, details } = props.multiProduct;
  const [defaultCardList, setDefaultCardList] = useState([]);
  let [choosenCardId, setChoosenCardId] = useState();
  // const paymentInformation = props.getPaymentInformation;
  const [paymentType, setPaymentType] = useState();
  const [savedCardsList, setSavedCardsList] = useState([]);
  const [selectedCardDetails, setSelectedCardsDetails] = useState();
  const [googlepaySelect, setGooglepaySelect] = useState(false);
  const [cardDetails, setCardDetails] = useState();
  const [show, setShow] = useState(false);
  const [showSendThanksLoader, setShowSendThanksLoader] = useState(false);
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [cardLastNumber, setCardLastNumber] = useState();
  const [availableCredit, setAvailableCredit] = useState(false);
  const [checkCreditIsAvailableOrNot, setCheckCreditIsAvailableOrNot] = useState(false);

  console.log("===curre===", currentPath);

  let asCompany = currentPath == "/payment" ? false : currentPath == "/organisation/send/payment" || "/organisation/employee/payment" ? true : false

  const PaymentType = sessionStorage.getItem('PaymentType');
  console.log("PaymentType",PaymentType)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleShow = () => setShow(true);

  const loadExistingCardsList = () => {
    let clickedValue;
    // if (props?.history?.location?.state !== undefined) {
    //   clickedValue = props?.history?.location?.state.googlepaySelect;
    // }
    let googlePayCard = sessionStorage.getItem('PaymentType') ? sessionStorage.getItem('PaymentType') : 'Card';
    if (googlePayCard === 'GooglePay' && clickedValue === true) {
      setGooglepaySelect(true);
    } else if (googlePayCard === 'Card') {
      setGooglepaySelect(false);
    }
    console.log("===asCo==", asCompany);

    paymentService
      .getListOfCards(asCompany)
      .then(response => {
        if (response) {
          let data = response['data'];
          setSavedCardsList(data);
          console.log("====Cd====", data);
          setCardLastNumber(data[0].last4);
          setChoosenCardId(data[0].id);
        }
      })
      .catch(error => {
        if (error.response) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          Toast(errorMessage, 'error');
        }
      });
  };

  const handleChangeGiftType = () => {
    if(currentPath == "/payment") {
      history.push('/selectItem');
    } else if(currentPath == "/organisation/send/payment") {
      history.push('/organisation/send/selectItem');
    } else {
      history.push('/organisation/employee/selectItem');
    }
    
  };

  const handleChangeReceiverEmail = () => {
    if(currentPath == "/payment") {
      history.push('/recipientInformation');
    } else if(currentPath == "/organisation/send/payment") {
      history.push({pathname: '/organisation/send/recipientInformation', state: {keepRecipients: true}});
    } else {
      // history.push('/organisation/employee/recipientInformation');
      history.push({pathname: '/organisation/employee/recipientInformation', state: {keepRecipients: true}});
    }
  };

  const editMessageBtnHandler = () => {
    if(currentPath == "/payment") {
      history.push('/recipientInformation');
    } else if(currentPath == "/organisation/send/payment") {
      history.push({pathname: '/organisation/send/recipientInformation', state: {keepRecipients: true}});
    } else {
      // history.push('/organisation/employee/recipientInformation');
      history.push({pathname: '/organisation/employee/recipientInformation', state: {keepRecipients: true}});
    }
  };

  const cancelBtnHandler = () => {
    if(currentPath == "/payment") {
      history.push('/dashboard');
    } else if(currentPath == "/organisation/send/payment") {
      history.push('/organisation/dashboard');
    } else {
      history.push('/employee/dashboard');
    }
    
  };

  const useCard = () => {
    setTickClick(false)
    setAvailableCredit(!availableCredit);
    setGooglepaySelect(false);
    sessionStorage.setItem('PaymentType', 'Card');
    setPaymentType('Card');
  }
  // Available credits
  const availableCreditBtnHandler = () => {

    if (details?.type !== "GiftCard" ? (price * productsCard?.quantity * details?.recipients.length > availableCreditBalance): (price * details?.recipients.length > availableCreditBalance)) {
      setCheckCreditIsAvailableOrNot(true);
      if(currentPath == "/organisation/employee/payment") {
        toast.error("You do not have enough credit. Contact your administrator");
      } else if(currentPath == "/organisation/send/payment"){
        toast.warn("Your card will be charged for this transaction. Please top up your account as an option.");
        setTickClick(false)
        setAvailableCredit(!availableCredit);
        setGooglepaySelect(false);
        sessionStorage.setItem('PaymentType', 'Card');
        setPaymentType('Card');
      }
    } else {
      setCheckCreditIsAvailableOrNot(false);
      setTickClick(true);
      setAvailableCredit(!availableCredit);
      setGooglepaySelect(false);
      sessionStorage.setItem('PaymentType', 'availableCredit');
      setPaymentType('availableCredit');
    }
  };

  useEffect(() => {
    if(currentPath !== "/organisation/employee/payment") {
      sessionStorage.setItem('PaymentType', 'Card');
    } 
    else {
      sessionStorage.setItem('PaymentType', '');
    }

    if (details?.type !== "GiftCard" ? (price * productsCard?.quantity * details?.recipients.length < availableCreditBalance): (price * details?.recipients.length < availableCreditBalance)) {
      setCheckCreditIsAvailableOrNot(false);
      setTickClick(true);
      setAvailableCredit(!availableCredit);
      setGooglepaySelect(false);
      sessionStorage.setItem('PaymentType', 'availableCredit');
      setPaymentType('availableCredit');
    } else {
      if(currentPath == "/organisation/send/payment") {
        toast.warn("Your card will be charged for this transaction. Please top up your account as an option.");
        setTickClick(false)
        setAvailableCredit(!availableCredit);
        setGooglepaySelect(false);
        sessionStorage.setItem('PaymentType', 'Card');
        setPaymentType('Card');
      }
    }
    loadExistingCardsList();
    window.scrollTo(0, 0);
  }, []);


  useEffect(()=> {
    localStorage.setItem("productImg", details?.type !== "GiftCard" ? (productsCard?.imagePath ? productsCard?.imagePath : productsCard?.imageUrl): details?.image);
    localStorage.setItem("productName", details?.type !== "GiftCard" ? productsCard?.name : details?.type);
    // OrganisationService.getOrganisationDetails()
    //   .then(response => {
    //     if (response) {
    //       let data = response['data'];
    //       setUserData(data);
    //       formik.setFieldValue("amount", data?.monthlyBudget || '');
    //     }
    //   })
    //   .catch(error => {
    //     let errorResponseGlobalErrors = error.response['data'].globalErrors;
    //     if (errorResponseGlobalErrors) {
    //       if (errorResponseGlobalErrors.length > 0) {
    //         errorResponseGlobalErrors.forEach(msg => {
    //           Toast(msg.message, 'error');
    //         });
    //       }
    //     }
    //   });
  }, [])

  const handleCheckbox = () => {
    setTickClick(!tickClick);
  }

  const handleTopUp = () => {
    setTopUp(true);
  }

  const handleClose = () => {
    setTopUp(false);
  }
  const handleCardClose = () => {
    setShow(false);
  }

  // entered amount validated from 0 to 100000
  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) {
      event.preventDefault();
    }

    var max_chars = 5;

    if (event.target.value.length > max_chars) {
      event.target.value = event.target.value.substr(0, max_chars);
    }
  };

  let priceId =
    details.type === 'GiftCard'
      ? details.giftPriceId
      : productsCard?.prices
      ? productsCard?.prices[0]?.priceId
      : productsCard.priceId;
  let price =
    details.type === 'GiftCard'
      ? details.giftValues * 1.05
      : productsCard?.prices
      ? productsCard?.prices[0]?.price
      : productsCard.price;

  let reviewData = {};

  if (selectedCardDetails) {
    // reviewData = props?.props?.location?.state;
    reviewData = selectedCardDetails;
    reviewData['amount'] = details.type === 'GiftCard' ? (details.giftValues ? price : 0) : reviewData.amount;
  }

  const AddCardForm = props => {
    const stripe = useStripe();
    const elements = useElements();
  
    const [cardElemetError, setCardElementError] = useState('');
    const [cardExpiryError, setCardExpiryError] = useState('');
    const [cardCVCError, setCardCVCError] = useState('');
    const initialValues = {
      postcode: null,
    };
    const [disableBtn, setDisableBtn] = useState(false);
  
    let checkNewCardAdded = false;

    // Updating default card
  const setAsDefaultBtnHandler = index => {
    let data = savedCardsList[index];

    let cardData = {
      id: data.id,
      expiresOn: data.expiresOn,
      setAsDefault: true,
    };

    paymentService
      .updateCard(data.id, cardData)
      .then(response => {
        if (response.status === 200) {
          loadExistingCardsList();
          Toast('Your default card is changed successfully', 'success');
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseFieldErrors) {
          if (errorResponseFieldErrors.length > 0) {
            errorResponseFieldErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };
  
    const saveCardDetails = async event => {
      if (checkNewCardAdded === false) {
        checkNewCardAdded = true;
  
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          Toast('Please add card details', 'error');
          return;
        }
  
        // Block native form submission.
        event.preventDefault();
  
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement);
        const cardDateElement = elements.getElement(CardExpiryElement);
        const cardCVVElement = elements.getElement(CardCvcElement);
  
        // Use your card Element with other Stripe.js APIs
        const { error, token } = await stripe.createToken(cardElement);
  
        if (error) {
          Toast('Please add the valid card details', 'error');
        } else if (formik.values.postcode === null) {
          Toast('Please add a postcode', 'error');
        } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
          Toast('Please add a valid postcode', 'error');
        } else {
          let tokenId = token.id;
          let data = {
            token: tokenId,
            setAsDefault: true,
            region: 'AU',
            postcode: formik.values.postcode,
          };
          console.log("===aC===", asCompany)
          paymentService
            .deleteCards(asCompany)
            .then(response => {
              if (response) {
                Toast('All cards deleted successfully', 'success');
                paymentService
                  .addNewCard(data, asCompany)
                  .then(response => {
                    if (response) {
                      console.log("---resp===", response);
                      setChoosenCardId(response?.data?.id);
                      Toast('New card added successfully', 'success');
                      formik.values.postcode = '';
                      setTimeout(() => {
                        props.loadExistingList();
                        props.hideModal();
                        checkNewCardAdded = false;
                      }, 1000);
                      handleCardClose();
                    }
                  })
                  .catch(error => {
                    if (error) {
                      let errorResponseGlobalErrors = error.response['data'].globalErrors;
                      if (errorResponseGlobalErrors) {
                        if (errorResponseGlobalErrors.length > 0) {
                          errorResponseGlobalErrors.forEach(msg => {
                            console.log('error global response message ', msg);
                            Toast(msg.message, 'error');
                          });
                        }
                      }
                    }
                  });
              }
            })
            .catch(error => {
              if (error) {
                let errorResponseGlobalErrors = error.response['data'].globalErrors;
                if (errorResponseGlobalErrors) {
                  if (errorResponseGlobalErrors.length > 0) {
                    errorResponseGlobalErrors.forEach(msg => {
                      console.log('error global response message ', msg);
                      Toast(msg.message, 'error');
                    });
                  }
                }
              }
            });
        }
      }
    };
  
    const handleChange = e => {
      if (e.elementType === 'cardNumber') {
        setCardElementError(e?.error?.message);
      } else if (e.elementType === 'cardNumber' && e.complete === true) {
        setCardElementError('');
      }
  
      if (e.elementType === 'cardExpiry') {
        setCardExpiryError(e?.error?.message);
      } else if (e.elementType === 'cardExpiry' && e.complete === true) {
        setCardExpiryError('');
      }
  
      if (e.elementType === 'cardCvc') {
        setCardCVCError(e?.error?.message);
      } else if (e.elementType === 'cardCvc' && e.complete === true) {
        setCardCVCError('');
      }
    };
    const validate = values => {
      const errors = {};
  
      if (values.postcode === null) {
        errors.postcode = 'Please add a  postcode';
      }
  
      return errors;
    };
  
    const handleDisable = () => {
      if (cardElemetError !== '' || cardExpiryError !== '' || cardCVCError !== '') {
        setDisableBtn(true);
      } else {
        setDisableBtn(false);
      }
    };
    const formik = useFormik({
      initialValues,
      validate,
    });
  
    return (
      <div>
        <div className="mt-3">
          <span className="font-sofia-pro-regular color-charcoal font-16 label-coffee">Card Number</span>
          <CardNumberElement className="payment-option__card form-control font-sofia-pro-regular shadow-none font-14" onChange={handleChange} />
          <span className="font-12 color-charcoal font-sofia-pro-regular">{cardElemetError}</span>
        </div>
        <div className="mt-3">
          <span className="font-sofia-pro-regular color-charcoal font-16 label-coffee">Expiry month / Year</span>
          <CardExpiryElement className="payment-option__expiry_element form-control font-sofia-pro-regular shadow-none font-14" onChange={handleChange} />
          <span className="font-12 color-charcoal font-sofia-pro-regular">{cardExpiryError}</span>
        </div>
        <div className="p-0 m-0 row mt-3">
          <div className="p-0 pr-1 col-sm-12 col-md-12 col-lg-12">
            <div>
              <span className="font-sofia-pro-regular color-charcoal font-16 label-coffee">CVV</span>
              <CardCvcElement className="payment-option__cvv form-control font-sofia-pro-regular shadow-none font-14" onChange={handleChange} />
              <span className="font-12 color-charcoal font-sofia-pro-regular">{cardCVCError}</span>
            </div>
          </div>
  
          {/* <div className="p-0 col-sm-12 col-md-12 col-lg-6">
                      <span className="font-16 color-charcoal font-sofia-pro-regular">Postcode</span>
                      <input type="number" className="form-control h-60 shadow-none" />
                  </div> */}
        </div>
        <div className="mt-3 d-flex flex-column">
          <span className="font-sofia-pro-regular color-charcoal font-16 label-coffee">Postcode</span>
          <input
            className="payment-option__expiry_element form-control font-sofia-pro-regular shadow-none font-14"
            placeholder="Eg: 2122"
            name="postcode"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postcode}
          />
          {formik.errors.postcode ? (
            <span className="font-12 color-charcoal font-sofia-pro-regular">{formik.errors.postcode}</span>
          ) : null}
        </div>
        <div className="my-2">
          {/* <button
            // className="w-150 btn bg-primary-color color-white h-44 font-sofia-pro-semi-bold font-16 outline-none"
            className='w-100 my-3 shadow-none d-flex justify-content-center align-items-center h-60 btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white cursor-pointer'
            type="button"
            disabled={disableBtn}
            onClick={saveCardDetails}
            
          >
            Save
          </button> */}
          <Button 
            classNameValue='w-100 my-3 shadow-none d-flex justify-content-center align-items-center h-60 btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white cursor-pointer'
            children='Save'
            onClick={saveCardDetails}
            showLoader={false}
            disabled={disableBtn}
          />
        </div>
      </div>
    );
  };

  const handlePayment = async (intentSecret, paymentMethod) => {
    const paymentIntent = await stripe.confirmCardPayment(intentSecret, {
      payment_method: paymentMethod,
    });

    let data = paymentIntent.paymentIntent;

    if (data?.status === 'succeeded') {
      if(currentPath == "/payment") {
        history.push({
          pathname: '/thanksSuccess',
          state: {
            productType: details?.type,
          },
        });
      } else if (currentPath === "/organisation/send/payment") {
        history.push({
          pathname: '/organisation/send/thanksSuccess',
          state: {
            productType: details?.type,
          },
        });
      } else {
        history.push({
          pathname: '/organisation/employee/thanksSuccess',
          state: {
            productType: details?.type,
          },
        });
      }
      
    } else {
      let errorMessage = paymentIntent?.error?.message;
      toast.error(errorMessage);
    }
  };

  const sendThanksEmployeeBtnHandler = () => {
    if (details?.type !== "GiftCard" ? (price * productsCard?.quantity * details?.recipients.length > availableCreditBalance): (price * details?.recipients.length > availableCreditBalance)) {
      toast.error("You do not have enough credit. Contact your administrator");
    } else {
      setShowSendThanksLoader(true);
      let data = {
        priceId: priceId,
        quantity:
          details?.recipients?.length > 1 ? details?.recipients?.length * productsCard?.quantity : productsCard?.quantity,
        amount: (
          Number(
            details?.recipients?.length > 1
              ? details?.recipients?.length * productsCard?.quantity
              : productsCard?.quantity
          ) * Number(price)
        ).toFixed(2),
        recipients: details.recipients,
        message: details?.message,
      };

      if (details.type === 'GiftCard') {
        // data.amount = reviewData.amount ? reviewData.amount * details?.recipients.length : reviewData.price;
        data.amount = price * details?.recipients.length;
        data.quantity = details?.recipients?.length;
      }

      if (details?.message?.type === null) {
        delete data.message;
      }

      data['sendAsCompany'] = true;

      if (PaymentType === 'Card') {
        SendThanksService.purchaseIntent(data)
          .then(response => {
            if (response.status === 200) {
              setShowSendThanksLoader(false);
              let secretId = response['data'].intentSecret;
              // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
              let paymentMethod = reviewData.choosedCardId;
              console.log("===choosenCard===", choosenCardId);
              handlePayment(secretId, choosenCardId);
              // handlePayment(secretId, paymentMethod);
            }
          })
          .catch(error => {
            setShowSendThanksLoader(false);
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;

            console.log('error filed errors ', errorResponseFieldErrors.length);
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  toast.error(msg.message);
                });
              }
            }
          });
      } else if (PaymentType === 'availableCredit') {
        data['paymentsource'] = {
          usecredit: true,
        };

        SendThanksService.purchaseProduct(data)
          .then(response => {
            if (response.status === 200) {
              setShowSendThanksLoader(false);
              let secretId = response['data'].intentSecret;
              let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
              // handlePayment(secretId, paymentMethod, props);
              history.push({
                pathname: '/organisation/employee/thanksSuccess',
                state: {
                  productType: details?.type,
                  type: 'organisation',
                },
              });
            }
          })
          .catch(error => {
            setShowSendThanksLoader(false);
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;

            console.log('error filed errors ', errorResponseFieldErrors.length);
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  toast.error(msg.message);
                });
              }
            }
          });
      }
    }
  };

  const sendThanksBtnCorporateHandler = () => {
    setShowSendThanksLoader(true);
    let data = {
      priceId: priceId,
      quantity:
        details?.recipients?.length > 1 ? details?.recipients?.length * productsCard?.quantity : productsCard?.quantity,
      amount: (
        Number(
          details?.recipients?.length > 1
            ? details?.recipients?.length * productsCard?.quantity
            : productsCard?.quantity
        ) * Number(price)
      ).toFixed(2),
      recipients: details.recipients,
      message: details.message,
    };

    if (sendThanksSchema.sendThanksData.recipientCsvFileBase64 !== null) {
      data['recipientCsvFileBase64'] = sendThanksSchema.sendThanksData.recipientCsvFileBase64;
      delete data.recipients;
    }

    if (details?.type === 'GiftCard') {
      console.log("===reviewData.amount===", reviewData.price);
      // data.amount = reviewData.amount ? reviewData.amount * details?.recipients.length : reviewData.price;
      data.amount = price * details?.recipients.length;
      data.quantity = details?.recipients?.length;
    }

    if (details?.message?.type === null) {
      delete data?.message;
    }

    data['sendAsCompany'] = true;

    if (PaymentType === 'Card') {
      console.log("===intentData===", data);
      SendThanksService.purchaseIntent(data)
        .then(response => {
          console.log("===choosenCard===", choosenCardId);
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            let secretId = response['data'].intentSecret;
            // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
            // console.log('paymentMethod', paymentMethod);
            let paymentMethod = reviewData.choosedCardId;

            handlePayment(secretId, choosenCardId);
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;

          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    } else if (PaymentType === 'availableCredit') {
      data['paymentsource'] = {
        usecredit: true,
      };

      SendThanksService.purchaseProduct(data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            let secretId = response['data'].intentSecret;
            // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
            let paymentMethod = reviewData.choosedCardId;
            handlePayment(secretId, paymentMethod);
            history.push({
              pathname: '/organisation/send/thanksSuccess',
              state: {
                productType: details?.type,
                type: 'organisation',
              },
            });
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;

          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };
  // // Purchase request
  const sendThanksBtnHandler = () => {
    setShowSendThanksLoader(true);
    let data = {
      priceId: priceId,
      quantity:
        details?.recipients?.length > 1 ? details?.recipients?.length * productsCard?.quantity : productsCard?.quantity,
      amount: (
        Number(
          details?.recipients?.length > 1
            ? details?.recipients?.length * productsCard?.quantity
            : productsCard?.quantity
        ) * Number(price)
      ).toFixed(2),
      recipients: details.recipients,
      message: details.message,
    };

    if (details.type === 'GiftCard') {
      // data.amount = reviewData.amount ? reviewData.amount * details.recipients.length : reviewData.price;
      data.amount = price * details?.recipients.length;
      data.quantity = details.recipients.length;
    }

    if (details?.message?.type === null) {
      delete data?.message;
    }

    if (PaymentType === 'Card') {
      SendThanksService.purchaseIntent(data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            let secretId = response['data'].intentSecret;
            // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
            let paymentMethod = reviewData.choosedCardId;
            handlePayment(secretId, choosenCardId);
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;

          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  const loopFieldErrors = data => {
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const getCardsList = () => {
    paymentService.getListOfCards().then(response => {
      if (response.status === 200) {
        let cardId = SendThanksSchema.sendThanksData.paymentSource.cardid;

        let data = response['data'];

        data.forEach((card, index) => {
          if (card.id === cardId) {
            reviewData['cardLastNumber'] = card?.last4;
            reviewData['brand'] = card?.brand;
            reviewData['cardExpiryDate'] = card?.expiresOn;

            let data = {
              cardLastNumber: card?.last4,
              brand: card?.brand,
              cardExpiryDate: card?.expiresOn,
            };

            setCardDetails(data);
          }
        });
      }
    });
  };

  return (
    <div>
      {windowWidth <= 767 ? (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
              padding: '0 20px',
              width: '408px',
            }}
          >
            <div>
              <div>
                <div className="d-flex flex-column position-relative" style={{marginRight: '10%'}}>
                  <span className="font-sofia-pro-regular font-18 color-black-pearl text-center">
                    Your Gifting Summary
                  </span>
                  <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
                    Does everything look good?
                  </p>
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                  <div className="mt-2 d-flex flex-column mr-3"></div>
                  <div className="d-flex flex-column summary-box-style ml-3">
                    <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Summary</label>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Gift</span>
                        <span className="ml-2 font-sofia-pro-regular font-14 color-primary"  onClick={handleChangeGiftType}>Edit</span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{details?.type}</span>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <span className="font-sofia-pro-regular color-gunmetal font-14">Cost per gift</span>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">${price}</span>
                    </div>

                    <hr className="w-100" style={{ color: '#F1F4F7' }} />

                    <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Recipients</span>
                        <span className="ml-2 font-sofia-pro-regular font-14 color-primary" onClick={handleChangeReceiverEmail}>Edit</span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{`x${details.recipients.length}`}</span>
                    </div>
                    {/* <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Gifting preferences</span>
                        <span
                          className="ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer"
                          onClick={handleBack}
                        >
                          Edit
                        </span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{`${formik.values.giftType}, ${formik.values.giftFrequency}`}</span>
                    </div> */}
                    <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Message</span>
                        <span className="ml-2 font-sofia-pro-regular font-14 color-primary" onClick={editMessageBtnHandler}>Edit</span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">
                        {details?.message?.message !== null ? details?.message?.message : details?.message?.type == "Video" ? details?.message?.type : "No Message"}
                      </span>
                    </div>
                    <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Purchase summary</label>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <span className="font-sofia-pro-regular color-gunmetal font-14">Subtotal</span>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{`$${details?.type !== "GiftCard" ? price * productsCard?.quantity: price}`}</span>
                    </div>
                    {currentPath !== "/payment" ? <div className="d-flex justify-content-between p-2">
                      <span className="font-sofia-pro-regular color-gunmetal font-14">Available thanks balance</span>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{availableCreditBalance}</span>
                    </div>: null}
                    <div className="mt-4 mb-0 d-flex align-items-center justify-content-between summary-label-box-style">
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Total (AUD)</label>
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">{`$${details?.type !== "GiftCard" ? price * productsCard?.quantity * details?.recipients.length: price * details?.recipients.length}`}</label>
                    </div>
                  </div>
                  <div
                    className="mt-3 d-flex flex-column summary-box-style justify-content-start"
                    style={{ marginLeft: '18px' }}
                  >
                    <label className="font-sofia-pro-regular color-charcoal font-16 mb-0">Paying by</label>
                    {currentPath !== "/organisation/employee/payment" && <div className="mt-2 px-3 d-flex align-items-center justify-content-between pay-field-box-style">
                      <span className="font-sofia-pro-regular color-charcoal font-14">Credit card ending with {cardLastNumber}</span>
                      <EditIcon className="cursor-pointer" onClick={handleShow} />
                    </div>}
                  {currentPath !== "/payment" && (availableCreditBalance == 0 ?
                  <>
                  <div className='mt-2 d-flex align-items-center'>
                  {/* {currentPath == "/organisation/employee/payment" ? <Tick/>: */}
                    {tickClick ? <Tick className='cursor-pointer' onClick={useCard}/>: <TickEmpty className='cursor-pointer' onClick={availableCreditBtnHandler}/>}
                    {/* } */}
                    <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>You do not have enough credit. </span>
                    {currentPath !== "/organisation/employee/payment" && <span className='ml-2 font-sofia-pro-regular color-primary font-14 cursor-pointer' 
                    onClick={handleTopUp}
                    >
                      Top up</span>}
                  </div>
                  <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
                  </>
                    : <>
                    <div className='mt-2 d-flex align-items-center'>
                    {tickClick ? <Tick className='cursor-pointer' onClick={useCard}/>: <TickEmpty className='cursor-pointer' onClick={availableCreditBtnHandler}/>}
                    <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>Use Available credit</span>
                  </div>
                  <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
                  </>)}
                  </div>
                </div>
                <div className="mt-4 mb-0 d-flex justify-content-center w-100 ml-1">
                {/* <Link to="/organisation/milestones" style={{ textDecoration: 'none' }}> */}
                    <Button
                      classNameValue="summary-coffee shadow-none d-flex justify-content-center align-items-center h-60 w-100 btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                      children="Send"
                      showLoader={false}
                      onClick={currentPath == "/payment" ? sendThanksBtnHandler: currentPath == "/organisation/send/payment" ? sendThanksBtnCorporateHandler: sendThanksEmployeeBtnHandler}
                      // onClick={() => {
                      //   onSubmit(formik.values);
                      // }}
                      // disabled={availableCreditBalance < price}
                    />
                  {/* </Link> */}
                </div>
                <div
                  className="mt-2 mb-5 d-flex justify-content-center w-100"
                  // style={{ height: '40px', marginLeft: '25px' }}
                >
                  <span className="font-sofia-pro-regular color-charcoal font-12 terms">
                    By setting up a milestone, you agree to the appreci{' '}
                    <a
                      href="https://appreci.io/terms/"
                      target="blank"
                      className="color-primary font-12 font-sofia-pro-medium"
                    >
                      terms and conditions{' '}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {details.quantity ? <QuantityBox quantity={details.quantity} total={price} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
          <div className="d-flex flex-column position-relative">
            <span className="font-sofia-pro-regular font-32 color-black-pearl text-center">
              Your Gifting Summary
            </span>
            <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
              Does everything look good?
            </p>
          </div>
          <div className="d-flex justify-content-center flex-wrap">
            <div className="mt-2 d-flex flex-column mr-3">
              <div className="d-flex summary-box-style justify-content-center">
                <div className="d-flex align-items-center justify-content-center summary-label-box-style1">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="img-item">
                      <img className={`w-100 h-100 category-img`} src={`${details?.type !== "GiftCard" ? (productsCard?.imagePath ? productsCard?.imagePath : productsCard?.imageUrl): details?.image}?h=${tileWidth}`} style={{opacity: '0.5'}}/>
                    </div>
                    <span className="mt-2 font-sofia-pro-regular color-gunmetal font-14">
                      Your {details?.type !== "GiftCard" ? productsCard?.name?.split(",")[0] : details?.type} is on the way.
                    </span>
                    <Link to="/previewgift" target='_blank' style={{textDecoration: 'none'}}>
                      <Button
                        classNameValue={`w-100 mt-3 color-primary preview-btn shadow-none d-flex justify-content-center align-items-center h-56 btn font-14 font-sofia-pro-light cursor-pointer`}
                        children="Preview gift experience"
                        showLoader={false}

                        // onClick={()=> {handleCategoryClicked("allProducts")}}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex flex-column summary-box-style justify-content-start">
                <label className="font-sofia-pro-regular color-charcoal font-16 mb-0">Paying by</label>
                {currentPath !== "/organisation/employee/payment" && <div className="mt-2 px-3 d-flex align-items-center justify-content-between pay-field-box-style">
                  <span className="font-sofia-pro-regular color-charcoal font-14">Credit card ending with {cardLastNumber}</span>
                  <EditIcon className="cursor-pointer" onClick={handleShow}/>
                </div>}
                {currentPath !== "/payment" &&(availableCreditBalance == 0 ?
                  <>
                  <div className='mt-2 d-flex align-items-center'>
                  {/* {currentPath == "/organisation/employee/payment" ? <Tick/>: */}
                    {tickClick ? <Tick className='cursor-pointer' onClick={useCard}/>: <TickEmpty className='cursor-pointer' onClick={availableCreditBtnHandler}/>}
                  {/* } */}
                    <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>You do not have enough credit. </span>
                    {currentPath !== "/organisation/employee/payment" && <span className='ml-2 font-sofia-pro-regular color-primary font-14 cursor-pointer' 
                    onClick={handleTopUp}
                    >
                      Top up</span>}
                  </div>
                  <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
                  </>
                  : <>
                    <div className='mt-2 d-flex align-items-center'>
                    {/* {currentPath == "/organisation/employee/payment" ? <Tick/>: */}
                    {tickClick ? <Tick className='cursor-pointer' onClick={useCard}/>: <TickEmpty className='cursor-pointer' onClick={availableCreditBtnHandler}/>}
                    {/* } */}
                    <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>Use Available credit</span>
                    <Link to={"/organisation/topup"} style={{textDecoration: 'none'}}>
                    {currentPath !== "/organisation/employee/payment" && <p className='ml-2 font-sofia-pro-regular color-primary font-14 cursor-pointer' style={{marginBottom: '0px', marginTop: '0px'}}>
                      Top up
                    </p>}
                    </Link>
                  </div>
                  <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
                  </>)}
              </div>
            </div>
            <div className="d-flex flex-column summary-box-style ml-3">
              <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Summary</label>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Gift</span>
                  <span className='ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={handleChangeGiftType}>Edit</span>
                  </div>
                  <span className="font-sofia-pro-regular color-gunmetal font-14">{details?.type}</span>
              </div>
              <div className="d-flex justify-content-between p-2">
                <span className="font-sofia-pro-regular color-gunmetal font-14">Cost per gift</span>
                <span className="font-sofia-pro-regular color-gunmetal font-14">${price}</span>
              </div>
              {/* <div className='w-100'> */}

              <hr className="w-100" style={{ color: '#F1F4F7' }} />
              {/* </div> */}
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Recipients</span>
                  <span className='ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={handleChangeReceiverEmail}>Edit</span>
                </div>
                <span className="font-sofia-pro-regular color-gunmetal font-14">{`x${details.recipients.length}`}</span>
              </div>
              {/* <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Gifting preferences</span>
                  <span
                    className="ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer"
                    onClick={handleBack}
                  >
                    Edit
                  </span>
                </div>
                <span className="font-sofia-pro-regular color-gunmetal font-14">{`${formik.values.giftType}, ${formik.values.giftFrequency}`}</span>
              </div> */}
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Message</span>
                  <span className='ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={editMessageBtnHandler}>Edit</span>
                </div>
                <span className="font-sofia-pro-regular color-gunmetal font-14">
                  {details?.message?.message !== null
                    ? details?.message?.message?.length > 25
                      ? details?.message?.message?.slice(0, 25) + "..."
                      : details?.message?.message
                    : details?.message?.type === "Video"
                    ? details?.message?.type
                    : "No Message"}
                </span>
              </div>
              <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Purchase summary</label>
              </div>
              <div className="d-flex justify-content-between p-2">
                <span className="font-sofia-pro-regular color-gunmetal font-14">Subtotal</span>
                <span className="font-sofia-pro-regular color-gunmetal font-14">{`$${details?.type !== "GiftCard" ? price * productsCard?.quantity: price}`}</span>
              </div>
              {currentPath !== "/payment" ?<div className="d-flex justify-content-between p-2">
                <span className="font-sofia-pro-regular color-gunmetal font-14">Available thanks balance</span>
                <span className="font-sofia-pro-regular color-gunmetal font-14">${availableCreditBalance}</span>
              </div>: null}
              <div className="mt-4 mb-0 d-flex align-items-center justify-content-between summary-label-box-style">
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Total (AUD)</label>
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">{`$${details?.type !== "GiftCard" ? price * productsCard?.quantity * details?.recipients.length: price * details?.recipients.length}`}</label>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-0 d-flex justify-content-center w-100">
            <Button
              classNameValue="twoBrand-back-coffee shadow-none d-flex justify-content-center align-items-center h-60 btn font-16 font-sofia-pro-semi-bold"
              children="Cancel"
              showLoader={false}
              onClick={cancelBtnHandler}
            />
           {/* <Link to="/organisation/milestones" style={{textDecoration: 'none'}}> */}
            <Button
            classNameValue="summary-coffee shadow-none d-flex justify-content-center align-items-center h-60 btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
            children="Send"
            showLoader={false}
            onClick={currentPath == "/payment" ? sendThanksBtnHandler: currentPath == "/organisation/send/payment" ? sendThanksBtnCorporateHandler: sendThanksEmployeeBtnHandler}
            // onClick={() => {onSubmit(formik.values)}}
            // disabled={availableCreditBalance < price}
            />
          {/* </Link> */}
          </div>
          <div className="mt-2 mb-5 d-flex justify-content-center">
            <span className="font-sofia-pro-regular color-charcoal font-12">
              By setting up a milestone, you agree to the appreci{' '}
              <a
                href="https://appreci.io/terms/"
                target="blank"
                className="color-primary font-12 font-sofia-pro-medium"
              >
                terms and conditions{' '}
              </a>
            </span>
          </div>
          {/* <Modal
        className="pt-4 border-0 modal-background-shadow contactss-modal"
        size="xs"
        backdrop="static"
        show={topup}
        onHide={handleClose}
        animation={false}
      >
        <div className="d-flex flex-column justify-content-center mt-4" style={{ marginLeft: '42px' }}>
          <div className="position-relative">
            <button
              type="button"
              className="border-0 position-absolute top-0"
              style={{ background: 'transparent', top: '7px', right: '20px' }}
              onClick={handleClose}
            >
              <Close />
            </button>
            <div className="font-sofia-pro contact-heading">Top-up account</div>
          </div>
          <div className="modal-body">
            <div className="container">
            <div className="col-md-12 d-flex flex-column w-100 mb-3">
                  <label className="organisation-topup__Enter-amount font-sofia-pro-regular">Default payment method</label>
                  <div className="input-group my-1 d-flex align-items-center budget-section">
                    <span className="h-60 input-group-text border-light-periwinkle h-60 border-right-0">$</span>
                      <input
                        autoComplete="off"
                        onKeyPress={onKeyPress.bind(this)}
                        type="number"
                        pattern="\d{3}"
                        required
                        onWheel={e => e.target.blur()}
                        // defaultValue={parseInt(availableCreditBalance)}
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        name="amount"
                        className="form-control w-auto shadow-none font-sofia-pro-regular color-charcoal-grey border-light-periwinkle budget-input h-60"
                        placeholder="Please enter the topup amount"
                      />
                  </div>
                  <span className='font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
              </div>
              <div className="col-md-12 d-flex flex-column w-100 mb-3">
                  <label className="organisation-topup__Enter-amount font-sofia-pro-regular">Enter amount</label>
                  <div className="input-group my-1 d-flex align-items-center budget-section">
                    <span className="h-60 input-group-text border-light-periwinkle h-60 border-right-0">$</span>
                      <input
                        autoComplete="off"
                        onKeyPress={onKeyPress.bind(this)}
                        type="number"
                        pattern="\d{3}"
                        required
                        onWheel={e => e.target.blur()}
                        // defaultValue={parseInt(availableCreditBalance)}
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        name="amount"
                        className="form-control w-auto shadow-none font-sofia-pro-regular color-charcoal-grey border-light-periwinkle budget-input h-60"
                        placeholder="Please enter the topup amount"
                      />
                  </div>
                  <span className='font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
              </div>
              <div className="topup-slide">
                  <BudgetRange
                    autoTopUp={userData?.monthlyAutoTopUp}
                    budget={userData?.monthlyBudget}
                    topUpCreate={false}
                    formik={formik}
                    setAutoTopUpSelected={setAutoTopUpSelected}
                  />
                </div>
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <button
                  className="ml-3 my-2 shadow-none d-flex justify-content-center align-items-center h-60 btn  bg-transparent font-16 font-sofia-pro color-gunmetal btn_cancel_invite"
                  style={{ border: '1px solid #B5C3CC', color: '#B5C3CC', width: '130px' }}
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <Button
                  classNameValue="ml-3 my-2 shadow-none d-flex justify-content-center align-items-center h-60 btn  bg-primary-color font-16 font-sofia-pro text-white btn_cancel_invite"
                  children="TopUp"
                  showLoader={showLoader}
                  style={{ width: '130px', marginRight: '15px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
      <Modal
        className="pt-4 border-0
         modal-background-shadow d-flex align-items-center"
        size="md"
        show={show}
        onHide={handleCardClose}
        animation={false}
      >
        <div className='d-flex flex-column coffee-box-style' style={{marginTop:"0%", padding:"0.65rem"}}>
          <Modal.Header className="p-0 m-0 px-3 border-0 d-flex align-items-center">
          <div className=' w-100 position-relative'>
          <span onClick={handleCardClose} className='cursor-pointer w-100 d-flex justify-content-end position-absolute' style={{left: '15px'}}>
          <Close />
          </span>
          <p className="font-32 color-black-pearl font-sofia-pro-semi-bold text-center w-100">Add a card</p> 
          </div>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">

            <Elements stripe={stripePromise}>
              <ElementsConsumer>
                
                {({ elements, stripe }) => (
                  <AddCardForm
                    elements={elements}
                    stripe={stripe}
                    hideModal={handleClose}
                    loadExistingList={loadExistingCardsList}
                  />
                )}
              </ElementsConsumer>
            </Elements>
          </Modal.Body>
        </div>
      </Modal>
        </div>
      )}
    </div>
  );
};

const SummaryPurchase = props => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => <Summary elements={elements} stripe={stripe} props={props} tileWidth={props.tileWidth}/>}
      </ElementsConsumer>
    </Elements>
  );
};

// MAPPING - SEND THANKS DATA
const mapStateToProps = state => {
  return {
    getPaymentInformation: state.sendThanks.sendThanksData,
  };
};

// DISPATCH  - SEND THANKS DATA
const mapDispatchToProps = dispatch => {
  return {
    paymentInformation: data => dispatch(sendThanksDetails(data)),
    resetData: () => dispatch(resetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPurchase);
// export default Summary;
