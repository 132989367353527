/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ButtonLoading from '../../../../common/Button/Button';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MikeIconWhite, PlayIcon, SendVideoIcon } from '../../../../assets/images';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import { ReactComponent as FolderIcon } from '../../../../assets/images/common/icons-folder.svg';
import UploadImage from '../../../../assets/images/common/upload.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as AudioIconInactive } from '../../../../assets/images/send_thanks/audio-icon-inactive.svg';
import { ReactComponent as AudioIcon } from '../../../../assets/images/send_thanks/ant-design_audio-outlined.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/images/send_thanks/record_video.svg';
import RecordVideoIcon from '../../../../assets/images/send_thanks/record_video.png';
import { ReactComponent as VideoIcon } from '../../../../assets/images/send_thanks/ri_video-upload-line.svg';
import { ReactComponent as VideoIconActive } from '../../../../assets/images/send_thanks/ri_folder-upload-line.svg';
import Header from '../../../../common/Header/Header';
import RecordAudio from '../../../../common/RecordAudio/RecordAudio';
import RecordVideo from '../../../../common/RecordVideo/RecordVideo';
import SideBar from '../../../../common/Sidebar/Sidebar';
import dashboardService from '../../../../services/DashboardService';
import http from '../../../../services/HttpService';
import { resetSendThanksDetails, sendThanksDetails } from '../../../../store/reducers/individual/sendThanks/sendThanks';
import * as RegexPatterns from '../../../../utils/RegexPatterns';
import sendThanksSchema, * as SendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import RecipientsForm from '../../../common/RecipientsForm';
import './AnonRecipientInformation.scss';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';
import { productDetailsDispatch } from '../../../../store/reducers/multiProduct/anonMultiProducts';
import AnonymousLayout from '../../../../common/component/anonymouslayout/AnonymousLayout';
import { ReactComponent as Step1 } from '../../../../assets/images/dashboard/icons/step-2.svg';
import RecipientsInput from '../../../common/RecipientsInput';
import BulkSendingIcon from '../../../../assets/images/dashboard/icons/bulksending.svg';
import SingleAccoutIcon from '../../../../assets/images/dashboard/icons/singleaccount.svg';
import TeamAccountIcon from '../../../../assets/images/dashboard/icons/teamaccount.svg';
import CooperateAccountIcon from '../../../../assets/images/dashboard/icons/cooperatebranding.svg';
import VoiceRecordIcon from '../../../../assets/images/dashboard/icons/videorecord.svg';
import AudioMessagingIcon from '../../../../assets/images/dashboard/icons/voicerecord.svg';
import FreeAccountIcon from '../../../../assets/images/dashboard/icons/freeaccount.svg';
import UnlimitedAccountIcon from '../../../../assets/images/dashboard/icons/unlimitedteamsaccout.svg';

const signupBenefits = [
  {
    title: 'Bulk Sending',
    icon: BulkSendingIcon,
  },
  {
    title: 'Single or Corperate Account',
    icon: SingleAccoutIcon,
  },
  {
    title: 'Team Account Management',
    icon: TeamAccountIcon,
  },
  {
    title: 'Corporate Branding',
    icon: CooperateAccountIcon,
  },
  {
    title: 'Video Record or Upload',
    icon: VoiceRecordIcon,
  },
  {
    title: 'Audio or Text Messaging',
    icon: AudioMessagingIcon,
  },
  {
    title: 'Free Account',
    icon: FreeAccountIcon,
  },
  {
    title: 'Unlimited Teams Accounts',
    icon: UnlimitedAccountIcon,
  },
];

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

const initialValues = {
  messageValue: null,
};

const validate = values => {
  let errors = {};

  if (values?.messageValue?.length > 100) {
    errors.messageValue = 'Characters length should not exceed 100';
  }

  return errors;
};

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RecipentInformationPage = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingUploadBtn, setLoadingUploadBtn] = useState(false);
  const [tags, setTags] = useState([]);

  const [showMessageBox, setshowMessageBox] = useState(false);

  const [messageValue, setMessageValue] = useState('');

  const [nextPathValue, setNextPathValue] = useState();

  const [audioModel, setAudioModel] = useState(false);

  const [videoModel, setVideoModel] = useState(false);

  const [messageType, setMessageType] = useState(1);

  const audioModalRef = useRef(null);

  const videoModalRef = useRef(null);

  const [availableBalance, setAvailableBalance] = useState();

  const [audioData, setAudioData] = useState();

  const [videoData, setVideoData] = useState();

  const [existingMediaData, setExistingMediaData] = useState();

  const [getUserData, setGetUserData] = useState([]);

  let UserDataCombined = [];

  const [fileName, setFileName] = useState(null);

  const [videoFile, setVideoFile] = useState(null);

  const [thumpFile, setThumpFile] = useState(null);

  const [thumpFileName, setThumpFileName] = useState(null);

  const [fileData, setFileData] = useState(null);

  const [audioFile, setAudioFile] = useState(null);

  const [mediaModal, setShowMediaModal] = useState(false);

  const showMediaModalHandler = () => setShowMediaModal(true);
  const hideMediaModalHandler = () => setShowMediaModal(false);

  const [uploadModal, setShowUploadModal] = useState(false);

  const showUploadModalHandler = () => setShowUploadModal(true);
  const hideUploadModalHandler = () => setShowUploadModal(false);

  const [showUploadVideoPlayModal, setShowUploadVideoPlayModal] = useState(false);

  const showUploadVideoPlayModalHandler = () => setShowUploadVideoPlayModal(true);
  const hideUploadVideoPlayModalHandler = () => setShowUploadVideoPlayModal(false);

  const [checkVideoIsUploaded, setCheckVideoIsUploaded] = useState(false);
  const [remainingChars, setRemainingChars] = useState(0);

  let tag, queryUser;

  let currentPath = usePathname();
  let query = useQuery();
  const { width } = useWindowDimensions();

  // Adding search - user data
  // useEffect(() => {
  //   if (currentPath === '/recipientInformation') {
  //     tag = props.searchData;
  //   }
  //   if (tag !== '' && tag !== undefined) {
  //     SearchTagSet(tag);
  //   }
  // }, [props?.searchData]);

  // useEffect(() => {
  //   let data;
  //   if (props?.history?.location?.state !== undefined) {
  //     if (
  //       props?.history?.location?.state.searchName !== null &&
  //       props?.history?.location?.state.searchName !== undefined
  //     ) {
  //       queryUser = [props?.history?.location?.state.searchName];
  //     }
  //   }
  //   if (query.get('user') !== null && query.get('user') !== undefined) {
  //     queryUser = [[query.get('user')]];
  //   }
  //   if (queryUser != null && queryUser != undefined) {
  //     data = queryUser[0].map(val => {
  //       return val;
  //     });
  //   }

  //   setGetUserData(getUserData.concat(data));

  //   if (data != null && data != undefined) {
  //     UserDataCombined = [...tags, data[0]];
  //   }

  //   var result = [];
  //   result = UserDataCombined.filter(function (item, pos, self) {
  //     return self.indexOf(item) == pos;
  //   });

  //   var items = result.map(item => item);

  //   if (items != null && items !== undefined && items !== '') {
  //     setTags(
  //       items.map(val => {
  //         if (val !== undefined) {
  //           return val;
  //         }
  //       })
  //     );
  //   }
  // }, [props?.history?.location?.state]);

  useEffect(() => {
    // if (props?.history?.location) {
    //   setNextPathValue(props?.history?.location?.state?.nextPath);
    // }
    // tag = props?.history?.location?.state?.user;
    loadExistingRecipientData();
    // getDashboardData();
  }, []);

  const messageButtonHandler = () => {
    setshowMessageBox(!showMessageBox);
    setMessageType(1);
  };

  // Text message handler

  const textMessageCancelHandler = () => {
    setMessageType(1);

    setExistingMediaData(undefined);

    setshowMessageBox(!showMessageBox);

    formik.setFieldValue('messageValue', undefined);

    props.recipientInformation(SendThanksSchema.sendThanksData);
  };

  useEffect(() => {
    window.onbeforeunload = function (event) {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // Adding recipient tag
  const SearchTagSet = tag => {
    if (tag !== undefined) {
      if (!tag.match(RegexPatterns.AusMobileRegexValue)) {
        toast.error('Please enter a valid mobile number');
        return;
      } else {
        if (RegexPatterns.EmailRegexValue.test(tag) === false && typeof tag === 'string') {
          setTags([tag]);
        } else if (!tag.match(RegexPatterns.EmailRegexValue)) {
          toast.error('Please enter a valid email');
          return;
        }
      }
      setTags([tag]);
    }
  };

  const proceedBtnHandler = () => {
    toast.dismiss();
    if (tags.length > 0) {
      let data = [];
      let recipient = SendThanksSchema.recipientData;

      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';
          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';
          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';
          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      if (nextPathValue !== undefined && nextPathValue !== '') {
        const paymentInformation = props.getRecipientInformation;
        let product = JSON.parse(sessionStorage.getItem('productDetails'));

        props.history.push({
          pathname: nextPathValue,
          state: {
            choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
            productDetails: SendThanksSchema.sendThanksData,
            productType: product.productType,
            priceId: product.priceId,
            amount: paymentInformation.amount,
          },
        });
        props.recipientInformation(SendThanksSchema.sendThanksData);
      } else {
        props.recipientInformation(SendThanksSchema.sendThanksData);
        // props.history.push('/selectItem');
        history.push('/anonpayment');
      }
      const userMessage = {
        type: 'Text',
        message: formik.values.messageValue,
        storedFile: null,
        storedFileSecret: null,
      };
      dispatch(
        productDetailsDispatch({
          recipients: data,
          message: userMessage,
        })
      );
    } else if (tags.length === 0) {
      toast.error('Please add recipient');
    }
  };

  const cancelBtnHandler = e => {
    // e.preventDefault();
    // props.resetData();
    // props.history.push({
    //   pathname: '/dashboard',
    // });
    history.push('/anonymousselectitem');
  };

  // Load existing data
  const loadExistingRecipientData = () => {
    if (props.getRecipientInformation) {
      let recipients = props.getRecipientInformation.recipients;
      let message = props.getRecipientInformation;
      if (recipients?.length > 0) {
        let data = [];
        recipients?.forEach((recipient, index) => {
          if (
            recipient?.phone !== '' &&
            recipient?.phone !== null &&
            recipient?.phone.match(RegexPatterns.MobileRegexValue)
          ) {
            data.push(recipient.phone);
          } else if (
            recipient?.email !== '' &&
            recipient?.email !== null &&
            recipient?.email.match(RegexPatterns.EmailRegexValue)
          ) {
            data.push(recipient.email);
          } else if (
            recipient?.userName !== '' &&
            recipient?.userName !== null &&
            typeof recipient?.userName === 'string'
          ) {
            data.push(recipient.userName);
          }

          setTags(data);
        });

        if (message.message !== '' && message.message !== undefined) {
          if (message.message.message !== null) {
            formik.setFieldValue('messageValue', message.message.message);
            setshowMessageBox(true);
          } else if (message.message.message === null && message.message.storedFile !== null) {
            setshowMessageBox(false);
            getMediaData(message.message.storedFile);
          }
        }
      } else {
        formik.resetForm();
      }
    } else {
      formik.resetForm();
      formik.setFieldValue('messageValue', undefined);
    }
  };

  const toggleAudioModal = useCallback(event => {
    setAudioModel(!audioModel);

    if (audioModel === false) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {})
        .catch(err => {
          toast.error('Please enable microphone access');
        });
    }
  });

  const toggleUploadModal = () => {
    setMessageType(4);
    showUploadModalHandler();
  };

  const selectAudio = useCallback(() => {
    setshowMessageBox(true);
    setAudioModel(false);
  });

  const playAudio = () => {
    new Promise((resolve, reject) => {
      setAudioModel(true);
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      if (audioModalRef.current.recordState === 'stopped') {
        audioModalRef.current.selectAudioPlayer();
      }
    });
  };

  const selectVideo = useCallback(() => {
    setshowMessageBox(true);
    setVideoModel(false);
  });

  const playVideo = () => {
    new Promise((resolve, reject) => {
      setVideoModel(true);
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      if (videoModalRef.current.recordState === 'stopped') {
        videoModalRef.current.selectVideoPlayer();
      }
    });
  };

  const toggleVideoModal = useCallback(event => {
    setVideoModel(!videoModel);

    if (videoModel === false) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {})
        .catch(err => {
          toast.error('Please enable microphone and camera access');
        });
    }
  });

  const getDashboardData = () => {
    dashboardService
      .getDashboardData()
      .then(response => {
        if (response) {
          let data = response['data'];
          setAvailableBalance(data?.thanksAvailable);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const audioResponse = data => {
    setMessageType(3);
    formik.setFieldValue('messageValue', null);
    setVideoData(undefined);
    if (data !== undefined && data !== null) {
      setVideoData(undefined);
      setAudioData(data);
    }
  };

  const videoResponse = data => {
    setMessageType(2);
    formik.setFieldValue('messageValue', null);
    setAudioData(undefined);
    if (data !== undefined && data !== null) {
      setVideoData(data);
      setAudioData(undefined);
    }
  };

  // Getting Media
  const getMediaData = id => {
    http
      .get(`/StoredFiles/${id}`)
      .then(response => {
        if (response) {
          let data = response['data'];
          if (data?.fileCategory === 'Audio') {
            setExistingMediaData(data);
            setAudioData(data);
          } else if (data?.fileCategory === 'Video') {
            setExistingMediaData(data);
            setVideoData(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  // Resetting existing audio data
  const closeAudioFile = () => {
    sendThanksSchema.message.type = null;
    sendThanksSchema.message.storedFile = null;
    sendThanksSchema.message.storedFileSecret = null;
    sendThanksSchema.message.message = null;
    setMessageType(1);
    setExistingMediaData(undefined);
    setAudioData(undefined);
    setVideoData(undefined);
    formik.setFieldValue('messageValue', undefined);
    formik.values.messageValue = null;
    formik.resetForm();
  };

  // Resetting existing video data
  const closeVideoFile = () => {
    sendThanksSchema.message.type = null;
    sendThanksSchema.message.storedFile = null;
    sendThanksSchema.message.storedFileSecret = null;
    sendThanksSchema.message.message = null;
    setMessageType(1);
    setExistingMediaData(undefined);
    setAudioData(undefined);
    setVideoData(undefined);
    formik.setFieldValue('messageValue', undefined);
    formik.values.messageValue = null;
    formik.resetForm();
  };

  const onSubmit = values => {
    if (
      (tags.length > 0 && formik.values.messageValue !== undefined && formik.values.messageValue !== '') ||
      audioData !== undefined ||
      videoData !== undefined
    ) {
      setMessageValue(formik.values.messageValue);
      let data = [];
      let recipient = SendThanksSchema.recipientData;
      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';

          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';

          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';

          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      if (showMessageBox === true || audioData !== undefined || videoData !== undefined) {
        if (nextPathValue !== undefined && nextPathValue !== '') {
          const paymentInformation = props.getRecipientInformation;
          let product = JSON.parse(sessionStorage.getItem('productDetails'));

          props.history.push({
            pathname: nextPathValue,
            state: {
              choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
              productDetails: SendThanksSchema.sendThanksData,
              productType: product.productType,
              priceId: product.priceId,
              amount: paymentInformation.amount,
            },
          });
          props.recipientInformation(SendThanksSchema.sendThanksData);
        } else {
          props.recipientInformation(SendThanksSchema.sendThanksData);
          // props.history.push('/selectItem');
          history.push('/anonpayment');
        }
        dispatch(
          productDetailsDispatch({
            recipients: data,
            // message: SendThanksSchema.sendThanksData['message'],
            message: 'test11111',
          })
        );
      }
    } else if (
      (tags?.length > 0 && showMessageBox === false) ||
      (formik.values.messageValue === '' && audioData === undefined && videoData === undefined)
    ) {
      setMessageValue(formik.values.messageValue);
      let data = [];
      let recipient = SendThanksSchema.recipientData;
      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';

          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';

          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';

          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      props.recipientInformation(SendThanksSchema.sendThanksData);

      if (nextPathValue !== undefined && nextPathValue !== '') {
        const paymentInformation = props.getRecipientInformation;
        let product = JSON.parse(sessionStorage.getItem('productDetails'));

        props.history.push({
          pathname: nextPathValue,
          state: {
            choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
            productDetails: SendThanksSchema.sendThanksData,
            productType: product.productType,
            priceId: product.priceId,
            amount: paymentInformation.amount,
          },
        });
        props.recipientInformation(SendThanksSchema.sendThanksData);
      } else {
        props.recipientInformation(SendThanksSchema.sendThanksData);
        // props.history.push('/selectItem');
        history.push('/anonpayment');
      }
    } else if (tags.length === 0) {
      toast.error('Please add recipient');
    }
  };

  const loadVideo = file =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject('Invalid video. Please select a video file.');
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  // Getting cover image for uploaded video
  const getVideoCover = (file, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', ex => {
        reject('error when loading video file', ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject('video is too short.');
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas');
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            'image/jpeg',
            0.95 /* quality */
          );
        });
      });
    });
  };

  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.name = fileName;
    theBlob.lastModifiedDate = Date.now();

    return theBlob;
  };

  // Getting uploaded media
  const getusersListFile = async e => {
    let fileName = e.currentTarget.files[0].name;
    var allowedExtensions = /(\.mov|\.mp4|\.mp3|\.AVI|\.WEBM|\.WAV|\.AVC)$/i;
    if (!allowedExtensions.exec(fileName)) {
      toast.error('Please upload file having extensions .mov/.mp4/.mp3/.AVI/.WEBM/.WAV only.');
      return false;
    } else {
      let file = e.currentTarget.files[0];
      const video = await loadVideo(file);
      let duration = video.duration;
      if (duration <= 15) {
        var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV|\.AVC)$/i;
        if (!allowedExtensionsForVideo.exec(fileName)) {
          let userData = JSON.parse(localStorage.getItem('userData'));
          let audioFileName = `${Date.now()}_${userData.userName}_audio.mp3`;
          setAudioFile(file);
          setFileName(audioFileName);
          setLoadingUploadBtn(false);
        } else {
          const cover = await getVideoCover(file, 1.5);
          // print out the result image blob
          let userData = JSON.parse(localStorage.getItem('userData'));
          let videoFileName = `${Date.now()}_${userData.userName}_video.mpeg`;
          let thumpFileName = `${Date.now()}_${userData.userName}_image.jpg`;
          var myThumpFile = blobToFile(cover, thumpFileName);
          setThumpFile(myThumpFile);
          setVideoFile(file);
          setThumpFileName(thumpFileName);
          setFileName(videoFileName);
          setLoadingUploadBtn(false);
        }
      } else {
        toast.error('Please upload a 15 sec duration video');
      }
    }
  };

  // Uploading media data
  const uploadBtnHandler = () => {
    if (fileName !== null) {
      setLoadingUploadBtn(true);
      var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV|\.mpeg|\.AVC)$/i;
      if (!allowedExtensionsForVideo.exec(fileName)) {
        var formData = new FormData();
        formData.append('fileName', fileName);
        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myAudioFile = new File([audioFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myAudioFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                let data = response['data'];
                setLoadingUploadBtn(false);
                setAudioData(data);
                hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setVideoData(undefined);
              })
              .catch(error => {
                if (error) {
                  setLoadingUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setLoadingUploadBtn(false);
              console.log('error ', error);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      } else {
        var formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('imagePreviewFile', thumpFile, thumpFileName);

        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myVideoFile = new File([videoFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myVideoFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                setLoadingUploadBtn(false);
                let data = response['data'];
                setVideoData(data);
                hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setAudioData(undefined);
              })
              .catch(error => {
                if (error) {
                  setLoadingUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setLoadingUploadBtn(false);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      }
    } else {
      toast.error('Please upload a file');
    }
  };

  const playUploadedVideo = () => {
    showUploadVideoPlayModalHandler();
  };

  // Removing uploaded video
  const uploadedVideoCloseBtnHandler = () => {
    setCheckVideoIsUploaded(false);
    setVideoData(undefined);
    setThumpFile(null);
    setVideoFile(null);
    setThumpFileName(null);
    setFileName(null);
    setAudioData(undefined);
    setFileData(undefined);
    setExistingMediaData(undefined);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });
  const errorToSignup = () => {
    toast.error('Create an account to access these features');
  };
  useEffect(() => {
    if (formik?.values?.messageValue) {
      setRemainingChars(formik?.values?.messageValue?.length);
    }
  }, [formik?.values?.messageValue]);
  return (
    <AnonymousLayout>
      <div className="text-center step-main">
        <Step1 className="step" />
      </div>
      <div className=" m-0 p-0 row ">
        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12 mb-4 d-flex flex-column right_half h-100vh">
          <div className="p-0 m-0 row my-lg-0">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-10">
              <div className="border-0  border-radius-5">
                <div className="card-body">
                  <div className="color-black-pearl font-36 varela-round recipient-text">Enter recipient info</div>
                  <div className="mt-3 justify-content-between anon-main-card">
                    <div className="col-lg-5 px-0">
                      {/* <RecipientsForm tags={tags} onChange={setTags}></RecipientsForm> */}

                      <div className="mb-2">
                        <RecipientsForm tags={tags} onChange={setTags} type={'Anonymous'}></RecipientsForm>
                      </div>
                      <div class="mb-3">
                        <form onSubmit={formik.handleSubmit} id="form-action">
                          <label
                            for="exampleFormControlTextarea1"
                            class="form-label monteserrat-normal font-16 light-grey"
                          >
                            Message
                          </label>
                          <textarea
                            className="form-control border-radius-24 border-none  textarea-height-message"
                            cols="10"
                            rows="5"
                            name="messageValue"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.messageValue}
                            placeholder="Enter your message"
                          ></textarea>
                          <div className="anon-remaining-chracters color-primary monteserrat-normal">
                            {remainingChars}/100
                          </div>
                        </form>
                      </div>

                      <div className="w-100 p-0 m-0 mt-5 align-items-center btn-next1 mt-5">
                        <div className="mb-2 hide-on-mobile">
                          <button
                            className="p-0 m-0 w-140 btn bg-white h-48 mr-4 border-0 font-16 color-gunmetal font-sofia-pro-medium outline-none"
                            onClick={cancelBtnHandler}
                          >
                            Back
                          </button>
                        </div>
                        <div className="mb-2">
                          <button
                            type="submit"
                            className="w-140 btn bg-primary-color color-white h-48 font-sofia-pro-semi-bold font-16 outline-none"
                            onClick={proceedBtnHandler}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                      {/* <div className="mt-2">
                      <span className="font-16 color-black-pearl font-sofia-pro-regular">Message</span>
                    </div> */}
                      <div className="mb-2 hide-on-big">
                        <button
                          className="p-0 m-0 w-140 btn bg-white h-48 mr-4 border-0 font-16 color-gunmetal font-sofia-pro-medium outline-none"
                          onClick={cancelBtnHandler}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    {checkVideoIsUploaded === false && (
                      <>
                        <div className="col-lg-5 right-ads-cont">
                          <div className="recipient-botton-cont">
                            <div className="font-18 line-hight-24 varela-round recipient-left-create">
                              <span className="color-primary">Appreciate customers fast!</span> Appreci helps you easily
                              improve customer retention and loyalty. A full account offers amazing tools and features.
                            </div>
                            <div className="p-0 m-0 mt-3 row justify-content-between">
                              <div className="bg-recipient- py-3">
                                {signupBenefits.map((data, index) => {
                                  return (
                                    <div key={index} className="d-flex  py-2 align-items-center">
                                      <div>
                                        <img src={data.icon} alt="Icons" height="32px" width="32px" />
                                      </div>
                                      <div className="font-16 color-charcoal  monteserrat-normal pl-2">
                                        {' '}
                                        {data.title}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {checkVideoIsUploaded === true && (
                      <div className="d-flex flex-column">
                        {fileData?.fileCategory === 'Video' && (
                          <div>
                            <Close className="audio-close-icon cursor-pointer" onClick={uploadedVideoCloseBtnHandler} />
                            <div
                              className="video-list"
                              style={{
                                backgroundImage: `url(${fileData ? fileData.imagePreviewPath + '?h=120&w=120' : ''})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                              }}
                            >
                              <SendVideoIcon className="mike" />
                              <PlayIcon className="play cursor-pointer" onClick={playUploadedVideo} />
                            </div>
                          </div>
                        )}
                        {fileData?.fileCategory === 'Audio' && (
                          <div>
                            <Close
                              className="audio-close-icon cursor-pointer mike"
                              onClick={uploadedVideoCloseBtnHandler}
                            />
                            <div className=" d-flex video-list justify-content-center align-items-center audio-list ">
                              <PlayIcon className="play cursor-pointer" onClick={playUploadedVideo} />
                            </div>
                          </div>
                        )}
                        <div className="d-flex justify-content-end">
                          <button
                            className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                            onClick={uploadedVideoCloseBtnHandler}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none shadow-none"
                            onClick={onSubmit}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    )}

                    {existingMediaData !== undefined && (
                      <div>
                        {existingMediaData?.fileCategory === 'Audio' && (
                          <div>
                            <Close className="audio-close-icon" onClick={closeAudioFile} />
                            <div className="audio-list">
                              <MikeIconWhite className="mike" />
                              <PlayIcon className="play" onClick={showMediaModalHandler} />
                            </div>
                          </div>
                        )}
                        {existingMediaData?.fileCategory === 'Video' && (
                          <div>
                            <Close className="audio-close-icon" onClick={closeVideoFile} />
                            <div className="video-list">
                              <SendVideoIcon className="mike" />
                              <PlayIcon className="play" onClick={showMediaModalHandler} />
                            </div>
                          </div>
                        )}
                        <div className="p-0 m-0 mt-5 d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <VideoIconActive onClick={toggleVideoModal} />
                            <AudioIconInactive className="ml-2" onClick={toggleAudioModal} />
                            <img
                              src={UploadImage}
                              className="p-1 ml-2 d-flex justify-content-center align-items-center message-icon1 bg-alice-blue border-radius-5"
                              onClick={toggleUploadModal}
                              height="35px"
                              width="35px"
                            />
                          </div>
                          <div className="d-flex ">
                            <button
                              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                              onClick={textMessageCancelHandler}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none shadow-none"
                              onClick={onSubmit}
                            >
                              Proceed
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    <Modal
                      className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
                      show={mediaModal}
                      onHide={hideMediaModalHandler}
                      animation={false}
                    >
                      <Modal.Header className="p-0 m-0 border-0">
                        <button
                          type="button"
                          className="close switch-to-account-modal__close__icon"
                          onClick={hideMediaModalHandler}
                        >
                          <Close />
                        </button>
                      </Modal.Header>
                      <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                        {existingMediaData?.fileCategory === 'Audio' && (
                          <audio controls preload="none">
                            <source src={existingMediaData?.url} type="audio/mpeg" />
                          </audio>
                        )}

                        {existingMediaData?.fileCategory === 'Video' && (
                          <video width="320" height="240" controls>
                            <source src={existingMediaData?.url} type="video/mp4" />
                          </video>
                        )}
                      </Modal.Body>
                    </Modal>

                    <Modal
                      className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
                      show={uploadModal}
                      onHide={hideUploadModalHandler}
                      animation={false}
                    >
                      <Modal.Header className="p-0 m-0 border-0">
                        <button
                          type="button"
                          className="close switch-to-account-modal__close__icon"
                          onClick={hideUploadModalHandler}
                        >
                          <Close />
                        </button>
                      </Modal.Header>
                      <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                        <div className="mt-2">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label className="p-0 m-0 font-sofia-pro-regular color-charcoal font-16">
                                Browse file
                              </label>
                            </div>
                          </div>
                          <div className="p-0 m-0 card w-100 border-0">
                            <label htmlFor="file-input">
                              <div className="invite__employee-upload-style">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  <FolderIcon />
                                  {fileName === null && (
                                    <div>
                                      <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                        <span className="font-16 font-sofia-pro-regular color-primary">
                                          <u>Browse file </u>
                                        </span>
                                      </p>
                                      <span className="font-14 font-sofia-pro-regular color-grey-blue">
                                        Supports MP4, MP3, MOV, AVI, WEBM, WAV, MPEG, AVC
                                      </span>
                                    </div>
                                  )}
                                  {fileName !== null && (
                                    <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                      <u>{fileName}</u>
                                    </p>
                                  )}
                                </div>
                                <input
                                  id="file-input"
                                  type="file"
                                  accept="audio/*,video/*"
                                  onChange={getusersListFile}
                                  onClick={event => {
                                    event.target.value = null;
                                  }}
                                />
                              </div>
                            </label>
                            <div className="d-flex justify-content-end">
                              <button
                                className="p-0 m-0 mr-4 border-0 bg-transparent font-14 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                                onClick={hideUploadModalHandler}
                              >
                                Cancel
                              </button>
                              <ButtonLoading
                                children={'Upload'}
                                showLoader={loadingUploadBtn}
                                onClick={uploadBtnHandler}
                                classNameValue="w-150 btn bg-primary-color color-white h-40 font-sofia-pro-semi-bold font-14 outline-none shadow-none justify-content-around align-items-center d-flex"
                              />
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

                    <Modal
                      className="border-0 modal-background-shadow switch-to-account-modal"
                      show={showUploadVideoPlayModal}
                      onHide={hideUploadVideoPlayModalHandler}
                      animation={false}
                    >
                      <Modal.Header className="p-0 m-0 py-2 border-0">
                        <button
                          type="button"
                          className="close switch-to-account-modal__close__icon"
                          onClick={hideUploadVideoPlayModalHandler}
                        >
                          <Close />
                        </button>
                      </Modal.Header>
                      <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                        {fileData?.fileCategory === 'Video' && (
                          <video width="320" height="240" controls>
                            <source src={fileData?.url} type="video/mp4" />
                          </video>
                        )}

                        {fileData?.fileCategory === 'Audio' && (
                          <audio controls preload="none">
                            <source src={fileData?.url} type="audio/mpeg" />
                          </audio>
                        )}
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordAudio
          ref={audioModalRef}
          selectAudio={selectAudio}
          audioModel={audioModel}
          toggleAudioModal={toggleAudioModal}
          toggleVideoModal={toggleVideoModal}
          audioResponse={audioResponse}
        />
        <RecordVideo
          ref={videoModalRef}
          selectVideo={selectVideo}
          videoModel={videoModel}
          toggleAudioModal={toggleAudioModal}
          toggleVideoModal={toggleVideoModal}
          videoResponse={videoResponse}
        />
      </div>
    </AnonymousLayout>
  );
};

// MAPPING - SEND THANKS DATA

const mapStateToProps = state => {
  return {
    getRecipientInformation: state.sendThanks.sendThanksData,
    searchData: state.header.searchData,
  };
};

// DISPATCH  - SEND THANKS DATA

const mapDispatchToProps = dispatch => {
  return {
    recipientInformation: data => dispatch(sendThanksDetails(data)),
    resetData: () => dispatch(resetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipentInformationPage);
