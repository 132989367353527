/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Router, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loadStripe } from '@stripe/stripe-js';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Elements, useStripe, useElements, ElementsConsumer } from '@stripe/react-stripe-js';
import OrganisationHeader from '../../organisationheader/organisationHeader';
import Button from '../../../../common/Button/Button';
import OrganisationSidebar from '../../organisationsidebar/organisationsidebar';
import httpService from '../../../../services/HttpService';
import PaymentService from '../../../../services/PaymentService';
import { orgSendThanksDetails, orgResetSendThanksDetails } from '../../../../store/reducers/organisation/sendThanks';
import sendThanksSchema, * as SendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import 'react-toastify/dist/ReactToastify.css';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import IconMiscCard from '../../../../assets/images/common/icons-misc-card.png';
import GoolePay from '../../../../assets/images/common/google-pay-mark-800.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

function Wrapper(props) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const PaymentType = sessionStorage.getItem('PaymentType');
  const [cardDetails, setCardDetails] = useState();
  const [gpayToken, setGpayToken] = useState();
  const [cardId, setCardID] = useState(null);
  const [showGooglePayLoader, setShowGooglePayLoader] = useState(false);

  const [showSendThanksLoader, setShowSendThanksLoader] = useState(false);

  let reviewData = {};

  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  const allowedCardNetworks = ['MASTERCARD', 'VISA'];

  const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

  const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'stripe',
      'stripe:version': 'v3',
      'stripe:publishableKey': process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },
  };

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
    },
  };

  const cardPaymentMethod = Object.assign(
    {},
    { tokenizationSpecification: tokenizationSpecification },
    baseCardPaymentMethod
  );

  let paymentsClient = null;
  function getGoogleIsReadyToPayRequest() {
    return Object.assign({}, baseRequest, {
      allowedPaymentMethods: [baseCardPaymentMethod],
    });
  }

  function getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = {
      merchantName: 'Appreci Pty Ltd',
    };
    return paymentDataRequest;
  }

  function getGooglePaymentsClient() {
    if (paymentsClient === null) {
      // eslint-disable-next-line no-undef
      paymentsClient = new google.payments.api.PaymentsClient({
        environment: process.env.REACT_APP_MERCHANT_ENVIRONMENT,
      });
    }
    return paymentsClient;
  }

  function addGooglePayButton() {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({
      onClick: onGooglePaymentButtonClicked,
      buttonSizeMode: 'fill',
    });
    document.getElementById('container').appendChild(button);
  }

  function getGoogleTransactionInfo() {
    return {
      countryCode: 'AU',
      currencyCode: 'AUD',
      totalPriceStatus: 'FINAL',
      totalPrice: Amount,
    };
  }

  function prefetchGooglePaymentData() {
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode: 'USD',
    };
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.prefetchPaymentData(paymentDataRequest);
  }

  function onGooglePaymentButtonClicked() {
    setShowGooglePayLoader(true);
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .loadPaymentData(paymentDataRequest)

      .then(res => {
        let data = JSON.parse(res.paymentMethodData.tokenizationData.token);
        setGpayToken(data.id);
        setShowGooglePayLoader(false);
        sessionStorage.setItem('PaymentType', 'Card');
      })
      .catch(function (err) {
        setShowGooglePayLoader(false);
        console.error(err);
      });
  }

  const [productPrice, setProductPrice] = useState();

  const [productDetailValues, setProductDetailValues] = useState();

  useEffect(() => {
    getCardsList();

    setProductDetailValues(JSON.parse(sessionStorage.getItem('productDetails')));
  }, [productPrice]);

  const getCardsList = () => {
    PaymentService.getListOfCards(true).then(response => {
      if (response.status === 200) {
        let cardId = SendThanksSchema.sendThanksData.paymentSource.cardid;
        setCardID(cardId);
        let data = response['data'];

        data.forEach((card, index) => {
          if (card.id === cardId) {
            reviewData['cardLastNumber'] = card?.last4;
            reviewData['brand'] = card?.brand;
            reviewData['cardExpiryDate'] = card?.expiresOn;

            let data = {
              cardLastNumber: card?.last4,
              brand: card?.brand,
              cardExpiryDate: card?.expiresOn,
            };

            setCardDetails(data);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (gpayToken != undefined) {
      gpayButton();
    }
  }, [gpayToken]);
  const Amount = sessionStorage.getItem('Amount');
  const gpayButton = () => {
    let data = {
      paymentSource: {
        token: gpayToken,
        cardId: null,
      },
      amount: Amount,
    };
    httpService
      .post('companies/current/credits', data)
      .then(res => {
        history.push({
          pathname: '/topup/success',
        });
      })
      .catch(err => {
        toast.error('Something went wrong');
      });
  };

  const topUpBtnHandler = () => {
    setShowSendThanksLoader(true);
    let data = {
      paymentSource: {
        cardId: cardId,
      },
      amount: Amount,
    };

    if (PaymentType === 'Card' && cardId !== null && cardId !== undefined) {
      httpService
        .post('companies/current/credits', data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            history.push('/topup/success');
            let secretId = response['data'].intentSecret;
            let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
            handlePayment(secretId, paymentMethod, props);
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
          }
          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const handlePayment = async (intentSecret, paymentMethod, props) => {
    const paymentIntent = await stripe.confirmCardPayment(intentSecret, {
      payment_method: paymentMethod,
    });

    let data = paymentIntent.paymentIntent;

    if (data?.status === 'succeeded') {
      // SendThanksService.purchaseProduct(sendThanksSchema.sendThanksData).then((response) => {
      //         if (response.status === 200) {

      //         }
      //     }).catch((error) => {
      //         if(error.response){
      //         let errorResponseFieldErrors = error.response['data'].fieldErrors['recipients[1].Phone'];
      //         let errorResponseGlobalErrors = error.response['data'].globalErrors;
      //         if (errorResponseFieldErrors) {
      //             if (errorResponseFieldErrors.length > 0) {
      //                 errorResponseFieldErrors.forEach(msg => {
      //                     toast.error(msg);
      //                 });
      //             }
      //         }
      //         if (errorResponseGlobalErrors) {
      //             if (errorResponseGlobalErrors.length > 0) {
      //                 errorResponseGlobalErrors.forEach((msg) => {
      //                     toast.error(msg.message);
      //                 })
      //             }
      //         }
      //     }
      //     })
      history.push({
        pathname: '/thanksSuccess',
        state: {
          productType: productDetailValues.productType,
        },
      });
    } else {
      let errorMessage = paymentIntent?.error?.message;
      toast.error(errorMessage);
    }
  };

  const editMessageBtnHandler = () => {
    history.push({
      pathname: '/organisation/topup',
    });
  };

  const changeCardBtnHandler = () => {
    props.props?.history.push({
      pathname: '/organisation/paymentOptions',
    });
  };

  const cancelBtnHandler = () => {
    props?.props?.resetData();
    props?.props?.history.push({
      pathname: '/organisation/dashboard',
    });
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column  ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half">
        <OrganisationHeader role="admin" />
        <div className="d-flex m-2 mx-4">
          <Link to="/organisation/paymentOptions">
            <span className="p-2 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 card border-0  border-radius-5">
              <div className="card-body">
                <span className="font-32 font-sofia-pro-light color-black-pearl">Review your Topup</span>
                <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                  <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Top up details</span>
                </div>

                <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                  <span className="font-16 font-sofia-pro-light color-charcoal">
                    Credit amount
                    {/* {
                                            productDetails?.recipients !== undefined && productDetails?.recipients.length !== 0 && */}
                    <span>
                      {/* {
                                                    productDetails?.recipients[0]?.message?.type === 'Text' && */}
                      <Link
                        to={{
                          pathname: '/organisation/topup',
                          state: { quantityChange: true },
                        }}
                      >
                        <span className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer">Edit</span>
                      </Link>
                      {/* } */}
                    </span>
                    {/* } */}
                  </span>
                  <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                    ${Amount}
                  </span>
                </div>

                <div className="mt-3">
                  <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Payment method</span>
                  </div>
                  <div className="mt-1 p-2 d-flex justify-content-between align-items-center">
                    <div>
                      {PaymentType === 'Card' ? (
                        <label className="font-14 font-sofia-pro-light color-black-pearl ">
                          <span>
                            {' '}
                            {cardDetails?.brand} •••• •••• •••• {cardDetails?.cardLastNumber} |
                            <span>
                              <Moment format="MM/YY">{cardDetails?.cardExpiryDate}</Moment>
                            </span>
                          </span>
                          <img className="pl-1" src={IconMiscCard} />
                        </label>
                      ) : PaymentType === 'GooglePay' ? (
                        <label className="font-14 font-sofia-pro-light color-black-pearl ">
                          <span>Google Pay</span>
                          <img className="pl-1" src={GoolePay} />
                        </label>
                      ) : (
                        ''
                      )}
                    </div>

                    <div>
                      <Link
                        to={{
                          pathname: '/organisation/paymentOptions',
                          state: { googlepaySelect: true },
                        }}
                      >
                        <span className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer" onClick={''}>
                          Change
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Purchase summary</span>
                  </div>
                  <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
                    <span className="font-16 font-sofia-pro-light color-charcoal">Amount</span>
                    <span className="font-16 font-sofia-pro-regular color-charcoal">${Amount}</span>
                  </div>
                  {/* <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
                                        <span className="font-16 font-sofia-pro-light color-charcoal">Available balance</span>
                                        <span className="font-16 font-sofia-pro-regular color-charcoal">-$0.0</span>
                                    </div> */}
                </div>
                <div className="mt-3">
                  <div className="mt-2 p-2 d-flex justify-content-between align-items-center bg-lavender-blush-1 border-radius-5">
                    <span className="font-18 font-sofia-pro-semi-bold color-dark-charcoal">Total spend</span>
                    <span className="font-18 font-sofia-pro-medium color-dark-charcoal">${Amount}</span>
                  </div>
                </div>
                <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
                  <button
                    className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none"
                    onClick={cancelBtnHandler}
                  >
                    Cancel
                  </button>

                  {/* <Link to="/thanksSuccess"> */}
                  {PaymentType === 'GooglePay' ? (
                    // <button type="submit" className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                    //     onClick={onGooglePaymentButtonClicked}>Send</button>
                    <Button
                      classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      children="Top up"
                      showLoader={showGooglePayLoader}
                      onClick={onGooglePaymentButtonClicked}
                    />
                  ) : PaymentType === 'Card' ? (
                    // <button type="submit" className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none" onClick={sendThanksBtnHandler}>Send</button>
                    <Button
                      classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      children="Top up"
                      showLoader={showSendThanksLoader}
                      onClick={topUpBtnHandler}
                    />
                  ) : (
                    ''
                  )}
                  {/* </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
          <div className="d-none"></div>
        </div>
      </div>
    </div>
  );
}

const OrganisationReviewThanksPage = props => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => <Wrapper elements={elements} stripe={stripe} props={props} />}
      </ElementsConsumer>
    </Elements>
  );
};

const mapStateToProps = state => {
  return {
    getPaymentInformation: state.organisationSendThanks.orgSendThanksData,
  };
};
// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    paymentInformation: data => dispatch(orgSendThanksDetails(data)),
    resetData: () => dispatch(orgResetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationReviewThanksPage);
