/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import SendThanksService from '../../../../services/SendThanksService';
import httpService from '../../../../services/HttpService';
import '../DashBoard.scss';
import './TransactionHistory.scss';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as Components2 } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-2.svg';
import { ReactComponent as SentActive } from '../../../../assets/images/dashboard/icons/icons-main-navigation-sent-active.svg';
import { ReactComponent as ProfilePic } from '../../../../assets/images/common/user-profile.svg';
import RedeemEmpty from '../../../../assets/images/dashboard/image/components-illustrations-empty-state-2.png';

const TransactionHistory = () => {
  const history = useHistory();
  const [recivedData, setRecivedData] = useState([]);
  const [sendData, setSentData] = useState([]);
  console.log("send", sendData);
  const [redeemedData, setRedeemedData] = useState([]);
  const [recivedTransaction, setRecivedTransaction] = useState(false);
  const [sentTransactions, setsentTransactions] = useState(true);
  const [redeemTransactions, setredeemTransactions] = useState(false);
  const [product, setProduct] = useState([]);
  const [sentPage, setSentPage] = useState([]);
  const [redeemPage, setRedeemPage] = useState([]);
  const [recivePage, setRecivePage] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  let [recCount, setrecCount] = useState(1);
  let [sendCount, setsendCount] = useState(1);
  let [redCount, setredCount] = useState(1);
  const [transactionId, setTransactionId] = useState();
  const [viewMessage, setViewMessage] = useState(false);
  const [messageValue, setmessageValue] = useState();
  const [userName, setUserName] = useState();
  const [profileUrl, setProfileUrl] = useState();
  const [viewUserName, setviewUserName] = useState(null);
  const [selectFile, setSelectFile] = useState(null);
  const [audioVideoUrl, setAudioVideoUrl] = useState(null);
  const [messageType, setMessageType] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  useEffect(() => {
    SendList(sendCount);
    ReciveList(recCount);
    RedeemList(redCount);
    SendServiceServiceList();
  }, []);

  useEffect(() => {
    if (selectFile != null) {
      GetStoredFile();
    }
  });

  useEffect(()=>{
    product.map(value => {
      recivedData.map((val, index) => {
        value.prices.filter(elem => {
          if (elem.priceId === val['priceId'] ){
            recivedData[index]['Type'] = value['type'];
          }
        })
      });
      sendData.map((val, index) => {
        value.prices.filter(elem => {
          if (elem.priceId === val['priceId'] ){
            sendData[index]['Type'] = value['type'];
          }
        })
      });
      redeemedData.map((val, index) => {
        value.prices.filter(elem => {
          if (elem.priceId === val['priceId'] ){
            redeemedData[index]['Type'] = value['type'];
          }
        })
      });
    });

  },[product, sendData, redeemedData, recivedData])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // navigate to send thanks with user name
  const SendClicked = () => {
    if (viewUserName !== null) {
      history.push(`/recipientInformation?user=${viewUserName}`);
    }
  };
  // get audio and video url for show message
  const GetStoredFile = () => {
    httpService.get(`StoredFiles/${selectFile}`).then(res => {
      setAudioVideoUrl(res.data['url']);
    });
  };
  // view button handler
  const ViewMessageBtn = index => {
    setViewMessage(true);
    if (sendData[index]['message'] === null) {
      setmessageValue('No message');
      setMessageType(null);
    } else {
      setmessageValue(sendData[index]['message']['message']);
      setSelectFile(sendData[index]['message']['storedFile']);
      setSelectFile(sendData[index]['message']['storedFile']);
      setMessageType(sendData[index]['message']['type']);
    }
    if (sendData[index]['user']['userName'] != null) {
      setUserName(sendData[index]['user']['fullName']);
    } else if (sendData[index]['user']['to'] != null) {
      setUserName(sendData[index]['user']['to']);
    }

    setProfileUrl(sendData[index]['user']['profilePhotoUrl']);
    if (sendData[index]['user']['userName'] != null) {
      setviewUserName(sendData[index]['user']['userName']);
    } else if (sendData[index]['user']['to'] != null && sendData[index]['user']['userName'] === null) {
      setviewUserName(sendData[index]['user']['to']);
    }
    if (selectFile !== null) {
      GetStoredFile();
    }
  };

  const ViewMessage = props => {
    return (
      <Modal
        {...props}
        centered
        animation={false}
        backdrop="static"
        style={{ overflow: 'hidden' }}
        className={
          sendData.length < 5
            ? 'transactionhistory-background_popupmessage'
            : 'transactionhistory-background_popupmessage1'
        }
      >
        <Modal.Body>
          <button className="border-0 dashboard-button__canceliconsend  " onClick={props.onHide}>
            <Close />
          </button>
          <div className="d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center m-0">
              {profileUrl === null ? (
                <ProfilePic className="transactionhistory-image__Oval " />
              ) : (
                <img
                  src={profileUrl + '?h=70&w=70'}
                  alt=""
                  className="transactionhistory-image__Oval  rounded-circle"
                />
              )}
            </div>

            <span className="font-sofia-pro-semi-bold transactionhistory-text__name d-flex justify-content-center">
              {userName}
            </span>
            <div className=" transactionhistory-card__message d-flex align-items-center justify-content-center ">
              <span className="sofia-pro-light transacationhistory-text__message text-break">
                {messageType === 'Text' ? (
                  messageValue !== null ? (
                    messageValue
                  ) : (
                    'No message'
                  )
                ) : messageType === 'Audio' ? (
                  <audio controls className="">
                    <source src={audioVideoUrl} type="audio/mp3" className="reseemthanks-audio__style" />
                  </audio>
                ) : messageType === 'Video' ? (
                  <video width="320" height="240" controls>
                    <source src={audioVideoUrl} type="video/mp4" />
                  </video>
                ) : messageType === null ? (
                  <span className="sofia-pro-light transacationhistory-text__message text-break">No message</span>
                ) : (
                  <span className="sofia-pro-light transacationhistory-text__message text-break">No message</span>
                )}
              </span>
            </div>
            <div className="d-flex justify-content-center transactionhistory-button__sendagain">
              <button className="bg-transparent border-0 my-3" onClick={SendClicked}>
                <SentActive />
                <span className="transactionhistory-text__Send-again  font-sofia-pro-regular mx-2">Send again</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  // showing received list of data
  const ReciveList = count => {
    httpService.get(`TransactionHistories/current?type=${'GiftReceived'}&pageNumber=${count}`).then(res => {
      let data = res.data['data'];
      let recivedList = [];
      data.forEach(res => {
        if (res['status'] === 'Received') {
          recivedList.push(res);
        }
      });
      if (count > 1) {
        Array.prototype.push.apply(recivedData, res.data['data']);
      } else {
        setRecivedData(recivedList);
      }
      setRecivePage(res.data);
    });
  };
  // cancel button handler
  const DeleteData = () => {
    httpService
      .delete(`Gifts/${transactionId}`)
      .then(res => {
        toast.success('Transaction cancelled successfully');
        setModalShow(false);
        SendList(sendCount);
      })
      .catch(error => {
        setModalShow(false);
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };
  // showing sent list
  const SendList = count => {
    return httpService.get(`TransactionHistories/current?type=${'GiftSent'}&pageNumber=${count}`).then(res => {
      if (count > 1) {
        Array.prototype.push.apply(sendData, res.data['data']);
      } else {
        setSentData(res.data['data']);
      }

      setSentPage(res.data);
    });
  };
  // showing redeemed list
  const RedeemList = count => {
    httpService.get(`TransactionHistories/current?type=${'GiftRedeemed'}&pageNumber=${count}`).then(res => {
      if (count > 1) {
        Array.prototype.push.apply(redeemedData, res.data['data']);
      } else {
        setRedeemedData(res.data['data']);
      }

      setRedeemPage(res.data);
    });
  };

  const ReciveTransactionhandler = () => {
    setRecivedTransaction(true);
    setsentTransactions(false);
    setredeemTransactions(false);
  };
  const SentTransctionhandler = () => {
    setRecivedTransaction(false);
    setsentTransactions(true);
    setredeemTransactions(false);
  };
  const RedeemTransactionhandler = () => {
    setRecivedTransaction(false);
    setsentTransactions(false);
    setredeemTransactions(true);
  };

  const SendServiceServiceList = () => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        setProduct(
          res.data.map(val => {
            return val;
          })
        );
      })
      .catch(err => {});
  };

  const Send = () => {
    setsendCount((sendCount = sendCount + 1));
    SendList(sendCount);
  };

  const Recive = () => {
    setrecCount((recCount = recCount + 1));
    ReciveList(recCount);
  };

  const Redeem = () => {
    setredCount((redCount = redCount + 1));
    RedeemList(redCount);
  };

  const Delete = props => {
    return (
      <Modal
        {...props}
        centered
        animation={false}
        backdrop="static"
        style={{ overflow: 'hidden' }}
        className="transactionhistory-background_popup"
      >
        <Modal.Body>
          <button
            className="border-0 dashboard-button__canceliconsend  "
            onClick={(props.onHide, () => setModalShow(false))}
          >
            <Close />
          </button>

          <span className="font-sofia-pro-semi-bold dashboard-text__Are-you-sure-you-wan ">
            Are you sure you want to cancel this transaction?
          </span>
          <div>
            <button className="transactionhistory-button__Yes border-0 my-3 " onClick={DeleteData}>
              <span className="transactionhistory-text__Yes font-sofia-pro-semi-bold">Yes</span>
            </button>
            <button
              className="border-0 bg-transparent mx-4"
              onClick={() => {
                setModalShow(false);
              }}
            >
              <span className="font-sofia-pro-semi-bold transactionhistory-text__No-dont-cancel">No, don’t cancel</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const CancelTransaction = index => {
    setModalShow(true);
    setTransactionId(sendData[index]['giftId']);
  };

  const SentTransactions = () => {
    if (sendData.length === 0) {
      return (
        <div className="my-4">
          <div>
            <div className="d-flex align-items-center justify-content-center my-5">
              <Components2 />
            </div>
            <div className="dashboard-components1 my-5">
              <span className="dashboard__trysendingyourfir font-sofia-pro-medium my-5">
                Try sending your first gift,
                <Link style={{ textDecoration: 'none' }} to="/recipientInformation">
                  <span className="dashboard-text__style1">Try now</span>
                </Link>
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        windowWidth <= 767 ? (
          sendData?.length > 0 &&
          sendData.map((value, index) => {
            var stilUtc = Moment.utc(value['date']).toDate();
            var local = Moment(stilUtc).local();
            let date = Moment(local).format('DD/MM/YYYY');
            return value['Type'] && (
            <div className="cards-area" key={index}>
              <div className="card card-transaction">
                <div className="card-user-body">
                  <div className="user-card-headers">
                    <div className='d-flex'>
                      <div className='d-flex flex-column'>
                        <div className="font-sofia-pro-regular milestone-headings">Transaction ID</div>
                        <div className="font-sofia-pro-regular milestone-headings">Date</div>
                        <div className="font-sofia-pro-regular milestone-headings">Product</div>
                        <div className="font-sofia-pro-regular milestone-headings">Sent to</div>
                        <div className="font-sofia-pro-regular milestone-headings">Status</div>
                        <div className="font-sofia-pro-regular milestone-headings">Message</div>
                        <div className="font-sofia-pro-regular milestone-headings">Quantity</div>
                        <div className="font-sofia-pro-regular milestone-headings">Receipts</div>
                      </div>
                      <div className='d-flex flex-column align-items-start'>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '0.4rem'}}>{value['transactionId']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{date}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['Type']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>
                          {value['user'] !== null
                          ? value['user']['fullName'] === ''
                            ? value['user']['to']
                            : value['user']['fullName']
                          : null}
                        </div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>
                          {value['status']}
                          {value['status'] === 'Sent' && value['Type'] !== 'GiftCard' ? (
                            <>
                              <button
                                className="mx-2 bg-transparent transactionhistory-button__cancel"
                                onClick={CancelTransaction.bind(this, index)}
                              >
                                <span className="transactionhistory-text__Cancel font-sofia-pro-semi-bold d-flex">
                                  cancel
                                </span>
                              </button>
                              <Delete show={modalShow} onHide={() => setModalShow(false)} />
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>
                          {
                          <>
                            <button className="border-0 bg-transparent" onClick={ViewMessageBtn.bind(this, index)}>
                              <span className="font-sofia-pro-regular transactionhistory-text__View">View</span>
                            </button>
                            <ViewMessage show={viewMessage} onHide={() => setViewMessage(false)} />
                          </>
                          }
                        </div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['quantity']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>
                          {value['receiptUrl'] !== null && (
                          <a
                            className="font-sofia-pro-regular transactionhistory-text__View"
                            target="_blank"
                            href={value['receiptUrl']}
                          >
                            {' '}
                            View Receipt
                          </a>
                          )}
                          {value['receiptUrl'] === null && <span>Not Available</span>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)
          })
        ): (
        <div className="card border-0  transactionhistory-card_second my-3 mx-0 mx-lg-3 table-responsive">
          <table className="table transactionhistory-table__thead ">
            <thead style={{ color: '#101317' }}>
              <tr>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Transaction ID
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Date
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Product Name
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Sent to
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Status
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Message
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Quantity
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Receipts
                </td>
              </tr>
            </thead>
            <tbody>
              {sendData?.map((value, index) => {
                var stilUtc = Moment.utc(value['date']).toDate();
                var local = Moment(stilUtc).local();
                let date = Moment(local).format('DD/MM/YYYY');
                return value['Type'] && (
                  <tr key={index} className="transactionhistory-row_nthchild">
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular text-break">
                      {value['transactionId']}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{date}</td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['Type']}</td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {value['user'] !== null
                        ? value['user']['fullName'] === ''
                          ? value['user']['to']
                          : value['user']['fullName']
                        : null}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {value['status']}
                      {value['status'] === 'Sent' && value['Type'] !== 'GiftCard' ? (
                        <>
                          <button
                            className="mx-2 bg-transparent transactionhistory-button__cancel"
                            onClick={CancelTransaction.bind(this, index)}
                          >
                            <span className="transactionhistory-text__Cancel font-sofia-pro-semi-bold d-flex">
                              cancel
                            </span>
                          </button>
                          <Delete show={modalShow} onHide={() => setModalShow(false)} />
                        </>
                      ) : (
                        ''
                      )}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {
                        <>
                          <button className="border-0 bg-transparent" onClick={ViewMessageBtn.bind(this, index)}>
                            <span className="font-sofia-pro-regular transactionhistory-text__View">View</span>
                          </button>
                          <ViewMessage show={viewMessage} onHide={() => setViewMessage(false)} />
                        </>
                      }
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['quantity']}</td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {value['receiptUrl'] !== null && (
                        <a
                          className="font-sofia-pro-regular transactionhistory-text__View"
                          target="_blank"
                          href={value['receiptUrl']}
                        >
                          {' '}
                          View Receipt
                        </a>
                      )}
                      {value['receiptUrl'] === null && <span>Not Available</span>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        )
      );
    }
  };
  const ReceivedTransactions = () => {
    if (recivedData.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center redeem-emptystate my-5">
          <img src={RedeemEmpty} alt="Empty" className="redeem-image__emptystate" />

          <span className="redeem-text__Start-by-sending-an font-sofia-pro-regular">
            Start by sending an appreciation to your mate
          </span>
          <Link to="/recipientInformation">
            <button className="redeem-button_view my-3">
              <SentActive />
              <span className="redeem-text__view font-sofia-pro-regular ">Send</span>
            </button>
          </Link>
        </div>
      );
    } else {
      return (
        windowWidth <= 767 ? (
          recivedData?.length > 0 &&
          recivedData.map((value, index) => {
            var stilUtc = Moment.utc(value['date']).toDate();
            var local = Moment(stilUtc).local();
            return value['Type'] && (
            <div className="cards-area" key={index}>
              <div className="card card-transaction">
                <div className="card-user-body">
                  <div className="user-card-headers">
                    <div className='d-flex'>
                      <div className='d-flex flex-column'>
                        <div className="font-sofia-pro-regular milestone-headings">Transaction ID</div>
                        <div className="font-sofia-pro-regular milestone-headings">Receive date</div>
                        <div className="font-sofia-pro-regular milestone-headings">Received from</div>
                        <div className="font-sofia-pro-regular milestone-headings">Product name</div>
                        <div className="font-sofia-pro-regular milestone-headings">Expiry date</div>
                        <div className="font-sofia-pro-regular milestone-headings">Quantity</div>
                      </div>
                      <div className='d-flex flex-column align-items-start'>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '0.4rem'}}>{value['transactionId']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{local.format('DD/MM/YYYY')}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['user']['fullName']}</div>
                        {value['Type'] === 'GiftCard' ? (
                        value['redeemUrl'] !== null ? (
                          <a href={value['redeemUrl']} target="next">
                            <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['Type']}</div>
                          </a>
                        ) : (
                          <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['Type']}</div>
                        )
                        ) : (
                          <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['Type']}</div>
                        )}
                        {value['expiresOn'] != null ? (
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>
                          {Moment(value['expiresOn']).format('DD/MM/YYYY')}
                        </div>
                        ) : (
                          <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>Null</div>
                        )}
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>
                          {value['quantity']}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)
          })
        ): (
        <div className="card border-0 transactionhistory-card_second my-3 mx-0 mx-lg-3 table-responsive">
          <table className="table transactionhistory-table__thead ">
            <thead style={{ color: '#101317' }}>
              <tr>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Transaction ID
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Receive date
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Received from
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Product name
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium ">
                  Expiry date
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Quantity
                </td>
              </tr>
            </thead>
            <tbody>
              {recivedData.map((value, index) => {
                var stilUtc = Moment.utc(value['date']).toDate();
                var local = Moment(stilUtc).local();
                return (
                  <tr key={index} className="transactionhistory-row_nthchild">
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {value['transactionId']}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {local.format('DD/MM/YYYY')}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {value['user']['fullName']}
                    </td>
                    {value['Type'] === 'GiftCard' ? (
                      value['redeemUrl'] !== null ? (
                        <a href={value['redeemUrl']} target="next">
                          <td className="transactionhistory-text__Gift-card font-sofia-pro-regular">{value['Type']}</td>
                        </a>
                      ) : (
                        <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['Type']}</td>
                      )
                    ) : (
                      <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['Type']}</td>
                    )}
                    {value['expiresOn'] != null ? (
                      <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                        {Moment(value['expiresOn']).format('DD/MM/YYYY')}
                      </td>
                    ) : (
                      <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">Null</td>
                    )}

                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['quantity']}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        )
      );
    }
  };

  const Redeemedtransaction = () => {
    if (redeemedData.length === 0) {
      return (
        <div className="d-flex flex-column align-items-center redeem-emptystate my-5">
          <img src={RedeemEmpty} alt="Empty" className="redeem-image__emptystate" />

          <span className="redeem-text__Start-by-sending-an font-sofia-pro-regular">
            Start by sending an appreciation to your mate
          </span>
          <Link to="/recipientInformation">
            <button className="redeem-button_view my-3">
              <SentActive />
              <span className="redeem-text__view font-sofia-pro-regular ">Send</span>
            </button>
          </Link>
        </div>
      );
    } else {
      return (
        windowWidth <= 767 ? (
          redeemedData?.length > 0 &&
          redeemedData?.map((value, index) => {
            var stilUtc = Moment.utc(value['date']).toDate();
            var local = Moment(stilUtc).local();
            return value['Type'] && (
            <div className="cards-area" key={index}>
              <div className="card card-transaction">
                <div className="card-user-body">
                  <div className="user-card-headers">
                    <div className='d-flex'>
                      <div className='d-flex flex-column'>
                        <div className="font-sofia-pro-regular milestone-headings">Transaction ID</div>
                        <div className="font-sofia-pro-regular milestone-headings">Redeem Date</div>
                        <div className="font-sofia-pro-regular milestone-headings">Redeemed at</div>
                        <div className="font-sofia-pro-regular milestone-headings">Received from</div>
                        <div className="font-sofia-pro-regular milestone-headings">Product name</div>
                        <div className="font-sofia-pro-regular milestone-headings">Quantity</div>
                      </div>
                      <div className='d-flex flex-column align-items-start'>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '0.4rem'}}>{value['transactionId']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{local.format('DD/MM/YYYY')}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['redeemedAt']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['user']['fullName']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>{value['Type']}</div>
                        <div className="font-sofia-pro-semi-bold card-title" style={{marginBottom: '1.75rem'}}>
                          {value['quantity']}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>)
          })
        ): (
        <div className="card border-0 transactionhistory-card_second my-3 mx-0 mx-lg-3 table-responsive">
          <table className="table transactionhistory-table__thead ">
            <thead style={{ color: '#101317' }}>
              <tr>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Transaction ID
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Redeem Date
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Redeemed at
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Received from
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Product name
                </td>
                <td scope="col" className="transactionhistory-text__Transaction-ID font-sofia-pro-medium">
                  Quantity
                </td>
              </tr>
            </thead>
            <tbody>
              {redeemedData.map((value, index) => {
                var stilUtc = Moment.utc(value['date']).toDate();
                var local = Moment(stilUtc).local();
                return (
                  <tr key={index} className="transactionhistory-row_nthchild">
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {value['transactionId']}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {local.format('DD/MM/YYYY')}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['redeemedAt']}</td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">
                      {value['user']['fullName']}
                    </td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['Type']}</td>
                    <td className="transactionhistory-text__AP12345 font-sofia-pro-regular">{value['quantity']}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        )
      );
    }
  };
  return (
    <div className="p-0 m-0 row">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar />
      </div>
      <div
        className={
          sendData.length >= 4
            ? 'p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half'
            : 'p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh'
        }
      >
        <Header />
        <div className="d-flex flex-row justify-content-around w-sm-100  transactionhistory-card__first border-0 m-3 ">
          <button className="border-0 bg-transparent" onClick={SentTransctionhandler}>
            {sentTransactions === true ? (
              <div className="d-flex  flex-column">
                <span className="font-sofia-pro-medium transactionhistory-text__Redeem-Transactionsselected my-3">
                  Sent Transactions
                </span>
                <div className="transactionhistory-bar " />
              </div>
            ) : (
              <span className="font-sofia-pro-light transactionhistory-text__Redeem-Transactionsunselected">
                Sent Transactions
              </span>
            )}
          </button>
          <button className="border-0 bg-transparent" onClick={ReciveTransactionhandler}>
            {recivedTransaction === true ? (
              <div className="d-flex  flex-column">
                <span className="font-sofia-pro-medium transactionhistory-text__Redeem-Transactionsselected my-3">
                  Received Transactions
                </span>
                <div className="transactionhistory-bar " />
              </div>
            ) : (
              <span className="font-sofia-pro-light transactionhistory-text__Redeem-Transactionsunselected">
                Received Transactions
              </span>
            )}
          </button>
          <button className="border-0 bg-transparent" onClick={RedeemTransactionhandler}>
            {redeemTransactions === true ? (
              <div className="d-flex  flex-column">
                <span className="font-sofia-pro-medium transactionhistory-text__Redeem-Transactionsselected my-3">
                  Redeem Transactions
                </span>
                <div className="transactionhistory-bar " />
              </div>
            ) : (
              <span className="font-sofia-pro-light transactionhistory-text__Redeem-Transactionsunselected">
                Redeem Transactions
              </span>
            )}
          </button>
        </div>
        {recivedTransaction === true ? (
          <ReceivedTransactions />
        ) : sentTransactions === true ? (
          <SentTransactions />
        ) : redeemTransactions === true ? (
          <Redeemedtransaction />
        ) : (
          ''
        )}
        <div></div>

        <div className="">
          {sentPage['pageCount'] > 1 &&
          sentTransactions === true &&
          sentPage['currentPage'] < sentPage['pageCount'] &&
          sendData.length != 0 ? (
            <button onClick={Send} className="bg-transparent border-0 color m-3">
              <span className="transactionhistory-text__Show-more font-sofia-pro-medium">show more</span>
            </button>
          ) : (
            ''
          )}
          {recivePage['pageCount'] > 1 &&
          recivedTransaction === true &&
          recivePage['currentPage'] < recivePage['pageCount'] &&
          recivedData.length != 0 ? (
            <button onClick={Recive} className="bg-transparent border-0 m-3">
              <span className="transactionhistory-text__Show-more font-sofia-pro-medium">show more</span>
            </button>
          ) : (
            ''
          )}
          {redeemPage['pageCount'] > 1 &&
          redeemTransactions === true &&
          redeemPage['currentPage'] < redeemPage['pageCount'] &&
          redeemedData.length != 0 ? (
            <button onClick={Redeem} className="bg-transparent border-0 m-3">
              <span className="transactionhistory-text__Show-more font-sofia-pro-medium">show more</span>
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
