import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../../assets/images/logo/logo-colour.svg';
import { ReactComponent as AppreciationPic } from '../../../../../assets/images/common/components-illustrations-donation.svg';
import httpService from '../../../../../services/HttpService';
import SendThanksService from '../../../../../services/SendThanksService';
import './Anonymous.scss';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../../../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as Remove } from '../../../../../assets/images/redeem_thanks/icons/icons-remove-16-px (1).svg';
import Add from '../../../../../assets/images/redeem_thanks/icons/icons-add-pink.png';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AnonymousRedeem = () => {
  const [giftData, setGiftData] = useState(null);
  const [product, setProduct] = useState(null);

  const [quantityValue, setQuantityValue] = useState(1);
  const [quantityModalShow, setQuantityModalShow] = useState(false);

  let query = useQuery();
  const history = useHistory();
  const Code = query.get('code');
  const SendServiceServiceList = () => {
    SendThanksService.getListOfProducts('AU').then(res => {
      setProduct(
        res.data.map(val => {
          return val;
        })
      );
    });
  };

  if (product !== null && giftData !== null) {
    product.map(value => {
      value.prices.map(item => {
        if (item.priceId === giftData['priceId']) {
          giftData['type'] = value['type'];
        }
      });
      if (value?.features === 'Exchange') {
        giftData['features'] = value?.features;
        giftData['exchangePriceId'] = value?.prices[0]?.priceId;
      }
    });
  }

  const RedeemClicked = () => {
    console.log("===status===", giftData['user']['status']);
    const redeemInfo = {
      anonymous: 'anonymous',
      code: Code,
      giftEmail: giftData['user']['email'],
      giftPhone: giftData['user']['phone'],
      features: giftData?.features,
      exchangePriceId: giftData?.exchangePriceId,
      type: giftData?.type,
      transactionId: giftData.transactionId,
      from: giftData?.from,
    };
    sessionStorage.setItem('redeemInfo', JSON.stringify(redeemInfo));
    if (
      giftData['user']['status'] === 'Anonymous' ||
      giftData['user']['status'] === 'Invited' ||
      giftData['user']['status'] === 'Deleted'
    ) {
      history.push('/redeem-anonymous');
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    SendServiceServiceList();
  }, []);

  useEffect(() => {
    httpService.get(`Gifts/bycode/${Code}`).then(res => {
      setGiftData(res.data);
    });
  }, []);

  const PropsOnHide = () => {
    setQuantityModalShow(false);
    setQuantityValue(1);
  };

  const TransactionIdpass = index => {
    setQuantityModalShow(true);
  };
  const ProceedBtn = () => {
    sessionStorage.setItem('SelectedQuantity', quantityValue);
    return (
      <>
        <button className="border-0 redeem-button__proceed my-3" onClick={RedeemClicked}>
          <span className="redeem-text__proceed font-sofia-pro-semi-bold">Proceed</span>
        </button>
      </>
    );
  };

  const SelectQuantity = props => {
    return (
      <Modal {...props} size="md" centered className="redeem-background_popup" animation={false} backdrop="static">
        <Modal.Body className="d-flex flex-column align-items-center ">
          <button className="border-0 bg-transparent redeem-quantity__cancel " onClick={PropsOnHide}>
            <Close />
          </button>
          <span className="redeem-text__How-many-coffees-wo font-sofia-pro-medium">
            How many {giftData.type !== null ? giftData.type : ''} would you like to redeem?
          </span>
          <div className="row justify-content-center my-4">
            <button
              className="border-0 bg-transparent"
              onClick={() => (quantityValue > 1 ? setQuantityValue(quantityValue - 1) : '')}
            >
              <Remove />
            </button>
            <div className="redeem-box__selectedvalue d-flex justify-content-center align-items-center">
              <span>{quantityValue}</span>
            </div>
            <button
              className="border-0 bg-transparent "
              onClick={() => (quantityValue < giftData.quantity ? setQuantityValue(quantityValue + 1) : '')}
            >
              <img src={Add} />
            </button>
          </div>

          <ProceedBtn />
          <button className="border-0 bg-transparent" onClick={props.onHide}>
            <span className="redeem-text__Cancel font-sofia-pro-medium">Cancel</span>
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div className="p-0 m-0 h-100vh bg-alice-blue">
      <div className="p-3 w-100 d-flex justify-content-between bg-white">
        <Logo />
      </div>
      {giftData !== null ? (
        !giftData?.redeemedOn ? (
          <div className="p-2 row bg-white my-3 mx-3 main-title-cont">
            <span className="color-nero font-sofia-pro-semi-bold anonymous-text__youhave mx-2 align-items-center">
              <div>You have received {giftData?.type} from&nbsp;</div>
              <div> {giftData?.from}</div>
            </span>

            {giftData?.type === 'Coffee' || giftData?.type === 'Wine' ? (
              <div>
                <button
                  className="redeemwine-button__redeemnow border-0 mx-lg-2 my-lg-0 my-3 mx-2"
                  onClick={RedeemClicked}
                  disabled={!giftData?.type}
                >
                  <span className="redeemwine-text__redeemnow font-sofia-pro-semi-bold">Redeem</span>
                </button>
              </div>
            ) : (
              <div style={{ textDecoration: 'none' }} className="position-relative">
                <button
                  onClick={TransactionIdpass}
                  disabled={!giftData?.type}
                  className="redeemwine-button__redeemnow border-0 mx-lg-2 my-lg-0 my-3 mx-5"
                >
                  <span className="redeemwine-text__redeemnow font-sofia-pro-semi-bold">Redeem</span>
                </button>
                <SelectQuantity
                  show={quantityModalShow}
                  onHide={() => {
                    setQuantityModalShow(false);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="p-3 w-100 d-flex justify-content-center bg-white my-3 ">
            <span className="color-nero font-sofia-pro-semi-bold text-center anonymous-text__youhave ">
              This gift has been redeemed
            </span>
          </div>
        )
      ) : null}
      <div className="p-3 mt-3 d-flex flex-column align-items-center justify-content-center card border-0 border-radius-5 bg-white">
        <div>
          <AppreciationPic />
        </div>
        <p className="text-center font-18 font-sofia-pro-medium color-black-pearl">
          Share your appreciation in spontaneous, tangible and <br /> thoughtful ways with Appreci
        </p>
        <Link to={'/signup'}>
          <button className=" w-180 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16">
            Sign up
          </button>
        </Link>
        <p className="text-center font-14 mt-3 font-sofia-pro-light oslo-grey">
          To people who desire deeper bonds in their relationships. Appreci is for turning thoughtful moments into{' '}
          <br /> immeasurable impact. Appreci is an everyday tool for people to convert their feelings into actions.{' '}
        </p>
        <div className="mx-5 my-2 d-flex justify-content-between">
          <div>
            <span className="text-center font-14 font-sofia-pro-light color-charcoal-grey">©Appreci</span>
          </div>
          <div>
            <a
              href="https://appreci.io/about-us/"
              target="_blank"
              className="pb-1 text-decoration-none  cursor-pointer "
            >
              {' '}
              <span className="pl-2 text-center font-14 font-sofia-pro-regular color-charcoal-grey">About</span>{' '}
            </a>
            <a
              href="https://appreci.io/privacy-policy"
              target="_blank"
              className="pb-1 text-decoration-none  cursor-pointer "
            >
              <span className="pl-2 text-center font-14 font-sofia-pro-regular color-charcoal-grey">
                Privacy policy
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnonymousRedeem;
