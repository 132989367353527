/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import EditEmployeePage from './EditEmployee';
import MerchantService from '../../../../services/MerchantService';
import Toast from '../../../../services/ToasterService';
import { EmailRegexValue } from '../../../../utils/RegexPatterns';
import Button from '../../../../common/Button/Button';
import './Employees.scss';
import { ReactComponent as AddIconWhite } from '../../../../assets/images/common/icons-add-white.svg';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/common/icons-misc-search.svg';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as EmptyDataImage } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const validate = values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.length > 25) {
    errors.firstName = 'Characters length should not exceed 25';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.length > 25) {
    errors.lastName = 'Characters length should not exceed 25';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!EmailRegexValue.test(values.email)) {
    errors.email = 'Invalid email format';
  }

  return errors;
};

const MerchantStoreEmployeesPage = () => {
  const [show, setShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState();

  const [employeesList, setEmployeesList] = useState([]);

  let [existingEmployeesList, setExistingEmployeesList] = useState([]);

  const [checkIsDataLoadedOrNot, setCheckIsDataLoadedOrNot] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedEmployeeIndex, setSelectedEmployeeIndex] = useState();

  const [searchText, setSearchText] = useState('');

  const [showLoader, setShowLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    formik.resetForm();
    setShow(true);
  };

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  const showDeleteModalHandler = () => setShowDeleteModal(true);
  const hideDeleteModalHandler = () => setShowDeleteModal(false);

  useEffect(() => {
    getEmployeesList();
  }, []);

  // Getting employee list
  const getEmployeesList = () => {
    MerchantService.getCompanyUsersList()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCheckIsDataLoadedOrNot(true);
          if (data.length === 0) {
            setEmployeesList([]);
          } else {
            setEmployeesList(data);
            setExistingEmployeesList(data);
          }
        }
      })
      .catch(error => {
        setCheckIsDataLoadedOrNot(true);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Add new user
  const onSubmit = values => {
    toast.dismiss();
    setShowLoader(true);
    let data = values;

    data['role'] = 'CompanyAdmin';

    MerchantService.createNewCompanyUser(data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          handleClose();
          getEmployeesList();
          formik.resetForm();
          Toast(`${data.firstName} ${data.lastName} has been added to your store`, 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate,
  });

  // Get employee data
  const getEmployeeData = id => {
    setEditModalId(id);
    MerchantService.getCompanyUser(id).then(response => {
      if (response) {
        let data = response['data'];
        handleEditModalShow();

        formik.setFieldValue('firstName', data.firstName);
        formik.setFieldValue('lastName', data.lastName);
        formik.setFieldValue('email', data.email);
        formik.setFieldValue('phone', data.phone);
      }
    });
  };

  const deleteEmployeeData = index => {
    showDeleteModalHandler();
    setSelectedEmployeeIndex(index);
  };

  const handleChange = value => {
    setSearchText(value);
    searchEmployee(value);
  };

  // Search employee
  const searchEmployee = value => {
    setEmployeesList(existingEmployeesList);

    const excludeColumns = ['lastLoginOn'];

    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setEmployeesList(existingEmployeesList);
    else {
      const filteredData = existingEmployeesList.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setEmployeesList(filteredData);
    }
  };

  // Delete user
  const modalDeleteEmployeeBtnHandler = () => {
    let id = employeesList[selectedEmployeeIndex].id;
    MerchantService.deleteCompanyUser(id)
      .then(response => {
        if (response) {
          Toast(
            `${employeesList[selectedEmployeeIndex].firstName} ${employeesList[selectedEmployeeIndex].lastName} has been removed from your store`,
            'success'
          );
          hideDeleteModalHandler();
          getEmployeesList();
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Check data is available
  const checkStoreListDataAvailaleOrNot = () => {
    return (
      <div>
        <div className="p-0 m-0 row mt-4 my-2 mx-4 px-2 d-flex justify-content-between">
          <span className="font-sofia-pro-light font-24 color-black-pearl">Store employees</span>
          <div className="d-flex">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend employee-search__icon">
                <div className="pl-2">
                  <SearchIcon />
                </div>
              </div>
              <input
                type="text"
                placeholder="Search employee"
                className="mr-2 h-48 form-control form-input-placeholder border-0 bg-white employee-search__input shadow-none"
                value={searchText}
                onChange={e => handleChange(e.target.value)}
              />
            </div>
            <button
              className="btn  w-180 bg-primary-color color-white font-sofia-pro-semi-bold font-16 shadow-none"
              onClick={handleShow}
            >
              <AddIconWhite />
              <span className="pl-2">Add new</span>
            </button>
          </div>
        </div>
        <div className="my-2">
          <div className="mx-lg-3  card dashboard-card__redeemthanks my-3 table-responsive-sm table-responsive-md border-0 ">
            <table className="table dashboard-table_headcolor ">
              <thead style={{ backgroundColor: '#fff2fa ' }}>
                <tr className="mx-2">
                  <td scope="col" className="font-sofia-pro-semi-bold">
                    First name
                  </td>
                  <td scope="col" className="font-sofia-pro-semi-bold  ">
                    Last name
                  </td>
                  <td scope="col" className="font-sofia-pro-semi-bold  ">
                    Email
                  </td>
                  <td scope="col" className="font-sofia-pro-semi-bold ">
                    Status
                  </td>
                  <td scope="col" className="font-sofia-pro-semi-bold ">
                    Last login
                  </td>
                  <td scope="col" className="font-sofia-pro-semi-bold ">
                    Actions
                  </td>
                </tr>
              </thead>
              <tbody>
                {employeesList?.length > 0 &&
                  checkIsDataLoadedOrNot === true &&
                  employeesList.map((employee, index) => {
                    return (
                      <tr key={index}>
                        <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                          <span
                            data-tip={employee?.firstName}
                            data-for="employeeFirstname"
                            className="d-block employee__name__text__ellipsis"
                          >
                            {' '}
                            {employee?.firstName !== null ? employee?.firstName : 'Not Available'}{' '}
                          </span>
                          {employee?.firstName?.length > 10 && <ReactTooltip id="employeeFirstname" />}
                        </td>
                        <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                          <span
                            data-tip={employee?.lastName}
                            data-for="employeeLastname"
                            className="d-block employee__name__text__ellipsis"
                          >
                            {' '}
                            {employee?.lastName !== null ? employee?.lastName : 'Not Available'}{' '}
                          </span>
                          {employee?.lastName?.length > 10 && <ReactTooltip id="employeeLastname" />}
                        </td>
                        <td className="font-sofia-pro-regular font-14 color-black-pearl">
                          <span
                            data-tip={employee?.email}
                            data-for="employeeEmail"
                            className="d-block employee__text__ellipsis"
                          >
                            {employee?.email}
                          </span>
                          {employee?.email?.length > 10 && <ReactTooltip id="employeeEmail" />}
                        </td>
                        <td
                          className="d-flex align-items-center font-sofia-pro-regular font-14 color-black-pearl"
                          key={index}
                        >
                          <p
                            className={
                              employee?.status === 'Activated'
                                ? 'p-0 m-0 store-locations__active'
                                : 'p-0 m-0 store-locations__inactive'
                            }
                          ></p>
                          <span className="pl-1">{employee.status}</span>
                        </td>
                        <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                          {employee?.lastLoginOn !== null && (
                            <div>
                              <Moment format="D MMM YYYY" utc local>
                                {employee?.lastLoginOn}
                              </Moment>{' '}
                              |
                              <Moment format="HH:mm A" utc local>
                                {employee?.lastLoginOn}
                              </Moment>
                            </div>
                          )}

                          {employee?.lastLoginOn === null && <span>Account not logged in</span>}
                        </td>
                        <td className="d-flex font-sofia-pro-regular">
                          <button
                            className="p-2 bg-lavender-blush-1 border-primary-color border-radius-5 shadow-none"
                            onClick={getEmployeeData.bind(this, employee.id)}
                          >
                            <span className="font-sofia-pro-regular font-16 color-primary">Edit</span>
                          </button>
                          <button
                            className="ml-1 p-2 bg-white border-color-pearl border-radius-5 shadow-none"
                            onClick={deleteEmployeeData.bind(this, index)}
                          >
                            <span className="font-sofia-pro-regular font-16 color-black-pearl">Delete</span>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {employeesList?.length === 0 && checkIsDataLoadedOrNot === true && (
              <div className="w-100 d-flex justify-content-center">
                <div className="p-0 m-0 row mt-3">
                  <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <EmptyDataImage />
                    <span className="my-2 font-14 font-sofia-pro-medium">
                      Uh-oh, no store employees found,{' '}
                      <span
                        className="font-14 font-sofia-pro-semi-bold color-primary cursor-pointer"
                        onClick={handleShow}
                      >
                        Try adding one
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar menu="Merchant" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh">
        <Header menu="Merchant" />
        {checkStoreListDataAvailaleOrNot()}
      </div>
      <Modal
        className="pt-4 border-0 
          modal-background-shadow employee-modal"
        size="md"
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Add an employee</span>
          </div>
          <button type="button" className="border-0 employee-modal__close__icon" onClick={handleClose}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="p-0 m-0 row">
            <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12">
              <form className="mt-3" onSubmit={formik.handleSubmit}>
                <div className="p-0 m-0 row">
                  <div className="p-0 m-0 pr-2 col-sm-12 col-md-6 col-lg-6">
                    <div className="p-0 b-0 pb-1 form-group">
                      <label className="font-sofia-pro-regular color-charcoal font-16">First name *</label>
                      <input
                        type="text"
                        className="form-control h-60 font-sofia-pro-regular form-input-placeholder border-light-periwinkle shadow-none"
                        name="firstName"
                        placeholder="Eg: John"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      {formik.touched.firstName && formik.errors.firstName ? (
                        <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="p-0 m-0 col-sm-12 col-md-6 col-lg-6">
                    <div className="p-0 b-0 pb-1 form-group">
                      <label className="font-sofia-pro-regular color-charcoal font-16">Last Name *</label>
                      <input
                        type="text"
                        className="form-control h-60 font-sofia-pro-regular border-light-periwinkle form-input-placeholder shadow-none"
                        name="lastName"
                        placeholder="Eg: Doe"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />
                      {formik.touched.lastName && formik.errors.lastName ? (
                        <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Email *</label>
                  <input
                    type="text"
                    className="form-control h-60 font-sofia-pro-regular border-light-periwinkle form-input-placeholder shadow-none"
                    name="email"
                    placeholder="Eg: test@gmail.com"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                    </div>
                  ) : null}
                </div>
                <div className="d-flex mt-2">
                  <Button
                    classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                    children="Save"
                    showLoader={showLoader}
                    onClick={() => onSubmit}
                  />
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0 modal-background-shadow employee-modal"
        backdrop="static"
        size="md"
        show={editModalShow}
        onHide={handleEditModalClose}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Edit an existing employee</span>
          </div>
          <button type="button" className="border-0 employee-modal__close__icon" onClick={handleEditModalClose}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <EditEmployeePage id={editModalId} closeModal={handleEditModalClose} loadEmployeesList={getEmployeesList} />
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0 
         modal-background-shadow employee-modal"
        size="md"
        show={showDeleteModal}
        backdrop="static"
        onHide={hideDeleteModalHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              Are you sure you want to remove {employeesList[selectedEmployeeIndex]?.firstName}{' '}
              {employeesList[selectedEmployeeIndex]?.lastName} ?
            </span>
            <span className="font-14 font-sofia-pro-light color-black-pearl">
              {employeesList[selectedEmployeeIndex]?.firstName} {employeesList[selectedEmployeeIndex]?.lastName} will no
              longer have access to the store and it’s features
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={hideDeleteModalHandler}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={modalDeleteEmployeeBtnHandler}
            >
              Remove
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={hideDeleteModalHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MerchantStoreEmployeesPage;
