import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const ApplePay = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm clientSecrets={props?.clientSecrets}/>
    </Elements>
  );
};

export default ApplePay;
