/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState } from 'react';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../../common/Button/Button';
import Toast from '../../../../services/ToasterService';
import http from '../../../../services/HttpService';
import './ResetPassword.scss';
import { ReactComponent as Logo } from '../../../../assets/images/logo/logo-colour.svg';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as ResetPasswordLeftPanelImage } from '../../../../assets/images/forgot_password/new-password.svg';
import { HidePasswordImage, ShowPasswordImage } from '../../../../assets/images';

const initialValues = {
  password: '',
};

const validate = values => {
  let errors = {};

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = ' Please enter at least 8 characters';
  }

  return errors;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResetPasswordPage() {
  let query = useQuery();

  const history = useHistory();

  const [showEmailSentMessage, setShowEmailSentMessage] = useState(false);

  const [show, setshow] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  // Sending reset password request for both email and mobile
  const onSubmit = values => {
    toast.dismiss();

    setShowLoader(true);

    let queryEmail = query.get('Email');
    let queryToken = query.get('Token');
    let queryMobile = query.get('Mobile');
    let data;
    if (queryEmail !== null) {
      data = {
        email: queryEmail,
        password: values.password,
      };
    } else if (queryMobile !== null) {
      data = {
        phone: queryMobile,
        region: 'AU',
        password: values.password,
      };
    }

    if (data?.password.trim().length > 0) {
      if (
        (queryEmail !== null && queryEmail.length > 0) ||
        (queryMobile !== null && queryMobile.length > 0 && queryToken.length > 0)
      ) {
        http
          .patch('/users/bytoken/' + queryToken, data)
          .then(response => {
            setShowLoader(false);
            Toast('Your password is changed successfully. Please login now.', 'success');
            setShowEmailSentMessage(true);
            history.push('/login');
          })
          .catch(error => {
            setShowLoader(false);
            let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (errorResponseFieldErrors) {
              if (errorResponseFieldErrors.length > 0) {
                errorResponseFieldErrors.forEach(msg => {
                  Toast(msg.message, 'error');
                });
              }
            }
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  console.log('error global response message ', msg);
                  Toast(msg.message, 'error');
                });
              }
            }
          });
      }
    } else {
      Toast('Password should contain atleast one character', 'error');
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const togglePassword = () => {
    setshow(!show);
  };

  return (
    <div className="p-0 m-0 row h-100">
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-7 d-flex justify-content-center align-items-center h-100vh reset-password-left__half bg-pale-mavue">
        <ResetPasswordLeftPanelImage />
      </div>
      <div className="p-0 m-0 p-2 col-sm-12 col-md-12 col-lg-5 d-flex flex-column align-items-center justify-content-center h-100vh reset-password-right__half">
        <div className="d-flex justify-content-between w-100 px-lg-5 px-md-3">
          <Logo />
          <Link to={'/login'}>
            <button className="btn color-primary border-radius-5 font-sofia-pro-regular">Sign in</button>
          </Link>
        </div>
        <div className="justify-content-start w-100 px-lg-5 px-md-3 mt-5">
          <span className="color-black-pearl font-40 font-sofia-pro-bold">New password</span>
        </div>
        <div className="w-100 px-lg-5 px-md-3 mt-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
              <label className="font-sofia-pro-regular color-charcoal font-16">Enter new password</label>

              <div className="input-group">
                <input
                  type={show ? 'text' : 'password'}
                  className="form-control h-60 font-sofia-pro-regular reset-password__placeholder border-light-periwinkle  reset-password__input"
                  name="password"
                  placeholder="********"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />

                <div className="p-3 input-group-append login-input__append__design">
                  {show ? (
                    <ShowPasswordImage className="p-0 m-0 login-password__icon__position" onClick={togglePassword} />
                  ) : (
                    <HidePasswordImage className="p-0 m-0 login-password__icon__position" onClick={togglePassword} />
                  )}
                </div>
              </div>

              {formik.touched.password && formik.errors.password ? (
                <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                  <img className="mr-1" src={ErrorImage} /> {formik.errors.password}
                </div>
              ) : null}
            </div>
            <div className="w-100 mt-4">
              <Button
                classNameValue="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                children="Update"
                showLoader={showLoader}
                onClick={() => onSubmit}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
