/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrganisationHeader from '../../../organisationheader/organisationHeader';
import Button from '../../../../../common/Button/Button';
import OrganisationSidebar from '../../../organisationsidebar/organisationsidebar';
import OrganisationService from '../../../../../services/OrganisationService';
import * as AllocateSchema from '../../../../../utils/Schema/allocateSchema';
import SendThanksService from '../../../../../services/SendThanksService';
import {
  allocateCreditDetails,
  resetAllocateCreditDetails,
} from '../../../../../store/reducers/organisation/organisation';
import IconsBackImage from '../../../../../assets/images/common/icons-back.png';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

function OrganisationAdminAllocateReviewThanksPage(props) {
  const history = useHistory();
  const PaymentType = sessionStorage.getItem('PaymentType');

  const [showSendThanksLoader, setShowSendThanksLoader] = useState(false);
  const [creditBalance, setCreditBalance] = useState();

  let reviewData = {};

  const recipientDetails = AllocateSchema.AllocateSchema;

  const [productPrice, setProductPrice] = useState();

  const [productDetailValues, setProductDetailValues] = useState();

  useEffect(() => {
    getCreditBalance();
    setProductDetailValues(JSON.parse(sessionStorage.getItem('productDetails')));
  }, [productPrice]);

  // Get credit balance
  const getCreditBalance = () => {
    OrganisationService.getCreditsBalance()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCreditBalance(data);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          toast.error(errorMessage);
        }
      });
  };

  const allocateThanksBtnHandler = () => {
    setShowSendThanksLoader(true);
    let data = {
      amount: AllocateSchema.AllocateSchema.amount,
      message: AllocateSchema.AllocateSchema.message,
      paymentSource: AllocateSchema.AllocateSchema.paymentSource,
    };

    if (PaymentType === 'availableCredit') {
      data.paymentSource.useCredit = true;
      data['quantity'] = null;

      OrganisationService.allocateCredits(AllocateSchema.AllocateSchema.employeeId, data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            history.push({
              pathname: '/organisation/admin/allocate/success',
              state: {
                type: 'organisation',
              },
            });
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
          }
          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const editMessageBtnHandler = () => {
    history.push({
      pathname: '/organisation/admin/allocate/recipientInformation',
      state: {
        nextPath: '/organisation/admin/allocate/review',
      },
    });
  };

  const cancelBtnHandler = () => {
    history.push({
      pathname: '/organisation/dashboard',
    });
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column  ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half">
        <OrganisationHeader role="admin" />
        <div className="d-flex m-2 mx-4">
          <Link to="/organisation/admin/allocate/recipientInformation">
            <span className="p-2 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 card border-0  border-radius-5">
              <div className="card-body">
                <span className="font-32 font-sofia-pro-light color-black-pearl">Review your purchase</span>
                <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                  <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Details</span>
                </div>
                <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                  <span className="font-16 font-sofia-pro-light color-charcoal">To</span>

                  <span className="font-16 font-sofia-pro-regular color-charcoal">
                    {AllocateSchema.AllocateSchema.name}
                  </span>
                </div>

                <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                  <span className="font-16 font-sofia-pro-light color-charcoal">
                    Message
                    {/* {
                                            productDetails?.recipients !== undefined && productDetails?.recipients.length !== 0 && */}
                    <span>
                      {/* {
                                                    productDetails?.recipients[0]?.message?.type === 'Text' && */}
                      <span
                        className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer"
                        onClick={editMessageBtnHandler}
                      >
                        Edit message
                      </span>
                      {/* } */}
                    </span>
                    {/* } */}
                  </span>

                  <div>
                    <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                      {AllocateSchema.AllocateSchema.message !== null
                        ? AllocateSchema.AllocateSchema.message
                        : 'No Message'}
                    </span>
                  </div>
                </div>

                <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                  <span className="font-16 font-sofia-pro-light color-charcoal">Amount</span>
                  <div>
                    <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                      $ {recipientDetails.amount}
                    </span>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Payment method</span>
                  </div>
                  <div className="mt-1 p-2 d-flex justify-content-between align-items-center">
                    <div>
                      {PaymentType === 'availableCredit' && (
                        <label className="font-14 font-sofia-pro-light color-black-pearl ">
                          <span>Available credits</span>
                        </label>
                      )}
                    </div>

                    <div>
                      <span className="pl-2 font-16 font-sofia-pro-regular color-charcoal cursor-pointer">
                        $ {creditBalance?.balance}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Purchase summary</span>
                  </div>
                  <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
                    <span className="font-16 font-sofia-pro-light color-charcoal">Allocated credits</span>
                    <span className="font-16 font-sofia-pro-regular color-charcoal">${recipientDetails.amount}</span>
                  </div>
                  {PaymentType === 'availableCredit' && (
                    <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
                      <span className="font-16 font-sofia-pro-light color-charcoal">Remaining credit balance</span>
                      <span className="font-16 font-sofia-pro-regular color-charcoal">
                        ${((creditBalance?.balance - recipientDetails.amount)*100).toFixed()/100}
                      </span>
                    </div>
                  )}
                </div>
                {PaymentType !== 'availableCredit' && (
                  <div className="mt-3">
                    <div className="mt-2 p-2 d-flex justify-content-between align-items-center bg-lavender-blush-1 border-radius-5">
                      <span className="font-18 font-sofia-pro-semi-bold color-dark-charcoal">Total spend</span>
                      <span className="font-18 font-sofia-pro-medium color-dark-charcoal">
                        ${recipientDetails.amount}
                      </span>
                    </div>
                  </div>
                )}

                {PaymentType === 'availableCredit' && (
                  <div className="mt-3">
                    <div className="mt-2 p-2 d-flex justify-content-between align-items-center bg-lavender-blush-1 border-radius-5">
                      <span className="font-18 font-sofia-pro-semi-bold color-dark-charcoal">Total spend</span>
                      <span className="font-18 font-sofia-pro-medium color-dark-charcoal">
                        ${recipientDetails.amount}
                      </span>
                    </div>
                  </div>
                )}

                <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
                  <button
                    className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none"
                    onClick={cancelBtnHandler}
                  >
                    Cancel
                  </button>

                  {PaymentType === 'Card' || PaymentType === 'availableCredit' ? (
                    <Button
                      classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      children="Send"
                      showLoader={showSendThanksLoader}
                      onClick={allocateThanksBtnHandler}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
          <div className="d-none"></div>
        </div>
      </div>
    </div>
  );
}

// MAPPING - SEND THANKS DATA

const mapStateToProps = state => {
  return {
    getAllocateRecipientInfo: state.organisation.allocateData,
  };
};

// DISPATCH  - SEND THANKS DATA

const mapDispatchToProps = dispatch => {
  return {
    allocateCreditDetails: data => dispatch(allocateCreditDetails(data)),
    resetData: () => dispatch(resetAllocateCreditDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationAdminAllocateReviewThanksPage);
