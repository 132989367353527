/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { connect, useDispatch } from 'react-redux';
import { sendThanksDetails, resetSendThanksDetails } from '../../../../store/reducers/individual/sendThanks/sendThanks';
import './SelectGiftItem.scss';
import ItemCheckedImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import IconsInfoGrey from '../../../../assets/images/common/icons-info-grey.png';
import ButtonBack from '../../../../common/Button/ButtonBack';
import { toast } from 'react-toastify';
import httpService from '../../../../services/HttpService';
import { productDetailsDispatch } from '../../../../store/reducers/multiProduct/anonMultiProducts';
import SendThanksService from '../../../../services/SendThanksService';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';
import AnonymousLayout from '../../../../common/component/anonymouslayout/AnonymousLayout';
import Header from '../../../../common/Header/Header';
import CoffeeIcon from '../../../../assets/images/dashboard/icons/coffee.svg';
import CoffeePinkIcon from '../../../../assets/images/dashboard/icons/coffee-pink.png';
import ChocolateIcon from '../../../../assets/images/dashboard/icons/iconoir_chocolate.svg';
import ChocolatePinkIcon from '../../../../assets/images/dashboard/icons/chocolate-pink.png';
import WineIcon from '../../../../assets/images/dashboard/icons/wine.svg';
import WinePinkIcon from '../../../../assets/images/dashboard/icons/wine-pink.png';
import BeerIcon from '../../../../assets/images/dashboard/icons/ph_beer-thin.svg';
import BeerPinkIcon from '../../../../assets/images/dashboard/icons/beer-pink.png';
import GiftCardsIcon from '../../../../assets/images/dashboard/icons/gift_card.svg';
import GiftCardsPinkIcon from '../../../../assets/images/dashboard/icons/giftcards-pink.png';
import CoffeeImage from '../../../../assets/images/dashboard/image/coffee_image.png';
import { ReactComponent as Step1 } from '../../../../assets/images/dashboard/icons/step-1.svg';
import SelectProduct from '../../../individual/sendThanks/selectItem/SelectProduct';
import AnonSelectProduct from './AnonSelectProduct';

const giftTypes = [
  {
    type: 'Coffee',
    icon: CoffeeIcon,
    pinkIcon: CoffeePinkIcon,
    image: CoffeeImage,
    price: '5.95',
  },
  {
    type: 'Chocolate',
    icon: ChocolateIcon,
    pinkIcon: ChocolatePinkIcon,
    image: CoffeeImage,
    price: '11.95',
  },
  {
    type: 'Wine',
    icon:BeerIcon ,
    pinkIcon: BeerPinkIcon,
    image: CoffeeImage,
    price: '27.95',
  },
  {
    type: 'Beer',
    icon: WineIcon,
    pinkIcon:WinePinkIcon ,
    image: CoffeeImage,
    price: '35.95',
  },
  // {
  //   type: 'Gift cards',
  //   icon: GiftCardsIcon,
  //   pinkIcon: GiftCardsPinkIcon,
  //   image: CoffeeImage,
  //   price: '10.95',
  // },
];

const SelectAnonymousItemPage = props => {
  const { width } = useWindowDimensions();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentPath = window.location.pathname;

  const [tileWidth, setTileWidth] = useState();
  const [selectedProductOption, setSelectedProductOption] = useState();

  const [productsType, setProductsType] = useState([]);
  const [giftProduct, setGiftProduct] = useState([]);

  const [availableGiftAmount, setAvailableGiftAmount] = useState(false);
  const [checkGiftCardIsClicked, setCheckGiftCardIsClicked] = useState(false);
  const [checkCustomOptionIsClicked, setCheckCustomOptionIsClicked] = useState(false);

  const [selectedGiftCardIndex, setselectedGiftCardIndex] = useState();
  const [selectedGiftcardValue, setSelectedGiftcardValue] = useState();
  const [selectedGiftType, setSelectedGiftType] = useState({type:'Coffee'});

  let giftCardValues = [
    {
      id: 0,
      amount: 20,
      checked: false,
    },
    {
      id: 1,
      amount: 50,
      checked: false,
    },
    {
      id: 2,
      amount: 100,
      checked: false,
    },
    {
      id: 3,
      amount: 'Custom',
      checked: false,
    },
  ];

  useEffect(() => {
    if (width > 500) {
      setTileWidth(48);
    } else {
      setTileWidth(40);
    }
  }, [width]);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadProductsTypes();
    loadProduct();
    return () => {
      props.resetData();
    };
  }, []);
  
  useEffect(() => {
    return () => {};
  }, [selectedGiftType]);

  // Checking page reload
  useEffect(() => {
    window.onbeforeunload = function (event) {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^[0-9]$/.test(keyValue)) {
      event.preventDefault();
    }

    let max_chars = 3;

    if (event.target.value.length > max_chars) {
      event.target.value = event.target.value.substr(0, max_chars);
    }
  };

  // Gift card custom value
  const giftCardCustomhandleOnChange = event => {
    toast.dismiss();
    setSelectedGiftcardValue();

    if (event.target.value !== '' && event.target.value > 10 && event.target.value <= 100) {
      setSelectedGiftcardValue(event.target.value);
      setAvailableGiftAmount(true);
    } else {
      setAvailableGiftAmount(false);
      toast.warn('Please enter values between 10 to 100');
    }
  };

  const selectedGiftCardItem = index => {
    if (giftCardValues[index].amount !== 'Custom') {
      setselectedGiftCardIndex(index);
      setCheckCustomOptionIsClicked(false);
      giftCardValues[index].checked = true;
      setAvailableGiftAmount(true);
      setSelectedGiftcardValue(giftCardValues[index].amount);
    } else {
      setCheckCustomOptionIsClicked(true);
      setAvailableGiftAmount(false);
    }
  };
  const selectedItem = index => {
    setSelectedProductOption(index);
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });

    if (productsType[index].type === 'GiftCard') {
      setCheckGiftCardIsClicked(true);
    } else {
      setCheckGiftCardIsClicked(false);
      dispatch(
        productDetailsDispatch({
          type: productsType[index].type,
        })
      );
    }
    // if (giftTypes.type === 'GiftCard') {
    //   setCheckGiftCardIsClicked(true);
    // } else {
    //   setCheckGiftCardIsClicked(false);
    //   dispatch(
    //     productDetailsDispatch({
    //       type: productsType[index].type,
    //     })
    //   );

    //   // const replacerPath = currentPath.replace('anonymousselectitem', 'selectProduct');
    //   // history.push(`${replacerPath}?type=${productsType[index].type}`);
    // }
  };
//   useEffect(()=>{
// selectedItem(0)
//   },[])

  const handleProceed = () => {
    if (productsType[selectedProductOption].type === 'GiftCard') {
      const replacerPath = currentPath.replace('selectItem', 'payment');
      dispatch(
        productDetailsDispatch({
          giftValues: selectedGiftcardValue,
          giftPriceId: giftProduct.prices[0]?.priceId,
        })
      );
      // props.history.push(`${replacerPath}`);
    history.push("/anonrecipientInformation")

    } else {
      // const replacerPath = currentPath.replace('selectItem', 'selectProduct');
      // history.push(`${replacerPath}?type=${productsType[selectedProductOption].type}`);
    }
    dispatch(
      productDetailsDispatch({
        type: productsType[selectedProductOption].type,
      })
    );
  };

  const loadProduct = async () => {
    SendThanksService.getListOfProducts('AU', 'Unknown', true)
      .then(res => {
        let data = res['data'];
        const giftProductFind = data.find(elem => {
          return elem.type === 'GiftCard';
        });
        setGiftProduct(giftProductFind);
      })
      .catch(error => {
        console.log('error ', error);
      });
  };

  const loadProductsTypes = async () => {
    httpService
      .get(`/products/types`)
      .then(res => {
        setProductsType(res.data);
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response?.data.globalErrors;
        let errorResponse = error.response?.data;
        if (errorResponse?.globalErrors[0]) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  // const handleNext = () => {
  //   if (selectedGiftType) {
  //     history.push('/anonrecipientInformation');
  //   } else {
  //     toast.error('Please select item to send gift');
  //   }
  // };
  return (
    <>
      <AnonymousLayout>
        {/* <Header /> */}
        {/* <ButtonBack
        from='selectItem'
        to='recipientInformation'
      /> */}
        <div className="text-center pt-3 pb-5">
          <Step1 className="step" />
        </div>
        <div className={`border-0 border-radius-5  ${width > 500 ? 'm-4' : 'm-1'}`}>
          <span className="ml-2 my-3 color-charcoal-grey font-36 monteserrat-normal">Choose gifts to send</span>
          <div className="card-body p-1">
            <div
              className={`p-0 m-0 row d-flex flex-wrap width-50 justify-content-between ${
                width < 500 ? 'justify-content-between' : ''
              }`}
            >
              {productsType.map((product, index) => {
                if(product.type !== "GiftCard") {
                  return (
                    <div
                      className="p-0 m-0 my-3 px-2 d-flex flex-column position-relative cursor-pointer"
                      key={index}
                      onClick={() => {
                        selectedItem(index);
                        setSelectedGiftType({ image: product.image, type: product.type, price: product.price });
                      }}
                    >
                      <div className={`position-relative w-${tileWidth}`}>
                        {/* {index === selectedProductOption ? (
                          <img src={ItemCheckedImage} height="25px" width="25px" className="select-item__checked_icon" />
                        ) : null} */}
                      </div>
                      <img
                        className={
                          // index === selectedProductOption
                            // ? 'border-primary-color-2 border-radius-11 align-self-center'
                            // : 
                            'border-radius-17 align-self-center'
                        }
                        src={`${product.imagePath}?h=${tileWidth}`}
                        // src={selectedGiftType?.type == product.type ? product.pinkIcon : product.icon}
                        height={tileWidth}
                        width={tileWidth}
                        alt="Icons"
                      />
                      <div
                        className={`w-${tileWidth} d-flex justify-content-between align-items-center align-self-center`}
                      >
                        <p
                          className={`p-0 m-0 mt-2 font-16 font-sofia-pro-regular ${
                            selectedGiftType?.type == product.type && 'color-primary'
                          }`}
                        >
                          {product.type}
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            {productsType.length === 0 && (
              <div className="d-flex border-0 w-100 text-center justify-content-center">
                <Spinner animation="border" />
              </div>
            )}

          
          </div>
          <div>
            <div className="ml-2 mb-3 mt-2 font-sofia-pro-light color-charcoal-grey font-24">Selected item</div>
            <hr />
            {selectedGiftType && (
              // <div className="gift-type-selected">
              //   <div className="gift-type-selected-image">
              //     <img src={selectedGiftType.image} alt="GiftImage" className="gift-type-selected-image-icon" />
              //   </div>
              //   <div className="mt-2 font-16 monteserrat-normal">{selectedGiftType.type}</div>
              //   <span className="font-20 monteserrat-bold">${selectedGiftType.price}</span>
              // </div>
              <>
                {checkGiftCardIsClicked === true ? (
              <>
                <div className="p-0 m-0 mx-3 my-4">
                  <span className="ml-2 mb-3 font-sofia-pro-light color-charcoal-grey font-24">Gift card amount</span>
                  <div className="mt-4">
                    {checkCustomOptionIsClicked === false && (
                      <div className="d-flex gap-20 flex-wrap ">
                        {giftCardValues.map((value, index) => {
                          return (
                            <div className="" key={index}>
                              <span
                                className={`p-3 w-90 bg-white-smoke cursor-pointer d-block ${
                                  index === selectedGiftCardIndex
                                    ? ' font-sofia-pro-semi-bold color-primary border-primary-color'
                                    : ' font-sofia-pro-regular color-approx-charcoal '
                                }`}
                                onClick={selectedGiftCardItem.bind(this, index)}
                              >
                                {value.amount !== 'Custom' ? '$ ' + value.amount : value.amount}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {checkCustomOptionIsClicked === true && (
                      <div className="w-100 wm-500 form-group mt-2">
                        <input
                          type="number"
                          onKeyPress={onKeyPress.bind(this)}
                          onChange={giftCardCustomhandleOnChange.bind(this)}
                          className="form-control font-sofia-pro-light font-14 h-60 select-item__quantity_placeholder shadow-none"
                          placeholder="Enter custom amount (min $10 | max $100)"
                          value={selectedGiftcardValue}
                          onWheel={e => e.target.blur()}
                          min="10"
                          max="100"
                          step="1"
                        />
                        <div className="d-flex align-items-center">
                          <img src={IconsInfoGrey} height="15px" width="15px" />
                          <p className="p-0 m-0 font-sofia-pro-regular font-12 color-grey-blue">
                            Enter amount between 10$ -100$ (accepts multiples of 10).
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="p-0 m-2 text-right">
                  <button
                    type="submit"
                    className="btn bg-primary-color h-44 w-120 color-white font-sofia-pro-semi-bold font-16 shadow-none"
                    disabled={
                      (selectedProductOption >= 0 && !checkGiftCardIsClicked) || availableGiftAmount ? false : true
                    }
                    onClick={handleProceed}
                  >
                    Next
                  </button>
                </div>
              </>
            ):
            
                <AnonSelectProduct productType={selectedGiftType.type}/>
            }
              </>
            )}
          </div>
          {/* <div className="p-0 m-3 text-right">
            <button
              type="submit"
              className="btn bg-primary-color h-44 color-white monteserrat-normal font-16 shadow-none px-5 btn-next"
              onClick={handleNext}
            >
              Next
            </button>
          </div> */}
        </div>
      </AnonymousLayout>
    </>
  );
};

// MAPPING - SEND THANKS DATA
const mapStateToProps = state => {
  return {
    getProductInformation: state.sendThanks.sendThanksData,
  };
};

// DISPATCH  - SEND THANKS DATA
const mapDispatchToProps = dispatch => {
  return {
    priceDetails: data => dispatch(sendThanksDetails(data)),
    resetData: () => dispatch(resetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectAnonymousItemPage);
