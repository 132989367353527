/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import SendThanksService from '../../../../services/SendThanksService';
import ItemCheckedImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import AddressDetails from './AddressDetails';
import BottomBar from '../Common/BottomBar';
import RedeemedGift from '../Common/RedeemedGift';
import '../../../individual/redeemThanks/RedeemBeer/SelectProduct.scss';

const SelectProduct = () => {
  const [product, setProduct] = useState([]);
  const [indexValue, setIndexValue] = useState();
  const [redeemedGift, setRedeemedGift] = useState(false);

  const Data = JSON.parse(sessionStorage.getItem('GiftSuccessDetails'));
  const anonymous = JSON.parse(sessionStorage.getItem('redeemInfo'));
  const priceIdOfGiftRecieved = sessionStorage.getItem('priceId');

  toast.configure({
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        res.data.map(val => {
          if (val['type'] === anonymous.type) {
            val['ranges'].map(item => {
              if (item.priceId == priceIdOfGiftRecieved) {
                setProduct(prevArray => [...prevArray, item]);
              }
            });
            // setProduct(val['ranges']);
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const BeerSelected = index => {
    setIndexValue(index);
    sessionStorage.setItem('GiftProductId', product[index].id);
    sessionStorage.setItem(
      'GiftSuccessDetails',
      JSON.stringify({
        Url: product[index]['imagePath'],
        Name: product[index]['name'],
      })
    );
  };

  if (redeemedGift) {
    return <RedeemedGift image={Data?.['Url']} name={Data?.['Name']} type={anonymous?.type}/>;
  }
  console.log('Products===>', product);
  return (
    <div className="m-0 p-0 row ">
      <div className="d-flex flex-column align-items-center m-auto">
        <Logo />
        <div className="redeemnow-rectangle__small d-flex align-items-center my-2">
          <span className="redeemnow-text__Step-1-Select-your font-sofia-pro-regular mx-3">
            <span className="text-style-1">Step 1:</span> Select your {anonymous.type}
          </span>
        </div>

        <div className="selectproduct-rectangle__list m-3">
          <div className=" row  m-1">
            {product.map((val, index) => {
              return (
                <button
                  key={val.id}
                  className="col-12   col-lg-4 border-0 bg-transparent "
                  onClick={BeerSelected.bind(this, index)}
                >
                  <div
                    key={index}
                    className={
                      indexValue === index
                        ? 'd-flex flex-column selectproduct-beer__selected position-relative my-2'
                        : 'd-flex flex-column position-relative selectproduct-beer__unselected '
                    }
                  >
                    {index === indexValue ? (
                      <img
                        src={ItemCheckedImage}
                        height="25px"
                        width="25px"
                        className="select-item__checked_icon-anon"
                        alt=""
                      />
                    ) : null}

                    <div className="d-flex flex-column">
                      <div className=" border-0 selectproduct-image__background mx-2 d-flex justify-content-center align-items-center my-2">
                        <img src={val['imagePath'] + '?h=120&w=120'} alt="" />
                      </div>
                      <span className="mx-3 selectproduct-text__beername font-sofia-pro-medium d-flex">
                        {val['name']}
                      </span>
                      <span className=" selectproduct-text__beerdiscription font-sofia-pro-light ">
                        {val['description']}
                      </span>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </div>
        <div>
          <div className="redeemnow-rectangle__small d-flex align-items-center my-2">
            <span className="redeemnow-text__Step-1-Select-your font-sofia-pro-regular mx-3">
              <span className="text-style-1">Step 2:</span> Enter your details
            </span>
          </div>
        </div>
        <AddressDetails setRedeemedGift={setRedeemedGift} />
        <BottomBar />
      </div>
    </div>
  );
};

export default SelectProduct;
