/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import SendThanksService from '../../../services/SendThanksService';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as Remove } from '../../../assets/images/redeem_thanks/icons/icons-remove-16-px (1).svg';
import BitMap from '../../../assets/images/dashboard/image/bitmap.jpg';
// import Add from '../../../../assets/images/redeem_thanks/icons/icons-add-pink.png';
import Add from '../../../assets/images/redeem_thanks/icons/icons-add-pink.png';

// import BottomBar from '../Common/BottomBar';
import BottomBar from '../../anonymous/redeem/Common/BottomBar';

// import RedeemNowWine from './RedeemNowWine';
import RedeemNowWine from '../../anonymous/redeem/redeemWine/RedeemNowWine';
// import '../../../individual/redeemThanks/redeemwine/RedeemWine.scss';
import '../../individual/redeemThanks/redeemwine/RedeemWine.scss';

const PreviewGift = () => {
  const From = sessionStorage.getItem('FromName');
  const [quantityValue, setQuantityValue] = useState(1);
  const [product, setProduct] = useState(null);
  let AvailableQuantity = sessionStorage.getItem('Quantity');
  const [showModal, setShowModal] = useState(false);
  const [proceedRedeem, setProceedRedeem] = useState(false);
  const [productImg, setProductImg] = useState();
  const [productName, setProductName] = useState();
  const [userName, setUserName] = useState();


  const userData = JSON.parse(localStorage.getItem("userData"));
  useEffect(() => {
    window.scrollTo(0, 0);
    setProductImg(localStorage.getItem("productImg"));
    setProductName(localStorage.getItem("productName"));
    setUserName(userData.fullName);
  }, []);

  useEffect(() => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        res.data.map((val, index) => {
          if (val['type'] === 'Wine') {
            setProduct(val);
          }
        });
      })
      .catch(err => {});
  }, []);

  return (
    <div className="d-flex flex-column align-items-center my-4">
      <Logo />
      <div className="top-cont-wine">
        <div className=" redeemwine-card__small d-flex flex-column align-items-center my-4">
          {productImg !== null ? (
            <img src={productImg + '?h=150&w=150'} alt="Wine" className="redeem-image__the_hidden_sea my-1" />
          ) : (
            ''
          )}
          <span className="redemmwine-text__thanks my-2 font-sofia-pro-bold">A thanks from {userName}</span>
          <span className="redeemwine-text__Hey-mate-Thanks-for  font-sofia-pro-light px-2">
            {productName !== null ? <span>A {productName} can be delivered anywhere in Australia  </span> : ''}
          </span>
        </div>
      </div>
      <span className="redeemwine-text__How-people-are-using font-sofia-pro-bold">
        How people are using <span className="text-style-1">Appreci</span>
      </span>
      <span className="redeemwine-text__Appreci-can-be-used font-sofia-pro-light my-2 ">
        Appreci can be used in a variety of ways to show appreciation and <br /> gratitude, personally and
        professionaly.
      </span>
      <img src={BitMap} className="my-3" />
      <BottomBar />
    </div>
  );
};

export default PreviewGift;
