/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import * as ReactBootstrap from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as RegexPattern from '../../../utils/RegexPatterns';

import OrganisationSidebar from '../../organisation/organisationsidebar/organisationsidebar.js';
import OrganisationHeader from '../../organisation/organisationheader/organisationHeader.js';
import EditUserPage from '../../organisation/users/EditUser.js';
import Button from '../../../common/Button/Button';
import Toast from '../../../services/ToasterService';
import OrganisationService from '../../../services/OrganisationService';
import '../../organisation/users/Users.scss';
import '../../merchant/store/employees/Employees.scss';
import { ReactComponent as DeleteClose } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/common/icons-misc-search.svg';
import { ReactComponent as FolderIcon } from '../../../assets/images/common/icons-folder.svg';
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import { ReactComponent as EditUser } from '../../../assets/images/common/edit-user.svg';
import { ReactComponent as DeleteUser } from '../../../assets/images/common/delete-user.svg';
import { ReactComponent as Allocate } from '../../../assets/images/common/allocate_dollar.svg';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Close } from '../../../assets/images/common/close-modal.svg';
import { ReactComponent as StarFilled } from '../../../assets/images/common/star-filled.svg';
import { ReactComponent as Star } from '../../../assets/images/common/star.svg';
import { ReactComponent as TrashFilled } from '../../../assets/images/common/trash-bin.svg';
import { ReactComponent as Trash } from '../../../assets/images/common/trash.svg';
import ErrorImage from '../../../assets/images/error-icons/icons-error-info.png';

import SampleCSVFormatImage from '../../../assets/images/common/sample_csv_staff.png';
import CSV from '../../../common/csv/staff_csv.xlsx';
import DropZone from '../../../common/Upload/Dropzone.js';
import OrganisationHeaderMobile from '../../organisation/organisationheader/organisationHeaderMobile.js';
import SideBar from '../../../common/Sidebar/Sidebar.js';

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: null,
};

const validate = values => {
  const errors = {};
  let re = RegexPattern.EmailRegexValue;
  let ph = RegexPattern.MobileRegexValue;

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!re.test(values.email)) {
    errors.email = 'Invalid email format';
  }

  if (!ph.test(!values.phone)) {
    errors.mobile = 'Invalid mobile number';
  }

  return errors;
};

const ContactCommon = () => {
  const [show, setShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState();

  const history = useHistory();

  const [usersList, setUsersList] = useState([]);

  let [existingUsersList, setExistingUsersList] = useState([]);

  const [checkIsDataLoadedOrNot, setCheckIsDataLoadedOrNot] = useState(false);

  const [fileName, setFileName] = useState(null);

  const [checkFileList, setCheckFileList] = useState(false);

  const [fileData, setFileData] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedUserIndex, setSelectedUserIndex] = useState();

  const [searchText, setSearchText] = useState('');

  const [showLoader, setShowLoader] = useState(false);

  const [disableResendInviteBtn, setDisableResendInviteBtn] = useState(false);

  const [showSampleFormat, setShowSampleFormat] = useState(false);

  const [userIndex, setUserIndex] = useState();

  const [emailClicked, setEmailClicked] = useState(true);

  const [csvClicked, setCsvClicked] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [starSelect, setStarSelect] = useState(Array(usersList.length).fill(false));

  const handleRowSelect = index => {
    const updatedSelectedRows = [...selectedRows];
    if (updatedSelectedRows.includes(index)) {
      updatedSelectedRows.splice(updatedSelectedRows.indexOf(index), 1);
    } else {
      updatedSelectedRows.push(index);
    }

    setSelectedRows(updatedSelectedRows);
    setSelectAllChecked(updatedSelectedRows.length === usersList.length);
  };

  const handleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedRows([]);
    } else {
      const allRowIndexes = usersList.map((_, index) => index);
      setSelectedRows(allRowIndexes);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleAcceptedFiles = files => {
    const file = files[0];
    setFileData(file.data);
    setFileName(file.name);
  };

  const handleRejectedFiles = files => {
    const file = files[0];
    setFileData(null);
    setFileName(null);
  };

  const handleClose = () => {
    setShow(false);
    setFileName(null);
    setFileData(null);
  };
  const handleShow = () => {
    setShow(true);
  };

  const handleCloseSampleFormat = () => setShowSampleFormat(false);
  const handleShowSampleFormat = () => {
    setShowSampleFormat(true);
  };

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  const showDeleteModalHandler = () => setShowDeleteModal(true);
  const hideDeleteModalHandler = () => setShowDeleteModal(false);

  useEffect(() => {
    getUsersList();
    return {};
  }, []);

  const getUsersList = () => {
    OrganisationService.getUsersList()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCheckIsDataLoadedOrNot(true);
          if (data.length === 0) {
            setUsersList([]);
          } else {
            setUsersList(data);
            setExistingUsersList(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const onSubmit = values => {
    toast.dismiss();

    if (values.email !== null || fileData !== null) {
      let data = {
        csvemail: null,
        csvfilebase64: null,
      };
      if (checkFileList === false) {
        data.csvemail = values.email;
        data.csvfilebase64 = null;
      } else if (checkFileList === true) {
        data.csvemail = null;
        data.csvfilebase64 = fileData;
      }

      OrganisationService.uploadEmployeesFileData(data)
        .then(response => {
          if (response) {
            Toast('Your invitation has been sent successfully', 'success');
            handleClose();
            getUsersList();
          }
        })
        .catch(error => {
          if (error) {
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (error) {
              let errorResponse = error.response['data'];
              console.log('error response field errors ', errorResponse);
            }
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors?.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  Toast(msg.message, 'error');
                });
              }
            }
          }
        });
    } else {
      Toast('Either text csv or file csv must be submitted', 'error');
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const getusersListFile = e => {
    setFileName(e.target.files[0].name);
    let base64Data = '';
    getBase64(e.target.files[0], result => {
      base64Data = result.split(',')[1];
      setFileData(base64Data);
    });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  const getUserData = (id, index) => {
    setUserIndex(index);
    setEditModalId(id);
    OrganisationService.getUser(id).then(response => {
      if (response) {
        let data = response['data'];
        handleEditModalShow();

        formik.setFieldValue('firstName', data.firstName);
        formik.setFieldValue('lastName', data.lastName);
        formik.setFieldValue('email', data.email);
        formik.setFieldValue('phone', data.phone);
      }
    });
  };

  const deleteEmployeeData = index => {
    console.log('---index----', index);
    showDeleteModalHandler();
    setSelectedUserIndex(index);
  };

  const handleChange = value => {
    setSearchText(value);
    searchEmployee(value);
  };

  const searchEmployee = value => {
    setUsersList(existingUsersList);

    const excludeColumns = ['lastLoginOn'];

    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setUsersList(existingUsersList);
    else {
      const filteredData = existingUsersList.filter(item => {
        return Object.keys(item).some(key =>
          item[key] !== null
            ? excludeColumns.includes(key)
              ? false
              : item[key].toString().toLowerCase().includes(lowercasedValue)
            : null
        );
      });
      setUsersList(filteredData);
    }
  };

  const modalDeleteUserBtnHandler = () => {
    let id = usersList[selectedUserIndex].id;
    OrganisationService.deleteUser(id)
      .then(response => {
        if (response) {
          if (usersList[selectedUserIndex].firstName !== null && usersList[selectedUserIndex].lastName !== null) {
            Toast(
              `${usersList[selectedUserIndex].firstName} ${usersList[selectedUserIndex].lastName} has been removed from your organisation`,
              'success'
            );
          } else {
            Toast(`User has been removed from your organisation`, 'success');
          }
          hideDeleteModalHandler();
          getUsersList();
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const handleStarClick = index => {
    const updatedStarSelect = [...starSelect];
    updatedStarSelect[index] = !updatedStarSelect[index];
    setStarSelect(updatedStarSelect);
  };

  const resendInviteBtnHandler = index => {
    let data = usersList[index];
    OrganisationService.resendInvite(data)
      .then(response => {
        if (response) {
          setDisableResendInviteBtn(true);
          Toast(`We’ve resent the link to the provided email address.`, 'success');
          setTimeout(() => {
            setDisableResendInviteBtn(false);
          }, 50000);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // const navigateToAllocatePage = index => {
  //   history.push(`/organisation/admin/allocate/recipientInformation?userId=${usersList[index]?.id}`);
  // };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const checkStoreListDataAvailaleOrNot = () => {
    return (
      <div className="contact-background">
        <div className="p-0 m-0 row mt-4 my-2 mx-4 px-2 d-flex justify-content-between">
          <span className="font-sofia-pro-light font-24 color-black-pearl">Contacts</span>
          <div className="d-flex">
            {windowWidth <= 767 ? (
              <div className="input-group input-group-sm mt-3 input-group-contact">
                <div className="input-group-prepend employee-search__icon">
                  <div className="pl-2">
                    <SearchIcon />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Search users"
                  className="mr-2 h-48 form-control form-input-placeholder border-0 bg-white employee-search__input shadow-none"
                  value={searchText}
                  onChange={e => handleChange(e.target.value)}
                />
              </div>
            ) : (
              <div className="input-group input-group-sm">
                <div className="input-group-prepend employee-search__icon">
                  <div className="pl-2">
                    <SearchIcon />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Search users"
                  className="mr-2 h-48 form-control form-input-placeholder border-0 bg-white employee-search__input shadow-none"
                  value={searchText}
                  onChange={e => handleChange(e.target.value)}
                />
              </div>
            )}
            {windowWidth <= 767 ? (
              <button
                className="d-flex justify-content-center align-items-center mt-3 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 shadow-none add-contact-mobile"
                onClick={handleShow}
              >
                <span>+</span>
              </button>
            ) : (
              <button
                className="d-flex justify-content-center align-items-center btn w-100 bg-primary-color color-white font-sofia-pro-semi-bold font-16 shadow-none"
                onClick={handleShow}
              >
                <span>+ Add new contact</span>
              </button>
            )}
          </div>
        </div>

        {windowWidth <= 767 ? (
          // Mobile layout
          usersList?.length > 0 &&
          checkIsDataLoadedOrNot === true &&
          usersList.map((user, index) => (
            <div className="" key={index}>
              <div className="card card-contact">
                <div className="card-body">
                  <div className="user-card-headers">
                    <div className="d-flex align-items-center">
                      <div className="contact-details" style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                        <div className="">
                          {starSelect[index] ? (
                            <StarFilled className="cursor-pointer" onClick={() => handleStarClick(index)} />
                          ) : (
                            <Star className="cursor-pointer" onClick={() => handleStarClick(index)} />
                          )}
                        </div>
                        <div className="d-flex">
                          {user.profilePhotoPath === null ? (
                            <ProfilePic className="rounded-circle dashboard_oval mb-4" />
                          ) : (
                            <img src={user.profilePhotoPath + '?h=80&w=80'} className="rounded-circle dashboard_oval" />
                          )}
                          <div className="pl-2 d-flex flex-column">
                            <h5 className="font-sofia-pro-regular font-14  card-title">
                              {user.firstName} {user.lastName}
                              <p className="font-sofia-pro-regular font-12 color-grey-blue mt-1 card-title">{user.email}</p>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex flex-column align-items-end contact-card-icons"
                        style={{ gap: '15px', marginLeft: 'auto' }}
                      >
                        <EditUser
                          className="cursor-pointer"
                          style={{ width: '16px', height: '16px' }}
                          onClick={() => getUserData(user.id, index)}
                        />
                        <DeleteUser
                          className="cursor-pointer mt-2"
                          style={{ width: '16px', height: '16px' }}
                          onClick={() => deleteEmployeeData(index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="my-2 row">
            <div class="col"></div>
            <div className="col-11 card dashboard-card__redeemthanks my-3 table-responsive border-0 ">
              <div className="row">
                <div className="table-responsive">
                  <table className="table col-12" style={{ height: '100%' }}>
                    <thead>
                      <tr className="mx-2">
                        <td scope="col" className="font-sofia-pro-regular table_headings">
                          <div className="d-flex">
                            <input
                              type="checkbox"
                              id={`checkbox-all`}
                              style={{ accentColor: '#E10177' }}
                              checked={selectAllChecked}
                              onChange={handleSelectAll}
                            />
                            <div className="ml-2 font-14 font-sofia-pro">
                              {selectedRows.length > 0 ? `${selectedRows.length} items selected` : ''}
                            </div>
                            {selectedRows.length === 0 && <div style={{ marginLeft: '65px' }}>Name</div>}
                          </div>
                        </td>
                        {!selectedRows.length > 0 && (
                          <td scope="col" className="font-sofia-pro-regular table_headings">
                            Email
                          </td>
                        )}

                        <td scope="col"></td>

                        {selectedRows.length > 0 && (
                          <td scope="col" className=" d-flex justify-content-end table_headings">
                            <button
                              className={`font-14 font-sofia-pro trash-btn ${isHovered ? 'hovered' : ''}`}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <span>{isHovered ? <TrashFilled /> : <Trash />}</span>
                                <span>Move to trash</span>
                              </div>
                            </button>
                          </td>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {usersList?.length > 0 &&
                        checkIsDataLoadedOrNot === true &&
                        usersList.map((user, index) => {
                          const isChecked = selectedRows.includes(index);
                          return (
                            <tr key={index}>
                              <td className="font-sofia-pro-regular font-14 color-black-pearl">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '30px' }}>
                                      <div style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
                                        <input
                                          type="checkbox"
                                          id={`checkbox-${index}`}
                                          style={{ accentColor: '#E10177' }}
                                          checked={isChecked}
                                          onChange={() => handleRowSelect(index)}
                                        />
                                        <div className="mt-1">
                                          {starSelect[index] ? (
                                            <StarFilled
                                              className="cursor-pointer"
                                              onClick={() => handleStarClick(index)}
                                            />
                                          ) : (
                                            <Star className="cursor-pointer" onClick={() => handleStarClick(index)} />
                                          )}
                                        </div>
                                      </div>

                                      {user['profilePhotoPath'] === null ? (
                                        <ProfilePic className="rounded-circle dashboard_oval" />
                                      ) : (
                                        <img
                                          src={user['profilePhotoPath'] + '?h=80&w=80'}
                                          className="rounded-circle dashboard_oval"
                                        />
                                      )}
                                    </div>
                                    <div className="pl-2 d-flex flex-column">
                                      <span
                                        data-tip={`${user.firstName}{user.lastName}`}
                                        data-for="userFirstname"
                                        className="d-block"
                                      >
                                        {user.firstName} {user.lastName}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td className="font-sofia-pro-regular font-14 color-black-pearl">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column">
                                      <span className="font-sofia-pro-regular font-14 color-black-pearl">
                                        {user.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="d-flex justify-content-end">
                                  <EditUser
                                    className="cursor-pointer"
                                    onClick={getUserData.bind(this, user.id, index)}
                                  />
                                  <DeleteUser
                                    className="cursor-pointer ml-2"
                                    onClick={deleteEmployeeData.bind(this, index)}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div class="col"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className=" m-0 p-0 row ">
      {/* <div className="p-0 m-0  border-0 col-lg-2 col-sm-12  my-4 d-flex  flex-column ">
        <OrganisationSidebar menu="Organisation" />
      </div> */}
      <div className="p-0 m-0 card border-0  col-lg-2 col-sm-12 my-4  ">
        <SideBar menu="Contacts" />
      </div>
      {windowWidth <= 767 ? (
        <>
          <div className="notification-bell">
            <OrganisationHeaderMobile role="admin" />
          </div>
          <div className="d-flex flex-column flex-end">{checkStoreListDataAvailaleOrNot()}</div>
        </>
      ) : (
        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh">
          <OrganisationHeader role="admin" />
          {checkStoreListDataAvailaleOrNot()}
        </div>
      )}
      <Modal
        className="pt-4 border-0 modal-background-shadow contactss-modal"
        size="xs"
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <div className="d-flex flex-column justify-content-center mt-4 contact-modal-body">
          <div className="position-relative">
            <button type="button" className="border-0 position-absolute top-0 cancel-btn" onClick={handleClose}>
              <Close />
            </button>
            <div className="font-sofia-pro font-24 contact-heading">New Contact</div>
          </div>

          <div className="modal-body">
            <div className="contact-body-container">
              <div className="col-md-6 contact-name">
                <div className="mb-3">
                  <label htmlFor="firstName" className="font-sofia-pro font-14 contact">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="contact-modal-input"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label htmlFor="lastName" className="font-sofia-pro font-14 contact">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="contact-modal-input"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3 d-flex flex-column">
                  <label htmlFor="email" className="font-sofia-pro font-14 form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="contact-modal"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="mb-3">
                  <label htmlFor="mobile" className="font-sofia-pro font-14 form-label">
                    Mobile Number
                  </label>
                  <input
                    type="text"
                    className="contact-modal"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="d-flex justify-content-between w-100">
                <button
                  className="my-2 shadow-none d-flex justify-content-center align-items-center h-60 btn  bg-transparent font-sofia-pro font-16 btn-cancel-footer footer-btns"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <Button
                  classNameValue=" my-2 shadow-none d-flex justify-content-center align-items-center h-60 btn  bg-primary-color font-sofia-pro font-16 text-white btn_cancel_invite btn-add-footer footer-btns"
                  children="Add Contact"
                  showLoader={showLoader}
                  onClick={handleClose}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="w-100 pt-4 border-0 modal-background-shadow employee-modal"
        backdrop="static"
        size="md"
        show={editModalShow}
        onHide={handleEditModalClose}
        animation={false}
      >
        <div className="position-relative">
          <Logo className="d-flex justify-content-center position-absolute" style={{ width: '100%', top: '10px' }} />
          <button
            type="button"
            className="border-0 position-absolute top-0"
            style={{ background: 'transparent', top: '10px', right: '10px' }}
            onClick={handleEditModalClose}
          >
            <Close />
          </button>
        </div>
        <div className="modal-inner">
          <Modal.Header className="p-0 m-0 px-3 pt-5 pb-2 border-0 d-flex justify-content-center align-items-center">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold text-center">Edit employee</span>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
            <EditUserPage
              id={editModalId}
              closeModal={handleEditModalClose}
              loadUsersList={getUsersList}
              deleteEmployeeData={deleteEmployeeData}
              index={userIndex}
            />
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        className="pt-4 border-0
          modal-background-shadow employee-modal"
        size="lg"
        show={showDeleteModal}
        backdrop="static"
        onHide={hideDeleteModalHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              Are you sure you want to remove {usersList[selectedUserIndex]?.firstName}{' '}
              {usersList[selectedUserIndex]?.lastName} from your organisation?
            </span>
            <span className="font-14 font-sofia-pro-light color-black-pearl">
              {usersList[selectedUserIndex]?.firstName} {usersList[selectedUserIndex]?.lastName} will no longer have
              access to any of the products or services on the platform
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={hideDeleteModalHandler}
          >
            <DeleteClose />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={() => {
                modalDeleteUserBtnHandler();
                handleEditModalClose();
              }}
            >
              Remove
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={hideDeleteModalHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
        show={showSampleFormat}
        onHide={handleCloseSampleFormat}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 border-0">
          <button
            type="button"
            className="close switch-to-account-modal__close__icon"
            onClick={handleCloseSampleFormat}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-1 pb-5 border-0 text-center">
          <h4 className="p-2 font-sofia-pro-regular color-black-pearl">Sample CSV format</h4>
          <div className="mt-2">
            <img src={SampleCSVFormatImage} className="mw-100" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContactCommon;
