/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';
// Onboarding
const createOrganisation = data => {
  return httpService.post('/Companies', data);
};

const getOrganisationDetails = () => {
  return httpService.get('/Companies/current');
};

const updateOrganisation = data => {
  return httpService.put('/Companies', data);
};

const uploadEmployeesFileData = data => {
  return httpService.post('/companies/current/users/bulk', data);
};

// Manage users
const getUsersList = () => {
  return httpService.get('/companies/current/users');
};

const getUser = id => {
  return httpService.get('/companies/current/users/' + id);
};

const updateUser = (id, data) => {
  return httpService.put('/companies/current/users/' + id, data);
};

const deleteUser = id => {
  return httpService.delete('/companies/current/users/' + id);
};

const resendInvite = data => {
  return httpService.post('/companies/current/users', data);
};

// Credits
const getCreditsBalance = () => {
  return httpService.get('/companies/current/credits');
};

const allocateCredits = (id, data) => {
  return httpService.post('/companies/current/credits/users/' + id, data);
};

// Manage credit budget
const manageCreditBudget = (data) => {
  return httpService.put('companies/current/credits/budget', data)
}

//Milestones
const getMilestones = () => {
  return httpService.get('/companies/current/Milestones');
}

const getMilestoneById = (id) => {
  return httpService.get('/companies/current/Milestones', + id);
}
const getAccessKey = () => {
  return httpService.get('/Users/current');
}

const createMilestone = (data) => {
  return httpService.post('/companies/current/Milestones', data);
}

const deleteMilestone = id => {
  return httpService.delete('/companies/current/Milestones/' + id);
};

const updateMilestone = (id, data) => {
  return httpService.patch('/companies/current/Milestones/' + id, data);
}

const updateBulkEmployees = (id, data) => {
  return httpService.post(`/companies/current/Milestones/${id}/employees`, data);
}

export default {
  createOrganisation,
  uploadEmployeesFileData,
  getOrganisationDetails,
  updateOrganisation,
  getUsersList,
  getAccessKey,
  getUser,
  updateUser,
  deleteUser,
  resendInvite,
  getCreditsBalance,
  allocateCredits,
  manageCreditBudget,
  getMilestones,
  createMilestone,
  deleteMilestone,
  updateBulkEmployees,
  getMilestoneById,
  updateMilestone,
};
