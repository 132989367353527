/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { Router, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as RegexPattern from '../../../../../utils/RegexPatterns';
import http from '../../../../../services/HttpService';
import BottomBar from '../BottomBar';
import SendThanksService from '../../../../../services/SendThanksService';
import LocationService from '../../../../../services/LocationService';
import AccountDetailsService from '../../../../../services/AccountDetailsServices';
import Button from '../../../../../common/Button/Button';
import './RedeemNow.scss';
import { ReactComponent as Logo } from '../../../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Navigation } from '../../../../../assets/images/redeem_thanks/icons/icons-location.svg';
import Tick from '../../../../../assets/images/redeem_thanks/image/icons-check-filled-white.png';
import ErrorImage from '../../../../../assets/images/error-icons/icons-error-info.png';
import IconsBackImage from '../../../../../assets/images/common/icons-back.png';

const RedeemNow = props => {
  const httpService = http;
  const [product, setProduct] = useState([]);
  const [productId, setProductId] = useState(null);
  const [select, setSelect] = useState(false);
  const [wineType, setWineType] = useState('');
  const [selectedProductOption, setSelectedProductOption] = useState();
  const [addressSelect, setAddressSelect] = useState(false);
  const [address, setAddress] = useState();
  const [account, setAccount] = useState([]);
  const TransactionId = sessionStorage.getItem('TransactionId');
  const history = useHistory();
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);
  const [indexValue, setIndexValue] = useState('');
  const SelectedQuantity = sessionStorage.getItem('selectQuantity');
  const initialValues = {
    fullname: null,
    email: null,
    mobile: null,
    street: null,
    suburb: null,
    city: null,
    state: null,
    postcode: null,
    country: null,
  };
  let city, state, country, postCode, street, street_number, suburb;
  toast.configure({
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

  let autoComplete = [];
  // used load script function for address auto completion
  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.setComponentRestrictions({
      country: ['au'],
    });
    autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
  }

  function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setAddress(autoComplete.getPlace());
    setAddressSelect(true);
  }

  // spliting address based on it limit
  if (address !== undefined) {
    for (let i = 0; i < address.address_components?.length; i++) {
      for (let j = 0; j < address.address_components[i].types.length; j++) {
        switch (address.address_components[i].types[j]) {
          case 'street_number':
            street_number = address.address_components[i].long_name ? address.address_components[i].long_name : null;
            break;
          case 'locality':
            suburb = address.address_components[i].long_name ? address.address_components[i].long_name : null;
            break;
          case 'administrative_area_level_1':
            state = address.address_components[i].long_name ? address.address_components[i].long_name : null;
            break;
          case 'country':
            country = address.address_components[i].long_name ? address.address_components[i].short_name : null;
            break;
          case 'postal_code':
            postCode = address.address_components[i].long_name ? address.address_components[i].long_name : null;
            break;
          case 'route':
            street = address.address_components[i].long_name ? address.address_components[i].long_name : null;
            break;
          case 'administrative_area_level_2':
            city = address.address_components[i].long_name ? address.address_components[i].long_name : null;
            break;
        }
      }
    }
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, [query]);

  useEffect(() => {
    AccountDetailsService.GetACCOuntDetails().then(res => {
      setAccount(res.data);
      formik.values.email = res.data['email'];
      formik.values.mobile = res.data['phone'];
    });
  }, []);
  useEffect(() => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        res.data.map((val, index) => {
          if (val['type'] === 'Wine') {
            setProduct(val['ranges']);
          }
        });
      })
      .catch(err => {});
  }, []);

  product.map((val, index) => {
    product[index]['selected'] = select;
  });
  const SelectWine = index => {
    setSelectedProductOption(index);
    setWineType(product[index]['name']);
    setProductId(product[index]['id']);
    setIndexValue(index);
    setSelect(true);
    sessionStorage.setItem(
      'Wine Type',
      JSON.stringify({
        Url: product[index]['imagePath'],
        Name: product[index]['name'],
      })
    );
  };

  const validate = values => {
    const errors = {};
    let re = RegexPattern.EmailRegexValue;
    let ph = RegexPattern.MobileRegexValue;
    if (!values.fullname) {
      errors.fullname = 'Required';
    } else if (values.fullname.length > 15) {
      errors.fullname = 'Must be 15 characters or less';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!re.test(values.email)) {
      errors.email = 'Invalid email format';
    }

    if (!ph.test(values.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }
    if (!values.address) {
      errors.address = 'Required';
    }
    if (!values.postcode) {
      errors.postcode = 'Required';
    }
    if (!values.city) {
      errors.city = 'Required';
    }
    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.suburb) {
      errors.suburb = 'Required';
    }
    if (!values.street) {
      errors.street = 'Required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
  });

  if (address !== undefined && addressSelect !== true) {
    formik.values.street = LocationService.getLocationAddress(address).street;
    formik.values.suburb = LocationService.getLocationAddress(address).city;
    formik.values.state = LocationService.getLocationAddress(address).state;
    formik.values.postcode = LocationService.getLocationAddress(address).postCode;
  }

  useEffect(() => {
    if (account['email'] !== null && account['email'] !== undefined) {
      formik.values.email = account['email'].trim();
    }
    if (account['phone'] !== null && account['phone'] !== undefined) {
      formik.values.mobile = account['phone'].trim();
    }
    if (account['fullName'] !== null && account['fullName'] !== undefined) {
      formik.values.fullname = account['fullName'].trim();
    }
  }, []);

  let Address = {
    quantity: SelectedQuantity,
    rangeId: productId,
    delivery: {
      email: formik.values.email,
      phone: formik.values.mobile,
      address: {
        street: formik.values.street,
        suburb: formik.values.suburb,
        state: formik.values.state,
        postcode: formik.values.postcode,
        country: 'Australia',
        region: 'AU',
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const SubmitData = () => {
    let data = Address;
    if (formik.values.email !== null && formik.values.mobile !== null) {
      if (
        productId &&
        formik.values.postcode &&
        formik.values.street &&
        formik.values.suburb &&
        formik.values.state != null &&
        formik.values.mobile.trim().length > 0 &&
        formik.values.mobile.trim().length > 0
      ) {
        setShowLoader(true);
        httpService
          .post(`Gifts/${TransactionId}/redeem`, data)
          .then(res => {
            setShowLoader(false);
            history.push('./redeemedwine');
            window.scrollTo(0, 0);
          })
          .catch(error => {
            setShowLoader(false);
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            let errorResponse = error.response['data'];
            if (errorResponse.globalErrors[0]) {
              if (errorResponseGlobalErrors.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  toast.error(msg.message);
                });
              }
            } else if (errorResponse.fieldErrors) {
              if (errorResponse.fieldErrors['delivery.Phone']) {
                toast.error(errorResponse.fieldErrors['delivery.Phone'][0]);
              } else if (errorResponse.fieldErrors['delivery.Email']) {
                toast.error(errorResponse.fieldErrors['delivery.Email'][0]);
              }
            }
          });
      } else if (formik.values.email === '' && account['email'] === null) {
        toast.error('Email is required');
      } else if (formik.values.mobile === '' && account['phone'] === null) {
        toast.error('Phone number is required');
      } else if (formik.values.mobile.trim().length === 0) {
        toast.error('Phone number is required');
      } else if (formik.values.email.trim().length === 0) {
        toast.error('Email is required');
      } else if (productId === null) {
        toast.error('Please select a product');
      } else if (formik.values.postcode === null || formik.values.postcode === undefined) {
        toast.error('Please add postcode');
      } else if (formik.values.suburb === null || formik.values.suburb === undefined) {
        toast.error('Please add suburb');
      } else if (formik.values.state === null || formik.values.state === undefined) {
        toast.error('Please add state');
      } else if (formik.values.street === null || formik.values.street === undefined) {
        toast.error('Please add street');
      }
    } else if (formik.values.email === null) {
      toast.error('Email is required');
    } else if (formik.values.mobile === null) {
      toast.error('Phone number is required');
    }
  };

  return (
    <div className="d-flex flex-column align-items-center my-2 ">
      <Logo />
      <div>
        <div className="d-flex">
          <Link to="/redeemwine" style={{ textDecoration: 'none' }}>
            <span className="p-2 m-4 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="redeemnow-rectangle__small d-flex align-items-center my-2">
          <span className="redeemnow-text__Step-1-Select-your font-sofia-pro-regular mx-3">
            <span className="text-style-1">Step 1:</span> Select your Wine
          </span>
        </div>
      </div>
      <div className="d-flex flex-column align-items-end">
        {product.map((value, index) => {
          return (
            <div key={index} className="d-flex align-items-center">
              <button className="border-0 bg-transparent my-1 d-flex " onClick={SelectWine.bind(this, index)}>
                <div className={select === true ? 'd-flex flex-row redeemnow-image__buttonwine' : 'd-flex flex-row'}>
                  {select === true && indexValue === index ? (
                    <div>
                      <img src={Tick} className="redeemnow-image__Tick" />
                      <img
                        src={value['imagePath'] + '?h=120&w=120'}
                        className="redeemnow-image__ComponentsWineImageShiraz my-lg-0 my-5"
                        style={{ backgroundColor: value['color'] }}
                      />
                    </div>
                  ) : (
                    <img
                      src={value['imagePath'] + '?h=120&w=120'}
                      className="redeemnow-image__ComponentsWineImageShirazempty my-lg-0 my-5"
                    />
                  )}

                  <div
                    className={
                      select === true && indexValue === index
                        ? `redeemnow-card__shirazselected `
                        : 'redeemnow-card__winetype border'
                    }
                    style={
                      select === true && indexValue === index
                        ? { border: 'solid', borderColor: value['color'] }
                        : { border: 'solid', borderColor: '' }
                    }
                  >
                    <div className="d-flex flex-column m-3">
                      <span className="font-sofia-pro-semi-bold redeemnow-text__Shiraz d-flex  ">{value['name']}</span>
                      <span className="redeemnow-text__A-long-even-growing  font-sofia-pro-light  m-1">
                        {value['description']}
                      </span>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          );
        })}
        <a href="https://thehiddensea.com/" target="_blank" style={{ textDecoration: 'none' }}>
          <span className="redeemnow-text__More-information font-sofia-pro-regular ">More information</span>
        </a>
      </div>

      <div>
        <div className="redeemnow-rectangle__small d-flex align-items-center my-2">
          <span className="redeemnow-text__Step-1-Select-your font-sofia-pro-regular mx-3">
            <span className="text-style-1">Step 2:</span> Enter your details
          </span>
        </div>
        <span className="font-sofia-pro-medium redeemnow-text__Personal-details">Personal details</span>
      </div>

      <div className="d-flex flex-column  my-2">
        <label className="redeemnow-text__fullname font-sofia-pro-regular">Full name</label>
        {account['fullName'] === null || '' ? (
          <input
            placeholder="Enter your full name "
            className="redeemnow-input p-3"
            name="fullname"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.fullname}
            autoComplete="off"
          />
        ) : (
          <input
            placeholder="Enter your full name "
            className="redeemnow-input p-3"
            name="fullname"
            type="text"
            value={account['fullName']}
            autoComplete="off"
          />
        )}

        {formik.touched.fullname && formik.errors.fullname ? (
          <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
            <img className="mr-1" src={ErrorImage} /> {formik.errors.fullname}
          </div>
        ) : null}
      </div>

      <div className="d-flex flex-column my-2">
        <label className="redeemnow-text__fullname font-sofia-pro-regular">Email address</label>

        <input
          placeholder="Eg: hello@sanjaytarani.com "
          className="redeemnow-input p-3"
          type="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          defaultValue={account['email']}
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
            <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
          </div>
        ) : null}
      </div>
      <div className="d-flex flex-column  my-2">
        <label className="redeemnow-text__fullname font-sofia-pro-regular">Mobile number</label>

        <input
          placeholder="Eg: 0478983456  "
          className="redeemnow-input p-3"
          type="mobile"
          name="mobile"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          defaultValue={account['phone']}
          autoComplete="off"
        />

        {formik.touched.mobile && formik.values.mobile?.length != 0 && formik.errors.mobile ? (
          <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
            <img className="mr-1" src={ErrorImage} /> {formik.errors.mobile}
          </div>
        ) : null}
        <span className="redeemnow-text__Delivery-details my-2 font-sofia-pro-medium">Delivery details</span>
      </div>
      {addressSelect === false ? (
        <div className="d-flex flex-column align-items-start ">
          <label className="redeemnow-text__fullname font-sofia-pro-regular ">Enter delivery address</label>
          <div className="d-flex flex-column align-items-end">
            <div className="redeemnow-input d-flex align-items-center justify-content-around ">
              <div className="w-100">
                <div className="search-location-input">
                  <input
                    ref={autoCompleteRef}
                    name="query"
                    onChange={e => setQuery(e.target.value)}
                    placeholder="Eg: 6, The Corso Manly, Sydney, NSW, 2122"
                    value={query}
                    className="border-0 redeemnow-input__width mx-2"
                    autoComplete="off"
                  />
                </div>
              </div>
              <Navigation className="redeemnow-button__navigation" />
            </div>

            <button
              className="redeemnow-button__entermanualy my-3 "
              onClick={() => {
                setAddressSelect(!addressSelect);
              }}
            >
              <span className="redeemnow-text__enterman font-sofia-pro-semi-bold  ">Enter manually</span>
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-column align-items-start my-2">
            <label className="redeemnow-text__fullname font-sofia-pro-regular">Street name</label>
            <input
              type="text"
              placeholder="Eg: The Corso  "
              className="redeemnow-input p-3"
              name="street"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.street}
              autoComplete="off"
            />
          </div>
          <div className="d-flex flex-column align-items-start my-2">
            <label className="redeemnow-text__fullname font-sofia-pro-regular">Suburb</label>
            <input
              placeholder="Eg: Manly "
              className="redeemnow-input p-3"
              type="text"
              name="suburb"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.suburb}
              autoComplete="off"
            />
          </div>
          <div className="d-flex flex-column align-items-start my-2">
            {/* <label className="redeemnow-text__fullname font-sofia-pro-regular">
              City
            </label>
            <input
              placeholder="Eg: Sydney "
              className="redeemnow-input p-3"
              type="text"
              name="city"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
            /> */}
            {/* {formik.touched.city && formik.errors.city ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.city}
              </div>
            ) : null} */}
          </div>
          <div className="d-flex flex-column align-items-start my-2">
            <label className="redeemnow-text__fullname font-sofia-pro-regular">State / Province / Region</label>
            <input
              placeholder="Eg: NSW "
              className="redeemnow-input p-3"
              type="text"
              name="state"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.state}
              autoComplete="off"
            />
          </div>
          <div className="d-flex flex-column align-items-end">
            <div className="d-flex flex-column align-items-start">
              <label className="redeemnow-text__fullname font-sofia-pro-regular">Postcode</label>
              <input
                placeholder="Eg: 2122"
                className="redeemnow-input p-3"
                type="text"
                name="postcode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postcode}
                autoComplete="off"
              />
            </div>
            <button
              className="redeemnow-button__entermanualy my-3 "
              onClick={() => {
                setAddressSelect(!addressSelect);
              }}
            >
              <span className="redeemnow-text__enterman font-sofia-pro-semi-bold  ">Find address</span>
            </button>
          </div>
        </div>
      )}
      <div className="my-5">
        <div className="d-flex">
          {/* <button
            className="redeemnow-button__submit border-0"
            onClick={SubmitData}
             showLoader={showLoader}
          >
            <span className="redeemnow-text__submit font-sofia-pro-semi-bold ">
              Submit
            </span>
          </button> */}

          <Button
            classNameValue="   redeemnow-button__submit border-0 bg-primary-color color-white"
            showLoader={showLoader}
            onClick={SubmitData}
          >
            <span className="redeemnow-text__submit font-sofia-pro-semi-bold">Submit</span>
          </Button>
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default RedeemNow;
