import useWindowDimensions from '../../Responsive/WindowDimensions';
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';

const ModalVideoAudio = ({ audioVideoUrl, setAudioVideoUrl, onHide, show }) => {
  const { width } = useWindowDimensions();
  const [loading, setLoading]= useState(true)

  useEffect(()=>{
    if (audioVideoUrl){
      setLoading(false)
    }
  },[audioVideoUrl])

  const closeModal = () =>{
    onHide()
    setAudioVideoUrl(null)
  }

  return (
    <Modal
      onHide={onHide}
      show={show}
      size="md"
      centered
      className="border-0 redeem-background_popup"
      animation={false}
      backdrop="static"
    >
      <Modal.Body className="my-3">
        <button className="border-0 bg-transparent redeemthanks-cancel__button " onClick={closeModal}>
          <Close />
        </button>
        <div className="p-0 m-0 px-3 pb-2 border-0 text-center ">
          {loading ? <Spinner animation="border" /> :
              audioVideoUrl?.fileCategory === 'Audio' && (
                <audio controls preload="none">
                  <source src={audioVideoUrl?.url} type="audio/mpeg" />
                </audio>
              )}
            {audioVideoUrl?.fileCategory === 'Video' &&
            (width > 500 ? (
                <video width="320" height="240" controls>
                  <source src={audioVideoUrl?.url} type="video/mp4" />
                </video>
            ) : (
              <video width='250' height='240' controls>
                <source src={audioVideoUrl?.url} type='video/mp4' />
              </video>
            ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalVideoAudio;