/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import RedeemCoffeeService from '../../../../services/RedeemCoffeeService';
import Coffee from '../../../../assets/images/dashboard/image/invalid-name.png';
import httpService from '../../../../services/HttpService';
import SendThanksService from '../../../../services/SendThanksService';
import Add from '../../../../assets/images/redeem_thanks/icons/icons-add-pink.png';
import Skip from '../../../../assets/images/common/skip.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as Remove } from '../../../../assets/images/redeem_thanks/icons/icons-remove-16-px (1).svg';
import RedeemedModal from '../../../../common/component/modal/RedeemedModal';
import ButtonLoading from '../../../../common/Button/Button';
import { useHistory } from 'react-router-dom';

const RedeemModalAnonymous = props => {
  const history = useHistory();

  const [modalShow, setModalShow] = useState(false);
  const [priceId, setPriceId] = useState([]);
  const [quantityValue, setQuantityValue] = useState(1);

  const AvailableQuantity = props.AvailableQuantity;
  const LocationId = props.LocationId;
  const CoffeShopName = props.CoffeShopName;
  const [cofeeShow, setcofeeShow] = useState(false);

  toast.configure({
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });

  const RedeemedCoffe = () => {
    const payload = {
      LocationId,
      quantity: quantityValue
    }
    httpService
      .post(`Gifts/bycode/${props.code}/redeem`, payload)
      .then(res => {
          setcofeeShow(true);
      })
      .catch(error => {
        setModalShow(false);
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        let errorResponse = error.response['data'];
        if (errorResponse.globalErrors[0]) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        } else if (errorResponse.fieldErrors) {
          if (errorResponse.fieldErrors['delivery.Phone']) {
            toast.error(errorResponse.fieldErrors['delivery.Phone'][0]);
          } else if (errorResponse.fieldErrors['delivery.Email']) {
            toast.error(errorResponse.fieldErrors['delivery.Email'][0]);
          }
        }
      });
  };

  useEffect(() => {
    SendThanksService.getListOfProducts('AU').then(res => {
      res['data'].map(val => {
        if (val['type'] === 'Coffee') {
          setPriceId(val.priceId);
        }
      });
    });
  }, []);

  const UserExperience = rating => {
    RedeemCoffeeService.PostRatingAnonymous(rating, props.code)
      .then(res => {
        toast.success('Thank you for the rating');
        if (props.Path === 'RedeemTanksScreen') {
          setTimeout(window.location.reload(), 10000);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const MyVerticallyCenteredModal = props => {
    return (
      <Modal {...props} size='' centered className='redeem-background_popup' animation={false} backdrop='static'>
        <Modal.Header className='border-0 redeem-card__manlyguild'>
          <Modal.Title className=' '>
            <span className='dashboard-text_Manly-Guild-Cafe font-sofia-pro-medium  '>{CoffeShopName}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex flex-column redeem-modal__yesredeem '>
          <button className='border-0 redeem-yesredeem__cancel bg-transparent' onClick={props.onHide}>
            <Close />
          </button>
          <span className='dashboard-text__Are-you-at-the-count font-sofia-pro-medium my-3'>
            Are you at the counter?
          </span>

          <span className='dashboard-text__In-order-to-redeem-y font-sofia-pro-light'>
            In order to redeem your thanks you need to show the next screen to the {CoffeShopName}.
          </span>
          <div className='d-flex flex-row my-5 '>
            <button className='border-0 dashboard-button__yesredeem' onClick={RedeemedCoffe}>
              <span className='dashboard-text__yesredeem font-sofia-pro-semi-bold'>Yes, redeem</span>
            </button>
            <button className='border-0 dashboard-button_cancel ' onClick={props.onHide}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  const RedeemScreenRedirect = () => {
    setModalShow(false);
    setcofeeShow(false)
    history.push('/signup');
  };

  const GetCode = () => {
    const [skipCofeeShow, setskipCofeeShow] = useState(false);
    const [exchangeLoading, setExchangeLoading] = useState(false);
    const [getCode, setGetCode] = useState({
      redeemCode:null,
      redeemUrl: null
    });

    function getSkipCode(codeExchangeable) {
      httpService
        .get(`gifts/bycode/${codeExchangeable}`)
        .then(res => {
          if (!res.data.redeemCode && !res.data.redeemUrl){
            setTimeout(()=>{
              getSkipCode(codeExchangeable)
            },10000)
          }else{
            setGetCode({
              redeemCode: res.data.redeemCode,
              redeemUrl: res.data.redeemUrl,
            })
          }
          setskipCofeeShow(true);
        })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
    }

    const SkipRedeemedCoffe = () => {
      setExchangeLoading(true)
      let data = {
        priceId:  props.exchangePriceId,
        quantity: quantityValue
      };
      sessionStorage.setItem('SelectedQuantity', quantityValue);
      sessionStorage.setItem('LocationId', LocationId);

      httpService
        .post(`gifts/bycode/${props.code}/exchange`, data)
        .then(res => {
          setskipCofeeShow(true);
          getSkipCode(res.data)
          setExchangeLoading(false)
        })
        .catch(error => {
          setModalShow(false);
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
          setExchangeLoading(false)
        });
    };

    return (
      <>
        <ButtonLoading
          children={<span className='redeem-text__proceed font-sofia-pro-semi-bold'>Proceed</span>}
          showLoader={exchangeLoading}
          onClick={SkipRedeemedCoffe}
          classNameValue='border-0 color-white redeem-button__proceed my-3'
        />
        <RedeemedModal
          productType='skip'
          userType='anonymous'
          getCode={getCode}
          show={skipCofeeShow}
          onHide={() => setskipCofeeShow(false)}
          quantityValue={quantityValue}
          closeYes={RedeemScreenRedirect}
        />
      </>
    );
  };

  const ProceedBtn = () => {
    const [yesRedeemShow, setYesRedeemShow] = useState(false);
    return (
      <>
        <button
          className='border-0 redeem-button__proceed my-3'
          onClick={() => {
            setYesRedeemShow(true);
          }}
        >
          <span className='redeem-text__proceed font-sofia-pro-semi-bold'>Proceed</span>
        </button>

        <MyVerticallyCenteredModal show={yesRedeemShow} onHide={() => setYesRedeemShow(false)} />
      </>
    );
  };

  const SelectQuantity = props => {
    return (
      <Modal {...props} size='md' centered className='redeem-background_popup' animation={false} backdrop='static'>
        <Modal.Body className='d-flex flex-column align-items-center '>
          <button className='border-0 bg-transparent redeem-quantity__cancel ' onClick={props.onHide}>
            <Close />
          </button>
          <span className='redeem-text__How-many-coffees-wo font-sofia-pro-medium'>
            How many coffee’s would you like to redeem?
          </span>
          <div className='row justify-content-center my-4'>
            <button
              className='border-0 bg-transparent'
              onClick={() => (quantityValue > 1 ? setQuantityValue(quantityValue - 1) : '')}
            >
              <Remove />
            </button>
            <div className='redeem-box__selectedvalue d-flex justify-content-center align-items-center'>
              <span>{quantityValue}</span>
            </div>
            <button
              className='border-0 bg-transparent '
              onClick={() => (quantityValue < AvailableQuantity ? setQuantityValue(quantityValue + 1) : '')}
            >
              <img src={Add} />
            </button>
          </div>

          <ProceedBtn />
          <button className='border-0 bg-transparent' onClick={props.onHide}>
            <span className='redeem-text__Cancel font-sofia-pro-medium'>Cancel</span>
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  const SkipSelectQuantity = props => {
    return (
      <Modal {...props} size='md' centered className='redeem-background_popup' animation={false} backdrop='static'>
        <Modal.Body className='d-flex flex-column align-items-center '>
          <button className='border-0 bg-transparent redeem-quantity__cancel ' onClick={props.onHide}>
            <Close />
          </button>
          <img src={Skip} className='redeem-skip' />
          <span className='redeem-text__How-many-coffees-wo font-sofia-pro-medium'>
            How many coffee’s would you like to redeem?
          </span>
          <div className='row justify-content-center my-4'>
            <button
              className='border-0 bg-transparent'
              onClick={() => (quantityValue > 1 ? setQuantityValue(quantityValue - 1) : '')}
            >
              <Remove />
            </button>
            <div className='redeem-box__selectedvalue d-flex justify-content-center align-items-center'>
              <span>{quantityValue}</span>
            </div>
            <button
              className='border-0 bg-transparent '
              onClick={() => (quantityValue < AvailableQuantity ? setQuantityValue(quantityValue + 1) : '')}
            >
              <img src={Add} />
            </button>
          </div>

          <GetCode onHide={props.onHide} />
          <button className='border-0 bg-transparent' onClick={props.onHide}>
            <span className='redeem-text__Cancel font-sofia-pro-medium'>Cancel</span>
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      {props.menu === 'availablelocation' ? (
        <div>
          <button
            className='dashboard-button_select border-0 dashboard-button'
            onClick={() => {
              setModalShow(true);
            }}
          >
            <span className='dashboard-text_select font-sofia-pro-semi-bold'>Select</span>
          </button>
          <SelectQuantity show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      ) : props.menu === 'redeemthanks' ? (
        <div>
          <button
            className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
            onClick={() => {
              setModalShow(true);
            }}
          >
            <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
          </button>
          <SelectQuantity show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      ) : props.menu === 'skip' ? (
        <>
          <button
            className="dashboard-button_select border-0 dashboard-button"
            onClick={() => {
              setModalShow(true);
            }}
          >
            <span className='dashboard-text_select font-sofia-pro-semi-bold'>Select</span>
          </button>
          <SkipSelectQuantity show={modalShow} onHide={() => setModalShow(false)} />
        </>
      ) : (
        ''
      )}
      <RedeemedModal
        show={cofeeShow}
        onHide={() => setcofeeShow(false)}
        quantityValue={quantityValue}
        closeYes={RedeemScreenRedirect}
        UserExperience={UserExperience}
        CoffeShopName={CoffeShopName}
      />
    </div>
  );
};

export default RedeemModalAnonymous;
