/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import SendThanksService from '../../../../services/SendThanksService';
import httpService from '../../../../services/HttpService';
import * as RegexPattern from '../../../../utils/RegexPatterns';
import { connect } from 'react-redux';
import { resetSendThanksDetails } from '../../../../store/reducers/individual/sendThanks/sendThanks';
import './AnonThanksSuccess.scss';
import AnonymousLayout from '../../../../common/component/anonymouslayout/AnonymousLayout';
import { ReactComponent as Step1 } from '../../../../assets/images/dashboard/icons/step-4.svg';
import AnonThanksSuccess from '../../../../assets/images/dashboard/icons/anon-thanks-success-icon.svg';
function ThanksSuccessPage(props) {
  const recieverEmail = props?.location?.state?.recieverEmail;

  let userDetails = props?.userDetails;

  let role = props.type;

  const history = useHistory();

  const [userInfo, setUserInfo] = useState();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // localStorage.clear();
    sessionStorage.clear();
  }, []);

  // useEffect(() => {
  //   let searchKeyword =
  //     userDetails?.recipients[0]?.userName === null
  //       ? userDetails?.recipients[0]?.email !== null
  //         ? userDetails?.recipients[0]?.email
  //         : userDetails?.recipients[0]?.phone
  //       : userDetails?.recipients[0]?.userName;
  //   try {
  //     let regexEmail = RegexPattern.EmailRegexValue;
  //     let regexMobile = RegexPattern.MobileRegexValue;

  //     let url = '';

  //     let data = {
  //       search: '',
  //       email: '',
  //       phone: '',
  //       userName: '',
  //       region: 'AU',
  //     };

  //     if (regexEmail.test(searchKeyword)) {
  //       data.email = searchKeyword;
  //       data.search = '';
  //       data.phone = '';
  //       data.userName = '';
  //       url = httpService.post(`/users/search`, data);
  //     } else if (regexMobile.test(searchKeyword)) {
  //       data.email = '';
  //       data.search = '';
  //       data.phone = searchKeyword;
  //       data.userName = '';
  //       url = httpService.post(`/users/search`, data);
  //     } else if (userDetails?.recipients[0]?.userName !== null && userDetails?.recipients[0]?.userName !== undefined) {
  //       data.email = '';
  //       data.search = '';
  //       data.phone = '';
  //       data.userName = searchKeyword;
  //       url = httpService.post(`/users/search`, data);
  //     } else {
  //       data.email = '';
  //       data.search = searchKeyword;
  //       data.phone = '';
  //       data.userName = '';
  //       url = httpService.post(`/users/search`, data);
  //     }

  //     const result = url
  //       .then(response => {
  //         if (response) {
  //           let data = response['data'];
  //           setUserInfo(data);
  //         }
  //       })
  //       .catch(error => {
  //         let errorResponseFieldErrors = error.response['data'].fieldErrors;
  //         let errorResponseGlobalErrors = error.response['data'].globalErrors;
  //         if (error) {
  //           let errorResponse = error.response['data'];
  //           console.log('error response field errors ', errorResponse);
  //         }
  //         loopFieldErrors(errorResponseFieldErrors);
  //         if (errorResponseGlobalErrors) {
  //           if (errorResponseGlobalErrors.length > 0) {
  //             errorResponseGlobalErrors.forEach(msg => {
  //               toast.error(msg.message);
  //             });
  //           }
  //         }
  //       });
  //   } catch (error) {
  //     console.log('error response');
  //   }
  // }, []);
  // useEffect(() => {
  //   SendThanksService.getListOfProducts('AU')
  //     .then(res => {
  //       res.data.map((val, index) => {
  //         if (val['type'] === productType) {
  //           setProduct(val);
  //         }
  //       });
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }, []);

  const loopFieldErrors = data => {
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const sendAgainBtnHandler = () => {
    // if (role === 'organisation') {
    //   history.push('/organisation/send/recipientInformation');
    // } else {
    //   history.push('/recipientInformation');
    // }
    props.resetData(); //Reset - existing data
    history.push('/anonymousselectitem');
  };

  const homeBtnHandler = () => {
    // props.resetData(); //Reset - existing data
  };

  return (
    <AnonymousLayout>
      <div className="text-center pt-3 pb-5">
        <Step1 className="step" />
      </div>
      <div className=" m-0 p-0 row ">
        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half">
          {/* <Header /> */}
          <div className="p-0 m-0 w-100 d-flex payment-main">
            {/* <div className="col-lg-2">&nbsp;</div> */}
            <div className="mt-4">
              <div className="mt-2 border-0  border-radius-5">
                <div className="thansks-success-card mx-4">
                  <div className="my-2 d-flex justify-content-center">
                    {product !== null && product !== undefined ? (
                      <img src={product['iconLargeUrl'] + '?h=140&w=140'} alt="" />
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="my-2 d-flex flex-column justify-content-center thanks-text-cont">
                    <span className="font-30 monteserrat-bold color-black-pearl line-height-42">
                      You Have Successfully Sent
                      <br />
                      <span className="font-20 monteserrat-normal">Thanks to&nbsp; {recieverEmail}</span>
                      {/* {userDetails?.recipients?.length > 0 && (
                        <div className="text-center">
                          Thanks to&nbsp; Craig Mair
                          {userInfo?.length > 0 && (
                            <span>
                              {userInfo[0]?.fullName !== ''
                                ? userInfo[0]?.fullName
                                : userDetails?.recipients[0].email !== null
                                ? userDetails?.recipients[0].email
                                : userDetails?.recipients[0].phone}
                            </span>
                          )}
                          <span>
                            {' '}
                            {userDetails?.recipients.length > 1
                              ? `and ${Number(userDetails.recipients.length) - 1} others`
                              : ' '}
                          </span>
                        </div>
                      )} */}
                    </span>
                    <p className="font-16 monteserrat-normal light-grey">
                      Unlock the full potential of your recognition program or sales process with Appreci. Experience
                      bulk gift sending, unlimited team accounts, and video/audio upload features. Create your account
                      today and take your company's productivity and sales to the next level!
                    </p>
                  </div>

                  <div className="p-0 m-0 mt-3 row text-center w-350 justify-content-between">
                    <div className="p-0 m-0  mb-1 send-again-div">
                      <button
                        type="submit"
                        className="w-150 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                        onClick={sendAgainBtnHandler}
                      >
                        Send again
                      </button>
                    </div>
                    <div className="p-0 m-0  mb-1 home-div pl-4">
                      <a href="https://portal.appreci.io/signup">
                        <button
                          className="w-150 h-60 p-0 m-0 border-radius-5  bg-transparent font-16  font-sofia-pro-semi-bold outline-none"
                          // onClick={homeBtnHandler}
                        >
                          <span
                            href="https://portal.appreci.io/signup"
                            style={{ color: '#000', textDecoration: 'none' }}
                          >
                            Create Account
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="thanks-success-icon">
              <img src={AnonThanksSuccess} alt="icon" className="thanks-success-icon-image" />
            </div>
          </div>
        </div>
      </div>
    </AnonymousLayout>
  );
}

// MAPPING - SEND THANKS DATA

const mapStateToProps = state => {
  return {
    userDetails: state.multiProduct.details,
  };
};

// DISPATCH  - SEND THANKS DATA

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(resetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThanksSuccessPage);
