/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import httpService from '../../../../../services/HttpService';
import './SetUsername.scss';
import UsernameSuccessImage from '../../../../../assets/images/common/icons-check-filled-green.png';
import UsernameErrorImage from '../../../../../assets/images/common/icons-close-red.png';
import ErrorImage from '../../../../../assets/images/error-icons/icons-error-info.png';
import ButtonLoading from '../../../../../common/Button/Button';
import ReactGA from 'react-ga';

const initialValues = {
  userName: '',
};

const validate = values => {
  let errors = {};

  if (!values.userName) {
    errors.userName = 'Required';
  }

  return errors;
};

function SetUsernamePage(props) {
  const [loadingSearchName, setLoadingSearchName] = useState(false);
  const [usernameSuggestions, setUsernameSuggestions] = useState([]);

  const [searchUserName, setSearchUserName] = useState('');

  const [isUsernameAvailable, setIsUserNameAvailable] = useState();

  const inputRef = useRef(null);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    inputRef.current.focus();

    let data = JSON.parse(sessionStorage.getItem('userNames'));

    const fetchData = async () => {
      try {
        if (data) {
          const result = await httpService.get(
            `/usernames/suggestions?firstname=${data.firstName}&lastname=${data.lastName}`
          );
          setUsernameSuggestions(result.data);
        }
      } catch (error) {
        toast.error(error);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Getting username suggestions list
    setLoadingSearchName(true);
    const delayDebounceFn = setTimeout(() => {
      try {
        if (searchUserName && searchUserName.length >= 3) {
          httpService
            .get(`/usernames/${searchUserName}`)
            .then(response => {
              setIsUserNameAvailable(response?.data?.isAvailable);
              setLoadingSearchName(false);
            })
            .catch(error => {
              console.log('error ', error);
              let errorResponseGlobalErrors = error.response?.data.globalErrors;
              let errorMessage = 'Error response field errors ';
              if (error) {
                let errorResponse = error.response?.data;
                console.log(errorMessage, errorResponse);
              }

              toast.error(errorMessage);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors?.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
              setLoadingSearchName(false);
            });
        }
      } catch (error) {
        console.log('error response');
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUserName]);

  // Updating user data
  const onSubmit = values => {
    toast.dismiss();
    let userNames = JSON.parse(sessionStorage.getItem('userNames'));
    let userData = JSON.parse(localStorage.getItem('userData'));
    let data = {
      firstName: userNames.firstName,
      lastName: userNames.lastName,
      email: userData.email,
      userName: values.userName,
      phone: userData.phone,
      region: 'AU',
    };
    axios.defaults.headers.common['userKey'] = userData.userKey;

    httpService
      .put('/users/current', data)
      .then(response => {
        if (response.status === 200) {
          props.moveToNextScreen();
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const handlechange = event => {
    setIsUserNameAvailable();
    let userName = event.target.value;
    if (userName.length > 0) {
      setSearchUserName(event.target.value);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  // Setting username
  const setUserNameValue = value => {
    formik.setFieldValue('userName', value);
    setSearchUserName(value);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  return (
    <div className="p-0 m-0 mt-4">
      <div className="d-flex justify-content-center align-items-center bg-alice-blue">
        <div className="p-0 m-0 username-box">
          <div className="w-100 card border-0 p-4">
            <span className="px-2 my-2 color-charcoal-grey font-32 font-sofia-pro-light text-center">
              Enter a username
            </span>

            <form onSubmit={formik.handleSubmit}>
              <div className="my-2 pos-relative">
                <input
                  type="text"
                  ref={inputRef}
                  className="form-control h-60 font-sofia-pro-regular set-username__placeholder border-light-periwinkle"
                  placeholder="Username"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  name="userName"
                  onKeyUp={handlechange}
                  autofocus
                />

                {formik.touched.userName && formik.errors.userName ? (
                  <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.userName}
                  </div>
                ) : null}

                {isUsernameAvailable === true ? (
                  <img className="set-username__success_icon" src={UsernameSuccessImage} />
                ) : null}
                {isUsernameAvailable === false ? (
                  <img className="set-username__error_icon" src={UsernameErrorImage} />
                ) : null}
                {isUsernameAvailable === false ? (
                  <p className="p-0 m-0 font-14 font-sofia-pro-regular color-charcoal-grey">
                    Username already taken, please choose a different username
                  </p>
                ) : null}
              </div>
              <div className="mt-4">
                <span className="p-0 m-0 col-sm-12 col-md-3 col-lg-3 font-14 font-sofia-pro-regular color-charcoal-grey">
                  Suggested :
                </span>
                <div className="p-0 m-0 mt-2 row">
                  {usernameSuggestions.map((userName, index) => {
                    return (
                      <div
                        className="p-0 m-0 w-50 mt-2 col-sm-6 col-md-6 col-lg-3 suggested-user-names-list"
                        key={index}
                      >
                        <span
                          className="px-1 py-1 border-radius-17 border-primary-color font-14 font-sofia-pro-regular color-primary cursor-pointer"
                          onClick={() => setUserNameValue(userName)}
                        >
                          {userName}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-4">
                <ButtonLoading
                  children={'Proceed'}
                  showLoader={loadingSearchName}
                  disabled={!isUsernameAvailable}
                  classNameValue="w-100 h-60 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none shadow-none justify-content-around align-items-center d-flex"
                />
              </div>
            </form>
          </div>
          <div className="mt-2 text-center">
            <a
              href="https://appreci.io/privacy-policy"
              target="_blank"
              className="pb-1 text-decoration-none font-14 font-sofia-pro-regular color-primary"
            >
              Why do I need to provide this information?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetUsernamePage;
