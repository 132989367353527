/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import 'moment-timezone';
import '../../components/individual/dashboard/DashBoard.scss';
import './Header.scss';
import { Modal } from 'react-bootstrap';
import Tour from 'reactour';
import ClaimCodePage from './ClaimCode';
import SearchBar from './SearchBar';
import useWindowDimensions from '../../common/Responsive/WindowDimensions';
import SendThanksService from '../../services/SendThanksService';
import { toast } from 'react-toastify';
import { searchDataDetails } from '../../store/reducers/header/header';
import { connect } from 'react-redux';
import httpService from '../../services/HttpService';
import merchantService from '../../services/MerchantService';
import MerchantService from '../../services/MerchantService';
import TransactionHistoryService from '../../services/TransactionHistoryService';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as Close } from '../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as MiscSwitchImage } from '../../assets/images/common/icons-misc-switch.svg';
import { ReactComponent as SwitchImage } from '../../assets/images/account/components-illustrations-switch-account.svg';
import ReactGA from 'react-ga';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

const tourConfig = [
  {
    selector: '[data-tut="reactour__iso"]',
    content: () => (
      <div className="d-flex flex-column">
        <SwitchImage />
        <span className="font-sofia-pro-medium font-16 color-black-pearl">Switch between accounts</span>
        <p className="font-sofia-pro-light font-14 color-black-pearl">
          You can always switch between your personal and organisational accounts from here.
        </p>
      </div>
    ),
  },
];

const Header = props => {
  const [showSearchList, setShowSearchList] = useState(false);
  const { width } = useWindowDimensions();

  const [recentSearchList, setRecentSearchList] = useState([]);
  const [recentSearchPlace, setRecentSearchPlace] = useState([]);
  const [userData, setUserData] = useState([]);

  const [companyData, setCompanyData] = useState();
  const [isTourOpen, setIsTourOpen] = useState(false);

  const [showSwitchToAccountModal, setShowSwitchToAccountModal] = useState(false);
  const [isNotificationListLoaded, setIsNotificationListLoaded] = useState(false);

  const hideSwitchToAccountModalHandler = () => setShowSwitchToAccountModal(false);

  let currentPath = usePathname();
  const history = useHistory();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [currentPath]);

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const openTour = () => {
    setIsTourOpen(true);
  };

  const accentColor = '#5cb7b7';

  let userData1 = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    if (currentPath === '/appreci-locations') {
      showPlceSearchList();
    }
    if (!currentPath.startsWith('/merchant')) {
      showUserSearchList();
    }
    if (isNotificationListLoaded === false) {
      getReceivedTransactions();
    }
    setIsNotificationListLoaded(true);
    return () => {};
  }, []);
  const showPlceSearchList = () => {
    httpService.get('Locations/history').then(res => {
      setRecentSearchPlace(res['data']);
    });
  };
  const showUserSearchList = () => {
    // setShowSearchList(true);
    if (showSearchList === false) {
      httpService
        .get('/users/search')
        .then(response => {
          setRecentSearchList(response['data']);
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response?.data.fieldErrors;
          let errorResponseGlobalErrors = error.response?.data.globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          console.log('error filed errors ', errorResponseFieldErrors);
          if (errorResponseFieldErrors.length > 0) {
            errorResponseFieldErrors.forEach(msg => {
              toast.error(msg);
            });
          }
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
      setShowSearchList(false);
    }
  };

  const getMerchantDetails = () => {
    MerchantService.getCompanyDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCompanyData(data);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        console.log('error filed errors ', errorResponseFieldErrors);
        if (errorResponseFieldErrors.length > 0) {
          errorResponseFieldErrors.forEach(msg => {
            toast.error(msg);
          });
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  useEffect(() => {
    // if (userData !== undefined) {
    //   setUserName(userData?.fullName);
    // }
    // if (currentPath === '/profile') {
    if (userData1 === undefined || userData1 === null) {
      getCurrentUser();
    } else {
      setUserData(userData1);
      userData1?.roles.forEach((data, index) => {
        if (data === 'CompanyAdmin') {
          getMerchantDetails();
        }
      });
    }

    let checkMerchantIsOnboarded = JSON.parse(sessionStorage.getItem('merchantOnboard'));
    if (Boolean(checkMerchantIsOnboarded) === true) {
      openTour();
    }
    return () => {};
  }, []);

  const getCurrentUser = () => {
    merchantService
      .getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'].roles;
          setUserData(response['data']);
          data.forEach((data, index) => {
            if (data === 'CompanyAdmin') {
              getMerchantDetails();
            }
          });
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const getReceivedTransactions = () => {
    TransactionHistoryService.getNotificationsList()
      .then(response => {
        if (response) {
          let data = response['data']?.data;
          SendThanksService.getListOfProducts('AU')
            .then(response => {
              if (response) {
                let productsList = response['data'];
                data.forEach((value, index) => {
                  productsList.forEach((product, index) => {
                    if (product?.price?.priceId === value.priceId) {
                      if (value?.type === 'GiftReceived') {
                        value['statusMessage'] = `You received ${product?.name} from`;
                      } else if (value?.type === 'GiftRedeemed') {
                        value['statusMessage'] = `Your ${product?.name} redeemed by`;
                      } else if (value?.type === 'GiftCancelled') {
                        value['statusMessage'] = `Your ${product?.name} cancelled by`;
                      }
                    }
                  });
                });
                setIsNotificationListLoaded(true);
              }
            })
            .catch(error => {
              let errorResponseGlobalErrors = error.response?.data.globalErrors;
              if (error) {
                let errorResponse = error.response?.data;
                console.log('error response field errors ', errorResponse);
              }

              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            });
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const switchToAccountBtnHandler = () => {
    history.push('/dashboard');
  };

  return (
    <div>
      {props.menu !== 'Merchant' && (
        <div className=" d-flex  flex-column ">
          <div className={`dashboard-search__rectanglebig`}>
            <div
              className={`d-flex flex-row 
            ${currentPath === '/forwardthanks' ? 'justify-content-end' : 'justify-content-between'}`}
            >
              {currentPath === '/profile' || currentPath === '/merchant/profile' || currentPath === '/forwardthanks' ? (
                ''
              ) : (
                <SearchBar SearchedList={recentSearchList} PlaceSearchedList={recentSearchPlace} />
              )}

              <div className={width > 580 ? ' d-flex flex-row justify-content-between' : ' d-flex flex-row'}>
                <div className={`d-flex ${width <= 580 && 'justify-content-center flex-wrap'}`}>
                  <ClaimCodePage currentPath={currentPath} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {props.menu === 'Merchant' && (
        <div className="w-100 p-3 bg-white d-flex justify-content-between alin-items-center">
          <span className="font-sofia-pro-light color-black-pearl font-18">{companyData?.name}</span>
          <span
            className="font-sofia-pro-regular color-black-pearl font-14 cursor-pointer"
            onClick={() => setShowSwitchToAccountModal(true)}
            data-tut="reactour__iso"
          >
            <MiscSwitchImage className="pr-2" />
            Switch to {userData['fullName']}
          </span>

          <Modal
            className="pt-4 border-0
         modal-background-shadow switch-to-account-modal"
            show={showSwitchToAccountModal}
            onHide={hideSwitchToAccountModalHandler}
            animation={false}
          >
            <Modal.Header className="p-0 m-0 border-0">
              <button
                type="button"
                className="close switch-to-account-modal__close__icon"
                onClick={hideSwitchToAccountModalHandler}
              >
                <Close />
              </button>
            </Modal.Header>
            <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
              <p className="font-16 font-sofia-pro-semi-bold color-black-pearl">Switch to {userData['fullName']}?</p>
              <div className="w-100 p-0 m-0 d-flex justify-content-start align-items-center">
                <button
                  type="submit"
                  className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                  onClick={switchToAccountBtnHandler}
                >
                  Switch
                </button>
                <button
                  className="p-0 m-0 ml-2 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                  onClick={hideSwitchToAccountModalHandler}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Tour
            className="d-flex justify-content-center align-items-center border-radius-11"
            onRequestClose={closeTour}
            steps={tourConfig}
            isOpen={isTourOpen}
            showNumber={false}
            disableDotsNavigation={false}
            showButtons={false}
            showNavigationNumber={false}
            showCloseButton={false}
            showNavigation={false}
          />
        </div>
      )}
    </div>
  );
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    searchData: data => dispatch(searchDataDetails(data)),
  };
};

export default connect(null, mapDispatchToProps)(Header);
