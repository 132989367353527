import React, { useEffect, useState } from 'react';

const SliderAutorotate = ({images, start = 0}) => {
  const [imageIndex, setImageIndex] = useState(start)
  const [image, setImage] = useState(images[imageIndex])

  useEffect(()=>{
    const autoImage = setInterval(()=>{
      setImageIndex(prev => {
        if(prev >= images.length-1){
          return start
        }
        return ++prev
      })
    },3000)
    return ()=>{
      clearInterval(autoImage);
    }
  },[image])

  useEffect(()=>{
    setImage(images[imageIndex])
  },[imageIndex])

  return (
    <div>
      <div className='d-flex flex-column align-items-center my-2'>
        <img src={image} className='my-3' width={200}/>
      </div>
      <div className='d-flex flex-row justify-content-center my-4'>
        {images.map((elem,index) =>(
          <button className='border-0 bg-transparent carousel-icons' onClick={() => setImageIndex(index)} >
              <div className={`redeemedwine-Oval__selected border-radius-8 redeemedwine-Oval__${elem === image ? 'selected'  : 'unselected' }`} />
          </button>
        ))}
      </div>
    </div>
  );
};

export default SliderAutorotate;