/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useEffect, useState, useReducer, useRef, useImperativeHandle } from 'react';
import { Modal, Button } from 'react-bootstrap';
import RecordAudioStore from '../../store/reducers/RecordAudioStore';
import {
  AudioModalClose,
  MikeIcon,
  CameraIcon,
  MikeIconWhite,
  StopRecord,
  RestartRecord,
  PlayIcon,
} from '../../assets/images';
import './RecordAudio.scss';
import AudioProvider from './AudioProvider';
import AudioAnalyzer from './AudioAnalyzer';
import Timer from '../Timer/Timer';
import Loader from '../Loader/Loader';
import http from '../../services/HttpService';
import { toast } from 'react-toastify';

const initialState = {
  showModal: false,
  stopModal: false,
  recordState: 'init',
  recorder: null,
  audio: null,
  audioState: 'stopped',
  loading: false,
  uploadedFile: {},
};

const RecordAudio = React.forwardRef(({ ...props }, ref) => {
  const [state, dispatch] = useReducer(RecordAudioStore, initialState);

  const [pausedTime, setPausedTime] = useState(15);

  const canvasRef = useRef(null);

  const canvasPreviewRef = useRef(null);

  const previewAudio = useRef(null);

  useEffect(() => {
    dispatch({ type: 'toggle_modal', data: props.audioModel });
  }, [props.audioModel]);

  useEffect(() => {
    if (state.showModal && state.recordState === 'selected') {
      const audioElement = previewAudio.current;
      if (audioElement) {
        audioElement.onplay = function () {
          dispatch({ type: 'toggle_audioState', data: 'playing' });
          AudioAnalyzer(canvasPreviewRef, audioElement, 'audio');
          audioElement.muted = false;
        };
        audioElement.onpause = function () {
          dispatch({ type: 'toggle_audioState', data: 'stopped' });
        };
      }
    }
  });

  const triggerRecording = async () => {
    switch (state.recordState) {
      case 'init':
      case 'stopped':
      case 'selected':
        const AudioRecorder = await AudioProvider();
        dispatch({
          type: 'record_state_start',
          data: { state: 'started', recorder: AudioRecorder },
        });
        break;
      case 'started':
      case 'resumed':
        dispatch({
          type: 'record_stop_confirmation',
          data: { modal: true, recordState: 'paused' },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    switch (state.recordState) {
      case 'started':
        state.recorder.clearChunk();
        state.recorder.start();
        renderCanvas();
        break;
      case 'resumed':
        state.recorder.resume();
        renderCanvas();
        break;
      case 'paused':
        state.recorder.pause();
        break;
      default:
        break;
    }
  }, [state.recordState]);

  const stopRecording = async () => {
    const audio = await state.recorder.stop();
    setPausedTime(15);
    dispatch({
      type: 'record_state_stop',
      data: { state: 'stopped', audio: audio },
    });
  };

  const renderCanvas = () => {
    AudioAnalyzer(canvasRef, state.recorder.getStream());
  };

  const confirmStopRecording = async () => {
    const audio = await state.recorder.stop();
    dispatch({
      type: 'record_state_stop',
      data: { state: 'stopped', audio: audio, stopModal: false },
    });
    setPausedTime(15);
  };

  const closeStopModal = async () => {
    dispatch({
      type: 'record_stop_confirmation',
      data: { stopModal: false, recordState: 'resumed' },
    });
  };

  const selectAudio = () => {
    dispatch({ type: 'select_audio', data: { recordState: 'selected' } });
  };

  const removeAudio = () => {
    setPausedTime(15);
    dispatch({ type: 'select_audio', data: { recordState: 'init' } });
  };

  const storeTimer = time => {
    setPausedTime(time);
  };

  useImperativeHandle(ref, () => ({
    selectAudioPlayer: selectAudio,
    recordState: state.recordState,
  }));

  const switchMediatype = async e => {
    props.toggleAudioModal(e);
    props.toggleVideoModal(e);
  };

  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.name = fileName;
    theBlob.lastModifiedDate = Date.now();

    /** Download Video 
        var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
        var url = window.URL.createObjectURL(theBlob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        */

    return theBlob;
  };

  const selectUpload = () => {
    dispatch({ type: 'trigger_loader', data: true });
    let userData = JSON.parse(localStorage.getItem('userData'));
    let audioFileName = `${Date.now()}_${userData?.userName ? userData?.userName:"Anonymous User"}_audio.mp3`;

    var formData = new FormData();
    formData.append('fileName', audioFileName);
    http
      .post('/StoredFiles', formData, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      })
      .then(initResponse => {
        let resp = initResponse.data;
        var myAudioFile = new File([state.audio.audioBlob], audioFileName, {
          lastModified: Date.now(),
        });
        var formDataUpdate = new FormData();
        formDataUpdate.append('fileName', myAudioFile, audioFileName);
        http
          .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
              fileSecret: resp.fileSecret,
            },
          })
          .then(response => {
            props.audioResponse(response.data);
            dispatch({ type: 'upload_completed', data: response.data });
            props.selectAudio();
          })
          .catch(error => {
            let errorResponse = error.response['data'];
            dispatch({ type: 'trigger_loader', data: false });
            console.log(errorResponse);
          });
      })
      .catch(error => {
        let errorResponse = error.response['data'];
        dispatch({ type: 'trigger_loader', data: false });
        console.log(errorResponse);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          loopFieldErrors(errorResponseFieldErrors);
          loopFieldErrors(errorResponseGlobalErrors);
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        console.log('j ', j);
        if (itemSubArray['message']) {
          toast.error(itemSubArray['message']);
        }
      }
    }
  };

  return (
    <>
      {state.loading && <Loader />}
      <Modal show={state.showModal} id="audio_modal" ref={ref} className="modal-background-shadow">
        <Modal.Body className="d-flex justify-content-center">
          <Button variant={'none'} type="button" className="close btn-model-close" onClick={props.toggleAudioModal}>
            <AudioModalClose />
          </Button>
          {
            {
              init: <p className="middle-line"></p>,
              started: (
                <>
                  <Timer
                    confirmStopRecording={confirmStopRecording}
                    pausedTime={pausedTime}
                    storeTimer={storeTimer}
                    recordState={state.recordState}
                  />
                  <canvas className="analyzer-canvas" ref={canvasRef} />
                </>
              ),
              resumed: (
                <>
                  <Timer
                    confirmStopRecording={confirmStopRecording}
                    pausedTime={pausedTime}
                    storeTimer={storeTimer}
                    recordState={state.recordState}
                  />
                  <canvas className="analyzer-canvas" ref={canvasRef} />
                </>
              ),
              stopped: (
                <>
                  <p className="middle-line"></p>
                  <div className="audio-list">
                    <Button variant={'none'} type="button" className="close" onClick={removeAudio}>
                      <AudioModalClose />
                    </Button>
                    <MikeIconWhite className="mike" />
                    <PlayIcon className="play" onClick={selectAudio} />
                    <p className="selector" onClick={selectUpload}>
                      Select
                    </p>
                  </div>
                </>
              ),
              selected: (
                <>
                  {state.audioState === 'playing' ? (
                    <canvas className="analyzer-canvas" key={'preview_'} ref={canvasPreviewRef} />
                  ) : (
                    <></>
                  )}
                  <audio controls ref={previewAudio}>
                    <source src={state.audio ? state.audio.audioUrl : ''} type="audio/ogg" />
                  </audio>
                </>
              ),
            }[state.recordState]
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="rec-toggle-btn">
            <Button
              variant="none"
              className="rect-inactive-btn"
              disabled={state.recordState === 'started' ? true : false}
              onClick={switchMediatype}
            >
              <CameraIcon />
            </Button>
            <Button variant="none" className="rec-active-btn">
              <MikeIcon />
            </Button>
          </div>
          <div className="modal-center-action">
            {
              {
                init: (
                  <Button
                    variant="none"
                    className="bg-primary-color color-white record-button-mike"
                    onClick={triggerRecording}
                  >
                    <MikeIconWhite />
                  </Button>
                ),
                started: <StopRecord className="record-button-stop" onClick={triggerRecording} />,
                paused: <StopRecord className="record-button-stop" onClick={triggerRecording} />,
                resumed: <StopRecord className="record-button-stop" onClick={triggerRecording} />,
                stopped: (
                  <Button variant="none" className="bg-secondary-color color-white record-button-mike-disabled" s>
                    <MikeIconWhite />
                  </Button>
                ),
                selected: (
                  <>
                    <Button variant="none" className="color-primary" onClick={triggerRecording}>
                      <RestartRecord /> Record again
                    </Button>
                    <Button
                      variant="none"
                      className="bg-primary-color color-white"
                      style={{ position: 'absolute', right: '15px' }}
                      onClick={selectUpload}
                    >
                      Send
                    </Button>
                  </>
                ),
              }[state.recordState]
            }
          </div>
          <div></div>
        </Modal.Footer>
      </Modal>
      <Modal show={state.stopModal} id="record_stop_modal" centered className="modal-background-shadow">
        <Modal.Body className="">
          <Button variant={'none'} type="button" className="close btn-model-close" onClick={closeStopModal}>
            <AudioModalClose />
          </Button>
          <p className="font-weight-bold">Are you sure you want to stop recording?</p>
          <Button
            type="button"
            variant="none"
            className="bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none"
            onClick={confirmStopRecording}
          >
            Finish
          </Button>
          <Button
            type="button"
            variant="none"
            className="color-gray-pearl font-sofia-pro-semi-bold font-16 outline-none"
            onClick={closeStopModal}
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
});

export default RecordAudio;
