import React, { useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import './Notification.scss';

const Toasts = ({text, style = {}, setShowToasts: setShow,}) => {
  const [showToasts, setShowToasts] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
      setShowToasts(true);
    }, 2000);
  }, []);

  const closeToasts = ()=>{
    setShowToasts(false)
    setShow(false)
  }

  return (
    <Toast onClose={closeToasts} show={showToasts} animation={false} className="border-0 toast-main mr-3">
       <Toast.Body className="bg-pale-mavue-1 z-index-1 d-flex toast-content" style={style}>
         <p className='m-0 text-left'>
           {text}
         </p>
         <button type='button' className='ml-2 mb-1 close' onClick={closeToasts}>
           <span aria-hidden='true'>&times;</span>
         </button>
       </Toast.Body>
    </Toast>
  );
};
export default Toasts;

