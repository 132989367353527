import React, { useState, useEffect } from 'react';
import Button from '../../../common/Button/Button.js';
import { connect, useDispatch, useSelector } from 'react-redux';
import { oneMinusToCard, oneMoreToCard, resetCard, addQuantityDispatch } from '../../../store/reducers/multiProduct/multiProduct';
import EmptyBox from './Box.js';
import SendThanksService from '../../../services/SendThanksService';
import Loader from '../../../common/Loader/Loader.js';
import ErrorImage from '../../../assets/images/error-icons/icons-error-info.png';
import QuantityBox from './QuantityBox.js';
import IconsInfoGrey from '../../../assets/images/common/icons-info-grey.png';
import { toast } from 'react-toastify';
import { productDetailsDispatch } from '../../../store/reducers/multiProduct/multiProduct';
import { useHistory } from 'react-router-dom';
import SendSlider from '../../../common/component/SendSlider.js';


const TwoBrand = ({handleAddRecipientsShow, showLoader, handleBack, selectedImg, formik, tileWidth, handleProceed, selectedProductOption, checkGiftCardIsClicked, productTypes}) => {
    const currentPath = window.location.pathname;
    const history = useHistory();
    const [selectedSubProduct, setSelectedSubProduct ] = useState({});
    const [subProduct, setSubProduct] = useState('');
    const [sProduct, setSProduct] = useState({});
    const [selectedProduct, setSelectedProduct] = useState();
    const [products, setProducts] = useState([]);
    const [selectedItemIndex, setSelectedItemIndex] = useState();
    const [itemList, setItemList] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [selectedProductIndex, setSelectedProductIndex] = useState();
    const [multipleProducts, setMultipleProducts] = useState();
    const [singleProducts, setSingleProducts] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [availableCreditBalance, setAvailableCreditBalance] = useState();
    const [availableGiftAmount, setAvailableGiftAmount] = useState(false);
    const [checkCustomOptionIsClicked, setCheckCustomOptionIsClicked] = useState(false);
    const [selectedGiftCardIndex, setselectedGiftCardIndex] = useState();
    const [selectedGiftcardValue, setSelectedGiftcardValue] = useState();
    const [giftProduct, setGiftProduct] = useState([]);
    const [proceedDisable, setProceedDisable] = useState(false);
    const [selectedProductImage, setSelectedProductImage] = useState(selectedImg[0]?.imagePath);
    const [allRanges, setAllRanges] = useState([]);
    const [rangeImages, setRangeImages] = useState([]);


    let giftCardValues = [
      {
        id: 0,
        amount: 20,
        checked: false,
      },
      {
        id: 1,
        amount: 50,
        checked: false,
      },
      {
        id: 2,
        amount: 100,
        checked: false,
      },
      {
        id: 3,
        amount: 'Custom',
        checked: false,
      },
    ];

  const dispatch = useDispatch();
  const { productsCard, details, total } = useSelector(state => state.multiProduct);

  useEffect(() => {
    if(products.length == 0) {
      loadProduct();
    }
  }, [selectedImg]);


  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^[0-9]$/.test(keyValue)) {
      event.preventDefault();
    }

    let max_chars = 3;

    if (event.target.value.length > max_chars) {
      event.target.value = event.target.value.substr(0, max_chars);
    }
  };

  // Gift card custom value
  const giftCardCustomhandleOnChange = event => {
    toast.dismiss();
    setSelectedGiftcardValue();

    if (event.target.value !== '' && event.target.value > 10 && event.target.value <= 100) {
      setSelectedGiftcardValue(event.target.value);
      setAvailableGiftAmount(true);
    } else {
      setAvailableGiftAmount(false);
      toast.warn('Please enter values between 10 to 100');
    }
  };

  const selectedGiftCardItem = index => {
    if (giftCardValues[index].amount !== 'Custom') {
      setselectedGiftCardIndex(index);
      setCheckCustomOptionIsClicked(false);
      giftCardValues[index].checked = true;
      setAvailableGiftAmount(true);
      setSelectedGiftcardValue(giftCardValues[index].amount);
    } else {
      setCheckCustomOptionIsClicked(true);
      setAvailableGiftAmount(false);
    }
  };


  const loadProduct = async () => {
    setIsLoading(true);
    SendThanksService.getListOfProducts('AU', 'Unknown', true)
      .then(res => {
        setIsLoading(false);
        setProducts(res?.data);
        const giftProductFind = res?.data.find(elem => {
          return elem.type === 'GiftCard';
        });
        setGiftProduct(giftProductFind);
        let purchaseData = [];
        res?.data?.forEach(value => {
          if (value.features === 'Purchase') {
            purchaseData.push(value);
          }
        });
        const productByType = purchaseData.filter(product => {
          return product.type === selectedImg[0].type && product.prices.length >= 1;
        });
        console.log("====productByType--------", productByType);
        if (productByType.length > 0 && productByType[0].ranges.length > 0) {
          setMultipleProducts(productByType);
          setSingleProducts(null);
        } else {
          setMultipleProducts(null);
          setSingleProducts(productByType);
        }
        // if (productByType.length > 1 && productByType[0].prices.length > 1) {
        //   setMultipleProducts(productByType);
        //   setSingleProducts(null);
        // } else {
        //   setMultipleProducts(null);
        //   setSingleProducts(productByType);
        // }
        const joinedList = joinPricesRanges(productByType);
        if (productByType.length > 0) {
          setSelectedItemIndex(0);
        }
        setItemList(joinedList.filter(f => f.type === selectedImg[0].type));
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error ', error);
      });
  };

  const joinPricesRanges = productList => {
    const joined = productList.map(itemList => {
      const concat = itemList.ranges.map(product => {
        let joinWithPrice = {};
        const priceProduct = itemList.prices.filter(itemPrice => {
          return product.priceId === itemPrice.priceId;
        });

        if (!priceProduct.length) {
          joinWithPrice = { ...product, price: itemList.maximumPrice };
        } else {
          joinWithPrice = { ...priceProduct[0], ...product };
        }
        return joinWithPrice;
      });
      return { ...itemList, ranges: concat };
    });
    return joined;
  };

  const handleGiftCardProceed = () => {
    if (productTypes[selectedProductOption].type === 'GiftCard') {
      const replacerPath = currentPath.replace('selectItem', 'payment');
      formik.setFieldValue("total", `$${selectedGiftcardValue  * 1.05}`);
      handleAddRecipientsShow(singleProducts[0]);
      dispatch(
        productDetailsDispatch({
          giftValues: selectedGiftcardValue,
          giftPriceId: giftProduct.prices[0]?.priceId,
        })
      );
      // props.history.push(`${replacerPath}`);
    //   history.push('/recipientInformation');
    // } else {
    //   if (window.location.pathname.includes('/organisation/send')) {
    //     history.push('/organisation/send/recipientInformation');
    //   } else if (window.location.pathname.includes('/organisation/employee')) {
    //     history.push('/organisation/employee/recipientInformation');
    //   } else {
    //     history.push('/recipientInformation');
    //   }
      // const replacerPath = currentPath.replace('selectItem', 'selectProduct');
      // history.push(`${replacerPath}?type=${productsType[selectedProductOption].type}`);
    }
    dispatch(
      productDetailsDispatch({
        type: productTypes[selectedProductOption].type,
      })
    );
  };

  const handleProductClick = product => {
    setSelectedProduct(product?.name);
    setSProduct(product);
    setSelectedSubProduct({});
    setSelectedProductImage(product?.imageUrl);
    formik.setFieldValue('brand', product);
    if(product?.prices.length === 1) {
      setProceedDisable(true);
      formik.setFieldValue('priceId', product?.prices[0]?.priceId);
    }
    setRangeImages([]);
  };

  const handleSubProductClick = subProduct => {
    setSubProduct(subProduct?.name);
    setSelectedSubProduct(subProduct);
    formik.setFieldValue('item', subProduct);
    formik.setFieldValue('priceId', subProduct?.priceId);
    setProceedDisable(true);
    setSelectedProductImage(subProduct?.imagePath);
    const priceRange = allRanges.filter((range) => range.priceId == subProduct.priceId)
    console.log("------priceRange--------", priceRange);
    const updatedRangeImages = [];
    priceRange.forEach((pR) => {
      updatedRangeImages.push(pR?.imagePath);
    })
    setRangeImages(priceRange);
  }

  console.log("------rangeImages--------", rangeImages);

  const selectedItems = index => {
    dispatch(resetCard());

    const groupedByPriceId = {};
    const firstElements = [];

    itemList[index]?.ranges.forEach((range) => {
      const { priceId } = range;

      if (!groupedByPriceId[priceId]) {
        groupedByPriceId[priceId] = [range];
      } else {
        groupedByPriceId[priceId].push(range);
      }
    });

    const resultArrays = Object.values(groupedByPriceId);
    console.log("===resultArrays===", resultArrays);

    resultArrays.forEach((array) => {
      if (array.length > 0) {
        firstElements.push(array[0]);
      }
    });

    console.log("---firstElements---", firstElements);
    setAllRanges(itemList[index]?.ranges);
    setProductsList(firstElements);
    setSelectedProductIndex(null);
    setSelectedItemIndex(index);
    formik.setFieldValue('quantity', 0);
  };

  const selectedProductI = (index, e) => {
    dispatch(resetCard());
    setSelectedProductIndex(index);
    formik.setFieldValue('quantity', 0);
  };

  const handleOneMoreToCard = product => {
    formik.setFieldValue('quantity', formik.values.quantity + 1);
    dispatch(oneMoreToCard(product));
    // formik.setFieldValue('total', `$${Math.floor(total * 100) / 100}`);
  };

  const handleOneMinusToCard = product => {
    formik.setFieldValue('quantity', formik.values.quantity - 1);
    dispatch(oneMinusToCard(product));
    // formik.setFieldValue('total', `$${Math.floor(total * 100) / 100}`);
  };

  const handleAddQuantity = (product, quantity) => {
    let data = {...product, quantity: quantity}
    dispatch(addQuantityDispatch(data));
  }

    const QuantityButton = ({ product }) => {
    return (
      <div className="d-flex justify-content-between align-items-center w-50">
        <button
          className={'dashboard-card_redeem px-3 py-1 dashboard-button'}
          onClick={() => handleOneMinusToCard(product)}
          disabled={productsCard.id !== product.id || productsCard.quantity == 0 ? true : false}
        >
          <span className="dashboard__sendthanks font-20 font-sofia-pro-medium"> - </span>
        </button>
        <span className="font-14 font-weight-bold">{productsCard.id === product.id ? productsCard.quantity : 0}</span>
        <button
          className={'dashboard-card_redeem  px-3 py-1 dashboard-button'}
          onClick={() => handleOneMoreToCard(product)}
        >
          <span className="dashboard__sendthanks font-20 font-sofia-pro-medium"> + </span>
        </button>
      </div>
    );
  };

  useEffect(() => {
    if(multipleProducts?.length !== 0) {
      const newTotal = `$${Math.floor(total * 100) / 100}`
      formik.setFieldValue('total', newTotal);
    }
  }, [total])
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
        {checkGiftCardIsClicked === true ? (
                  <>
                    <div className="p-0 m-0 mx-3 my-4">
                      <span className="ml-2 mb-3 font-sofia-pro-light color-charcoal-grey font-24">Gift card amount</span>
                      <div className="mt-4">
                        {checkCustomOptionIsClicked === false && (
                          <div className="d-flex gap-20 flex-wrap ">
                            {giftCardValues.map((value, index) => {
                              return (
                                <div className="" key={index}>
                                  <span
                                    className={`p-3 w-90 bg-white-smoke cursor-pointer d-block ${
                                      index === selectedGiftCardIndex
                                        ? ' font-sofia-pro-semi-bold color-primary border-primary-color'
                                        : ' font-sofia-pro-regular color-approx-charcoal '
                                    }`}
                                    onClick={selectedGiftCardItem.bind(this, index)}
                                  >
                                    {value.amount !== 'Custom' ? '$ ' + value.amount : value.amount}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {checkCustomOptionIsClicked === true && (
                          <>
                            <div className="w-100 wm-500 form-group mt-2">
                              <input
                                type="number"
                                onKeyPress={onKeyPress.bind(this)}
                                onChange={giftCardCustomhandleOnChange.bind(this)}
                                className="font-14 form-control-coffee h-60 font-sofia-pro-light select-item__quantity_placeholder border-light-periwinkle shadow-none"
                                placeholder="Enter custom amount (min $10 | max $100)"
                                value={selectedGiftcardValue}
                                onWheel={e => e.target.blur()}
                                min="10"
                                max="100"
                                step="1"
                              />
                              <div className="d-flex align-items-center">
                                <img src={IconsInfoGrey} height="15px" width="15px" alt="" />
                                <p className="p-0 m-0 font-sofia-pro-regular font-12 color-grey-blue">
                                  Enter amount between 10$ -100$ (accepts multiples of 10).
                                </p>
                              </div>
                            </div>
                            <button
                              className="btn bg-primary-color h-44 w-120 color-white font-sofia-pro-semi-bold font-12 shadow-none"
                              onClick={() => setCheckCustomOptionIsClicked(false)}
                            >
                              Back to cards
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="p-0 m-2 text-right">
                      <button
                        type="submit"
                        className="btn bg-primary-color h-44 w-120 color-white font-sofia-pro-semi-bold font-16 shadow-none"
                        disabled={(selectedProductOption >= 0 && !checkGiftCardIsClicked) || availableGiftAmount ? false : true}
                        onClick={handleGiftCardProceed}
                      >
                        Next
                      </button>
                    </div>
                  </>
        ) : (
          <>
          {multipleProducts && (
            <>
            {formik.values.quantity ? <QuantityBox quantity={formik.values.quantity} total={formik.values.total} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
              <div className="d-flex flex-column position-relative mt-5">
                <div
                  className={`font-sofia-pro-regular ${
                    windowWidth <= 767 ? 'font-18 text-center' : 'font-32 text-center'
                  } color-black-pearl `}
                >
                  Select the type of {selectedImg[0].type}
                </div>
                <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
                  Your recipient will get to choose their favourite gift from our available range.
                </p>
              </div>
              <div className="twobrand-modals">
                {rangeImages.length ? <SendSlider images={rangeImages} selectedProduct={selectedProduct}/>
                :<div className="mt-4 img-card-coffee mr-3">
                  <img className={`w-100 h-100 category-img`} src={`${selectedProductImage}?h=${tileWidth}`} />
                </div>}
                <div className="mt-3 mb-5 d-flex justify-content-center align-items-center flex-column flex-wrap">
                  <div className={`d-flex twoBrand-box-style justify-content-center align-items-center`}>
                    <div className="w-100 d-flex flex-column align-items-start flex-wrap">
                      <form className="w-100 mt-3" onSubmit={formik.handleSubmit}>
                        <div className="w-100 p-0 b-0 pb-1 form-group">
                          <label className="font-sofia-pro-regular color-charcoal font-16">Select brand</label>
                          <div className={`d-flex justify-content-between`}>
                            {multipleProducts
                              ?.filter(p => p.type == selectedImg[0].type)
                              .map((product, index) => (
                                <EmptyBox
                                  product={product}
                                  className="box"
                                  label={product?.name}
                                  price={`$${product?.minimumPrice}`}
                                  onProductClick={handleProductClick}
                                  isProductSelected={selectedProduct && selectedProduct === product.name}
                                  onClicked={selectedItems.bind(this, index)}
                                />
                              ))}
                          </div>
                        </div>
                        <div className="w-100 p-0 b-0 pb-1 form-group">
                          {productsList?.length && itemList[selectedItemIndex]?.ranges?.length > 1 ? (
                            <div>
                              <label className="font-sofia-pro-regular color-charcoal font-16">Select item</label>
                              <div className="d-flex justify-content-between flex-wrap">
                                {productsList.map((product, index) => {
                                  return (
                                    <EmptyBox
                                      subProduct={product}
                                      className="box"
                                      label={product.name}
                                      price={`$${product?.price}`}
                                      onProductClick={handleSubProductClick}
                                      isSelected={subProduct && subProduct === product.name}
                                      onClicked={() => {
                                        selectedProductI(index);
                                      }}
                                    />
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        {/* <div className="w-100 p-0 b-0 pb-1 form-group">
                          <label className="font-sofia-pro-regular color-charcoal font-16">
                            {selectedProduct} - {selectedSubProduct?.name}
                          </label>
                          <p>{selectedSubProduct?.description}</p>
                        </div> */}
                        <div className="w-100 p-0 b-0 pb-1 form-group d-flex justify-content-between">
                        {Object.keys(formik.values.brand).length || Object.keys(formik.values.item).length ? <QuantityButton product={Object.keys(selectedSubProduct).length === 0 ? sProduct  : selectedSubProduct}/>: null}
                          <div className="d-flex flex-column align-items-end">
                            <p>Total</p>
                            <label className="font-sofia-pro-regular color-charcoal font-24">
                              {/* {`$${Math.floor(total * 100) / 100}`} */}
                              {formik.values.total}
                            </label>
                          </div>
                        </div>
                        <p className='font-sofia-pro-italic font-16 color-gunmetal invite_paragraph font-weight-normal text-left'>Price includes shipping Australia wide</p>
                        {windowWidth <= 767 ? (
                          <div className="w-100 justify-content-center">
                            {handleAddRecipientsShow ?
                            <Button
                              classNameValue="twoBrand-coffee mb-3 shadow-none justify-content-center align-items-center h-60 w-100  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                              children="Proceed"
                              showLoader={showLoader}
                              onClick={() => handleAddRecipientsShow()}
                              disabled={formik.values.quantity && proceedDisable ? false : true}
                            />: <Button
                                  classNameValue="twoBrand-coffee mb-3 shadow-none justify-content-center align-items-center h-60 w-100  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                                  children="Proceed"
                                  showLoader={showLoader}
                                  onClick={() => handleProceed()}
                                  disabled={formik.values.quantity && proceedDisable ? false : true}
                                />
                            }
                          </div>
                        ) : (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                              classNameValue="twoBrand-back-coffee mb-3 shadow-none d-flex justify-content-center align-items-center h-60  btn font-16 font-sofia-pro-semi-bold"
                              children="Back"
                              showLoader={showLoader}
                              onClick={handleBack}
                            />
                            {handleAddRecipientsShow ? 
                              <Button
                                classNameValue="twoBrand-coffee mb-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                                children="Proceed"
                                showLoader={showLoader}
                                onClick={() => handleAddRecipientsShow()}
                                disabled={formik.values.quantity && proceedDisable ? false : true}
                              />:
                              <Button
                                classNameValue="twoBrand-coffee mb-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                                children="Proceed"
                                showLoader={showLoader}
                                onClick={() => handleProceed()}
                                disabled={formik.values.quantity && proceedDisable ? false : true}
                              />}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {singleProducts && (
            <>
              {formik.values.quantity ? <QuantityBox quantity={formik.values.quantity} total={formik.values.total} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
              <div className="d-flex flex-column position-relative" style={{ marginTop: '80px' }}>
                <span className="font-sofia-pro-regular font-32 color-black-pearl text-center">
                  Select your {selectedImg[0].type}
                </span>
                <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
                  Send a {selectedImg[0].type} of appreciation to someone who deserves it
                </p>
              </div>
              <div className="mt-3 mb-5 d-flex justify-content-center align-items-center flex-column flex-wrap">
                <div className="d-flex coffee-box-style justify-content-center">
                  <div className="d-flex flex-column align-items-start flex-wrap">
                    <div className="img-card-coffee">
                      <img className={`w-100 h-100 category-img`} src={`${selectedProductImage}?h=${tileWidth}`} />
                    </div>
                    <form className="mt-3" onSubmit={formik.handleSubmit}>
                      <div className="w-100 p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16 label-coffee">Quantity</label>
                      <input
                        type="text"
                        className="form-control-coffee h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                        name="quantity"
                        onChange={(e) => {
                          const inputValue = e.target.value;
                        
                          if (inputValue === "" || /^[1-9][0-9]{0,2}$/.test(inputValue)) {
                            formik.handleChange(e);
                        
                            if (inputValue === "") {
                              formik.setFieldValue('total', ""); // Clear the 'total' field if input is empty
                            } else {
                              formik.setFieldValue('total', `$${singleProducts[0].minimumPrice * inputValue}`);
                              handleAddQuantity(singleProducts[0], inputValue);
                            }
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.quantity}
                      />
                      {formik.touched.quantity && formik.errors.quantity ? (
                        <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.quantity}
                        </div>
                      ) : null}
                      </div>
                      <div className="d-flex justify-content-between w-100">
                        <Button
                          classNameValue="btn-back-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn font-16 font-sofia-pro-semi-bold"
                          children="Back"
                          showLoader={showLoader}
                          onClick={handleBack}
                        />
                        {handleAddRecipientsShow ? 
                        <Button
                          classNameValue="btn-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                          children="Next"
                          showLoader={showLoader}
                          onClick={() => {handleAddRecipientsShow(singleProducts[0], formik.values.quantity)}}
                          disabled={formik.values.quantity ? false : true}
                        />:
                        <Button
                          classNameValue="btn-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                          children="Next"
                          showLoader={showLoader}
                          onClick={() => {handleProceed()}}
                          disabled={formik.values.quantity ? false : true}
                        />}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
          </>
        )
        }
        </>
      )}
    </>
  );
};

export default TwoBrand;
