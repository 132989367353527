/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import OrganisationHeader from '../../../organisationheader/organisationHeader';
import httpService from '../../../../../services/HttpService';
import * as RegexPattern from '../../../../../utils/RegexPatterns';
import SendThanksService from '../../../../../services/SendThanksService';
import './organisationEmployeeThanksSuccess.scss';
import { orgResetSendThanksDetails } from '../../../../../store/reducers/organisation/sendThanks';
import OrganisationSidebar from '../../../organisationsidebar/organisationsidebar';
import AnonThanksSuccess from '../../../../../assets/images/dashboard/icons/anon-thanks-success-icon.svg';

function OrganisationEmployeeThanksSuccessPage(props) {
  const currentPath = window.location.pathname;

  const productType = props.location.state.productType;

  let userDetails = props?.userDetails;
  let role = props.type;

  const history = useHistory();

  const [userInfo, setUserInfo] = useState();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    let searchKeyword =
      userDetails?.recipients[0].userName === null
        ? userDetails?.recipients[0].email !== null
          ? userDetails?.recipients[0].email
          : userDetails?.recipients[0].phone
        : userDetails?.recipients[0].userName;
    try {
      let regexEmail = RegexPattern.EmailRegexValue;
      let regexMobile = RegexPattern.MobileRegexValue;

      let url = '';

      let data = {
        search: '',
        email: '',
        phone: '',
        userName: '',
        region: 'AU',
      };

      if (regexEmail.test(searchKeyword)) {
        data.email = searchKeyword;
        data.search = '';
        data.phone = '';
        data.userName = '';
        url = httpService.post(`/users/search`, data);
      } else if (regexMobile.test(searchKeyword)) {
        data.email = '';
        data.search = '';
        data.phone = searchKeyword;
        data.userName = '';
        url = httpService.post(`/users/search`, data);
      } else if (userDetails?.recipients[0].userName !== null && userDetails?.recipients[0].userName !== undefined) {
        data.email = '';
        data.search = '';
        data.phone = '';
        data.userName = searchKeyword;
        url = httpService.post(`/users/search`, data);
      } else {
        data.email = '';
        data.search = searchKeyword;
        data.phone = '';
        data.userName = '';
        url = httpService.post(`/users/search`, data);
      }

      const result = url
        .then(response => {
          if (response) {
            let data = response['data'];
            setUserInfo(data);
          }
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    } catch (error) {
      console.log('error response');
    }
  }, []);
  useEffect(() => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        res.data.map((val, index) => {
          if (val['type'] === productType) {
            setProduct(val);
          }
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const loopFieldErrors = data => {
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const sendAgainBtnHandler = () => {
    history.push('/organisation/employee/recipientInformation');
    props.resetData();
  };

  const homeBtnHandler = () => {
    props.resetData();
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 py-2 d-flex h-100 flex-column  ">
        {currentPath === '/organisation/employee/thanksSuccess' ? (
          <OrganisationSidebar menu="Sendthanks" />
        ) : (
          <OrganisationSidebar menu="Organisation" />
        )}
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half h-100vh">
        <OrganisationHeader role="admin" />
        <div className="m-0 p-0 row mt-5">
          <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half">
            <div className="p-0 m-0 w-100 d-flex payment-main">
              <div className="mt-4">
                <div className="mt-2 border-0  border-radius-5">
                  <div className="thansks-success-card">
                    <div className="my-2 d-flex justify-content-center">
                      {product !== null && product !== undefined ? (
                        <img src={product['iconLargeUrl'] + '?h=140&w=140'} alt="" />
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="my-2 d-flex flex-column justify-content-center thanks-text-cont">
                      <span className="font-30 monteserrat-bold color-black-pearl line-height-36">
                        You Have Successfully Sent
                        <br />
                        <span className="font-20 monteserrat-normal">
                          Thanks to&nbsp;
                          {userDetails?.recipients?.length > 0 && (
                            <>
                              {userInfo?.length > 0 && (
                                <>
                                  {userInfo[0]?.fullName !== ''
                                    ? userInfo[0]?.fullName
                                    : userDetails?.recipients[0]?.email !== null
                                    ? userDetails?.recipients[0]?.email
                                    : userDetails?.recipients[0]?.phone}
                                </>
                              )}
                              <span>
                                {' '}
                                {userDetails?.recipients.length > 1
                                  ? `and ${Number(userDetails.recipients.length) - 1} others`
                                  : ' '}
                              </span>
                            </>
                          )}
                        </span>
                        {/* {userDetails?.recipients?.length > 0 && (
                        <div className="font-20 monteserrat-normal">
                          Thanks to&nbsp; Craig Mair
                          {userInfo?.length > 0 && (
                            <span className="font-20 monteserrat-normal">
                              {userInfo[0]?.fullName !== ''
                                ? userInfo[0]?.fullName
                                : userDetails?.recipients[0].email !== null
                                ? userDetails?.recipients[0].email
                                : userDetails?.recipients[0].phone}
                            </span>
                          )}
                          <span>
                            {' '}
                            {userDetails?.recipients.length > 1
                              ? `and ${Number(userDetails.recipients.length) - 1} others`
                              : ' '}
                          </span>
                        </div>
                      )} */}
                      </span>
                      <p className="font-16 monteserrat-normal light-grey pt-3">
                        Congratulations! Your act of appreciation has set off a chain of positivity. Enjoy the warm
                        feeling of making someone feel truly valued.
                      </p>
                    </div>

                    <div className="p-0 m-0 mt-3 row text-center w-350 justify-content-between">
                      <div className="p-0 m-0  mb-1 send-again-div">
                        <button
                          type="submit"
                          className="w-150 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                          onClick={sendAgainBtnHandler}
                        >
                          Send again
                        </button>
                      </div>
                      <div className="p-0 m-0 mb-1 home-div pl-4">
                        <Link to={role === 'organisation' ? '/organisation/dashboard' : '/dashboard'}>
                          <button
                            className="w-150 h-60 p-0 m-0 border-radius-5  bg-transparent font-16  font-sofia-pro-semi-bold outline-none"
                            onClick={homeBtnHandler}
                          >
                            Home
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="thanks-success-icon">
                <img src={AnonThanksSuccess} alt="icon" className="thanks-success-icon-image" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="mt-4 col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 mt-2 card border-0  border-radius-5">
              <div className="card-body">
                <div className="my-2 d-flex justify-content-center">
                  {product !== null && product !== undefined ? (
                    <img src={product['iconLargeUrl'] + '?h=140&w=140'} alt="" />
                  ) : (
                    ''
                  )}
                </div>
                <div className="my-2 d-flex flex-column justify-content-center align-items-center">
                  <span className="font-24 font-sofia-pro-regular color-black-pearl">
                    You have successfully sent Thanks to
                    {userDetails?.recipients?.length > 0 && (
                      <div className="text-center">
                        {userInfo?.length > 0 && (
                          <span>
                            {userInfo[0]?.fullName !== ''
                              ? userInfo[0]?.fullName
                              : userDetails?.recipients[0].email !== null
                              ? userDetails?.recipients[0].email
                              : userDetails?.recipients[0].phone}
                          </span>
                        )}
                        <span>
                          {' '}
                          {userDetails?.recipients.length > 1
                            ? `and ${Number(userDetails.recipients.length) - 1} others`
                            : ' '}
                        </span>
                      </div>
                    )}
                    {userDetails?.recipients?.length === 0 &&
                      userDetails?.recipientCsvFileBase64 !== undefined &&
                      userDetails?.recipientCsvFileBase64 !== null && (
                        <span className="font-24 font-sofia-pro-regular color-black-pearl"> your employees</span>
                      )}
                  </span>
                  <p className="font-14 font-sofia-pro-light color-charcoal-grey text-center">
                    To cancel this transaction, you can do it through transactions history before user has redeemed the
                    product and before expiry date is achieved
                  </p>
                </div>

                <div className="p-0 m-0 mt-3 row text-center">
                  <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                    <button
                      type="submit"
                      className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      onClick={sendAgainBtnHandler}
                    >
                      Send again
                    </button>
                  </div>
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                    <Link to="/organisation/dashboard">
                      <button
                        className="w-180 h-60 p-0 m-0  border-radius-5  bg-transparent font-16 color-primary border-faded-pink font-sofia-pro-semi-bold outline-none"
                        onClick={homeBtnHandler}
                      >
                        Home
                      </button>
                    </Link>
                  </div>
                  <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
        </div> */}
      </div>
    </div>

    // <div className=" m-0 p-0 row ">
    //   <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 py-2 d-flex h-100 flex-column  ">
    //     <OrganisationSidebar menu="Sendthanks" />
    //   </div>
    //   <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half h-100vh">
    //     <OrganisationHeader role="employee" />
    //     <div className="p-0 m-0 row">
    //       <div className="col-lg-2">&nbsp;</div>
    //       <div className="mt-4 col-sm-12 col-md-12 col-lg-8">
    //         <div className="ml-2 mt-2 card border-0  border-radius-5">
    //           <div className="card-body">
    //             <div className="my-2 d-flex justify-content-center">
    //               {product !== null && product !== undefined ? (
    //                 <img src={product['iconLargeUrl'] + '?h=140&w=140'} alt="" />
    //               ) : (
    //                 ''
    //               )}
    //             </div>
    //             <div className="my-2 d-flex flex-column justify-content-center align-items-center">
    //               <span className="font-24 font-sofia-pro-regular color-black-pearl">
    //                 You have successfully sent Thanks to
    //                 {userDetails?.recipients?.length > 0 && (
    //                   <div className="text-center">
    //                     {userInfo?.length > 0 && (
    //                       <span>
    //                         {userInfo[0]?.fullName !== ''
    //                           ? userInfo[0]?.fullName
    //                           : userDetails?.recipients[0].email !== null
    //                           ? userDetails?.recipients[0].email
    //                           : userDetails?.recipients[0].phone}
    //                       </span>
    //                     )}
    //                     <span>
    //                       {' '}
    //                       {userDetails?.recipients.length > 1
    //                         ? `and ${Number(userDetails.recipients.length) - 1} others`
    //                         : ' '}
    //                     </span>
    //                   </div>
    //                 )}
    //               </span>
    //               <p className="font-14 font-sofia-pro-light color-charcoal-grey text-center">
    //                 To cancel this transaction, you can do it through transactions history before user has redeemed the
    //                 product and before expiry date is achieved
    //               </p>
    //             </div>

    //             <div className="p-0 m-0 mt-3 row text-center">
    //               <div className="p-0 m-0 col-lg-2">&nbsp;</div>
    //               <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
    //                 <button
    //                   type="submit"
    //                   className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
    //                   onClick={sendAgainBtnHandler}
    //                 >
    //                   Send again
    //                 </button>
    //               </div>
    //               <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
    //                 <Link to="/employee/dashboard">
    //                   <button
    //                     className="w-180 h-60 p-0 m-0  border-radius-5  bg-transparent font-16 color-primary border-faded-pink font-sofia-pro-semi-bold outline-none"
    //                     onClick={homeBtnHandler}
    //                   >
    //                     Home
    //                   </button>
    //                 </Link>
    //               </div>
    //               <div className="p-0 m-0 col-lg-2">&nbsp;</div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-2">&nbsp;</div>
    //     </div>
    //   </div>
    // </div>
  );
}

// MAPPING - SEND THANKS DATA

const mapStateToProps = state => {
  return {
    userDetails: state.multiProduct.details,
  };
};

// DISPATCH  - SEND THANKS DATA

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(orgResetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationEmployeeThanksSuccessPage);
