/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { toast } from 'react-toastify';
import OTPInput from '../../../../../common/OTP/OTPReader';
import http from '../../../../../services/HttpService';
import Toast from '../../../../../services/ToasterService';
import './Otp.scss';
import UsernameSuccessImage from '../../../../../assets/images/common/icons-check-filled-green.png';
import ReactGA from 'react-ga';

function OtpPage(props) {
  const query = new URLSearchParams(useLocation().search);

  const [OTP, setOTP] = useState('');

  const [checkResendBtnIsClicked, setcheckResendBtnIsClicked] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  // Validating OTP
  const handleNextBtn = () => {
    toast.dismiss();
    setShowLoader(true);
    if (OTP.length === 4) {
      let userData = JSON.parse(localStorage.getItem('userData'));
      const requestData = {};
      if (query.get('auth_type') === 'byemail' || query.get('auth_type') === 'bytoken') {
        requestData.email = userData.email; //encodeURIComponent(userData.email).replace('%20', '+');
      } else {
        requestData.phone = userData.phone;
        requestData.region = 'AU';
      }

      http
        .get(`/Users/bycode/${OTP}`, { params: requestData })
        .then(response => {
          props.moveToNextScreen();
          setShowLoader(false);
        })
        .catch(error => {
          setShowLoader(false);
          if (error.response) {
            let errorResponse = error.response['data'];
            setOTP('');
            if (errorResponse.globalErrors[0]) {
              let errorMessage = errorResponse.globalErrors[0].message;
              Toast(errorMessage, 'error');
            }
          }
        });
    } else {
      Toast('OTP length must be equal to 4 digit', 'error');
      setShowLoader(false);
    }
  };

  // Resend OTP
  const handleResendBtn = () => {
    toast.dismiss();
    setShowLoader(true);
    let userData = JSON.parse(localStorage.getItem('userData'));

    let data = {
      type: 'onetimepassword',
    };
    if (query.get('auth_type') === 'byemail' || query.get('auth_type') === 'bytoken') {
      data.email = userData.email; //encodeURIComponent(userData.email).replace('%20', '+');
    } else {
      data.phone = userData.phone;
      data.region = 'AU';
    }

    http
      .post('/users/tokens', data)
      .then(response => {
        setShowLoader(false);
        if (response.status === 200) {
          setcheckResendBtnIsClicked(true);
          setOTP('');
          setTimeout(() => {
            setcheckResendBtnIsClicked(false);
          }, 5000);
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponse = error.response['data'];
        if (errorResponse.globalErrors[0]) {
          let errorMessage = errorResponse.globalErrors[0].message;
          Toast(errorMessage, 'error');
        }
      });
  };

  const submitOTP = e => {
    if (e.key === 'Enter') handleNextBtn();
  };

  return (
    <div className="p-0 m-0">
      <div className="mt-3 d-flex justify-content-center align-items-center bg-alice-blue">
        <div className="p-0 m-0">
          <div className="card border-0 p-4">
            <span className="px-2 color-charcoal-grey font-32 font-sofia-pro-light text-center">
              We’ve sent OTP to your <br /> registered{' '}
              {query.get('auth_type') === 'byemail' || query.get('auth_type') === 'bytoken' ? 'email' : 'phone'}
            </span>
            {query.get('auth_type') === 'byemail' || query.get('auth_type') === 'bytoken' ? (
              <p className="mt-2 oslo-grey font-sofia-pro-light font-16 text-center">
                Don’t forget to check your spam mailbox
              </p>
            ) : (
              <></>
            )}
            <div className="mt-2 my-2 d-flex justify-content-between align-items-baseline">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                onKeyDown={submitOTP}
                OTPLength={4}
                otpType="number"
                disabled={false}
                inputClassName="otp-input__box font-sofia-pro-regular otp__placeholder border-light-periwinkle font-45 border-radius-5"
              />
            </div>
            <div className="mt-2">
              {/* <button className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16" onClick={handleNextBtn}>Verify</button> */}
              {/* <Button classNameValue="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16" children="Verify" showLoader={showLoader} onClick={() => handleNextBtn} /> */}
              {showLoader === false && (
                <button
                  type="submit"
                  onClick={handleNextBtn}
                  className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                >
                  Verify
                </button>
              )}
              {showLoader === true && (
                <button
                  className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                  disabled
                >
                  <div>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    <span className="pl-2">Loading</span>
                  </div>
                </button>
              )}
            </div>
            {checkResendBtnIsClicked === true ? (
              <div className="mt-2">
                <img src={UsernameSuccessImage} /> &nbsp;
                <span className="font-14 font-sofia-pro-regular color-gunmetal">
                  We’ve resent the code to your{' '}
                  {query.get('auth_type') === 'byemail' || query.get('auth_type') === 'bytoken'
                    ? 'email address'
                    : 'phone number'}{' '}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="mt-2 text-center">
            <span className="font-14 font-sofia-pro-regular color-charcoal-grey">
              Didn’t receive code?{' '}
              <span className="font-14 font-sofia-pro-regular color-primary cursor-pointer" onClick={handleResendBtn}>
                Resend
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtpPage;
