import React from 'react';

export const automaticallyReceiveAPromoCode = "You will automatically receive a promo code from us. Paste this code in the Promo Code field in Skip."

export const AddMyStoreInfo = () => (
  <div>
    <h5><strong>Add Your Store</strong></h5>
    When you add your store, you will need to be the owner or manager of a <strong>cafe, online store, or product. </strong>
    <br/>
    <br/>
    Our platform gives you the ability to sell your products to appreci's customer base. When you add your store you can also:
    <br/>
    <br/>
    <ul className="pl-4">
      <li>Manage staff (add and remove staff)</li>
      <li>Manage locations</li>
      <li>add additional stores</li>
      <li>Send gifts to your customers</li>
    </ul>
    If you'd like to know more please get in touch <a  href = "mailto: hello@appreci.io" className="color-primary">hello@appreci.io</a>
  </div>
)


export const AddMyOrganisationInfo= () => (
  <div>
    <h5><strong>Add Your Organisation</strong></h5>
    When you add your organisation, you will become the administrator of the account.
    <br/>
    <br/>
    Our platform gives you the ability to do the following:
    <br/>
    <br/>
    <ul className="pl-4">
      <li>Manage staff (add and remove staff)</li>
      <li>Top up corporate accounts</li>
      <li>Allocate budgets to staff</li>
      <li>Send gifts and messages on behalf of your organisation</li>
    </ul>
    If you'd like to know more please get in touch <a  href = "mailto: hello@appreci.io" className="color-primary">hello@appreci.io</a>
  </div>
)