/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Store
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore,persistReducer } from 'redux-persist';
import { rootReducer } from './rootReducer';
import storage from "redux-persist/lib/storage";

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
];
const persistConfig = {
  key: "root",
  version: 1,
  storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const initialState = window.___INITIAL_STATE__;

export const store = configureStore({
  reducer: persistedReducer,
  initialState,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

// export default store;

// persistor.purge();
