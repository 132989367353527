/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useEffect, useState, useMemo } from 'react';
import { BrowserRouter as Router, Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import http from '../../../../services/HttpService';
import { EmailRegexValue, AusMobileRegexValue } from '../../../../utils/RegexPatterns';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';
import AuthSliderNew from '../../../../common/AuthSlider/AuthSliderNew';
import Button from '../../../../common/Button/Button';
import Toast from '../../../../services/ToasterService';
import './Signup.scss';
import { Logo, Google, Facebook, HidePasswordImage, ShowPasswordImage, ErrorImage } from '../../../../assets/images';
import ReactGA from 'react-ga';


const SignupPage = () => {
  const AunonymousEmail = sessionStorage.getItem('GiftEmail');
  const AunonymousPhone = sessionStorage.getItem('GiftPhone');

  const history = useHistory();

  const location = useLocation();

  const [show, setshow] = useState(false);

  const [authType, setAuthType] = useState('byemail');

  const { height, width } = useWindowDimensions();

  const [showLoader, setShowLoader] = useState(false);

  const togglePassword = () => {
    setshow(!show);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const validate = values => {
    let errors = {};

    if (authType === 'byemail') {
      if (!values.email) {
        errors.email = 'Required';
      } else if (!EmailRegexValue.test(values.email)) {
        errors.email = 'Invalid email format';
      }
    } else {
      let re = AusMobileRegexValue;
      let phoneString = values.phone.toString();
      let firstTwoChar = phoneString.slice(0, 2);
      if (!values.phone) {
        errors.phone = 'Required';
      }
    }

    if (!values.password) {
      errors.password = 'Required';
    } else if (values.password.length < 8) {
      errors.password = ' Please enter at least 8 characters';
    }
    return errors;
  };

  // sending signup request
  const onSubmit = values => {
    toast.dismiss();
    setShowLoader(true);
    let data = values;
    if (data.password.trim().length > 0) {
      let errorMessage;
      data.deviceId = '';
      data.cookieless = true;
      data.acceptTermsConditions = true;
      data.firstName = '';
      data.lastName = '';
      data.region = 'AU';
      if (authType === 'byphone') delete data.email;
      http
        .post(`/Users/${authType}`, data)
        .then(function (response) {
          let responseData = response['data'];
          LoginRequest(data);
          setShowLoader(false);
        })
        .catch(error => {
          setShowLoader(false);
          console.log(error.response['data'], 'err');
          let errorResponse = error.response['data'];
          if (errorResponse.globalErrors[0]) {
            errorMessage = errorResponse.globalErrors[0].message;
            Toast(errorMessage, 'error');
            if (errorMessage === 'Already registered') {
              history.push('/login');
            }
          } else if (errorResponse.fieldErrors) {
            if (errorResponse.fieldErrors['phone']) {
              toast.error(errorResponse.fieldErrors['phone'][0]);
            } else if (errorResponse.fieldErrors['email']) {
              toast.error(errorResponse.fieldErrors['email'][0]);
            }
          }
        });
    } else {
      Toast('Password should contain atleast one character', 'error');
    }
  };

  // Background - login request to get access token
  const LoginRequest = data => {
    let errorMessage;
    if (typeof data.email == 'undefined') {
      delete data.email;
    }
    data.region = 'AU';
    http
      .post(`/UserSessions/${authType}`, data)
      .then(function (response) {
        let responseData = response['data'];
        localStorage.setItem('userData', JSON.stringify(responseData));
        history.replace(`/user-details?auth_type=${authType}`, true);
      })
      .catch(error => {
        let errorResponse = error.response['data'];
        if (errorResponse.globalErrors[0]) {
          errorMessage = errorResponse.globalErrors[0].message;
          Toast(errorMessage, 'error');
        }
      });
  };

  // Social login
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const email = query.get('email');
    const errordescription = query.get('errordescription');
    if (token && email) {
      const socialBody = {
        email: email,
      };
      http
        .post(`/UserSessions/bytoken/${token}`, socialBody)
        .then(function (response) {
          let responseData = response['data'];
          localStorage.setItem('userData', JSON.stringify(responseData));
          const userActivatedStatus = responseData.status;
          if (userActivatedStatus === 'Registered') {
            history.replace(`/user-details?auth_type=bytoken`, true);
          } else if (
            userActivatedStatus === 'Activated' &&
            (responseData.userName === null || responseData.userName === '')
          ) {
            history.replace(
              {
                pathname: '/user-details?auth_type=bytoken',
                state: { screenValue: 1 },
              },
              true
            );
            window.location.reload();
          } else {
            Toast('Login Success', 'success');
            if (sessionStorage.getItem('Anonymous') === 'anonymous') {
              history.replace('/redeemthanks', true);
            } else {
              history.replace('/dashboard', true);
            }
          }
        })
        .catch(error => {
          let errorResponse = error.response['data'];
          if (errorResponse.globalErrors[0]) {
            let errorMessage = errorResponse.globalErrors[0].message;
            Toast(errorMessage, 'error');
          }
        });
    }
    if (errordescription) {
      Toast(errordescription, 'error');
    }
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (AunonymousEmail !== 'null') {
      formik.setFieldValue('email', AunonymousEmail);
    } else if (AunonymousPhone !== 'null') {
      setAuthType('byphone');
      formik.setFieldValue('phone', AunonymousPhone);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      password: '',
    },
    onSubmit,
    validate,
  });

  const toggleAuthType = () => {
    setAuthType(authType === 'byemail' ? 'byphone' : 'byemail');
  };

  const validateNumber = e => {
    const re = /^[0-9 +\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      formik.setFieldValue('phone', e.target.value);
    }
  };

  return (
    <div className="p-0 m-0 row">
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-7">
        <AuthSliderNew />
      </div>
      <div
        className={
          width <= 768
            ? 'p-0 m-0 px-4 col-sm-12 col-md-12 col-lg-5 mt-md-5 pt-md-5 my-4 d-flex flex-column align-items-center justify-content-center signup-right__half'
            : 'p-0 m-0 px-4 col-sm-12 col-md-12 col-lg-5 d-flex flex-column align-items-center justify-content-center'
        }
      >
        <div className="mx-2 w-100">
          <div className="px-lg-3 px-md-3 mt-4 w-100 d-flex justify-content-between">
            <Logo />
            {sessionStorage.getItem('Anonymous') !== 'anonymous' ? (
              <Link to={'/login'}>
                <button className="btn btn-outline border-primary-color color-primary border-radius-5 font-sofia-pro-regular">
                  Sign in
                </button>
              </Link>
            ) : (
              ''
            )}
          </div>
          <div className="px-lg-3 px-md-3 my-2 mt-4 justify-content-start w-100">
            <span
              className={
                width > 1280
                  ? 'color-black-pearl font-40 font-sofia-pro-bold'
                  : 'color-black-pearl font-35 font-sofia-pro-bold'
              }
            >
              Create account
            </span>
          </div>
          <div className="px-lg-3 px-md-3 w-100">
            <form onSubmit={formik.handleSubmit}>
              <div className="p-0 m-0 mb-2 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">
                  {authType === 'byemail' ? 'Enter Email' : 'Phone number'}
                </label>
                {sessionStorage.getItem('Anonymous') !== 'anonymous' ? (
                  <span
                    onClick={toggleAuthType}
                    className="float-right font-sofia-pro-regular font-14 color-primary toggle-span"
                  >
                    {authType === 'byemail' ? 'User phone number instead' : 'User email instead'}
                  </span>
                ) : (
                  ''
                )}

                {authType === 'byemail' ? (
                  <>
                    <input
                      type="email"
                      className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle"
                      name="email"
                      placeholder="Ex: user@domain.com"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      readOnly={sessionStorage.getItem('Anonymous') === 'anonymous' ? true : false}
                    />

                    {formik.touched.email && formik.errors.email ? (
                      <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <>
                    <input
                      type="text"
                      className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle"
                      name="phone"
                      placeholder="Enter phone number"
                      onChange={validateNumber}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                      readOnly={sessionStorage.getItem('Anonymous') === 'anonymous' ? true : false}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
                      </div>
                    ) : null}
                  </>
                )}
              </div>
              <div className="p-0 m-0 mb-2 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">Enter Password</label>
                <div className="input-group">
                  <input
                    type={show ? 'text' : 'password'}
                    className="form-control h-60 font-sofia-pro-regular signup-password__placeholder border-light-periwinkle signup-password__input"
                    name="password"
                    placeholder="Choose a strong Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />
                  <div className="input-group-append signup-input__append__design">
                    {show ? (
                      <ShowPasswordImage
                        className="p-0 m-0 signup-password__icon__position"
                        height="25px"
                        onClick={togglePassword}
                      />
                    ) : (
                      <HidePasswordImage
                        className="p-0 m-0 signup-password__icon__position"
                        height="25px"
                        onClick={togglePassword}
                      />
                    )}
                  </div>
                </div>

                {formik.touched.password && formik.errors.password ? (
                  <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.password}
                  </div>
                ) : null}
              </div>
              <div className="w-100 mt-3">
                {/* <button type="submit" className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16">Create account</button> */}
                <Button
                  classNameValue="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                  children="Create account"
                  showLoader={showLoader}
                  onClick={() => onSubmit}
                />
              </div>
            </form>

            {/* Social Accounts */}
            {/*<div className="my-3 pt-2 signup-or__container">*/}
            {/*  <span className="font-sofia-pro-medium color-grey-blue font-14">OR</span>*/}
            {/*</div>*/}
            {/*<div className="my-1 text-center">*/}
            {/*  <span className="color-black-pearl font-sofia-pro-regular font-20">Sign up using social accounts</span>*/}
            {/*</div>*/}
            {/*<div className="mt-2 d-flex justify-content-center align-items-center">*/}
            {/*  <a href={`${process.env.REACT_APP_BASE_URL}/Users/byoauth/google?redirectUrl=/signup`}>*/}
            {/*    <Google className="mr-3" />*/}
            {/*  </a>*/}
            {/*  <a href={`${process.env.REACT_APP_BASE_URL}/Users/byoauth/facebook?redirectUrl=/signup`}>*/}
            {/*    <Facebook className="mr-3" />*/}
            {/*  </a>*/}
            {/*</div>*/}
            <div className="text-center mt-2">
              <span
                className={
                  width > 1280
                    ? 'color-grey-blue font-14 font-sofia-pro-regular'
                    : 'color-grey-blue font-11 font-sofia-pro-regular'
                }
              >
                By continuing you agree to Appreci’s
                <a
                  href="https://appreci.io/terms"
                  target="_blank"
                  className="pb-1 text-decoration-none border-bottom-grey-blue cursor-pointer color-grey-blue"
                >
                  {' '}
                  Terms & Conditions
                </a>{' '}
                and
                <a
                  href="https://appreci.io/privacy-policy/"
                  target="_blank"
                  className="pb-1 text-decoration-none border-bottom-grey-blue cursor-pointer color-grey-blue"
                >
                  {' '}
                  Privacy Policy
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
