/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';

// Sendthanks - Products list, Purchase API, Getting recipients from CSV file

const getListOfProducts = (region, type, isInstantRedeem) => {
  return httpService.get(`/Products?region=${region}`);
};

const purchaseProduct = data => {
  return httpService.post(`/Purchases`, data);
};

const purchaseIntent = data => {
  return httpService.post(`/Purchases/intent`, data);
};

const extractRecipientsFromCSV = data => {
  return httpService.post(`/purchases/recipients`, data);
};

export default {
  getListOfProducts,
  purchaseProduct,
  purchaseIntent,
  extractRecipientsFromCSV,
};
