/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import { useEffect } from 'react';
import  AnonUserSideBar from '../../../common/AnonUserSidebar/AnonUserSidebar';
// import Header from '../../../common/Header/Header';
import "../../AnonUserSidebar/AnonUserSidebar.scss"
const AnonymousLayout = ({ children, props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return {};
  }, []);
console.log("In the anon flow")
  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column ">
        <AnonUserSideBar menu={props?.menu} />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half hm-100vh">
        <div className='white-header-sidebar'></div>
        {children}
      </div>
    </div>
  );
};

export default AnonymousLayout;
