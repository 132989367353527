/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as WhyMerchantImage } from '../../../assets/images/account/why-merchant.svg';
import BackIcon from '../../../assets/images/common/icons-back.png';

function MerchantPlanPage() {
  return (
    <div className="w-100 bg-alice-blue">
      <div className="p-2">
        <Logo />
      </div>
      <div className="mx-lg-5 my-2 p-4 bg-white">
        <Link to={'/dashboard'}>
          <button className="ml-3  mb-2 btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none">
            <img src={BackIcon} height="30px" />
            Back
          </button>
        </Link>
        <div className="p-0 m-0 card border-0">
          <div className="mx-lg-3 mx-md-3 row">
            <div className="p-4 col-sm-12 col-md-4 col-lg-5 bg-pale-mavue-1">
              <div className="d-flex flex-column">
                <span className="font-32 font-sofia-pro-regular color-black-pearl">Why</span>
                <span className="font-32 font-sofia-pro-bold color-primary "> Merchant account?</span>
              </div>
              <div className="d-flex flex-column">
                <span className="font-16 font-sofia-pro-light color-black-pearl">
                  {' '}
                  Join over 250 Australian businesses in using our merchant digital marketing software.
                </span>
                <span className="mt-2 font-16 font-sofia-pro-light color-black-pearl">
                  See all gifts being redeemed at your establishment and have complete control of your payout frequency.
                </span>
              </div>
              <div className="mt-3">
                <WhyMerchantImage className="pr-2 d-flex justify-content-center" height="215px" width="auto" />
              </div>
            </div>
            <div className="col-sm-12 col-md-8 col-lg-7">
              <div>
                <div className="d-flex flex-column">
                  <span className="font-32 font-sofia-pro-light color-black-pearl">Start your</span>
                  <span className="font-32 font-sofia-pro-bold color-primary"> Free plan</span>
                </div>
              </div>
              <div className="p-0 m-0 mt-3 row">
                <div className="col-sm-12 col-md-9 col-lg-9">
                  <div className="p-3 bg-alice-blue">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="font-32 font-sofia-pro-bold color-black-pearl ">Free</span>
                      <span className="d-flex flex-column align-items-end">
                        <span className="font-24 font-sofia-pro-semi-bold color-black-pearl">$0</span>
                        <span className="font-18 font-sofia-pro-regular color-black-pearl">monthly</span>
                      </span>
                    </div>
                    <div className="mt-2">
                      <span className="font-20 font-sofia-pro-regular color-black-pearl">Benefits</span>
                      <ul className="mt-2 list-unstyled font-sofia-pro-light font-16 color-black-pearl line-height-42">
                        <li>- Add/manage users & permissions</li>
                        <li>- Purchase generic thanks from Appreci</li>
                        <li>- Purchase products (merchant and appreci’s owned)</li>
                        <li>- Allocate thanks to users</li>
                        <li>- Run bulk send events</li>
                        <li>- API integration to Teams, Slack and other external Saas products</li>
                      </ul>
                      <Link className="text-decoration-none" to="/merchant/onboarding/business">
                        <button className="w-100 my-2 shadow-none d-flex justify-content-center align-items-center btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white">
                          Create account
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-12 col-md-6 col-lg-6 ">
                                    <div className="p-3 bg-pale-mavue-1">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="font-32 font-sofia-pro-bold color-black-pearl ">Paid</span>
                                            <span className="d-flex flex-column align-items-end">
                                                <span className="font-24 font-sofia-pro-semi-bold color-black-pearl">$19.95</span>
                                                <span className="font-18 font-sofia-pro-regular color-black-pearl">monthly</span>
                                            </span>
                                        </div>
                                        <div className="mt-2">
                                            <span className="font-20 font-sofia-pro-regular color-black-pearl">Benefits</span>
                                            <ul className="list-unstyled font-sofia-pro-light font-16 color-black-pearl">
                                                <li>- Add/manage users & permissions</li>
                                                <li>- Purchase generic thanks from Appreci</li>
                                                <li>
                                                    - Purchase products (merchant and appreci’s owned)</li>
                                                <li>- Allocate thanks to users</li>
                                                <li>- Run bulk send events</li>
                                            </ul>
                                            <Link to="/merchant/onboarding/merchantDetails">
                                                <button className="my-2 shadow-none d-flex justify-content-center align-items-center btn bg-transparent font-16 font-sofia-pro-semi-bold w-100 border-primary-color color-primary">
                                                    Create account
                                            </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantPlanPage;
