/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import httpService from '../../../../../services/HttpService';
import './UserDetails.scss';
import ErrorImage from '../../../../../assets/images/error-icons/icons-error-info.png';

const initialValues = {
  firstName: '',
  lastName: '',
};

const validate = values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  }

  if (values.firstName.length > 25) {
    errors.firstName = 'Please enter the first name within 25 characters';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  }

  if (values.lastName.length > 25) {
    errors.lastName = 'Please enter the last name within 25 characters';
  }

  return errors;
};

function UserDetailsPage(props) {
  const history = useHistory();

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();

    let userData = JSON.parse(localStorage.getItem('userData'));

    let firstname = userData?.firstName !== null && userData?.firstName !== '' ? userData?.firstName : '';
    let lastname = userData?.lastname !== null && userData?.lastname !== '' ? userData?.lastname : '';

    formik.setFieldValue('firstName', firstname);
    formik.setFieldValue('lastName', lastname);
  }, []);

  // Updating user data
  const onSubmit = values => {
    let userData = JSON.parse(localStorage.getItem('userData'));

    if (props.isNameNotAvailable && userData.userName !== null) {
      let data = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: userData?.email !== null ? userData?.email : null,
        phone: userData?.phone !== null ? userData?.phone : null,
        userName: userData?.userName,
        region: 'AU',
      };

      httpService
        .put('/users/current', data)
        .then(response => {
          if (response.status === 200) {
            history.push('/dashboard');
            let userData = JSON.parse(localStorage.getItem('userData'));
            userData['firstName'] = values?.firstName;
            userData['lastName'] = values?.lastName;
            localStorage.setItem('userData', JSON.stringify(userData));
          }
        })
        .catch(error => {
          console.log('error ', error);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);

          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    } else {
      let userNameObj = values;
      sessionStorage.setItem('userNames', JSON.stringify(userNameObj));
      props.moveToNextScreen('test');
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  return (
    <div className="p-0 m-0 mt-3">
      <div className="d-flex justify-content-center align-items-center bg-alice-blue">
        <div className="p-0 m-0">
          <div className="card border-0 p-4">
            <span className="px-2 color-charcoal-grey font-32 font-sofia-pro-light text-center">
              Please enter your First and <br /> Last name
            </span>

            <form onSubmit={formik.handleSubmit}>
              <div className="my-2">
                <input
                  type="text"
                  ref={inputRef}
                  className="form-control h-60 font-sofia-pro-regular user-details__placeholder border-light-periwinkle"
                  placeholder="First name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  name="firstName"
                  autofocus
                />

                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="mt-2">
                <input
                  type="text"
                  className="form-control h-60 font-sofia-pro-regular user-details__placeholder border-light-periwinkle"
                  placeholder="Last name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  name="lastName"
                  autofocus
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
                  </div>
                ) : null}
              </div>

              <div className="mt-2">
                <button
                  type="submit"
                  className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                >
                  Proceed
                </button>
              </div>
            </form>
          </div>
          <div className="mt-2 text-center">
            <a
              href="https://appreci.io/privacy-policy"
              target="_blank"
              className="pb-1 text-decoration-none font-14 font-sofia-pro-regular color-primary"
            >
              Why do I need to provide this information?
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDetailsPage;
