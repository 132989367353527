import React from 'react';
import Button from '../../../common/Button/Button.js';
import ItemCheckedImage from '../../../assets/images/common/icons-check-filled-pink.png';

const GiftSelector = ({
  selectedCategory,
  showLoader,
  windowWidth,
  selectedImg,
  tileWidth,
  drinks,
  sweets,
  giftCards,
  hampers,
  handleCategoryClicked,
  onImgClick
}) => {
  return (
    <>
        <div className="d-flex flex-column position-relative titles-text" style={{ marginTop: '80px' }}>
            <span className="font-sofia-pro-regular font-32 color-black-pearl text-center choose-gift-type-text">
            Choose your appreci gift type
            </span>
            <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
            Select a gift from our collection that best suits the occasion.
            </p>
        </div>
        <div className="btn-flex-container">
            <Button
            classNameValue={`${
                selectedCategory == 'allProducts' ? 'btn-flex-pink-item' : 'btn-flex-item'
            } my-3 shadow-none d-flex justify-content-center align-items-center h-56  btn font-16 font-sofia-pro-light`}
            children="All products"
            showLoader={showLoader}
            onClick={() => {
                handleCategoryClicked('allProducts');
            }}
            />
            {drinks.length != 0 && <Button
            classNameValue={`${
                selectedCategory == 'drinks' ? 'btn-flex-pink-item' : 'btn-flex-item'
            } my-3 shadow-none d-flex justify-content-center align-items-center h-56  btn font-16 font-sofia-pro-light`}
            children="Drinks"
            showLoader={showLoader}
            onClick={() => {
                handleCategoryClicked('drinks');
            }}
            />}
            {sweets.length != 0 && <Button
            classNameValue={`${
                selectedCategory == 'sweets' ? 'btn-flex-pink-item' : 'btn-flex-item'
            } my-3 shadow-none d-flex justify-content-center align-items-center h-56  btn font-16 font-sofia-pro-light`}
            children="Sweet tooth"
            showLoader={showLoader}
            onClick={() => {
                handleCategoryClicked('sweets');
            }}
            />}
            {giftCards.length != 0 && <Button
            classNameValue={`${
                selectedCategory == 'giftCards' ? 'btn-flex-pink-item' : 'btn-flex-item'
            } my-3 shadow-none d-flex justify-content-center align-items-center h-56  btn font-16 font-sofia-pro-light`}
            children="Gift cards"
            showLoader={showLoader}
            onClick={() => {
                handleCategoryClicked('giftCards');
            }}
            />}
            {hampers.length != 0 && <Button
            classNameValue={`${
                selectedCategory == 'hampers' ? 'btn-flex-pink-item' : 'btn-flex-item'
            } my-3 shadow-none d-flex justify-content-center align-items-center h-56  btn font-16 font-sofia-pro-light`}
            children="Hampers"
            showLoader={showLoader}
            onClick={() => {
                handleCategoryClicked('hampers');
            }}
            />}
        </div>
        {drinks.length != 0 && selectedCategory == 'allProducts' || selectedCategory == 'drinks' ? (
            <div className="mt-3 mb-5 d-flex justify-content-center align-items-center flex-column">
            <span className="font-sofia-pro-regular font-24 color-black-pearl text-center">Drink gifts</span>
            <div
                className={`d-flex ${
                windowWidth <= 767 ? 'justify-content-between' : 'justify-content-around'
                } flex-wrap product-cards box-style`}
            >
                {drinks?.map((drink, index) => (
                <div className="d-flex flex-column align-items-start cursor-pointer">
                    {selectedImg?.type == drink.type ? (
                    <div className={`position-relative w-50`}>
                        <img
                        src={ItemCheckedImage}
                        height="25px"
                        width="25px"
                        className="select-item_checked_icon"
                        />
                    </div>
                    ) : (
                    ''
                    )}
                    <div
                    className="img-card"
                    onClick={() => {
                        onImgClick(drink, drink.productTypeIndex);
                    }}
                    >
                    <img
                        className={`w-100 h-100 ${
                        selectedImg.type == drink.type ? 'border-primary-color-2' : ''
                        } category-img`}
                        src={`${drink?.imagePath}?h=${tileWidth}`}
                    />
                    </div>
                    <span className="font-sofia-pro-regular font-18 color-black-pearl text-center product-title">
                    {drink?.type}
                    </span>
                </div>
                ))}
            </div>
            </div>
        ) : (
            ''
        )}
        {sweets.length != 0 && selectedCategory == 'allProducts' || selectedCategory == 'sweets' ? (
            <div className="mt-3 mb-5 d-flex justify-content-center align-items-center flex-column">
            <span className="font-sofia-pro-regular font-24 color-black-pearl text-center">
                Sweet tooth gifts
            </span>
            <div
                className={`d-flex ${
                windowWidth <= 767 ? 'justify-content-start' : 'justify-content-around'
                } flex-wrap product-cards box-style`}
            >
                {sweets?.map((sweet, index) => (
                <div className="d-flex flex-column align-items-start cursor-pointer">
                    {selectedImg?.type == sweet.type ? (
                    <div className={`position-relative w-50`}>
                        <img
                        src={ItemCheckedImage}
                        height="25px"
                        width="25px"
                        className="select-item_checked_icon"
                        />
                    </div>
                    ) : (
                    ''
                    )}
                    <div
                    className="img-card"
                    onClick={() => {
                        onImgClick(sweet, sweet.productTypeIndex);
                    }}
                    >
                    <img
                        className={`w-100 h-100 ${
                        selectedImg?.type == sweet.type ? 'border-primary-color-2' : ''
                        } category-img`}
                        src={`${sweet?.imagePath}?h=${tileWidth}`}
                    />
                    </div>
                    <span className="font-sofia-pro-regular font-18 color-black-pearl text-center product-title">
                    {sweet?.type}
                    </span>
                </div>
                ))}
            </div>
            </div>
        ) : (
            ''
        )}
        {giftCards.length != 0 && selectedCategory == 'allProducts' || selectedCategory == 'giftCards' ? (
            <div className="mt-3 mb-5 d-flex justify-content-center align-items-center flex-column">
            <span className="font-sofia-pro-regular font-24 color-black-pearl text-center">Gift cards</span>
            <div
                className={`d-flex ${
                windowWidth <= 767 ? 'justify-content-start' : 'justify-content-around'
                } flex-wrap product-cards box-style`}
            >
                {giftCards?.map((giftCard, index) => (
                <div className="d-flex flex-column align-items-start cursor-pointer">
                    {selectedImg?.type == giftCard.type ? (
                    <div className={`position-relative w-50`}>
                        <img
                        src={ItemCheckedImage}
                        height="25px"
                        width="25px"
                        className="select-item_checked_icon"
                        />
                    </div>
                    ) : (
                    ''
                    )}
                    <div
                    className="img-card"
                    onClick={() => {
                        onImgClick(giftCard, giftCard.productTypeIndex);
                    }}
                    >
                    <img
                        className={`w-100 h-100 ${
                        selectedImg?.type == giftCard.type ? 'border-primary-color-2' : ''
                        } category-img`}
                        src={`${giftCard?.imagePath}?h=${tileWidth}`}
                    />
                    </div>
                    <span className="font-sofia-pro-regular font-18 color-black-pearl text-center product-title">
                    {giftCard?.type}
                    </span>
                </div>
                ))}
            </div>
            </div>
        ) : (
            ''
        )}
        {hampers.length != 0 && selectedCategory == 'allProducts' || selectedCategory == 'hampers' ? (
            <div className="mt-3 mb-5 d-flex justify-content-center align-items-center flex-column">
            <span className="font-sofia-pro-regular font-24 color-black-pearl text-center">Hampers</span>
            <div
                className={`d-flex ${
                windowWidth <= 767 ? 'justify-content-start' : 'justify-content-around'
                } flex-wrap product-cards box-style`}
            >
                {hampers?.map((hamper, index) => (
                <div className="d-flex flex-column align-items-start cursor-pointer">
                    {selectedImg?.type == hamper.type ? (
                    <div className={`position-relative w-50`}>
                        <img
                        src={ItemCheckedImage}
                        height="25px"
                        width="25px"
                        className="select-item_checked_icon"
                        />
                    </div>
                    ) : (
                    ''
                    )}
                    <div
                    className="img-card"
                    onClick={() => {
                        onImgClick(hamper, hamper.productTypeIndex);
                    }}
                    >
                    <img
                        className={`w-100 h-100 ${
                        selectedImg?.type == hamper.type ? 'border-primary-color-2' : ''
                        } category-img`}
                        src={`${hamper?.imagePath}?h=${tileWidth}`}
                    />
                    </div>
                    <span className="font-sofia-pro-regular font-18 color-black-pearl text-center product-title">
                    {hamper?.type}
                    </span>
                </div>
                ))}
            </div>
            </div>
        ) : (
            ''
        )}
    </>
  );
}

export default GiftSelector;
