import React from 'react';
import { Router, useHistory } from 'react-router-dom';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import '../../../../components/individual/sendThanks/thanksSuccess/ThanksSuccess.scss';
import './forwardthanks.scss';
import { ReactComponent as CoffeeIllustraionImage } from '../../../../assets/images/send_thanks/coffee-illustration.svg';

const ForwardThanksSuccess = () => {
  const history = useHistory();

  const sendAgainBtnHandler = () => {
    history.push('/redeemthanks');
  };

  const homeBtnHandler = () => {
    history.push('/dashboard');
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 py-2 d-flex h-100 flex-column  ">
        <SideBar menu="Redeemthanks" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half h-100vh">
        <Header />
        <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="mt-4 col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 mt-2 card border-0  border-radius-5">
              <div className="card-body">
                <div className="my-2 d-flex justify-content-center">
                  <CoffeeIllustraionImage />
                </div>
                <div className="my-2 d-flex flex-column justify-content-center ">
                  <span className="font-24 font-sofia-pro-regular color-black-pearl test">
                    Thanks forwarded successfully!{' '}
                  </span>

                  <p className="font-14 font-sofia-pro-light color-charcoal-grey text-center">
                    You should be proud. We’ll notify you once your thanks has been redeemed
                  </p>
                </div>

                <div className="p-0 m-0 mt-3 row text-center">
                  <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                    <button
                      type="submit"
                      className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      onClick={sendAgainBtnHandler}
                    >
                      Forward more
                    </button>
                  </div>
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                    <button
                      className="w-180 h-60 p-0 m-0  border-radius-5  bg-transparent font-16 color-primary border-faded-pink font-sofia-pro-semi-bold outline-none"
                      onClick={homeBtnHandler}
                    >
                      Home
                    </button>
                  </div>
                  <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default ForwardThanksSuccess;
