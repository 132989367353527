import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import ItemCheckedImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import IconsInfoGrey from '../../../../assets/images/common/icons-info-grey.png';
import {
  oneMinusToCard,
  oneMoreToCard,
  resetCard
} from '../../../../store/reducers/multiProduct/multiProduct';
import ButtonBack from '../../../../common/Button/ButtonBack';
import SendThanksService from '../../../../services/SendThanksService';
import ModalInfoHorizontal from '../../../../common/component/modal/ModalInfoHorizontal';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SelectProduct = (props) => {
  const currentPath = window.location.pathname;

  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  // const productType = query.get('type');
  const productType = props.productType;

  const { productsCard, total } = props.multiProduct;

  const [itemList, setItemList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState();
  const [selectedProductIndex, setSelectedProductIndex] = useState();

  useEffect(() => {
    SendThanksService.getListOfProducts('AU', 'Unknown', true)
      .then(res => {
        let data = res['data'];
        let purchaseData = [];
        data?.forEach(value => {
          if (value.features === 'Purchase') {
            purchaseData.push(value);
          }
        });
        const productByType = purchaseData.filter(product => {
          return product.type === productType;
        });
        const joinedList = joinPricesRanges(productByType);

        if (productByType.length === 1) {
          setSelectedItemIndex(0);
          setProductsList(joinedList[0].ranges);
        }
        setItemList(joinedList);
      })
      .catch(error => {
        console.log('error ', error);
      });

      dispatch(resetCard());
  }, [productType]);

  const joinPricesRanges = (productList) => {
    const joined = productList.map(itemList => {
      const concat = itemList.ranges.map(product => {
        let joinWithPrice = {};
        const priceProduct = itemList.prices.filter(itemPrice => {
          return product.priceId === itemPrice.priceId;
        });

        if (!priceProduct.length) {
          joinWithPrice = { ...product, price: itemList.maximumPrice };
        } else {
          joinWithPrice = { ...priceProduct[0], ...product };
        }
        return joinWithPrice;
      });
      return { ...itemList, ranges: concat };
    });
    return joined;
  };

  const cancelBtnHandler = () => {
    history.push('/dashboard');
  };


  const selectedItems = index => {
    dispatch(resetCard());
    setProductsList(itemList[index].ranges);
    setSelectedProductIndex(null);
    setSelectedItemIndex(index);
  };

  const selectedProduct = (index, e) => {
    dispatch(resetCard());
    setSelectedProductIndex(index);
  };

  const handleOneMoreToCard = (product) => {
    dispatch(oneMoreToCard(product));
  };
  const handleOneMinusToCard = (product) => {
    dispatch(oneMinusToCard(product));
  };

  const checkout = () => {
    // const replacerPath = currentPath.replace('selectProduct', 'payment');
    // props.history.push(`${replacerPath}`);
  
    history.push("/anonrecipientInformation")
  };

  const QuantityButton = ({ product }) => {
    return (
      <div className='d-flex justify-content-between align-items-center'>
        <button className={'dashboard-card_redeem px-3 py-1 dashboard-button'}
                onClick={() => handleOneMinusToCard(product)}>
          <span className='dashboard__sendthanks font-20 font-sofia-pro-medium'> - </span>
        </button>
        <span className='font-14 font-weight-bold'>
          {productsCard.id === product.id ? productsCard.quantity : 0}
        </span>
        <button className={'dashboard-card_redeem  px-3 py-1 dashboard-button'}
                onClick={() => handleOneMoreToCard(product)}>
          <span className='dashboard__sendthanks font-20 font-sofia-pro-medium'> + </span>
        </button>
      </div>
    );
  };

  return (
    <>
      {/* <ButtonBack
        from='selectProduct'
        to='selectItem'
      /> */}

      <div className='mb-4 border-0 border-radius-5'>
        <div className='card-body px-0'>

          {/*Select the brand*/}
          <div>
            {/* <span className='font-sofia-pro-light color-charcoal-grey font-24'>Select the Product</span> */}
            <div className='p-0 my-2 mx-0 row select-item_main'>
              {itemList.map((product, index) => {
                return (
                  <div className='p-0 my-2 d-flex flex-column' key={index}>
                    <div
                      className='p-0 m-2 d-flex flex-column position-relative cursor-pointer'
                      key={index}
                      onClick={selectedItems.bind(this, index)}
                    >
                      <div className='position-relative w-150'>
                        {index === selectedItemIndex ? (
                          <img src={ItemCheckedImage} height='25px' width='25px'
                               className='select-item__checked_icon' alt=''/>
                        ) : null}
                      </div>
                      <img
                        className={
                          index === selectedItemIndex
                            ? 'border-primary-color-2 border-radius-11'
                            : 'border-white border-radius-17'
                        }
                        src={product.imageUrl + '?h=120'}
                        height='155px'
                        width='185px'
                        alt=''
                      />
                      <div className='w-150 mb-2 justify-content-between align-items-center'>
                        <div
                          className='p-0 m-0 mt-2 color-purple-brown font-16 font-sofia-pro-regular d-flex justify-content-between'>
                          <div>{product.name}</div>
                          {product.ranges.length ?
                            <img
                              onClick={() => setShowInfoModal(true)}
                              className='mt-2  cursor-pointer'
                              src={IconsInfoGrey}
                              height='20px'
                              width='20px'
                              alt=''
                            /> : null
                          }
                        </div>
                        <p className='p-0 m-0 oslo-grey font-12 font-sofia-pro-regular'>
                          {productType !== 'Coffee' ?
                            product.minimumPrice === product.maximumPrice ?
                              `$${product.minimumPrice}`
                              :
                              `$${product.minimumPrice} - $${product.maximumPrice}`
                            : `$${itemList[0].prices[0].price}`
                          }
                        </p>
                      </div>
                    </div>
                    {
                     ((productType === 'Coffee' || product?.prices?.length <= 1) && selectedItemIndex === index ) && <QuantityButton product={product} />
                    }
                  </div>
                );
              })}
              {itemList.length === 0 && (
                <div className='d-flex border-0 w-100 text-center justify-content-center'>
                  <Spinner animation='border' />
                </div>
              )}
            </div>
          </div>

          {/*Select product*/}
          {productsList.length && itemList[selectedItemIndex]?.prices?.length > 1  ?
            <div>
              <span className='font-sofia-pro-light color-charcoal-grey font-24'>Select price for {itemList[selectedItemIndex]?.name}</span>
              <div className='p-0 my-2 mx-0 row select-item_main'>
                {productsList.map((product, index) => {
                  return (
                    <div
                      className='p-0 m-0 m-2 d-flex flex-column position-relative'
                      key={index}
                    >
                      {index === selectedProductIndex ? (
                        <img src={ItemCheckedImage} height='25px' width='25px' className='select-subitem__checked_icon' />
                      ) : null}
                      <div style={{ backgroundColor: 'color' }}>
                        <img
                          className={
                            index === selectedProductIndex
                              ? 'border-primary-color-2 border-radius-11 cursor-pointer'
                              : 'border-white border-radius-17 cursor-pointer'
                          }
                          onClick={selectedProduct.bind(this, index)}
                          style={{ backgroundColor: product.color }}
                          src={product.imagePath + '?h=120'}
                          height='155px'
                          width='185px'
                          alt=''
                        />
                      </div>

                      <div className='w-150 mb-2 justify-content-between align-items-center'>
                        <div
                          className='p-0 m-0 mt-2 color-purple-brown font-16 font-sofia-pro-regular d-flex justify-content-between'>
                          <div>{product.name}</div>
                        </div>
                        <p className='p-0 m-0 oslo-grey font-12 font-sofia-pro-regular'>
                          {`$${ product.price }`}
                        </p>
                      </div>
                      {
                        index === selectedProductIndex
                          ?
                          <QuantityButton product={product} />
                          : null
                      }
                    </div>
                  );
                })}
              </div>
            </div>
            : null}
        </div>
      </div>

      <div className='p-0 m-3 text-right'>
        {/* <button className='p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium'
                onClick={cancelBtnHandler}>
          Cancel
        </button> */}
        <button
          type='submit'
          className='btn bg-primary-color h-44 color-white monteserrat-normal font-16 shadow-none w-140'
          disabled={productsCard.quantity > 0 ? false : true}
          onClick={checkout}
        >
          {!productsCard.quantity ?
            'Next' :
            `${productsCard.quantity} item${productsCard.quantity > 1 ?'s':''} ($${Math.floor(total * 100) / 100})`
          }
        </button>
      </div>

      <ModalInfoHorizontal
        show={showInfoModal}
        onHide={() => setShowInfoModal(false)}
        productList={itemList[selectedItemIndex]}
      />
    </>
  );
};

// MAPPING - multi product
const mapStateToProps = state => {
  return {
    multiProduct: state.multiProduct
  };
};

export default connect(mapStateToProps)(SelectProduct);
