/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// https://codepen.io/yomateo/pen/ypbNrJ
// https://codepen.io/elalemanyo/pen/wueft
// https://www.npmjs.com/package/react-media-recorder
// https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder#constructor
// https://wavesurfer-js.org/examples/

const AudioAnalyzer = (canvasRef, streamSrc = null, type = 'stream') => {
  // Constants
  const audioContext = new (window.AudioContext || window.webkitAudioContext)(); //new AudioContext();
  const analyser = audioContext.createAnalyser();
  const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);
  var distortion = audioContext.createWaveShaper();

  // Variables
  let input = null;
  if (type === 'stream') {
    input = audioContext.createMediaStreamSource(streamSrc);
  } else {
    input = audioContext.createMediaElementSource(streamSrc);
    input.connect(audioContext.destination);
  }
  let recorder = null;
  let recording = null;
  let isRecording = false;
  let isPlaying = false;

  // Setup analyser node
  analyser.smoothingTimeConstant = 0.3;
  analyser.fftSize = 1024;

  // Canvas variables
  const barWidth = 2;
  const barGutter = 2;
  const barColor = '#f203af';
  let canvas = null;
  let canvasContext = null;
  let bars = [];
  let width = 0;
  let height = 0;
  let halfHeight = 0;
  let drawing = false;

  canvas = canvasRef.current;
  if (!canvas) {
    return false;
  }
  canvasContext = canvas.getContext('2d');

  width = canvas.offsetWidth;
  height = canvas.offsetHeight;
  halfHeight = canvas.offsetHeight / 2;
  canvasContext.clearRect(0, 0, width, height);
  canvasContext.canvas.width = width;
  canvasContext.canvas.height = height;

  input.connect(analyser);
  analyser.connect(scriptProcessor);
  scriptProcessor.connect(audioContext.destination);
  //scriptProcessor.onaudioprocess = processInput; Deprecated function
  scriptProcessor.addEventListener('audioprocess', function (audioEvent) {
    processInput();
  });

  const processInput = audioProcessingEvent => {
    const array = new Uint8Array(analyser.frequencyBinCount);

    analyser.getByteFrequencyData(array);
    bars.push(getAverageVolume(array));

    if (bars.length <= Math.floor(width / (barWidth + barGutter))) {
      renderBars(bars);
    } else {
      renderBars(bars.slice(bars.length - Math.floor(width / (barWidth + barGutter))), bars.length);
    }
  };

  // Calculate the average volume
  const getAverageVolume = array => {
    const length = array.length;

    let values = 0;
    let i = 0;

    for (; i < length; i++) {
      values += array[i];
    }

    return values / length;
  };

  // Render the bars
  const renderBars = bars => {
    if (!drawing) {
      drawing = true;

      window.requestAnimationFrame(() => {
        canvasContext.clearRect(0, 0, width, height);

        bars.forEach((bar, index) => {
          canvasContext.fillStyle = barColor;
          canvasContext.fillRect(index * (barWidth + barGutter), halfHeight, barWidth, halfHeight * (bar / 100));
          canvasContext.fillRect(
            index * (barWidth + barGutter),
            halfHeight - halfHeight * (bar / 100),
            barWidth,
            halfHeight * (bar / 100)
          );
        });

        drawing = false;
      });
    }
  };
};
export default AudioAnalyzer;
