/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SendThanksService from '../../../../services/SendThanksService';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as Remove } from '../../../../assets/images/redeem_thanks/icons/icons-remove-16-px (1).svg';
import BitMap from '../../../../assets/images/dashboard/image/bitmap.jpg';
import Add from '../../../../assets/images/redeem_thanks/icons/icons-add-pink.png';
import BottomBar from '../Common/BottomBar';
import RedeemNowWine from './RedeemNowWine';
import '../../../individual/redeemThanks/redeemwine/RedeemWine.scss';

const RedeemWine = () => {
  const From = sessionStorage.getItem('FromName');
  const [quantityValue, setQuantityValue] = useState(1);
  const [product, setProduct] = useState(null);
  let AvailableQuantity = sessionStorage.getItem('Quantity');
  const [showModal, setShowModal] = useState(false);
  const [proceedRedeem, setProceedRedeem] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        res.data.map((val, index) => {
          if (val['type'] === 'Wine') {
            setProduct(val);
          }
        });
      })
      .catch(err => {
      });
  }, []);
  const PropsOnHide = () => {
    setShowModal(false);
    setQuantityValue(1);
  };

  const RedeemClicked = () => {
    sessionStorage.setItem('selectQuantity', quantityValue);
    setProceedRedeem(true)
  }

  if (proceedRedeem){
    return <RedeemNowWine />
  }

  const SelectQuantity = props => {
    return (
      <Modal {...props} size='md' centered className='redeemwine-background_popup' animation={false} backdrop='static'>
        <Modal.Body className='d-flex flex-column align-items-center '>
          <button className='border-0 bg-transparent redeem-quantity__cancel ' onClick={PropsOnHide}>
            <Close />
          </button>
          <span className='redeem-text__How-many-coffees-wo font-sofia-pro-medium'>
            How many wine’s would you like to redeem?
          </span>
          <div className='row justify-content-center my-4'>
            <button
              className='border-0 bg-transparent'
              onClick={() => (quantityValue > 1 ? setQuantityValue(quantityValue - 1) : '')}
            >
              <Remove />
            </button>
            <div className='redeem-box__selectedvalue d-flex justify-content-center align-items-center'>
              <span>{quantityValue}</span>
            </div>
            <button
              className='border-0 bg-transparent '
              onClick={() => (quantityValue < AvailableQuantity ? setQuantityValue(quantityValue + 1) : '')}
            >
              <img src={Add} />
            </button>
          </div>

            <button
              className='border-0 redeem-button__proceed my-3'
              onClick={RedeemClicked}
            >
              <span className='redeem-text__proceed font-sofia-pro-semi-bold'>Proceed</span>
            </button>
          <button className='border-0 bg-transparent' onClick={props.onHide}>
            <span className='redeem-text__Cancel font-sofia-pro-medium'>Cancel</span>
          </button>
        </Modal.Body>
      </Modal>
    );
  };
  // select quantity modal button handler
  const SelectQuantityBtn = () => {
    return (
      <>
        <button
          className='redeemwine-button__redeemnow border-0 my-2'
          onClick={() => {
            setShowModal(true);
          }}
        >
          <span className='redeemwine-text__redeemnow font-sofia-pro-semi-bold'>Redeem now</span>
        </button>
        <SelectQuantity show={showModal} onHide={() => setShowModal(false)} />
      </>
    );
  };

  return (
    <div className='d-flex flex-column align-items-center my-4'>
      <Logo />
      <div className="top-cont-wine">
        <div className=' redeemwine-card__small d-flex flex-column align-items-center my-4'>
          {product !== null ? (
            <img src={product['imageUrl'] + '?h=120&w=120'} alt='Wine' className='redeem-image__the_hidden_sea my-1' />
          ) : (
            ''
          )}
          <span className='redemmwine-text__thanks my-2 font-sofia-pro-bold'>A thanks from {From}</span>
          <span className='redeemwine-text__Hey-mate-Thanks-for  font-sofia-pro-light px-2'>
            {product !== null ? <span dangerouslySetInnerHTML={{ __html: product['description'] }} /> : ''}
          </span>
          <SelectQuantityBtn />
        </div>
      </div>
      <span className='redeemwine-text__How-people-are-using font-sofia-pro-bold'>
        How people are using <span className='text-style-1'>Appreci</span>
      </span>
      <span className='redeemwine-text__Appreci-can-be-used font-sofia-pro-light my-2 '>
        Appreci can be used in a variety of ways to show appreciation and <br /> gratitude, personally and
        professionaly.
      </span>
      <img src={BitMap} className='my-3' />
      <BottomBar />
    </div>
  );
};

export default RedeemWine;
