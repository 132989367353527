/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Moment from 'react-moment';
import merchantService from '../../../../services/MerchantService';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import Toast from '../../../../services/ToasterService';
import './Payout.scss';
import { ReactComponent as EmptyDataImage } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';

function MerchantPayoutPage() {
  const [payoutList, setPayoutList] = useState([]);
  const [payoutTotalAmount, setPayoutTotalAmount] = useState();
  let [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    getPayoutList(pageNumber);
    window.scrollTo(0, 0);
  }, []);

  // Get payout data
  const getPayoutList = pageNumber => {
    merchantService
      .getPayoutList(pageNumber)
      .then(response => {
        if (response) {
          let responseData = response['data'];

          if (pageNumber > 1) {
            Array.prototype.push.apply(payoutList, responseData);
          } else {
            setPayoutList(responseData);
          }

          setPayoutList(responseData);

          let totalAmount = 0;
          responseData?.data.forEach((payout, index) => {
            totalAmount += payout.amount;
          });

          setPayoutTotalAmount(totalAmount);
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Load data
  const LoadMoreData = () => {
    setPageNumber((pageNumber = pageNumber + 1));
    getPayoutList(pageNumber);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  // Check data is available
  const checkPayoutListDataAvailaleOrNot = () => {
    if (payoutList?.data?.length > 0) {
      return (
        <div className="p-0 m-0 row mt-3">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <span className="font-sofia-pro-light font-24 color-black-pearl">Payouts</span>
            <div className="my-2">
              <div className="card dashboard-card__redeemthanks my-3 table-responsive border-0 ">
                <table className="table dashboard-table_headcolor merchant-payout__table ">
                  <thead style={{ backgroundColor: '#fff2fa ' }}>
                    <tr className="mx-2">
                      <td scope="col" className="font-sofia-pro-semi-bold">
                        Date
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Payout ID
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Amount
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Transactions
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {payoutList?.data?.map((payout, index) => {
                      return (
                        <tr key={index}>
                          <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                            <Moment format="DD/MM/YYYY">{payout.paidOn}</Moment>
                          </td>
                          <td className=" font-sofia-pro-regular font-14 color-primary">
                            <Link
                              className="color-primary text-decoration-none"
                              to={`/merchant/payout/${payout.id}/${payout.paidOn}/${payout.payoutId}`}
                            >
                              {payout.payoutId}
                            </Link>
                          </td>
                          <td className="font-sofia-pro-regular font-14 color-black-pearl">{payout.amount}</td>
                          <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                            {payout.redemptionCount}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {payoutList?.totalCount >= 50 && (
                <span
                  className="font-14 color-primary border-bottom-primary-color font-sofia-pro-medium"
                  onClick={LoadMoreData}
                >
                  Show more
                </span>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mt-1">
            <div className="p-0 m-0 mt-5 card border-0">
              <div className="p-0 m-0 p-3 card-body d-flex flex-column">
                <span className="color-purple-brown font-20 font-sofia-pro-medium">Payout</span>
                <span className="font-56 color-black-pearl font-sofia-pro-medium">
                  ${Number(payoutTotalAmount) > 0 ? Number(payoutTotalAmount.toFixed(2)) : 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-0 m-0 row mt-3 h-100 d-flex flex-column justify-content-center align-items-center">
          <EmptyDataImage />
          <span className="my-2 font-14 font-sofia-pro-medium">Uh-oh, no payout found.</span>
        </div>
      );
    }
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar menu="Merchant" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh">
        <Header menu="Merchant" />

        {checkPayoutListDataAvailaleOrNot()}
      </div>
    </div>
  );
}

export default MerchantPayoutPage;
