/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import DashboardService from '../../../../services/DashboardService';
import SendThanksService from '../../../../services/SendThanksService';
import TransactionService from '../../../../services/TransactionHistoryService';
import { DateRange } from 'react-date-range';
import StackedBar from '../../../individual/dashboard/StackChart';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import { Modal } from 'react-bootstrap';
import './Transactions.scss';
import { ReactComponent as Components1 } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-2.svg';
import { ReactComponent as Components2 } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/common/icons-misc-search.svg';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const MerchantTransactionsPage = () => {
  const [merchantMenuIndex, setMerchantMenuIndex] = useState(0);

  const [merchantDashboardData, setMerchantDashboardData] = useState();

  const [merchantTransactionsData, setMerchantTransactionData] = useState(0);

  const [selectedFilter, setSelectedFilter] = useState();

  const [productsList, setProductsList] = useState();

  const [grpahData, setGraphData] = useState([]);

  const [selectedGraphFilterValue, setSelectedGraphFilterValue] = useState();

  const [graphLabel, selectedGraphLabel] = useState([]);

  const [customDatePicker, setCustomDatePicker] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection',
    },
  ]);

  const [showDatePicker, setShowDatePicker] = useState(false);

  const handleCloseDatePicker = () => setShowDatePicker(false);
  const handleShowDatePicker = () => setShowDatePicker(true);

  const [transactionList, setTransactionList] = useState();

  const [searchText, setSearchText] = useState('');

  let [existingTransactionList, setExistingTransactionList] = useState([]);

  useEffect(() => {
    loadMerchantDashboardData();
    getGraphData();
    loadTransactionList();
    window.scrollTo(0, 0);
  }, []);

  const loadMerchantDashboardData = () => {
    DashboardService.getMerchantDashboardData()
      .then(response => {
        if (response) {
          let data = response['data'];

          SendThanksService.getListOfProducts('AU').then(response => {
            if (response) {
              let productData = response['data'];
              setProductsList(data);
              if (productData?.length > 0) {
                productData.forEach((product, index) => {
                  data?.lastThreeRedemptions.forEach((redemption, index) => {
                    if (product?.price?.priceId === redemption?.priceId) {
                      redemption['type'] = product?.type;
                    }
                  });
                });
              }

              setMerchantDashboardData(data);
            }
          });
          let transactionsCount = data?.widgets.filter(widget => {
            return widget.name === 'Transactions';
          });
          setMerchantTransactionData(transactionsCount[0].number);
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const loadTransactionList = () => {
    TransactionService.getRedemptionsList(1)
      .then(response => {
        if (response) {
          let responseData = response['data'];

          SendThanksService.getListOfProducts('AU').then(response => {
            if (response) {
              let productData = response['data'];
              setProductsList(responseData);
              if (productData?.length > 0) {
                productData.forEach((product, index) => {
                  responseData?.data.forEach((transaction, index) => {
                    if (product?.price?.priceId === transaction?.priceId) {
                      transaction['type'] = product?.type;
                    }
                  });
                });
              }

              setTransactionList(responseData?.data);
              setExistingTransactionList(responseData?.data);
            }
          });
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const widgetFilterBtnHandler = type => {
    setSelectedFilter(type);

    let from, to;

    if (type === 'week') {
      from = moment().startOf('week').toDate();
      to = moment().endOf('week').toDate();
    } else if (type === 'month') {
      from = moment().startOf('month').toDate();
      to = moment().endOf('month').toDate();
    } else if (type === 'year') {
      from = moment().startOf('year').toDate();
      to = moment().endOf('year').toDate();
    } else if (type === 'custom') {
      handleShowDatePicker();
    }

    from = moment(from).format('YYYY-MM-DD');
    to = moment(to).format('YYYY-MM-DD');

    DashboardService.getMerchantDashboardWidget(from, to)
      .then(response => {
        if (response) {
          let data = response['data'];
          let transactionsCount = data?.filter(widget => {
            return widget.name === 'Transactions';
          });
          setMerchantTransactionData(transactionsCount[0].number);
        }
      })
      .catch(error => {
        console.log('errors ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const getGraphData = e => {
    let type = e === undefined ? 'Daily' : e.target.value;
    setSelectedGraphFilterValue(type);
    DashboardService.getMerchantDashboardGraphData(type).then(response => {
      if (response) {
        let data = response['data'];
        setGraphData(
          data.map(val => {
            return val['number'];
          })
        );
        // setGraphData(data);

        let labels = [];
        if (type === 'Daily') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('DD/M/YY'));
          });
        } else if (type === 'Yearly') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('YYYY'));
          });
        } else if (type === 'Monthly') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('MMMM'));
          });
        }

        selectedGraphLabel(labels);
      }
    });
  };

  const customDatePickerEventHandler = e => {
    setCustomDatePicker([e.selection]);

    let from = moment(e.selection.startDate).format('YYYY-MM-DD');
    let to = moment(e.selection.endDate).format('YYYY-MM-DD');

    if (from !== to) {
      handleCloseDatePicker();
      DashboardService.getMerchantDashboardWidget(from, to)
        .then(response => {
          if (response) {
            let data = response['data'];
            let transactionsCount = data?.filter(widget => {
              return widget.name === 'Transactions';
            });
            setMerchantTransactionData(transactionsCount[0].number);
          }
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);

          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const handleChange = value => {
    if (value.trim() !== '') {
      setSearchText(value);
      searchTransaction(value);
    } else {
      setSearchText('');
    }
  };

  const searchTransaction = value => {
    // console.log('existing transaction list ', existingTransactionList);

    setTransactionList(existingTransactionList);

    const excludeColumns = ['Date'];

    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setTransactionList(existingTransactionList);
    else {
      const filteredData = existingTransactionList.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setTransactionList(filteredData);
    }
  };

  const Transaction = () => {
    if (transactionList?.length === 0) {
      return (
        <div className="p-0 m-0 row mx-3 mb-3">
          <div className=" col-sm-12 col-md-12 col-lg-12 ">
            <div className="card border-0">
              <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
              <div className="dashboard-components1">
                <Components2 />
              </div>
              <div className="dashboard-components1 my-2">
                <span className="dashboard__trysendingyourfir font-sofia-pro-medium my-2">
                  Try sending your first transaction
                  {/* <span className="dashboard-text__style1">Try now</span> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-0 m-0 row mx-3 mb-3">
          <div className=" col-sm-12 col-md-12 col-lg-12 ">
            <div className="card border-0 dashboard-transaction_card">
              <div className="table-responsive">
                <table className="table dashboard-table_headcolor border-bottom-pale-grey">
                  <thead style={{ backgroundColor: '#fff2fa ' }}>
                    <tr className="mx-2">
                      <td scope="col" className="font-sofia-pro-semi-bold">
                        Transaction ID
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Date
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Location
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Product
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Price
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Quantity
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Total
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Payout Id
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList?.map((transaction, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              {transaction.transferId}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              <Moment format="DD/M/YYYY">{transaction.redeemedOn}</Moment>
                            </span>
                          </td>
                          <td>
                            <Link
                              className="color-primary text-decoration-none"
                              to={`/merchant/transaction/${transaction.locationId}`}
                            >
                              <span className="font-14 font-sofia-pro-regular color-primary">
                                {transaction.location}
                              </span>
                            </Link>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">{transaction.type}</span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              ${transaction.amount}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              {transaction.quantity}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              ${transaction.total}
                            </span>
                          </td>
                          <td>
                            <Link
                              className="color-primary text-decoration-none"
                              to={`/merchant/payout/${transaction.payoutId}/${transaction.redeemedOn}/${transaction.payoutStripeId}`}
                            >
                              <span className="font-14 font-sofia-pro-regular color-primary">
                                {transaction.payoutStripeId}
                              </span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  function ChartState() {
    if (grpahData?.length === 0) {
      return (
        <div>
          <div className="row mx-2 ">
            <span className="dashboard-redeemed__thankschar p-2 font-sofia-pro-medium ">Redeemed Chart</span>
          </div>
          <div className="dashboard-components1">
            <Components1 />
          </div>
          <div className="dashboard-components1">
            <span className="dashboard__uhohnochartsyet font-sofia-pro-medium">Uh-oh,no charts yet!</span>
            <div className="my-2">
              <span className="dashboard__trysendingthanks font-sofia-pro-light">
                Try sending thanks to your mate <span>&#128521;</span>
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-2">
          <div className="p-0 m-0 row justify-content-between align-items-center">
            <span className="p-0 m-0 col-lg-6 col-md-10 font-sofia-pro-medium font-20 ">Daily Payout</span>
            <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-end ">
              <div className="my-1">
                <div className="my-1">
                  <select
                    className="form-control shadow-none border-cloudy-blue font-14 font-sofia-pro-light color-grey-blue"
                    value={selectedGraphFilterValue}
                    onChange={getGraphData}
                  >
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-chart">
            <StackedBar data={grpahData} labels={graphLabel} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 mt-4 d-flex  flex-column ">
        <SideBar menu="Merchant" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half ">
        <Header menu="Merchant" />
        <div className=" p-0 m-0 mt-2 my-2 row mx-3">
          <div className=" col-sm-12 col-md-8 col-lg-8 my-4">
            <div className="card border-0 ">
              <ChartState />
            </div>
          </div>
          <div className="mt-4 col-sm-12 col-md-4 col-lg-4   dashboard-mobile_card">
            <div className="card px-2 border-0 pt-sm-2 pt-md-2 dashboard-card_hieght">
              <span className="dashboard__availablethanks font-sofia-pro-medium mx-2 my-lg-2 my-md-2  ">
                Transactions
              </span>
              <span className="dashboard__available_thanksvalue font-sofia-pro-medium mx-2 my-md-2 my-lg-0">
                {transactionList?.length}
              </span>
            </div>
          </div>
        </div>
        <div className="p-0 m-0 row mb-3 mx-4 px-2 d-flex justify-content-end">
          {/* <div className="d-flex merchant-transactions-filter__btn">
                        <button type="button" className={selectedFilter === 'week' ? "mr-2 font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none" : "mr-2 font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none"} onClick={widgetFilterBtnHandler.bind(this, 'week')}>This week</button>
                        <button type="button" className={selectedFilter === 'month' ? "mr-2 font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none" : "mr-2 font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none"} onClick={widgetFilterBtnHandler.bind(this, 'month')}>This month</button>
                        <button type="button" className={selectedFilter === 'year' ? "mr-2 font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none" : "mr-2 font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none"} onClick={widgetFilterBtnHandler.bind(this, 'year')}>This Year</button>
                        <button type="button" className={selectedFilter === 'custom' ? "mr-2 d-flex justify-content-center align-items-center font-14 font-sofia-pro-semi-bold btn btn-outline-primary border-primary-color color-primary shadow-none" : "mr-2 d-flex justify-content-center align-items-center font-14 font-sofia-pro-regular btn btn-outline-primary border-grey-blue color-grey-blue shadow-none"} onClick={widgetFilterBtnHandler.bind(this, 'custom')}>
                            {selectedFilter === 'custom' ? <DatePickerActive /> : <DatePickerInActive />}
                            <span className="pl-1">Custom</span>
                        </button>
                    </div> */}
          <div>
            <div className="input-group input-group-sm">
              <div className="input-group-prepend transaction-search__icon">
                <div className="pl-2">
                  <SearchIcon />
                </div>
              </div>
              <input
                type="text"
                placeholder="Search transaction"
                className="mr-2 h-48 form-control form-input-placeholder border-0 bg-white transaction-search__input shadow-none"
                value={searchText}
                onChange={e => handleChange(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Transaction />
      </div>

      <Modal
        className="border-0 merchant-dashboard__tour__modal modal-background-shadow"
        size="md"
        show={showDatePicker}
        onHide={handleCloseDatePicker}
      >
        <DateRange
          editableDateInputs={true}
          onChange={customDatePickerEventHandler}
          moveRangeOnFirstSelection={false}
          ranges={customDatePicker}
        />
      </Modal>
    </div>
  );
};

export default MerchantTransactionsPage;
