/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Organisation allocate credits reducer
import { persistReducer, PURGE, REHYDRATE, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const ALLOCATE_CREDIT_DETAILS = 'ALLOCATE_CREDIT_DETAILS';

const RESET_ALLOCATE_CREDIT_DETAILS = 'RESET_ALLOCATE_CREDIT_DETAILS';

export const allocateCreditDetails = data => ({
  type: ALLOCATE_CREDIT_DETAILS,
  payload: {
    data,
  },
});

export const resetAllocateCreditDetails = data => ({
  type: RESET_ALLOCATE_CREDIT_DETAILS,
  payload: {
    data,
  },
});

let initialState = {
  allocateData: {
    message: '',
    paymentSource: {
      token: '',
      cardId: '',
      setCardAsDefault: true,
      useCredit: true,
    },
    amount: 0,
    employeeId: 0,
  },
};

let allocateData;

export const allocateCreditReducer = persistReducer(
  {
    storage,
    key: 'appreci-allocate',
    whitelist: ['allocateData'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case 'ALLOCATE_CREDIT_DETAILS':
        const allocateDetails = action.payload.data;
        allocateData = {
          ...state.allocateData,
        };

        allocateData.amount = allocateDetails.amount;
        allocateData.employeeId = allocateDetails.employeeId;
        allocateData.message = allocateDetails.message;
        allocateData.paymentSource.cardId = allocateDetails.paymentSource.cardId;
        allocateData.paymentSource.setCardAsDefault = allocateDetails.paymentSource.setCardAsDefault;
        allocateData.paymentSource.token = allocateDetails.paymentSource.token;
        allocateData.paymentSource.useCredit = allocateDetails.paymentSource.useCredit;
        allocateData = {
          ...state.allocateData,
        };

        return {
          allocateData,
        };

      case 'RESET_SEND_THANKS_DETAILS':
        storage.removeItem('persist:appreci-allocate');
        state = undefined;

        return { state };
      default:
        return state;
    }
  }
);
