/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { geocodeByLatLng, getLatLng } from 'react-google-places-autocomplete';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { toast } from 'react-toastify';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import MerchantService from '../../../../services/MerchantService';
import EditStoreLocationPage from './EditLocation';
import Button from '../../../../common/Button/Button';
import Toast from '../../../../services/ToasterService';
import SearchLocationInput from '../../../../common/GooglePlaces/PlacesSearch';
import LocationService from '../../../../services/LocationService';
import './Locations.scss';
import { ReactComponent as AddIconWhite } from '../../../../assets/images/common/icons-add-white.svg';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as EmptyDataImage } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';

const initialValues = {
  name: '',
};

const validate = values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  return errors;
};

const MerchantStoreLocationsPage = () => {
  const [show, setShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState();
  const { value, setValue } = useState();

  const [existingAddress, setExistingAddress] = useState();

  const [addressData, setAddressData] = useState({
    latitude: 0,
    longitude: 0,
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  });

  let markers = [];

  const [storesList, setStoresList] = useState([]);

  const [storeData, setStoreData] = useState({});

  const [markerLocation, setMarkerLocation] = useState({
    lat: -20.917574,
    lng: 142.702789,
  });

  const mapStyles = {
    height: '50vh',
    width: '100%',
  };

  const [defaultCenter, setDefaultCenter] = useState({
    lat: -23.442,
    lng: 144.249,
  });

  const [showLoader, setShowLoader] = useState(false);

  const [deleteLoader, setDeleteLoader] = useState(false);

  const [selectedLocationIndex, setSelectedLocationIndex] = useState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [checkUserStorePublicalyAvailable, setCheckUserStorePublicalyAvailable] = useState(true);

  const [checkUserRedeemOption, setCheckUserRedeemOption] = useState(true);

  const [showRedeemOptionPopup, setShowRedeemOptionPopup] = useState(false);

  const [showPublicalyAvailablePopup, setShowPublicalyAvailablePopup] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs',
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    formik.resetForm();
    setShow(true);
    setShow(false);
    setShow(true);
    getGeoLocation();
  };

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  const showRedeemModalPopupHandler = () => {
    setShowRedeemOptionPopup(true);
  };
  const hideRedeemModalPopupHandler = () => setShowRedeemOptionPopup(false);

  const showPublicalyAvailableModalPopupHandler = () => {
    setShowPublicalyAvailablePopup(true);
  };
  const hidePublicalyAvailableModalPopupHandler = () => setShowPublicalyAvailablePopup(false);

  const showDeleteModalHandler = () => setShowDeleteModal(true);
  const hideDeleteModalHandler = () => setShowDeleteModal(false);

  useEffect(() => {
    getMerchantStoreList();
  }, []);

  // Get store list
  const getMerchantStoreList = () => {
    MerchantService.getStoresList()
      .then(response => {
        if (response) {
          let data = response['data'];
          if (data === 0) {
            setStoresList([]);
          } else {
            setStoresList(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Get user location
  const getGeoLocation = () => {
    const geolocation = window.navigator && window.navigator.geolocation;

    if (geolocation) {
      geolocation.getCurrentPosition(
        position => {
          setMarkerLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          setDefaultCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },

        error => {
          setDefaultCenter({
            lat: -23.442,
            lng: 144.249,
          });

          setMarkerLocation({
            lat: -23.442,
            lng: 144.249,
          });
        }
      );
    }
  };

  // Get lat, lng from map
  const onClickMap = event => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const position = { lat, lng };

    setMarkerLocation({
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    });

    setDefaultCenter({
      lat: lat,
      lng: lng,
    });

    markers.push(position);

    geocodeByLatLng(position).then(response => {
      const address = response[0].formatted_address;
      let city, state, country, postCode, street;
      for (let i = 0; i < response[0].address_components.length; i++) {
        for (let j = 0; j < response[0].address_components[i].types.length; j++) {
          switch (response[0].address_components[i].types[j]) {
            case 'locality':
              city = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
            case 'administrative_area_level_1':
              state = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
            case 'country':
              country = response[0].address_components[i].long_name
                ? response[0].address_components[i].short_name
                : null;
              break;
            case 'postal_code':
              postCode = response[0].address_components[i].long_name
                ? response[0].address_components[i].long_name
                : null;
              break;
            case 'route':
              street = response[0].address_components[i].long_name ? response[0].address_components[i].long_name : null;
              break;
          }
        }
      }
      setAddressData({
        latitude: lat,
        longitude: lng,
        state: state,
        postcode: postCode,
        country: country,
        street: street,
        suburb: city,
      });

      setExistingAddress(address);
    });
  };

  // Add new location
  const onSubmit = values => {
    toast.dismiss();
    setShowLoader(true);

    let data = values;

    data['address'] = addressData;

    data['isActive'] = checkUserRedeemOption;

    data['isPublic'] = checkUserStorePublicalyAvailable;

    data['address']['latitude'] = markerLocation.lat;

    data['address']['longitude'] = markerLocation.lng;

    MerchantService.createNewStoreLocation(data)
      .then(response => {
        setShowLoader(false);
        if (response) {
          handleClose();
          getMerchantStoreList();
          Toast('New location added in your account', 'success');
          formik.resetForm();
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  // Get address
  const getAddress = data => {
    getLatitudeLongitude(data);

    let addressData = LocationService.getLocationAddress(data);

    if (addressData.street === undefined) {
      Toast('Please add street address', 'error');
      return;
    }

    if (addressData.city === undefined) {
      Toast('Please add city address', 'error');
      return;
    }

    if (addressData.state === undefined) {
      Toast('Please add state address', 'error');
      return;
    }

    if (addressData.country === undefined) {
      Toast('Please add country address', 'error');
      return;
    }

    if (addressData.postCode === undefined) {
      Toast('Please add post code address', 'error');
      return;
    }

    setAddressData({
      street: addressData.street,
      suburb: addressData.city,
      state: addressData.state,
      country: addressData.country,
      postcode: addressData.postCode,
    });
  };

  // Get latitude and longitude
  const getLatitudeLongitude = data => {
    getLatLng(data).then(({ lat, lng }) => {
      setMarkerLocation({
        lat: parseFloat(lat),
        lng: parseFloat(lng),
      });

      setDefaultCenter({
        lat: lat,
        lng: lng,
      });
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate,
  });

  // Get store data
  const getStoreData = id => {
    setEditModalId(id);
    MerchantService.getStore(id).then(response => {
      if (response) {
        let data = response['data'];
        setStoreData(data);
        handleEditModalShow();

        formik.setFieldValue('name', data.name);
        formik.setFieldValue('description', data.address);
        setCheckUserRedeemOption(data.isActive);
        setCheckUserStorePublicalyAvailable(data.isPublic);
      }
    });
  };

  const deleteLocationData = index => {
    showDeleteModalHandler();
    setSelectedLocationIndex(index);
  };

  // Delete location
  const deleteLocationModalBtnHandler = () => {
    toast.dismiss();
    setDeleteLoader(true);
    let id = storesList[selectedLocationIndex].id;
    MerchantService.deleteStore(id)
      .then(response => {
        setDeleteLoader(false);
        if (response) {
          Toast('Your store has been de activated successfully.', 'success');
          getMerchantStoreList();
          hideDeleteModalHandler();
        }
      })
      .catch(error => {
        setDeleteLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const onValueChangeRedeemCheckboxOption = e => {
    setCheckUserRedeemOption(e.target.checked);

    if (e.target.checked === false) {
      showRedeemModalPopupHandler();
    }
  };

  const onValueChangeForUserPublicAvailable = e => {
    setCheckUserStorePublicalyAvailable(e.target.checked);
    if (e.target.checked === false) {
      showPublicalyAvailableModalPopupHandler();
    }
  };

  const cancelRedeemModalPopupHandler = () => {
    setCheckUserRedeemOption(true);
    hideRedeemModalPopupHandler();
  };

  const cancelPublicalyAvailableModalPopupHandler = () => {
    setCheckUserStorePublicalyAvailable(true);
    hidePublicalyAvailableModalPopupHandler();
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar menu="Merchant" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh">
        <Header menu="Merchant" />
        <div>
          <div className="p-0 m-0 row mt-4 my-2 mx-4 px-2 d-flex justify-content-between">
            <span className="font-sofia-pro-light font-24 color-black-pearl">Store Locations</span>
            <button className="btn bg-primary-color color-white font-sofia-pro-semi-bold font-16" onClick={handleShow}>
              <AddIconWhite />
              <span className="pl-2">Add new</span>
            </button>
          </div>
          <div className="my-2">
            <div className="card dashboard-card__redeemthanks my-3 table-responsive border-0 ">
              <table className="table dashboard-table_headcolor ">
                <thead style={{ backgroundColor: '#fff2fa ' }}>
                  <tr className="mx-2">
                    <td scope="col" className="font-sofia-pro-semi-bold">
                      Name
                    </td>
                    <td scope="col" className="font-sofia-pro-semi-bold  ">
                      Address
                    </td>
                    <td scope="col" className="font-sofia-pro-semi-bold  ">
                      Status
                    </td>
                    <td scope="col" className="font-sofia-pro-semi-bold ">
                      Actions
                    </td>
                  </tr>
                </thead>
                {storesList?.length > 0 && (
                  <tbody>
                    {storesList.map((store, index) => {
                      return (
                        <tr key={index}>
                          <td className=" font-sofia-pro-regular font-14 color-black-pearl">{store.name}</td>
                          <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                            <span className="d-block"> {store.address} </span>
                          </td>
                          <td className="d-flex align-items-center font-sofia-pro-regular font-14 color-black-pearl">
                            <p
                              className={
                                store.isActive === true
                                  ? 'p-0 m-0 store-locations__active'
                                  : 'p-0 m-0 store-locations__inactive'
                              }
                            ></p>
                            <span className="pl-1">{store.isActive === true ? 'Active' : 'In Active'}</span>
                          </td>
                          <td className=" font-sofia-pro-regular">
                            <div className="d-flex">
                              <button
                                className="p-2 bg-lavender-blush-1 border-primary-color border-radius-5"
                                onClick={getStoreData.bind(this, store.id)}
                              >
                                <span className="font-sofia-pro-regular font-16 color-primary">Edit</span>
                              </button>
                              <button
                                className="ml-1 p-2 bg-white border-color-pearl border-radius-5"
                                onClick={deleteLocationData.bind(this, index)}
                              >
                                <span className="font-sofia-pro-regular font-16 color-black-pearl">Delete</span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {storesList?.length === 0 && (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                  <EmptyDataImage />
                  <span className="my-2 font-14 font-sofia-pro-medium">
                    Uh-oh, no store locations found,
                    <span
                      className="font-14 font-sofia-pro-semi-bold color-primary cursor-pointer"
                      onClick={handleShow}
                    >
                      Try creating one
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="pt-4 border-0
         modal-background-shadow merchant-store__location__modal"
        size="lg"
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Add a new location</span>
            <span className="font-14 color-black-pearl font-sofia-pro-regular">
              Please provide more information about your new cafe
            </span>
          </div>
          <button type="button" className="border-0 merchant-store-modal__close__icon" onClick={handleClose}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="p-0 m-0 row">
            <div className="p-0 m-0 col-sm-12 col-md-6 col-lg-6">
              <form
                className="mt-3"
                onSubmit={formik.handleSubmit}
                onKeyPress={e => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              >
                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Cafe name *</label>
                  <input
                    type="text"
                    className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle"
                    name="name"
                    placeholder="Eg: Manly Guild"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                      <img className="mr-1" src={ErrorImage} /> {formik.errors.name}
                    </div>
                  ) : null}
                </div>
                <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>
                  {isLoaded === true && (
                    <SearchLocationInput loadAddress={getAddress} data={existingAddress} setIsMapLoaded={true} />
                  )}
                </div>

                <div className="d-flex mt-2">
                  <label className="merchant-location__container">
                    <input
                      type="checkbox"
                      name="redeemOption"
                      checked={checkUserRedeemOption === true}
                      onChange={onValueChangeRedeemCheckboxOption}
                    />
                    <span className="merchant-location__checkmark"></span>
                  </label>

                  <span className="font-14 color-gunmetal font-sofia-pro-regular">Allow users to redeem thanks</span>
                </div>
                <div className="d-flex mt-2">
                  <label className="merchant-location__container">
                    <input
                      type="checkbox"
                      name="publicalyAvailable"
                      checked={checkUserStorePublicalyAvailable === true}
                      onChange={onValueChangeForUserPublicAvailable}
                    />
                    <span className="merchant-location__checkmark"></span>
                  </label>

                  <span className="font-14 color-gunmetal font-sofia-pro-regular">Show cafe location on map</span>
                </div>
                <div className="d-flex mt-2">
                  <Button
                    classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                    children="Save"
                    showLoader={showLoader}
                    onClick={() => onSubmit}
                  />
                </div>
              </form>
            </div>
            <div className="p-0 m-0 p-3 col-sm-12 col-md-6 col-lg-6">
              <div style={{ width: '100%' }}>
                {isLoaded === true ? (
                  <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter} onClick={onClickMap}>
                    <Marker position={markerLocation} />
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0 modal-background-shadow merchant-store__location__modal"
        backdrop="static"
        size="lg"
        show={editModalShow}
        onHide={handleEditModalClose}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Edit existing location</span>
            <span className="font-14 color-black-pearl font-sofia-pro-regular">
              Please provide accurate information about your cafe
            </span>
          </div>
          <button type="button" className="border-0 merchant-store-modal__close__icon" onClick={handleEditModalClose}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <EditStoreLocationPage
            id={editModalId}
            closeModal={handleEditModalClose}
            loadMerchantList={getMerchantStoreList}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0
         modal-background-shadow employee-modal"
        size="md"
        backdrop="static"
        show={showDeleteModal}
        onHide={hideDeleteModalHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              Are you sure you want to remove {storesList[selectedLocationIndex]?.name} ?
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={hideDeleteModalHandler}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={deleteLocationModalBtnHandler}
            >
              Remove
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={hideDeleteModalHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0
         modal-background-shadow employee-modal"
        size="md"
        backdrop="static"
        show={showRedeemOptionPopup}
        onHide={hideRedeemModalPopupHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              {' '}
              Are you sure you do not want users to redeem at your store?
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={cancelRedeemModalPopupHandler}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={hideRedeemModalPopupHandler}
            >
              Ok
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={cancelRedeemModalPopupHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0
         modal-background-shadow employee-modal"
        size="md"
        backdrop="static"
        show={showPublicalyAvailablePopup}
        onHide={hidePublicalyAvailableModalPopupHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              Are you sure your store should not be visible on the map?
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={cancelPublicalyAvailableModalPopupHandler}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={hidePublicalyAvailableModalPopupHandler}
            >
              Ok
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={cancelPublicalyAvailableModalPopupHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MerchantStoreLocationsPage;
