/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import http from '../../../../services/HttpService';
import LocationService from '../../../../services/LocationService';
import * as RegexPattern from '../../../../utils/RegexPatterns';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import Button from '../../../../common/Button/Button';
import '../../../individual/redeemThanks/RedeemBeer/redeemDetails/RedeemDetails.scss';

const AddressDetails = ({ setRedeemedGift }) => {
  const httpService = http;
  const ProductId = sessionStorage.getItem('GiftProductId');
  const Quantity = sessionStorage.getItem('SelectedQuantity');
  const [addressSelect, setAddressSelect] = useState(false);
  const [address, setAddress] = useState();
  const [query, setQuery] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const autoCompleteRef = useRef(null);
  const redeemInfo = JSON.parse(sessionStorage.getItem('redeemInfo'));

  const initialValues = {
    firstName: null,
    lastName: null,
    mobile: null,
    email: null,
    street: null,
    suburb: null,
    state: null,
    postcode: null,
    country: null,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // use google map for address auto completion
  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, [query]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validate = values => {
    const errors = {};
    let re = RegexPattern.EmailRegexValue;
    let ph = RegexPattern.MobileRegexValue;

    if (!values.firstName) {
      errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    } else if (values.lastName.length > 15) {
      errors.lastName = 'Must be 15 characters or less';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (!re.test(values.email)) {
      errors.email = 'Invalid email format';
    }

    if(values.mobile === null) {
      formik.setFieldValue("mobile", "+61402981808")
    } else if (!ph.test(values.mobile)) {
      errors.mobile = 'Invalid mobile number';
    }

    if (!values.postcode) {
      errors.postcode = 'Required';
    }

    if (!values.state) {
      errors.state = 'Required';
    }
    if (!values.suburb) {
      errors.suburb = 'Required';
    }
    if (!values.street) {
      errors.street = 'Required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
  });

  // spliting location based on limit
  useEffect(() => {
    if (address !== undefined && addressSelect !== true) {
      formik.values.street = LocationService.getLocationAddress(address).street;
      formik.values.suburb = LocationService.getLocationAddress(address).city;
      formik.values.state = LocationService.getLocationAddress(address).state;
      formik.values.postcode = LocationService.getLocationAddress(address).postCode;
    }
  }, [address, formik, addressSelect]);

  let autoComplete = [];

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current);
    autoComplete.setFields(['address_components', 'formatted_address']);
    autoComplete.setComponentRestrictions({
      country: ['au'],
    });
    autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery));
  }

  function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    setAddress(autoComplete.getPlace());
    setAddressSelect(true);
  }

  const RedeemBeer = () => {
    let data = Address;
    if (
      ProductId !== null &&
      ProductId !== undefined &&
      formik.values.postcode !== null &&
      formik.values.postcode !== undefined &&
      formik.values.postcode.length > 0 &&
      formik.values.street !== null &&
      formik.values.street !== undefined &&
      formik.values.street.length > 0 &&
      formik.values.suburb !== null &&
      formik.values.suburb !== undefined &&
      formik.values.suburb.length > 0 &&
      formik.values.state !== null &&
      formik.values.state !== undefined &&
      formik.values.suburb.length > 0 &&
      formik.values.mobile.trim().length > 0
    ) {
      setShowLoader(true);
      httpService
        .post(`/gifts/bycode/${redeemInfo.code}/redeem`, data)
        .then(res => {
          setRedeemedGift(true);
          window.scrollTo(0, 0);
          setShowLoader(false);
        })
        .catch(error => {
          setShowLoader(false);
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          let errorResponse = error.response['data'];
          if (errorResponse.globalErrors[0]) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          } else if (errorResponse.fieldErrors) {
            if (errorResponse.fieldErrors['delivery.Phone']) {
              toast.error(errorResponse.fieldErrors['delivery.Phone'][0]);
            } else if (errorResponse.fieldErrors['delivery.Email']) {
              toast.error(errorResponse.fieldErrors['delivery.Email'][0]);
            }
          } else {
            console.log(error);
          }
        });
    } 
    else if (
      formik.values.street === null ||
      formik.values.street === undefined ||
      formik.values.street.length <= 0
    ) {
      toast.error('Please add a street');
    } else if (
      formik.values.postcode === null ||
      formik.values.postcode === undefined ||
      formik.values.postcode.length <= 0
    ) {
      toast.error('Please add a postcode');
    } else if (
      formik.values.suburb === null ||
      formik.values.suburb === undefined ||
      formik.values.suburb.length <= 0
    ) {
      toast.error('Please add a suburb');
    } else if (formik.values.state === null || formik.values.state === undefined || formik.values.state.length <= 0) {
      toast.error('Please add a state');
    } else if (formik.values.mobile === null) {
      console.log('mobile', formik.values.mobile);
      formik.setFieldValue("mobile", "+61402981808")
    } 
  };
  let Address;
  useEffect(() => {
    Address = {
      quantity: Quantity,
      rangeId: ProductId,
      delivery: {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        email: redeemInfo?.giftEmail ? redeemInfo?.giftEmail : formik.values.email,
        phone: formik.values.mobile !== null ? formik.values.mobile : "+61402981808",
        address: {
          street: formik.values.street,
          suburb: formik.values.suburb,
          state: formik.values.state,
          postcode: formik.values.postcode,
          country: 'Australia',
          region: 'AU',
        },
      },
    };
  });

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0   d-flex flex-column">
        <div className="d-flex flex-column addressdetails-rectangle__big">
          <span className="font-sofia-pro-medium redeemnow-text__Personal-details">Personal details</span>

          <div className="d-flex flex-column  my-2">
            <label className="redeemnow-text__fullname font-sofia-pro-regular">First name</label>
            <input
              className="redeemnow-input p-3"
              placeholder="Enter your first name "
              name="firstName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              autoComplete="off"
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
              </div>
            ) : null}
          </div>

          <div className="d-flex flex-column  my-2">
            <label className="redeemnow-text__fullname font-sofia-pro-regular">Last name</label>
            <input
              placeholder="Enter your last name "
              className="redeemnow-input p-3"
              name="lastName"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              autoComplete="off"
            />

            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
              </div>
            ) : null}
          </div>

          <div className="d-flex flex-column  my-2">
            <label className="font-sofia-pro-regular addressdetails-label__headertext">Mobile number</label>
            <input
              className="redeemnow-input p-3"
              placeholder="Eg: 0478983456  "
              type="mobile"
              name="mobile"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              autoComplete="off"
            />
            {formik.touched.mobile && formik.errors.mobile ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.mobile}
              </div>
            ) : null}
          </div>

          {!redeemInfo.giftEmail && (
            <div className="d-flex flex-column my-2">
              <label className="redeemnow-text__fullname font-sofia-pro-regular">Email address</label>

              <input
                placeholder="Eg: hello@appreci "
                className="redeemnow-input p-3"
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={redeemInfo?.giftEmail}
                autoComplete="off"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                  <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                </div>
              ) : null}
            </div>
          )}
          <span className="redeemnow-text__Delivery-details my-2 font-sofia-pro-medium">Delivery details</span>
          {addressSelect !== false ? (
            <div>
              <div className="d-flex flex-column align-items-start my-2">
                <label className="redeemnow-text__fullname font-sofia-pro-regular">Street name</label>

                <input
                  type="text"
                  placeholder="Eg: The Corso  "
                  className="redeemnow-input p-3"
                  name="street"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.street}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex flex-column align-items-start my-2">
                <label className="redeemnow-text__fullname font-sofia-pro-regular">Suburb</label>
                <input
                  type="text"
                  name="suburb"
                  className="redeemnow-input p-3"
                  placeholder="Eg: Manly"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.suburb}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex flex-column align-items-start my-2"></div>
              <div className="d-flex flex-column align-items-start my-2">
                <label className="redeemnow-text__fullname font-sofia-pro-regular">State / Province / Region</label>
                <input
                  placeholder="Eg: NSW "
                  className="redeemnow-input p-3"
                  type="text"
                  name="state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                  autoComplete="off"
                />
              </div>
              <div className="d-flex flex-column align-items-end">
                <div className="d-flex flex-column align-items-start">
                  <label className="redeemnow-text__fullname font-sofia-pro-regular">Postcode</label>
                  <input
                    placeholder="Eg: 2122"
                    className="redeemnow-input p-3"
                    type="text"
                    name="postcode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.postcode}
                    autoComplete="off"
                  />
                </div>
                <button
                  className="redeemnow-button__entermanualy my-3 "
                  onClick={() => {
                    setAddressSelect(!addressSelect);
                  }}
                >
                  <span className="redeemnow-text__enterman font-sofia-pro-semi-bold  ">Find address</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-end">
              <div className="d-flex flex-column align-items-start">
                <input
                  className="redeemnow-input p-3"
                  ref={autoCompleteRef}
                  name="query"
                  onChange={e => setQuery(e.target.value)}
                  placeholder="Eg: 6, The Corso Manly, Sydney, NSW, 2122"
                  value={query}
                  autoComplete="off"
                />
              </div>
              <button
                className="redeemnow-button__entermanualy my-3 "
                onClick={() => {
                  setAddressSelect(!addressSelect);
                }}
              >
                <span className="redeemnow-text__enterman font-sofia-pro-semi-bold  ">Enter Manually</span>
              </button>
            </div>
          )}
          <Button
            classNameValue={'border-0 addressdetails-button__proceed m-3 bg-primary-color color-white'}
            showLoader={showLoader}
            onClick={RedeemBeer}
          >
            <span className="redeem-text__proceed font-sofia-pro-semi-bold">Redeem</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddressDetails;
