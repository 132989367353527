/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {ReactComponent as Upload} from '../../assets/images/common/upload-icon.svg';

function VideoDropzone(props) {
  const maxSize = 104857600; // 100MB

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      props.rejectedFiles(rejectedFiles);
    }

    if (acceptedFiles.length > 0) {
      props.acceptedFiles(acceptedFiles);
    }
  }, []);

  const acceptedVideoFormats = ['video/mp4', 'video/mp3', 'video/quicktime', 'video/avi', 'video/webm', 'audio/wav', 'video/mpeg', 'video/avc'];

  const { isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles, getFilesFromEvent } =
    useDropzone({
      onDrop,
      minSize: 0,
      maxSize,
    //   noClick: true,
      noKeyboard: true,
      accept: acceptedVideoFormats.join(','),
      multiple: props.multiple,
    });

  const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

  const getFiles = getFilesFromEvent?.map(file => {
    console.log('file ', file);
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} onChange={props.getusersListFile} onClick={e => (e.target.value = null)} />
        <div className="d-flex flex-column align-items-center">
          {isDragActive && !isDragReject ? (
            <span className="font-14 font-sofia-pro-semi-bold color-gunmetal">
              Drop your video file here...
            </span>
          ) : !isDragReject && (
            <>
                <Upload/>
                <span className='text-center font-sofia-pro-semi-bold font-12 color-gunmetal cursor-pointer'>Click or drag video file to this area to upload File 100MB max (.mov, mp4)</span>
            </>
          )}
          <input {...getInputProps()} onChange={props.getusersListFile} onClick={e => (e.target.value = null)} />
          {isDragReject && (
            <span className="mt-3 pl-3 font-14 font-sofia-pro-light color-gunmetal">
              "File type must be a supported video format!"
            </span>
          )}
          {isFileTooLarge && (
            <div className="font-16 font-sofia-pro-semi-bold color-thatch-green">File is too large.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoDropzone;
