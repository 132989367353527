import React from "react";
import SearchBar from '../../../common/Header/SearchBar';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';


const CreateMilestoneHeader = () => {
    return (
        <div className={`dashboard-search__rectanglebig`}>
        <div>
            <Logo className='d-flex justify-content-center p-1' style={{width: '100%'}}/>
        </div>
        </div>
      );
}
export default CreateMilestoneHeader;