/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFormik } from 'formik';
import axios from 'axios';
import { EmailRegexValue, AusMobileRegexValue } from '../../../../utils/RegexPatterns';
import OTPInput from '../../../../common/OTP/OTPReader';
import './ForgotPassword.scss';
import { ReactComponent as Logo } from '../../../../assets/images/logo/logo-colour.svg';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as ForgotPasswordLeftPanelImage } from '../../../../assets/images/forgot_password/forgot-password.svg';
import UsernameSuccessImage from '../../../../assets/images/common/icons-check-filled-green.png';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

function ForgotPasswordPage() {
  let data = {};

  const history = useHistory();

  const [showEmailSentMessage, setShowEmailSentMessage] = useState(false);

  const [forgotPasswordData, setForgotPasswordData] = useState({});

  const [authType, setAuthType] = useState({
    authMethod: 'byemail',
  });

  const [minutesValue, setMinutesValue] = useState();
  const [secondsValue, setSecondsValue] = useState();
  const [enableResendLink, setEnableResendLink] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [checkOTPSent, setCheckOTPSent] = useState(false);
  const [checkResendBtnIsClicked, setcheckResendBtnIsClicked] = useState(false);
  const [userData, setUserData] = useState();
  const [OTP, setOTP] = useState('');
  const [tokenForMobile, setTokenForMobile] = useState();

  const validate = values => {
    let errors = {};

    if (authType.authMethod === 'byemail') {
      let re = EmailRegexValue;

      if (!values.email) {
        errors.email = 'Required';
      } else if (!re.test(values.email)) {
        errors.email = 'Invalid email format';
      }
    } else {
      let re = AusMobileRegexValue;
      let phoneString = values.phone.toString();
      let firstTwoChar = phoneString.slice(0, 2);
      if (!values.phone) {
        errors.phone = 'Required';
      } else if (values.phone.toString().length !== 10) {
        errors.phone = 'Phone number should be 10 digits';
      } else if (firstTwoChar !== '04') {
        errors.phone = 'Phone number extension is invalid';
      }
    }

    return errors;
  };

  // Sending forgot password request for both email and mobile
  const onSubmit = values => {
    if (showEmailSentMessage === false) {
      data['email'] = values.email !== '' ? values.email : null;
      data['phone'] = values.phone !== '' ? values.phone : null;
      data['region'] = values.phone !== '' ? 'AU' : null;
      data['Type'] = authMethod === 'byphone' ? 'onetimepassword' : 'password';

      setForgotPasswordData(data);

      if (authMethod === 'byphone') {
        delete data.email;
      }

      axios
        .post('/users/tokens', data)
        .then(response => {
          if (authMethod === 'byphone') {
            setCheckOTPSent(true);
            data.phone = '+61' + data.phone;
            setUserData(data);
          } else {
            setTimeout(() => {
              setShowEmailSentMessage(true);
            }, 3000);
            toast.success('Link sent successfully');
            timer(300);
            setShowTimer(true);
          }
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (errorResponseFieldErrors) {
            if (errorResponseFieldErrors.length > 0) {
              errorResponseFieldErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                console.log('error global response message ', msg);
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  // Verify OTP
  const handleNextBtn = () => {
    toast.dismiss();
    if (OTP.length === 4) {
      axios
        .get(`/users/tokens/bycode/${OTP}?phone=${urlencode(userData.phone)}&region=au`)
        .then(response => {
          let responseData = response['data'];
          setTokenForMobile(responseData);
          history.push(`/users/resetPassword?Mobile=${userData.phone}&Token=${responseData}`);
        })
        .catch(error => {
          if (error.response) {
            let errorResponse = error.response['data'];
            let errorMessage = errorResponse.globalErrors[0].message;
            toast.error(errorMessage);
          }
        });
    } else {
      toast.error('OTP length must be equal to 4 digit');
    }
  };

  // Resend OTP
  const handleResendBtn = () => {
    toast.dismiss();
    // let userData = JSON.parse(sessionStorage.getItem('temp_userData'));

    let data = {
      phone: userData.phone,
      type: 5,
      region: 'AU',
    };
    axios
      .post('/users/tokens', data)
      .then(response => {
        if (response.status === 200) {
          setcheckResendBtnIsClicked(true);

          setTimeout(() => {
            setcheckResendBtnIsClicked(false);
          }, 5000);
        }
      })
      .catch(error => {
        let errorResponse = error.response['data'];
        let errorMessage = errorResponse.globalErrors[0].message;
        toast.error(errorMessage);
      });
  };

  const validateNumber = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      formik.setFieldValue('phone', e.target.value);
    }
  };

  const toggleAuthType = () => {
    const { authMethod } = authType;
    setAuthType({
      authMethod: authMethod === 'byphone' ? 'byemail' : 'byphone',
    });
  };

  const toggleSigninType = () => {
    setAuthType({
      ...authType,
    });
  };

  const { authMethod } = authType;

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
    },
    onSubmit,
    validate,
  });

  // For showing timer

  let timerOn = true;

  const timer = remaining => {
    var m = Math.floor(remaining / 60);
    var s = remaining % 60;

    m = m < 10 ? '0' + m : m;
    s = s < 10 ? '0' + s : s;
    setMinutesValue(m);
    setSecondsValue(s);
    remaining -= 1;

    if (remaining >= 0 && timerOn) {
      setTimeout(function () {
        timer(remaining);
      }, 1000);
      return;
    }

    if (!timerOn) {
      // Do validate stuff here
      return;
    }

    // Do timeout stuff here
    setShowTimer(false);
    setEnableResendLink(true);
  };

  // Resend link
  const resendLink = () => {
    if (forgotPasswordData !== '' || forgotPasswordData !== null) {
      axios
        .post('/users/tokens', forgotPasswordData)
        .then(response => {
          toast.success('Link has been resent to your mailbox');
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (errorResponseFieldErrors) {
            if (errorResponseFieldErrors.length > 0) {
              errorResponseFieldErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                console.log('error global response message ', msg);
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  // For url encode
  const urlencode = str => {
    var symbols = {
      '@': '%40',
      '%26amp%3B': '%26',
      '*': '%2A',
      '+': '%2B',
      '/': '%2F',
      '%26lt%3B': '%3C',
      '%26gt%3B': '%3E',
    };
    return escape(str).replace(/([@*+/]|%26(amp|lt|gt)%3B)/g, function (m) {
      return symbols[m];
    });
  };

  return (
    <div className="p-0 m-0 row h-100vh">
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-7 d-flex justify-content-center align-items-center forgot-password-left__half bg-pale-mavue">
        <ForgotPasswordLeftPanelImage />
      </div>
      {checkOTPSent === false ? (
        <div className="p-0 m-0 p-3 col-sm-12 col-md-12 col-lg-5 d-flex flex-column align-items-center justify-content-center forgot-password-right__half">
          <div className="d-flex justify-content-between w-100 px-lg-5 px-md-3">
            <Logo />
            <Link to={'/login'}>
              <button className="btn color-primary border-radius-5 font-sofia-pro-regular">Sign in</button>
            </Link>
          </div>
          <div className="justify-content-start w-100 px-lg-5 px-md-3 mt-5">
            <span className="color-black-pearl font-40 font-sofia-pro-bold">
              {showEmailSentMessage ? (authMethod === 'byemail' ? 'Email sent' : 'Message sent') : 'Forgot password'}
            </span>{' '}
            <br />
            <p className="p-0 m-0 font-sofia-pro-light font-16 color-black-pearl line-hight-24">
              {showEmailSentMessage
                ? authMethod === 'byemail'
                  ? 'Please check your inbox and click on the link received, don’t forget to check your spam folder'
                  : 'Please check your message inbox and click on the link received'
                : 'Enter your registered email address / phone number below to receive password reset instructions'}
            </p>
          </div>
          <div className="w-100 px-lg-5 px-md-3 mt-4">
            <form onSubmit={formik.handleSubmit}>
              {!showEmailSentMessage ? (
                <div className="form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">
                    {authMethod === 'byemail' ? 'Email address' : 'Phone number'}
                  </label>
                  <span
                    onClick={toggleAuthType}
                    className="float-right font-sofia-pro-regular font-14 color-primary toggle-span"
                  >
                    {authMethod === 'byemail' ? 'User phone number instead' : 'User email instead'}
                  </span>

                  {authMethod === 'byemail' ? (
                    <>
                      <input
                        type="email"
                        className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle shadow-none"
                        name="email"
                        placeholder="Enter your email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <input
                        type="text"
                        className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle"
                        name="phone"
                        placeholder="Enter phone number"
                        onChange={validateNumber}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              ) : null}
              {showEmailSentMessage ? (
                <div className="w-100 mt-4">
                  <button
                    type="submit"
                    className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                    onClick={resendLink}
                    disabled={enableResendLink === false ? true : false}
                  >
                    {' '}
                    Resend link
                  </button>{' '}
                </div>
              ) : (
                <div className="w-100 mt-4">
                  <button
                    type="submit"
                    className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                  >
                    Send
                  </button>
                </div>
              )}
              {showEmailSentMessage && showTimer ? (
                <span className="color-primary font-sofia-pro-regular font-14">
                  Time left - {minutesValue}:{secondsValue}
                </span>
              ) : null}
            </form>
          </div>
        </div>
      ) : (
        <div className="p-0 m-0 p-3 col-sm-12 col-md-12 col-lg-5 d-flex flex-column align-items-center justify-content-center forgot-password-right__half">
          <div className="d-flex justify-content-between w-100 px-lg-5 px-md-3">
            <Logo />
            <Link to={'/login'}>
              <button className="btn color-primary border-radius-5 font-sofia-pro-regular">Sign in</button>
            </Link>
          </div>
          <div className="d-flex justify-content-center align-items-center bg-alice-blue">
            <div className="p-0 m-0">
              <div className="card border-0 p-4">
                <span className="px-2 color-charcoal-grey font-32 font-sofia-pro-light text-center">
                  Please enter OTP
                </span>
                <p className="mt-2 oslo-grey font-sofia-pro-light font-16 text-center">
                  We have sent an OTP to the registered phone number
                </p>
                <div className="mt-2 my-2 d-flex justify-content-between align-items-baseline">
                  <OTPInput
                    value={OTP}
                    onChange={setOTP}
                    OTPLength={4}
                    otpType="number"
                    disabled={false}
                    inputClassName="otp-input__box font-sofia-pro-regular otp__placeholder border-light-periwinkle font-45 border-radius-5"
                  />
                </div>
                <div className="mt-2">
                  <button
                    className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                    onClick={handleNextBtn}
                  >
                    Verify
                  </button>
                </div>
                {checkResendBtnIsClicked === true ? (
                  <div className="mt-2">
                    <img src={UsernameSuccessImage} /> &nbsp;
                    <span className="font-14 font-sofia-pro-regular color-gunmetal">
                      We’ve resent the code to your phone number
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className="p-2 text-center">
                <span className="font-14 font-sofia-pro-regular color-charcoal-grey">
                  Didn’t receive code?{' '}
                  <span
                    className="font-14 font-sofia-pro-regular color-primary cursor-pointer"
                    onClick={handleResendBtn}
                  >
                    Resend
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPasswordPage;
