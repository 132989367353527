import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import Moment from 'moment';
import Toast from '../../../services/ToasterService';
import TooltipYesNo from '../../Notification/TooltipYesNo';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/dashboard/icons/closed-icon.svg';
import Coffee from '../../../assets/images/dashboard/image/invalid-name.png';
import Skip from '../../../assets/images/send_thanks/Skip.png';
import CopyIcon from '../../../assets/images/common/copy-icon.svg';
import BadIcon from '../../../assets/images/redeem_thanks/image/bad.png';
import GoodIcon from '../../../assets/images/redeem_thanks/image/good.png';
import GreatIcon from '../../../assets/images/redeem_thanks/image/great.png';
import './modal.scss';
import useWindowDimensions from '../../Responsive/WindowDimensions';
import AddToAppleButton from "../../../assets/images/common/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"

const SkipBody = ({ getCode }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (getCode?.redeemCode) {
      setLoading(false);
    }
  }, [getCode]);

  const SkipCode = () => {
    navigator.clipboard.writeText(getCode?.redeemCode);
    setTimeout(() => {
      window.location.assign(getCode?.redeemUrl)
    }, 1000);
    Toast('Skip code copied', 'success');
  };

// const handleAddToAppleWallet = ()=>{

// }

  return loading ? (
    <div className="dashboard-text_September-346 font-sofia-pro-regular">
      <p>Please wait while your skip code is being generated.</p>
      <Spinner animation="border" variant="light" />
    </div>
  ) : (
    <div className="dashboard-text_September-346 font-sofia-pro-regular">
      <p>Click the code below and we'll take you to skip to place an order</p>
      <span className="font-20 color-black-pearl font-sofia-pro-bold">Skip Promo Code</span>
      <div className="copy-redeem-skip">
        <Button variant="none" className="copy-redeem-skip_btn color-primary" onClick={SkipCode}>
          <span className="copy-redeem-skip_text font-sofia-pro-bold">{getCode?.redeemCode}</span>
          <img src={CopyIcon} className="copy-redeem-skip_icon" />
        </Button>
      </div>
      {/* <div>
        <a
          href="https://firebasestorage.googleapis.com/v0/b/app-thnx.appspot.com/o/Lollipop%20(1).pkpass?alt=media&token=8f3d7516-f560-4c62-aeb1-08d9f75385ab"
          target="_blank"
        >
          <img src={AddToAppleButton} alt="Apple Wallet" onClick={handleAddToAppleWallet} />
        </a>
      </div> */}
    </div>
  );
};

const CoffeeShopBody = ({ UserExperience }) => {

  return (
    <div className='d-flex justify-content-center'>
      <div className=' dashboard-card__clickhere my-4 '>
        <span className='font-sofia-pro-regular redeem-text__Hows-your-experienc d-flex justify-content-center my-2'>
          How’s your experience?
        </span>
        <div className='d-flex flex-row align-items-center justify-content-around my-3'>
          <button className='border-0 bg-transparent' onClick={() => UserExperience(-1)}>
            <div className='d-flex flex-column align-items-center'>
              <img src={BadIcon} className='redeem-image__experieanceicon' />
              <span className='redeem-text__userexp font-sofia-pro-light'>Bad</span>
            </div>
          </button>
          <button className='border-0 bg-transparent ' onClick={() => UserExperience(0)}>
            <div className='d-flex flex-column'>
              <img src={GoodIcon} className='redeem-image__experieanceicon' />
              <span className='redeem-text__userexp font-sofia-pro-light'>Good</span>
            </div>
          </button>
          <button className='border-0 bg-transparent ' onClick={() => UserExperience(1)}>
            <div className='d-flex flex-column'>
              <img src={GreatIcon} className='redeem-image__experieanceicon' />
              <span className='redeem-text__userexp font-sofia-pro-light'>Great</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

const RedeemedModal = (
  {
    getCode = {},
    show,
    onHide,
    quantityValue,
    closeYes,
    productType = '',
    userType = '',
    UserExperience = null,
    CoffeShopName = ''
  }
) => {
  const { width } = useWindowDimensions();

  const ExitProcess = () => {
    return (
      <div>
        <p>Your skip code will also be available in your email inbox.</p>
        <p>Do you want to exit the redemption process now?</p>
      </div>
    );
  };

  return (
    <div>
      <Modal
        onHide={onHide}
        show={show}
        centered
        className='redeem-coffee__modal'
        animation={false}
        backdrop='static'
        style={{ overflow: 'hidden' }}
      >
        <Modal.Body className='dashboard-model_cofee '>

          {productType === 'skip' && userType === 'anonymous' ?
            <TooltipYesNo
              close={false}
              body={<ExitProcess />}
              id='AddMyStoreInfo'
              placement='bottom'
              handleYes={closeYes}
            >
              <span className='border-0'>
              <CloseIcon width="20px" height="20px"/>
                
              </span>
            </TooltipYesNo>
            :
            <button className={`border-0 bg-transparent  modal-close-btn`} onClick={() => closeYes()}>
              <Close />
            </button>

          }
          <div className='d-flex flex-column '>
            <span className='dashboard-text_manlyguildcofee font-sofia-pro-medium '>{CoffeShopName}</span>
            <div className={`d-flex flex-column ${productType === 'skip' && 'container-modal-coffee'}`}>
              <div className='text-align-center dashboard-card__enjoyYourFixed h-60'>
                <div className='dashboard-transformLeftTop' />
                <div className=' font-sofia-pro-medium dashboard-text__enjoyYour'>ENJOY YOUR</div>
                <div className='dashboard-transformLeftBottom my-2' />
                <div className='dashboard-transformFixedRightTop' />
                <div className='dashboard-transformFixedRightBottom my-2' />
              </div>
              {productType !== 'skip' &&
                <span className='dashboar-text_cofee font-sofia-pro-bold'>
                  {quantityValue} Coffee
                </span>
              }
              <div className='d-flex justify-content-center'>
                {productType === 'skip' ?
                  <img src={Skip} alt='Cofee' className='dashboard-image_coffee ' />
                  :
                  <img src={Coffee} alt='Cofee' className='dashboard-image_coffee ' />
                }
              </div>
              <span className='dashboard-text_September-346 font-sofia-pro-regular'>
                {Moment(new Date()).format('DD MMMM  @ hh:mm a')}
              </span>

              {productType === 'skip' ?
                <SkipBody getCode={getCode} />
                :
                <CoffeeShopBody UserExperience={UserExperience} />
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RedeemedModal;