import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';

const CheckoutForm = props => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const history = useHistory();

  console.log('Client Secret===>', props.clientSecrets);
  const handlePaymentMethodReceived = async event => {
    // Send the payment details to our function.
    // const paymentDetails = {
    //   payment_method: event.paymentMethod.id,
    //   shipping: {
    //     name: event.shippingAddress.recipient,
    //     phone: event.shippingAddress.phone,
    //     address: {
    //       line1: event.shippingAddress.addressLine[0],
    //       city: event.shippingAddress.city,
    //       postal_code: event.shippingAddress.postalCode,
    //       state: event.shippingAddress.region,
    //       country: event.shippingAddress.country,
    //     },
    //   },
    // };
    // const response = await fetch('/.netlify/functions/create-payment-intent', {
    //   method: 'post',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ paymentDetails }),
    // }).then((res) => {
    //   return res.json();
    // });
    // if (response.error) {
    //   // Report to the browser that the payment failed.
    //   console.log(response.error);
    //   event.complete('fail');
    // } else {
    // Report to the browser that the confirmation was successful, prompting
    // it to close the browser payment method collection interface.
    if (props.clientSecrets != undefined) {
      event.complete('success');
      // Let Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        // response.paymentIntent.client_secret
        props.clientSecrets
      );

      if (error) {
        console.log(error);
        return;
      }
      if (paymentIntent.status === 'succeeded') {
        console.log('Payment is Successful');
        history.push('/thanksSuccess');
      } else {
        console.warn(`Unexpected status: ${paymentIntent.status} for ${paymentIntent}`);
      }
    } else {
      console.log('Payment is not Successfull');
      event.complete('fail');
    }
  };

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Demo total',
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      console.log('Available Payment details===> outside');
      pr.canMakePayment().then(result => {
        if (result) {
          console.log('Available Payment details===>', result);
          setPaymentRequest(pr);
          pr.on('paymentMethod', handlePaymentMethodReceived);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  }

  // Use a traditional checkout form.
  return 'Insert your form or button component here.';
};
export default CheckoutForm;
