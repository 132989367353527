/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import { useEffect } from 'react';
import OrganisationHeader from '../../../components/organisation/organisationheader/organisationHeader';
import OrganisationSidebar from '../../../components/organisation/organisationsidebar/organisationsidebar';

const OrganisationLayout = ({ children, props }) => {
  const currentPath = window.location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);

    return {};
  }, []);

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column ">
        {currentPath === '/organisation/employee/selectItem' ? (
          <OrganisationSidebar menu="Sendthanks" />
        ) : (
          <OrganisationSidebar menu="Organisation" />
        )}
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half hm-100vh">
        <OrganisationHeader role={props?.role} />
        {children}
      </div>

    </div>
  );
};

export default OrganisationLayout