/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as SendThanks } from '../../assets/images/dashboard/icons/icons-main-navigation-sent-inactive.svg';
import { ReactComponent as SendThanksActive } from '../../assets/images/dashboard/icons/icons-main-navigation-sent-active.svg';
import HomeIconInactive from '../../assets/images/dashboard/icons/home-sidebar-icon.svg';
import HomeIcon from '../../assets/images/dashboard/image/icons-main-navigation-home-active.png';
import CloseIcon from '../../assets/images/common/close.png';
import useWindowDimensions from '../../common/Responsive/WindowDimensions';
import '../../components/individual/dashboard/DashBoard.scss';
import './AnonUserSidebar.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TooltipProfile from '../Notification/TooltipProfile';
import PopoverProfile from '../component/Sidebar/PopoverProfile';
import BurgerLogo from '../component/Sidebar/BurgerLogo';
import ReactGA from 'react-ga';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});
const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

const SideBar = props => {
  const { width } = useWindowDimensions();
  const [isOpen, setIsopen] = useState(false);
  const [isOpenMobile, setIsopenMobile] = useState(false);
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  const ToggleSidebarMobile = () => {
    isOpenMobile === true ? setIsopenMobile(false) : setIsopenMobile(true);
  };
  let userData1 = JSON.parse(localStorage.getItem('userData'));
  let data = { fullname: userData1?.fullName, type: 'FullName' };
  let currentPath = usePathname();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [currentPath]);

  function PopupButton() {
    const [clicked, setClicked] = useState(false);

    return (
      <div>
        <TooltipProfile
          clicked={clicked}
          setClicked={setClicked}
          id="AddMyStoreInfoCorporate"
          placement="top"
          body={<PopoverProfile toAccount={'/profile'} toHistory={'/transactionhistory'} />}
        >
          <Button />
        </TooltipProfile>
      </div>
    );
  }

  const handleLandingPage = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = 'https://appreci.io';
  };

  function SideBarMobile() {
    if (props.menu === 'Merchant') {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebarMobile} />
          <div className={`sidebar ${isOpenMobile == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo
                className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'}
                height="49px"
                width="115px"
              />
              <div className="mx-3" onClick={ToggleSidebarMobile}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">{/* <MerchantMenu /> */}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebarMobile} />
          <div className={`sidebar ${isOpenMobile === true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo
                className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'}
                height="49px"
                width="115px"
              />
              <div className="mx-3" onClick={ToggleSidebarMobile}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">
                <div className="d-flex dashboard-sidebar_hieght  flex-column justify-content-between">
                  <div className="mb-5">
                    <Home />
                    <div className="my-4">
                      <SendThanksMenu />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  function Home() {
    if (props.menu === 'Home') {
      return (
        <div onClick={handleLandingPage}>
          <Link to="#" style={{ textDecoration: 'none' }}>
            <div className={width > 700 ? 'w-100 d-flex justify-content-between' : 'd-flex justify-content-between'}>
              <div className="d-flex justify-content-center align-content-start">
                <img src={HomeIcon} alt="Home" className="dashboard-icons dashboard-navigation__home__active" />
                <span className="pl-3 dashboard-home font-sofia-pro-semi-bold">Home</span>
              </div>
              <div className="dashboard-menu_selected" />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="w-100" onClick={handleLandingPage}>
          <Link to="#" style={{ textDecoration: 'none' }} className="align-items-center d-flex">
            <img src={HomeIconInactive} alt="Home" />
            <span className="pl-3 dashboard-text__inActive  font-sofia-pro-regular">Home</span>
          </Link>
        </div>
      );
    }
  }

  function SendThanksMenu() {
    if ('Sendthanks' === 'Sendthanks') {
      return (
        <div>
          <Link to="/anonymousselectitem" style={{ textDecoration: 'none' }}>
            <div
              className={
                width > 700
                  ? 'w-100 d-flex justify-content-between align-items-center'
                  : 'd-flex justify-content-between'
              }
            >
              <div className="d-flex justify-content-center align-content-start">
                <SendThanksActive style={{ width: '32px', height: '32px' }} />
                <span className="pl-3 dashboard-home  font-sofia-pro-semi-bold">Send</span>
              </div>
              <div className="dashboard-menu_selected  " />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className=" w-100">
          <Link to="/recipientInformation" style={{ textDecoration: 'none' }}>
            <SendThanks className="dashboard-icons dashboard-menu__sendthanksinactive" />
            <span className="pl-3 dashboard-text__inActive  font-sofia-pro-regular">Send</span>
          </Link>
        </div>
      );
    }
  }

  function LoadMenu() {
    if (props.menu === 'Merchant') {
      return (
        <div className="pl-3 ">
          <div className="m-0 p-0 dashboard-menu my-4">{/* <MerchantMenu /> */}</div>
        </div>
      );
    } else {
      return (
        <div className="topnav navbar-collapse dashboard-menubar_mobile">
          <div className="m-0 p-0 dashboard-menu my-4">
            <div className="dashboard-sidebar_hieght d-flex flex-column justify-content-between">
              <div>
                <Home />
                <div className="my-4">
                  <SendThanksMenu />
                </div>
                {/* <RedeemThanksMenu /> */}
              </div>
              {/* <Accounts /> */}
            </div>
          </div>
        </div>
      );
    }
  }

  function SidebarForTablet() {
    // useEffect(() => {
    //   if (sessionStorage.getItem('greeting') === 'true') {
    //     setIsopen(false);
    //   }
    // });

    if (props.menu === 'Merchant') {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebar} />
          <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo
                className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'}
                height="49px"
                width="115px"
              />
              <div className="mx-3" onClick={ToggleSidebar}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">{/* <MerchantMenu /> */}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebar} />
          <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo
                className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'}
                height="49px"
                width="115px"
              />
              <div className="mx-3" onClick={ToggleSidebar}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="topnav navbar-collapse dashboard-menubar_mobile pl-3 ">
              <div className="m-0 p-0 dashboard-menu my-4">
                <div className="dashboard-sidebar_hieght d-flex flex-column justify-content-between">
                  <div>
                    <Home />
                    <div className="my-4">
                      <SendThanksMenu />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return width >= 700 && width <= 991 ? (
    <div>
      <SidebarForTablet />
    </div>
  ) : width < 700 ? (
    <div>
      <SideBarMobile />
    </div>
  ) : (
    <div className="px-2 d-flex justify-content-center align-items-center dashboar-sidebar_fixed dashboard-sidebar">
      <Logo
        className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'}
        height="49px"
        width="115px"
      />
      <LoadMenu />
    </div>
  );
};
export default SideBar;
