/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useLocation, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import CommonService from '../../../../services/CommonService';
import ReactTooltip from 'react-tooltip';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
// import Dropzone from '../../../../common/Upload/Dropzone';
import DocDropzone from '../../../../common/Upload/DocDropzone';
import LogoDropZone from '../../../../common/Upload/LogoDropezone';
import SearchLocationInput from '../../../../common/GooglePlaces/PlacesSearch';
import * as RegexPattern from '../../../../utils/RegexPatterns';
import merchantService from '../../../../services/MerchantService';
import MerchantService from '../../../../services/MerchantService';
import LocationService from '../../../../services/LocationService';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import Stepper from '../../../../common/Stepper/Stepper';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import IconsCheckFilledImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import BackIcon from '../../../../assets/images/common/icons-back.png';
import './BusinessDetails.scss';

const initialValues = {
  legalName: '',
  tradingName: '',
  webSite: '',
  taxId: '',
  taxIdProvided: true,
  phone: '',
  classification: '',
  email: '',
  isVeteranOwned: false,
  facebookURL: '',
  instagramURL: '',
  twitterURL: '',
};

const validate = values => {
  let errors = {};

  let re = RegexPattern.MobileRegexValue;

  let emailRegex = RegexPattern.EmailRegexValue;

  if (!values.legalName) {
    errors.legalName = 'Required';
  } else if (values.legalName.trim() === '') {
    errors.legalName = 'Required';
  }

  if (!values.tradingName) {
    errors.tradingName = 'Required';
  } else if (values.tradingName.trim() === '') {
    errors.tradingName = 'Required';
  }

  if (!values.taxId) {
    errors.taxId = 'Required';
  } else if (!RegexPattern.ABNRegex.test(values.taxId)) {
    errors.taxId = 'Invalid ABN number';
  } else if (values.taxId.trim() === '') {
    errors.taxId = 'Invalid ABN number';
  }

  if (!values.webSite) {
    errors.webSite = 'Required';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!re.test(values.phone)) {
    errors.phone = 'Invalid phone format';
  } else if (values.phone.trim() === '') {
    errors.phone = 'Invalid phone format';
  }

  return errors;
};

let companyData = {
  company: {
    legalName: '',
    webSite: '',
    taxId: '',
    taxIdProvided: true,
    phone: '',
    classification: '',
    region: 'AU',
    address: {
      latitude: 0,
      longitude: 0,
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
    verificationFileBase64: '',
    verificationStatus: 'None',
    logoFileBase64: '',
  },
};

function MerchantBusinessDetailsPage(props) {
  const [typeOfBusiness, setTypeOfBusiness] = useState('5812');

  const [value, setValue] = useState(null);

  const history = useHistory();

  const [proofFileData, setProofFileData] = useState();
  const [logoFileData, setLogoFileData] = useState();
  const [logoFileImage, setLogoFileImage] = useState();
  const [ProofFileImage, setProofFileImage] = useState();
  const [logoFileName, setLogoFileName] = useState();

  const [requirementStatus, setRequirementStatus] = useState();

  const [checkUserHasCompanyAdminRole, setCheckUserHasCompanyAdminRole] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const [showEmailInputBox, setShowEmailInputBox] = useState(false);

  const [existingAddress, setExistingAddress] = useState();

  const [isVeteranOwnedValue, setIsVeteranOwnedValue] = useState(false);

  const [showBackBtnModal, setShowShowBackBtnModal] = useState(false);

  const [merchantTermsAgreementValue, setMerchantTermsAgreementValue] = useState(true);

  const showBackBtnModalHandler = () => setShowShowBackBtnModal(true);
  const hideBackBtnModalHandler = () => setShowShowBackBtnModal(false);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleCloseSuccessPopup = () => setShowSuccessPopup(false);
  const handleShowSuccessPopup = () => setShowSuccessPopup(true);

  const errors = props?.location?.state?.errors;

  const handleChangeBusiness = e => {
    setTypeOfBusiness(e.target.value);
  };

  const [steps, setSteps] = useState([
    {
      title: 'Business details',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Personal details',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Payment',
      icon: IconsCheckFilledImage,
    },
  ]);

  const [addressData, setAddressData] = useState({
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  });

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    let data = JSON.parse(localStorage.getItem('userData'));
    if (data?.roles.length > 0) {
      data?.roles.forEach((role, index) => {
        if (role === 'CompanyAdmin') {
          // To verify user have admin access
          getMerchantDetails();
          getCompanyDetails();
          setCheckUserHasCompanyAdminRole(true);
        } else {
          getCurrentUser();
        }
      });
    }
    return {};
  }, []);

  const getCompanyDetails = () => {
    merchantService
      .getCompanyDetails()
      .then(response => {
        let data = response['data'];
        formik.setFieldValue('tradingName', data.name);
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Handling proof file
  const proofChangeHandler = files => {
    let fileName = files[0].name;
    let checkValidFile = CommonService.validateFile(fileName);
    let verificationBase64 = '';

    if (checkValidFile) {
      let proofImageUrl = URL.createObjectURL(files[0]);
      setProofFileImage(files[0].name);

      getBase64(files[0], result => {
        verificationBase64 = result.split(',')[1];
        verificationBase64 = /,(.+)/.exec(result)[1];
        setProofFileData(verificationBase64);
      });
    } else {
      Toast('Only pdf, docx, jpg and png files are allowed!', 'warning');
    }
  };

  const rejectedProofFilesHandler = rejectedFiles => {
    if (rejectedFiles?.length > 0) {
      rejectedFiles.forEach((rejectedFile, index) => {
        rejectedFile.errors.forEach(error => {
          Toast(error.code + ' - ' + error.message, 'error');
        });
      });
    }
  };

  // Handling proof file
  const logoChangeHandler = files => {
    let logoBase64 = '';
    let fileName = files[0].name;
    let checkValidImage = CommonService.validateImage(fileName);

    setLogoFileName(fileName);

    if (checkValidImage) {
      let logoImageUrl = URL.createObjectURL(files[0]);
      setLogoFileImage(logoImageUrl);
      getBase64(files[0], result => {
        logoBase64 = result.split(',')[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData(logoBase64);
      });
    } else {
      Toast('Only jpg/jpeg and png files are allowed!', 'warning');
    }
  };

  // Clearing proof file data
  const clearProofImage = () => {
    setProofFileData();
    setProofFileImage('');
  };

  // Clearing logo file data
  const clearLogoImage = () => {
    setLogoFileData();
    setLogoFileImage();
  };

  // Submitting business details data
  const onSubmit = values => {
    toast.dismiss();

    if (logoFileData !== undefined) {
      setShowLoader(true);

      companyData.company.legalName = values.legalName.trim();
      companyData.company.taxId = values.taxId.trim();
      companyData.company.webSite = values.webSite !== '' ? values.webSite : null;
      companyData.company.taxIdProvided = values.taxIdProvided;
      companyData.company.phone = values.phone.trim();
      companyData.company.classification = typeOfBusiness;
      companyData.payout = null;
      companyData.representative = null;
      companyData.bankAccount = null;
      companyData['updateTermsAgreement'] = merchantTermsAgreementValue;
      // companyData.company.address = values.address;

      let createNewCompanyData = {
        name: values.tradingName.trim(),
        logoFileBase64: logoFileData,
        logoFileName: logoFileName,
        isVeteranOwned: isVeteranOwnedValue,
        socialMedias: [],
      };

      if (values.facebookURL !== '') {
        let data = {
          type: 'Facebook',
          url: values.facebookURL !== '' ? values.facebookURL : '',
        };

        createNewCompanyData.socialMedias.push(data);
      }

      if (values.instagramURL !== '') {
        let data = {
          type: 'Instagram',
          url: values.instagramURL !== '' ? values.instagramURL : '',
        };
        createNewCompanyData.socialMedias.push(data);
      }
      if (values.twitterURL !== '') {
        let data = {
          type: 'Twitter',
          url: values.twitterURL !== '' ? values.twitterURL : '',
        };
        createNewCompanyData.socialMedias.push(data);
      }

      companyData.company.verificationFileBase64 = proofFileData;
      companyData.logoFileBase64 = logoFileData;

      if (checkUserHasCompanyAdminRole !== true) {
        let data = {
          emailspecified: 'true',
          email: values.email.trim(),
        };

        if (showEmailInputBox === true) {
          merchantService
            .updateEmailDetails(data)
            .then(response => {
              if (response) {
                createCompany(createNewCompanyData);
              }
            })
            .catch(error => {
              console.log('error ', error);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }

              loopFieldErrors(errorResponseFieldErrors);

              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    Toast(msg.message, 'error');
                  });
                }
              }
            });
        } else {
          createCompany(createNewCompanyData);
        }
      } else {
        updateCompany(createNewCompanyData);
      }
    } else {
      if (logoFileData === undefined) {
        Toast('Please upload logo file', 'warning');
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  // Creating company
  const createCompany = data => {
    toast.dismiss();
    merchantService
      .createNewCompany(data)
      .then(response => {
        if (response.status === 200) {
          getCurrentUser();
          updateMerchant(companyData);
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Update company
  const updateCompany = data => {
    toast.dismiss();
    merchantService
      .updateCompanyDetails(data)
      .then(response => {
        if (response.status === 200) {
          getCurrentUser();
          updateMerchant(companyData);
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Getting current user details
  const getCurrentUser = () => {
    merchantService
      .getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'];
          if (data?.email === null || data?.email === undefined) {
            setShowEmailInputBox(true);
          } else if (data?.email !== null && data?.email !== undefined) {
            setShowEmailInputBox(false);
          }
          if (data?.roles.length > 0) {
            data?.roles.forEach((role, index) => {
              if (role === 'CompanyAdmin') {
                setCheckUserHasCompanyAdminRole(true);
              }
            });
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Getting merchant details
  const getMerchantDetails = () => {
    merchantService
      .getMerchantDetails()
      .then(response => {
        if (response) {
          let merchantData = response['data'];
          setRequirementStatus(merchantData.requirementStatus);
          formik.setFieldValue(
            'legalName',
            merchantData?.company?.legalName !== null ? merchantData?.company?.legalName : ''
          );
          formik.setFieldValue('taxId', merchantData?.company?.taxId !== null ? merchantData?.company?.taxId : '');
          formik.setFieldValue('phone', merchantData?.company?.phone !== null ? merchantData?.company?.phone : '');
          setTypeOfBusiness(
            merchantData?.company?.classification !== null ? merchantData?.company?.classification : '5812'
          );
          formik.setFieldValue(
            'webSite',
            merchantData?.company?.webSite !== null ? merchantData?.company?.webSite : ''
          );

          let address =
            merchantData?.company?.address?.street +
            ' ' +
            merchantData?.company?.address?.suburb +
            ' ' +
            merchantData?.company?.address?.state +
            ' ' +
            merchantData?.company?.address?.country +
            ' ' +
            merchantData?.company?.address?.postcode;

          if (
            merchantData?.company?.address !== null &&
            merchantData?.company?.address?.street !== null &&
            merchantData?.company?.address?.suburb !== null &&
            merchantData?.company?.address?.state !== null &&
            merchantData?.company?.address?.country !== null &&
            merchantData?.company?.address?.postcode !== null
          ) {
            let address =
              merchantData?.company?.address?.street +
              ' ' +
              merchantData?.company?.address?.suburb +
              ' ' +
              merchantData?.company?.address?.state +
              ' ' +
              merchantData?.company?.address?.country +
              ' ' +
              merchantData?.company?.address?.postcode;
            setExistingAddress(address);
            loadExistingAddress(address);
          } else {
            setExistingAddress();
          }

          setAddressData({
            street: merchantData?.company?.address?.street,
            suburb: merchantData?.company?.address?.city,
            state: merchantData?.company?.address?.state,
            country: merchantData?.company?.address?.country,
            postcode: merchantData?.company?.address?.postCode,
          });

          companyData.company.address.street = merchantData?.company?.address?.street;
          companyData.company.address.suburb = merchantData?.company?.address?.suburb;
          companyData.company.address.state = merchantData?.company?.address?.state;
          companyData.company.address.country = merchantData?.company?.address?.country;
          companyData.company.address.postcode = merchantData?.company?.address?.postcode;
          companyData.company.address.country = merchantData?.company?.address?.country;
          companyData.company.address.latitude = merchantData?.company?.address?.latitude;
          companyData.company.address.longitude = merchantData?.company?.address?.longitude;
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Loading existing address
  const loadExistingAddress = data => {
    geocodeByAddress(data)
      .then(results => {
        if (results) {
          getLatitudeLongitude(results[0]);

          let addressData = LocationService.getLocationAddress(results);

          if (addressData.street === undefined) {
            Toast('Please add street address', 'error');
            return;
          }

          if (addressData.city === undefined) {
            Toast('Please add city address', 'error');
            return;
          }

          if (addressData.state === undefined) {
            Toast('Please add state address', 'error');
            return;
          }

          if (addressData.country === undefined) {
            Toast('Please add country address', 'error');
            return;
          }

          if (addressData.postCode === undefined) {
            Toast('Please add post code address', 'error');
            return;
          }
          setAddressData({
            street: addressData.street,
            suburb: addressData.city,
            state: addressData.state,
            country: addressData.country,
            postcode: addressData.postCode,
          });
        }
      })
      .catch(error => console.error(error));
  };

  // Get address details
  const getAddress = data => {
    setValue(data);

    getLatitudeLongitude(data);

    let addressData = LocationService.getLocationAddress(data);

    if (addressData.street === undefined) {
      Toast('Please add street address', 'error');
      return;
    }

    if (addressData.city === undefined) {
      Toast('Please add city address', 'error');
      return;
    }

    if (addressData.state === undefined) {
      Toast('Please add state address', 'error');
      return;
    }

    if (addressData.country === undefined) {
      Toast('Please add country address', 'error');
      return;
    }

    if (addressData.postCode === undefined) {
      Toast('Please add post code address', 'error');
      return;
    }

    companyData.company.address.street = addressData.street;
    companyData.company.address.suburb = addressData.city;
    companyData.company.address.state = addressData.state;
    companyData.company.address.country = addressData.country;
    companyData.company.address.postcode = addressData.postCode;
    companyData.company.address.country = addressData.country;
  };

  // Get lattitide and longitude data
  const getLatitudeLongitude = data => {
    getLatLng(data).then(({ lat, lng }) => {
      companyData.company.address.latitude = lat;
      companyData.company.address.longitude = lng;
    });
  };

  // Updating merchant
  const updateMerchant = data => {
    if (merchantTermsAgreementValue === true) {
      toast.dismiss();

      delete data.company.logoFileBase64;

      delete data.logoFileBase64;

      MerchantService.updateMerchantDetails(data)
        .then(response => {
          if (response) {
            setShowLoader(false);
            let merchantData = response['data'];
            setCheckUserHasCompanyAdminRole(false);

            if (errors?.length > 0) {
              handleShowSuccessPopup();
            } else {
              history.push({
                pathname: '/merchant/onboarding/personal',
                state: {
                  role: 'Merchant',
                },
              });
            }
          }
        })
        .catch(error => {
          setShowLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors?.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                Toast(msg.message, 'error');
              });
            }
          }
        });
    } else {
      Toast('Please read & accept the terms & conditions', 'error');
      setShowLoader(false);
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    setShowLoader(false);
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  // Base64 conversion
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const onValueChange = e => {
    let isVeteranOwned = e.target.checked;
    setIsVeteranOwnedValue(isVeteranOwned);
  };

  const backBtnHandler = () => {
    showBackBtnModalHandler();
  };

  const handleSuccessPopupBtnHandler = () => {
    handleCloseSuccessPopup();
    history.push('/dashboard');
  };

  const onAgreementValueChange = e => {
    let termsAndCondtions = e.target.checked;
    setMerchantTermsAgreementValue(termsAndCondtions);
  };

  return (
    <div className="bg-alice-blue">
      <div className="p-2">
        <Logo />
      </div>
      <div className="mx-lg-5 mx-md-2 my-2 p-lg-4 p-md-2 bg-white">
        <div className="p-0 m-0 row">
          <div className="col-md-2 col-lg-3">&nbsp;</div>
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="mb-3">
              <button
                className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none"
                onClick={backBtnHandler}
              >
                <img src={BackIcon} height="30px" />
                Back
              </button>
            </div>
            <div>
              <Stepper steps={steps} activeStep={currentStep} />
            </div>

            <div>
              {errors?.length > 0 && (
                <div className="p-2 my-1 border-radius-5 border-grey-blue">
                  <span className="font-sofia-pro-regular color-reddish font-16">Note</span>

                  <ul>
                    {errors?.map((msg, index) => {
                      return <li className="font-sofia-pro-regular color-reddish font-14">{msg}</li>;
                    })}
                  </ul>
                </div>
              )}
              <div className="p-0 m-0 p-3 card">
                <span className="font-sofia-pro-semi-bold font-18 color-black-pearl">Business details</span>
                <form className="mt-3" onSubmit={formik.handleSubmit}>
                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Legal Name *</label>
                    <input
                      type="text"
                      className="form-control shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle"
                      name="legalName"
                      placeholder="Eg: Restaurant"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.legalName}
                    />

                    {formik.touched.legalName && formik.errors.legalName ? (
                      <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.legalName}
                      </div>
                    ) : null}
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Trading Name *</label>
                    <input
                      type="text"
                      className="form-control shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle"
                      name="tradingName"
                      placeholder="Eg: Coffee Store"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tradingName}
                    />

                    {formik.touched.tradingName && formik.errors.tradingName ? (
                      <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.tradingName}
                      </div>
                    ) : null}
                  </div>
                  {showEmailInputBox === true ? (
                    <div className="p-0 b-0 pb-1 form-group">
                      <label className="font-sofia-pro-regular color-charcoal font-16">Email *</label>
                      <input
                        type="text"
                        className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                        name="email"
                        placeholder="Eg: example@domain.com"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>
                    {existingAddress === undefined && (
                      <SearchLocationInput loadAddress={getAddress} data={existingAddress} />
                    )}
                    {existingAddress !== undefined && (
                      <SearchLocationInput loadAddress={getAddress} data={existingAddress} />
                    )}
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Type of business *</label>
                    <select
                      className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl business-details__placeholder border-light-periwinkle"
                      name="typeOfBusiness"
                      placeholder="Eg: Restaurant"
                      value={typeOfBusiness}
                      onChange={handleChangeBusiness}
                    >
                      <option value="5812">Eating Places, Restaurants</option>
                      <option value="5814">Fast Food Restaurants</option>
                      <option value="5499">
                        Miscellaneous Food Stores - Convenience Stores and Specialty Markets{' '}
                      </option>
                      <option value="5921">Package Stores-Beer, Wine, and Liquor</option>
                      <option value="5999">Others</option>
                    </select>
                  </div>

                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">ABN *</label>

                    <input
                      type="text"
                      className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl business-details__placeholder border-light-periwinkle"
                      name="taxId"
                      placeholder="Eg: 1234567890"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.taxId}
                    />

                    {formik.touched.taxId && formik.errors.taxId ? (
                      <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.taxId}
                      </div>
                    ) : null}
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Phone *</label>
                    <input
                      type="text"
                      className="form-control h-60 font-sofia-pro-regular color-black-pearl business-details__placeholder border-light-periwinkle"
                      name="phone"
                      placeholder="Eg: 123456789"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
                      </div>
                    ) : null}
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Website *</label>
                    <input
                      type="text"
                      className="form-control h-60 font-sofia-pro-regular color-black-pearl business-details__placeholder border-light-periwinkle"
                      name="webSite"
                      placeholder="https://www.appreci.com"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.webSite}
                    />
                    {formik.touched.webSite && formik.errors.webSite ? (
                      <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                        <img className="mr-1" src={ErrorImage} /> {formik.errors.webSite}
                      </div>
                    ) : null}
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <div>
                      <div className="p-0 b-0 pb-1 mt-2 form-group">
                        <label className="font-sofia-pro-regular color-black-pearl font-16">
                          Proof of legal identity (Optional)
                          <span className="d-block font-sofia-pro-light font-14 color-black-pearl">
                            The information in this document (.pdf format) must include business name, business address,
                            and company number as provided. You can upload a bill or invoice as well.
                          </span>
                        </label>

                        <label className="position-relative w-100">
                          <div className="upload-file-style">
                            <DocDropzone
                              acceptedFiles={proofChangeHandler}
                              rejectedFiles={rejectedProofFilesHandler}
                              maxLength={1}
                              supportText={'Supports: PDF, WORD, PNG, JPEG file'}
                              multiple={false}
                            ></DocDropzone>
                          </div>
                        </label>

                        {ProofFileImage && (
                          <div className="mt-2 d-flex justify-content-between">
                            <div className="business-details-image-name__text__ellipsis">
                              <span
                                data-tip
                                data-for="proofFileImageName"
                                className="font-14 font-sofia-pro-medium color-black-pearl "
                              >
                                {ProofFileImage}
                              </span>
                              <ReactTooltip
                                id="proofFileImageName"
                                html={true}
                                className="business-details-image-name__tooltip"
                              >
                                {ProofFileImage}
                              </ReactTooltip>
                            </div>
                            <button
                              type="button"
                              className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                              onClick={clearProofImage}
                            >
                              Clear
                            </button>
                          </div>
                        )}
                      </div>

                      <div className="p-0 b-0 pb-1 mt-2 form-group">
                        <label className="font-sofia-pro-regular color-black-pearl font-16">
                          Logo*
                          <span className="d-block font-sofia-pro-light font-14 color-black-pearl">
                            This will be displayed to customers on the mobile and web application (Public). You can
                            upload the store logo or a photo of the store with logo in view.
                          </span>
                        </label>

                        <label className="position-relative w-100">
                          <div className="upload-file-style">
                            <LogoDropZone
                              acceptedFiles={logoChangeHandler}
                              rejectedFiles={rejectedProofFilesHandler}
                              maxLength={1}
                              supportText={'Supports: PNG, JPEG file'}
                              multiple={false}
                            ></LogoDropZone>
                          </div>
                        </label>

                        {logoFileImage && (
                          <div className="mt-2 d-flex justify-content-between">
                            <img src={logoFileImage} height="35px" />
                            <button
                              type="button"
                              className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                              onClick={clearLogoImage}
                            >
                              Clear
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="p-0 b-0 pb-1 mt-2 form-group">
                        <label className="font-sofia-pro-regular color-black-pearl font-16">Facebook</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="facebookURL"
                          placeholder="Eg: https://www.facebook.com/share"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.facebookURL}
                        />
                      </div>
                      <div className="p-0 b-0 pb-1 mt-2 form-group">
                        <label className="font-sofia-pro-regular color-black-pearl font-16">Instagram</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="instagramURL"
                          placeholder="Eg: https://www.instagram.com/share"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.instagramURL}
                        />
                      </div>
                      <div className="p-0 b-0 pb-1 mt-2 form-group">
                        <label className="font-sofia-pro-regular color-black-pearl font-16">Twitter</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
                          name="twitterURL"
                          placeholder="Eg: https://www.twitter.com/share"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.twitterURL}
                        />
                      </div>
                      <div className="p-0 b-0 pb-1 mt-2 form-group d-flex align-items-center">
                        <label className="merchant-business__container">
                          <input type="checkbox" onChange={onValueChange} checked={isVeteranOwnedValue} />
                          <span className="merchant-business__checkmark"></span>
                        </label>
                        <label className="font-sofia-pro-regular color-black-pearl font-16">Is veteran owned</label>
                      </div>
                      <div>
                        <div className="p-0 b-0 pb-1 mt-2 form-group d-flex align-items-center">
                          <label className="merchant-business__container">
                            <input
                              type="checkbox"
                              name="termsAndConditions"
                              checked={merchantTermsAgreementValue}
                              onChange={onAgreementValueChange}
                            />
                            <span className="merchant-business__checkmark"></span>
                          </label>

                          <span className="font-16 font-sofia-pro-regular color-dark-pearl">
                            I agree to{' '}
                            <a
                              href="https://appreci.io/terms/"
                              target="blank"
                              className="color-primary font-16 font-sofia-pro-medium"
                            >
                              terms and conditions{' '}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                    children="Proceed"
                    showLoader={showLoader}
                    onClick={() => onSubmit}
                  />
                </form>
              </div>
            </div>

            <Modal
              className="pt-4 border-0
         modal-background-shadow switch-to-account-modal"
              backdrop="static"
              show={showBackBtnModal}
              onHide={hideBackBtnModalHandler}
              animation={false}
            >
              <Modal.Header className="p-0 m-0 border-0">
                <button
                  type="button"
                  className="close switch-to-account-modal__close__icon"
                  onClick={hideBackBtnModalHandler}
                >
                  <Close />
                </button>
              </Modal.Header>
              <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                <p className="font-16 font-sofia-pro-semi-bold color-black-pearl">Are you sure you want to exit?</p>
                <div className="w-100 p-0 m-0 d-flex justify-content-start align-items-center">
                  <Link to={'/merchant/plans'}>
                    <button
                      type="submit"
                      className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                    >
                      Yes
                    </button>
                  </Link>
                  <button
                    className="p-0 m-0 ml-2 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                    onClick={hideBackBtnModalHandler}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              className="border-0 merchant-success__modal modal-background-shadow"
              size="md"
              show={showSuccessPopup}
              onHide={handleCloseSuccessPopup}
              backdrop="static"
            >
              <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
                <span className="font-16 color-primary font-sofia-pro-semi-bold">
                  <img src={IconsCheckFilledImage} height="25px" width="25px" />
                  <span className="pl-1">ACCOUNT UPDATED</span>
                </span>
              </Modal.Header>
              <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                <p className="font-16 font-sofia-pro-light color-black-pearl">
                  You have successfully resubmitted the missing documents and our team will verify them.
                </p>
                <p className="font-16 font-sofia-pro-light color-black-pearl">
                  You can continue using your personal account and once your merchant account is verified, we'll send
                  you the next steps.
                </p>
                <span className="p-0 m-0 font-sofia-pro-medium font-14 color-black-pearl">
                  Note: It may take up to 5 days to get the account activated.
                </span>
                <div className=" p-0 m-0 mt-4 mb-2 d-flex justify-content-start align-items-center">
                  <button
                    className="p-0 m-0 py-2 w-120 h-50 border-primary-color-2 border-radius-5 bg-transparent font-16 color-primary font-sofia-pro-semi-bold outline-none  shadow-none"
                    onClick={handleSuccessPopupBtnHandler}
                  >
                    Ok got it
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantBusinessDetailsPage;
