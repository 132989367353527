/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Allocate credits schema
export const AllocateSchema = {
  message: '',
  paymentSource: {
    token: null,
    cardid: null,
    setCardAsDefault: false,
    useCredit: false,
  },
  amount: null,
  employeeId: 0,
  name: '',
};
