/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const onClick = () => {};

const Button = ({ textLoading = true, classNameValue, children, showLoader, onClick, disabled, style }) => {
  return (
    <>
      {showLoader === false && (
        <button type="submit" onClick={onClick} className={classNameValue} disabled={disabled} style={style}>
          {children}
        </button>
      )}
      {showLoader === true && (
        <button className={classNameValue} disabled={disabled} style={style}>
          <div>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            {textLoading && <span className="pl-2">Loading</span>}
          </div>
        </button>
      )}
    </>
  );
};

export default Button;
