/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ path, component: Component, layout: Layout, layoutProps, render, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const location = useLocation();

  useEffect(() => {
    getAuthCard();
  }, []);

  // Checking user is authenticated
  function getAuthCard() {
    let data = JSON.parse(localStorage.getItem('userData'));
    if (data) {
      return setIsAuthenticated(true);
    } else {
      return setIsAuthenticated(false);
    }
  }

  const [currentPath, setCurrentPath] = useState(null);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [currentPath]);

  if (isAuthenticated === null) {
    return <></>;
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuthenticated)
          return (
            <Redirect
              exact
              to={{
                pathname: '/login',
                search: currentPath !== '/login' ? '?redirectUrl=' + currentPath : '',
                state: { from: props.location },
              }}
            />
          );
        return Component ?
                Layout ?
                  <Layout props={layoutProps}>
                    <Component {...props} />
                  </Layout>
                :
                  <Component {...props} />
              : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
