/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
// MOMENT
import Moment from 'react-moment';
// STRIPE
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import { connect } from 'react-redux';
import paymentService from '../../../../services/PaymentService';
import Toast from '../../../../services/ToasterService';
import { sendThanksDetails, resetSendThanksDetails } from '../../../../store/reducers/individual/sendThanks/sendThanks';
import sendThanksSchema, * as SendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import './PaymentOptions.scss';
import IconMiscCard from '../../../../assets/images/common/icons-misc-card.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import GoolePay from '../../../../assets/images/common/google-pay-mark-800.png';
import ButtonBack from '../../../../common/Button/ButtonBack';
import AddCardForms from '../../../../common/component/modal/AddCardForms';
import ReviewPurchase from '../reviewThanks/ReviewPurchase';
import UsernameSuccessImage from '../../../../assets/images/common/icons-check-filled-green.png';
import ApplePay from './ApplePay';
import CheckoutForm from './CheckoutForm';
import SummaryPurchase from '../../../common/Summary';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Payment(props) {
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { productsCard, details } = props.multiProduct;
  const paymentInformation = props.getPaymentInformation;
  const [savedCardsList, setSavedCardsList] = useState([]);
  const [defaultCardList, setDefaultCardList] = useState([]);
  let [choosenCardId, setChoosenCardId] = useState();
  const [cardLastNumber, setCardLastNumber] = useState();
  const [cardExpiryDate, setCardExpiryDate] = useState();
  const [cardBrand, setCardBrand] = useState();
  const [googlepaySelect, setGooglepaySelect] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState();
  const [paymentMethod, setPaymentMethod] = useState(1);
  const [cardDetails, setCardDetails] = useState();
  const [selectedCardDetails, setSelectedCardsDetails] = useState();
  const [paymentType, setPaymentType] = useState();
  const [clientSecrets, setClientSecrets] = useState();
  const [tileWidth, setTileWidth] = useState();
  const { width } = useWindowDimensions();

  let priceId = productsCard?.prices ? productsCard?.prices[0]?.priceId : productsCard.priceId;
  let checkCardRemoved = false;

  useEffect(() => {
    if (width > 500) {
      setTileWidth(250);
    } else {
      setTileWidth(250);
    }
  }, [width]);

  useEffect(() => {
    loadExistingCardsList();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    checkIfUserSelectedPaymentCard();
  }, [savedCardsList]);

  // Checking page reload
  useEffect(() => {
    window.onbeforeunload = function (event) {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  // Check selected a card
  const checkIfUserSelectedPaymentCard = () => {
    if (savedCardsList.length > 0) {
      savedCardsList.map((res, index) => {
        if (sendThanksSchema.sendThanksData?.paymentSource?.cardid === res.id) {
          setChoosenCardId(res.id);
          setCardLastNumber(res.last4);

          selectedCardDetails.cardLastNumber = res.last4;
          setCardExpiryDate(res.expiresOn);
          setCardBrand(res.brand);
        }
      });
    }
  };

  // load existing cards list
  const loadExistingCardsList = () => {
    let googlePayCard = sessionStorage.getItem('PaymentType') ? sessionStorage.getItem('PaymentType') : 'Card';
    if (googlePayCard === 'GooglePay') {
      setGooglepaySelect(true);
    } else if (googlePayCard === 'Card') {
      setGooglepaySelect(false);
    }

    paymentService
      .getListOfCards()
      .then(response => {
        if (response) {
          let data = response['data'];
          sessionStorage.setItem('PaymentType', 'Card');
          setPaymentType('Card');
          setSavedCardsList(data);
          let defaultCardData = data.filter(data => {
            return data.isDefault === true;
          });
          for (let i = 0; i < data.length; i++) {
            if (data[i].isDefault) {
              setSelectedCardsDetails({
                ...selectedCardDetails,
                cardLastNumber: data[i].last4,
                choosedCardId: data[i].id,
                cardExpiryDate: data[i].expiresOn,
                productDetails: SendThanksSchema.sendThanksData,
                productType: details?.type,
                priceId: priceId,
                amount: paymentInformation?.amount,
                brand: data[i].brand,
              });
            }
          }
          if (
            (defaultCardData.length > 0 && sendThanksSchema?.sendThanksData?.paymentSource?.cardid === '') ||
            sendThanksSchema?.sendThanksData?.paymentSource?.cardid === undefined
          ) {
            if (googlePayCard === 'Card') {
              setChoosenCardId(defaultCardData[0].id);
            }
          }
          setDefaultCardList(defaultCardData);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          Toast(errorMessage, 'error');
        }
      });
  };

  const deleteCard = index => {
    setShowDeleteModal(true);
    setSelectedCardIndex(index);
  };

  const reviewBtnHandler = () => {
    if (choosenCardId !== undefined) {
      sessionStorage.setItem('PaymentType', 'Card');
      setPaymentType('Card');
      sendThanksSchema.sendThanksData.paymentSource.cardid = choosenCardId;
      props.paymentInformation(sendThanksSchema);
      // props.history.push({
      //   pathname: '/reviewThanks',
      //   state: {
      //     cardLastNumber: cardLastNumber,
      //     choosedCardId: choosenCardId,
      //     cardExpiryDate: cardExpiryDate,
      //     productDetails: SendThanksSchema.sendThanksData,
      //     productType: details?.type,
      //     priceId: priceId,
      //     amount: paymentInformation?.amount,
      //     brand: cardBrand,
      //   },
      // });
      setSelectedCardsDetails({
        cardLastNumber: cardLastNumber,
        choosedCardId: choosenCardId,
        cardExpiryDate: cardExpiryDate,
        productDetails: SendThanksSchema.sendThanksData,
        productType: details?.type,
        priceId: priceId,
        amount: paymentInformation?.amount,
        brand: cardBrand,
      });
    } else if (googlepaySelect === true) {
      sessionStorage.setItem('PaymentType', 'GooglePay');
      setPaymentType('GooglePay');

      props.history.push({
        pathname: '/reviewThanks',
        state: {
          Type: 'Google Pay',
          productDetails: sendThanksSchema.sendThanksData,
          productType: details?.type,
          priceId: priceId,
          amount: paymentInformation.amount,
        },
      });
    } else {
      Toast('Please add a card for payment', 'error');
    }
  };

  // Updating default card
  const setAsDefaultBtnHandler = index => {
    let data = savedCardsList[index];

    let cardData = {
      id: data.id,
      expiresOn: data.expiresOn,
      setAsDefault: true,
    };

    paymentService
      .updateCard(data.id, cardData)
      .then(response => {
        if (response.status === 200) {
          loadExistingCardsList();
          Toast('Your default card is changed successfully', 'success');
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseFieldErrors) {
          if (errorResponseFieldErrors.length > 0) {
            errorResponseFieldErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Removing card
  const cardDeleteBtnHandler = () => {
    if (checkCardRemoved === false) {
      checkCardRemoved = true;
      let cardId = savedCardsList[selectedCardIndex].id;

      paymentService
        .deleteCard(cardId)
        .then(response => {
          loadExistingCardsList();
          checkIfUserSelectedPaymentCard();
          Toast('Card removed successfully', 'success');

          setShowDeleteModal(false);

          if (cardId === choosenCardId) {
            setChoosenCardId(undefined);
          }

          setTimeout(() => {
            checkCardRemoved = false;
          }, 2000);
        })
        .catch(error => {
          if (error?.response) {
            let errorResponse = error?.response['data'];
            let errorMessage = errorResponse?.globalErrors[0].message;
            Toast(errorMessage, 'error');
          }
        });
    }
  };

  const cancelBtnHandler = () => {
    props.resetData();
    props.history.push({
      pathname: '/dashboard',
    });
  };

  const googlePayBtnHandler = () => {
    setGooglepaySelect(!googlepaySelect);
    setChoosenCardId(undefined);
    sessionStorage.setItem('PaymentType', 'GooglePay');
    setPaymentType('GooglePay');
  };

  return (
    <>
      <ButtonBack from="payment" to="recipientInformation" />

      <SummaryPurchase tileWidth={tileWidth}/>
      {/* <div className="m-0 p-0 row">
        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12 mb-4 d-flex flex-column right_half">
          <div className="p-0 m-0 row my-lg-0">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="border-0  border-radius-5">
                <div className="card-body">
                  <span className="color-black-pearl font-32 font-sofia-pro-light pl-2">Select payment</span>
                  <div className="mt-3 col-lg-12 main-anon-payment col-md-12 col-sm-12 px-0">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="d-flex justify-content-between my-3 pt-4 pb-3">
                        <div className="w-50 text-center cursor-pointer" onClick={() => setPaymentMethod(0)}>
                          <div className="pb-2 monteserrat-normal font-16 light-grey ">Add Debit Card/Credit Card</div>
                          {paymentMethod == 0 ? <div className="border-bottom-grey-blue"></div> : null}
                        </div>

                        <div className="w-50 text-center cursor-pointer" onClick={() => setPaymentMethod(1)}>
                          <div className="pb-2 monteserrat-normal font-16 light-grey ">
                            Saved Cards
                            {savedCardsList.length !== 0 && (
                              <img src={UsernameSuccessImage} className="pl-2" alt="Filled Green Icon" />
                            )}
                          </div>
                          {paymentMethod == 1 ? <div className="border-bottom-grey-blue"></div> : null}
                        </div>
                      </div>
                      {paymentMethod == 0 ? (
                        <>
                          <div class="mb-3">
                            <Elements stripe={stripePromise}>
                              <ElementsConsumer>
                                {({ elements, stripe }) => (
                                  <AddCardForms
                                    setChoosenCardId={setChoosenCardId}
                                    elements={elements}
                                    stripe={stripe}
                                    setCardDetails={setCardDetails}
                                    // senderEmail={senderEmail}
                                    // userName={senderUserName}
                                    //   hideModal={() => setShow(false)}
                                    loadExistingList={loadExistingCardsList}
                                    // setCheckCard={setCheckCard}
                                  />
                                )}
                              </ElementsConsumer>
                            </Elements>
                          </div>
                        </>
                      ) : (
                        <div className="p-0 m-0 row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="border-0  border-radius-5">
                              <div className="payment-main-card">
                                <div>
                                  <div className="my-2 d-flex justify-content-between">
                                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                      Default payment method
                                    </span>
                                  </div>

                                  {defaultCardList.length !== 0 &&
                                    defaultCardList.map((data, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="mt-3 p-2 d-flex justify-content-between align-items-center border-faded-pink bg-lavender-blush border-radius-5"
                                        >
                                          <div className="payment-options-radio__item">
                                            <label className="font-14 font-sofia-pro-light color-black-pearl ">
                                              <span className="pl-2">
                                                {' '}
                                                {data.brand} •••• •••• •••• {data.last4} |
                                                <span>
                                                  <Moment format="MM/YY">{data.expiresOn}</Moment>
                                                </span>
                                              </span>
                                              <img className="pl-1" src={IconMiscCard} />
                                            </label>
                                          </div>
                                          <span className="font-14 font-sofia-pro-light color-gunmetal">
                                            Default card
                                          </span>
                                        </div>
                                      );
                                    })}

                                  <div className="mt-4">
                                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                      Your saved credit and debit cards
                                    </span>
                                    {savedCardsList.length !== 0 &&
                                      savedCardsList.map((data, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="mt-3 p-2 justify-content-between align-items-center card-details-main"
                                          >
                                            <div className="payment-options-radio__item">
                                              <input
                                                type="radio"
                                                id={'savedCard' + index}
                                                name={'savedCard' + index}
                                                value={data.id}
                                                checked={data.id === choosenCardId && googlepaySelect === false}
                                                onChange={e => {
                                                  setChoosenCardId(e.target.value);
                                                  setCardLastNumber(data.last4);
                                                  setCardExpiryDate(data.expiresOn);
                                                  setGooglepaySelect(false);
                                                  sessionStorage.setItem('PaymentType', 'Card');
                                                  setPaymentType('Card');
                                                }}
                                              />
                                              <label className="font-14 font-sofia-pro-light color-black-pearl card-number">
                                                <span className="pl-2">
                                                  {' '}
                                                  {data.brand} •••• •••• •••• {data.last4} |{' '}
                                                  <span>
                                                    <Moment format="MM/YY">{data?.expiresOn}</Moment>
                                                  </span>
                                                </span>
                                                <img className="pl-1 card-icon" src={IconMiscCard} />
                                              </label>
                                            </div>
                                            <div className="card-buttons">
                                              <button
                                                type="button"
                                                className="mr-2 p-2 btn btn-sm border-0  shadow-none"
                                                onClick={setAsDefaultBtnHandler.bind(this, index)}
                                              >
                                                <span className="font-14 font-sofia-pro-regular color-primary">
                                                  Set as default
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="p-2 btn btn-sm border-pale-pink color-primary  shadow-none"
                                                onClick={deleteCard.bind(this, index)}
                                              >
                                                <span className="font-14 font-sofia-pro-regular color-primary">
                                                  Delete
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    {savedCardsList.length === 0 ? (
                                      <p className="my-4 text-center font-18 font-sofia-pro-medium">No data found</p>
                                    ) : null}
                                    <div className="mt-4">
                                      <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                        Add a Payment Method
                                      </span>
                                    </div>
                                    <div className="mt-3 p-2 d-flex justify-content-between align-items-center">
                                      <div
                                        className="p-2 border-primary-color payment-options-radio__item border-radius-5 cursor-pointer"
                                        // onClick={() => setShow(true)}
                                      >
                                        <label
                                          className="font-14 font-sofia-pro-light color-black-pearl cursor-pointer"
                                          onClick={() => {
                                            // setShow(true);
                                            setPaymentMethod(0);
                                          }}
                                        >
                                          <span className="pl-2"> Add a debit or credit card </span>
                                        </label>
                                      </div>
                                    </div>
                                      <Elements stripe={stripePromise}>
                                      <CheckoutForm clientSecrets={props?.clientSecrets} />
                                    </Elements>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">&nbsp;</div>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-12 col-sm-12 mb-5">
                      <ReviewPurchase
                        selectedCardDetails={selectedCardDetails}
                        paymentType={paymentType}
                        setClientSecrets={setClientSecrets}
                        // addedCard={cardDetails} checkCard={checkCard}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className='p-0 m-0 row'>
        <div className='col-lg-2'>&nbsp;</div>
        <div className='col-sm-12 col-md-12 col-lg-8'>
          <div className='card border-0  border-radius-5'>
            <div className='card-body payment-main-card'>
              <span className='font-32 font-sofia-pro-light color-black-pearl'>Payment Options</span>

              <div>
                <div className='my-2 d-flex justify-content-between'>
                  <span className='font-16 font-sofia-pro-semi-bold color-dark-charcoal'>Default payment method</span>
                </div>

                {defaultCardList.length !== 0 &&
                defaultCardList.map((data, index) => {
                  return (
                    <div
                      key={index}
                      className='mt-3 p-2 d-flex justify-content-between align-items-center border-faded-pink bg-lavender-blush border-radius-5'
                    >
                      <div className='payment-options-radio__item'>
                        <label className='font-14 font-sofia-pro-light color-black-pearl '>
                              <span className='pl-2'>
                                {' '}
                                {data.brand} •••• •••• •••• {data.last4} |
                                <span>
                                  <Moment format='MM/YY'>{data.expiresOn}</Moment>
                                </span>
                              </span>
                          <img className='pl-1' src={IconMiscCard} />
                        </label>
                      </div>
                      <span className='font-14 font-sofia-pro-light color-gunmetal'>Default card</span>
                    </div>
                  );
                })}

                <div className='mt-4'>
                    <span className='font-16 font-sofia-pro-semi-bold color-dark-charcoal'>
                      Your saved credit and debit cards
                    </span>
                  {savedCardsList.length !== 0 &&
                  savedCardsList.map((data, index) => {
                    return (
                      <div key={index} className='mt-3 p-2 justify-content-between align-items-center card-details-main'>
                        <div className='payment-options-radio__item'>
                          <input
                            type='radio'
                            id={'savedCard' + index}
                            name={'savedCard' + index}
                            value={data.id}
                            checked={data.id === choosenCardId && googlepaySelect === false}
                            onChange={e => {
                              setChoosenCardId(e.target.value);
                              setCardLastNumber(data.last4);
                              setCardExpiryDate(data.expiresOn);
                              setGooglepaySelect(false);
                              sessionStorage.setItem('PaymentType', 'Card');
                            }}
                          />
                          <label className='font-14 font-sofia-pro-light color-black-pearl card-number'>
                                <span className='pl-2'>
                                  {' '}
                                  {data.brand} •••• •••• •••• {data.last4} |{' '}
                                  <span>
                                    <Moment format='MM/YY'>{data?.expiresOn}</Moment>
                                  </span>
                                </span>
                            <img className='pl-1 card-icon' src={IconMiscCard} />
                          </label>
                        </div>
                        <div className='card-buttons'>
                          <button
                            type='button'
                            className='mr-2 p-2 btn btn-sm border-0  shadow-none'
                            onClick={setAsDefaultBtnHandler.bind(this, index)}
                          >
                            <span className='font-14 font-sofia-pro-regular color-primary'>Set as default</span>
                          </button>
                          <button
                            type='button'
                            className='p-2 btn btn-sm border-pale-pink color-primary  shadow-none'
                            onClick={deleteCard.bind(this, index)}
                          >
                            <span className='font-14 font-sofia-pro-regular color-primary'>Delete</span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                  {savedCardsList.length === 0 ? (
                    <p className='my-4 text-center font-18 font-sofia-pro-medium'>No data found</p>
                  ) : null}
                  <div className='mt-4'>
                    <span className='font-16 font-sofia-pro-semi-bold color-dark-charcoal'>Add a Payment Method</span>
                  </div>
                  <div className='mt-3 p-2 d-flex justify-content-between align-items-center'>
                    <div
                      className='p-2 border-primary-color payment-options-radio__item border-radius-5 cursor-pointer'
                      onClick={() => setShow(true)}
                    >
                      <label
                        className='font-14 font-sofia-pro-light color-black-pearl cursor-pointer'
                        onClick={() => setShow(true)}
                      >
                        <span className='pl-2'> Add a debit or credit card </span>
                      </label>
                    </div>
                  </div>
                  <div className='mt-4'>
                      <span className='font-16 font-sofia-pro-semi-bold color-dark-charcoal'>
                        Other Payment options
                      </span>
                    <div className='mt-3 p-2 d-flex justify-content-between align-items-center'>
                      <div className='payment-options-radio__item'>
                        <input
                          type='radio'
                          id='ritema'
                          name='ritem'
                          value='ropt5'
                          onClick={googlePayBtnHandler}
                          checked={googlepaySelect === true}
                        />
                        <label
                          className='font-14 font-sofia-pro-light color-black-pearl cursor-pointer'
                          onClick={googlePayBtnHandler}
                        >
                          <span className='pl-2'> Google Pay </span>
                          <img src={GoolePay} className='mx-2' />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center'>
                <button
                  className='p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none'
                  onClick={cancelBtnHandler}
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  className='w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none'
                  onClick={reviewBtnHandler}
                  disabled={!choosenCardId && !googlepaySelect}
                >
                  Review
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-2'>&nbsp;</div>
      </div> */}
      {/* <Modal
        className="pt-4 border-0 modal-background-shadow payment-modal"
        size="md"
        show={show}
        onHide={() => setShow(false)}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Add a card</span>
          <button type="button" className="close modal-close-icon" onClick={() => setShow(false)}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <AddCardForms
                  setChoosenCardId={setChoosenCardId}
                  elements={elements}
                  stripe={stripe}
                  hideModal={() => setShow(false)}
                  loadExistingList={loadExistingCardsList}
                />
              )}
            </ElementsConsumer>
          </Elements>
        </Modal.Body>
      </Modal> */}

      {/* <Modal
        className="pt-4 border-0
         modal-background-shadow payment-modal"
        size="md"
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Delete card?</span>
          <button type="button" className="close modal-close-icon" onClick={() => setShowDeleteModal(false)}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <p className="font-16 font-sofia-pro-light color-nobel">
            Are you sure you want to delete this payment method?
          </p>
          <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={cardDeleteBtnHandler}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

// MAPPING - SEND THANKS DATA
const mapStateToProps = state => {
  return {
    multiProduct: state.multiProduct,
    getPaymentInformation: state.sendThanks.sendThanksData,
  };
};

// DISPATCH  - SEND THANKS DATA
const mapDispatchToProps = dispatch => {
  return {
    paymentInformation: data => dispatch(sendThanksDetails(data)),
    resetData: () => dispatch(resetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
