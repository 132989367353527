import React, { useEffect, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';

// Slider Values
const STEP = 50;
const MIN = 500;
const MAX = 8000;

const pricePerPerson = 50;

const BudgetRange = ({
   autoTopUp: autoTopUpInit,
   budget: budgetInit,
   formik,
   topUpCreate= true,
   setAutoTopUpSelected
}) => {

  let budgetVal
  let sliderVal
  if (budgetInit) {
    const val = budgetInit
    if (val >= MIN && val <= MAX) {
      budgetVal = val
      sliderVal = val
    } else if (val > MAX) {
      budgetVal = val
      sliderVal = MAX
    } else if (val < MIN) {
      budgetVal = val
      sliderVal = MIN
    }
  }

  const [values, setValues] = useState([sliderVal || MIN])
  const [showHeadcount, setShowHeadcount] = useState(false)
  const [autoTopUp, setAutoTopUp] = useState(autoTopUpInit || false)
  const [budget, setBudget] = useState(budgetVal || MIN)

  useEffect(()=>{
    if ( budgetInit){
        setBudget(budgetInit)
        setValues([budgetInit < MIN ? MIN : budgetInit])
        setAutoTopUp(autoTopUpInit)
      }
  },[autoTopUpInit,budgetInit])

  useEffect(() => {
    sessionStorage.setItem('autoTopUp', autoTopUp)
    if (setAutoTopUpSelected) {
      setAutoTopUpSelected(autoTopUp)
      formik.setFieldValue('autoTopUp', autoTopUp)
    }
  }, [autoTopUp])

  useEffect(() => {
    sessionStorage.setItem('budget', budget.toString())
    if (formik) {
      formik.setFieldValue('amount', budget)
    }
  }, [budget])

  const onBudgetChange = (e) => {
    const val = e.target.value
    if (val >= MIN && val <= MAX) {
      setBudget(val)
      setValues([val])
    } else if (val > MAX) {
      setBudget(val)
      setValues([MAX])
    } else if (val < MIN) {
      setBudget(val)
      setValues([MIN])
    }
  }

  return (
    <div className="budget-section">
        <div className="headcount">
          Headcount
        </div>
        <Range
          values={values}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(values) => {
            setValues(values)
            setBudget(values[0])
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%"
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "16px",
                  background: getTrackBackground({
                    values: values,
                    colors: ["#e10177", "#ccc"],
                    min: MIN,
                    max: MAX
                  }),
                  alignSelf: "center"
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "26px",
                width: "26px",
                borderRadius: "26px",
                border: "2px solid",
                borderColor: isDragged ? "#fff" : "#ccc",
                backgroundColor: isDragged ? "#e10177" : "#fff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                outline: "none"
              }}
              onMouseOver={() => setShowHeadcount(true)}
              onMouseLeave={() => setShowHeadcount(false)}
            >
              {showHeadcount &&
              <div
                style={{
                  position: 'absolute',
                  top: '28px',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                  padding: '4px',
                  borderRadius: '4px',
                  backgroundColor: '#e10177',
                  zIndex: 9990,
                }}
              >
                {Math.floor(values[0] / pricePerPerson)}
              </div>
              }
            </div>
          )}
        />
      {/* <div className={topUpCreate? '' : 'mt-5'}>
        {topUpCreate?
          <div className="p-0 b-0 pb-1 mt-5 form-group d-flex">
            <div className="input-group">
              <label className="font-sofia-pro-regular mr-3 color-charcoal-grey font-16 w-200">Set Your Budget</label>
              <div className="input-group my-1 d-flex align-items-center budget-section">
                <span className="h-60 input-group-text border-light-periwinkle h-60 border-right-0">$</span>
                <input
                  type="number"
                  className="form-control w-auto shadow-none font-sofia-pro-regular color-charcoal-grey border-light-periwinkle budget-input h-60"
                  name="budget"
                  placeholder="Set Your Budget"
                  onChange={onBudgetChange}
                  value={budget}
                />
              </div>
            </div>
          </div>
        :null}
        <div>
          <label className="font-sofia-pro-regular mr-3 color-charcoal-grey font-16 w-120">Auto Top Up</label>
          <input type="radio" checked={autoTopUp} onClick={() => setAutoTopUp(!autoTopUp)} />
        </div>
      </div> */}
    </div>
  )
}

export default BudgetRange