import React, { useEffect, useState } from 'react';

const SliderAutorotate = ({images, start = 0, selectedProduct}) => {
  const [imageIndex, setImageIndex] = useState(start)
  const [image, setImage] = useState(images[imageIndex])

  useEffect(()=>{
    if(images?.length > 1) {
        const autoImage = setInterval(()=>{
            setImageIndex(prev => {
                if(prev >= images.length-1){
                return start
                }
                return ++prev
            })
            },3000)
        return ()=>{
        clearInterval(autoImage);
        }
    } else {
        setImageIndex(0);
    }
  },[images, start, image])

  useEffect(()=>{
    setImage(images[imageIndex])
  },[imageIndex, images])

  if (images.length === 1) {
    return (
      <div className='d-flex flex-column align-items-center flex-wrap'>
        <div className="mt-4 img-card-coffee mr-3">
          <img src={`${image?.imagePath}?h=${200}`} className={`w-100 h-100 sub-category-img`} />
        </div>
        <div className="mt-4 py-1 form-group slider-description">
          <label className="mx-2 font-sofia-pro-regular color-charcoal font-16">
            {selectedProduct} - {image?.name}
          </label>
          <p className='mx-2'>{image?.description}</p>
        </div>
      </div>
    );
  }

  return (
    <div className='d-flex flex-column align-items-center flex-wrap'>
      <div className="mt-4 img-card-coffee mr-3">
        <img src={`${image?.imagePath}?h=${200}`} className={`w-100 h-100 sub-category-img`}/>
      </div>
      <div className='d-flex flex-row justify-content-center mt-5 mb-1'>
        {images.map((elem,index) =>(
          <button className='border-0 bg-transparent carousel-icons' onClick={() => setImageIndex(index)} >
              <div className={`redeemedwine-Oval__selected border-radius-8 redeemedwine-Oval__${elem?.imagePath === image?.imagePath ? 'selected'  : 'unselected' }`} />
          </button>
        ))}
      </div>
      <div className="mt-4 py-1 form-group slider-description">
        <label className="mx-2 font-sofia-pro-regular color-charcoal font-16">
          {selectedProduct} - {image?.name}
        </label>
        <p className='mx-2'>{image?.description}</p>
      </div>
    </div>
  );
};

export default SliderAutorotate;