/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import merchantService from '../../../../services/MerchantService';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import './Password.scss';
import { HidePasswordImage, ShowPasswordImage, ErrorImage } from '../../../../assets/images';

const initialValues = {
  currentPassword: '',
  password: '',
};

const validate = values => {
  let errors = {};

  if (!values.currentPassword) {
    errors.currentPassword = 'Required';
  }

  if (!values.password) {
    errors.password = 'Required';
  } else if (values.password.length < 8) {
    errors.password = ' Please enter at least 8 characters';
  }

  return errors;
};

function MerchantProfilePasswordPage(props) {
  const [currentUserData, setCurrentUserData] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const [show, setshow] = useState(false);

  const togglePassword = () => {
    setshow(!show);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  // Getting current user
  const getCurrentUser = () => {
    merchantService
      .getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCurrentUserData(data);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  // Updating password
  const onSubmit = values => {
    toast.dismiss();
    setShowLoader(true);

    let data = {
      currentPassword: values.currentPassword,
      password: values.password,
      passwordSpecified: true,
      email: null,
      emailSpecified: false,
      phone: null,
      phoneSpecified: false,
      region: 'AU',
    };

    merchantService
      .updateMerchantPassword(data)
      .then(response => {
        setShowLoader(false);
        if (response) {
          Toast('You password is updated.', 'success');
          formik.resetForm();
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  return (
    <div>
      <div className="p-0 m-0 p-4 card border-0">
        <span className="font-24 font-sofia-pro-bold color-primary">Password</span>
        <div className="mt-4">
          <span className="font-sofia-pro-bold font-14 color-gunmetal">CHANGE PASSWORD</span>
          <hr />
        </div>
        <form className="mt-1" onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">Current Password</label>
                <input
                  type="password"
                  className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl payment__placeholder border-light-periwinkle"
                  name="currentPassword"
                  placeholder="Enter current password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.currentPassword}
                />

                {formik.touched.currentPassword && formik.errors.currentPassword ? (
                  <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.currentPassword}
                  </div>
                ) : null}
              </div>
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">New Password</label>
                <div className="input-group">
                  <input
                    type={show ? 'text' : 'password'}
                    className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl payment__placeholder border-light-periwinkle  password__input"
                    name="password"
                    placeholder="Enter new password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                  />

                  <div className="p-3 input-group-append password-input__append__design">
                    {show ? (
                      <ShowPasswordImage
                        className="p-0 m-0 merchant-profile__icon__position"
                        onClick={togglePassword}
                      />
                    ) : (
                      <HidePasswordImage
                        className="p-0 m-0 merchant-profile__icon__position"
                        onClick={togglePassword}
                      />
                    )}
                  </div>
                </div>

                {formik.touched.password && formik.errors.password ? (
                  <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.password}
                  </div>
                ) : null}
              </div>
            </div>
            <div>
              <Button
                classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                children=" Save changes"
                showLoader={showLoader}
                onClick={() => onSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MerchantProfilePasswordPage;
