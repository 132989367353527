import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import Deloitte from '../../../../assets/images/redeem_thanks/image/bitmap.png';
import Microsoft from '../../../../assets/images/redeem_thanks/image/microsoft-logo-2012.png';
import Kpmg from '../../../../assets/images/redeem_thanks/image/bitmap-KPMG.png';
import BottomBar from './BottomBar';
import WhiteSceen from './WhiteSceen';
import '../../../individual/redeemThanks/RedeemBeer/redeemedbeer/RedeemedBeer.scss';

const RedeemedGift = ({image, name, type}) => {

  return (
    <div className='d-flex align-items-center justify-content-center m-5 '>
      <div className='d-flex flex-column align-items-center align-items-sm-center mx-4 mx-lg-0 my-2 '>
        <Link to='/dashboard'>
          <Logo />
        </Link>
        <div>
          <div className='redeemedwine-card__big my-3 d-flex flex-column align-items-center '>
            <img src={image + '?h=150&w=150'} alt='' className='my-3' />
            <span className='redeemedwine-text__Your-Shiraz-is-on-th font-sofia-pro-bold'>
             Your {name} is on the way
          </span>

            <span className='redeemed-text__We-appreciate-you-ta font-sofia-pro-light '>
            We appreciate you taking the time for sharing your details. We’ll send you a confirmation email when your
            {type} is shipped.
          </span>
          </div>
        </div>
        {/* <WhiteSceen /> */}
        {/* <span className='redeemedwine-text__Trusted-by font-sofia-pro-bold my-2'>Trusted by</span>
        <div className='d-flex flex-row my-3 mx-lg-0 mx-3'>
          <div className='mx-lg-4 mx-3'>
            <img src={Deloitte} />
          </div>
          <div className='mx-lg-4 mx-2'>
            <img src={Microsoft} />
          </div>
          <div className='mx-lg-4 '>
            <img src={Kpmg} />
          </div>
        </div> */}

        <BottomBar />
      </div>
    </div>
  );
};

export default RedeemedGift;
