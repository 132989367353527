/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Toast from '../../../../../services/ToasterService';
import merchantService from '../../../../../services/MerchantService';
import './Share.scss';
import { ReactComponent as SquareLogo } from '../../../../../assets/images/logo/appreci-sqaure-logo.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/images/common/copy-icon.svg';
import { ReactComponent as FacebookLogo } from '../../../../../assets/images/common/facebook-logo.svg';
import { ReactComponent as InstagramLogo } from '../../../../../assets/images/common/instagram-logo.svg';
import { ReactComponent as TwitterLogo } from '../../../../../assets/images/common/twitter-logo.svg';

function SharePage(props) {
  const [userData, setUserData] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getCurrentUser();
  }, []);

  // Getting user details
  const getCurrentUser = () => {
    merchantService
      .getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'].roles;
          setUserData(response['data']);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // document.addEventListener("keydown", (e) => {
  //     if (e.key === "Enter") {
  //         onSubmit();
  //     }
  // })

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const onSubmit = () => {
    history.push('/loading');
  };

  return (
    <div className="p-0 m-0">
      <div className="d-flex justify-content-center align-items-center bg-alice-blue">
        <div className="p-0 m-0 mt-3">
          <form onSubmit={onSubmit}>
            <div className="card border-0 p-2">
              <span className="px-2 color-charcoal-grey font-24 font-sofia-pro-light text-center">
                Share your username to start <br /> receiving thanks
              </span>
              <div className="my-2 d-flex flex-column justify-content-center align-items-center">
                <SquareLogo className="h-60" />
                <span className="font-sofia-pro-semi-bold color-charcoal font-18">@{userData['userName']}</span>
                <div className="mt-1">
                  <span className="font-sofia-pro-light font-14 oslo-grey">{userData?.profileUrl}</span> &nbsp;
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        <strong>Copy</strong>
                      </Tooltip>
                    }
                  >
                    <CopyIcon
                      className="cursor-pointer"
                      onClick={() => {
                        let link = `${userData?.profileUrl}`;
                        navigator.clipboard.writeText(link);
                        Toast('Link copied', 'success');
                      }}
                    />
                  </OverlayTrigger>
                </div>
              </div>
              <div className="mt-1"></div>
              <div className="mt-1">
                {/* <Link to={'/loading'}> */}
                <button
                  type="submit"
                  className="w-100 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                >
                  Finish
                </button>
                {/* </Link> */}
              </div>
            </div>
            {/* <div className="mt-2">
                        <span className="font-sofia-pro-medium color-black-pearl font-16">Share to</span>
                        <div className="d-flex mt-1">
                            <div className="d-flex flex-column justify-content-center align-items-center pr-3">
                                <FacebookLogo className="h-30" />
                                <span className="font-sofia-pro-light font-14 color-black-pearl">Facebook</span>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center pr-3">
                                <TwitterLogo className="h-30" />
                                <span className="font-sofia-pro-light font-14 color-black-pearl">Twitter</span>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center pr-3">
                                <InstagramLogo className="h-30" />
                                <span className="font-sofia-pro-light font-14 color-black-pearl">Instagram</span>
                            </div>
                        </div>

                    </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}

export default SharePage;
