/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useStripe, Elements } from '@stripe/react-stripe-js'; // STRIPE
import { loadStripe } from '@stripe/stripe-js';
import * as MerchantOnboardingSchema from '../../../../utils/Schema/merchantOnboarding';
import merchantService from '../../../../services/MerchantService';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import './Payment.scss';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const initialValues = {
  bsb: '',
  account: '',
};

const validate = values => {
  let errors = {};

  if (!values.bsb) {
    errors.bsb = 'Required';
  }

  if (!values.account) {
    errors.account = 'Required';
  }

  return errors;
};

const MerchantProfilePaymentPage = props => (
  <Elements stripe={stripePromise}>
    <Wrapper />
  </Elements>
);

function Wrapper(props) {
  const [merchantPaymentData, setMerchantPaymentData] = useState();
  const [merchantAgreementValue, setMerchantAgreementValue] = useState();

  const [transferScheduleValue, setTransferScheduleValue] = useState();

  const [bankAccountData, setBankAccountData] = useState();

  const [payoutData, setPayoutData] = useState();

  const [MerchantData, setMerchantData] = useState();

  const [transferScheduleMonthlyValue, setTransferScheduleMonthlyValue] = useState();

  const [showMonthlyInputBox, setShowMonthlyInputBox] = useState(false);

  const [showWeeklyDropDown, setShowWeeklyDropdown] = useState(false);

  const [weekDaySchedule, setWeekDaySchedule] = useState();

  const [showLoader, setShowLoader] = useState(false);

  const stripe = useStripe();
  const history = useHistory();

  useEffect(() => {
    getMerchantDetails();
    window.scrollTo(0, 0);
  }, []);

  // Updating payment details
  const onSubmit = values => {
    toast.dismiss();
    setShowLoader(true);

    if (values.account.startsWith('XXX') !== true) {
      if (showMonthlyInputBox === true && transferScheduleMonthlyValue === undefined) {
        Toast('Add transfer schedule value', 'warning');
        setShowLoader(false);
        return;
      }

      if (transferScheduleMonthlyValue < 1 || transferScheduleMonthlyValue > 31) {
        Toast('Add transfer schedule value between 1 to 31', 'warning');
        setShowLoader(false);
        return;
      }

      MerchantData.payout = payoutData;

      MerchantData.bankAccount = bankAccountData;

      MerchantData?.payout?.products.map((product, index) => {
        product.hasAgreement = merchantAgreementValue;
        delete product.type;
      });

      MerchantData.payout.products = merchantPaymentData?.payout?.products;
      MerchantData.payout.transferSchedule = transferScheduleValue;
      MerchantData.payout.weekDaySchedule = weekDaySchedule;
      MerchantData.payout.transfersEnabled = true;
      MerchantData.payout.dayOfMonthSchedule = transferScheduleMonthlyValue;

      let data = {
        country: 'Au',
        currency: 'aud',
        routing_number: values.bsb,
        account_number: values.account,
        account_holder_name: MerchantData.representative.firstName + ' ' + MerchantData.representative.lastName,
        account_holder_type: MerchantData.representative.role,
      };

      MerchantData.representative = null;
      MerchantData.company = null;

      stripe.createToken('bank_account', data).then(function (result) {
        // Handle result.error or result.token
        if (result?.token) {
          MerchantOnboardingSchema.merchantOnboardingData.bankAccount.token = result?.token?.id;
          sendMerchantUpdateRequest(MerchantData);
        } else if (result?.error) {
          Toast(result?.error?.message, 'error');
        }
      });
    } else {
      toast.dismiss();
      let bankData = bankAccountData?.display.split('-');

      formik.setFieldValue('bsb', bankData[1].trim());
      formik.setFieldValue('account', bankData[2].trim());

      if (bankData[1].trim() !== values.bsb) {
        Toast('Please update account number!', 'error');
      } else {
        if (showMonthlyInputBox === true && transferScheduleMonthlyValue === undefined) {
          Toast('Add transfer schedule value', 'warning');
          return;
        }

        if (transferScheduleMonthlyValue < 1 || transferScheduleMonthlyValue > 31) {
          Toast('Add transfer schedule value between 1 to 31', 'warning');
          return;
        }
        MerchantData.payout = payoutData;

        MerchantData.bankAccount = bankAccountData;

        MerchantData?.payout?.products.map((product, index) => {
          product.hasAgreement = merchantAgreementValue;
          delete product.type;
        });

        MerchantData.payout.products = merchantPaymentData?.payout?.products;
        MerchantData.payout.transferSchedule = transferScheduleValue;
        MerchantData.payout.weekDaySchedule = weekDaySchedule;
        MerchantData.payout.transfersEnabled = true;
        MerchantData.payout.dayOfMonthSchedule = transferScheduleMonthlyValue;

        MerchantData.representative = null;
        MerchantData.company = null;

        sendMerchantUpdateRequest(MerchantData);
      }

      MerchantOnboardingSchema.merchantOnboardingData.bankAccount = MerchantData.bankAccount;
    }
  };

  const sendMerchantUpdateRequest = data => {
    toast.dismiss();
    setShowLoader(true);
    data.company = null;
    data.representative = null;

    data.payout.transferSchedule = transferScheduleValue;
    data.payout.products = merchantPaymentData?.payout?.products;
    data.payout.dayOfMonthSchedule = transferScheduleMonthlyValue;

    merchantService
      .updateMerchantDetails(data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          Toast('Your changes have been saved successfully.', 'success');
          getMerchantDetails();
        }
      })
      .catch(error => {
        setShowLoader(false);
        if (error.response) {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                Toast(msg.message, 'error');
              });
            }
          }
        }
      });
  };

  // Getting merchant details
  const getMerchantDetails = () => {
    merchantService
      .getMerchantDetails()
      .then(response => {
        if (response) {
          let merchantPayoutData = response['data'];
          setMerchantPaymentData(merchantPayoutData);
          setMerchantData(merchantPayoutData);

          setBankAccountData(merchantPayoutData?.bankAccount);

          let bankData = merchantPayoutData?.bankAccount?.display.split('-');

          formik.setFieldValue('bsb', bankData[1].trim());
          formik.setFieldValue('account', bankData[2].trim());

          setTransferScheduleValue(merchantPayoutData?.payout?.transferSchedule);

          if (merchantPayoutData?.payout?.transferSchedule === 'Weekly') {
            setShowWeeklyDropdown(true);
            setShowMonthlyInputBox(false);
            setWeekDaySchedule(merchantPayoutData?.payout?.weekDaySchedule);
          } else if (merchantPayoutData?.payout?.transferSchedule === 'Monthly') {
            setShowMonthlyInputBox(true);
            setShowWeeklyDropdown(false);
            setTransferScheduleMonthlyValue(merchantPayoutData?.payout?.dayOfMonthSchedule);
          } else {
            setShowWeeklyDropdown(false);
            setShowMonthlyInputBox(false);
          }

          setPayoutData(merchantPayoutData?.payout);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const onChangeWeekDropdown = event => {
    let weekValue = event.target.value;
    setWeekDaySchedule(weekValue);
  };

  const onTransferScheduleValueChange = e => {
    let transferValue = e.target.value;

    setTransferScheduleValue(e.target.value);

    if (transferValue === 'Weekly') {
      setShowWeeklyDropdown(true);
      setShowMonthlyInputBox(false);
    } else if (transferValue === 'Monthly') {
      setShowMonthlyInputBox(true);
      setShowWeeklyDropdown(false);
    } else {
      setShowWeeklyDropdown(false);
      setShowMonthlyInputBox(false);
    }
  };

  const transferScheduleMonthlyChange = e => {
    let value = e.target.value;
    setTransferScheduleMonthlyValue(value);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  return (
    <div>
      <div className="p-0 m-0 p-4 card border-0">
        <span className="font-24 font-sofia-pro-bold color-black-pearl">Payment information</span>
        <span className="font-14 fornt-sofia-pro-light color-black-pearl">Update your payment information</span>
        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">BSB*</label>
                <input
                  type="text"
                  className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl payment__placeholder border-light-periwinkle"
                  name="bsb"
                  placeholder="Eg: 062-452"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bsb}
                />

                {formik.touched.bsb && formik.errors.bsb ? (
                  <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.bsb}
                  </div>
                ) : null}
              </div>
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">Account number*</label>
                <input
                  type="text"
                  className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl payment__placeholder border-light-periwinkle"
                  name="account"
                  placeholder="Eg: 1234567890"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account}
                />

                {formik.touched.account && formik.errors.account ? (
                  <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.account}
                  </div>
                ) : null}
              </div>
              <div>
                <label className="font-sofia-pro-regular color-charcoal font-16"> Transfer Schedule* </label>
                <div className="mt-2 d-flex align-items-center position-relative">
                  <label className="ml-4 d-flex payment-details-container">
                    <input
                      type="radio"
                      name="radio"
                      value="Daily"
                      checked={transferScheduleValue === 'Daily' ? true : false}
                      onChange={onTransferScheduleValueChange}
                    />
                    <span className="checkmark"></span>
                    <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">Daily</span>
                  </label>
                </div>
                <div className="mt-2 d-flex align-items-center position-relative">
                  <label className="ml-4 d-flex payment-details-container">
                    <input
                      type="radio"
                      name="radio"
                      value="Weekly"
                      checked={transferScheduleValue === 'Weekly' ? true : false}
                      onChange={onTransferScheduleValueChange}
                    />
                    <span className="checkmark"></span>
                    <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">Weekly</span>
                  </label>
                  {showWeeklyDropDown === true && (
                    <select
                      className="ml-2 p-2 font-sofia-pro-regular font-14 color-black-pearl"
                      value={weekDaySchedule}
                      onChange={onChangeWeekDropdown}
                    >
                      <option value="Sunday">Sunday</option>
                      <option value="Monday">Monday</option>
                      <option value="Tuesday">Tuesday</option>
                      <option value="Wednesday">Wednesday</option>
                      <option value="Thursday">Thursday</option>
                      <option value="Friday">Friday</option>
                      <option value="Saturday">Saturday</option>
                    </select>
                  )}
                </div>
                <div className="mt-2 d-flex align-items-center position-relative">
                  <label className="ml-4 d-flex payment-details-container">
                    <input
                      type="radio"
                      name="radio"
                      value="Monthly"
                      checked={transferScheduleValue === 'Monthly' ? true : false}
                      onChange={onTransferScheduleValueChange}
                    />
                    <span className="checkmark"></span>
                    <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">Monthly</span>
                  </label>
                  {showMonthlyInputBox === true && (
                    <div>
                      <input
                        type="number"
                        placeholder="Enter value between 1 to 31"
                        className="ml-2 font-sofia-pro-regular merchant__profile-payment-monthly-input"
                        onChange={transferScheduleMonthlyChange}
                        value={transferScheduleMonthlyValue}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <Button
                classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                children=" Save"
                showLoader={showLoader}
                onClick={() => onSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MerchantProfilePaymentPage;
