/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { toast } from 'react-toastify';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';
import RedeemCoffeeService from '../../../../services/RedeemCoffeeService';
import RedeemModal from './RedeemModal';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import './Redeem.scss';
import '../../../individual/dashboard/DashBoard.scss';
import Skip from '../../../../assets/images/common/skip.png';
import { ReactComponent as SendActive } from '../../../../assets/images/dashboard/icons/icons-location.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/dashboard/icons/icons-search.svg';
import RedeemEmpty from '../../../../assets/images/dashboard/image/components-illustrations-empty-state-2.png';
import httpService from '../../../../services/HttpService';
import Toasts from '../../../../common/Notification/Toasts';
import { automaticallyReceiveAPromoCode } from '../../../../utils/utils';

const RedeemPage = () => {
  const { width } = useWindowDimensions();

  let AvailableQuantity = sessionStorage.getItem('Quantity');
  const TransactionId = sessionStorage.getItem('TransactionId');
  const [availableLocation, setavailableLocation] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [search, setSearch] = useState(null);
  const [cityName, setCityName] = useState('');
  const [locationData, setLocationData] = useState([])
  const [availablelocationData, setavailablelocationData] = useState([]);
  const [locationDataSearch, setLocationDataSearch] = useState([])
  let [availablelocationPage, setavailablelocationPage] = useState(1);
  const [showToasts, setShowToasts] = useState();
  const giftInfo = JSON.parse(sessionStorage.getItem('redeemInfo'));

  Geocode.setApiKey('AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs');

  // calling geolocation function to get latitude and longitude
  useEffect(() => {
    getMyLocation();

    httpService
      .get(`locations?producttype=Coffee&region=AU&pagesize=1000&ApiKey=aebf3068-17a5-46fc-83eb-4a660c488ce8`,)
      .then(res => {
        setLocationData(res.data.data)
        setLocationDataSearch(res.data.data)
      });
  }, []);

  useEffect(()=>{
    if (availableLocation !== null) {
      let SearchFilterd = locationDataSearch.filter(val => {
        if (search !== null) {
          if (val['address'] !== null && val['name'] !== null) {
            return (
              val['address'].toLowerCase().includes(search.toLowerCase()) ||
              val['name'].toLowerCase().includes(search.toLowerCase())
            );
          }
        } else if (search === null) {
          return val;
        }
      });
      setLocationData(SearchFilterd)
    }
  },[search])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  toast.configure({
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  // doing reverse geocoding to get city name
  if (lat & (long != null)) {
    Geocode.fromLatLng(lat, long).then(res => {
      let data;

      if (res.results[0] != undefined) {
        for (let i = 0; i < res.results[0].address_components.length; i++) {
          for (let j = 0; j < res.results[0].address_components[i].types.length; j++) {
            switch (res.results[0].address_components[i].types[j]) {
              case 'administrative_area_level_1':
                data = res.results[0].address_components[i].long_name
                  ? res.results[0].address_components[i].long_name
                  : null;
                break;
            }
          }
        }
      }

      setCityName(data);
    });
  }
  // redirect to redeem screen while refresh the screen
  window.onload = function () {
    window.location = '/redeemThanks';
  };

  const GetDirection = index => {
    showMap(availableLocation[index]['latitude'], availableLocation[index]['longitude']);
  };
  // redirect to google map to show cafe location
  function showMap(lat, lng) {
    var url = 'https://maps.google.com/?q=' + lat + ',' + lng;
    window.open(url);
  }

  const getMyLocation = () => {
    const geolocation = window.navigator && window.navigator.geolocation;

    if (geolocation) {
      geolocation.getCurrentPosition(
        position => {
          getAvalilableLocation(position.coords.latitude, position.coords.longitude, availablelocationPage);
        },

        error => {}
      );
    }
  };

  const getAvalilableLocation = (latitude, longitude, count) => {
    setLat(latitude);
    setLong(longitude);
    RedeemCoffeeService.getListOfAvailableLocations(latitude, longitude, count)
      .then(res => {
        if (count > 1) {
          Array.prototype.push.apply(availableLocation, res.data['data']);
        } else {
          setavailableLocation(res.data['data']);
        }
        setavailablelocationData(res.data);
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };
  const NextPage = () => {
    setavailablelocationPage((availablelocationPage = availablelocationPage + 1));
    getAvalilableLocation(availablelocationPage);
  };

  return (
    <div>
      <div className=" m-0 p-0 row ">
        <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column ">
          <SideBar menu="Redeemthanks" />
        </div>
        <div
          className={
            locationData !== undefined && locationData !== null && locationData.length < 3
              ? ' p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh'
              : ' p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half '
          }
        >
          <Header />
          <div>
            <div className="card  m-2 border-0 d-flex  flex-column ">
              <div className=" row   mx-2  justify-content-between align-items-center my-2">
                <div className="row col-lg-8 col-sm-12  ">
                  <span className="dashboard-text__availablelocation font-sofia-pro-medium my-2">
                    Available Locations
                  </span>
                  <div
                    className="card dashboard-card__currentlocation my-2
                    flex-row align-items-center mx-2"
                  >
                    <span className="dashboard-redeem__Ovaldeactive border-radius-5  mx-1" />
                    <span className="dashboard-text__yourcurrentlocation font-sofia-pro-regular  ">
                      Your current location:{' '}
                      <span className="dashboard-text-style-1 p-1">
                        {(lat || long || cityName) === null ? 'Unknown' : cityName}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="dashboard-search row my-1 mx-1 justify-content-between align-items-center  ">
                  <SearchIcon className=" mx-lg-2 mx-md-2 " />

                  <input
                    className="font-sofia-pro-light redeem-search__availablelocation dashboard-search_text mx-lg-2 mx-md-2   border-0"
                    placeholder="Enter Postcode or suburb"
                    onChange={e => setSearch(e.target.value)}
                  />

                  <SendActive className=" mx-2 d-flex " />
                </div>
              </div>

              <div className="dashboard-line my-1" />
              {
                giftInfo?.features === 'Exchange' &&
                <div>
                  <div className={`d-flex flex-row mx-2 justify-content-between align-items-center my-2 ${width < 850 && showToasts? 'flex-wrap' : ''}`}>
                    <div className="d-flex flex-row">
                      <img src={Skip} className="redeem-skip" />
                      <div className="d-flex flex-column justify-content-center">
                      <span className="dashboard-teaxt_manlyguild font-sofia-pro-semi-bold mx-2">
                        Exchange your gift for a SKIP voucher.
                      </span>
                      <span className="dashboard-text_thecorsomanly20 font-sofia-pro-regular mx-2">
                        Up to 2000 redemption locations across Australia.
                      </span>
                      </div>
                    </div>
                    <div className={`d-flex align-items-center ${width < 850 && showToasts? 'w-100 justify-content-between' : ''}`}>
                      <Toasts text={automaticallyReceiveAPromoCode} setShowToasts={setShowToasts}/>
                      <RedeemModal
                        AvailableQuantity={AvailableQuantity}
                        exchangePriceId={giftInfo?.exchangePriceId}
                        TransactionId={TransactionId}
                        LocationId={''}
                        menu="skip"
                      />
                    </div>
                  </div>
                  <div className="dashboard-line my-1" />
                </div>
              }

              {locationData !== undefined && locationData !== null && locationData.length > 0 ? (
                locationData.map((value, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex flex-row mx-2 justify-content-between my-2">
                        <div className="d-flex flex-row">
                          {value['merchant']['logoUrl'] === null || '' ? (
                            <div className="dashboard-oval__availablelocation rounded-circle mx-1" />
                          ) : (
                            <div className=".dashboard-oval__availablelocationimage  mx-1">
                              <img
                                src={value['merchant']['logoUrl'] + '?h=80&w=80'}
                                className="redeem-caff__imageround"
                              />
                            </div>
                          )}
                          <div className="d-flex flex-column justify-content-center">
                            <span className="dashboard-teaxt_manlyguild font-sofia-pro-semi-bold mx-2">
                              {value['name']}
                            </span>
                            <span className="dashboard-text_thecorsomanly20 font-sofia-pro-regular mx-2">
                              {value['address']}
                            </span>
                            <span className="dashboard-text__kmawayGetDi font-sofia-pro-light mx-2">
                              {value['distanceInMetres'] < 1000
                                ? value['distanceInMetres'] + ' metre away'
                                : (value['distanceInMetres'] / 1000.0).toFixed(1) + ' km away'}{' '}
                              <span className="dashboard-text_dot ">.</span>
                              <button className="border-0 bg-transparent" onClick={GetDirection.bind(this, index)}>
                                <span className="dashboard-text__kmawayGetDitextstyle-1 font-sofia-pro-regular">
                                  Get Directions
                                </span>
                              </button>
                            </span>
                          </div>
                        </div>
                        <RedeemModal
                          AvailableQuantity={AvailableQuantity}
                          TransactionId={TransactionId}
                          LocationId={value['id']}
                          CoffeShopName={value['name']}
                          menu="availablelocation"
                        />
                      </div>
                      <div className="dashboard-line my-1" />
                    </div>
                  );
                })
              ) : (
                <div className=" d-flex  flex-column align-items-center justify-content-center my-3 ">
                  <img src={RedeemEmpty} />
                  <span className="redeem-text__Start-by-sending-an font-sofia-pro-regular">
                    While our network of appreci café’s are being updated, please
                    <br />exchange your gift with our partner Skip.
                  </span>
                  <a href="https://appreci.io/contact" target="_blank">
                    <button className="redeemwine-button__redeemnow border-0 my-4" onClick={() => {}}>
                      <span className="redeemwine-text__redeemnow font-sofia-pro-semi-bold">Add a café I like</span>
                    </button>
                  </a>
                </div>
              )}
            </div>
            <div>
              {availablelocationPage > 1 && availablelocationPage < availablelocationData['pageCount'] ? (
                <button onClick={NextPage} className="bg-transparent border-0 m-3">
                  <span className="transactionhistory-text__Show-more font-sofia-pro-medium">show more</span>
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemPage;
