/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *

 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation, Link, useHistory } from 'react-router-dom';
import Loader from '../../../../common/Loader/Loader';
import httpService from '../../../../services/HttpService';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Impersonate = () => {
  const history = useHistory();
  let query = useQuery();
  const Email = query.get('Email');
  const Phone = query.get('Phone');
  const Region = query.get('Region');
  const Token = query.get('Token');

  useEffect(() => {
    if (Token !== null && Token !== undefined && Token !== '') {
      Login();
    }
  });
  var string = query.get('Email');
  var email = decodeURIComponent(string);

  const Login = () => {
    let data = {
      email: email,
      phone: Phone,
      region: Region,
    };
    httpService.post(`usersessions/bytoken/${Token}`, data).then(response => {
      let responseData = response['data'];
      localStorage.setItem('userData', JSON.stringify(responseData));
      history.push('/dashboard');
    });
  };
  return (
    <div>
      <Loader />
    </div>
  );
};

export default Impersonate;
