/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Common reducer for sendthanks (Individual)
import { persistReducer, PURGE, REHYDRATE, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as sendThanksDataSchema from '../../../../utils/Schema/sendThanksSchema';

const SEND_THANKS_DETAILS = 'SEND_THANKS_DETAILS';
const PRODUCTS_LIST = 'PRODUCTS_LIST';

const RESET_SEND_THANKS_DETAILS = 'RESET_SEND_THANKS_DETAILS';

export const sendThanksDetails = data => ({
  type: SEND_THANKS_DETAILS,
  payload: {
    data,
  },
});

export const setProductsList = data => ({
  type: PRODUCTS_LIST,
  payload: {
    data,
  },
});

export const resetSendThanksDetails = data => ({
  type: RESET_SEND_THANKS_DETAILS,
  payload: {
    data,
  },
});

const emptySendThanksSchema = {
  priceId: '',
  quantity: 1,
  amount: 0,
  paymentSource: {
    token: '',
    cardid: '',
    setCardAsDefault: false,
  },
  recipients: [],
  data: {
    guid: '',
    externalId: '',
    channel: '',
    redeemUrl: '',
    delivery: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      address: {
        street: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
      },
    },
  },
};

let initialState = {
  sendThanksData: emptySendThanksSchema,
};

let sendThanksData;

export const sendThanksReducer = persistReducer(
  {
    storage,
    key: 'appreci-send-thanks',
    whitelist: ['sendThanksData'],
    // blacklist: ['sendThanksData']
  },
  (state = initialState, action) => {
    // console.log('state ', state, action);
    switch (action.type) {
      case 'SEND_THANKS_DETAILS':
        const sendThanksPriceDetails = action.payload.data;
        sendThanksData = {
          ...state.sendThanksData,
        };

        sendThanksData.recipients = sendThanksPriceDetails.recipients;
        sendThanksData.priceId = sendThanksPriceDetails.priceId;
        sendThanksData.amount = sendThanksPriceDetails.amount;
        sendThanksData.quantity = sendThanksPriceDetails.quantity;
        sendThanksData.message = sendThanksPriceDetails.message;
        if (
          sendThanksData?.paymentSource !== undefined &&
          sendThanksPriceDetails?.sendThanksData?.paymentSource !== undefined
        ) {
          sendThanksData.paymentSource.cardid = sendThanksPriceDetails.sendThanksData.paymentSource.cardid;
        }
        return {
          sendThanksData,
        };
      case 'PRODUCTS_LIST':
        const products = action.payload.data;
        return {
          products,
        };
      case 'RESET_SEND_THANKS_DETAILS':
        sendThanksDataSchema.sendThanksData.recipients = [];
        sendThanksDataSchema.sendThanksData.priceId = '';
        sendThanksDataSchema.sendThanksData.amount = '';
        sendThanksDataSchema.sendThanksData.quantity = 1;

        if (sendThanksDataSchema.sendThanksData?.paymentSource !== undefined) {
          sendThanksDataSchema.sendThanksData.paymentSource.cardid = '';
        }

        storage.removeItem('persist:appreci-send-thanks');
        state = undefined;

        return { state };
      default:
        return state;
    }
  }
);
