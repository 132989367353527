/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *

 ********************************************************************************/

import React from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import OrganisationSidebar from '../organisationsidebar/organisationsidebar';
import Header from '../../../common/Header/Header.js';
import SuspendCoffee from '../../../assets/images/payitforward/suspend-coffee.png';

const OrganisationPayItForward = () => {
  const history = useHistory();
  const payitforwardPush = () => {
    history.push('/organisation/forwardthanks');
  };
  return (
    <div className="d-flex flex-column">
      <div className=" m-0 p-0 row ">
        <div className="p-0 m-0 card border-0  col-lg-2 col-sm-12 my-4  ">
          <OrganisationSidebar menu="Redeemthanks" />
        </div>
        <div className="h-100vh p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half">
          <Header />
          <span className="payitforward-text__payitforward font-sofia-pro-light mx-4 my-2">Pay it forward</span>
          <div className=" row m-3">
            <div className="d-flex flex-column  border-0 payitforward-card col-sm-12 col-lg-5 m-lg-0 m-2">
              <img src={SuspendCoffee} className="payitforward-image__suspendcoffe my-2" />
              <span className="font-sofia-pro-light payitforward-text__Your-coffee-will-be my-2 mx-5">
                Forward your thanks to a friend in need
              </span>

              <button className=" border-0  bg-transparent" onClick={payitforwardPush}>
                <div className="payitforward-button__select d-flex justify-content-center align-items-center my-4">
                  <span className="payitforward-text__select font-sofia-pro-semi-bold">Select</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationPayItForward;
