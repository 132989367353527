import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import React from 'react';

const ModalInfoHorizontal = ({ show, onHide, productList }) => {

  return (
    <Modal
      className='border-0 modal-background-shadow '
      size='md'
      show={show}
      onHide={onHide}
      animation={false}
    >
      <button type='button' className='close select-item-modal__close__icon' onClick={onHide}>
        <Close />
      </button>
      {productList !== undefined && (
        <Modal.Body className='p-0 m-0'>
          <div className='p-0 m-0 mt-2 px-4'>
            <span className='mt-3 font-24 font-sofia-pro-bold color-dark-charcoal'>Available {productList.type}</span>
            <div className='p-0 m-0 mt-3'>
              {
                productList?.ranges !== undefined &&
                productList?.ranges.map((data, index) => {
                  return (
                    <div className='d-flex' key={index}>
                      <div className='p-0 m-0 mt-1' key={index}>
                        <img
                          src={data.imagePath + '?h=130&&w=130'}
                          style={{ backgroundColor: data?.color !== null ? data?.color : '#ffe1f4' }}
                          className='p-2'
                        />
                        <div className='pr-3 d-flex justify-content-between align-items-center'>
                          <div className='d-flex flex-column'>
                            <span className='p-0 m-0 font-14 font-sofia-pro-bold color-nero'>{data.name}</span>
                            <span className='font-12 font-sofia-pro-regular color-nobel'>Australian</span>
                          </div>
                          <span className='font-18 color-nero font-sofia-pro-light'>${data?.price}</span>
                        </div>
                      </div>
                      <p className='ml-2'>{data.description}</p>
                    </div>
                  );
                })}
            </div>
            <div className='w-100 py-3 text-center'>
              <button
                type='submit'
                className='w-50 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16'
                onClick={onHide}
              >
                Ok, got it
              </button>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default ModalInfoHorizontal;