import React from 'react';
import './Milestones.scss'; // You can create this CSS file to style the empty box
import { useLocation } from 'react-router-dom';

const QuantityBox = ({balance, quantity, total}) => {
    const location = useLocation();
  return (
    <div className='d-flex justify-content-end'>
        <div className="d-flex flex-column justify-content-center align-items-center quantity-box">
            <span className="quantity-txt">{quantity} items ( {total} )</span>
            {location.pathname !== "/selectitem" ?<span className="quantity-balance-txt">Available Balance ${balance}</span>: null}
        </div>
    </div>
)};

export default QuantityBox;
