/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Button from '../../../common/Button/Button';
import { EmailRegexValue } from '../../../utils/RegexPatterns';
import Toast from '../../../services/ToasterService';
import './Milestones.scss';
import ErrorImage from '../../../assets/images/error-icons/icons-error-info.png';
import OrganisationService from '../../../services/OrganisationService';
import {ReactComponent as Cake} from "../../../assets/images/common/cake.svg";
import {ReactComponent as EditMilestone} from "../../../assets/images/common/edit-user.svg"
import {ReactComponent as InfoInActive} from "../../../assets/images/common/info-icon-inactive.svg";
import {ReactComponent as InfoActive} from "../../../assets/images/common/info-icon-active.svg";
import {ReactComponent as ClosePopup} from "../../../assets/images/common/close-popup.svg";
import {ReactComponent as Date} from "../../../assets/images/common/date-picker.svg";
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import {DatePicker} from "antd";
import ReactTooltip from 'react-tooltip';
import CustomDropdown from '../../../common/CustomDropDown/CustomDropdown';
import moment from 'moment';
import HttpService from '../../../services/HttpService';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'

const AddRecipients = ({handleShowMessageScreen, formik, modals, setModals}) => {

  dayjs.extend(utc);
  const [showMilestonePopup, setShowMilestonePopup] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  // const [modals, setModals] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [isInfoClick, setIsInfoClick] = useState(false);

  const [clickedValue, setClickedValue] = useState(false);
  const [clickedModalValue, setClickedModalValue] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const [checkIsDataLoadedOrNot, setCheckIsDataLoadedOrNot] = useState(false);
  const [usersList, setUsersList] = useState([]);
  let [existingUsersList, setExistingUsersList] = useState([]);
  const [disable, setDisable] = useState(true);

  const [employeeData, setEmployeeData] = useState(formik.values.recipients);

  useEffect(() => {
    setEmployeeData(formik.values.recipients);
  }, [formik.values.recipients]);

  useEffect(() => {
    getUsersList();
    return {};
  }, []);

  let isNextButtonDisabled = employeeData.some(
    (employee) => !employee?.employee || !employee?.date
  );

  const getUsersList = () => {
    OrganisationService.getUsersList()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCheckIsDataLoadedOrNot(true);
          if (data.length === 0) {
            setUsersList([]);
          } else {
            setUsersList(data);
            setExistingUsersList(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const searchUserList = (val, index) => {
    formik.setFieldValue('searchData', (usersList.filter(user => 
      user.firstName.toLowerCase().includes(val.toLowerCase()) ||
      user.lastName.toLowerCase().includes(val.toLowerCase())
    )));

    setClickedValue(true);
  }

  const searchModalUserList = (val, index) => {
    setClickedModalValue(true);
    setModalIndex(index);
    if(formik.values.searchData.length !== 0) {
      const filteredUsers = formik.values.searchData.filter(user => 
        user.firstName.toLowerCase().includes(val.toLowerCase()) ||
        user.lastName.toLowerCase().includes(val.toLowerCase())
      );
      formik.setFieldValue('searchData',filteredUsers);
    } else {
      const filteredUsers = usersList.filter(user => 
        user.firstName.toLowerCase().includes(val.toLowerCase()) ||
        user.lastName.toLowerCase().includes(val.toLowerCase())
      );
      formik.setFieldValue('searchData',filteredUsers);
    }

    // const filteredUsers = formik.values.searchData.filter(user => 
    //   user.firstName.toLowerCase().includes(val.toLowerCase()) ||
    //   user.lastName.toLowerCase().includes(val.toLowerCase())
    // );
    // formik.setFieldValue('searchData',filteredUsers);
  }
  // searchUserList(0);
  const handleInfoClick = (value) => {
    setIsInfoClick(value);
  };

  const onOpenMileStonePopup = () => {
    setClickCount(prevCount => prevCount + 1);
    setModals(prevCount =>([ ...prevCount , {}]));
    setEmployeeData([...formik.values.recipients , {employee:"",date:""}]);
    formik.setFieldValue("recipients", [...formik.values.recipients , {employee:"",date:""}])
  }

  const onCloseMileStonePopup = (index) => {
    setShowMilestonePopup(false);
  }

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const handleModalClose = (index) => {
    const deselectedUser = formik.values.selectedUsers.find(user => `${user['firstName']} ${user['lastName']}` === formik.values.recipients[index +1]?.employee);
    formik.setFieldValue('searchData', ([...formik.values.searchData, deselectedUser]))
    const updatedUsers = formik.values.selectedUsers.filter(user => `${user['firstName']} ${user['lastName']}` !== formik.values.recipients[index +1]?.employee);
    formik.setFieldValue('selectedUsers', updatedUsers);

    const updatedRecipients = [...formik.values.recipients];
    updatedRecipients.splice(index + 1, 1);

    if (updatedRecipients.length >= 1) {
      // Update the formik values with the updated recipients array
      formik.setFieldValue('recipients', updatedRecipients);
    } else {
      // Don't delete the last item, you can show a message or handle this case as needed
      toast.error("Cannot delete the last recipient.");
    }
    // Close the specific modal by filtering out the clicked index
    setModals(prevModals => prevModals.filter((_, i) => i !== index));
    if (modals.length === 1) {
      // Close the modal if it was the last one open
      setShowMilestonePopup(false);
    }
  };

  const selectUser = (index, val, event) => {
    event.preventDefault();
    formik.setFieldValue(`recipients[${index}].employee`, val);
    const selectedUser = formik.values.searchData.find(user => `${user['firstName']} ${user['lastName']}` === val);
    formik.setFieldValue('selectedUsers', [...formik.values.selectedUsers, selectedUser]);
    const updatedUsers = formik.values.searchData.filter(user => `${user['firstName']} ${user['lastName']}` !== val);
    formik.setFieldValue('searchData', (updatedUsers));
    setClickedValue(false);
    setClickedModalValue(false);
  }

  const SearcheResult = ({i}) => {
    return (
      <>
        {formik.values.searchData.length>0 ? 
          (<div className="header-search_result d-flex flex-column">
          <div className="header-search__resultline my-3" />
          <div className="d-flex mx-3  my-lg-0 my-3">
            <span className="header-search__results-found font-sofia-pro-medium">
              {formik.values.searchData.length} results
              found
            </span>
          </div>
          {formik.values.searchData.map((val, index) => {
                return (
                  <div key={index} onClick={(e) => {selectUser(i, `${val['firstName']} ${val['lastName']}`, e)}}>
                    <div className="d-flex justify-content-between align-items-center flex-row m-3">
                      <div className="d-flex">
                        {/* <div>
                          {val['profilePhotoUrl'] != null ? (
                            <img src={val['profilePhotoUrl'] + '?h=40&w=40'} className="rounded-circle" />
                          ) : (
                            <ProfilePic className="rounded-circle header-search__Ovalimage" />
                          )}
                        </div> */}

                        <div className="d-flex flex-column cursor-pointer">
                          <span className="header-search__results-found font-sofia-pro-regular mx-3">
                            {`${val['firstName']} ${val['lastName']}`}
                          </span>
                          <span className="header-search__results-foundusername font-sofia-pro-regular mx-3">
                            {val['email']}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="header-search__resultline " />
                  </div>
                );
              })
            }
        </div>): ''}
      </>
    );
  };

  const options = [
    "Email"
  ]

  const disabledDate = current => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };

  const handleRecipientChange = (index, field, value) => {
    const updatedRecipients = [...formik.values.recipients];
    updatedRecipients[index][field] = value;
    formik.setFieldValue('recipients', updatedRecipients);
  };

  const handleDateChange = (index, dateString) => {
    const utcDate = moment.tz(dateString.format("YYYY-MM-DD HH:mm:ss"), "Australia/Sydney").toISOString()

    // Call the existing handleRecipientChange function with the converted date
    handleRecipientChange(index, 'date', utcDate);
  }

  const formatted = dayjs(formik.values.recipients[0]?.date).format("DD/MM");

  return (
    <>
    <form className="mt-3">
      <div className="p-0 b-0 pb-1 form-group">
        <label className="font-sofia-pro-regular color-charcoal font-16">Send Via</label>
        <CustomDropdown options={options} 
          name="sendVia"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.sendVia}
        />
      </div>
      <div className='row position-relative'>
          {isInfoClick ? <>
          <ReactTooltip place="top" effect="solid"/>
          <InfoActive data-tip="This is the milestone date for your recipient" className='position-absolute' style={{top: '10px', right: '12px', cursor: 'pointer', zIndex: '3'}} onClick={() => handleInfoClick(false)}/></>: 
          <InfoInActive data-tip="This is the milestone date for your recipient" className='position-absolute' style={{top: '10px', right: '12px', cursor: 'pointer', zIndex: '3'}} onClick={() => handleInfoClick(true)}/>}
          <div className='col-1' ></div>
          <div className='d-flex flex-column'>
            <div className="col-md-6 col-lg-6 my-2 p-0 b-0 pb-1 form-group position-absolute" style={{left: '21px'}}>
              <label className="font-sofia-pro-regular color-charcoal title-label">Employee</label>
              <input
                type="text"
                // className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none title-input"
                className="mr-2 h-60 form-control font-sofia-pro-regular border-light-periwinkle title-input shadow-none"
                name={`recipients[0].employee`}
                placeholder="Type staff name"
                onChange={(e) => {formik.handleChange(e); searchUserList(e.target.value, 0)}}
                onBlur={formik.handleBlur}
                value={formik.values.recipients[0]?.employee || ''}
                // onKeyDown={(e) => handleKeyDown(e, 0)}
                autoComplete="off"
              />
              {formik.touched.recipients?.[0]?.employee &&
                formik.errors.recipients?.[0]?.employee ? (
                  <div>{formik.errors.recipients[0].employee}</div>
                ) : null}
            </div>
            <div className="col-md-6 col-lg-6 my-2 p-0 b-0 pb-1 form-group position-absolute" style={{left: '21px'}}>
              {clickedValue === true && !formik.values.recipients[0]?.employee.includes(' ') ? (
                <div className="d-flex justify-content-start ">
                  <SearcheResult i={0}/>
                </div>
              ) : null }
            </div>
          </div>
          <div className="col-5 my-2 p-0 b-0 pb-1 form-group" style={{right: '-47%'}}>
            <label className="font-sofia-pro-regular color-charcoal title-label">Date</label>
            <div className='form-control-wrapper'>
            <DatePicker
              disabledDate={disabledDate}
              placeholder=""
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
              name={`recipients[0].date`}
              value={formik.values.recipients[0]?.date ? dayjs.utc(formik.values.recipients[0]?.date) : null}
              onChange={(val) => handleDateChange(0, val)}
              format="DD/MM"
              suffixIcon={<Date className='date-icon'/>}
              allowClear={false}
              />
              {formik.touched.recipients?.[0]?.date &&
                formik.errors.recipients?.[0]?.date ? (
                  <div>{formik.errors.recipients[0].date}</div>
                ) : null}
            </div>
          </div>
          </div>
      <div className='container'>
      {modals.map((modal, index) => (
          <div className='row position-relative' style={{background: '#F8F9FA', borderRadius: '5px', marginTop: '2%'}}>
          <ClosePopup className='position-absolute' style={{top: '10px', right: '12px', cursor: 'pointer', zIndex: '3'}} onClick={() => {handleModalClose(index)
          }}/>
          <div className='col-1' ></div>
          <div className='d-flex flex-column'>
            <div className="col-md-6 col-lg-6 my-2 p-0 b-0 pb-1 form-group position-absolute" style={{left: '21px'}}>
              <label className="font-sofia-pro-regular color-charcoal title-label">Employee</label>
              <input
                type="text"
                className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none title-input"
                name={`recipients[${index + 1}].employee`}
                placeholder="Type staff name"
                onChange={(e) => {formik.handleChange(e); searchModalUserList(e.target.value, index + 1);}}
                onBlur={formik.handleBlur}
                value={formik.values.recipients[index + 1]?.employee || ''}
                autoComplete="off"
              />
              {formik.touched.recipients?.[index]?.employee &&
                formik.errors.recipients?.[index]?.employee ? (
                  <div>{formik.errors.recipients[index].employee}</div>
                ) : null}
            </div>
            <div className="col-md-6 col-lg-6 my-2 p-0 b-0 pb-1 form-group position-absolute" style={{left: '21px'}}>
              {clickedModalValue === true && modalIndex == index + 1 && !formik.values.recipients[index + 1]?.employee.includes(' ')? (
                <div className="d-flex justify-content-start ">
                  <SearcheResult i={index + 1}/>
                </div>
              ) : null }
            </div>
          </div>
          <div className="col-5 my-2 p-0 b-0 pb-1 form-group" style={{right: '-47%'}}>
            <label className="font-sofia-pro-regular color-charcoal title-label">Date</label>
            <div className='form-control-wrapper'>
            <DatePicker disabledDate={disabledDate} placeholder="" className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
            name={`recipients[${index + 1}].date`}
            value={formik.values.recipients[index + 1]?.date ? dayjs.utc(formik.values.recipients[index + 1]?.date) : null}
            onChange={(val) => handleDateChange(index + 1, val)}
            format="DD/MM"
            suffixIcon={<Date className='date-icon'/>}
            allowClear={false}/>
            {formik.touched.recipients?.[index]?.date &&
                formik.errors.recipients?.[index]?.date ? (
                  <div>{formik.errors.recipients[index].date}</div>
                ) : null}
            </div>
          </div>
          </div>
      ))}
      <div className='row'>
      <span className="font-sofia-pro-bold font-16 my-1" style={{color: '#E10177', cursor: 'pointer'}} onClick={() => {onOpenMileStonePopup()}}>+Add another employee</span>
      </div>
      </div>
      <div className="d-flex justify-content-between w-100">
        <Button
          classNameValue="btn-back-recipient my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn font-16 font-sofia-pro-semi-bold"
          children="Back"
          showLoader={showLoader}
          // onClick={handleRecipientsBack}
        />
        <Button
          classNameValue="btn-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
          children="Next"
          showLoader={showLoader}
          onClick={handleShowMessageScreen}
          disabled={isNextButtonDisabled==true ? isNextButtonDisabled: formik.values.recipients[0]?.employee && formik.values.recipients[0]?.date ? false : true}
        />
      </div>
    </form>
    </>
  );
};

export default AddRecipients;
