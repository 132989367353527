/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState } from 'react';
import { Router, Link } from 'react-router-dom';
import BottomBar from '../BottomBar';
import './RedeemedWine.scss';
import { ReactComponent as Logo } from '../../../../../assets/images/dashboard/icons/logo-colour.svg';
import Love from '../../../../../assets/images/redeem_thanks/image/group-5_love.png';
import Mike from '../../../../../assets/images/redeem_thanks/image/group-5_mike.png';
import Coffee from '../../../../../assets/images/redeem_thanks/image/group-5.png';
import Deloitte from '../../../../../assets/images/redeem_thanks/image/bitmap.png';
import Microsoft from '../../../../../assets/images/redeem_thanks/image/microsoft-logo-2012.png';
import Kpmg from '../../../../../assets/images/redeem_thanks/image/bitmap-KPMG.png';
import IconsBackImage from '../../../../../assets/images/common/icons-back.png';

const RedeemedWine = () => {
  const WineType = JSON.parse(sessionStorage.getItem('Wine Type'));
  const WhiteSceen = () => {
    const [first, setFirst] = useState(true);
    const [second, setSecond] = useState(false);
    const [three, setThree] = useState(false);
    const First = () => {
      setFirst(true);
      setSecond(false);
      setThree(false);
    };
    const Second = () => {
      setFirst(false);
      setSecond(true);
      setThree(false);
    };
    const Three = () => {
      setFirst(false);
      setSecond(false);
      setThree(true);
    };
    window.onload = function () {
      window.location = '/redeemThanks';
    };
    return (
      <div>
        <div className="redeemedwine-card__white d-flex flex-column align-items-center my-2">
          {first === true ? (
            <img src={Love} className="my-3" />
          ) : second === true ? (
            <img src={Mike} className="my-3" />
          ) : three === true ? (
            <img src={Coffee} className="my-3" />
          ) : (
            ''
          )}

          {first === true ? (
            <span className="font-sofia-pro-bold redeemedwine-text__Support-a-cause">
              Support <span className="text-style-1">a cause</span>
            </span>
          ) : second === true ? (
            <span className="font-sofia-pro-bold redeemedwine-text__Support-a-cause">
              Event <span className="text-style-1">Marketing</span>
            </span>
          ) : three === true ? (
            <span className="font-sofia-pro-bold redeemedwine-text__Support-a-cause">
              New <span className="text-style-1">Customers</span>
            </span>
          ) : (
            ''
          )}

          {first === true ? (
            <span className="redeemedwine-text__People-are-using-App font-sofia-pro-light">
              People are using Appreci to support charities. Via the app, users can forward their thanks to charities as
              a way to support those in need.
            </span>
          ) : second === true ? (
            <span className="redeemedwine-text__People-are-using-App font-sofia-pro-light">
              Organisations are using Appreci to appreciate their events attendees.
            </span>
          ) : (
            <span className="redeemedwine-text__People-are-using-App font-sofia-pro-light">
              Getting on the Appreci map exposes cafes to thousands of potential appreciative customers.
            </span>
          )}
        </div>
        <div className="d-flex flex-row justify-content-center my-4">
          <button
            className="border-0 bg-transparent"
            onClick={() => {
              First();
            }}
          >
            {first === true ? (
              <div className="redeemedwine-Oval__selected border-radius-8" />
            ) : (
              <div className="redeemedwine-Oval__unselected border-radius-8" />
            )}
          </button>
          <button
            className="border-0 bg-transparent"
            onClick={() => {
              Second();
            }}
          >
            {second === true ? (
              <div className="redeemedwine-Oval__selected border-radius-8" />
            ) : (
              <div className="redeemedwine-Oval__unselected border-radius-8" />
            )}
          </button>
          <button
            className="border-0 bg-transparent"
            onClick={() => {
              Three();
            }}
          >
            {three === true ? (
              <div className="redeemedwine-Oval__selected border-radius-8" />
            ) : (
              <div className="redeemedwine-Oval__unselected border-radius-8" />
            )}
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className="d-flex flex-column align-items-center align-items-sm-center mx-4 mx-lg-0 my-2 ">
      <Logo />

      <div>
        <div className="d-flex">
          <Link to="/redeemthanks" style={{ textDecoration: 'none' }}>
            <span className="p-2 m-4 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="redeemedwine-card__big my-3 d-flex flex-column align-items-center ">
          <img src={WineType['Url'] + '?h=150&w=150'} className="my-2" />
          <span className="redeemedwine-text__Your-Shiraz-is-on-th font-sofia-pro-bold">
            Your {WineType['Name']} is on the way!
          </span>

          <span className="redeemed-text__We-appreciate-you-ta font-sofia-pro-light ">
            We appreciate you taking the time for sharing your details. We’ll send you a confirmation email when your
            wine is shipped.
          </span>
        </div>
      </div>
      <WhiteSceen />
      <span className="redeemedwine-text__Trusted-by font-sofia-pro-bold my-2">Trusted by</span>
      <div className="d-flex flex-row my-3 mx-lg-0 mx-3">
        <div className="mx-lg-4 mx-3">
          <img src={Deloitte} />
        </div>
        <div className="mx-lg-4 mx-2">
          <img src={Microsoft} />
        </div>
        <div className="mx-lg-4 ">
          <img src={Kpmg} />
        </div>
      </div>

      <BottomBar />
    </div>
  );
};

export default RedeemedWine;
