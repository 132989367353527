/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
// STRIPE
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, ElementsConsumer } from '@stripe/react-stripe-js';
// MOMENT
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import OrganisationHeader from '../../../../organisationheader/organisationHeader';
import Button from '../../../../../../common/Button/Button';
import OrganisationSidebar from '../../../../../../components/organisation/organisationsidebar/organisationsidebar';
import * as AllocateSchema from '../../../../../../utils/Schema/allocateSchema';
import SendThanksService from '../../../../../../services/SendThanksService';
import PaymentService from '../../../../../../services/PaymentService';
import {
  allocateCreditDetails,
  resetAllocateCreditDetails,
} from '../../../../../../store/reducers/organisation/organisation';
import OrganisationService from '../../../../../../services/OrganisationService';
import IconsBackImage from '../../../../../../assets/images/common/icons-back.png';
import IconMiscCard from '../../../../../../assets/images/common/icons-misc-card.png';
import GoolePay from '../../../../../../assets/images/common/google-pay-mark-800.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

function Wrapper(props) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const PaymentType = sessionStorage.getItem('PaymentType');
  const [cardDetails, setCardDetails] = useState();
  const [gpayToken, setGpayToken] = useState();

  const [showGooglePayLoader, setShowGooglePayLoader] = useState(false);

  const [showSendThanksLoader, setShowSendThanksLoader] = useState(false);
  const [creditBalance, setCreditBalance] = useState();

  let reviewData = {};
  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  const allowedCardNetworks = ['MASTERCARD', 'VISA'];

  const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

  const recipientDetails = AllocateSchema.AllocateSchema;

  const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'stripe',
      'stripe:version': 'v3',
      'stripe:publishableKey': process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },
  };

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
    },
  };

  const cardPaymentMethod = Object.assign(
    {},
    { tokenizationSpecification: tokenizationSpecification },
    baseCardPaymentMethod
  );

  let paymentsClient = null;
  function getGoogleIsReadyToPayRequest() {
    return Object.assign({}, baseRequest, {
      allowedPaymentMethods: [baseCardPaymentMethod],
    });
  }

  function getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = {
      merchantName: 'Appreci Pty Ltd',
    };
    return paymentDataRequest;
  }

  function getGooglePaymentsClient() {
    if (paymentsClient === null) {
      // eslint-disable-next-line no-undef
      paymentsClient = new google.payments.api.PaymentsClient({
        environment: process.env.REACT_APP_MERCHANT_ENVIRONMENT,
      });
    }
    return paymentsClient;
  }

  const onGooglePayLoaded = () => {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(res => {
        if (res.result) {
          addGooglePayButton();
        }
      })
      .catch(function (err) {
        console.error(err);
      });
  };

  function addGooglePayButton() {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({
      onClick: onGooglePaymentButtonClicked,
      buttonSizeMode: 'fill',
    });
    document.getElementById('container').appendChild(button);
  }

  function getGoogleTransactionInfo() {
    return {
      countryCode: 'AU',
      currencyCode: 'AUD',
      totalPriceStatus: 'FINAL',
      totalPrice: 0,
    };
  }

  function prefetchGooglePaymentData() {
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode: 'USD',
    };
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.prefetchPaymentData(paymentDataRequest);
  }

  function onGooglePaymentButtonClicked() {
    setShowGooglePayLoader(true);
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .loadPaymentData(paymentDataRequest)

      .then(res => {
        let data = JSON.parse(res.paymentMethodData.tokenizationData.token);
        setGpayToken(data.id);
        setShowGooglePayLoader(false);
      })
      .catch(function (err) {
        setShowGooglePayLoader(false);
        console.error(err);
      });
  }

  if (props?.props?.location?.state) {
    reviewData = props?.props?.location?.state;
    let giftCardValue = JSON.parse(sessionStorage.getItem('giftCardValue'));
    reviewData['amount'] =
      reviewData.productType === 'GiftCard' ? (giftCardValue !== undefined ? giftCardValue : 0) : reviewData.amount;
  }

  const [productPrice, setProductPrice] = useState();

  const [productDetailValues, setProductDetailValues] = useState();

  useEffect(() => {
    getCreditBalance();
    getCardsList();
    loadExistingProductsList();
    setProductDetailValues(JSON.parse(sessionStorage.getItem('productDetails')));
  }, [productPrice]);

  const getCreditBalance = () => {
    OrganisationService.getCreditsBalance()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCreditBalance(data);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          toast.error(errorMessage);
        }
      });
  };

  const getCardsList = () => {
    PaymentService.getListOfCards(true).then(response => {
      if (response.status === 200) {
        let cardId = AllocateSchema.AllocateSchema.paymentSource.cardid;
        let data = response['data'];

        data.forEach((card, index) => {
          if (card.id === cardId) {
            reviewData['cardLastNumber'] = card?.last4;
            reviewData['brand'] = card?.brand;
            reviewData['cardExpiryDate'] = card?.expiresOn;

            let data = {
              cardLastNumber: card?.last4,
              brand: card?.brand,
              cardExpiryDate: card?.expiresOn,
            };

            setCardDetails(data);
          }
        });
      }
    });
  };

  const loadExistingProductsList = () => {
    SendThanksService.getListOfProducts('AU').then(response => {
      let data = response['data'];

      let priceId = AllocateSchema.AllocateSchema.priceId;
      let choosenCardId = AllocateSchema.AllocateSchema.paymentSource.cardid;

      data.forEach((product, index) => {
        if (product.price.priceId === priceId && reviewData.productType !== 'GiftCard') {
          setProductPrice(product.price.price);
          AllocateSchema.AllocateSchema.amount = 0;
          AllocateSchema.AllocateSchema.priceId = priceId;
          AllocateSchema.AllocateSchema.paymentSource.cardid = choosenCardId;
          AllocateSchema.AllocateSchema.amount = 0;
          AllocateSchema.AllocateSchema.paymentSource.token = null;
        } else if (reviewData.productType === 'GiftCard') {
          AllocateSchema.AllocateSchema.amount = 0;
          AllocateSchema.AllocateSchema.priceId = priceId;
          AllocateSchema.AllocateSchema.paymentSource.cardid = choosenCardId;
          AllocateSchema.AllocateSchema.paymentSource.token = null;
          AllocateSchema.AllocateSchema.quantity = null;
        }
      });

      let product = JSON.parse(sessionStorage.getItem('productDetails'));
      if (product !== undefined) {
        setProductDetailValues(product);
      }
    });
  };

  useEffect(() => {
    if (gpayToken != undefined) {
      gpayButton();
    }
  });
  const gpayButton = () => {
    let data = {
      paymentSource: {
        token: gpayToken,
        cardid: null,
        setCardAsDefault: false,
      },
      priceId: AllocateSchema.AllocateSchema.priceId,
      amount: AllocateSchema.AllocateSchema.amount,
    };
    SendThanksService.purchaseProduct(data)
      .then(res => {
        history.push({
          pathname: '/organisation/allocate/success',
          state: {
            productType: productDetailValues.productType,
          },
        });
      })
      .catch(err => {
        toast.error('Something went wrong');
      });
  };

  const sendThanksBtnHandler = () => {
    setShowSendThanksLoader(true);
    let data = {
      amount: AllocateSchema.AllocateSchema.amount,
      message: AllocateSchema.AllocateSchema.message,
      paymentSource: AllocateSchema.AllocateSchema.paymentSource,
    };

    if (PaymentType === 'Card') {
      // data['quantity'] = null;
      data.paymentSource.useCredit = false;
      OrganisationService.allocateCredits(AllocateSchema.AllocateSchema.employeeId, data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            let secretId = response['data'].intentSecret;
            let paymentMethod = AllocateSchema.AllocateSchema.paymentSource.cardid;
            handlePayment(secretId, paymentMethod, props);
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
          }
          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    } else if (PaymentType === 'availableCredit') {
      data.paymentSource.useCredit = true;
      data['quantity'] = null;

      OrganisationService.allocateCredits(AllocateSchema.AllocateSchema.employeeId, data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            let secretId = response['data'].intentSecret;
            let paymentMethod = AllocateSchema.AllocateSchema.paymentSource.cardid;
            // handlePayment(secretId, paymentMethod, props);
            history.push({
              pathname: '/organisation/allocate/success',
              state: {
                type: 'organisation',
              },
            });
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
          }
          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const handlePayment = async (intentSecret, paymentMethod, props) => {
    const paymentIntent = await stripe.confirmCardPayment(intentSecret, {
      payment_method: paymentMethod,
    });

    let data = paymentIntent.paymentIntent;

    if (data?.status === 'succeeded') {
      history.push({
        pathname: '/organisation/allocate/success',
        state: {
          productType: productDetailValues.productType,
        },
      });
    } else {
      let errorMessage = paymentIntent?.error?.message;
      toast.error(errorMessage);
    }
  };

  const editMessageBtnHandler = () => {
    props?.props?.history.push({
      pathname: '/organisation/allocate/recipientInformation',
      state: {
        nextPath: '/organisation/allocate/review',
      },
    });
  };

  const cancelBtnHandler = () => {
    props?.props?.history.push({
      pathname: '/employee/dashboard',
    });
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column  ">
        <OrganisationSidebar menu="Credits" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half">
        <OrganisationHeader role="employee" />
        <div className="d-flex m-2 mx-4">
          <Link to="/organisation/allocate/recipientInformation">
            <span className="p-2 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 card border-0  border-radius-5">
              <div className="card-body">
                <span className="font-32 font-sofia-pro-light color-black-pearl">Review your purchase</span>
                <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                  <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Details</span>
                </div>
                <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                  <span className="font-16 font-sofia-pro-light color-charcoal">To</span>

                  <span className="font-16 font-sofia-pro-regular color-charcoal">
                    {AllocateSchema.AllocateSchema.name}
                  </span>
                </div>

                <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                  <span className="font-16 font-sofia-pro-light color-charcoal">
                    Message
                    <span>
                      <span
                        className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer"
                        onClick={editMessageBtnHandler}
                      >
                        Edit message
                      </span>
                    </span>
                  </span>

                  <div>
                    <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                      {recipientDetails.message !== null ? recipientDetails.message : 'No message'}
                    </span>
                  </div>
                </div>

                <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                  <span className="font-16 font-sofia-pro-light color-charcoal">Amount</span>
                  <div>
                    <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                      $ {recipientDetails.amount}
                    </span>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Payment method</span>
                  </div>
                  <div className="mt-1 p-2 d-flex justify-content-between align-items-center">
                    <div>
                      {PaymentType === 'Card' ? (
                        <label className="font-14 font-sofia-pro-light color-black-pearl ">
                          <span>
                            {' '}
                            {cardDetails?.brand} •••• •••• •••• {cardDetails?.cardLastNumber} |
                            <span>
                              <Moment format="MM/YY">{cardDetails?.cardExpiryDate}</Moment>
                            </span>
                          </span>
                          <img className="pl-1" src={IconMiscCard} />
                        </label>
                      ) : PaymentType === 'GooglePay' ? (
                        <label className="font-14 font-sofia-pro-light color-black-pearl ">
                          <span>Google Pay</span>
                          <img className="pl-1" src={GoolePay} />
                        </label>
                      ) : (
                        ''
                      )}
                      {PaymentType === 'availableCredit' && (
                        <label className="font-14 font-sofia-pro-light color-black-pearl ">
                          <span>Available credits</span>
                        </label>
                      )}
                    </div>

                    <div>
                      <span className="pl-2 font-16 font-sofia-pro-regular color-charcoal cursor-pointer">
                        $ {creditBalance?.balance}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Purchase summary</span>
                  </div>
                  <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
                    <span className="font-16 font-sofia-pro-light color-charcoal">Allocated credits</span>
                    <span className="font-16 font-sofia-pro-regular color-charcoal">${recipientDetails.amount}</span>
                  </div>
                  {PaymentType === 'availableCredit' && (
                    <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
                      <span className="font-16 font-sofia-pro-light color-charcoal">Remaining credit balance</span>
                      <span className="font-16 font-sofia-pro-regular color-charcoal">
                        ${creditBalance?.balance - recipientDetails.amount}
                      </span>
                    </div>
                  )}
                </div>
                {PaymentType !== 'availableCredit' && (
                  <div className="mt-3">
                    <div className="mt-2 p-2 d-flex justify-content-between align-items-center bg-lavender-blush-1 border-radius-5">
                      <span className="font-18 font-sofia-pro-semi-bold color-dark-charcoal">Total spend</span>
                      <span className="font-18 font-sofia-pro-medium color-dark-charcoal">
                        ${recipientDetails.amount}
                      </span>
                    </div>
                  </div>
                )}

                {PaymentType === 'availableCredit' && (
                  <div className="mt-3">
                    <div className="mt-2 p-2 d-flex justify-content-between align-items-center bg-lavender-blush-1 border-radius-5">
                      <span className="font-18 font-sofia-pro-semi-bold color-dark-charcoal">Total spend</span>
                      <span className="font-18 font-sofia-pro-medium color-dark-charcoal">
                        ${recipientDetails.amount}
                      </span>
                    </div>
                  </div>
                )}

                <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
                  <button
                    className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none"
                    onClick={cancelBtnHandler}
                  >
                    Cancel
                  </button>

                  {PaymentType === 'GooglePay' ? (
                    <Button
                      classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      children="Send"
                      showLoader={showGooglePayLoader}
                      onClick={onGooglePaymentButtonClicked}
                    />
                  ) : PaymentType === 'Card' || PaymentType === 'availableCredit' ? (
                    <Button
                      classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      children="Send"
                      showLoader={showSendThanksLoader}
                      onClick={sendThanksBtnHandler}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
          <div className="d-none"></div>
        </div>
      </div>
    </div>
  );
}

const OrganisationAllocateReviewThanksPage = props => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => <Wrapper elements={elements} stripe={stripe} props={props} />}
      </ElementsConsumer>
    </Elements>
  );
};

const mapStateToProps = state => {
  return {
    getAllocateRecipientInfo: state.organisation.allocateData,
  };
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    allocateCreditDetails: data => dispatch(allocateCreditDetails(data)),
    resetData: () => dispatch(resetAllocateCreditDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationAllocateReviewThanksPage);
