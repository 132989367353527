/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import moment from 'moment';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import * as MerchantOnboardingSchema from '../../../../utils/Schema/merchantOnboarding';
import merchantService from '../../../../services/MerchantService';
import MerchantService from '../../../../services/MerchantService';
import LocationService from '../../../../services/LocationService';
import * as RegexPattern from '../../../../utils/RegexPatterns';
import Toast from '../../../../services/ToasterService';
import SearchLocationInput from '../../../../common/GooglePlaces/PlacesSearch';
import Button from '../../../../common/Button/Button';
import './PersonalDetails.scss';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';

let autoComplete;

const initialValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  idNumber: null,
};

const validate = values => {
  let errors = {};

  let re = RegexPattern.MobileRegexValue;

  let license = RegexPattern.DrivingLicense;

  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.length > 25) {
    errors.firstName = 'Characters length should not exceed 25';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.length > 25) {
    errors.lastName = 'Characters length should not exceed 25';
  }

  if (!values.idNumber) {
    errors.idNumber = 'Required';
  }
  // else if (!re.test(values.idNumber)) {
  //     errors.idNumber = 'Invalid license number';
  // }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!re.test(values.phone)) {
    errors.phone = 'Invalid phone format';
  }

  return errors;
};

const loadScript = (url, callback) => {
  let script = document.createElement('script');
  script.type = 'text/javascript';

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

function MerchantProfilePersonalDetailsPage(props) {
  const [dob, setDob] = useState();
  const [ExistingMerchantDetails, setExistingMerchantDetails] = useState();
  const [showGoogleAddressBar, setShowGoogleAddressBar] = useState(false);
  const [value, setValue] = useState(null);

  const [existingAddress, setExistingAddress] = useState();

  const [requirementStatusValue, setRequirementStatusValue] = useState();

  const [addressData, setAddressData] = useState({
    street: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
  });

  const [addressCoordinates, setAddressCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMerchantDetails();
    return {};
  }, []);

  // Getting merchant data
  const getMerchantDetails = () => {
    MerchantService.getMerchantDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setRequirementStatusValue(data?.requirementStatus);
          let address =
            data?.representative?.address?.street +
            ' ' +
            data?.representative?.address?.suburb +
            ' ' +
            data?.representative?.address?.state +
            ' ' +
            data?.representative?.address?.country +
            ' ' +
            data?.representative?.address?.postcode;
          setExistingAddress(address);
          loadExistingAddress(address);
          formik.setFieldValue('firstName', data?.representative?.firstName);
          formik.setFieldValue('lastName', data.representative?.lastName);
          formik.setFieldValue('email', data.representative?.email);
          formik.setFieldValue('phone', data.representative?.phone);
          formik.setFieldValue('idNumber', data.representative?.idNumber);
          setDob(moment(data?.representative?.dateOfBirth).format('yyyy-MM-DD'));
          setExistingMerchantDetails(response['data']);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        console.log('error filed errors ', errorResponseFieldErrors);
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Updating personal details data
  const onSubmit = values => {
    toast.dismiss();
    setShowLoader(true);

    MerchantOnboardingSchema.merchantOnboardingData.representative = {
      id: ExistingMerchantDetails.representative.id !== '' ? ExistingMerchantDetails.representative.id : null,
      isOwner:
        ExistingMerchantDetails.representative.isOwner !== '' ? ExistingMerchantDetails.representative.isOwner : null,
      role: ExistingMerchantDetails.representative.role !== '' ? ExistingMerchantDetails.representative.role : null,
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      email: '',
      phone: '',
      address: {
        latitude: 0,
        longitude: 0,
        street: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
      },
    };
    MerchantOnboardingSchema.merchantOnboardingData.company = null;
    MerchantOnboardingSchema.merchantOnboardingData.payout = null;
    MerchantOnboardingSchema.merchantOnboardingData.bankAccount = null;
    MerchantOnboardingSchema.merchantOnboardingData.representative.firstName = values.firstName;
    MerchantOnboardingSchema.merchantOnboardingData.representative.lastName = values.lastName;
    MerchantOnboardingSchema.merchantOnboardingData.representative.phone = values.phone;
    MerchantOnboardingSchema.merchantOnboardingData.representative.dateOfBirth = dob;
    MerchantOnboardingSchema.merchantOnboardingData.representative.email = values.email;

    MerchantOnboardingSchema.merchantOnboardingData.representative.idNumber = values.idNumber;
    MerchantOnboardingSchema.merchantOnboardingData.representative.idNumberProvided = true;

    MerchantOnboardingSchema.merchantOnboardingData.representative.address.street =
      addressData.street !== '' ? addressData.street : ExistingMerchantDetails.representative.address.street;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.suburb =
      addressData.suburb !== '' ? addressData.suburb : ExistingMerchantDetails.representative.address.suburb;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.state =
      addressData.state !== '' ? addressData.state : ExistingMerchantDetails.representative.address.state;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.country =
      addressData.country !== '' ? addressData.country : ExistingMerchantDetails.representative.address.country;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.postcode =
      addressData.postcode !== '' ? addressData.postcode : ExistingMerchantDetails.representative.address.postcode;

    MerchantOnboardingSchema.merchantOnboardingData.representative.address.latitude = addressCoordinates.latitude;
    MerchantOnboardingSchema.merchantOnboardingData.representative.address.longitude = addressCoordinates.longitude;

    merchantService
      .updateMerchantDetails(MerchantOnboardingSchema.merchantOnboardingData)
      .then(response => {
        if (response) {
          setShowLoader(false);
          Toast('Your changes have been saved successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const getAddress = data => {
    setValue(data);

    getLatitudeLongitude(data);

    let addressData = LocationService.getLocationAddress(data);

    if (addressData.street === undefined) {
      Toast('Please add street address', 'error');
      return;
    }

    if (addressData.city === undefined) {
      Toast('Please add city address', 'error');
      return;
    }

    if (addressData.state === undefined) {
      Toast('Please add state address', 'error');
      return;
    }

    if (addressData.country === undefined) {
      Toast('Please add country address', 'error');
      return;
    }

    if (addressData.postCode === undefined) {
      Toast('Please add post code address', 'error');
      return;
    }

    setAddressData({
      street: addressData.street,
      suburb: addressData.city,
      state: addressData.state,
      country: addressData.country,
      postcode: addressData.postCode,
    });
  };

  const loadExistingAddress = data => {
    geocodeByAddress(data)
      .then(results => {
        if (results) {
          getLatitudeLongitude(results[0]);

          let addressData = LocationService.getLocationAddress(results);

          if (addressData.street === undefined) {
            Toast('Please add street address', 'error');
            return;
          }

          if (addressData.city === undefined) {
            Toast('Please add city address', 'error');
            return;
          }

          if (addressData.state === undefined) {
            Toast('Please add state address', 'error');
            return;
          }

          if (addressData.country === undefined) {
            Toast('Please add country address', 'error');
            return;
          }

          if (addressData.postCode === undefined) {
            Toast('Please add post code address', 'error');
            return;
          }
          setAddressData({
            street: addressData.street,
            suburb: addressData.city,
            state: addressData.state,
            country: addressData.country,
            postcode: addressData.postCode,
          });
        }
      })
      .catch(error => console.error(error));
  };

  const getLatitudeLongitude = data => {
    getLatLng(data).then(({ lat, lng }) => {
      setAddressCoordinates({
        latitude: lat,
        longitude: lng,
      });
    });
  };

  const updateAddressBtnHandler = () => {
    setShowGoogleAddressBar(!showGoogleAddressBar);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  return (
    <div>
      <div className="p-0 m-0 p-4 card border-0">
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <span className="font-sofia-pro-bold font-24 color-black-pearl">Personal details</span>
            <span className="font-sofia-pro-light font-14 color-black-pearl">Update your personal information</span>
          </div>
          <div className="py-2 px-3 d-flex align-items-center bg-cool-green-light border-radius-5">
            <p
              className={
                requirementStatusValue === 'Complete'
                  ? 'p-0 m-0 mr-2 merchant-profile-active'
                  : 'p-0 m-0 mr-2 merchant-profile-in-active'
              }
            ></p>
            <span
              className={
                requirementStatusValue === 'Complete'
                  ? 'font-14 font-sofia-pro-regular color-cool-green'
                  : 'font-14 font-sofia-pro-regular color-reddish'
              }
            >
              {requirementStatusValue === 'Complete' ? 'Active' : 'Pending'}
            </span>
          </div>
        </div>

        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">First name *</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
              name="firstName"
              placeholder="Eg: your first name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />

            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Last name *</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
              name="lastName"
              placeholder="Eg: your last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />

            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">License Number *</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
              name="idNumber"
              placeholder="Eg: your license number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.idNumber}
            />

            {formik.touched.idNumber && formik.errors.idNumber ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.idNumber}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>

            {existingAddress === undefined && <SearchLocationInput loadAddress={getAddress} data={existingAddress} />}
            {existingAddress !== undefined && <SearchLocationInput loadAddress={getAddress} data={existingAddress} />}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Date of birth *</label>
            <input
              type="date"
              className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
              name="email"
              placeholder="13-02-1990"
              value={dob}
              onChange={event => setDob(event.target.value)}
            />
          </div>

          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Phone number *</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
              name="phone"
              placeholder="Eg: 1234567890"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />

            {formik.touched.phone && formik.errors.phone ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
              </div>
            ) : null}
          </div>

          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Email address *</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl personal-details__placeholder border-light-periwinkle"
              name="email"
              placeholder="Eg: example@domain.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />

            {formik.touched.email && formik.errors.email ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
              </div>
            ) : null}
          </div>

          <Button
            classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
            children=" Save"
            showLoader={showLoader}
            onClick={() => onSubmit}
          />
        </form>
      </div>
    </div>
  );
}

export default MerchantProfilePersonalDetailsPage;
