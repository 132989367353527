/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import * as RegexPattern from '../../../../utils/RegexPatterns';
import merchantService from '../../../../services/MerchantService';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import MerchantService from '../../../../services/MerchantService';
import LocationService from '../../../../services/LocationService';
import './BusinessDetails.scss';
import CommonService from '../../../../services/CommonService';
import SearchLocationInput from '../../../../common/GooglePlaces/PlacesSearch';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import { ReactComponent as ProfilePic } from '../../../../assets/images/common/user-profile.svg';

const initialValues = {
  legalName: '',
  webSite: '',
  taxId: '',
  taxIdProvided: true,
  phone: '',
  classification: '',
  tradingName: '',
};

const validate = values => {
  let errors = {};

  let re = RegexPattern.MobileRegexValue;

  if (!values.legalName) {
    errors.legalName = 'Required';
  }

  if (!values.tradingName) {
    errors.tradingName = 'Required';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!re.test(values.phone)) {
    errors.phone = 'Invalid phone format';
  }

  return errors;
};

function MerchantProfileBusinessDetailsPage(props) {
  const [typeOfBusiness, setTypeOfBusiness] = useState('5812');

  const [value, setValue] = useState(null);

  const [logoFileData, setLogoFileData] = useState();
  const [logoFileImage, setLogoFileImage] = useState();

  const [checkLogoIsUploaded, setCheckLogoIsUploaded] = useState(false);

  const [companyDeatils, setCompanyDetails] = useState();

  const [merchantData, setMerchantData] = useState();

  const [showGoogleAddressBar, setShowGoogleAddressBar] = useState(false);

  const [checkUserHaveMerchantAccount, setCheckUserHaveMerchantAccount] = useState(false);

  const [existingAddress, setExistingAddress] = useState();

  const [companyCoordinates, setCompanyCoordinates] = useState({
    latitude: 0,
    longitude: 0,
  });

  const [showLoader, setShowLoader] = useState(false);

  const [companyData, setCompanyData] = useState();

  const handleChangeBusiness = e => {
    setTypeOfBusiness(e.target.value);
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    getCompanyDetails();
    window.scrollTo(0, 0);
  }, []);

  // Load existing address
  const loadExistingAddress = data => {
    geocodeByAddress(data)
      .then(results => {
        if (results) {
          getLatitudeLongitude(results[0]);

          let addressData = LocationService.getLocationAddress(results[0]);

          if (addressData.street === undefined) {
            Toast('Please add street address', 'error');
            return;
          }

          if (addressData.city === undefined) {
            Toast('Please add city address', 'error');
            return;
          }

          if (addressData.state === undefined) {
            Toast('Please add state address', 'error');
            return;
          }

          if (addressData.country === undefined) {
            Toast('Please add country address', 'error');
            return;
          }

          if (addressData.postCode === undefined) {
            Toast('Please add post code address', 'error');
            return;
          }

          merchantData.company.address.street = addressData.street;
          merchantData.company.address.suburb = addressData.city;
          merchantData.company.address.state = addressData.state;
          merchantData.company.address.country = addressData.country;
          merchantData.company.address.postcode = addressData.postCode;
          merchantData.company.address.country = addressData.country;
        }
      })
      .catch(error => console.error(error));
  };

  // Get company details
  const getCompanyDetails = () => {
    toast.dismiss();
    setShowLoader(true);
    merchantService
      .getCompanyDetails()
      .then(response => {
        if (response) {
          setShowLoader(false);
          let data = response['data'];
          setCompanyDetails(data);
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Update company details
  const updateCompanyDetails = data => {
    toast.dismiss();
    setShowLoader(true);
    merchantService
      .updateCompanyDetails(data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          getCompanyDetails();
          clearLogoImage();
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Logo file change handler
  const logoChangeHandler = event => {
    setCheckLogoIsUploaded(true);
    event.preventDefault();
    let logoImageUrl = URL.createObjectURL(event.target.files[0]);
    setLogoFileImage(logoImageUrl);
    let logoBase64 = '';
    let fileName = event.target.files[0].name;

    let checkValidImage = CommonService.validateImage(fileName);

    if (checkValidImage) {
      getBase64(event.target.files[0], result => {
        logoBase64 = result.split(',')[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData(logoBase64);
      });
    } else {
      Toast('Only jpg/jpeg and png files are allowed!', 'warning');
    }
  };

  // Clear logo data
  const clearLogoImage = () => {
    setCheckLogoIsUploaded(false);
    setLogoFileData();
    setLogoFileImage();
  };

  // Updating details
  const onSubmit = values => {
    toast.dismiss();
    setShowLoader(true);
    merchantData.company.legalName = values.legalName;
    merchantData.company.webSite = 'https://' + values.webSite;
    merchantData.company.taxIdProvided = values.taxIdProvided;
    merchantData.company.phone = values.phone;
    merchantData.company.classification = typeOfBusiness;
    merchantData.payout = null;
    merchantData.representative = null;
    merchantData.bankAccount = null;

    let companyData = {
      name: values.tradingName,
      logoFileBase64: logoFileData,
      logoFileName: 'Logo.png',
      socialMedias: null,
    };

    companyData.logoFileBase64 = logoFileData;

    merchantData.company.address.latitude =
      companyCoordinates.latitude !== 0 ? companyCoordinates.latitude : merchantData?.company?.address?.latitude;
    merchantData.company.address.longitude =
      companyCoordinates.longitude !== 0 ? companyCoordinates.longitude : merchantData?.company?.address?.longitude;

    updateMerchant(merchantData);
    updateCompanyDetails(companyData);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  // Get current user details
  const getCurrentUser = () => {
    merchantService
      .getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'].roles;
          data.forEach((data, index) => {
            if (data === 'CompanyAdmin') {
              setCheckUserHaveMerchantAccount(true);
              getMerchantDetails();
              getCompanyDetails();
            }
          });
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Get merchant details
  const getMerchantDetails = () => {
    merchantService
      .getMerchantDetails()
      .then(response => {
        if (response) {
          let merchantData = response['data'];
          setMerchantData(merchantData);

          let Website = merchantData?.company?.webSite !== null ? merchantData?.company?.webSite.split('//') : '';

          formik.setFieldValue('legalName', merchantData?.company?.legalName);
          formik.setFieldValue('webSite', Website[1]);
          formik.setFieldValue('phone', merchantData?.company?.phone);
          formik.setFieldValue('taxId', merchantData?.company?.taxId);
          setTypeOfBusiness(merchantData?.company?.classification);

          if (merchantData?.company?.address !== null) {
            let address =
              merchantData?.company?.address?.street +
              ' ' +
              merchantData?.company?.address?.suburb +
              ' ' +
              merchantData?.company?.address?.state +
              ' ' +
              merchantData?.company?.address?.country +
              ' ' +
              merchantData?.company?.address?.postcode;
            setExistingAddress(address);
            loadExistingAddress(address);
          }
        }
      })
      .catch(error => {
        console.log('error ', error);
        if (error) {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                Toast(msg.message, 'error');
              });
            }
          }
        }
      });
  };

  // Get address
  const getAddress = data => {
    setValue(data);

    getLatitudeLongitude(data);

    let addressData = LocationService.getLocationAddress(data);

    if (addressData.street === undefined) {
      Toast('Please add street address', 'error');
      return;
    }

    if (addressData.city === undefined) {
      Toast('Please add city address', 'error');
      return;
    }

    if (addressData.state === undefined) {
      Toast('Please add state address', 'error');
      return;
    }

    if (addressData.country === undefined) {
      Toast('Please add country address', 'error');
      return;
    }

    if (addressData.postCode === undefined) {
      Toast('Please add post code address', 'error');
      return;
    }
    merchantData.company.address.street = addressData.street;
    merchantData.company.address.suburb = addressData.city;
    merchantData.company.address.state = addressData.state;
    merchantData.company.address.country = addressData.country;
    merchantData.company.address.postcode = addressData.postCode;
    merchantData.company.address.country = addressData.country;
  };

  // Getting latitude and longitude values
  const getLatitudeLongitude = data => {
    getLatLng(data).then(({ lat, lng }) => {
      setCompanyCoordinates({
        latitude: lat,
        longitude: lng,
      });
    });
  };

  // Updating merchant details
  const updateMerchant = data => {
    toast.dismiss();
    setShowLoader(true);
    delete data?.company?.logoFileBase64;

    MerchantService.updateMerchantDetails(data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          let merchantData = response['data'];
          Toast('Your changes have been saved successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  // Converting to base64
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <div>
      <div className="p-0 m-0 p-4 card border-0">
        <span className="font-sofia-pro-semi-bold font-24 color-black-pearl">Edit business details</span>
        <span className="font-sofia-pro-light font-14 color-black-pearl">Update your business information</span>
        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div>
            <label className="font-16 font-sofia-pro-regular color-nero">Logo</label>
            <div className="d-flex align-items-center">
              {logoFileImage === undefined && companyDeatils?.logoPath !== null && (
                <img className="rounded-circle" src={companyDeatils?.logoPath + '?h=75'} height="75px" width="75px" />
              )}
              {logoFileImage === undefined && companyDeatils?.logoPath === null && <ProfilePic />}
              {logoFileImage !== undefined && (
                <img src={logoFileImage} height="75px" width="75px" className="rounded-circle" />
              )}
              <div className="d-flex flex-column">
                <input
                  type="file"
                  className="ml-3 merchant-profile-custom-file-input"
                  onChange={logoChangeHandler}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
              </div>
            </div>
          </div>

          <div className="p-0 b-0 mt-2 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Legal Name *</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle"
              name="legalName"
              placeholder="Eg: Restaurant"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.legalName}
            />

            {formik.touched.legalName && formik.errors.legalName ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.legalName}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 mt-2 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Trading Name *</label>
            <input
              type="text"
              className="form-control shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle"
              name="tradingName"
              placeholder="Eg: Coffee Store"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tradingName}
            />
            {formik.touched.tradingName && formik.errors.tradingName ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.tradingName}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Address *</label>
            {existingAddress === undefined && <SearchLocationInput loadAddress={getAddress} data={existingAddress} />}
            {existingAddress !== undefined && <SearchLocationInput loadAddress={getAddress} data={existingAddress} />}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Type of business *</label>
            <select
              className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl business-details__placeholder border-light-periwinkle"
              name="typeOfBusiness"
              placeholder="Eg: Restaurant"
              value={typeOfBusiness}
              onChange={handleChangeBusiness}
            >
              <option value="5812">Eating Places, Restaurants</option>
              <option value="5814">Fast Food Restaurants</option>
              <option value="5499">Miscellaneous Food Stores - Convenience Stores and Specialty Markets </option>
              <option value="5921">Package Stores-Beer, Wine, and Liquor</option>
              <option value="5999">Others</option>
            </select>
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Phone *</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular color-black-pearl business-details__placeholder border-light-periwinkle"
              name="phone"
              placeholder="Eg: 123456789"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.errors.phone ? (
              <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Website (if any)</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular color-black-pearl business-details__placeholder border-light-periwinkle"
              name="webSite"
              placeholder="www.appreci.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.webSite}
            />
          </div>
          <Button
            classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
            children=" Save"
            showLoader={showLoader}
            onClick={() => onSubmit}
          />
        </form>
      </div>
    </div>
  );
}

export default MerchantProfileBusinessDetailsPage;
