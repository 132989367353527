/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { Modal } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardExpiryElement,
  CardNumberElement,
  CardCvcElement,
  useStripe,
  useElements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import './Payment.scss';
import paymentService from '../../../../../services/PaymentService';
import Toast from '../../../../../services/ToasterService';
import IconMiscCard from '../../../../../assets/images/common/icons-misc-card.png';
import { ReactComponent as DeleteIcon } from '../../../../../assets/images/common/icons-delete-red.svg';
import { ReactComponent as Close } from '../../../../../assets/images/dashboard/icons/icons-close-white.svg';
import * as RegexPatterns from '../../../../../utils/RegexPatterns';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PaymentPage(props) {
  const [savedCardsList, setSavedCardsList] = useState([]);

  const [defaultCardList, setDefaultCardList] = useState([]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const showDeleteModalHandler = () => setShowDeleteModal(true);

  const hideDeleteModalHandler = () => setShowDeleteModal(false);

  const [selectedCardId, setSelectedCardId] = useState();

  let checkCardRemoved = false;

  useEffect(() => {
    window.scrollTo(0, 0);
    loadExistingCardsList();

    return function cleanup() {
      setSavedCardsList([]);
    };
  }, []);

  // Loading existing cards data
  const loadExistingCardsList = () => {
    paymentService
      .getListOfCards()
      .then(response => {
        if (response) {
          let data = response['data'];
          setSavedCardsList(data);
          // Filtering default card from cards list
          let defaultCardData = data.filter(data => {
            return data.isDefault === true;
          });
          setDefaultCardList(defaultCardData);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Selecting card id
  const deleteCard = id => {
    showDeleteModalHandler();
    setSelectedCardId(id);
  };

  // Making card as default card
  const setAsDefaultBtnHandler = index => {
    let data = savedCardsList[index];

    let cardData = {
      id: data.id,
      expiresOn: data.expiresOn,
      setAsDefault: true,
    };

    paymentService
      .updateCard(data.id, cardData)
      .then(response => {
        if (response.status === 200) {
          loadExistingCardsList();
          Toast('Your default card is changed successfully', 'success');
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Removing card
  const cardDeleteBtnHandler = () => {
    if (checkCardRemoved === false) {
      checkCardRemoved = true;
      let cardId = selectedCardId;

      paymentService
        .deleteCard(cardId)
        .then(response => {
          loadExistingCardsList();
          Toast('Card removed successfully', 'success');

          hideDeleteModalHandler();

          setTimeout(() => {
            checkCardRemoved = false;
          }, 2000);
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                console.log('error global response message ', msg);
                Toast(msg.message, 'error');
              });
            }
          }
        });
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  return (
    <div>
      <div className="p-0 m-0 p-4 card border-0">
        <span className="font-24 font-sofia-pro-bold color-black-pearl">Your payment Options</span>
        <span className="font-14 font-sofia-pro-light color-black-pearl">
          An overview of your payment methods and settings
        </span>
        <div className="mt-4">
          <span className="font-sofia-pro-bold font-14 color-gunmetal">DEFAULT PAYMENT METHOD</span>
          <hr />
        </div>
        {defaultCardList.length !== 0 &&
          defaultCardList.map((data, index) => {
            return (
              <div className="card border-0 h-170 payment-card">
                <div className="p-0 m-0 p-3 card-body  d-flex flex-column justify-content-between" key={data.id}>
                  <div className="d-flex flex-column">
                    <span className="font-sofia-pro-bold font-14 color-black-pearl">Card number</span>
                    <span className="font-sofia-pro-regular font-14 color-black-pearl">
                      **** **** **** {data.last4}
                      <span className="pl-1">
                        <Moment format="MM/YY">{data.expiresOn}</Moment>
                      </span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <button
                      className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-40  btn  bg-transparent border-primary-color font-16 font-sofia-pro-semi-bold color-primary"
                      onClick={deleteCard.bind(this, data.id)}
                    >
                      Delete Card
                    </button>
                    <span className="my-2 shadow-none d-flex justify-content-center align-items-center w-180   bg-transparent font-16 font-sofia-pro-semi-bold color-black-pearl">
                      Default card
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        {defaultCardList.length === 0 && (
          <p className="font-sofia-pro-semi-bold font-14 color-black-pearl text-center">No data found</p>
        )}
        <div className="mt-4">
          <span className="font-sofia-pro-bold font-14 color-gunmetal">YOUR SAVED CREDIT AND DEBIT CARDS</span>
          <hr />
        </div>
        <div className="mb-2 d-flex flex-column">
          {savedCardsList.length !== 0 &&
            savedCardsList.map((data, index) => {
              return (
                <div className="d-flex justify-content-between align-items-baseline" key={index}>
                  <label className="font-14 font-sofia-pro-light color-black-pearl ">
                    <span className="pl-2">
                      {' '}
                      VISA •••• •••• •••• {data.last4} |
                      <span className="pl-1">
                        <Moment format="MM/YY">{data.expiresOn}</Moment>
                      </span>
                    </span>
                    <img className="pl-1" src={IconMiscCard} />
                  </label>
                  <div className="d-flex">
                    <button
                      type="button"
                      className="mr-2 p-2 btn btn-sm border-0  shadow-none"
                      onClick={setAsDefaultBtnHandler.bind(this, index)}
                    >
                      <span className="font-14 font-sofia-pro-regular color-primary">Set as default</span>
                    </button>
                    <button
                      type="button"
                      className="p-2 btn btn-sm color-reddish  shadow-none d-flex align-items-center"
                      onClick={deleteCard.bind(this, data.id)}
                    >
                      <DeleteIcon />
                      <span className="font-14 font-sofia-pro-regular color-reddish">Delete</span>
                    </button>
                  </div>
                </div>
              );
            })}
          {savedCardsList.length === 0 && (
            <p className="font-sofia-pro-semi-bold font-14 color-black-pearl text-center">No data found</p>
          )}
        </div>
        <div className="mt-4">
          <span className="font-sofia-pro-bold font-14 color-gunmetal">ADD A NEW PAYMENT METHOD</span>
          <hr />
        </div>
        <div>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <AddCardForm elements={elements} stripe={stripe} loadExistingList={loadExistingCardsList} />
              )}
            </ElementsConsumer>
          </Elements>
        </div>

        <Modal
          className="pt-4 border-0
         modal-background-shadow payment-modal"
          size="md"
          show={showDeleteModal}
          onHide={hideDeleteModalHandler}
          animation={false}
        >
          <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Delete card?</span>
            <button type="button" className="close modal-close-icon" onClick={hideDeleteModalHandler}>
              <Close />
            </button>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
            <p className="font-16 font-sofia-pro-light color-nobel">
              Are you sure you want to delete this payment method?
            </p>
            <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
              <button
                className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                onClick={hideDeleteModalHandler}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                onClick={cardDeleteBtnHandler}
              >
                Delete
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

const AddCardForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardElemetError, setCardElementError] = useState('');
  const [cardExpiryError, setCardExpiryError] = useState('');
  const [cardCVCError, setCardCVCError] = useState('');

  const [disableBtn, setDisableBtn] = useState(false);
  const initialValues = {
    postcode: null,
  };

  let checkNewCardAdded = false;

  const validate = values => {
    const errors = {};

    return errors;
  };

  // Adding new card
  const saveCardDetails = async event => {
    if (checkNewCardAdded === false) {
      checkNewCardAdded = true;

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        Toast('Please add card details', 'error');
        return;
      }

      // Block native form submission.
      event.preventDefault();

      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardNumberElement);
      const cardDateElement = elements.getElement(CardExpiryElement);
      const cardCVVElement = elements.getElement(CardCvcElement);

      // Use your card Element with other Stripe.js APIs
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        Toast('Please add the valid card details', 'error');
      } else if (formik.values.postcode === null) {
        Toast('Please add a postcode', 'error');
      } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
        Toast('Please add a valid postcode', 'error');
      } else {
        let tokenId = token.id;
        let data = {
          token: tokenId,
          setAsDefault: false,
          region: 'AU',
          postcode: formik.values.postcode,
        };

        paymentService
          .addNewCard(data)
          .then(response => {
            if (response) {
              Toast('New card added successfully', 'success');

              elements.getElement(CardNumberElement).clear();
              elements.getElement(CardExpiryElement).clear();
              elements.getElement(CardCvcElement).clear();

              setTimeout(() => {
                props.loadExistingList();
                formik.values.postcode = '';
                elements.checkNewCardAdded = false;
              }, 1000);
            }
          })
          .catch(error => {
            if (error) {
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    console.log('error global response message ', msg);
                    Toast(msg.message, 'error');
                  });
                }
              }
            }
          });
      }
    }
  };

  const handleChange = e => {
    if (e.elementType === 'cardNumber') {
      setCardElementError(e?.error?.message);
    } else if (e.elementType === 'cardNumber' && e.complete === true) {
      setCardElementError('');
    }

    if (e.elementType === 'cardExpiry') {
      setCardExpiryError(e?.error?.message);
    } else if (e.elementType === 'cardExpiry' && e.complete === true) {
      setCardExpiryError('');
    }

    if (e.elementType === 'cardCvc') {
      setCardCVCError(e?.error?.message);
    } else if (e.elementType === 'cardCvc' && e.complete === true) {
      setCardCVCError('');
    }
  };

  const handleDisable = () => {
    if (cardElemetError !== '' || cardExpiryError !== '' || cardCVCError !== '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validate,
  });

  return (
    <div>
      <div className="mt-3">
        <span className="font-16 color-charcoal font-sofia-pro-regular">Card Number</span>
        <CardNumberElement className="payment-option__card" onChange={handleChange} />
        <span className="font-12 color-charcoal font-sofia-pro-regular">{cardElemetError}</span>
      </div>
      <div className="p-0 m-0 row mt-3">
        <div className="pl-0 col-sm-12 col-md-6 col-lg-6">
          <div className="">
            <span className="font-16 color-charcoal font-sofia-pro-regular">Expiry Date</span>
            <CardExpiryElement className="payment-option__expiry_element" onChange={handleChange} />
            <span className="font-12 color-charcoal font-sofia-pro-regular">{cardExpiryError}</span>
          </div>
        </div>
        <div className="pl-0 col-sm-12 col-md-6 col-lg-6">
          <div className="">
            <div>
              <span className="font-16 color-charcoal font-sofia-pro-regular">CVV</span>
              <CardCvcElement className="payment-option__cvv" onChange={handleChange} />
              <span className="font-12 color-charcoal font-sofia-pro-regular">{cardCVCError}</span>
            </div>
          </div>
        </div>
        <div className="pl-0 col-sm-12 col-md-6 col-lg-6 my-3">
          <span className="font-16 color-charcoal font-sofia-pro-regular">Postcode</span>
          <input
            className="payment-option__cvv w-100"
            placeholder="Eg: 2122"
            name="postcode"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postcode}
          />
          {formik.errors.postcode ? (
            <span className="font-12 color-charcoal font-sofia-pro-regular">{formik.errors.postcode}</span>
          ) : null}
        </div>
      </div>

      <div className="pl-0 col-sm-12 col-md-6 col-lg-4 d-flex align-items-end my-3">
        <div>
          <button
            className="w-150 h-60 btn bg-transparent border-primary-color color-primary font-sofia-pro-semi-bold font-16 outline-none"
            type="button"
            disabled={disableBtn}
            onClick={saveCardDetails}
          >
            Add card
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
