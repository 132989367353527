import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as FolderIcon } from '../../assets/images/common/icons-folder.svg';
import SampleCSVFormatImage from '../../assets/images/common/sample_csv_customers.png';
import CSV from '../../common/csv/employees.csv';
import { ReactComponent as Close } from '../../assets/images/dashboard/icons/icons-close-white.svg';
import SendThanksService from '../../services/SendThanksService';
import RecipientsInput from './RecipientsInput';

function loopFieldErrors(data) {
  for (var i in data) {
    var itemSubArray = data[i];
    for (var j in itemSubArray) {
      toast.error(itemSubArray[j]);
    }
  }
}

//TODO: This was ecxtracted from RecipientInformation.js.
// Find other places where this code is used and replace it with the component.
export default function RecipientsForm({ tags, onChange, csvEnabled, type }) {
  const [showCsvUploadForm, setShowCsvUploadForm] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [showExampleModal, setShowExampleModal] = useState(false);

  // Getting uploaded media
  const getusersListFile = e => {
    setFileName(e.target.files[0].name);

    let csvFile = e.target.files[0];

    var formData = new FormData();
    formData.append('csvFile', csvFile);

    SendThanksService.extractRecipientsFromCSV(formData)
      .then(response => {
        if (response) {
          let data = response['data'];
          console.log('File processed', data);
          onChange(data);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        }
      });
  };

  return (
    <div>
      {!showCsvUploadForm ? (
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <label className={type ?"font-sofia-pro-regular color-charcoal font-16 label-coffee": "font-sofia-pro-regular color-charcoal font-16 label-coffee"}>
                {type ? 'Email/phone' : 'Email or mobile number or username'}
              </label>
              <p className="d-block font-sofia-pro-regular color-charcoal font-10">
                {type ? null: "(Username with prefix @, &nbsp;Press spacebar/enter for adding multiple recipients)"}
              </p>
            </div>
            <div>
              {csvEnabled && (
                <p
                  className="d-block font-sofia-pro-regular color-primary text-underline font-16 cursor-pointer"
                  onClick={() => setShowCsvUploadForm(true)}
                >
                  <u>Upload CSV file</u>
                </p>
              )}
            </div>
          </div>
          <div className="position-relative">
            <RecipientsInput tags={tags} onChange={onChange} type={type}></RecipientsInput>
          </div>
        </div>
      ) : (
        <div className="mt-2">
          <div className="d-flex justify-content-between">
            <div>
              <label className="p-0 m-0 font-sofia-pro-regular color-charcoal font-16">Upload file</label>
            </div>
            <div>
              <p
                className="d-block font-sofia-pro-regular color-primary text-underline font-16  cursor-pointer"
                onClick={() => setShowCsvUploadForm(false)}
              >
                <u>Enter details</u>
              </p>
            </div>
          </div>
          <div className="p-0 m-0 card w-100 border-0">
            <label htmlFor="file-input">
              <div className="invite__employee-upload-style">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <FolderIcon />
                  {fileName === null && (
                    <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                      <span className="font-16 font-sofia-pro-regular color-primary">
                        <u>Choose file </u>
                      </span>
                    </p>
                  )}
                  {fileName !== null && (
                    <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                      <u>{fileName}</u>
                    </p>
                  )}
                </div>
                <input
                  id="file-input"
                  type="file"
                  accept=".csv"
                  onChange={getusersListFile}
                  onClick={event => {
                    event.target.value = null;
                  }}
                />
              </div>
            </label>
            <div>
              <p className="p-0 m-0 font-sofia-pro-regular color-charcoal font-14">
                NOTE: In the CSV, you can include recipient's email or phone or username. Please note that the first row
                will be the header and will not be considered as a recipient detail.
                <br />
              </p>
              <a href={CSV} download="cvs customer">
                <span
                  className="p-0 m-0 font-sofia-pro-regular cursor-pointer color-primary font-14"
                  onClick={() => setShowExampleModal(true)}
                >
                  <u>Download CSV Template</u>
                </span>
              </a>
            </div>
          </div>
        </div>
      )}

      <Modal
        className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
        show={showExampleModal}
        onHide={() => setShowExampleModal(false)}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 border-0">
          <button
            type="button"
            className="close switch-to-account-modal__close__icon"
            onClick={() => setShowExampleModal(false)}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-1 pb-5 border-0 text-center">
          <h4 className="p-2 font-sofia-pro-regular color-black-pearl">Sample CSV format</h4>
          <div className="mt-2">
            <img src={SampleCSVFormatImage} className="mw-100" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
