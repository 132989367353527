/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory, Link } from 'react-router-dom';
import 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import * as ReactBootstrap from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import OrganisationSidebar from '../organisationsidebar/organisationsidebar.js';
import OrganisationHeader from '../organisationheader/organisationHeader.js';
// import EditUserPage from './EditUser.js';
import EditIcon from './EditIcon.js'
import EditUserPage from './EditUser.js';
import Button from '../../../common/Button/Button.js';
import Toast from '../../../services/ToasterService.js';
import OrganisationService from '../../../services/OrganisationService.js';
import './Milestones.scss';
import { ReactComponent as DeleteClose } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/common/icons-misc-search.svg';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Close } from '../../../assets/images/common/close-modal.svg';
import SampleCSVFormatImage from '../../../assets/images/common/sample_csv_staff.png';
import ErrorImage from '../../../assets/images/error-icons/icons-error-info.png';
import CSV from '../../../common/csv/staff_csv.xlsx';
import DeleteIcon from './DeleteIcon.js';
import {ReactComponent as DeleteUser} from '../../../assets/images/common/delete-user.svg'
import OrganisationHeaderMobile from '../organisationheader/organisationHeaderMobile.js';

const initialValues = {
  email: null,
};

const OrganisationMilestonesPage = () => {
  const [show, setShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editModalId, setEditModalId] = useState();

  const history = useHistory();

  const [usersList, setUsersList] = useState([]);

  let [existingUsersList, setExistingUsersList] = useState([]);

  const [checkIsDataLoadedOrNot, setCheckIsDataLoadedOrNot] = useState(false);

  const [fileName, setFileName] = useState(null);

  const [checkFileList, setCheckFileList] = useState(false);

  const [fileData, setFileData] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedUserIndex, setSelectedUserIndex] = useState();
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState();


  const [searchText, setSearchText] = useState('');

  const [showLoader, setShowLoader] = useState(false);

  const [disableResendInviteBtn, setDisableResendInviteBtn] = useState(false);

  const [showSampleFormat, setShowSampleFormat] = useState(false);

  const [userIndex, setUserIndex] = useState();

  const [isEditHover, setIsEditHover] = useState([]);
  const [isDeleteHover, setIsDeleteHover] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [existingMilestones, setExistingMilestones] = useState([]);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleEditMouseEvent = (index, value) => {
    setIsEditHover(prevHoverState => {
      const newHoverState = [...prevHoverState];
      newHoverState[index] = value;
      return newHoverState;
    });
  };

  const handleDeleteMouseEvent = (index, value) => {
    setIsDeleteHover((prevHoverState) => {
      const newHoverState = [...prevHoverState];
      newHoverState[index] = value;
      return newHoverState;
    });
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleCloseSampleFormat = () => setShowSampleFormat(false);
  const handleShowSampleFormat = () => {
    setShowSampleFormat(true);
  };

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  const showDeleteModalHandler = () => setShowDeleteModal(true);
  const hideDeleteModalHandler = () => setShowDeleteModal(false);

  const getMilestonesData = () => {
    OrganisationService.getMilestones().then(response => {
      if (response) {
        let data = response['data'];
        setMilestones(data.reverse());
        setExistingMilestones(data);
      }
    });
  };

  useEffect(() => {
    getUsersList();
    getMilestonesData();
    return {};
  }, []);

  const getUsersList = () => {
    OrganisationService.getUsersList()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCheckIsDataLoadedOrNot(true);
          if (data.length === 0) {
            setUsersList([]);
          } else {
            setUsersList(data);
            setExistingUsersList(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const onSubmit = values => {
    toast.dismiss();

    if (values.email !== null || fileData !== null) {
      let data = {
        csvemail: null,
        csvfilebase64: null,
      };
      if (checkFileList === false) {
        data.csvemail = values.email;
        data.csvfilebase64 = null;
      } else if (checkFileList === true) {
        data.csvemail = null;
        data.csvfilebase64 = fileData;
      }

      OrganisationService.uploadEmployeesFileData(data)
        .then(response => {
          if (response) {
            Toast('Your invitation has been sent successfully', 'success');
            handleClose();
            // getUsersList();
          }
        })
        .catch(error => {
          if (error) {
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (error) {
              let errorResponse = error.response['data'];
              console.log('error response field errors ', errorResponse);
            }
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors?.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  Toast(msg.message, 'error');
                });
              }
            }
          }
        });
    } else {
      Toast('Either text csv or file csv must be submitted', 'error');
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const getusersListFile = e => {
    setFileName(e.target.files[0].name);
    let base64Data = '';
    getBase64(e.target.files[0], result => {
      base64Data = result.split(',')[1];
      setFileData(base64Data);
    });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const deleteMilestoneData = index => {
    showDeleteModalHandler();
    setSelectedMilestoneIndex(index);
  };

  const handleChange = value => {
    setSearchText(value);
    // searchEmployee(value);
    searchMilestone(value);
  };

  // const searchEmployee = value => {
  //   setUsersList(existingUsersList);

  //   const excludeColumns = ['lastLoginOn'];

  //   const lowercasedValue = value.toLowerCase().trim();
  //   if (lowercasedValue === '') setUsersList(existingUsersList);
  //   else {
  //     const filteredData = existingUsersList.filter(item => {
  //       return Object.keys(item).some(key =>
  //         item[key] !== null
  //           ? excludeColumns.includes(key)
  //             ? false
  //             : item[key].toString().toLowerCase().includes(lowercasedValue)
  //           : null
  //       );
  //     });
  //     setUsersList(filteredData);
  //   }
  // };

  const searchMilestone = value => {
    setMilestones(existingMilestones.reverse());

    const excludeColumns = ['lastLoginOn'];

    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === '') setMilestones(existingMilestones.reverse());
    else {
      const filteredData = existingMilestones.filter(item => {
        return Object.keys(item).some(key =>
          item[key] !== null
            ? excludeColumns.includes(key)
              ? false
              : item[key].toString().toLowerCase().includes(lowercasedValue)
            : null
        );
      });
      setMilestones(filteredData.reverse());
    }
  };

  const modalDeleteMilestoneBtnHandler = () => {
    let id = milestones[selectedMilestoneIndex].id;
    OrganisationService.deleteMilestone(id)
      .then(response => {
        if (response) {
          if (milestones[selectedMilestoneIndex].title !== null !== null) {
            Toast(
              `${milestones[selectedMilestoneIndex].title} has been removed from your milestones`,
              'success'
            );
          } else {
            Toast(`Milestone has been removed from your milestones`, 'success');
          }
          hideDeleteModalHandler();
          getMilestonesData();
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const resendInviteBtnHandler = index => {
    let data = usersList[index];
    OrganisationService.resendInvite(data)
      .then(response => {
        if (response) {
          setDisableResendInviteBtn(true);
          Toast(`We’ve resent the link to the provided email address.`, 'success');
          setTimeout(() => {
            setDisableResendInviteBtn(false);
          }, 50000);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const checkStoreListDataAvailaleOrNot = () => {
    return (
      <div className="milestone-mobile-background">
        <div className="p-0 m-0 row mt-4 my-2 mx-4 px-2 d-flex justify-content-between">
          <span className="font-sofia-pro-regular font-24 color-black-pearl">Milestone gifting</span>
          <div className="d-flex milestone-search-btn">
            <div className="input-group input-group-sm input-group-milestone">
              <div className="input-group-prepend employee-search_icon">
                <div className="pl-2">
                  <SearchIcon />
                </div>
              </div>
              <input
                type="text"
                placeholder="Search"
                className="mr-2 h-48 form-control form-input-placeholder border-0 bg-white employee-search__input shadow-none"
                value={searchText}
                onChange={e => handleChange(e.target.value)}
              />
            </div>
            <Link to={'/organisation/createMilestone'} style={{ textDecoration: 'none' }}>
              <button
                className="btn w-193 h-44 shadow-none bg-primary-color color-white font-sofia-pro-semi-bold font-16 create-milestone-btn"
                onClick={handleShow}
              >
                <span className="pl-2">Create a milestone</span>
              </button>
            </Link>
          </div>
        </div>
        {windowWidth <= 767 ? (
          milestones?.length > 0 &&
          milestones.map((milestone, index) => (
            <div className="cards-area" key={index}>
              <div className="card card-milestone">
                <div className="card-user-body">
                  <div className="user-card-headers">
                    <div className='d-flex'>
                      <div className='d-flex flex-column'>
                        <div className="font-sofia-pro-regular milestone-headings">Milestone type</div>
                        <div className="font-sofia-pro-regular milestone-headings">Title</div>
                        <div className="font-sofia-pro-regular milestone-headings">Employees</div>
                      </div>
                      <div className='d-flex flex-column align-items-start'>
                        <div className="font-sofia-pro-semi-bold card-title text-center" style={{marginBottom: '1.75rem'}}>{milestone?.type}</div>
                        <div className="font-sofia-pro-semi-bold card-title text-center" style={{marginBottom: '1.75rem'}}>{milestone?.title}</div>
                        <div className="font-sofia-pro-semi-bold card-title text-center" style={{marginBottom: '1.75rem'}}>{milestone?.employees?.length}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-end milestone-footer-btns mr-3">
                  {/* <EditIcon
                    key={index}
                    isHovered={isEditHover[index] || false}
                    index={index}
                    onMouseEnter={handleEditMouseEvent}
                    onMouseLeave={handleEditMouseEvent}
                  /> */}
                  <DeleteIcon 
                    key={index}
                    isHovered={isDeleteHover[index] || false}
                    index={index}
                    onMouseEnter={handleDeleteMouseEvent}
                    onMouseLeave={handleDeleteMouseEvent}
                    deleteMilestoneData={deleteMilestoneData}
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="my-2 row">
            <div class="col"></div>
            <div className="col-11 card dashboard-card__redeemthanks my-3 table-responsive border-0 ">
              <div className="row">
                <table className="table col-12" style={{ height: '100%' }}>
                  <thead>
                    <tr className="mx-2">
                      <td scope="col" className="font-sofia-pro-regular table_headings">
                        Milestone type
                        {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                      </td>
                      <td scope="col" className="font-sofia-pro-regular table_headings">
                        Title
                        {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                      </td>
                      {/* <td scope="col" className="font-14 font-sofia-pro table_headings">
                        Gift type
                      </td> */}
                      <td scope="col" className="font-sofia-pro-regular table_headings">
                        Employees
                        {/* <SortingIcon style={{cursor: 'pointer'}}/> */}
                      </td>
                      <td scope="col"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {milestones?.length > 0 &&
                      milestones.map((milestone, index) => {
                        return (
                        <tr key={index}>
                            <td className=" font-sofia-pro-regular font-14 color-black-pearl">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                  <div className="pl-2 d-flex flex-column">
                                    <span className="d-block">{milestone?.type}</span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="pt-3 font-sofia-pro-regular font-14 color-black-pearl">
                              <span className="pl-1">{milestone?.title}</span>
                            </td>
                            {/* <td className="pt-3 font-sofia-pro-regular font-14 color-black-pearl">
                              <span className="pl-1">{milestone?.gift}</span>
                            </td> */}
                            <td className="pt-3 font-sofia-pro-regular font-14 color-black-pearl" key={index}>
                              <span className="pl-1">{milestone?.employees?.length}</span>
                            </td>
                            <td>
                            <div className="d-flex">
                            <ReactTooltip place="bottom" effect="solid" />
                            {/* <EditIcon
                            key={index}
                            isHovered={isEditHover[index] || false}
                            index={index}
                            onMouseEnter={handleEditMouseEvent}
                            onMouseLeave={handleEditMouseEvent}
                          /> */}
                          <DeleteIcon 
                            key={index}
                            isHovered={isDeleteHover[index] || false}
                            index={index}
                            onMouseEnter={handleDeleteMouseEvent}
                            onMouseLeave={handleDeleteMouseEvent}
                            deleteMilestoneData={deleteMilestoneData}
                          />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col"></div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12  my-4 d-flex  flex-column ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      {windowWidth <= 767 ? (
        <>
          <div className="notification-bell">
            <OrganisationHeaderMobile role="admin" />
          </div>
          <div className="d-flex flex-column flex-end">{checkStoreListDataAvailaleOrNot()}</div>
        </>
      ) : (
        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half" style={{minHeight: 'h-100vh'}}>
          <OrganisationHeader role="admin" />
          {checkStoreListDataAvailaleOrNot()}
        </div>
      )}
      {/* <Modal
        className="pt-4 border-0
           modal-background-shadow employee-modal"
        size="lg"
        backdrop="static"
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <div className='position-relative'>
        <Logo className='d-flex justify-content-center' style={{width: '100%'}}/>
        <button type="button" className='border-0 position-absolute top-0' style={{ background: 'transparent', top:"-7px",right:"0px"}} onClick={handleClose}>
        <Close/>
        </button>
        </div>
        <div className='modal-invite-inner'>
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold text-center">Create Your Milestone</span>
            <p className="mt-2 font-sofia-pro color-gunmetal invite_paragraph">
            Choose your milestone and never forget to send your gratitude again
            </p>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Select a milestone</label>
            <select
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
            >
              <option value="" label="Select a milestone" />
              <option value="CompanyAdmin" label="Admin" />
              <option value="CompanyUser" label="Employee" />
            </select>
          </div>
          {/* <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Email</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
              name="email"
              placeholder="Eg: test@gmail.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Milestone title</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
              name="phone"
              placeholder="e.g. Work Anniversary"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
              </div>
            ) : null}
          </div>
          <div className="w-100">
            <Button
              classNameValue="w-100 my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
              children="Next"
              showLoader={showLoader}
              onClick={() => onSubmit}
            />
          </div>
        </form>
        </Modal.Body>
        </div>
      </Modal> */}

      <Modal
        className="w-100 pt-4 border-0 modal-background-shadow employee-modal"
        backdrop="static"
        size="md"
        show={editModalShow}
        onHide={handleEditModalClose}
        animation={false}
      >
        <div className="position-relative">
          <Logo className="d-flex justify-content-center" style={{ width: '100%' }} />
          <button
            type="button"
            className="border-0 position-absolute top-0"
            style={{ background: 'transparent', top: '-7px', right: '0px' }}
            onClick={handleEditModalClose}
          >
            <Close />
          </button>
        </div>
        <div className="modal-inner">
          <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0 d-flex justify-content-center align-items-center">
            <span className="font-24 color-black-pearl font-sofia-pro-semi-bold text-center">Edit employee</span>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
            {/* <EditUserPage id={editModalId} closeModal={handleEditModalClose} loadUsersList={getUsersList} deleteEmployeeData={deleteEmployeeData} index={userIndex}/> */}
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        className="pt-4 border-0
          modal-background-shadow employee-modal d-flex align-items-center"
        size="lg"
        show={showDeleteModal}
        backdrop="static"
        onHide={hideDeleteModalHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
          <div className="d-flex flex-column">
            <span className="font-18 color-black-pearl font-sofia-pro-semi-bold">
              Are you sure you want to remove {milestones[selectedMilestoneIndex]?.title} from your milestones?
            </span>
            <span className="font-14 font-sofia-pro-light color-black-pearl">
            {milestones[selectedMilestoneIndex]?.title} will be deleted permanently!
            </span>
          </div>
          <button
            type="button"
            className="close modal-close-icon employee-modal__close__icon"
            onClick={hideDeleteModalHandler}
          >
            <DeleteClose />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
          <div className="w-100 p-0 m-0 mt-3 d-flex justify-content-start align-items-center">
            <button
              type="submit"
              className="mr-3 w-90 btn bg-primary-color color-white font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
              onClick={() => {
                modalDeleteMilestoneBtnHandler();
                handleEditModalClose();
              }}
            >
              Delete
            </button>
            <button
              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
              onClick={hideDeleteModalHandler}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
        show={showSampleFormat}
        onHide={handleCloseSampleFormat}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 border-0">
          <button
            type="button"
            className="close switch-to-account-modal__close__icon"
            onClick={handleCloseSampleFormat}
          >
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-1 pb-5 border-0 text-center">
          <h4 className="p-2 font-sofia-pro-regular color-black-pearl">Sample CSV format</h4>
          <div className="mt-2">
            <img src={SampleCSVFormatImage} className="mw-100" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OrganisationMilestonesPage;
