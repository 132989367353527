/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
//Root reducer
import { combineReducers } from 'redux';
import * as sendThanks from './reducers/individual/sendThanks/sendThanks';
import * as header from './reducers/header/header';
import * as organisation from './reducers/organisation/organisation';
import * as organisationSendThanks from './reducers/organisation/sendThanks';
import * as addProductCard  from './reducers/multiProduct/multiProduct';
import * as anonAddProductCard  from './reducers/multiProduct/anonMultiProducts';

export const rootReducer = combineReducers({
  sendThanks: sendThanks.sendThanksReducer,
  header: header.headerReducer,
  organisation: organisation.allocateCreditReducer,
  organisationSendThanks: organisationSendThanks.organisationSendThanksReducer,
  multiProduct: addProductCard.addProductCardReducer,
  anonMultiProduct: anonAddProductCard.addProductCardReducer,
});
