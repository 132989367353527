/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { Router, useHistory } from 'react-router-dom';
import OrganisationSidebar from '../organisationsidebar/organisationsidebar';
import OrganisationHeader from '../organisationheader/organisationHeader';
import httpService from '../../../services/HttpService';
import './OrganisationTopup.scss';
import BudgetRange from '../onboarding/plans/BudgetRange';
import OrganisationService from '../../../services/OrganisationService';
import Toast from '../../../services/ToasterService';

const TopUp = props => {
  const history = useHistory();
  const [nextPathValue, setNextPathValue] = useState(null);
  const [availableCredits, setAvailableCredits] = useState(null);
  const [queryUser, setQueryUser] = useState(null);
  const [autoTopUpSelected, setAutoTopUpSelected] = useState(null);
  const [userData, setUserData] =useState({})

  useEffect(() => {
    if (props?.history?.location) {
      setNextPathValue(props?.history?.location?.state?.nextPath);
    }
    OrganisationService.getOrganisationDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setUserData(data);
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  }, []);

  useEffect(() => {
    if (props?.history?.location?.state !== undefined) {
      setQueryUser(props?.history?.location?.state.quantityChange);
    }
  }, []);
  const Amount = sessionStorage.getItem('Amount');
  useEffect(() => {
    GetAvailableBalance();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (queryUser === true) {
      formik.values.amount = Amount;
    }
  }, []);
  const GetAvailableBalance = () => {
    httpService.get('companies/current/credits').then(res => {
      setAvailableCredits(res.data['balance']);
    });
  };

  const initialValues = {
    amount: userData?.monthlyBudget || '',
    autoTopUp: userData?.monthlyAutoTopUp || false
  };
  // entered amount validated from 0 to 100000
  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) {
      event.preventDefault();
    }

    var max_chars = 5;

    if (event.target.value.length > max_chars) {
      event.target.value = event.target.value.substr(0, max_chars);
    }
  };

  const ProceedBtnHandler = () => {
    toast.dismiss();
    OrganisationService.manageCreditBudget({
      monthlyBudget: formik.values.amount,
      monthlyAutoTopUp: formik.values.autoTopUp
    });
    if (formik.values.amount > 9 && formik.values.amount < 100001) {
      if (queryUser === true) {
        history.push('/organisation/review');
      } else {
        history.push('/organisation/paymentOptions');
      }
      sessionStorage.setItem('Amount', formik.values.amount);
    } else if (formik.values.amount < 10) {
      toast.error('Minimum top up amount is $10');
    } else if (formik.values.amount > 100001) {
      toast.error('Maximum top up amount is $100000');
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
  });

  const CancelBtnHandler = () => {
    history.push('/organisation/dashboard');
  };
  return (
    <div className=" m-0 p-0 row">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <OrganisationSidebar menu="Organisation" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 h-100vh d-flex flex-column right_half ">
        <OrganisationHeader role="admin" />
        <div className="col-sm-12 ">
          <span className="organisation-topup__Top-up-account font-sofia-pro-light m-3">Top-up account</span>
          <div className="row ">
            <div className="col-sm-12 col-md-12 col-lg-8">
              <div className="card border-0  m-3">
                <div className="d-flex flex-column mx-3">
                  <span className="organisation-topup__Enter-amount font-sofia-pro-regular my-3">Enter amount</span>
                  <div className="input-group my-1 d-flex align-items-center budget-section">
                    <span className="h-60 input-group-text border-light-periwinkle h-60 border-right-0">$</span>
                    {queryUser === true ? (
                      <input
                        autoComplete="off"
                        onKeyPress={onKeyPress.bind(this)}
                        type="number"
                        pattern="\d{3}"
                        required
                        onWheel={e => e.target.blur()}
                        defaultValue={Amount}
                        onChange={formik.handleChange}
                        name="amount"
                        className="form-control w-auto shadow-none font-sofia-pro-regular color-charcoal-grey border-light-periwinkle budget-input h-60"
                        placeholder="Please enter the topup amount"
                      />
                    ) : (
                      <input
                        autoComplete="off"
                        onKeyPress={onKeyPress.bind(this)}
                        type="number"
                        pattern="\d{3}"
                        required
                        onWheel={e => e.target.blur()}
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        name="amount"
                        className="form-control w-auto shadow-none font-sofia-pro-regular color-charcoal-grey border-light-periwinkle budget-input h-60"
                        placeholder="Please enter the topup amount"
                      />
                    )}
                  </div>
                </div>

                <div className="topup-slider">
                  <BudgetRange
                    autoTopUp={userData?.monthlyAutoTopUp}
                    budget={userData?.monthlyBudget}
                    topUpCreate={false}
                    formik={formik}
                    setAutoTopUpSelected={setAutoTopUpSelected}
                  />
                </div>
                <div className="d-flex justify-content-end my-2 mx-3">
                  <button
                    className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium"
                    onClick={CancelBtnHandler}
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="btn bg-primary-color  color-white font-sofia-pro-semi-bold font-16 h-60 shadow-none"
                    onClick={ProceedBtnHandler}
                  >
                    Proceed
                  </button>

                  {/* <button type="submit" className="btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16 shadow-none" disabled={!checkQuantityIsGreaterThanZero && (selectedProduct?.recipients?.length * productQuantityValue) < 100 ? true : false} onClick={handleProceed.bind(this)}>Proceed</button> */}
                </div>
                {!autoTopUpSelected &&
                <button
                  className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-transparent font-14 font-sofia-pro-regular color-gunmetal"
                  onClick={CancelBtnHandler}
                >
                  Skip for now
                </button>
                }
              </div>
            </div>
            <div className=" col-sm-12   col-md-12 col-lg-4   my-3">
              <div className=" card d-flex flex-column mx-3 border-0">
                <span className="font-sofia-pro-medium organisation-topup__Available-credits m-3">
                  Available balance
                </span>
                <span className="font-sofia-pro-medium organisation-topup__amount mx-3 my-1">${availableCredits}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUp;
