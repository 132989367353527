/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import OrganisationHeader from '../../../../organisationheader/organisationHeader';
import * as AllocateSchema from '../../../../../../utils/Schema/allocateSchema';
import { resetAllocateCreditDetails } from '../../../../../../store/reducers/organisation/organisation';
import OrganisationSidebar from '../../../../organisationsidebar/organisationsidebar';
import './AllocateThanksSuccess.scss';
import { ReactComponent as GiftIllustrationImage } from '../../../../../../assets/images/send_thanks/components-illustrations-gift.svg';

function OrganisationAllocateSuccessPage(props) {
  let userDetails;

  let productType;

  let role = props.type;

  const history = useHistory();

  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (props?.location.state !== undefined) {
      productType = props.location.state.productType;
      userDetails = props.getRecipients;
    }
  });

  useEffect(() => {
    // let searchKeyword = AllocateSchema.AllocateSchema.email !== null ? AllocateSchema.AllocateSchema.email : '';
    // try {
    //     let regexEmail = RegexPattern.EmailRegexValue;
    //     let regexMobile = RegexPattern.MobileRegexValue;
    //     let url = ''
    //     let data = {
    //         search: "",
    //         email: "",
    //         phone: "",
    //         userName: "",
    //         region: 'AU'
    //     }
    //     if (regexEmail.test(searchKeyword)) {
    //         data.email = searchKeyword;
    //         data.search = "";
    //         data.phone = "";
    //         data.userName = "";
    //         url = httpService.post(`/users/search`, data);
    //     }
    //     const result = url.then((response) => {
    //         if (response) {
    //             let data = response['data'];
    //             setUserInfo(data)
    //         }
    //     }).catch(error => {
    //         let errorResponseFieldErrors = error.response['data'].fieldErrors;
    //         let errorResponseGlobalErrors = error.response['data'].globalErrors;
    //         if (error) {
    //             let errorResponse = error.response['data'];
    //             console.log("error response field errors ", errorResponse);
    //         }
    //         loopFieldErrors(errorResponseFieldErrors);
    //         if (errorResponseGlobalErrors) {
    //             if (errorResponseGlobalErrors.length > 0) {
    //                 errorResponseGlobalErrors.forEach((msg) => {
    //                     toast.error(msg.message);
    //                 })
    //             }
    //         }
    //     })
    // } catch (error) {
    //     console.log("error response")
    // }
  }, []);

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const allocateAgainBtnHandler = () => {
    history.push('/organisation/allocate/recipientInformation');
    AllocateSchema.AllocateSchema.paymentSource = {
      token: null,
      cardid: null,
      setCardAsDefault: false,
      useCredit: false,
    };
    AllocateSchema.AllocateSchema.message = '';
    AllocateSchema.AllocateSchema.employeeId = '';
    AllocateSchema.AllocateSchema.name = '';
    AllocateSchema.AllocateSchema.amount = null;
    props.resetData();
  };

  const homeBtnHandler = () => {
    props.resetData();
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 py-2 d-flex h-100 flex-column  ">
        <OrganisationSidebar menu="Credits" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 d-flex flex-column right_half h-100vh">
        <OrganisationHeader role="employee" />
        <div className="p-0 m-0 row">
          <div className="col-lg-2">&nbsp;</div>
          <div className="mt-4 col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 mt-2 card border-0  border-radius-5">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <GiftIllustrationImage />
                </div>
                <div className="my-2 d-flex flex-column justify-content-center align-items-center">
                  <span className="font-24 font-sofia-pro-regular color-black-pearl">
                    You have successfully allocated credits to
                    {/* {
                                            userDetails?.recipients?.length > 0 &&

                                            } */}
                    <div className="text-center">
                      <span>{AllocateSchema.AllocateSchema.name !== '' ? AllocateSchema.AllocateSchema.name : ''}</span>
                    </div>
                  </span>
                  <p className="font-14 font-sofia-pro-light color-charcoal-grey text-center">
                    To cancel this transaction, you can do it through transactions history before user has redeemed the
                    product and before expiry date is achieved
                  </p>
                </div>

                <div className="p-0 m-0 mt-3 row text-center">
                  <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                    <button
                      type="submit"
                      className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                      onClick={allocateAgainBtnHandler}
                    >
                      Allocate more
                    </button>
                  </div>
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 mb-1">
                    <Link to="/employee/dashboard">
                      <button
                        className="w-180 h-60 p-0 m-0  border-radius-5  bg-transparent font-16 color-primary border-faded-pink font-sofia-pro-semi-bold outline-none"
                        onClick={homeBtnHandler}
                      >
                        Home
                      </button>
                    </Link>
                  </div>
                  <div className="p-0 m-0 col-lg-2">&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    getRecipients: state.organisation.allocateData,
  };
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    resetData: () => dispatch(resetAllocateCreditDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationAllocateSuccessPage);
