/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useStripe, Elements } from '@stripe/react-stripe-js'; // STRIPE
import { loadStripe } from '@stripe/stripe-js';
import './Payment.scss';
import Button from '../../../../common/Button/Button';
import Stepper from '../../../../common/Stepper/Stepper';
import merchantService from '../../../../services/MerchantService';
import sendThanksService from '../../../../services/SendThanksService';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import BackIcon from '../../../../assets/images/common/icons-back.png';
import IconsCheckFilledImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import Toast from '../../../../services/ToasterService';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const initialValues = {
  bsb: '',
  account: '',
};

const validate = values => {
  let errors = {};

  if (!values.bsb) {
    errors.bsb = 'Required';
  } else if (values.bsb.trim() === '') {
    errors.bsb = 'Required';
  }

  if (!values.account) {
    errors.account = 'Required';
  } else if (values.account.trim() === '') {
    errors.account = 'Required';
  }

  return errors;
};

const MerchantPaymentPage = props => {
  return (
    <Elements stripe={stripePromise}>
      <Wrapper errors={props?.location?.state?.errors} />
    </Elements>
  );
};

function Wrapper(props) {
  const [merchantPaymentData, setMerchantPaymentData] = useState();
  const [merchantAgreementValue, setMerchantAgreementValue] = useState(false);
  const [merchantTermsAgreementValue, setMerchantTermsAgreementValue] = useState(true);

  const [transferScheduleValue, setTransferScheduleValue] = useState();

  const [showBackBtnModal, setShowShowBackBtnModal] = useState(false);

  const errors = props.errors;

  let agreementValue = false;

  const [steps, setSteps] = useState([
    {
      title: 'Business details',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Personal details',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Payment',
      icon: IconsCheckFilledImage,
    },
  ]);

  const [currentStep, setCurrentStep] = useState(2);

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleCloseSuccessPopup = () => setShowSuccessPopup(false);
  const handleShowSuccessPopup = () => setShowSuccessPopup(true);

  const showBackBtnModalHandler = () => setShowShowBackBtnModal(true);
  const hideBackBtnModalHandler = () => setShowShowBackBtnModal(false);

  const [showLoader, setShowLoader] = useState(false);

  const [showWeeklyDropDown, setShowWeeklyDropdown] = useState(false);

  const [showMonthlyInputBox, setShowMonthlyInputBox] = useState(false);

  const stripe = useStripe();
  const history = useHistory();

  const [date, setDate] = useState(null);

  const [weekDaySchedule, setWeekDaySchedule] = useState('Sunday');

  const [transferScheduleMonthlyValue, setTransferScheduleMonthlyValue] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    getProductsList();
    setShowLoader(false);
  }, []);

  // Submitting merchant details
  const onSubmit = async values => {
    toast.dismiss();

    if (showMonthlyInputBox === true && transferScheduleMonthlyValue === undefined) {
      Toast('Add transfer schedule value', 'warning');
      return;
    }

    if (transferScheduleMonthlyValue < 1 || transferScheduleMonthlyValue > 31) {
      Toast('Add transfer schedule value between 1 to 31', 'warning');
      return;
    }

    setShowLoader(true);

    let payoutData = {
      payout: {
        transfersEnabled: true,
        transferSchedule: '',
        weekDaySchedule: '',
        dayOfMonthSchedule: 0,
        products: [
          {
            priceId: '',
            payout: 0,
            agreementOn: '',
            hasAgreement: true,
          },
        ],
      },
    };

    payoutData.payout = merchantPaymentData.payout;

    payoutData.bankAccount = merchantPaymentData.bankAccount;

    merchantPaymentData?.payout?.products.map((product, index) => {
      product.hasAgreement = merchantAgreementValue;
      product.agreementOn = new Date().toISOString();
      delete product.type;
    });

    payoutData.payout.products = merchantPaymentData?.payout?.products;
    payoutData.payout.transferSchedule = transferScheduleValue;
    payoutData.payout.weekDaySchedule = weekDaySchedule;
    payoutData.payout.transfersEnabled = true;
    payoutData.payout.dayOfMonthSchedule = transferScheduleMonthlyValue;

    if (errors?.length > 0) {
      payoutData['updateTermsAgreement'] = merchantTermsAgreementValue;
    } else {
      payoutData['updateTermsAgreement'] = true;
    }

    let data = {
      country: 'Au',
      currency: 'aud',
      routing_number: values?.bsb?.trim(),
      account_number: values?.account?.trim(),
      account_holder_name:
        merchantPaymentData?.representative?.firstName?.trim() +
        ' ' +
        merchantPaymentData?.representative?.lastName?.trim(),
      account_holder_type: merchantPaymentData?.representative?.role,
    };

    payoutData.representative = null;
    payoutData.company = null;

    if (data?.account_number) {
      await stripe.createToken('bank_account', data).then(function (result) {
        // Handle result.error or result.token
        if (result?.token) {
          payoutData.bankAccount.token = result?.token?.id;
          sendMerchantUpdateRequest(payoutData);
        } else if (result?.error) {
          Toast(result?.error?.message, 'error');
          setShowLoader(false);
        }
      });
    }
  };

  // Merchant update request
  const sendMerchantUpdateRequest = data => {
    setShowLoader(true);
    toast.dismiss();
    merchantService
      .updateMerchantDetails(data)
      .then(response => {
        if (response) {
          if (errors?.length > 0) {
            handleShowSuccessPopup();
          } else {
            history.push({
              pathname: '/loading',
              state: {
                role: 'Merchant',
              },
            });
          }

          setShowLoader(false);
        }
      })
      .catch(error => {
        setShowLoader(false);
        if (error.response) {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                Toast(msg.message, 'error');
              });
            }
          }
        }
      });
  };

  // Getting products list
  const getProductsList = () => {
    sendThanksService.getListOfProducts('AU').then(response => {
      if (response) {
        let data = response['data'];
        getMerchantDetails(data);
      }
    });
  };

  // Getting merchant details
  const getMerchantDetails = data => {
    merchantService
      .getMerchantDetails()
      .then(response => {
        if (response) {
          let merchantPayoutData = response['data'];
          data?.map((product, index) => {
            merchantPayoutData?.payout?.products.map((merProduct, index) => {
              if (product?.price?.priceId === merProduct.priceId) {
                merProduct['type'] = product.type;
              }
            });
          });
          setMerchantPaymentData(merchantPayoutData);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    setShowLoader(false);
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const onValueChange = e => {
    let hasAgreementValue = e.target.checked;
    setMerchantAgreementValue(hasAgreementValue);
    agreementValue = e.target.checked;
  };

  const onAgreementValueChange = e => {
    let termsAndCondtions = e.target.checked;
    setMerchantTermsAgreementValue(termsAndCondtions);
  };

  const onTransferScheduleValueChange = e => {
    let transferValue = e.target.value;

    setTransferScheduleValue(e.target.value);

    if (transferValue === 'weekly') {
      setShowWeeklyDropdown(true);
      setShowMonthlyInputBox(false);
    } else if (transferValue === 'monthly') {
      setShowMonthlyInputBox(true);
      setShowWeeklyDropdown(false);
    } else {
      setShowWeeklyDropdown(false);
      setShowMonthlyInputBox(false);
    }
  };

  const transferScheduleMonthlyChange = e => {
    let value = e.target.value;
    setTransferScheduleMonthlyValue(value);
  };

  const backBtnHandler = () => {
    showBackBtnModalHandler();
  };

  const onChangeWeekDropdown = event => {
    let weekValue = event.target.value;
    setWeekDaySchedule(weekValue);
  };

  const handleSuccessPopupBtnHandler = () => {
    handleCloseSuccessPopup();
    history.push('/dashboard');
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  return (
    <div className="bg-alice-blue">
      <div className="p-2">
        <Logo />
      </div>
      <div className="mx-lg-5 mx-md-2 my-2 p-lg-4 p-md-2 bg-white">
        <div className="p-0 m-0 row">
          <div className="col-md-2 col-lg-3">&nbsp;</div>
          <div className="col-sm-12 col-md-8 col-lg-6">
            <div className="mb-3">
              <button
                className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none"
                onClick={backBtnHandler}
              >
                <img src={BackIcon} height="30px" />
                Back
              </button>
            </div>
            <div>
              <Stepper steps={steps} activeStep={currentStep} />
            </div>

            <div>
              {errors?.length > 0 && (
                <div className="p-2 my-1 border-radius-5 border-grey-blue">
                  <span className="font-sofia-pro-regular color-reddish font-16">Note</span>

                  <ul>
                    {errors?.map((msg, index) => {
                      return <li className="font-sofia-pro-regular color-reddish font-14">{msg}</li>;
                    })}
                  </ul>
                </div>
              )}
              <p className="p-0 m-0 text-right font-sofia-pro-italic font-14 color-black-pearl">
                All the payouts will be sent to the below mentioned account
              </p>
              <div className="p-0 m-0 p-3 card">
                <form className="mt-3" onSubmit={formik.handleSubmit}>
                  <span className="font-sofia-pro-semi-bold font-18 color-black-pearl">Payment details</span>

                  <div className="d-flex flex-column justify-content-between h-100vh">
                    <div>
                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">BSB*</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl payment__placeholder border-light-periwinkle"
                          name="bsb"
                          placeholder="Eg: 062-452"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.bsb}
                        />

                        {formik.touched.bsb && formik.errors.bsb ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.bsb}
                          </div>
                        ) : null}
                      </div>
                      <div className="p-0 b-0 pb-1 form-group">
                        <label className="font-sofia-pro-regular color-charcoal font-16">Account number*</label>
                        <input
                          type="text"
                          className="form-control shadow-none h-60 font-sofia-pro-regular color-black-pearl payment__placeholder border-light-periwinkle"
                          name="account"
                          placeholder="Eg: 1234567890"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.account}
                        />

                        {formik.touched.account && formik.errors.account ? (
                          <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                            <img className="mr-1" src={ErrorImage} /> {formik.errors.account}
                          </div>
                        ) : null}
                      </div>
                      <div>
                        <label className="font-sofia-pro-regular color-charcoal font-16"> Transfer Schedule* </label>
                        <div className="mt-2 d-flex align-items-center position-relative">
                          <label className="ml-4 d-flex payment-details-container">
                            <input type="radio" name="radio" value="daily" onChange={onTransferScheduleValueChange} />
                            <span className="checkmark"></span>
                            <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">Daily</span>
                          </label>
                        </div>
                        <div className="mt-2 d-flex align-items-center position-relative">
                          <label className="ml-4 d-flex payment-details-container">
                            <input type="radio" name="radio" value="weekly" onChange={onTransferScheduleValueChange} />
                            <span className="checkmark"></span>
                            <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">Weekly</span>
                          </label>
                          {showWeeklyDropDown === true && (
                            <select
                              className="ml-2 p-2 font-sofia-pro-regular font-14 color-black-pearl"
                              onChange={onChangeWeekDropdown}
                            >
                              <option value="Sunday">Sunday</option>
                              <option value="Monday">Monday</option>
                              <option value="Tuesday">Tuesday</option>
                              <option value="Wednesday">Wednesday</option>
                              <option value="Thursday">Thursday</option>
                              <option value="Friday">Friday</option>
                              <option value="Saturday">Saturday</option>
                            </select>
                          )}
                        </div>
                        <div className="mt-2 d-flex align-items-center position-relative">
                          <label className="ml-4 d-flex payment-details-container">
                            <input type="radio" name="radio" value="monthly" onChange={onTransferScheduleValueChange} />
                            <span className="checkmark"></span>
                            <span className="pl-1 font-sofia-pro-regular font-14 color-black-pearl">Monthly</span>
                          </label>
                          {showMonthlyInputBox === true && (
                            <div>
                              <input
                                type="number"
                                placeholder="Enter value between 1 to 31"
                                className="ml-2 font-sofia-pro-regular merchant__profile-payment-monthly-input"
                                onChange={transferScheduleMonthlyChange}
                                value={transferScheduleMonthlyValue}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="p-0 b-0 pb-1 mt-4 d-flex form-group merchant-payment__container">
                        {merchantPaymentData?.payout?.products.map((merProduct, index) => {
                          return (
                            <div className="d-flex">
                              <label className="merchant-payment__container">
                                <input type="checkbox" onChange={onValueChange} />
                                <span className="merchant-payment__checkmark"></span>
                              </label>

                              <span className="font-14 font-sofia-pro-light color-dark-pearl">
                                I accept to receive the Agreed Price of{' '}
                                {merchantPaymentData?.payout.products[0]?.payout} per{' '}
                                {merchantPaymentData?.payout.products[0]?.type} (any size) redeemed by customers
                              </span>
                            </div>
                          );
                        })}
                      </div>
                      {errors?.length > 0 && (
                        <div>
                          <div className="d-flex">
                            <label className="merchant-payment__container">
                              <input
                                type="checkbox"
                                name="termsAndConditions"
                                checked={merchantTermsAgreementValue}
                                onChange={onAgreementValueChange}
                              />
                              <span className="merchant-payment__checkmark"></span>
                            </label>

                            <span className="font-14 font-sofia-pro-light color-dark-pearl">
                              I agree to{' '}
                              <a
                                href="https://appreci.io/terms/"
                                target="blank"
                                className="color-primary font-14 font-sofia-pro-medium"
                              >
                                terms and conditions{' '}
                              </a>
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      {merchantAgreementValue === false && (
                        <button
                          type="submit"
                          className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                          disabled
                        >
                          Proceed
                        </button>
                      )}
                      {merchantAgreementValue === true && (
                        <Button
                          classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                          children="Proceed"
                          showLoader={showLoader}
                          onClick={() => onSubmit}
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <Modal
              className="pt-4 border-0
         modal-background-shadow switch-to-account-modal"
              show={showBackBtnModal}
              backdrop="static"
              onHide={hideBackBtnModalHandler}
              animation={false}
            >
              <Modal.Header className="p-0 m-0 border-0">
                <button
                  type="button"
                  className="close switch-to-account-modal__close__icon"
                  onClick={hideBackBtnModalHandler}
                >
                  <Close />
                </button>
              </Modal.Header>
              <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                <p className="font-16 font-sofia-pro-semi-bold color-black-pearl">Are you sure you want to exit?</p>
                <div className="w-100 p-0 m-0 d-flex justify-content-start align-items-center">
                  <Link to={'/merchant/onboarding/business'}>
                    <button
                      type="submit"
                      className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                    >
                      Yes
                    </button>
                  </Link>
                  <button
                    className="p-0 m-0 ml-2 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                    onClick={hideBackBtnModalHandler}
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              className="border-0 merchant-success__modal modal-background-shadow"
              size="md"
              show={showSuccessPopup}
              backdrop="static"
              onHide={handleCloseSuccessPopup}
            >
              <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
                <span className="font-16 color-primary font-sofia-pro-semi-bold">
                  <img src={IconsCheckFilledImage} height="25px" width="25px" />
                  <span className="pl-1">ACCOUNT UPDATED</span>
                </span>
              </Modal.Header>
              <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                <p className="font-16 font-sofia-pro-light color-black-pearl">
                  You have successfully resubmitted the missing documents and our team will verify them.
                </p>
                <p className="font-16 font-sofia-pro-light color-black-pearl">
                  You can continue using your personal account and once your merchant account is verified, we'll send
                  you the next steps.
                </p>
                <span className="p-0 m-0 font-sofia-pro-medium font-14 color-black-pearl">
                  Note: It may take up to 5 days to get the account activated.
                </span>
                <div className=" p-0 m-0 mt-4 mb-2 d-flex justify-content-start align-items-center">
                  <button
                    className="p-0 m-0 py-2 w-120 h-50 border-primary-color-2 border-radius-5 bg-transparent font-16 color-primary font-sofia-pro-semi-bold outline-none  shadow-none"
                    onClick={handleSuccessPopupBtnHandler}
                  >
                    Ok got it
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantPaymentPage;
