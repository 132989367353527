/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import './NewRedeem.scss';
import { ReactComponent as Logo } from '../../../../assets/images/logo/logo-colour.svg';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import SkipIcon from '../../../../assets/images/common/skip.png';
import ButtonLoading from '../../../../common/Button/Button';
import httpService from '../../../../services/HttpService';
import RedeemedModal from '../../../../common/component/modal/RedeemedModal';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';

import { MikeIconWhite, PlayIcon, SendVideoIcon } from '../../../../assets/images';

import { useLocation, useHistory } from 'react-router-dom';
// import SendThanksService from '../../../../services/SendThanksService';

function NewRedeem({ message }) {
  // const [skipCofeeShow, setskipCofeeShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const anonymous = JSON.parse(sessionStorage.getItem('redeemInfo'));
  const [quantityValue, setQuantityValue] = useState(1);
  const history = useHistory();
  const [mediaModal, setShowMediaModal] = useState(false);

  const showMediaModalHandler = () => setShowMediaModal(true);
  const hideMediaModalHandler = () => setShowMediaModal(false);

  const closeVideoFile = () => {
    // sendThanksSchema.message.type = null;
    // sendThanksSchema.message.storedFile = null;
    // sendThanksSchema.message.storedFileSecret = null;
    // sendThanksSchema.message.message = null;
    // setMessageType(1);
    // setExistingMediaData(undefined);
    // setAudioData(undefined);
    // setVideoData(undefined);
    // formik.setFieldValue('messageValue', undefined);
    // formik.values.messageValue = null;
    // formik.resetForm();
  };
  const GetCode = () => {
    const [skipCofeeShow, setskipCofeeShow] = useState(false);
    const [exchangeLoading, setExchangeLoading] = useState(false);
    const [getCode, setGetCode] = useState({
      redeemCode: null,
      redeemUrl: null,
    });

    function getSkipCode(codeExchangeable) {
      httpService
        .get(`gifts/bycode/${codeExchangeable}`)
        .then(res => {
          if (!res.data.redeemCode && !res.data.redeemUrl) {
            setTimeout(() => {
              getSkipCode(codeExchangeable);
            }, 10000);
          } else {
            setGetCode({
              redeemCode: res.data.redeemCode,
              redeemUrl: res.data.redeemUrl,
            });
          }
          setskipCofeeShow(true);
        })
        .catch(error => {
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
    const SkipRedeemedCoffe = () => {
      setExchangeLoading(true);
      let data = {
        priceId: anonymous?.exchangePriceId,
        quantity: 1,
      };
      sessionStorage.setItem('SelectedQuantity', quantityValue);
      // sessionStorage.setItem('LocationId', LocationId);

      httpService
        .post(`gifts/bycode/${anonymous?.code}/exchange`, data)
        .then(res => {
          console.log('Response from exchange--->', res);
          setskipCofeeShow(true);
          getSkipCode(res.data);
          setExchangeLoading(false);
        })
        .catch(error => {
          setModalShow(false);
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
          setExchangeLoading(false);
        });
    };
    const RedeemScreenRedirect = () => {
      setModalShow(false);
      // setcofeeShow(false)
      history.push('/signup');
    };
    console.log('Mesages types=>', message);

    return (
      <>
        <ButtonLoading
          children={<span className="redeem-text__proceed font-sofia-pro-semi-bold">Redeem</span>}
          showLoader={exchangeLoading}
          onClick={SkipRedeemedCoffe}
          classNameValue="border-0 color-white redeem-button__redeem my-3"
        />
        <RedeemedModal
          productType="skip"
          userType="anonymous"
          getCode={getCode}
          show={skipCofeeShow}
          onHide={() => setskipCofeeShow(false)}
          quantityValue="1"
          closeYes={RedeemScreenRedirect}
        />
      </>
    );
  };

  return (
    <div>
      <div className="bg-alice-blue">
        <div className="p-2 d-flex mb-3 justify-content-between bg-white">
          <Logo style={{ height: '60px', width: '120px' }} />
        </div>
        <div className="p-2 bg-white mb-3 font-18 font-sofia-pro-semi-bold color-black-pearl redeem-title">
          <div>You have received {anonymous?.type} from&nbsp;</div>
          <div> {anonymous?.from}</div>
        </div>
        {message.type == 'Text' ? (
          <div className='d-flex justify-content-center font-sofia-pro-bold color-primary font-24'>{message.message}</div>
        ) : message.type == 'Audio' ? (
          <div className='d-flex justify-content-center'>
            {/* <Close className="audio-close-icon" onClick={closeAudioFile} /> */}
            <div className="audio-list">
              <MikeIconWhite className="mike" />
              <PlayIcon className="play" onClick={showMediaModalHandler} />
            </div>
          </div>
        ) : message.type == 'Video' ? (
          <div className='d-flex justify-content-center'>
            {/* <Close className="audio-close-icon" onClick={closeVideoFile} /> */}
            <div className="send-video-list">
              <SendVideoIcon className="mike" />
              <PlayIcon className="play" onClick={showMediaModalHandler} />
            </div>
          </div>
        ) : null}
        <div className="p-0 m-0 row bg-white justify-content-center d-grid">
          <div className="p-0 m-0 col-sm-12 col-md-6 col-lg-6 text-center">
            <div className="pt-3 m-0 font-sofia-pro-bold color-black-pearl font-30">
              <div>Order your coffee ahead of the queue</div>
              <div className="text-center">with</div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="skip-image">
                <img src={SkipIcon} alt="Skip Icon" className="skip-image-icon" />
              </div>
            </div>
            <div>
              <GetCode onHide={() => setModalShow(false)} />
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
        show={mediaModal}
        onHide={hideMediaModalHandler}
        animation={false}
      >
        <Modal.Header className="p-0 m-0 border-0">
          <button type="button" className="close switch-to-account-modal__close__icon" onClick={hideMediaModalHandler}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
          {message.type === 'Audio' && (
            <audio controls preload="none">
              <source src={message?.storedFileUrl} type="audio/mpeg" />
            </audio>
          )}

          {message.type === 'Video' && (
            <video width="320" height="240" controls>
              <source src={message?.storedFileUrl} type="video/mp4" />
            </video>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default NewRedeem;
