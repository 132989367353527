import React, { useEffect, useState } from 'react';
import RedeemThanks from './RedeemNormal/Redeemthanks';
import AnonymousRedeem from './AnonymousRedeem/AnonymousReem';
import { useLocation } from 'react-router-dom';
import httpService from '../../../../services/HttpService';
import SendThanksService from '../../../../services/SendThanksService';
import NewRedeem from '../../../anonymous/redeem/newredeem/newRedeem';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const RedeemCommon = () => {
  const [giftData, setGiftData] = useState(null);
  const [product, setProduct] = useState(null);
  let query = useQuery();
  const Code = query.get('code');

  const SendServiceServiceList = () => {
    SendThanksService.getListOfProducts('AU').then(res => {
      setProduct(
        res.data.map(val => {
          return val;
        })
      );
    });
  };

  const RedeemClicked = () => {
    if (giftData) {
      const redeemInfo = {
        anonymous: 'anonymous',
        code: Code,
        giftEmail: giftData['user']['email'],
        giftPhone: giftData['user']['phone'],
        features: giftData?.features,
        exchangePriceId: giftData?.exchangePriceId,
        type: 'Coffee',
        transactionId: giftData.transactionId,
        from: giftData?.from,
      };

      sessionStorage.setItem('redeemInfo', JSON.stringify(redeemInfo));
      sessionStorage.setItem('priceId', giftData?.priceId);
    }
  };
  if (product !== null && giftData !== null) {
    product.map(value => {
      value.prices.map(item => {
        if (item.priceId === giftData['priceId']) {
          giftData['type'] = value['type'];
        }
      });
      if (value?.features === 'Exchange') {
        giftData['features'] = value?.features;
        giftData['exchangePriceId'] = value?.prices[0]?.priceId;
      }
    });

    RedeemClicked();
  }
  useEffect(() => {
    httpService.get(`Gifts/bycode/${Code}`).then(res => {
      setGiftData(res.data);
    });
    SendServiceServiceList();
  }, []);

  let test = 'test';
  console.log('Gifts data===>', giftData);
  return (
    <>
      {product ? (
        <div>
          {
            //price id of coffee for Prod env
            !JSON.parse(localStorage.getItem('userData')) && giftData?.priceId == 'price_1HJVpVIOy5z37gCroSXhRlCq' ? (
              //price id of coffee for staging env
              // !JSON.parse(localStorage.getItem('userData')) && giftData?.priceId == 'price_1H11seIOy5z37gCrpv4MSz1d' ?(
              <NewRedeem message={giftData?.message} />
            ) : !JSON.parse(localStorage.getItem('userData')) ? (
              <AnonymousRedeem />
            ) : (
              <RedeemThanks />
            )
          }
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default RedeemCommon;
