/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Common Toaster service
import React from 'react';
import { toast } from 'react-toastify';
import successImage from '../assets/images/common/icons-check-filled-white.png';
import { ReactComponent as ErrorImage } from '../assets/images/common/icons-close-white.svg';

const Toast = (message, type) => {
  switch (type) {
    case 'success':
      return toast.success((<div className="d-flex justify-content-center align-items-center">
          <img src={successImage} height="25px" width="25px" />
          <p className="p-0 m-0 pl-2">{message}</p>
        </div>), {autoClose: 1000}
      );
    case 'error':
      return toast.error(
        <div className="d-flex justify-content-center align-items-center">
          <ErrorImage height="25px" width="25px" />
          <p className="p-0 m-0 pl-2">{message}</p>
        </div>
      );
    case 'warning':
      return toast.warning(
        <div className="d-flex justify-content-center align-items-center">
          <p className="p-0 m-0 pl-2">{message}</p>
        </div>
      );
    default:
      return toast.warning(
        <div className="d-flex justify-content-center align-items-center">
          <p className="p-0 m-0 pl-2"></p>
        </div>
      );
  }
};
export default Toast;
