import React, { useState } from 'react';

import {ReactComponent as DeleteMilestone} from '../../../assets/images/common/delete-user.svg'
import {ReactComponent as DeleteMilestoneActive} from '../../../assets/images/common/delete-user-active.svg'

const DeleteIcon = ({ isHovered, index, onMouseEnter, onMouseLeave, deleteMilestoneData }) => {
  return (
    <span
      data-tip="Delete"
      onMouseEnter={(e) => onMouseEnter(index, true)}
      onMouseLeave={(e) => onMouseLeave(index, false)}
      onClick={() => deleteMilestoneData(index)}
    >
      {isHovered ? (
        <DeleteMilestoneActive className="cursor-pointer table-delete" />
      ) : (
        <DeleteMilestone className="cursor-pointer table-delete" />
      )}
    </span>
  );
};

export default DeleteIcon;