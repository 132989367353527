/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
// STRIPE
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, ElementsConsumer } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { oneMinusToCard, oneMoreToCard, resetCard } from '../../../../store/reducers/multiProduct/anonMultiProducts';

// MOMENT
import Moment from 'react-moment';
import { connect, useSelector, useDispatch } from 'react-redux';
import OrganisationHeader from '../../organisationheader/organisationHeader';
import Button from '../../../../common/Button/Button';
import OrganisationSidebar from '../../organisationsidebar/organisationsidebar';

import { orgSendThanksDetails, orgResetSendThanksDetails } from '../../../../store/reducers/organisation/sendThanks';
import sendThanksSchema, * as SendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import SendThanksService from '../../../../services/SendThanksService';
import EditButtonIcon from '../../../../assets/images/common/edit-button-icon.png';

// TOOLTIP
import ReactTooltip from 'react-tooltip';
import PaymentService from '../../../../services/PaymentService';

import OrganisationService from '../../../../services/OrganisationService';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import IconMiscCard from '../../../../assets/images/common/icons-misc-card.png';
import GoolePay from '../../../../assets/images/common/google-pay-mark-800.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const currentPath = window.location.pathname;
toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

function Wrapper(props) {
  const stripe = useStripe();
  const history = useHistory();
  const dispatch = useDispatch();

  const { productsCard, details } = useSelector(state => state.multiProduct);

  const PaymentType = sessionStorage.getItem('PaymentType');

  const [cardDetails, setCardDetails] = useState();
  const [gpayToken, setGpayToken] = useState();

  const [showGooglePayLoader, setShowGooglePayLoader] = useState(false);

  const [showSendThanksLoader, setShowSendThanksLoader] = useState(false);
  const [creditBalance, setCreditBalance] = useState();

  let reviewData = {};

  let priceId =
    details.type === 'GiftCard'
      ? details.giftPriceId
      : productsCard?.prices
      ? productsCard?.prices[0]?.priceId
      : productsCard.priceId;
  let price =
    details.type === 'GiftCard'
      ? details.giftValues * 1.05
      : productsCard?.prices
      ? productsCard?.prices[0]?.price
      : productsCard.price;

  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  const allowedCardNetworks = ['MASTERCARD', 'VISA'];

  const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

  const tokenizationSpecification = {
    type: 'PAYMENT_GATEWAY',
    parameters: {
      gateway: 'stripe',
      'stripe:version': 'v3',
      'stripe:publishableKey': process.env.REACT_APP_STRIPE_PUBLIC_KEY,
    },
  };

  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
    },
  };

  const cardPaymentMethod = Object.assign(
    {},
    { tokenizationSpecification: tokenizationSpecification },
    baseCardPaymentMethod
  );

  let paymentsClient = null;

  if (props?.props?.selectedCardDetails) {
    // reviewData = props?.props?.location?.state;
    reviewData = props?.props?.selectedCardDetails;
    reviewData['amount'] = details.type === 'GiftCard' ? (details.giftValues ? price : 0) : reviewData.amount;
  }

  function getGooglePaymentDataRequest() {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = {
      merchantName: 'Appreci Pty Ltd',
    };
    return paymentDataRequest;
  }

  function getGooglePaymentsClient() {
    if (paymentsClient === null) {
      // eslint-disable-next-line no-undef
      paymentsClient = new google.payments.api.PaymentsClient({
        environment: process.env.REACT_APP_MERCHANT_ENVIRONMENT,
      });
    }
    return paymentsClient;
  }
  function getGoogleTransactionInfo() {
    return {
      countryCode: 'AU',
      currencyCode: 'AUD',
      totalPriceStatus: 'FINAL',
      totalPrice: (
        Number(
          details?.recipients?.length > 1
            ? details?.recipients?.length * productsCard?.quantity
            : productsCard?.quantity
        ) * Number(price)
      ).toFixed(2),
    };
  }

  function onGooglePaymentButtonClicked() {
    setShowGooglePayLoader(true);
    const paymentDataRequest = getGooglePaymentDataRequest();
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .loadPaymentData(paymentDataRequest)

      .then(res => {
        let data = JSON.parse(res.paymentMethodData.tokenizationData.token);
        setGpayToken(data.id);
        setShowGooglePayLoader(false);
      })
      .catch(function (err) {
        setShowGooglePayLoader(false);
        console.error(err);
      });
  }

  if (props?.props?.location?.state) {
    reviewData = props?.props?.location?.state;
    reviewData['amount'] = details.type === 'GiftCard' ? (details.giftValues ? price : 0) : reviewData.amount;
  }

  useEffect(() => {
    getCreditBalance();
    getCardsList();
    loadExistingProductsList();
  }, []);

  // Checking page reload
  useEffect(() => {
    window.scrollTo(0, 0);
    window.onbeforeunload = function (event) {
      return true;
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const getCreditBalance = () => {
    OrganisationService.getCreditsBalance()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCreditBalance(data);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          toast.error(errorMessage);
        }
      });
  };

  const getCardsList = () => {
    PaymentService.getListOfCards(true).then(response => {
      if (response.status === 200) {
        let cardId = SendThanksSchema.sendThanksData.paymentSource.cardid;
        let data = response['data'];

        data.forEach((card, index) => {
          if (card.id === cardId) {
            reviewData['cardLastNumber'] = card?.last4;
            reviewData['brand'] = card?.brand;
            reviewData['cardExpiryDate'] = card?.expiresOn;

            let data = {
              cardLastNumber: card?.last4,
              brand: card?.brand,
              cardExpiryDate: card?.expiresOn,
            };

            setCardDetails(data);
          }
        });
      }
    });
  };

  const loadExistingProductsList = () => {
    SendThanksService.getListOfProducts('AU').then(response => {
      let data = response['data'];

      let choosenCardId = SendThanksSchema.sendThanksData.paymentSource.cardid;

      data.forEach((product, index) => {
        if (product.price?.priceId === priceId && reviewData.productType !== 'GiftCard') {
          sendThanksSchema.sendThanksData.amount = 0;
          sendThanksSchema.sendThanksData.priceId = priceId;
          sendThanksSchema.sendThanksData.paymentSource.cardid = choosenCardId;
          sendThanksSchema.sendThanksData.amount = sendThanksSchema.sendThanksData.quantity * product.price.price;
          sendThanksSchema.sendThanksData.paymentSource.token = null;
          props.props.paymentInformation(sendThanksSchema.sendThanksData);
        } else if (reviewData.productType === 'GiftCard') {
          sendThanksSchema.sendThanksData.amount = reviewData.amount
            ? sendThanksSchema.sendThanksData.recipients.length * reviewData.amount
            : reviewData.price;
          sendThanksSchema.sendThanksData.priceId = priceId;
          sendThanksSchema.sendThanksData.paymentSource.cardid = choosenCardId;
          sendThanksSchema.sendThanksData.paymentSource.token = null;
          sendThanksSchema.sendThanksData.quantity = null;
          props.props.paymentInformation(sendThanksSchema.sendThanksData);
        }
      });
    });
  };

  useEffect(() => {
    let data = {
      priceId: priceId,
      quantity:
        details?.recipients?.length > 1 ? details?.recipients?.length * productsCard?.quantity : productsCard?.quantity,
      amount: (
        Number(
          details?.recipients?.length > 1
            ? details?.recipients?.length * productsCard?.quantity
            : productsCard?.quantity
        ) * Number(price)
      ).toFixed(2),
      recipients: details.recipients,
      message: details.message,
    };
    if (details?.message?.type === null) {
      delete data?.message;
    }

    SendThanksService.purchaseIntent(data)
      .then(response => {
        if (response.status === 200) {
          setShowSendThanksLoader(false);
          let secretId = response['data'].intentSecret;
          // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
          let paymentMethod = reviewData.choosedCardId;
          props?.props?.setClientSecrets(secretId);
          // handlePayment(secretId, paymentMethod, props);
        }
      })
      .catch(error => {
        setShowSendThanksLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;

        console.log('error filed errors ', errorResponseFieldErrors.length);
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  }, []);
  const handleOneMoreToCard = product => {
    dispatch(oneMoreToCard(product));
  };
  const handleOneMinusToCard = product => {
    dispatch(oneMinusToCard(product));
  };

  const QuantityButton = ({ product, quantity }) => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <button
          className={'dashboard-card_redeem border-none bd-grey-color2 px-3 py-1 dashboard-button'}
          onClick={() => handleOneMinusToCard(product)}
        >
          <span className=" font-20 font-sofia-pro-medium"> {'<'}</span>
        </button>
        <span className="font-14 font-weight-bold px-2">
          {/* {productsCard.id === product.id ? productsCard.quantity : 0} */}
          {quantity}
        </span>
        <button
          className={'dashboard-card_redeem  border-none bd-grey-color2 px-3 py-1 dashboard-button'}
          onClick={() => handleOneMoreToCard(product)}
        >
          <span className="font-20 font-sofia-pro-medium"> {'>'}</span>
        </button>
      </div>
    );
  };

  useEffect(() => {
    if (gpayToken != undefined) {
      gpayButton();
    }
  });
  const gpayButton = () => {
    let data = {
      paymentSource: {
        token: gpayToken,
        cardId: null,
        setCardAsDefault: false,
      },
      priceId: priceId,
      quantity: details?.recipients?.length > 1 ? details?.recipients?.length : productsCard?.quantity,
      amount: (
        Number(details?.recipients?.length > 1 ? details?.recipients?.length : productsCard?.quantity) * Number(price)
      ).toFixed(2),
      recipients: details?.recipients,
    };

    if (sendThanksSchema.sendThanksData.recipientCsvFileBase64 !== null) {
      data['recipientCsvFileBase64'] = sendThanksSchema.sendThanksData.recipientCsvFileBase64;
    }

    SendThanksService.purchaseProduct(data)
      .then(res => {
        history.push({
          pathname: '/organisation/send/thanksSuccess',
          state: {
            productType: details.type,
          },
        });
      })
      .catch(err => {
        toast.error('Something went wrong');
      });
  };

  // const sendThanksBtnHandler = () => {
  //   setShowSendThanksLoader(true);
  //   let data = {
  //     priceId: priceId,
  //     quantity:
  //       details?.recipients?.length > 1 ? details?.recipients?.length * productsCard?.quantity : productsCard?.quantity,
  //     amount: (
  //       Number(
  //         details?.recipients?.length > 1
  //           ? details?.recipients?.length * productsCard?.quantity
  //           : productsCard?.quantity
  //       ) * Number(price)
  //     ).toFixed(2),
  //     recipients: details.recipients,
  //     message: details.message,
  //   };

  //   if (details.type === 'GiftCard') {
  //     data.amount = reviewData.amount ? reviewData.amount * details.recipients.length : reviewData.price;
  //     data.quantity = details.recipients.length;
  //   }

  //   if (details?.message?.type === null) {
  //     delete data?.message;
  //   }

  //   if (PaymentType === 'Card') {
  //     SendThanksService.purchaseIntent(data)
  //       .then(response => {
  //         if (response.status === 200) {
  //           setShowSendThanksLoader(false);
  //           let secretId = response['data'].intentSecret;
  //           // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
  //           let paymentMethod = reviewData.choosedCardId;
  //           handlePayment(secretId, paymentMethod, props);
  //         }
  //       })
  //       .catch(error => {
  //         setShowSendThanksLoader(false);
  //         let errorResponseFieldErrors = error.response['data'].fieldErrors;
  //         let errorResponseGlobalErrors = error.response['data'].globalErrors;

  //         console.log('error filed errors ', errorResponseFieldErrors.length);
  //         loopFieldErrors(errorResponseFieldErrors);
  //         if (errorResponseGlobalErrors) {
  //           if (errorResponseGlobalErrors.length > 0) {
  //             errorResponseGlobalErrors.forEach(msg => {
  //               toast.error(msg.message);
  //             });
  //           }
  //         }
  //       });
  //   }
  // };

  const sendThanksBtnHandler = () => {
    setShowSendThanksLoader(true);
    let data = {
      priceId: priceId,
      quantity:
        details?.recipients?.length > 1 ? details?.recipients?.length * productsCard?.quantity : productsCard?.quantity,
      amount: (
        Number(
          details?.recipients?.length > 1
            ? details?.recipients?.length * productsCard?.quantity
            : productsCard?.quantity
        ) * Number(price)
      ).toFixed(2),
      recipients: details.recipients,
      message: details.message,
    };

    if (sendThanksSchema.sendThanksData.recipientCsvFileBase64 !== null) {
      data['recipientCsvFileBase64'] = sendThanksSchema.sendThanksData.recipientCsvFileBase64;
      delete data.recipients;
    }

    if (details?.type === 'GiftCard') {
      data.amount = reviewData.amount ? reviewData.amount * details?.recipients.length : reviewData.price;
      data.quantity = details?.recipients?.length;
    }

    if (details?.message?.type === null) {
      delete data?.message;
    }

    data['sendAsCompany'] = true;

    if (PaymentType === 'Card') {
      SendThanksService.purchaseIntent(data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            let secretId = response['data'].intentSecret;
            // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
            // console.log('paymentMethod', paymentMethod);
            let paymentMethod = reviewData.choosedCardId;

            handlePayment(secretId, paymentMethod, props);
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;

          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    } else if (PaymentType === 'availableCredit') {
      data['paymentsource'] = {
        usecredit: true,
      };

      SendThanksService.purchaseProduct(data)
        .then(response => {
          if (response.status === 200) {
            setShowSendThanksLoader(false);
            let secretId = response['data'].intentSecret;
            // let paymentMethod = sendThanksSchema.sendThanksData.paymentSource.cardid;
            let paymentMethod = reviewData.choosedCardId;
            handlePayment(secretId, paymentMethod, props);
            history.push({
              pathname: '/organisation/send/thanksSuccess',
              state: {
                productType: details?.type,
                type: 'organisation',
              },
            });
          }
        })
        .catch(error => {
          setShowSendThanksLoader(false);
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;

          console.log('error filed errors ', errorResponseFieldErrors.length);
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
    }
  };

  const loopFieldErrors = data => {
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const handlePayment = async (intentSecret, paymentMethod, props) => {
    const paymentIntent = await stripe.confirmCardPayment(intentSecret, {
      payment_method: paymentMethod,
    });

    let data = paymentIntent.paymentIntent;

    if (data?.status === 'succeeded') {
      // SendThanksService.purchaseProduct(sendThanksSchema.sendThanksData).then((response) => {
      //         if (response.status === 200) {

      //         }
      //     }).catch((error) => {
      //         if(error.response){
      //         let errorResponseFieldErrors = error.response['data'].fieldErrors['recipients[1].Phone'];
      //         let errorResponseGlobalErrors = error.response['data'].globalErrors;
      //         if (errorResponseFieldErrors) {
      //             if (errorResponseFieldErrors.length > 0) {
      //                 errorResponseFieldErrors.forEach(msg => {
      //                     toast.error(msg);
      //                 });
      //             }
      //         }
      //         if (errorResponseGlobalErrors) {
      //             if (errorResponseGlobalErrors.length > 0) {
      //                 errorResponseGlobalErrors.forEach((msg) => {
      //                     toast.error(msg.message);
      //                 })
      //             }
      //         }
      //     }
      //     })
      history.push({
        pathname: '/organisation/send/thanksSuccess',
        state: {
          productType: details.type,
        },
      });
    } else {
      let errorMessage = paymentIntent?.error?.message;
      toast.error(errorMessage);
    }
  };

  const editQuantityBtnHandler = () => {
    props?.props?.history.push({
      pathname: '/organisation/send/selectItem',
      state: {
        nextPath: 'reviewThanks',
      },
    });
  };

  const editMessageBtnHandler = () => {
    // props?.props?.history.push({
    //   pathname: '/organisation/send/recipientInformation',
    //   state: {
    //     nextPath: 'reviewThanks',
    //   },
    // });
    history.push('/organisation/send/recipientInformation');
  };

  const changeCardBtnHandler = () => {
    props.props?.history.push({
      pathname: '/organisation/send/payment',
    });
  };

  const cancelBtnHandler = () => {
    props?.props?.resetData();
    history.push({
      pathname: '/organisation/dashboard',
    });
  };
  const handleChangeGiftType = () => {
    history.push('/organisation/send/selectItem');
  };
  const handleChangeReceiverEmail = () => {
    history.push('/organisation/send/recipientInformation');
  };
  return (
    <div className="p-0 m-0 row">
      {/* <div className='col-lg-2'>&nbsp;</div> */}
      <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="card border-0  border-radius-24">
          <div className="card-body">
            <span className="font-36 varela-round color-black-pearl">Review purchase</span>
            {/* <div className='mt-2 p-2 bg-lavender-blush-1 border-radius-5'>
            <span className='font-16 font-sofia-pro-semi-bold color-dark-charcoal'>Details</span>
          </div> */}
            <div className="mt-5 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
              <span className="font-18 monteserrat-bold color-charcoal">Gift</span>
              <span className="font-18 monteserrat-normal color-charcoal">
                {details?.type}
                <span className="font-12 monteserrat-normal pl-2 cursor-pointer" onClick={handleChangeGiftType}>
                  Change
                </span>
              </span>
            </div>
            {details.type !== 'GiftCard' && (
              <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
                <span className="font-18 monteserrat-bold color-charcoal">
                  Quantity
                  {details?.recipients?.length === 1 && (
                    <span
                      className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer"
                      onClick={editQuantityBtnHandler}
                    >
                      <img src={EditButtonIcon} className="edit-button-icon" alt="Edit Icon" />{' '}
                    </span>
                  )}
                </span>

                <QuantityButton
                  product={productsCard}
                  quantity={
                    details?.recipients?.length > 1
                      ? details?.recipients?.length * productsCard?.quantity
                      : productsCard?.quantity
                  }
                />
              </div>
            )}
            <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
              <span className="font-18 monteserrat-bold color-charcoal">To</span>
              <div>
                {details?.recipients !== undefined && details?.recipients.length !== 0 && (
                  <span data-tip data-for="recipientList" className="font-16 font-sofia-pro-regular color-charcoal">
                    {details.recipients[0]?.email !== null
                      ? details.recipients[0]?.email
                      : details.recipients[0]?.phone}
                    {details.recipients[0]?.userName !== null ? details.recipients[0]?.userName : ''}
                    {details.recipients.length > 1 ? <span className="pl-2">...</span> : null}
                    {details.recipients.length > 1 && (
                      <ReactTooltip className="bg-primary-color color-white " id="recipientList">
                        {details.recipients.map((recipient, index) => {
                          return (
                            <div key={index}>
                              <p className="mt-1"> {recipient.email !== null ? recipient.email : ''}</p>
                              <p className="mt-1"> {recipient.phone !== null ? recipient.phone : ''}</p>
                              <p className="mt-1"> {recipient.userName !== null ? recipient.userName : ''}</p>
                            </div>
                          );
                        })}
                      </ReactTooltip>
                    )}
                  </span>
                )}
                <span className="font-12 monteserrat-normal pl-2 cursor-pointer" onClick={handleChangeReceiverEmail}>
                  Change
                </span>
                {/* <span className='font-12 monteserrat-normal light-grey2 pl-2'>Change</span> */}
              </div>
            </div>

            <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
              <span className="font-18 monteserrat-bold color-charcoal d-flex w-100 justify-content-between align-items-center">
                Message
                <span>
                  <span
                    className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer w-100 d-flex justify-content-between "
                    onClick={editMessageBtnHandler}
                  >
                    <span className="edit-message-text font-12 monteserrat-normal light-grey2 ">Edit </span>
                    <img src={EditButtonIcon} className="edit-button-icon" alt="Edit Icon" />
                  </span>
                </span>
              </span>
              {details?.recipients !== undefined && details?.recipients.length !== 0 && (
                <div>
                  {details?.message?.message !== null && (
                    <div>
                      <span
                        data-tip
                        data-for="recipientMessage"
                        className="text-right d-block font-12 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis"
                      >
                        {details?.message?.message}
                      </span>
                      <ReactTooltip id="recipientMessage" html={true} className="review-thanks__tooltip">
                        {details?.message?.message}
                      </ReactTooltip>
                    </div>
                  )}
                  {details?.message?.message === null && (
                    <div>
                      <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                        {details?.message?.type}
                      </span>
                    </div>
                  )}
                  {(details?.message !== undefined && details?.message?.message === null) ||
                    (details?.message?.message?.trim() === '' && (
                      <div>
                        <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                          No message
                        </span>
                      </div>
                    ))}
                  {!details?.message && (
                    <div>
                      <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
                        No message
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="mt-1"></div>
            <div className="mt-3 mb-5">
              <div className="mt-2 p-2 d-flex justify-content-between align-items-center border-radius-5">
                <span className="font-32 color-dark-charcoal monteserrat-medium">Total</span>
                <span className="font-32 monteserrat-normal color-dark-charcoal">
                  $
                  {details.type === 'GiftCard'
                    ? Number(
                        reviewData.amount ? reviewData.amount * details?.recipients?.length : reviewData.price
                      ).toFixed(2)
                    : (
                        Number(
                          details?.recipients?.length > 1
                            ? details?.recipients?.length * productsCard?.quantity
                            : productsCard?.quantity
                        ) * Number(price)
                      ).toFixed(2)}
                  <div className="font-12 light-grey2">Including GST</div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
        <button
          className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none"
          onClick={cancelBtnHandler}
        >
          Cancel
        </button>

        {PaymentType === 'GooglePay' ? (
          <Button
            classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none google-play-btn"
            children="Send"
            showLoader={showGooglePayLoader}
            onClick={onGooglePaymentButtonClicked}
          />
        ) : PaymentType === 'Card' || PaymentType === 'availableCredit' ? (
          <Button
            classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
            children="Send"
            showLoader={showSendThanksLoader}
            onClick={sendThanksBtnHandler}
          />
        ) : (
          ''
        )}
      </div>
      <div className="d-none"></div>
    </div>

    // <div className=" m-0 p-0 row ">

    //   <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half">

    //     <div className="p-0 m-0 row">
    //       <div className="col-lg-2">&nbsp;</div>
    //       <div className="col-sm-12 col-md-12 col-lg-8">
    //         <div className="ml-2 card border-0  border-radius-5">
    //           <div className="card-body">
    //             <span className="font-32 font-sofia-pro-light color-black-pearl">Review your purchase</span>
    //             <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
    //               <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Details</span>
    //             </div>
    //             <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
    //               <span className="font-16 font-sofia-pro-light color-charcoal">To</span>
    //               {details?.recipients !== undefined && details?.recipients.length !== 0 && (
    //                 <span data-tip data-for="recipientList" className="font-16 font-sofia-pro-regular color-charcoal">
    //                   {details.recipients[0]?.email !== null
    //                     ? details.recipients[0]?.email
    //                     : details.recipients[0]?.phone}
    //                   {details.recipients[0]?.userName !== null ? details.recipients[0]?.userName : ''}
    //                   {details.recipients.length > 1 ? <span className="pl-2">...</span> : null}
    //                   {details.recipients.length > 1 && (
    //                     <ReactTooltip className="bg-primary-color color-white " id="recipientList">
    //                       {details.recipients.map((recipient, index) => {
    //                         return (
    //                           <div key={index}>
    //                             <p className="mt-1"> {recipient.email !== null ? recipient.email : ''}</p>
    //                             <p className="mt-1"> {recipient.phone !== null ? recipient.phone : ''}</p>
    //                             <p className="mt-1"> {recipient.userName !== null ? recipient.userName : ''}</p>
    //                           </div>
    //                         );
    //                       })}
    //                     </ReactTooltip>
    //                   )}
    //                 </span>
    //               )}
    //               {sendThanksSchema.sendThanksData.recipientCsvFileBase64 !== null && (
    //                 <span className="font-16 font-sofia-pro-regular color-charcoal"> CSV File</span>
    //               )}
    //             </div>
    //             <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
    //               <span className="font-16 font-sofia-pro-light color-charcoal">Item</span>
    //               <span className="font-16 font-sofia-pro-regular color-charcoal">
    //                 {details?.type}
    //               </span>
    //             </div>
    //             <div className="mt-3 p-1 d-flex justify-content-between align-items-center border-bottom-pale-grey">
    //               <span className="font-16 font-sofia-pro-light color-charcoal">
    //                 Message
    //                 <span>
    //                   {sendThanksSchema.sendThanksData.recipientCsvFileBase64 === null && (
    //                     <span
    //                       className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer"
    //                       onClick={editMessageBtnHandler}
    //                     >
    //                       Edit message
    //                     </span>
    //                   )}
    //                 </span>
    //               </span>
    //               {details?.recipients !== undefined && details?.recipients.length !== 0 && (
    //                 <div>
    //                   {details?.message && (
    //                     <div>
    //                       <span
    //                         data-tip
    //                         data-for="recipientMessage"
    //                         className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis"
    //                       >
    //                         {details?.message?.message}
    //                       </span>
    //                       <ReactTooltip id="recipientMessage" html={true} className="review-thanks__tooltip">
    //                         {details?.message?.message}
    //                       </ReactTooltip>
    //                     </div>
    //                   )}
    //                   {details?.message?.message === null && (
    //                     <div>
    //                       <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
    //                         {details?.message?.type}
    //                       </span>
    //                     </div>
    //                   )}
    //                   {details?.message === undefined ||
    //                     details?.message?.message === null ||
    //                     details?.message?.message === undefined ||
    //                     (details?.message?.type === null && (
    //                       <div>
    //                         <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
    //                           No message
    //                         </span>
    //                       </div>
    //                     ))}
    //                   {!details?.message&& (
    //                     <div>
    //                       <span className="text-right d-block font-16 font-sofia-pro-regular color-charcoal review-thanks__message__text__ellipsis">
    //                         No message
    //                       </span>
    //                     </div>
    //                   )}
    //                 </div>
    //               )}
    //             </div>
    //             {details.type !== 'GiftCard' && (
    //               <div className="mt-3 p-1 d-flex justify-content-between align-items-center">
    //                 <span className="font-16 font-sofia-pro-light color-charcoal">
    //                   Quantity
    //                   {details?.recipients?.length === 1 && (
    //                     <span
    //                       className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer"
    //                       onClick={editQuantityBtnHandler}
    //                     >
    //                       Edit quantity
    //                     </span>
    //                   )}
    //                 </span>
    //                 <span className="font-16 font-sofia-pro-regular color-charcoal">
    //                   {productsCard?.quantity}
    //                 </span>
    //               </div>
    //             )}
    //             <div className="mt-3">
    //               <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
    //                 <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Payment method</span>
    //               </div>
    //               <div className="mt-1 p-2 d-flex justify-content-between align-items-center">
    //                 <div>
    //                   {PaymentType === 'Card' ? (
    //                     <label className="font-14 font-sofia-pro-light color-black-pearl ">
    //                       <span>
    //                         {' '}
    //                         {cardDetails?.brand} •••• •••• •••• {cardDetails?.cardLastNumber} |
    //                         <span>
    //                           <Moment format="MM/YY">{cardDetails?.cardExpiryDate}</Moment>
    //                         </span>
    //                       </span>
    //                       <img className="pl-1" src={IconMiscCard} />
    //                     </label>
    //                   ) : PaymentType === 'GooglePay' ? (
    //                     <label className="font-14 font-sofia-pro-light color-black-pearl ">
    //                       <span>Google Pay</span>
    //                       <img className="pl-1" src={GoolePay} />
    //                     </label>
    //                   ) : (
    //                     ''
    //                   )}
    //                   {PaymentType === 'availableCredit' && (
    //                     <label className="font-14 font-sofia-pro-light color-black-pearl ">
    //                       <span>Available credits : $ {creditBalance?.balance}</span>
    //                     </label>
    //                   )}
    //                 </div>

    //                 <div>
    //                   <span
    //                     className="pl-2 font-16 font-sofia-pro-regular color-primary cursor-pointer"
    //                     onClick={changeCardBtnHandler}
    //                   >
    //                     Change
    //                   </span>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="mt-1">
    //               <div className="mt-2 p-2 bg-lavender-blush-1 border-radius-5">
    //                 <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">Purchase summary</span>
    //               </div>
    //               <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
    //                 <span className="font-16 font-sofia-pro-light color-charcoal">Amount (Including GST)</span>
    //                 <span className="font-16 font-sofia-pro-regular color-charcoal">
    //                   $
    //                   {details.type === 'GiftCard'
    //                     ? Number(
    //                         reviewData.amount
    //                           ? reviewData.amount * details?.recipients?.length
    //                           : reviewData.price
    //                       ).toFixed(2)
    //                     : (
    //                         Number(
    //                           details?.recipients?.length > 1
    //                             ? details?.recipients?.length * productsCard?.quantity
    //                             : productsCard?.quantity
    //                         ) * Number(price)
    //                       ).toFixed(2)}
    //                 </span>
    //               </div>
    //               {PaymentType === 'availableCredit' && (
    //                 <div className="mt-1 p-1 d-flex justify-content-between align-items-center">
    //                   <span className="font-16 font-sofia-pro-light color-charcoal">Remaining balance</span>
    //                   <span className="font-16 font-sofia-pro-regular color-charcoal">
    //                     $
    //                     {details.type === 'GiftCard'
    //                       ? (
    //                           Number(creditBalance?.balance) -
    //                           Number(
    //                             reviewData.amount
    //                               ? reviewData.amount * details?.recipients?.length
    //                               : reviewData.price
    //                           )
    //                         ).toFixed(2)
    //                       : (
    //                           Number(creditBalance?.balance) -
    //                           Number(
    //                             details?.recipients?.length > 1
    //                               ? details?.recipients?.length * productsCard?.quantity
    //                               : productsCard?.quantity
    //                           ) *
    //                             Number(price)
    //                         ).toFixed(2)}
    //                   </span>
    //                 </div>
    //               )}
    //             </div>

    //             <div className="mt-3">
    //               <div className="mt-2 p-2 d-flex justify-content-between align-items-center bg-lavender-blush-1 border-radius-5">
    //                 <span className="font-18 font-sofia-pro-semi-bold color-dark-charcoal">Total spend</span>
    //                 <span className="font-18 font-sofia-pro-medium color-dark-charcoal">
    //                   $
    //                   {reviewData.productType === 'GiftCard'
    //                     ? Number(
    //                         reviewData.amount
    //                           ? reviewData.amount * details?.recipients?.length
    //                           : reviewData.price
    //                       ).toFixed(2)
    //                     : (
    //                         Number(
    //                           details?.recipients?.length > 1
    //                             ? details?.recipients?.length * productsCard?.quantity
    //                             : productsCard?.quantity
    //                         ) * Number(price)
    //                       ).toFixed(2)}
    //                 </span>
    //               </div>
    //             </div>

    //             <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
    //               <button
    //                 className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none"
    //                 onClick={cancelBtnHandler}
    //               >
    //                 Cancel
    //               </button>

    //               {PaymentType === 'GooglePay' ? (
    //                 <Button
    //                   classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
    //                   children="Send"
    //                   showLoader={showGooglePayLoader}
    //                   onClick={onGooglePaymentButtonClicked}
    //                 />
    //               ) : PaymentType === 'Card' || PaymentType === 'availableCredit' ? (
    //                 <Button
    //                   classNameValue="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
    //                   children="Send"
    //                   showLoader={showSendThanksLoader}
    //                   onClick={sendThanksBtnHandler}
    //                 />
    //               ) : (
    //                 ''
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-2">&nbsp;</div>
    //       <div className="d-none" />
    //     </div>
    //   </div>
    // </div>
  );
}

const OrganisationSendThanksReviewThanksPage = props => {
  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ elements, stripe }) => <Wrapper elements={elements} stripe={stripe} props={props} />}
      </ElementsConsumer>
    </Elements>
  );
};

// MAPPING - SEND THANKS DATA
const mapStateToProps = state => {
  return {
    getPaymentInformation: state.organisationSendThanks.orgSendThanksData,
  };
};

// DISPATCH  - SEND THANKS DATA
const mapDispatchToProps = dispatch => {
  return {
    paymentInformation: data => dispatch(orgSendThanksDetails(data)),
    resetData: () => dispatch(orgResetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationSendThanksReviewThanksPage);
