/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';

const getListOfAvailableLocations = (lat, long, count) => {
  return httpService.get(
    `Locations?latitude=${lat}&longitude=${long}&distanceInMetres=10000000&productType=coffee&region=AU&pageNumber=${count}`
  );
};

const RedeemCoffee = (locationId, transactionId, quantity) => {
  return httpService.post(`Gifts/${transactionId}/redeem?locationId=${locationId}&quantity=${quantity}`);
};

const PostRating = (rating, responseid) => {
  return httpService.post(`Gifts/${responseid}/rating?rating=${rating}`);
};

const PostRatingAnonymous = (rating, responseid) => {
  return httpService.post(`Gifts/bycode/${responseid}/rating`,{rating});
};

const LocationCluster = data => {
  return httpService.post('/api/v1/Locations/clustered', data);
};

export default {
  getListOfAvailableLocations,
  RedeemCoffee,
  PostRating,
  PostRatingAnonymous,
  LocationCluster,
};
