/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import OrganisationHeader from '../../../../organisationheader/organisationHeader';
import Toast from '../../../../../../services/ToasterService';
import './AllocateRecipientInformation.scss';
import * as AllocateSchema from '../../../../../../utils/Schema/allocateSchema';
import {
  allocateCreditDetails,
  resetAllocateCreditDetails,
} from '../../../../../../store/reducers/organisation/organisation';
import OrganisationService from '../../../../../../services/OrganisationService';
import OrganisationSidebar from '../../../../organisationsidebar/organisationsidebar';
import { ErrorImage } from '../../../../../../assets/images';
import IconsBackImage from '../../../../../../assets/images/common/icons-back.png';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

const OrganisationAllocateRecipentInformationPage = props => {
  const initialValues = {
    message: null,
    amount: null,
  };

  const validate = values => {
    let errors = {};

    if (values.amount === null || values.amount < 0) {
      errors.amount = 'Please add amount';
    } else if (values.amount < 10) {
      errors.amount = 'Minimum allocate amount is $10';
    } else if (values.amount > availableCreditBalance?.balance) {
      errors.amount = `Amount ${values.amount} is higher than available balance ${availableCreditBalance?.balance}.`;
    }

    return errors;
  };

  const [availableCreditBalance, setAvailableCreditBalance] = useState();

  const [userData, setUserData] = useState([]);

  const [employeeId, setEmployeeId] = useState();

  const history = useHistory();

  const [nextPathValue, setNextPathValue] = useState();

  let allocateData = AllocateSchema.AllocateSchema;

  useEffect(() => {
    getUsersList();
    getCreditBalance();

    if (allocateData?.amount !== '' && allocateData?.amount !== undefined) {
      formik.setFieldValue('amount', allocateData?.amount);
    }

    if (allocateData?.message !== '' && allocateData?.message !== undefined) {
      formik.setFieldValue('message', allocateData?.message);
    }

    if (allocateData?.employeeId !== '' && allocateData?.employeeId !== undefined) {
      setEmployeeId(allocateData?.employeeId);
    }

    if (props.history.location) {
      setNextPathValue(props?.history?.location?.state?.nextPath);
    }
  }, []);

  const getUsersList = () => {
    OrganisationService.getUsersList()
      .then(response => {
        if (response) {
          let data = response['data'];

          let userSessionData = JSON.parse(localStorage.getItem('userData'));

          let employeesList = [];

          data.forEach((user, index) => {
            if (user?.status !== 'Invited' && user?.role !== 'CompanyAdmin' && userSessionData?.email !== user.email) {
              employeesList.push(user);
            }
          });

          setUserData(employeesList);

          if (employeesList?.length > 0) {
            setEmployeeId(employeesList[0].id);
          }
        }
      })
      .catch(error => {
        if (error) {
          let errorResponseFieldErrors = error.response['data'].fieldErrors;
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }
          loopFieldErrors(errorResponseFieldErrors);
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors?.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                Toast(msg.message, 'error');
              });
            }
          }
        }
      });
  };

  const getCreditBalance = () => {
    OrganisationService.getCreditsBalance()
      .then(response => {
        if (response) {
          let data = response['data'];
          setAvailableCreditBalance(data);
        }
      })
      .catch(error => {
        if (error) {
          let errorResponse = error.response['data'];
          let errorMessage = errorResponse.globalErrors[0].message;
          toast.error(errorMessage);
        }
      });
  };

  const handleEmployeeDropdownChangeHandler = e => {
    setEmployeeId(e.target.value);
  };

  const cancelBtnHandler = e => {
    e.preventDefault();
    // props.resetData();
    props.history.push({
      pathname: '/employee/dashboard',
    });
  };

  const onSubmit = values => {
    let data = AllocateSchema.AllocateSchema;

    data.amount = values.amount;
    data.message = values.message;
    data.employeeId = employeeId;

    userData.forEach((employee, index) => {
      if (Number(employee?.id) === Number(employeeId)) {
        data.name =
          employee.firstName !== null
            ? employee.firstName
            : '' + ' ' + employee.lastName !== null
            ? employee.lastName
            : '';
      }
    });

    props.allocateCreditDetails(data);

    if (nextPathValue !== '' && nextPathValue !== undefined) {
      sessionStorage.setItem('PaymentType', 'availableCredit');
      AllocateSchema.AllocateSchema.paymentSource.useCredit = true;
      props.history.push({
        pathname: nextPathValue,
        state: {
          Type: 'AvailableCredit',
        },
      });
    } else {
      sessionStorage.setItem('PaymentType', 'availableCredit');
      AllocateSchema.AllocateSchema.paymentSource.useCredit = true;
      props.history.push({
        pathname: '/organisation/allocate/review',
        state: {
          Type: 'AvailableCredit',
        },
      });
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column">
        <OrganisationSidebar menu="Credits" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 mb-4 d-flex flex-column right_half h-100vh">
        <OrganisationHeader role="employee" />
        <div className="d-flex">
          <Link to="/organisation/dashboard">
            <span className="p-2 m-4 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 card border-0  border-radius-5">
              <div className="card-body">
                <form className="mt-2" onSubmit={formik.handleSubmit}>
                  <span className="color-black-pearl font-32 font-sofia-pro-light">Enter recipient information </span>
                  <div className="mt-3">
                    {/* <form>
                                        <div className="form-group">
                                            <label className="font-sofia-pro-regular color-charcoal font-16">Email or Username or mobile number</label>
                                            <input type="text" className="form-control shadow-none h-60 font-sofia-pro-regular font-14 recipient-information__placeholder" placeholder="Enter name" />
                                        </div>
                                    </form> */}
                    <label className="p-0 m-0 font-sofia-pro-regular color-charcoal font-16">Choose employee</label>
                    {/* <small className="pl-2 font-sofia-pro-regular color-charcoal font-10">()</small> */}
                    {userData?.length > 0 && (
                      <select
                        className="form-control shadow-none font-sofia-pro-regular color-charcoal font-16 h-60"
                        placeholder="Choose employee"
                        onChange={handleEmployeeDropdownChangeHandler.bind(this)}
                        value={employeeId}
                      >
                        {userData?.map((data, index) => {
                          return data.firstName !== null || data.lastName !== null ? (
                            <option value={data.id} key={index}>
                              {data.firstName === null ? '' : data.firstName}{' '}
                              {data.lastName === null ? '' : data.lastName}
                            </option>
                          ) : (
                            ''
                          );
                        })}
                      </select>
                    )}

                    {userData?.length === 0 && (
                      <div>
                        <select
                          className="form-control shadow-none font-sofia-pro-regular font-16 h-60"
                          placeholder="Choose employee"
                          disabled
                        >
                          <option></option>
                        </select>
                        <span className="font-sofia-pro-regular color-reddish font-16">
                          There are no employees added to this organisation
                        </span>
                      </div>
                    )}

                    <div className="mt-3">
                      <label className="p-0 m-0 font-sofia-pro-regular color-charcoal font-16">Amount</label>
                      <input
                        type="number"
                        className="form-control  shadow-none  font-sofia-pro-light font-16  h-60"
                        placeholder="Enter credit amount"
                        name="amount"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.amount}
                        onWheel={e => e.target.blur()}
                      />
                      {formik.touched.amount && formik.errors.amount ? (
                        <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                          <img className="mr-1" src={ErrorImage} /> {formik.errors.amount}
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-3">
                      <span className="font-16 color-charcoal  font-sofia-pro-regular ">Appreciation note</span>
                      <textarea
                        className="form-control shadow-none font-sofia-pro-light font-16"
                        rows="4"
                        placeholder="Your message(Optional)"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                      ></textarea>
                    </div>

                    <div>
                      <div className="p-0 m-0 row">
                        <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
                          {/* <Link to="/dashboard"> */}
                          <button
                            className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none"
                            onClick={cancelBtnHandler}
                          >
                            Cancel
                          </button>
                          {/* </Link> */}
                          {/* <Link to="/selectItem"> */}
                          <button
                            type="submit"
                            className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none"
                          >
                            Proceed
                          </button>
                          {/* </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mt-md-4 mt-sm-3">
            <div className="card border-0  border-radius-5">
              <div className="card-body d-flex flex-column">
                <span className="color-gunmetal font-16 font-sofia-pro-medium">Available credits</span>
                <span className="font-56 color-black-pearl font-sofia-pro-medium">
                  ${availableCreditBalance?.balance}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    getAllocateRecipientInfo: state.organisation.allocateData,
  };
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    allocateCreditDetails: data => dispatch(allocateCreditDetails(data)),
    resetData: () => dispatch(resetAllocateCreditDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationAllocateRecipentInformationPage);
