/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import { ReactComponent as LogoMedium } from '../../../../assets/images/dashboard/icons/logo-main-medium.svg';
import { ReactComponent as InstagramLogo } from '../../../../assets/images/dashboard/icons/instagram.svg';
import { ReactComponent as FacebookLogo } from '../../../../assets/images/dashboard/icons/facebook.svg';
import { ReactComponent as LinkedInLogo } from '../../../../assets/images/dashboard/icons/linkedin-alt.svg';
import { ReactComponent as TwitterLogo } from '../../../../assets/images/dashboard/icons/twitter-alt.svg';
import { ReactComponent as YoutubeLogo } from '../../../../assets/images/dashboard/icons/youtube.svg';
import Heart from '../../../../assets/images/dashboard/image/untitled.png';
import '../../../individual/redeemThanks/redeemwine/RedeemWine.scss';

const BottomBar = () => {
  let CopyrightYear = new Date().getFullYear();
  return (
    <div>
      <div className='redeemwine-card__small d-flex flex-column '>
        <LogoMedium className='mx-3 my-4' />
        <span className='redeemwine-text__Our-mission-is-to-en font-sofia-pro-light mx-3'>
          Our mission is to enable everybody to be mindful and grateful, so we set out to create a software platform
          that enables physical connections.
        </span>
        <div className='d-flex flex-row my-4'>
          <InstagramLogo className='mx-3' />
          <FacebookLogo className='mx-3' />
          <TwitterLogo className='mx-3' />
          <LinkedInLogo className='mx-3' />
          <YoutubeLogo className='mx-3' />
        </div>
        <div className='redeemwine-line d-flex mx-4 my-3' />
        <span className='redeemwine-text__-Appreci-All-r font-sofia-pro-light'>
          ©{CopyrightYear} Appreci. All rights reserved.
          <br />
          Made with <img src={Heart} /> by Appreci
        </span>
      </div>
    </div>
  );
};

export default BottomBar;
