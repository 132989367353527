/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import { Carousel, Image } from 'react-bootstrap';
import {
  MerchantImageFor1280,
  OrganisationImageFor2560,
  IndividualImageFor1280,
  IndividualImageFor2560,
  OrganisationImageFor1280,
  OrganisationImageFor1440,
  MerchantImageFor1440,
  IndividualImageFor1440,
  MerchantImageFor2560,
} from '../../assets/images';
import useWindowDimensions from '../Responsive/WindowDimensions';
import './AuthSlider.scss';

const AuthSliderNew = () => {
  const { width } = useWindowDimensions();

  return (
    <div className="login-left__half">
      {/* {
                width <= 1280 ?
                    <Carousel className="Auth-slider__carousel" fade indicators={true} nextIcon="" nextLabel="" prevIcon="" prevLabel="">
                        <Carousel.Item>
                            <Image
                                // className="login-res__image"
                                fluid
                                src={IndividualImageFor1280}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image
                                // className="login-res__image"
                                fluid
                                src={MerchantImageFor1280}
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image
                                // className="login-res__image"
                                fluid
                                src={OrganisationImageFor1280}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                    : <></>
            } */}

      {width <= 1441 ? (
        <Carousel
          className="Auth-slider__carousel"
          fade
          indicators={true}
          nextIcon=""
          nextLabel=""
          prevIcon=""
          prevLabel=""
        >
          <Carousel.Item>
            <Image
              // className="login-res__image"
              fluid
              src={IndividualImageFor1440}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              // className="login-res__image"
              fluid
              src={MerchantImageFor1440}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              // className="login-res__image"
              fluid
              src={OrganisationImageFor1440}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      ) : (
        <></>
      )}
      {width > 1441 && width <= 2560 ? (
        <Carousel
          className="Auth-slider__carousel"
          fade
          indicators={true}
          nextIcon=""
          nextLabel=""
          prevIcon=""
          prevLabel=""
        >
          <Carousel.Item>
            <Image
              // className="login-res__image"
              fluid
              src={IndividualImageFor2560}
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              // className="login-res__image"
              fluid
              src={MerchantImageFor2560}
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              // className="login-res__image"
              fluid
              src={OrganisationImageFor2560}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AuthSliderNew;
