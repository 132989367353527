import { Link } from 'react-router-dom';
import React from 'react';
import { ReactComponent as PadLock } from '../../../assets/images/common/padlock-icon.svg';

const ItemSideBar = ({comingSoon, logic, iconActive, icon, name, to }) => {

  return (
    <div className='w-100 d-flex justify-content-between'>
      <Link style={{ textDecoration: 'none' }} to={to}>
        {iconActive?.length ?
          <img src={
            logic ?
              iconActive :
              icon
          }
               alt={name}
               className='dashboard-icons dashboard-navigation__home__active'
          />
          :
          logic ?
            iconActive :
            icon
        }
        <span
          className={
            logic ?
              'pl-2 dashboard-home font-sofia-pro-semi-bold' :
              'pl-2 dashboard-text__inActive  font-sofia-pro-regular'
          }
        >
           {/* {name} {comingSoon ? <span className="font-12 ">(coming soon)</span> : null} */}
           {name} {comingSoon ? <PadLock style={{marginTop: '5px'}}/> : null}
         </span>
      </Link>
      {logic &&
        <div className="dashboard-menu_selected  "></div>
      }
    </div>
  );
};

export default ItemSideBar;