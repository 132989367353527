import { ReactComponent as ProfilePic } from '../../assets/images/common/user-profile.svg';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { ReactComponent as SendThanksIcon } from '../../assets/images/send_thanks/icons-top-actions-send-thanks.svg';
import React, { useEffect, useState } from 'react';
import TransactionHistoryService from '../../services/TransactionHistoryService';
import SendThanksService from '../../services/SendThanksService';
import { toast } from 'react-toastify';
import CommonService from '../../services/CommonService';
import { MikeIconWhite, PlayIcon } from '../../assets/images';
import GiftMessageVideo from '../../components/common/GiftMessageVideo';
import './Notification.scss'
import useWindowDimensions from '../Responsive/WindowDimensions';
import ModalVideoAudio from '../component/modal/ModalVideoAudio';
import { ReactComponent as BuildingIcon } from '../../assets/images/common/building-icon.svg';

const NotificationList = ({ role }) => {
  const { width } = useWindowDimensions();

  const [recentNotifications, setRecentNotifications] = useState();
  const [showNotificationMediaModal, setShowNotificationMediaModal] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (role === 'personal'){
      getReceivedTransactions();
    }else if(role === 'employee'){
      SendThanksService.getListOfProducts('AU')
        .then(response => {
          if (response) {
            setProductList(response['data']);
            getReceivedTransactionsOfAdmin()
          }
        })
        .catch(error => {
          let errorResponseGlobalErrors = error.response['data'].globalErrors;
          if (error) {
            let errorResponse = error.response['data'];
            console.log('error response field errors ', errorResponse);
          }

          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });

    }
    return () => {};
  }, []);


  const getNewMediaFile = index => {
    setShowNotificationMediaModal(true);
    let guid = recentNotifications[index].message?.storedFile;
    let fileSecret = recentNotifications[index].message?.storedFileSecret;

    CommonService.getMediaFile(fileSecret, guid).then(response => {
      if (response) {
        let data = response['data'];
        setMediaData(data);
      }
    });
  };

  const getReceivedTransactions = () => {
    TransactionHistoryService.getNotificationsList()
      .then(response => {
        if (response) {
          let data = response['data']?.data;
          SendThanksService.getListOfProducts('AU')
            .then(response => {
              if (response) {
                let productsList = response['data'];
                data.forEach((value, index) => {
                  productsList.forEach((product, index) => {
                    if (product?.price?.priceId === value.priceId) {
                      if (value?.type === 'GiftReceived') {
                        value['statusMessage'] = `You received ${product?.name} from`;
                      } else if (value?.type === 'GiftRedeemed') {
                        value['statusMessage'] = `Your ${product?.name} redeemed by`;
                      } else if (value?.type === 'GiftCancelled') {
                        value['statusMessage'] = `Your ${product?.name} cancelled by`;
                      }
                    }
                  });
                });
                setRecentNotifications(data);
              }
            })
            .catch(error => {
              let errorResponseGlobalErrors = error.response?.data.globalErrors;
              if (error) {
                let errorResponse = error.response?.data;
                console.log('error response field errors ', errorResponse);
              }
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            });
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };


  const getReceivedTransactionsOfAdmin = () => {
    TransactionHistoryService.getNotificationsListOfAdmin()
      .then(response => {
        if (response) {
          let data = response['data']?.data;
          if (role === 'employee') {
            data.forEach((value, index) => {
              productList.forEach((product, index) => {
                if (product?.price?.priceId === value.priceId) {
                  if (value?.type === 'GiftReceived') {
                    value['statusMessage'] = `You received ${product?.name} from`;
                  } else if (value?.type === 'GiftRedeemed') {
                    value['statusMessage'] = `Your ${product?.name} redeemed by`;
                  } else if (value?.type === 'GiftCancelled') {
                    value['statusMessage'] = `Your ${product?.name} cancelled by`;
                  } else if (value?.type === 'GiftSent') {
                    value['statusMessage'] = `You sent a ${product?.name} to`;
                  }
                }
              });
            });
            setRecentNotifications(data);
          }
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const notificationClickHandler = index => {
    let id = recentNotifications[index].id;
    TransactionHistoryService.changeNotificationStatus(id)
      .then(response => {
        if (response) {
          // console.log('response ', response)
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  return (
    <div>
      <div className='p-2 w-100 d-flex'>
        <div className='w-100 d-flex flex-column align-items-start'>
          <div className='w-100 d-flex justify-content-between align-items-center'>
            <span className='mb-3 font-18 font-sofia-pro-semi-bold color-black-pearl'>
              New notifications
            </span>
            {/* <small className="font-12 font-sofia-pro-regular color-primary">Clear all</small> */}
          </div>
          {recentNotifications?.length > 0 &&
          recentNotifications.map((notification, index) => {
            if (index < 4) {
              return (
                <div
                  className={`p-0 m-0 w-100 mt-1 p-1 card bg-pink-closest border-pale-mavue-1 d-flex flex-row ${width > 600 ? 'h-120' : 'h-150'}`}
                  key={index}
                  onClick={notificationClickHandler.bind(this, index)}
                >
                  { notification.user !== null &&
                  notification.user?.profilePhotoUrl !== '' &&
                  notification.user?.profilePhotoUrl !== null && (
                    <img
                      className='rounded-circle'
                      src={notification.user?.profilePhotoUrl + '?h=30'}
                      height='35px'
                      width='35px'
                    />
                  )}
                  {notification.user ?
                    notification.user.profilePhotoUrl === '' ||
                    (notification.user.profilePhotoUrl === null && <ProfilePic height="35px" />):
                    <BuildingIcon
                      style={{fill: '#e20077' }}
                      className="rounded-circle dashboard_icon"
                    />
                  }
                  <div className='px-3 d-flex justify-content-between w-100'>
                    <div>
                      <div className='d-flex flex-column'>
                        <Link
                          className='text-decoration-none'
                          to={
                            notification.type === 'GiftReceived'
                              ? '/redeemThanks'
                              : notification.type === 'GiftRedeemed'
                                ? '/transactionhistory'
                                : null
                          }
                        >
                          <span className='font-16 font-sofia-pro-light color-black-pearl'>
                            <span className='font-16 font-sofia-pro-regular'>
                              {notification?.type !== undefined
                                ? notification?.type === 'GiftReceived'
                                ? 'You just received a gift from'
                                : 'You sent a product to'
                                : ''
                              } &nbsp;
                              <span>
                                {notification.user ?
                                  notification.user?.fullName !== null
                                    ? notification.user?.fullName
                                    : notification.user?.userName
                                  : notification.companyName
                                }
                              </span>
                            </span>
                          </span>
                        </Link>
                        <span className='font-12 color-gunmetal font-sofia-pro-light'>
                          {notification?.message?.type === 'Text' && (
                            <span>Message: {notification?.message?.message}</span>
                          )}
                        </span>
                        <p className='p-0 m-0 pl-1 font-12 font-sofia-pro-regular'>
                          <Moment format=' Do MMM h:mm a' utc local>
                            {notification?.date}
                          </Moment>
                        </p>
                      </div>
                      {notification.type === 'GiftReceived' && (
                        <div className='cursor-pointer'>
                          {/* <span onClick={navigateToSendThanks.bind(this, index)}> */}
                          {role === 'employee' && (
                            <Link
                              to={{
                                pathname: '/organisation/employee/recipientInformation',
                                state: {
                                  searchName: [recentNotifications[index].user?.userName]
                                }
                              }}
                            >
                              <SendThanksIcon height='25px' />
                              <span className='font-14 font-sofia-pro-light color-primary'>
                                Reply thanks
                              </span>
                            </Link>
                          )}
                          {role === 'personal' && (
                            <Link
                              to={{
                                pathname: '/recipientInformation',
                                state: {
                                  searchName: [recentNotifications[index].user?.userName]
                                }
                              }}
                            >
                              <SendThanksIcon height='25px' />
                              <span className='font-14 font-sofia-pro-light color-primary'>
                                Reply thanks
                              </span>
                            </Link>
                          )}
                        </div>
                      )}
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className="d-flex flex-column">
                          {notification?.message?.type === 'Video' && (
                            <GiftMessageVideo
                              fileId={notification['message']['storedFile']}
                              fileSecret={notification['message']['storedFileSecret']}
                              onClick={getNewMediaFile.bind(this, index)}
                              size={'small'}
                              videoSize={'?h=120&w=120'}
                            />
                          )}

                          {notification?.message?.type === 'Audio' && (
                            <div className="audio-list-class audio-list-class_100">
                              <MikeIconWhite className="mike" />
                              <PlayIcon className="play" onClick={getNewMediaFile.bind(this, index)} />
                            </div>
                          )}
                        </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
          {recentNotifications?.length === 0 && (
            <div className='w-100 pb-2 pt-4 text-center'>
                <span className='font-14 text-center color-black-pearl fonr-sofia-pro-regular'>
                  No recent notifications found
                </span>
            </div>
          )}

          <div className='p-2 w-100 text-center'>
            {role === 'employee' && (
              <Link to={'/employee/transactionhistory'}>
                <small className='font-16 font-sofia-pro-regular color-primary'>
                  View all notifications
                </small>
              </Link>
            )}
            {role === 'personal' && (
              <Link to={'/transactionhistory'}>
                <small className='font-16 font-sofia-pro-regular color-primary'>
                  View all notifications
                </small>
              </Link>
            )}
          </div>
        </div>
      </div>
      <ModalVideoAudio
        show={showNotificationMediaModal}
        onHide={() => setShowNotificationMediaModal(false)}
        audioVideoUrl={mediaData}
        setAudioVideoUrl={setMediaData}
      />
    </div>
  );
};

export default NotificationList;