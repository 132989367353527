import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import Toast from '../../../services/ToasterService';
import * as RegexPatterns from '../../../utils/RegexPatterns';
import paymentService from '../../../services/PaymentService';
import { useFormik } from 'formik';

const AddCardForms = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [cardElemetError, setCardElementError] = useState('');
  const [cardExpiryError, setCardExpiryError] = useState('');
  const [cardCVCError, setCardCVCError] = useState('');
  const initialValues = {
    postcode: null,
  };

  let checkNewCardAdded = false;
  let anonUserData = JSON.parse(localStorage.getItem('anonUserData'));

  const saveCardDetails = async event => {
    if (props.type == 'anonymous') {
      if (props.checkingStatusOfUser) {
        if (props.registeredUserloggedIn || anonUserData) {
          if (checkNewCardAdded === false) {
            checkNewCardAdded = true;

            if (!stripe || !elements) {
              // Stripe.js has not loaded yet. Make sure to disable
              // form submission until Stripe.js has loaded.
              Toast('Please add card details', 'error');
              return;
            }

            // Block native form submission.
            event.preventDefault();

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardNumberElement);

            // Use your card Element with other Stripe.js APIs
            const { error, token } = await stripe.createToken(cardElement);

            if (error) {
              Toast('Please add the valid card details', 'error');
            } else if (formik.values.postcode === null) {
              Toast('Please add a postcode', 'error');
            } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
              Toast('Please add a valid postcode', 'error');
            } else {
              let tokenId = token.id;
              let data = {
                token: tokenId,
                setAsDefault: false,
                region: 'AU',
                postcode: formik.values.postcode,
              };

              paymentService
                .addNewCard(data)
                .then(response => {
                  if (response) {
                    Toast('New card added successfully', 'success');
                    props?.loadExistingList()
                    formik.values.postcode = '';
                    setTimeout(() => {
                      checkNewCardAdded = false;
                    }, 1000);
                    props.setChoosenCardId(response.data.id);
                    props.setCheckCard(true);
                  }
                })
                .catch(error => {
                  if (error) {
                    let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
                    if (errorResponseGlobalErrors) {
                      if (errorResponseGlobalErrors.length > 0) {
                        errorResponseGlobalErrors.forEach(msg => {
                          console.log('error global response message ', msg);
                          Toast(msg.message, 'error');
                        });
                      }
                    }
                  }
                });
            }
          } else {
            if (!stripe || !elements) {
              // Stripe.js has not loaded yet. Make sure to disable
              // form submission until Stripe.js has loaded.
              Toast('Please add card details', 'error');
              return;
            }

            // Block native form submission.
            event.preventDefault();

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardNumberElement);

            // Use your card Element with other Stripe.js APIs
            const { error, token } = await stripe.createToken(cardElement);

            if (error) {
              Toast('Please add the valid card details', 'error');
            } else if (formik.values.postcode === null) {
              Toast('Please add a postcode', 'error');
            } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
              Toast('Please add a valid postcode', 'error');
            } else {
              let tokenId = token.id;
              let data = {
                token: tokenId,
                setAsDefault: false,
                region: 'AU',
                postcode: formik.values.postcode,
              };

              paymentService
                .addNewCard(data)
                .then(response => {
                  if (response) {
                    Toast('New card added successfully', 'success');
                    props?.loadExistingList()
                    formik.values.postcode = '';
                    setTimeout(() => {
                      checkNewCardAdded = false;
                    }, 1000);
                    props.setChoosenCardId(response.data.id);
                    props.setCheckCard(true);
                  }
                })
                .catch(error => {
                  if (error) {
                    let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
                    if (errorResponseGlobalErrors) {
                      if (errorResponseGlobalErrors.length > 0) {
                        errorResponseGlobalErrors.forEach(msg => {
                          console.log('error global response message ', msg);
                          Toast(msg.message, 'error');
                        });
                      }
                    }
                  }
                });
            }
          }
        } else {
          Toast('Please authenticate email with OTP first', 'error');
          props.showUploadModalHandler();
        }
      } else {
        if (props.senderEmail && props.userName) {
          if (checkNewCardAdded === false) {
            checkNewCardAdded = true;

            if (!stripe || !elements) {
              // Stripe.js has not loaded yet. Make sure to disable
              // form submission until Stripe.js has loaded.
              Toast('Please add card details', 'error');
              return;
            }

            // Block native form submission.
            event.preventDefault();

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardNumberElement);

            // Use your card Element with other Stripe.js APIs
            const { error, token } = await stripe.createToken(cardElement);

            if (error) {
              Toast('Please add the valid card details', 'error');
            } else if (formik.values.postcode === null) {
              Toast('Please add a postcode', 'error');
            } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
              Toast('Please add a valid postcode', 'error');
            } else {
              let tokenId = token.id;
              let data = {
                token: tokenId,
                setAsDefault: false,
                region: 'AU',
                postcode: formik.values.postcode,
              };

              paymentService
                .addNewCard(data)
                .then(response => {
                  if (response) {
                    Toast('New card added successfully', 'success');
                    props?.loadExistingList()

                    formik.values.postcode = '';
                    setTimeout(() => {
                      checkNewCardAdded = false;
                    }, 1000);
                    props.setChoosenCardId(response.data.id);
                    props.setCheckCard(true);
                  }
                })
                .catch(error => {

                  if (error) {
                    let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
                    if (errorResponseGlobalErrors) {
                      if (errorResponseGlobalErrors.length > 0) {
                        errorResponseGlobalErrors.forEach(msg => {
                          console.log('error global response message ', msg);
                          Toast(msg.message, 'error');
                        });
                      }
                    }
                  }
                });
            }
          } else {
            if (!stripe || !elements) {
              // Stripe.js has not loaded yet. Make sure to disable
              // form submission until Stripe.js has loaded.
              Toast('Please add card details', 'error');
              return;
            }

            // Block native form submission.
            event.preventDefault();

            // Get a reference to a mounted CardElement. Elements knows how
            // to find your CardElement because there can only ever be one of
            // each type of element.
            const cardElement = elements.getElement(CardNumberElement);

            // Use your card Element with other Stripe.js APIs
            const { error, token } = await stripe.createToken(cardElement);

            if (error) {
              Toast('Please add the valid card details', 'error');
            } else if (formik.values.postcode === null) {
              Toast('Please add a postcode', 'error');
            } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
              Toast('Please add a valid postcode', 'error');
            } else {
              let tokenId = token.id;
              let data = {
                token: tokenId,
                setAsDefault: false,
                region: 'AU',
                postcode: formik.values.postcode,
              };


              paymentService
                .addNewCard(data)
                .then(response => {
                  if (response) {
                    Toast('New card added successfully', 'success');
                    props?.loadExistingList()

                    formik.values.postcode = '';
                    setTimeout(() => {
                      checkNewCardAdded = false;
                    }, 1000);
                    props.setChoosenCardId(response.data.id);
                    props.setCheckCard(true);
                  }
                })
                .catch(error => {

                  if (error) {
                    let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
                    if (errorResponseGlobalErrors) {
                      if (errorResponseGlobalErrors.length > 0) {
                        errorResponseGlobalErrors.forEach(msg => {
                          console.log('error global response message ', msg);
                          Toast(msg.message, 'error');
                        });
                      }
                    }
                  }
                });
            }
          }
        } else {
          // Toast('Please authenticate email with OTP first', 'error');
          // props.showUploadModalHandler();
          Toast('Please add email address or user name', 'error');
        }
      }

      // if (props.registeredUserloggedIn) {
      //   console.log('Regitered User login===>', props.registeredUserloggedIn);
      //   // if (!props.registeredUserloggedIn) {
      //   if (props.senderEmail && props.userName) {
      //     if (checkNewCardAdded === false) {
      //       checkNewCardAdded = true;

      //       if (!stripe || !elements) {
      //         // Stripe.js has not loaded yet. Make sure to disable
      //         // form submission until Stripe.js has loaded.
      //         Toast('Please add card details', 'error');
      //         return;
      //       }

      //       // Block native form submission.
      //       event.preventDefault();

      //       // Get a reference to a mounted CardElement. Elements knows how
      //       // to find your CardElement because there can only ever be one of
      //       // each type of element.
      //       const cardElement = elements.getElement(CardNumberElement);

      //       // Use your card Element with other Stripe.js APIs
      //       const { error, token } = await stripe.createToken(cardElement);

      //       if (error) {
      //         Toast('Please add the valid card details', 'error');
      //       } else if (formik.values.postcode === null) {
      //         Toast('Please add a postcode', 'error');
      //       } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
      //         Toast('Please add a valid postcode', 'error');
      //       } else {
      //         let tokenId = token.id;
      //         let data = {
      //           token: tokenId,
      //           setAsDefault: false,
      //           region: 'AU',
      //           postcode: formik.values.postcode,
      //         };
      //         // props.setCardDetails(data)

      //         paymentService
      //           .addNewCard(data)
      //           .then(response => {
      //             if (response) {
      //               Toast('New card added successfully', 'success');
      //               formik.values.postcode = '';
      //               setTimeout(() => {
      //                 // props.loadExistingList();
      //                 // props.hideModal();
      //                 checkNewCardAdded = false;
      //               }, 1000);
      //               props.setChoosenCardId(response.data.id);
      //               props.setCheckCard(true);
      //             }
      //           })
      //           .catch(error => {
      //             if (error) {
      //               let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
      //               if (errorResponseGlobalErrors) {
      //                 if (errorResponseGlobalErrors.length > 0) {
      //                   errorResponseGlobalErrors.forEach(msg => {
      //                     console.log('error global response message ', msg);
      //                     Toast(msg.message, 'error');
      //                   });
      //                 }
      //               }
      //             }
      //           });
      //       }
      //     }
      //   } else {
      //     // Toast('Please authenticate email with OTP first', 'error');
      //     // props.showUploadModalHandler();
      //     Toast('Please add email address or user name', 'error');
      //   }
      // } else {
      //   // Toast('Please add email address or user name', 'error');
      //   Toast('Please authenticate email with OTP first', 'error');
      //   props.showUploadModalHandler();
      // }
    } else {
      if (checkNewCardAdded === false) {
        checkNewCardAdded = true;
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          Toast('Please add card details', 'error');
          return;
        }

        // Block native form submission.
        event.preventDefault();

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement);

        // Use your card Element with other Stripe.js APIs
        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
          Toast('Please add the valid card details', 'error');
        } else if (formik.values.postcode === null) {
          Toast('Please add a postcode', 'error');
        } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
          Toast('Please add a valid postcode', 'error');
        } else {
          let tokenId = token.id;
          let data = {
            token: tokenId,
            setAsDefault: false,
            region: 'AU',
            postcode: formik.values.postcode,
          };
          if(props.asCompany){
            data.asCompany=true
          }
          paymentService
            .addNewCard(data)
            .then(response => {
              if (response) {
                Toast('New card added successfully', 'success');
                props?.loadExistingList()

                formik.values.postcode = '';
                setTimeout(() => {
                  props.loadExistingList();
                  checkNewCardAdded = false;
                }, 1000);
                props.setChoosenCardId(response.data.id);
                props.setCheckCard(true);
              }
            })
            .catch(error => {
              if (error) {
                // let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
                let errorResponseGlobalErrors = error?.response;
                if (errorResponseGlobalErrors) {
                  if (errorResponseGlobalErrors.length > 0) {
                    errorResponseGlobalErrors.forEach(msg => {
                      console.log('error global response message ', msg);
                      Toast(msg.message, 'error');
                    });
                  }
                }
              }
            });
        }
      } else {
        if (!stripe || !elements) {
          // Stripe.js has not loaded yet. Make sure to disable
          // form submission until Stripe.js has loaded.
          Toast('Please add card details', 'error');
          return;
        }

        // Block native form submission.
        event.preventDefault();

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement);

        // Use your card Element with other Stripe.js APIs
        const { error, token } = await stripe.createToken(cardElement);

        if (error) {
          Toast('Please add the valid card details', 'error');
        } else if (formik.values.postcode === null) {
          Toast('Please add a postcode', 'error');
        } else if (!formik.values.postcode.match(RegexPatterns.PostCode)) {
          Toast('Please add a valid postcode', 'error');
        } else {
          let tokenId = token.id;
          let data = {
            token: tokenId,
            setAsDefault: false,
            region: 'AU',
            postcode: formik.values.postcode,
          };
          if(props.asCompany){
            data.asCompany=true
          }
          paymentService
            .addNewCard(data)
            .then(response => {
              if (response) {
                Toast('New card added successfully', 'success');
                props?.loadExistingList()

                formik.values.postcode = '';
                setTimeout(() => {
                  props.loadExistingList();
                  checkNewCardAdded = false;
                }, 1000);
                props.setChoosenCardId(response.data.id);
                props.setCheckCard(true);
              }
            })
            .catch(error => {
              if (error) {
                // let errorResponseGlobalErrors = error?.response['data']?.globalErrors;
                let errorResponseGlobalErrors = error?.response;
                if (errorResponseGlobalErrors) {
                  if (errorResponseGlobalErrors.length > 0) {
                    errorResponseGlobalErrors.forEach(msg => {
                      console.log('error global response message ', msg);
                      Toast(msg.message, 'error');
                    });
                  }
                }
              }
            });
        }
      }
    }
  };

  const handleChange = e => {
    if (e.elementType === 'cardNumber') {
      setCardElementError(e?.error?.message);
    } else if (e.elementType === 'cardNumber' && e.complete === true) {
      setCardElementError('');
    }

    if (e.elementType === 'cardExpiry') {
      setCardExpiryError(e?.error?.message);
    } else if (e.elementType === 'cardExpiry' && e.complete === true) {
      setCardExpiryError('');
    }

    if (e.elementType === 'cardCvc') {
      setCardCVCError(e?.error?.message);
    } else if (e.elementType === 'cardCvc' && e.complete === true) {
      setCardCVCError('');
    }
  };
  const validate = values => {
    const errors = {};

    if (values.postcode === null) {
      errors.postcode = 'Please add a postcode';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    validate,
  });

  return (
    <div>
      <div className="mt-3">
        <span className="monteserrat-normal font-16 light-grey mb-2 padding-left">Card Number</span>
        <CardNumberElement className="anon-payment-option__card" onChange={handleChange} />
        <span className="font-12 color-charcoal font-sofia-pro-regular">{cardElemetError}</span>
      </div>

      <div className="p-0 m-0 row mt-4 align-items-baseline justify-content-between">
        <div className="col-sm-8 col-md-8 col-lg-8 pl-0">
          <span className="monteserrat-normal font-16 light-grey padding-left">Expiry month / Year</span>
          <CardExpiryElement className="anon-payment-option__card" onChange={handleChange} />
          <span className="font-12 color-charcoal font-sofia-pro-regular">{cardExpiryError}</span>
        </div>
        <div className="p-0 pr-1 col-sm-4 col-md-4 col-lg-4">
          <div>
            <span className="monteserrat-normal font-16 light-grey padding-left">CVV</span>
            <CardCvcElement className="anon-payment-option__card" onChange={handleChange} />
            <span className="font-12 color-charcoal font-sofia-pro-regular">{cardCVCError}</span>
          </div>
        </div>
      </div>
      <div className="mt-4 d-flex flex-column">
        <span className="monteserrat-normal font-16 light-grey padding-left">Postcode</span>
        <input
          className="anon-payment-option__card"
          placeholder="Eg: 2122"
          name="postcode"
          type="text"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.postcode}
        />
        {formik.errors.postcode ? (
          <span className="font-12 color-charcoal font-sofia-pro-regular">{formik.errors.postcode}</span>
        ) : null}
      </div>
      <div className="my-2">
        <button
          className="w-150 btn bg-primary-color color-white h-44 font-sofia-pro-semi-bold font-16 outline-none"
          type="button"
          onClick={saveCardDetails}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddCardForms;
