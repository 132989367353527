/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// common schema for send thanks
export const sendThanksData = {
  priceId: '',
  quantity: 1,
  amount: 0,
  paymentSource: {
    token: '',
    cardid: '',
    setCardAsDefault: false,
  },
  recipients: [],
  data: {
    guid: '',
    externalId: '',
    channel: '',
    redeemUrl: '',
    delivery: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      address: {
        street: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
      },
    },
  },
  recipientCsvFileBase64: null,
};

export const recipientData = {
  email: '',
  userName: '',
  message: {
    type: null,
    message: null,
    storedFile: null,
    storedFileSecret: null,
  },
  firstName: '',
  lastName: '',
  phone: '',
};

export const message = {
  type: null,
  message: null,
  storedFile: null,
  storedFileSecret: null,
};

export default {
  sendThanksData,
  recipientData,
  message,
};
