import React, { useState } from 'react';
import RecordAudio from '../../common/RecordAudio/RecordAudio';
import RecordVideo from '../../common/RecordVideo/RecordVideo';
import RecipientsForm from './RecipientsForm';
import VideoDropzone from '../../common/Upload/VideoDropzone';
import { ReactComponent as DeleteClose } from '../../assets/images/dashboard/icons/icons-close-pink.svg';
import ButtonLoading from '../../common/Button/Button';
import { ReactComponent as FolderIcon } from '../../assets/images/common/icons-folder.svg';
import { ReactComponent as Record } from '../../assets/images/common/record-icon.svg';
import { ReactComponent as ProfilePic } from '../../assets/images/common/user-profile.svg';
import ReactTooltip from 'react-tooltip';
import useWindowDimensions from '../../common/Responsive/WindowDimensions';
import { toast } from 'react-toastify';

const RecipientInfo = ({
  videoResponse,
  audioResponse,
  toggleVideoModal,
  toggleAudioModal,
  videoModel,
  videoModalRef,
  audioModel,
  audioModalRef,
  selectVideo,
  selectAudio,
  formik,
  setFileData,
  fileName,
  setFileName,
  tags,
  setTags,
  proceedBtnHandler,
  cancelBtnHandler,
  remainingChars,
  uploadBtnHandler,
  getusersListFile,
  showLoader,
  csvEnabled,
  txtMsg,
  messageButtonHandler,
  textMessageCancelHandler,
  videoFile,
  usersList,
  AvailableBalanceComponent
}) => {
  const { width } = useWindowDimensions();
  const handleAcceptedFiles = files => {
    const file = files[0];
    // console.log("---fileData---", file);
    // formik.setFieldValue('message.type', "video");
    // formik.setFieldValue('message.storedFile', file.name);
    setFileData(file.data);
    setFileName(file.name);
  };

  const handleRejectedFiles = files => {
    const file = files[0];
    setFileData(null);
    setFileName(null);
  };

  const hideFileHandler = () => {
    setFileName(null);
  };

  console.log("===mes===", formik.values.messageValue);
  const userData = JSON.parse(localStorage.getItem("userData"))

  return (
    <>
      <div className="d-flex flex-column position-relative">
        <span className="font-sofia-pro-regular font-36 color-black-pearl text-center">Enter recipient info</span>
      </div>
      <div className="mt-3 mb-5 d-flex justify-content-center align-items-start flex-wrap" style={{background: '#f8f9fa'}}>
        <div className="mb-5 mr-3 d-flex coffee-box-style justify-content-center">
          <div className="d-flex flex-column align-items-start flex-wrap w-100">
            <form className="mt-3 w-100" onSubmit={formik.handleSubmit}>
              <div class="mb-3">
                <RecipientsForm tags={tags} onChange={setTags} type={'Anonymous'} csvEnabled={csvEnabled}></RecipientsForm>
              </div>
              {!txtMsg ? (
                <>
                  <div className="p-0 b-0 pb-1 form-group d-flex justify-content-between align-items-center">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Message</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="record-box d-flex justify-content-center align-items-center cursor-pointer"
                        onClick={toggleVideoModal}
                      >
                        <Record />
                        <div className="font-sofia-pro-regular font-14" style={{ marginLeft: '1rem' }}>
                          Record
                        </div>
                      </div>
                      <div
                        className="txt-box d-flex justify-content-center align-items-center font-sofia-pro color-primary font-14 cursor-pointer"
                        onClick={() => messageButtonHandler()}
                      >
                        Switch to text
                      </div>
                    </div>
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                    <div className="w-100 d-flex flex-column justify-content-center align-items-center upload-box position-relative">
                      {fileName === null && (
                        <VideoDropzone
                          rejectedFiles={handleRejectedFiles}
                          acceptedFiles={handleAcceptedFiles}
                          multiple={false}
                          getusersListFile={getusersListFile}
                        />
                      )}
                      {fileName !== null && (
                        <>
                          <DeleteClose onClick={hideFileHandler} className="cursor-pointer close-delete" />
                          <FolderIcon />
                          <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                            <u>{fileName}</u>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="p-0 b-0 pb-1 form-group d-flex justify-content-between align-items-center">
                    <label className="font-sofia-pro-regular color-charcoal font-16">Message</label>
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="txt-box d-flex justify-content-center align-items-center font-sofia-pro color-primary font-14 cursor-pointer"
                        onClick={() => textMessageCancelHandler()}
                      >
                        Switch to video
                      </div>
                    </div>
                  </div>
                  <div className="p-0 b-0 pb-1 form-group">
                    <div className="w-100 position-relative txtMsg-box">
                      <textarea
                        className="full-width-textarea"
                        cols="10"
                        rows="5"
                        name="messageValue"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.messageValue}
                        placeholder="Enter your message"
                        maxLength={100}
                      ></textarea>
                      <div className="char-count">{remainingChars}/100</div>
                    </div>
                  </div>
                </>
              )}

              {/* <div className="p-0 b-0 pb-1 d-flex justify-content-start">
                    <p className='font-sofia-pro-regular font-14 color-gunmetal'>Recipients {formik.values.recipients.map((recipient, index) => (!more &&  index < 2 ?`,${recipient.employee}`: `,${recipient.employee}`))}</p>
                    {formik.values.recipients.length > 2 ? <p className='font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={() => {showMore()}}>+ {formik.values.recipients.length - 2} more</p>: null}
                </div> */}
              <div className="d-flex justify-content-between w-100">
                <ButtonLoading
                  classNameValue="twoBrand-back-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn font-16 font-sofia-pro-semi-bold"
                  children="Back"
                  showLoader={false}
                  onClick={cancelBtnHandler}
                  // onClick={handleMsgBack}
                />
                <ButtonLoading
                  classNameValue="twoBrand-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                  children="Next"
                  showLoader={showLoader}
                  onClick={() => {
                    console.log("===vDa===", videoFile);
                    if(videoFile) {
                        console.log("===vDa1===", videoFile);
                        uploadBtnHandler();
                    } else {
                        console.log("===vDa2===", videoFile);
                        proceedBtnHandler();
                    }
                  }}
                />
              </div>
            </form>
            <RecordAudio
              ref={audioModalRef}
              selectAudio={selectAudio}
              audioModel={audioModel}
              toggleAudioModal={toggleAudioModal}
              toggleVideoModal={toggleVideoModal}
              audioResponse={audioResponse}
            />
            <RecordVideo
              ref={videoModalRef}
              selectVideo={selectVideo}
              videoModel={videoModel}
              toggleAudioModal={toggleAudioModal}
              toggleVideoModal={toggleVideoModal}
              videoResponse={videoResponse}
            />
          </div>
        </div>
        {AvailableBalanceComponent && width <= 991 && (
            <AvailableBalanceComponent
              card={'w-150 mr-4'}
              className="font-9 dashboard-mobile__dashboardData mx-2"
              classVariable={'mx-2 my-3'}
            />
          )}
        {usersList ? (<div className='ml-3 mb-5' >
          <div className="color-black-pearl font-24 monteserrat-bold pt-4 pb-2">Team</div>
          <div className="card border-0 p-2">
            {usersList.map((user, index) => {
              return (
                userData.email !== user.email &&

                <tr key={index}>
                  <td
                    className=" font-sofia-pro-regular font-14 color-black-pearl py-3 cursor-pointer"
                    onClick={() => {
                      if (!tags.includes(user?.email)) {
                        setTags([...tags, user?.email]);
                      } else {
                        toast.error("This email is already added!");
                      }
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        {user['profilePhotoPath'] === null ? (
                          <ProfilePic className="rounded-circle dashboard_oval" />
                        ) : (
                          <img
                            src={user['profilePhotoPath'] + '?h=80&w=80'}
                            className="rounded-circle dashboard_oval"
                            alt=""
                          />
                        )}
                        <div className="pl-2 d-flex flex-column">
                          <span
                            data-tip={`${user.firstName}{user.lastName}`}
                            data-for="userFirstname"
                            className="d-block"
                          >
                            {user.firstName} {user.lastName}{' '}
                          </span>

                          <span
                            data-tip={user.email}
                            data-for="userEmail"
                            className="d-block user__text__ellipsis color-grey-blue font-sofia-pro-light font-12"
                          >
                            {user.email}
                          </span>
                          {user?.email?.length > 10 && <ReactTooltip id="userEmail" />}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </div>
        </div>): null}
      </div>
    </>
  );
};

export default RecipientInfo;
