/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import { useFormik } from 'formik';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ButtonLoading from '../../../../common/Button/Button';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MikeIconWhite, PlayIcon, SendVideoIcon } from '../../../../assets/images';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import { ReactComponent as FolderIcon } from '../../../../assets/images/common/icons-folder.svg';
import UploadImage from '../../../../assets/images/common/upload.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as AudioIconInactive } from '../../../../assets/images/send_thanks/audio-icon-inactive.svg';
import { ReactComponent as AudioIcon } from '../../../../assets/images/send_thanks/ant-design_audio-outlined.svg';
import { ReactComponent as MessageIcon } from '../../../../assets/images/send_thanks/record_video.svg';
import RecordVideoIcon from '../../../../assets/images/send_thanks/record_video.png';
import { ReactComponent as VideoIcon } from '../../../../assets/images/send_thanks/ri_video-upload-line.svg';
import { ReactComponent as VideoIconActive } from '../../../../assets/images/send_thanks/ri_folder-upload-line.svg';
import Header from '../../../../common/Header/Header';
import RecordAudio from '../../../../common/RecordAudio/RecordAudio';
import RecordVideo from '../../../../common/RecordVideo/RecordVideo';
import SideBar from '../../../../common/Sidebar/Sidebar';
import dashboardService from '../../../../services/DashboardService';
import http from '../../../../services/HttpService';
import { resetSendThanksDetails, sendThanksDetails } from '../../../../store/reducers/individual/sendThanks/sendThanks';
import * as RegexPatterns from '../../../../utils/RegexPatterns';
import sendThanksSchema, * as SendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import RecipientsForm from '../../../common/RecipientsForm';
import './AnonPayment.scss';
import useWindowDimensions from '../../../../common/Responsive/WindowDimensions';
import { productDetailsDispatch } from '../../../../store/reducers/multiProduct/anonMultiProducts';
import AnonymousLayout from '../../../../common/component/anonymouslayout/AnonymousLayout';
import { ReactComponent as Step1 } from '../../../../assets/images/dashboard/icons/step-3.svg';
// STRIPE
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import AddCardForms from '../../../../common/component/modal/AddCardForms';
import ReviewPurchase from '../../../individual/sendThanks/reviewThanks/ReviewPurchase';
import AnonReviewPurchase from '../anonreviewthanks/AnonReviewPurchase';
import OTPInput from '../../../../common/OTP/OTPInput';
import OtpPage from '../../../individual/onboarding/stepper/otp/Otp';
import AnonymousOTPModal from '../../../../common/component/modal/AnonymousOTPModal';
import paymentService from '../../../../services/PaymentService';
import Toast from '../../../../services/ToasterService';
import Moment from 'react-moment';
import IconMiscCard from '../../../../assets/images/common/icons-misc-card.png';
import UsernameSuccessImage from '../../../../assets/images/common/icons-check-filled-green.png';
import GoolePay from '../../../../assets/images/common/google-pay-mark-800.png';
import ApplePay from '../../../individual/sendThanks/paymentOptions/ApplePay';
import CheckoutForm from '../../../individual/sendThanks/paymentOptions/CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

const initialValues = {
  messageValue: null,
};

const validate = values => {
  let errors = {};

  if (values?.messageValue?.length > 100) {
    errors.messageValue = 'Characters length should not exceed 100';
  }

  return errors;
};

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AnonPayment = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(0);
  let userData = JSON.parse(localStorage.getItem('userData'));
  let anonUserData = JSON.parse(localStorage.getItem('anonUserData'));
  let [choosenCardId, setChoosenCardId] = useState();

  const [loadingUploadBtn, setLoadingUploadBtn] = useState(false);
  const [tags, setTags] = useState([]);

  const [showMessageBox, setshowMessageBox] = useState(false);

  const [messageValue, setMessageValue] = useState('');

  const [nextPathValue, setNextPathValue] = useState();

  const [audioModel, setAudioModel] = useState(false);

  const [videoModel, setVideoModel] = useState(false);

  const [messageType, setMessageType] = useState(1);

  const audioModalRef = useRef(null);

  const videoModalRef = useRef(null);

  const [availableBalance, setAvailableBalance] = useState();

  const [audioData, setAudioData] = useState();

  const [videoData, setVideoData] = useState();

  const [existingMediaData, setExistingMediaData] = useState();

  const [getUserData, setGetUserData] = useState([]);

  let UserDataCombined = [];

  const [fileName, setFileName] = useState(null);

  const [videoFile, setVideoFile] = useState(null);

  const [thumpFile, setThumpFile] = useState(null);

  const [thumpFileName, setThumpFileName] = useState(null);

  const [fileData, setFileData] = useState(null);

  const [audioFile, setAudioFile] = useState(null);

  const [mediaModal, setShowMediaModal] = useState(false);

  const showMediaModalHandler = () => setShowMediaModal(true);
  const hideMediaModalHandler = () => setShowMediaModal(false);

  const [uploadModal, setShowUploadModal] = useState(false);

  const showUploadModalHandler = () => setShowUploadModal(true);
  const hideUploadModalHandler = () => setShowUploadModal(false);

  const [showUploadVideoPlayModal, setShowUploadVideoPlayModal] = useState(false);

  const showUploadVideoPlayModalHandler = () => setShowUploadVideoPlayModal(true);
  const hideUploadVideoPlayModalHandler = () => setShowUploadVideoPlayModal(false);

  const [checkVideoIsUploaded, setCheckVideoIsUploaded] = useState(false);
  const [cardDetails, setCardDetails] = useState();
  const [checkCard, setCheckCard] = useState(false);
  const [senderEmail, setSenderEmail] = useState(userData ? userData?.email : anonUserData ? anonUserData.email : null);
  const [senderUserName, setSenderUserName] = useState(
    userData ? userData?.fullName : anonUserData ? anonUserData.fullName : null
  );
  const [userStatus, setUserStatus] = useState(
    userData ? userData?.status : anonUserData ? anonUserData?.status : null
  );
  const [savedCardsList, setSavedCardsList] = useState([]);
  const [defaultCardList, setDefaultCardList] = useState([]);
  const [googlepaySelect, setGooglepaySelect] = useState(false);
  const [selectedCardDetails, setSelectedCardsDetails] = useState();
  const [selectedCardIndex, setSelectedCardIndex] = useState();
  const [cardLastNumber, setCardLastNumber] = useState();
  const [cardExpiryDate, setCardExpiryDate] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cardBrand, setCardBrand] = useState();
  const [registeredUserloggedIn, setRegisteredUserLoggedIn] = useState(false);
  const [checkingStatusOfUser, setCheckingStatusOfUser] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [clientSecrets, setClientSecrets] = useState();
  console.log('Secret CLient===> in anon', clientSecrets);

  const paymentInformation = props.getPaymentInformation;
  let tag, queryUser;

  let currentPath = usePathname();
  let query = useQuery();
  const { width } = useWindowDimensions();

  const { productsCard, details } = useSelector(state => state.anonMultiProduct);
  let priceId = productsCard?.prices ? productsCard?.prices[0]?.priceId : productsCard.priceId;
  let checkCardRemoved = false;

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
  }

  const handleEmailValidation = value => {
    // var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var validRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (value.match(validRegex)) {
      setSenderEmail(value);
    } else {
      toast.error('Please enter a valid email');
    }
    if (senderUserName && senderEmail) {
      handleAnonByEmail(senderUserName);
    }
  };
  const handleUserName = fullname => {
    if (fullname == '') {
      toast.error('Please enter a user name');
    } else {
      const nameArr = fullname.split(' ');
      setSenderUserName(fullname);
      handleAnonByEmail(nameArr);
    }
  };

  const handleAnonByEmail = value => {
    const values = {
      firstName: value[0],
      lastName: value[1] ? value[1] : null,
      email: senderEmail,
      password: uuidv4(),
      acceptTermsConditions: true,
      isanonymous: true,
    };
    const data = values;
    if (senderEmail) {
      http
        .post('/users/byemail', data)
        .then(res => {
          userAnonLogin(data);
        })
        .catch(error => {
          // window.alert("This email is already registered, Please Sign in to send gifts")
          console.log('error in api', error);
          // toast.error('This email is already registered, Please Sign in to send gifts or change the email');
          localStorage.removeItem('anonUserData');
          if (!userData || anonUserData) {
            setSavedCardsList([]);
            showUploadModalHandler();
          }
          if (senderEmail != userData?.email) {
            setSavedCardsList([]);
            showUploadModalHandler();
            axios.defaults.headers.common['userKey'] = '';
          }
          setCheckingStatusOfUser(true);
        });
    }
  };

  const userAnonLogin = userData => {
    const userCred = { email: userData.email, password: userData.password };
    http
      .post('/usersessions/byemail', userCred)
      .then(res => {
        let responseData = res['data'];
        setUserStatus(responseData.status);
        localStorage.removeItem('userData');
        localStorage.setItem('anonUserData', JSON.stringify(responseData));
        axios.defaults.headers.common['userKey'] = responseData.userKey;
      })
      .catch(error => {
        console.log('error in api ', error);
      });
  };
  useEffect(() => {
    window.onbeforeunload = function (event) {
      return true;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const messageButtonHandler = () => {
    setshowMessageBox(!showMessageBox);
    setMessageType(1);
  };

  // Text message handler

  const textMessageCancelHandler = () => {
    setMessageType(1);

    setExistingMediaData(undefined);

    setshowMessageBox(!showMessageBox);

    formik.setFieldValue('messageValue', undefined);

    props.recipientInformation(SendThanksSchema.sendThanksData);
  };

  // Adding recipient tag
  const SearchTagSet = tag => {
    if (tag !== undefined) {
      if (!tag.match(RegexPatterns.AusMobileRegexValue)) {
        toast.error('Please enter a valid mobile number');
        return;
      } else {
        if (RegexPatterns.EmailRegexValue.test(tag) === false && typeof tag === 'string') {
          setTags([tag]);
        } else if (!tag.match(RegexPatterns.EmailRegexValue)) {
          toast.error('Please enter a valid email');
          return;
        }
      }
      setTags([tag]);
    }
  };

  const proceedBtnHandler = () => {
    toast.dismiss();
    if (tags.length > 0) {
      let data = [];
      let recipient = SendThanksSchema.recipientData;

      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';
          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';
          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';
          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue?.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      if (nextPathValue !== undefined && nextPathValue !== '') {
        const paymentInformation = props.getRecipientInformation;
        let product = JSON.parse(sessionStorage.getItem('productDetails'));

        // props.history.push({
        //   pathname: nextPathValue,
        //   state: {
        //     choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
        //     productDetails: SendThanksSchema.sendThanksData,
        //     productType: product.productType,
        //     priceId: product.priceId,
        //     amount: paymentInformation.amount,
        //   },
        // });
        props.recipientInformation(SendThanksSchema.sendThanksData);
      } else {
        props.recipientInformation(SendThanksSchema.sendThanksData);
        // props.history.push('/selectItem');
        // history.push('/anonthankssuccess');
      }
      dispatch(
        productDetailsDispatch({
          recipients: data,
          message: null,
        })
      );
    } else if (tags.length === 0) {
      toast.error('Please add recipient');
    }
  };

  const cancelBtnHandler = e => {
    // e.preventDefault();
    // props.resetData();
    // props.history.push({
    //   pathname: '/dashboard',
    // });
    history.push('/anonrecipientInformation');
  };

  // Load existing data
  const loadExistingRecipientData = () => {
    if (props.getRecipientInformation) {
      let recipients = props.getRecipientInformation.recipients;
      let message = props.getRecipientInformation;
      if (recipients?.length > 0) {
        let data = [];
        recipients?.forEach((recipient, index) => {
          if (
            recipient?.phone !== '' &&
            recipient?.phone !== null &&
            recipient?.phone.match(RegexPatterns.MobileRegexValue)
          ) {
            data.push(recipient.phone);
          } else if (
            recipient?.email !== '' &&
            recipient?.email !== null &&
            recipient?.email.match(RegexPatterns.EmailRegexValue)
          ) {
            data.push(recipient.email);
          } else if (
            recipient?.userName !== '' &&
            recipient?.userName !== null &&
            typeof recipient?.userName === 'string'
          ) {
            data.push(recipient.userName);
          }

          setTags(data);
        });

        if (message.message !== '' && message.message !== undefined) {
          if (message.message.message !== null) {
            formik.setFieldValue('messageValue', message.message.message);
            setshowMessageBox(true);
          } else if (message.message.message === null && message.message.storedFile !== null) {
            setshowMessageBox(false);
            getMediaData(message.message.storedFile);
          }
        }
      } else {
        formik.resetForm();
      }
    } else {
      formik.resetForm();
      formik.setFieldValue('messageValue', undefined);
    }
  };

  const toggleAudioModal = useCallback(event => {
    setAudioModel(!audioModel);

    if (audioModel === false) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {})
        .catch(err => {
          toast.error('Please enable microphone access');
        });
    }
  });

  const toggleUploadModal = () => {
    setMessageType(4);
    showUploadModalHandler();
  };

  const selectAudio = useCallback(() => {
    setshowMessageBox(true);
    setAudioModel(false);
  });

  const playAudio = () => {
    new Promise((resolve, reject) => {
      setAudioModel(true);
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      if (audioModalRef.current.recordState === 'stopped') {
        audioModalRef.current.selectAudioPlayer();
      }
    });
  };

  const selectVideo = useCallback(() => {
    setshowMessageBox(true);
    setVideoModel(false);
  });

  const playVideo = () => {
    new Promise((resolve, reject) => {
      setVideoModel(true);
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      if (videoModalRef.current.recordState === 'stopped') {
        videoModalRef.current.selectVideoPlayer();
      }
    });
  };

  const toggleVideoModal = useCallback(event => {
    setVideoModel(!videoModel);

    if (videoModel === false) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(() => {})
        .catch(err => {
          toast.error('Please enable microphone and camera access');
        });
    }
  });

  const getDashboardData = () => {
    dashboardService
      .getDashboardData()
      .then(response => {
        if (response) {
          let data = response['data'];
          setAvailableBalance(data?.thanksAvailable);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const audioResponse = data => {
    setMessageType(3);
    formik.setFieldValue('messageValue', null);
    setVideoData(undefined);
    if (data !== undefined && data !== null) {
      setVideoData(undefined);
      setAudioData(data);
    }
  };

  const videoResponse = data => {
    setMessageType(2);
    formik.setFieldValue('messageValue', null);
    setAudioData(undefined);
    if (data !== undefined && data !== null) {
      setVideoData(data);
      setAudioData(undefined);
    }
  };

  // Getting Media
  const getMediaData = id => {
    http
      .get(`/StoredFiles/${id}`)
      .then(response => {
        if (response) {
          let data = response['data'];
          if (data?.fileCategory === 'Audio') {
            setExistingMediaData(data);
            setAudioData(data);
          } else if (data?.fileCategory === 'Video') {
            setExistingMediaData(data);
            setVideoData(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  // Resetting existing audio data
  const closeAudioFile = () => {
    sendThanksSchema.message.type = null;
    sendThanksSchema.message.storedFile = null;
    sendThanksSchema.message.storedFileSecret = null;
    sendThanksSchema.message.message = null;
    setMessageType(1);
    setExistingMediaData(undefined);
    setAudioData(undefined);
    setVideoData(undefined);
    formik.setFieldValue('messageValue', undefined);
    formik.values.messageValue = null;
    formik.resetForm();
  };

  // Resetting existing video data
  const closeVideoFile = () => {
    sendThanksSchema.message.type = null;
    sendThanksSchema.message.storedFile = null;
    sendThanksSchema.message.storedFileSecret = null;
    sendThanksSchema.message.message = null;
    setMessageType(1);
    setExistingMediaData(undefined);
    setAudioData(undefined);
    setVideoData(undefined);
    formik.setFieldValue('messageValue', undefined);
    formik.values.messageValue = null;
    formik.resetForm();
  };

  const onSubmit = values => {
    if (
      (tags.length > 0 && formik.values.messageValue !== undefined && formik.values.messageValue !== '') ||
      audioData !== undefined ||
      videoData !== undefined
    ) {
      setMessageValue(formik.values.messageValue);
      let data = [];
      let recipient = SendThanksSchema.recipientData;
      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';

          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';

          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';

          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue?.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      if (showMessageBox === true || audioData !== undefined || videoData !== undefined) {
        if (nextPathValue !== undefined && nextPathValue !== '') {
          const paymentInformation = props.getRecipientInformation;
          let product = JSON.parse(sessionStorage.getItem('productDetails'));

          // props.history.push({
          //   pathname: nextPathValue,
          //   state: {
          //     choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
          //     productDetails: SendThanksSchema.sendThanksData,
          //     productType: product.productType,
          //     priceId: product.priceId,
          //     amount: paymentInformation.amount,
          //   },
          // });
          props.recipientInformation(SendThanksSchema.sendThanksData);
        } else {
          props.recipientInformation(SendThanksSchema.sendThanksData);
          // props.history.push('/selectItem');
        }
        dispatch(
          productDetailsDispatch({
            recipients: data,
            message: SendThanksSchema.sendThanksData['message'],
          })
        );
      }
    } else if (
      (tags?.length > 0 && showMessageBox === false) ||
      (formik.values.messageValue === '' && audioData === undefined && videoData === undefined)
    ) {
      setMessageValue(formik.values.messageValue);
      let data = [];
      let recipient = SendThanksSchema.recipientData;
      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';

          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';

          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';

          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue?.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      props.recipientInformation(SendThanksSchema.sendThanksData);

      if (nextPathValue !== undefined && nextPathValue !== '') {
        const paymentInformation = props.getRecipientInformation;
        let product = JSON.parse(sessionStorage.getItem('productDetails'));

        // props.history.push({
        //   pathname: nextPathValue,
        //   state: {
        //     choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
        //     productDetails: SendThanksSchema.sendThanksData,
        //     productType: product.productType,
        //     priceId: product.priceId,
        //     amount: paymentInformation.amount,
        //   },
        // });
        props.recipientInformation(SendThanksSchema.sendThanksData);
      } else {
        props.recipientInformation(SendThanksSchema.sendThanksData);
        // props.history.push('/selectItem');
      }
    } else if (tags.length === 0) {
      toast.error('Please add recipient');
    }
  };

  const loadVideo = file =>
    new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = function () {
          resolve(this);
        };

        video.onerror = function () {
          reject('Invalid video. Please select a video file.');
        };

        video.src = window.URL.createObjectURL(file);
      } catch (e) {
        reject(e);
      }
    });

  // Getting cover image for uploaded video
  const getVideoCover = (file, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', ex => {
        reject('error when loading video file', ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject('video is too short.');
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement('canvas');
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext('2d');
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            'image/jpeg',
            0.95 /* quality */
          );
        });
      });
    });
  };

  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.name = fileName;
    theBlob.lastModifiedDate = Date.now();

    return theBlob;
  };

  // Getting uploaded media
  const getusersListFile = async e => {
    let fileName = e.currentTarget.files[0].name;
    var allowedExtensions = /(\.mov|\.mp4|\.mp3|\.AVI|\.WEBM|\.WAV|\.AVC)$/i;
    if (!allowedExtensions.exec(fileName)) {
      toast.error('Please upload file having extensions .mov/.mp4/.mp3/.AVI/.WEBM/.WAV only.');
      return false;
    } else {
      let file = e.currentTarget.files[0];
      const video = await loadVideo(file);
      let duration = video.duration;
      if (duration <= 15) {
        var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV|\.AVC)$/i;
        if (!allowedExtensionsForVideo.exec(fileName)) {
          let userData = JSON.parse(localStorage.getItem('userData'));
          let audioFileName = `${Date.now()}_${userData.userName}_audio.mp3`;
          setAudioFile(file);
          setFileName(audioFileName);
          setLoadingUploadBtn(false);
        } else {
          const cover = await getVideoCover(file, 1.5);
          // print out the result image blob
          let userData = JSON.parse(localStorage.getItem('userData'));
          let videoFileName = `${Date.now()}_${userData.userName}_video.mpeg`;
          let thumpFileName = `${Date.now()}_${userData.userName}_image.jpg`;
          var myThumpFile = blobToFile(cover, thumpFileName);
          setThumpFile(myThumpFile);
          setVideoFile(file);
          setThumpFileName(thumpFileName);
          setFileName(videoFileName);
          setLoadingUploadBtn(false);
        }
      } else {
        toast.error('Please upload a 15 sec duration video');
      }
    }
  };

  // Uploading media data
  const uploadBtnHandler = () => {
    if (fileName !== null) {
      setLoadingUploadBtn(true);
      var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV|\.mpeg|\.AVC)$/i;
      if (!allowedExtensionsForVideo.exec(fileName)) {
        var formData = new FormData();
        formData.append('fileName', fileName);
        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myAudioFile = new File([audioFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myAudioFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                let data = response['data'];
                setLoadingUploadBtn(false);
                setAudioData(data);
                hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setVideoData(undefined);
              })
              .catch(error => {
                if (error) {
                  setLoadingUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setLoadingUploadBtn(false);
              console.log('error ', error);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      } else {
        var formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('imagePreviewFile', thumpFile, thumpFileName);

        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myVideoFile = new File([videoFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myVideoFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                setLoadingUploadBtn(false);
                let data = response['data'];
                setVideoData(data);
                hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setAudioData(undefined);
              })
              .catch(error => {
                if (error) {
                  setLoadingUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setLoadingUploadBtn(false);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      }
    } else {
      toast.error('Please upload a file');
    }
  };

  const playUploadedVideo = () => {
    showUploadVideoPlayModalHandler();
  };

  // Removing uploaded video
  const uploadedVideoCloseBtnHandler = () => {
    setCheckVideoIsUploaded(false);
    setVideoData(undefined);
    setThumpFile(null);
    setVideoFile(null);
    setThumpFileName(null);
    setFileName(null);
    setAudioData(undefined);
    setFileData(undefined);
    setExistingMediaData(undefined);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  // load existing cards list
  const loadExistingCardsList = () => {
    let googlePayCard = sessionStorage.getItem('PaymentType') ? sessionStorage.getItem('PaymentType') : 'Card';
    if (googlePayCard === 'GooglePay') {
      setGooglepaySelect(true);
    } else if (googlePayCard === 'Card') {
      setGooglepaySelect(false);
    }

    paymentService
      .getListOfCards()
      .then(response => {
        if (response) {
          let data = response['data'];
          sessionStorage.setItem('PaymentType', 'Card');
          setPaymentType('Card');
          setSavedCardsList(data);
          console.log('User cards data', data.length);
          if (data.length > 0) {
            setPaymentMethod(1);
          }
          let defaultCardData = data.filter(data => {
            return data.isDefault === true;
          });
          for (let i = 0; i < data.length; i++) {
            if (data[i]?.isDefault) {
              setSelectedCardsDetails({
                ...selectedCardDetails,
                cardLastNumber: data[i]?.last4,
                choosedCardId: data[i]?.id,
                cardExpiryDate: data[i]?.expiresOn,
                productDetails: SendThanksSchema.sendThanksData,
                productType: details?.type,
                priceId: priceId,
                amount: paymentInformation?.amount,
                brand: data[i]?.brand,
              });
            }
          }
          if (
            (defaultCardData.length > 0 && sendThanksSchema?.sendThanksData?.paymentSource?.cardid === '') ||
            sendThanksSchema?.sendThanksData?.paymentSource?.cardid === undefined
          ) {
            if (googlePayCard === 'Card') {
              setChoosenCardId(defaultCardData[0]?.id);
            }
          }
          setDefaultCardList(defaultCardData);
        }
      })
      .catch(error => {
        if (error) {
          console.log('Error---->', error);
          // let errorResponse = error?.response['data'];
          // let errorMessage = errorResponse.globalErrors[0].message;
          // Toast(errorMessage, 'error');
        }
      });
  };
  useEffect(() => {
    if (userStatus || checkCard) {
      loadExistingCardsList();
    }
  }, [userStatus, checkCard]);

  useEffect(() => {
    if (anonUserData || userData) {
      loadExistingCardsList();
      setCheckCard(true);
    }
  }, []);

  const setAsDefaultBtnHandler = index => {
    let data = savedCardsList[index];

    let cardData = {
      id: data.id,
      expiresOn: data.expiresOn,
      setAsDefault: true,
    };

    paymentService
      .updateCard(data.id, cardData)
      .then(response => {
        if (response.status === 200) {
          loadExistingCardsList();
          Toast('Your default card is changed successfully', 'success');
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors.userName;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseFieldErrors) {
          if (errorResponseFieldErrors.length > 0) {
            errorResponseFieldErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              console.log('error global response message ', msg);
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const checkIfUserSelectedPaymentCard = () => {
    if (savedCardsList.length > 0) {
      savedCardsList.map((res, index) => {
        if (sendThanksSchema.sendThanksData?.paymentSource?.cardid === res.id) {
          setChoosenCardId(res.id);
          setCardLastNumber(res.last4);

          selectedCardDetails.cardLastNumber = res.last4;
          setCardExpiryDate(res.expiresOn);
          setCardBrand(res.brand);
        }
      });
    }
  };
  const deleteCard = index => {
    setShowDeleteModal(true);
    setSelectedCardIndex(index);
  };
  // Removing card
  const cardDeleteBtnHandler = () => {
    if (checkCardRemoved === false) {
      checkCardRemoved = true;
      let cardId = savedCardsList[selectedCardIndex].id;

      paymentService
        .deleteCard(cardId)
        .then(response => {
          loadExistingCardsList();
          checkIfUserSelectedPaymentCard();
          Toast('Card removed successfully', 'success');

          setShowDeleteModal(false);

          if (cardId === choosenCardId) {
            setChoosenCardId(undefined);
          }

          setTimeout(() => {
            checkCardRemoved = false;
          }, 2000);
        })
        .catch(error => {
          if (error?.response) {
            let errorResponse = error?.response['data'];
            let errorMessage = errorResponse?.globalErrors[0].message;
            Toast(errorMessage, 'error');
          }
        });
    }
  };
  const googlePayBtnHandler = () => {
    setGooglepaySelect(!googlepaySelect);
    setChoosenCardId(undefined);
    sessionStorage.setItem('PaymentType', 'GooglePay');
    setPaymentType('GooglePay');
  };

  return (
    <AnonymousLayout>
      <div className="text-center pt-3 pb-5">
        <Step1 />
      </div>
      <div className="m-0 p-0 row">
        <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12 mb-4 d-flex flex-column right_half">
          <div className="p-0 m-0 row my-lg-0">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="border-0  border-radius-5">
                <div className="card-body">
                  <span className="color-black-pearl font-32 font-sofia-pro-light pl-2">Select payment</span>
                  <div className="mt-3 col-lg-12 main-anon-payment col-md-12 col-sm-12 px-0">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div class="mb-3">
                        <label
                          for="FormControlInput1"
                          class="form-label monteserrat-normal font-16 light-grey padding-left"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          class="form-control border-radius-100 border-none"
                          id="FormControlInput1"
                          placeholder="Add email for your receipt"
                          required
                          onBlur={e => handleEmailValidation(e.target.value)}
                          onChange={e => setSenderEmail(e.target.value)}
                          value={senderEmail}
                        />
                      </div>
                      <div>
                        <label
                          for="FormControlInput2"
                          class="form-label monteserrat-normal font-16 light-grey padding-left"
                        >
                          User Name
                        </label>
                        <input
                          type="text"
                          class="form-control border-radius-100 border-none"
                          id="FormControlInput2"
                          placeholder="Full Name"
                          onBlur={e => handleUserName(e.target.value)}
                          onChange={e => setSenderUserName(e.target.value)}
                          disabled={senderEmail == undefined ? 'true' : ''}
                          value={senderUserName}
                        />
                      </div>
                      {userStatus == 'Activated' ? (
                        <div className="d-flex justify-content-between my-3 pt-4 pb-3">
                          <div
                            className="w-50 text-center cursor-pointer"
                            onClick={() => {
                              setPaymentMethod(0);
                              setCheckCard(false);
                            }}
                          >
                            <div className="pb-2 monteserrat-normal font-16 light-grey ">
                              Add Debit Card/Credit Card
                            </div>
                            {paymentMethod == 0 ? <div className="border-bottom-grey-blue"></div> : null}
                          </div>

                          <div className="w-50 text-center cursor-pointer" onClick={() => setPaymentMethod(1)}>
                            <div className="pb-2 monteserrat-normal font-16 light-grey">
                              Saved Cards
                              {savedCardsList.length !== 0 && (
                                <img src={UsernameSuccessImage} className="pl-2" alt="Filled Green Icon" />
                              )}
                            </div>
                            {paymentMethod == 1 ? <div className="border-bottom-grey-blue"></div> : null}
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-between my-3 pt-4 pb-3">
                          <div className="w-50 text-center cursor-pointer" onClick={() => setPaymentMethod(0)}>
                            <div className="pb-2 monteserrat-normal font-16 light-grey ">Debit Card/Credit Card</div>
                            {paymentMethod == 0 ? <div className="border-bottom-grey-blue"></div> : null}
                          </div>

                          {paymentMethod == 1 && savedCardsList.length !== 0 ? (
                            <div className="w-50 text-center cursor-pointer" onClick={() => setPaymentMethod(1)}>
                              <div className="pb-2 monteserrat-normal font-16 light-grey ">
                                {paymentMethod == 1 && savedCardsList.length !== 0 ? 'Saved Cards' : ''}
                                {savedCardsList.length !== 0 && (
                                  <img src={UsernameSuccessImage} className="pl-2" alt="Filled Green Icon" />
                                )}
                              </div>
                              {paymentMethod == 1 && savedCardsList.length !== 0 ? (
                                <div className="border-bottom-grey-blue"></div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      )}
                      {paymentMethod == 0 ? (
                        <>
                          <div class="mb-3">
                            <Elements stripe={stripePromise}>
                              <ElementsConsumer>
                                {({ elements, stripe }) => (
                                  <AddCardForms
                                    setChoosenCardId={setChoosenCardId}
                                    elements={elements}
                                    stripe={stripe}
                                    setCardDetails={setCardDetails}
                                    senderEmail={senderEmail}
                                    userName={senderUserName}
                                    //   hideModal={() => setShow(false)}
                                    // loadExistingList={loadExistingCardsList}
                                    setCheckCard={setCheckCard}
                                    type={'anonymous'}
                                    registeredUserloggedIn={registeredUserloggedIn}
                                    showUploadModalHandler={showUploadModalHandler}
                                    checkingStatusOfUser={checkingStatusOfUser}
                                  />
                                )}
                              </ElementsConsumer>
                            </Elements>
                          </div>
                          {/* <Elements stripe={stripePromise}>
                            <CheckoutForm clientSecrets={props?.clientSecrets} />
                          </Elements> */}
                          {/* <div className="mt-4">
                            <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                              Other Payment options
                            </span>
                            <div className="mt-3 p-2 d-flex justify-content-between align-items-center">
                              <div className="payment-options-radio__item">
                                <input
                                  type="radio"
                                  id="ritema"
                                  name="ritem"
                                  value="ropt5"
                                  onClick={googlePayBtnHandler}
                                  checked={googlepaySelect === true}
                                />
                                <label
                                  className="font-14 font-sofia-pro-light color-black-pearl cursor-pointer"
                                  onClick={googlePayBtnHandler}
                                >
                                  <span className="pl-2"> Google Pay </span>
                                  <img src={GoolePay} className="mx-2" alt=''/>
                                </label>
                              </div>
                            </div>
                          </div> */}
                        </>
                      ) : (
                        <div className="p-0 m-0 row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="border-0  border-radius-5">
                              <div className="payment-main-card">
                                <div>
                                  <div className="my-2 d-flex justify-content-between">
                                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                      Default payment method
                                    </span>
                                  </div>
                                  {defaultCardList.length !== 0 &&
                                    defaultCardList.map((data, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="mt-3 p-2 d-flex justify-content-between align-items-center border-faded-pink bg-lavender-blush border-radius-5"
                                        >
                                          <div className="payment-options-radio__item">
                                            <label className="font-14 font-sofia-pro-light color-black-pearl ">
                                              <span className="pl-2">
                                                {' '}
                                                {data.brand} •••• •••• •••• {data.last4} |
                                                <span>
                                                  <Moment format="MM/YY">{data.expiresOn}</Moment>
                                                </span>
                                              </span>
                                              <img className="pl-1" src={IconMiscCard} />
                                            </label>
                                          </div>
                                          <span className="font-14 font-sofia-pro-light color-gunmetal">
                                            Default card
                                          </span>
                                        </div>
                                      );
                                    })}

                                  <div className="mt-4">
                                    <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                      Your saved credit and debit cards
                                    </span>
                                    {savedCardsList.length !== 0 &&
                                      savedCardsList.map((data, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="mt-3 p-2 justify-content-between align-items-center card-details-main"
                                          >
                                            <div className="payment-options-radio__item">
                                              <input
                                                type="radio"
                                                id={'savedCard' + index}
                                                name={'savedCard' + index}
                                                value={data.id}
                                                checked={data.id === choosenCardId && googlepaySelect === false}
                                                onChange={e => {
                                                  setChoosenCardId(e.target.value);
                                                  setCardLastNumber(data.last4);
                                                  setCardExpiryDate(data.expiresOn);
                                                  setGooglepaySelect(false);
                                                  sessionStorage.setItem('PaymentType', 'Card');
                                                  setPaymentType('Card');
                                                }}
                                              />
                                              <label className="font-14 font-sofia-pro-light color-black-pearl card-number">
                                                <span className="pl-2">
                                                  {' '}
                                                  {data.brand} •••• •••• •••• {data.last4} |{' '}
                                                  <span>
                                                    <Moment format="MM/YY">{data?.expiresOn}</Moment>
                                                  </span>
                                                </span>
                                                <img className="pl-1 card-icon" src={IconMiscCard} alt="" />
                                              </label>
                                            </div>
                                            <div className="card-buttons">
                                              <button
                                                type="button"
                                                className="mr-2 p-2 btn btn-sm border-0  shadow-none"
                                                onClick={setAsDefaultBtnHandler.bind(this, index)}
                                              >
                                                <span className="font-14 font-sofia-pro-regular color-primary">
                                                  Set as default
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                className="p-2 btn btn-sm border-pale-pink color-primary  shadow-none"
                                                onClick={deleteCard.bind(this, index)}
                                              >
                                                <span className="font-14 font-sofia-pro-regular color-primary">
                                                  Delete
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    {savedCardsList.length === 0 ? (
                                      <p className="my-4 text-center font-18 font-sofia-pro-medium">No data found</p>
                                    ) : null}
                                    <div className="mt-4">
                                      <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                        Add a Payment Method
                                      </span>
                                    </div>
                                    <div className="mt-3 p-2 d-flex justify-content-between align-items-center">
                                      <div
                                        className="p-2 border-primary-color payment-options-radio__item border-radius-5 cursor-pointer"
                                        // onClick={() => setShow(true)}
                                      >
                                        <label
                                          className="font-14 font-sofia-pro-light color-black-pearl cursor-pointer"
                                          onClick={() => {
                                            // setShow(true);
                                            setPaymentMethod(0);
                                            setCheckCard(false);
                                          }}
                                        >
                                          <span className="pl-2"> Add a debit or credit card </span>
                                        </label>
                                      </div>
                                    </div>
                                    {/* <div className="mt-4">
                                      <span className="font-16 font-sofia-pro-semi-bold color-dark-charcoal">
                                        Other Payment options
                                      </span>
                                      <div className="mt-3 p-2 d-flex justify-content-between align-items-center">
                                        <div className="payment-options-radio__item">
                                          <input
                                            type="radio"
                                            id="ritema"
                                            name="ritem"
                                            value="ropt5"
                                            onClick={googlePayBtnHandler}
                                            checked={googlepaySelect === true}
                                          />
                                          <label
                                            className="font-14 font-sofia-pro-light color-black-pearl cursor-pointer"
                                            onClick={googlePayBtnHandler}
                                          >
                                            <span className="pl-2"> Google Pay </span>
                                            <img src={GoolePay} className="mx-2" />
                                          </label>
                                        </div>
                                      </div>
                                     
                                    </div> */}
                                    {/* <ApplePay clientSecrets={clientSecrets}/> */}
                                    <Elements stripe={stripePromise}>
                                      <CheckoutForm clientSecrets={props?.clientSecrets} />
                                    </Elements>
                                  </div>
                                </div>

                                {/* <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
         <button
           className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
           onClick={cancelBtnHandler}
         >
           Cancel
         </button>
         <button
           type="submit"
           className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
           // onClick={reviewBtnHandler}
           disabled={!choosenCardId && !googlepaySelect}
         >
           Review
         </button>
       </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-2">&nbsp;</div>
                        </div>
                      )}

                      {/* <div className="mt-2">
                      <span className="font-16 color-black-pearl font-sofia-pro-regular">Message</span>
                    </div> */}
                    </div>
                    {checkVideoIsUploaded === false && (
                      <div className="col-lg-6 col-md-12 col-sm-12 mb-5">
                        <AnonReviewPurchase
                          addedCard={cardDetails}
                          checkCard={checkCard}
                          userStatus={userStatus}
                          paymentType={paymentType}
                          setClientSecrets={setClientSecrets}
                        />
                      </div>
                    )}

                    {checkVideoIsUploaded === true && (
                      <div className="d-flex flex-column">
                        {fileData?.fileCategory === 'Video' && (
                          <div>
                            <Close className="audio-close-icon cursor-pointer" onClick={uploadedVideoCloseBtnHandler} />
                            <div
                              className="video-list"
                              style={{
                                backgroundImage: `url(${fileData ? fileData.imagePreviewPath + '?h=120&w=120' : ''})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                              }}
                            >
                              <SendVideoIcon className="mike" />
                              <PlayIcon className="play cursor-pointer" onClick={playUploadedVideo} />
                            </div>
                          </div>
                        )}
                        {fileData?.fileCategory === 'Audio' && (
                          <div>
                            <Close
                              className="audio-close-icon cursor-pointer mike"
                              onClick={uploadedVideoCloseBtnHandler}
                            />
                            <div className=" d-flex video-list justify-content-center align-items-center audio-list ">
                              <PlayIcon className="play cursor-pointer" onClick={playUploadedVideo} />
                            </div>
                          </div>
                        )}
                        <div className="d-flex justify-content-end">
                          <button
                            className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                            onClick={uploadedVideoCloseBtnHandler}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none shadow-none"
                            onClick={onSubmit}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    )}

                    {existingMediaData !== undefined && (
                      <div>
                        {existingMediaData?.fileCategory === 'Audio' && (
                          <div>
                            <Close className="audio-close-icon" onClick={closeAudioFile} />
                            <div className="audio-list">
                              <MikeIconWhite className="mike" />
                              <PlayIcon className="play" onClick={showMediaModalHandler} />
                            </div>
                          </div>
                        )}
                        {existingMediaData?.fileCategory === 'Video' && (
                          <div>
                            <Close className="audio-close-icon" onClick={closeVideoFile} />
                            <div className="video-list">
                              <SendVideoIcon className="mike" />
                              <PlayIcon className="play" onClick={showMediaModalHandler} />
                            </div>
                          </div>
                        )}
                        <div className="p-0 m-0 mt-5 d-flex justify-content-between align-items-center">
                          <div className="d-flex">
                            <VideoIconActive onClick={toggleVideoModal} />
                            <AudioIconInactive className="ml-2" onClick={toggleAudioModal} />
                            <img
                              src={UploadImage}
                              className="p-1 ml-2 d-flex justify-content-center align-items-center message-icon1 bg-alice-blue border-radius-5"
                              onClick={toggleUploadModal}
                              height="35px"
                              width="35px"
                              alt="Upload Imaege"
                            />
                          </div>
                          <div className="d-flex ">
                            <button
                              className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                              onClick={textMessageCancelHandler}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none shadow-none"
                              onClick={onSubmit}
                            >
                              Proceed
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <Modal
                      className="pt-4 border-0
         modal-background-shadow payment-modal"
                      size="md"
                      show={showDeleteModal}
                      onHide={() => setShowDeleteModal(false)}
                      animation={false}
                    >
                      <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
                        <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Delete card?</span>
                        <button
                          type="button"
                          className="close modal-close-icon"
                          onClick={() => setShowDeleteModal(false)}
                        >
                          <Close />
                        </button>
                      </Modal.Header>
                      <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                        <p className="font-16 font-sofia-pro-light color-nobel">
                          Are you sure you want to delete this payment method?
                        </p>
                        <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
                          <button
                            className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                            onClick={() => setShowDeleteModal(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                            onClick={cardDeleteBtnHandler}
                          >
                            Delete
                          </button>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
                      show={mediaModal}
                      onHide={hideMediaModalHandler}
                      animation={false}
                    >
                      <Modal.Header className="p-0 m-0 border-0">
                        <button
                          type="button"
                          className="close switch-to-account-modal__close__icon"
                          onClick={hideMediaModalHandler}
                        >
                          <Close />
                        </button>
                      </Modal.Header>
                      <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                        {existingMediaData?.fileCategory === 'Audio' && (
                          <audio controls preload="none">
                            <source src={existingMediaData?.url} type="audio/mpeg" />
                          </audio>
                        )}

                        {existingMediaData?.fileCategory === 'Video' && (
                          <video width="320" height="240" controls>
                            <source src={existingMediaData?.url} type="video/mp4" />
                          </video>
                        )}
                      </Modal.Body>
                    </Modal>

                    <Modal
                      className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
                      show={uploadModal}
                      onHide={hideUploadModalHandler}
                      animation={false}
                    >
                      <Modal.Header className="p-0 m-0 border-0">
                        <button
                          type="button"
                          className="close switch-to-account-modal__close__icon"
                          onClick={hideUploadModalHandler}
                        >
                          <Close />
                        </button>
                      </Modal.Header>
                      <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                        <AnonymousOTPModal
                          email={senderEmail}
                          hideUploadModalHandler={hideUploadModalHandler}
                          setUserStatus={setUserStatus}
                          setRegisteredUserLoggedIn={setRegisteredUserLoggedIn}
                        />
                        {/* <div className="mt-2">
                          <div className="d-flex justify-content-between">
                            <div>
                              <label className="p-0 m-0 font-sofia-pro-regular color-charcoal font-16 padding-left">
                                Browse file
                              </label>
                            </div>
                          </div>
                          <div className="p-0 m-0 card w-100 border-0">
                            <label htmlFor="file-input">
                              <div className="invite__employee-upload-style">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  <FolderIcon />
                                  {fileName === null && (
                                    <div>
                                      <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                        <span className="font-16 font-sofia-pro-regular color-primary">
                                          <u>Browse file </u>
                                        </span>
                                      </p>
                                      <span className="font-14 font-sofia-pro-regular color-grey-blue">
                                        Supports MP4, MP3, MOV, AVI, WEBM, WAV, MPEG, AVC
                                      </span>
                                    </div>
                                  )}
                                  {fileName !== null && (
                                    <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                      <u>{fileName}</u>
                                    </p>
                                  )}
                                </div>
                                <input
                                  id="file-input"
                                  type="file"
                                  accept="audio/*,video/*"
                                  onChange={getusersListFile}
                                  onClick={event => {
                                    event.target.value = null;
                                  }}
                                />
                              </div>
                            </label>
                            <div className="d-flex justify-content-end">
                              <button
                                className="p-0 m-0 mr-4 border-0 bg-transparent font-14 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                                onClick={hideUploadModalHandler}
                              >
                                Cancel
                              </button>
                              <ButtonLoading
                                children={'Upload'}
                                showLoader={loadingUploadBtn}
                                onClick={uploadBtnHandler}
                                classNameValue="w-150 btn bg-primary-color color-white h-40 font-sofia-pro-semi-bold font-14 outline-none shadow-none justify-content-around align-items-center d-flex"
                              />
                            </div>
                          </div>
                        </div> */}
                      </Modal.Body>
                    </Modal>

                    <Modal
                      className="border-0 modal-background-shadow switch-to-account-modal"
                      show={showUploadVideoPlayModal}
                      onHide={hideUploadVideoPlayModalHandler}
                      animation={false}
                    >
                      <Modal.Header className="p-0 m-0 py-2 border-0">
                        <button
                          type="button"
                          className="close switch-to-account-modal__close__icon"
                          onClick={hideUploadVideoPlayModalHandler}
                        >
                          <Close />
                        </button>
                      </Modal.Header>
                      <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                        {fileData?.fileCategory === 'Video' && (
                          <video width="320" height="240" controls>
                            <source src={fileData?.url} type="video/mp4" />
                          </video>
                        )}

                        {fileData?.fileCategory === 'Audio' && (
                          <audio controls preload="none">
                            <source src={fileData?.url} type="audio/mpeg" />
                          </audio>
                        )}
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RecordAudio
          ref={audioModalRef}
          selectAudio={selectAudio}
          audioModel={audioModel}
          toggleAudioModal={toggleAudioModal}
          toggleVideoModal={toggleVideoModal}
          audioResponse={audioResponse}
        />
        <RecordVideo
          ref={videoModalRef}
          selectVideo={selectVideo}
          videoModel={videoModel}
          toggleAudioModal={toggleAudioModal}
          toggleVideoModal={toggleVideoModal}
          videoResponse={videoResponse}
        />
      </div>
      {/* <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center btn-next">
        <button
          className="p-0 m-0 w-140 btn bg-white h-48 mr-4 border-0 font-16 color-gunmetal font-sofia-pro-medium outline-none"
          onClick={cancelBtnHandler}
        >
          Back
        </button>
        <button
          type="submit"
          className="w-140 btn bg-primary-color color-white h-48 font-sofia-pro-semi-bold font-16 outline-none"
          onClick={proceedBtnHandler}
        >
          Next
        </button>
      </div> */}
    </AnonymousLayout>
  );
};

// MAPPING - SEND THANKS DATA

const mapStateToProps = state => {
  return {
    getRecipientInformation: state.sendThanks.sendThanksData,
    searchData: state.header.searchData,
    getPaymentInformation: state.sendThanks.sendThanksData,
  };
};

// DISPATCH  - SEND THANKS DATA

const mapDispatchToProps = dispatch => {
  return {
    recipientInformation: data => dispatch(sendThanksDetails(data)),
    resetData: () => dispatch(resetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnonPayment);
