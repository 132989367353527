/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MikeIconWhite, PlayIcon, SendVideoIcon } from '../../../../assets/images';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';
import { ReactComponent as AudioIconInactive } from '../../../../assets/images/send_thanks/audio-icon-inactive.svg';
import { ReactComponent as AudioIcon } from '../../../../assets/images/send_thanks/icons-send-audio.svg';
import UploadImage from '../../../../assets/images/common/upload.png';
import { ReactComponent as MessageIcon } from '../../../../assets/images/send_thanks/icons-send-message.svg';
import { ReactComponent as VideoIcon } from '../../../../assets/images/send_thanks/icons-send-video.svg';
import { ReactComponent as VideoIconActive } from '../../../../assets/images/send_thanks/video-icon-active.svg';
import Header from '../../../../common/Header/Header';
import RecordAudio from '../../../../common/RecordAudio/RecordAudio';
import RecordVideo from '../../../../common/RecordVideo/RecordVideo';
import SideBar from '../../../../common/Sidebar/Sidebar';
import { default as http, default as HttpService } from '../../../../services/HttpService';
import { resetSendThanksDetails, sendThanksDetails } from '../../../../store/reducers/individual/sendThanks/sendThanks';
import * as RegexPatterns from '../../../../utils/RegexPatterns';
import sendThanksSchema, * as SendThanksSchema from '../../../../utils/Schema/sendThanksSchema';
import RecipientsForm from '../../../common/RecipientsForm';
import dashboardService from '../../../../services/DashboardService';
import { ReactComponent as FolderIcon } from '../../../../assets/images/common/icons-folder.svg';
import { productDetailsDispatch } from '../../../../store/reducers/multiProduct/multiProduct';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastClassName: 'appreci-success__message',
});

const initialValues = {
  messageValue: null,
};

const validate = values => {
  let errors = {};

  if (values.messageValue.length > 100) {
    errors.messageValue = 'Characters length should not exceed 100';
  }

  return errors;
};

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

const ForewardThanks = props => {
  const TransactionId = sessionStorage.getItem('TransactionId');
  const history = useHistory();
  const dispatch = useDispatch();

  const [tags, setTags] = useState([]);

  const [showMessageBox, setshowMessageBox] = useState(false);

  const [messageValue, setMessageValue] = useState('');

  const [nextPathValue, setNextPathValue] = useState();

  const [audioModel, setAudioModel] = useState(false);

  const [videoModel, setVideoModel] = useState(false);

  const [messageType, setMessageType] = useState(1);

  const audioModalRef = useRef(null);

  const videoModalRef = useRef(null);

  const [availableBalance, setAvailableBalance] = useState();

  const [audioData, setAudioData] = useState();

  const [videoData, setVideoData] = useState();

  const [existingMediaData, setExistingMediaData] = useState();

  const [getUserData, setGetUserData] = useState([]);

  let UserDataCombined = [];

  const [fileName, setFileName] = useState(null);

  const [videoFile, setVideoFile] = useState(null);

  const [thumpFile, setThumpFile] = useState(null);

  const [thumpFileName, setThumpFileName] = useState(null);

  const [fileData, setFileData] = useState(null);

  const [audioFile, setAudioFile] = useState(null);

  const [mediaModal, setShowMediaModal] = useState(false);

  const showMediaModalHandler = () => setShowMediaModal(true);
  const hideMediaModalHandler = () => setShowMediaModal(false);

  const [uploadModal, setShowUploadModal] = useState(false);

  const showUploadModalHandler = () => setShowUploadModal(true);
  const hideUploadModalHandler = () => setShowUploadModal(false);

  const [showUploadVideoPlayModal, setShowUploadVideoPlayModal] = useState(false);

  const showUploadVideoPlayModalHandler = () => setShowUploadVideoPlayModal(true);
  const hideUploadVideoPlayModalHandler = () => setShowUploadVideoPlayModal(false);

  const [checkVideoIsUploaded, setCheckVideoIsUploaded] = useState(false);

  const [disableUploadBtn, setDisableUploadBtn] = useState(false);

  let tagInput = '';

  let tag, queryUser;

  let currentPath = usePathname();

  const messageButtonHandler = () => {
    setshowMessageBox(!showMessageBox);
    setMessageType(1);
  };

  const textMessageCancelHandler = () => {
    setshowMessageBox(!showMessageBox);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getDashboardData();
  }, []);
  const SearchTagSet = tag => {
    if (tag !== undefined) {
      if (tag.match(RegexPatterns.CheckNumberOrNotRegexValue)) {
        if (!tag.match(RegexPatterns.AusMobileRegexValue)) {
          toast.error('Please enter a valid mobile number');
          return;
        }
      } else {
        if (RegexPatterns.EmailRegexValue.test(tag) === false && typeof tag === 'string') {
          setTags([tag]);
        } else if (!tag.match(RegexPatterns.EmailRegexValue)) {
          toast.error('Please enter a valid email');
          return;
        }
      }
      setTags([tag]);
    }
  };


  const proceedBtnHandler = () => {
    toast.dismiss();
    if (tags.length > 0) {
      let data = [];
      let recipient = SendThanksSchema.recipientData;

      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';
          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';
          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';
          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      if (nextPathValue !== undefined && nextPathValue !== '') {
        const paymentInformation = props.getRecipientInformation;
        let product = JSON.parse(sessionStorage.getItem('productDetails'));

        props.history.push({
          pathname: nextPathValue,
          state: {
            choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
            productDetails: SendThanksSchema.sendThanksData,
            productType: product.productType,
            priceId: product.priceId,
            amount: paymentInformation.amount,
          },
        });
        props.recipientInformation(SendThanksSchema.sendThanksData);
      } else {
        props.recipientInformation(SendThanksSchema.sendThanksData);
        props.history.push('/selectItem');
      }
      dispatch(productDetailsDispatch({
        recipients: data,
        message:null
      }))
    } else if (tags.length === 0) {
      toast.error('Please add recipient');
    }
  };

  const cancelBtnHandler = e => {
    e.preventDefault();
    props.resetData();
    props.history.push({
      pathname: '/dashboard',
    });
  };

  useEffect(() => {
    if (currentPath === '/recipientInformation') {
      tag = props.searchData;
    }
    if (tag !== '' && tag !== undefined) {
      SearchTagSet(tag);
    }
  }, [props?.searchData]);

  useEffect(() => {
    let data;
    if (props?.history?.location?.state !== undefined) {
      if (
        props?.history?.location?.state.searchName !== null &&
        props?.history?.location?.state.searchName !== undefined
      ) {
        queryUser = [props?.history?.location?.state.searchName];
      }
    }
    if (queryUser != null && queryUser != undefined) {
      data = queryUser[0].map(val => {
        return val;
      });
    }
    setGetUserData(getUserData.concat(data));
    if (data != null && data != undefined) {
      UserDataCombined = [...tags, data[0]];
    }
    var result = [];
    result = UserDataCombined.filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });
    var items = result.map(item => item);

    if (items != null && items !== undefined && items !== '') {
      setTags(
        items.map(val => {
          if (val !== undefined) {
            return val;
          }
        })
      );
    }
  }, [props?.history?.location?.state]);

  const toggleAudioModal = useCallback(event => {
    setMessageType(3);
    setAudioModel(!audioModel);

    if (audioModel === false) {
      navigator.getUserMedia(
        { audio: true },
        () => {},
        err => {
          alert(err);
        }
      );
    }
  });

  const selectAudio = useCallback(() => {
    setshowMessageBox(true);
    setAudioModel(false);
  });

  const playAudio = () => {
    new Promise((resolve, reject) => {
      setAudioModel(true);
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      if (audioModalRef.current.recordState === 'stopped') {
        audioModalRef.current.selectAudioPlayer();
      }
    });
  };

  const selectVideo = useCallback(() => {
    setshowMessageBox(true);
    setVideoModel(false);
  });

  const playVideo = () => {
    new Promise((resolve, reject) => {
      setVideoModel(true);
      setTimeout(() => {
        resolve();
      }, 500);
    }).then(() => {
      if (videoModalRef.current.recordState === 'stopped') {
        videoModalRef.current.selectVideoPlayer();
      }
    });
  };

  const toggleVideoModal = useCallback(event => {
    setMessageType(2);
    setVideoModel(!videoModel);

    if (videoModel === false) {
      navigator.getUserMedia(
        { audio: true, video: true },
        () => {},
        err => {
          alert(err);
        }
      );
    }
  });

  const getDashboardData = () => {
    dashboardService
      .getDashboardData()
      .then(response => {
        if (response) {
          let data = response['data'];
          setAvailableBalance(data?.thanksAvailable);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const audioResponse = data => {
    if (data !== undefined && data !== null) {
      setAudioData(data);
    }
  };

  const videoResponse = data => {
    if (data !== undefined && data !== null) {
      setVideoData(data);
    }
  };

  const getMediaData = id => {
    // console.log('id ', id);
    http
      .get(`/StoredFiles/${id}`)
      .then(response => {
        if (response) {
          let data = response['data'];
          if (data?.fileCategory === 'Audio') {
            setExistingMediaData(data);
          } else if (data?.fileCategory === 'Video') {
            setExistingMediaData(data);
          }
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const toggleUploadModal = () => {
    setMessageType(4);
    showUploadModalHandler();
  };

  const closeAudioFile = () => {
    SendThanksSchema.sendThanksData.recipients.forEach(recipient => {
      recipient.message.message = null;
      recipient.message.storedFile = null;
      recipient.message.storedFileSecret = null;
      recipient.message.type = null;
    });
    setMessageType(1);
    setExistingMediaData(undefined);
  };

  const closeVideoFile = () => {
    SendThanksSchema.sendThanksData.recipients.forEach(recipient => {
      recipient.message.message = null;
      recipient.message.storedFile = null;
      recipient.message.storedFileSecret = null;
      recipient.message.type = null;
    });
    setMessageType(1);
    setExistingMediaData(undefined);
  };

  const onSubmit = values => {
    if (
      (tags.length > 0 && formik.values.messageValue !== undefined && formik.values.messageValue !== '' && formik.values.messageValue !== null) ||
      audioData !== undefined ||
      videoData !== undefined
    ) {
      let data = [];
      let recipient = SendThanksSchema.recipientData;

      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';

          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';

          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';

          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      let forwardData = {
        quantity: 1,
        recipient: {
          email: data[0].email,
          userName: data[0].userName,
          message: sendThanksSchema?.sendThanksData?.message,
          firstName: data[0].firstName,
          lastName: data[0].lastName,
          phone: data[0].phone,
          region: 'AU',
        },
      };

      if (showMessageBox === true || audioData !== undefined || videoData !== undefined) {
        if (nextPathValue !== undefined && nextPathValue !== '') {
          const paymentInformation = props.getRecipientInformation;
          let product = JSON.parse(sessionStorage.getItem('productDetails'));

          props.history.push({
            pathname: nextPathValue,
            state: {
              choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
              productDetails: SendThanksSchema.sendThanksData,
              productType: product.productType,
              priceId: product.priceId,
              amount: paymentInformation.amount,
            },
          });
          props.recipientInformation(SendThanksSchema.sendThanksData);
        } else {
          const data = {...forwardData}
          data.message = {...data.recipient.message}
          delete data.recipient.message
          HttpService.post(`Gifts/${TransactionId}/send`, data)
            .then(res => {
              history.push('/forwardsuccess');
            })
            .catch(error => {
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            });
        }
        dispatch(productDetailsDispatch({
          recipients: data,
          message: SendThanksSchema.sendThanksData['message']
        }))
      }
    } else if (
      (tags?.length > 0 && showMessageBox === false) ||
      (formik.values.messageValue === '' && audioData === undefined && videoData === undefined)
    ) {
      let data = [];
      let recipient = SendThanksSchema.recipientData;
      tags.forEach((tag, index) => {
        if (tag.match(RegexPatterns.MobileRegexValue)) {
          let sendThanksPhoneSchemaObject = {};
          sendThanksPhoneSchemaObject = Object.create(recipient);
          sendThanksPhoneSchemaObject.phone = tag;
          sendThanksPhoneSchemaObject['firstName'] = null;
          sendThanksPhoneSchemaObject['lastName'] = null;
          sendThanksPhoneSchemaObject['userName'] = null;
          sendThanksPhoneSchemaObject['email'] = null;
          sendThanksPhoneSchemaObject['region'] = 'AU';

          data.push(sendThanksPhoneSchemaObject);
        } else if (tag.match(RegexPatterns.EmailRegexValue)) {
          let sendThanksEmailSchemaObject = {};
          sendThanksEmailSchemaObject = Object.create(recipient);
          sendThanksEmailSchemaObject.email = tags[index];
          sendThanksEmailSchemaObject['firstName'] = null;
          sendThanksEmailSchemaObject['lastName'] = null;
          sendThanksEmailSchemaObject['userName'] = null;
          sendThanksEmailSchemaObject['phone'] = null;
          sendThanksEmailSchemaObject['region'] = 'AU';

          data.push(sendThanksEmailSchemaObject);
        } else {
          let sendThanksUserNameSchemaObject = {};
          sendThanksUserNameSchemaObject = Object.create(recipient);
          sendThanksUserNameSchemaObject.email = null;
          sendThanksUserNameSchemaObject['firstName'] = null;
          sendThanksUserNameSchemaObject['lastName'] = null;
          sendThanksUserNameSchemaObject['userName'] = tags[index];
          sendThanksUserNameSchemaObject['phone'] = null;
          sendThanksUserNameSchemaObject['region'] = 'AU';

          data.push(sendThanksUserNameSchemaObject);
        }
      });

      SendThanksSchema.sendThanksData['message'] = sendThanksSchema.message;
      SendThanksSchema.sendThanksData['message']['type'] = null;
      SendThanksSchema.sendThanksData['message']['message'] = null;
      SendThanksSchema.sendThanksData['message']['storedFile'] = null;
      SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      if (
        formik.values.messageValue !== undefined &&
        formik.values.messageValue !== null &&
        formik.values.messageValue !== ''
      ) {
        SendThanksSchema.sendThanksData.message.type = 'Text';
        SendThanksSchema.sendThanksData.message.message = formik.values.messageValue.trim();
        SendThanksSchema.sendThanksData['message']['storedFile'] = null;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = null;
      } else if (audioData !== undefined && audioData !== null && audioData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = audioData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = audioData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = audioData?.fileSecret;
      } else if (videoData !== undefined && videoData !== null && videoData !== '') {
        SendThanksSchema.sendThanksData['message']['type'] = videoData?.fileCategory;
        SendThanksSchema.sendThanksData['message']['message'] = null;
        SendThanksSchema.sendThanksData['message']['storedFile'] = videoData?.guid;
        SendThanksSchema.sendThanksData['message']['storedFileSecret'] = videoData?.fileSecret;
      }

      if (sendThanksSchema.sendThanksData['message']['type'] === null) {
        delete sendThanksSchema.sendThanksData['message'];
      }

      SendThanksSchema.sendThanksData.recipients = data;

      props.recipientInformation(SendThanksSchema.sendThanksData);

      let forwardData = {
        quantity: 1,
        recipient: {
          email: data[0].email,
          userName: data[0].userName,
          firstName: data[0].firstName,
          lastName: data[0].lastName,
          phone: data[0].phone,
          region: 'AU',
        },
      };

      if (nextPathValue !== undefined && nextPathValue !== '') {
        const paymentInformation = props.getRecipientInformation;
        let product = JSON.parse(sessionStorage.getItem('productDetails'));

        props.history.push({
          pathname: nextPathValue,
          state: {
            choosedCardId: sendThanksSchema.sendThanksData.paymentSource.cardid,
            productDetails: SendThanksSchema.sendThanksData,
            productType: product.productType,
            priceId: product.priceId,
            amount: paymentInformation.amount,
          },
        });
        props.recipientInformation(SendThanksSchema.sendThanksData);
      } else {
        const data = {...forwardData}
        data.message = {...data.recipient.message}
        delete data.recipient.message
        console.log("-----ifInElse-------", Object.keys(data.message).length === 0, data.message);
        if(Object.keys(data.message).length === 0) {
          // delete data.message
          // data.message = {type: ''};
          data.message = {
            type: "Text",
            message: "",
            storedFileUrl: "",
            storedFile: "",
            storedFileSecret: ""
          }
        }
        HttpService.post(`Gifts/${TransactionId}/send`, data)
          .then(res => {
            history.push('/forwardsuccess');
          })
          .catch(error => {
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  toast.error(msg.message);
                });
              }
            }
          });
      }
    } else if (tags.length === 0) {
      toast.error('Please add recipient');
    }
  };

  const loadVideo = file => new Promise((resolve, reject) => {
    try {
      let video = document.createElement('video')
      video.preload = 'metadata'

      video.onloadedmetadata = function () {
        resolve(this)
      }

      video.onerror = function () {
        reject("Invalid video. Please select a video file.")
      }

      video.src = window.URL.createObjectURL(file)
    } catch (e) {
      reject(e)
    }
  })

  const getVideoCover = (file, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener('seeked', () => {
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            blob => {
              resolve(blob);
            },
            "image/jpeg",
            0.95 /* quality */
          );
        });
      });
    });
  }

  const blobToFile = (theBlob, fileName) => {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.name = fileName;
    theBlob.lastModifiedDate = Date.now();

    return theBlob;
  }

  const getusersListFile = async (e) => {
    let fileName = e.currentTarget.files[0].name;
    var allowedExtensions = /(\.mov|\.mp4|\.mp3|\.AVI|\.WEBM|\.WAV)$/i;
    if (!allowedExtensions.exec(fileName)) {
      toast.error('Please upload file having extensions .mov/.mp4/.mp3/.AVI/.WEBM/.WAV only.');
      return false;
    } else {
      let file = e.currentTarget.files[0];
      const video = await loadVideo(file)
      let duration = video.duration;
      if (duration <= 15) {
        var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV)$/i;
        var allowedExtensionsForAudio = /(\.mp3|\.ogg|\.WAV)$/i;
        if (!allowedExtensionsForVideo.exec(fileName)) {
          let userData = JSON.parse(localStorage.getItem('userData'));
          let audioFileName = `${Date.now()}_${userData.userName}_audio.mp3`;
          setAudioFile(file);
          setFileName(audioFileName);
        } else {
          const cover = await getVideoCover(file, 1.5);
          // print out the result image blob
          let userData = JSON.parse(localStorage.getItem('userData'));
          let videoFileName = `${Date.now()}_${userData.userName}_video.mpeg`;
          let thumpFileName = `${Date.now()}_${userData.userName}_image.jpg`;
          var myThumpFile = blobToFile(cover, thumpFileName)
          setThumpFile(myThumpFile);
          setVideoFile(file);
          setThumpFileName(thumpFileName);
          setFileName(videoFileName);
        }
      } else {
        toast.error('Please upload a 15 sec duration video');
      }
    }
  }

  const uploadBtnHandler = () => {
    if (fileName !== null) {
      setDisableUploadBtn(true);
      var allowedExtensionsForVideo = /(\.mov|\.mp4|\.AVI|\.WEBM|\.WAV|\.mpeg|\.AVC)$/i;
      if (!allowedExtensionsForVideo.exec(fileName)) {
        var formData = new FormData();
        formData.append('fileName', fileName);
        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myAudioFile = new File([audioFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myAudioFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                let data = response['data'];
                setDisableUploadBtn(false);
                setAudioData(data);
                hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setVideoData(undefined);
              })
              .catch(error => {
                if (error) {
                  setDisableUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setDisableUploadBtn(false);
              console.log('error ', error);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      } else {
        var formData = new FormData();
        formData.append('fileName', fileName);
        formData.append('imagePreviewFile', thumpFile, thumpFileName);

        http
          .post('/StoredFiles', formData, {
            headers: {
              'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            },
          })
          .then(initResponse => {
            let resp = initResponse.data;
            var myVideoFile = new File([videoFile], fileName, {
              lastModified: Date.now(),
            });
            var formDataUpdate = new FormData();
            formDataUpdate.append('fileName', myVideoFile, fileName);
            http
              .patch(`/StoredFiles/${resp.guid}/content`, formDataUpdate, {
                headers: {
                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                  fileSecret: resp.fileSecret,
                },
              })
              .then(response => {
                setDisableUploadBtn(false);
                let data = response['data'];
                setVideoData(data);
                hideUploadModalHandler();
                setCheckVideoIsUploaded(true);
                setFileData(data);
                setExistingMediaData(undefined);
                formik.setFieldValue('messageValue', undefined);
                setAudioData(undefined);
              })
              .catch(error => {
                if (error) {
                  setDisableUploadBtn(false);
                  let errorResponseFieldErrors = error.response['data'].fieldErrors;
                  let errorResponseGlobalErrors = error.response['data'].globalErrors;
                  if (error) {
                    let errorResponse = error.response['data'];
                    console.log('error response field errors ', errorResponse);
                  }
                  loopFieldErrors(errorResponseFieldErrors);
                  if (errorResponseGlobalErrors) {
                    if (errorResponseGlobalErrors.length > 0) {
                      errorResponseGlobalErrors.forEach(msg => {
                        toast.error(msg.message);
                      });
                    }
                  }
                }
              });
          })
          .catch(error => {
            if (error) {
              setDisableUploadBtn(false);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              loopFieldErrors(errorResponseFieldErrors);
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            }
          });
      }
    } else {
      toast.error('Please upload a file');
    }
  };

  const playUploadedVideo = () => {
    showUploadVideoPlayModalHandler();
  }

  const uploadedVideoCloseBtnHandler = () => {
    setCheckVideoIsUploaded(false);
    setVideoData(undefined);
    setThumpFile(null);
    setVideoFile(null);
    setThumpFileName(null);
    setFileName(null);
    setAudioData(undefined);
    setFileData(undefined);
  }



  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        toast.error(itemSubArray[j]);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column">
        <SideBar menu="Redeemthanks" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh">
        <Header />
        <div className="d-flex">
          <Link to="/dashboard">
            <span className="p-2 m-4 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" />
              Back
            </span>
          </Link>
        </div>
        <div className="p-0 m-0 row">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <div className="ml-2 card border-0  border-radius-5">
              <div className="card-body">
                <span className="color-black-pearl font-32 font-sofia-pro-light">Enter recipient information</span>
                <div className="mt-3">
                  <RecipientsForm tags={tags} onChange={setTags}></RecipientsForm>
                  <div className="mt-2">
                    <span className="font-16 color-black-pearl font-sofia-pro-regular">Your message</span>
                  </div>
                  {
                    checkVideoIsUploaded === false &&
                    <div>
                      {
                        showMessageBox === false && existingMediaData === undefined ?
                          <div>
                            <div className="p-0 m-0 row">
                              <div className="p-0 w-50 m-0 col-xs-6 col-md-3 my-2 ">
                                <button
                                  type="button"
                                  className="px-2 justify-content-center align-items-center btn shadow-none font-14 w-120 color-black-pearl border-radius-5 font-sofia-pro-regular bg-alice-blue h-60 message-btn-align"
                                  onClick={messageButtonHandler}
                                >
                                  <MessageIcon />
                                  <span className="ml-1">Type your message</span>
                                </button>
                              </div>
                              <div className="p-0 w-50 m-0 col-xs-6 col-md-3 my-2 ">
                                <button
                                  type="button"
                                  className=" px-2 justify-content-center align-items-center btn shadow-none font-14 w-120 color-black-pearl border-radius-5 font-sofia-pro-regular bg-alice-blue h-60 message-btn-align"
                                  onClick={toggleVideoModal}
                                >
                                  <VideoIcon />
                                  <span className="ml-1">Record video</span>
                                </button>
                              </div>
                              <div className="p-0 w-50 m-0 col-xs-6 col-md-3 my-2">
                                <Button
                                  variant=""
                                  className=" px-2 justify-content-center align-items-center shadow-none font-14 w-120 color-black-pearl border-radius-5 font-sofia-pro-regular bg-alice-blue h-60 message-btn-align"
                                  onClick={toggleAudioModal}
                                >
                                  <AudioIcon />
                                  <span className="ml-1">Record audio</span>
                                </Button>
                              </div>
                              <div className="p-0 w-50 m-0 col-xs-6 col-md-3 my-2">
                                <Button
                                  variant=""
                                  className="px-2 justify-content-center align-items-center shadow-none font-14 w-120 color-black-pearl border-radius-5 font-sofia-pro-regular bg-alice-blue h-60 message-btn-align"
                                  onClick={toggleUploadModal}
                                >
                                  <img src={UploadImage} height="20px" width="20px" />
                                  <span className="ml-1">Upload audio/video</span>
                                </Button>
                              </div>
                              <div className="w-100 p-0 m-0 mt-5 d-flex justify-content-end align-items-center">
                                <button className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none" onClick={cancelBtnHandler}>Cancel</button>
                                <button type="submit" className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none" onClick={onSubmit}>Proceed</button>
                              </div>
                            </div>
                          </div> :
                          <div>
                            {
                              existingMediaData === undefined &&
                                <>
                                  <form onSubmit={formik.handleSubmit} id="form-action">
                                    {
                                      {
                                        1: <div>
                                            <textarea
                                              className="mt-1 form-control shadow-none font-sofia-pro-regular font-14 recipient-information__input__text__area"
                                              cols="10"
                                              rows="5"
                                              name="messageValue"
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              value={formik.values.messageValue}
                                              placeholder="Enter your message">
                                            </textarea>
                                          {formik.errors.messageValue ? (
                                            <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                                              <img className="mr-1" src={ErrorImage} />{" "}
                                              {formik.errors.messageValue}
                                            </div>
                                          ) : null}
                                        </div>,
                                        2: <div className="video-list" style={{ backgroundImage: `url(${videoModalRef.current ? videoModalRef.current.thumpUrl : ''})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover' }}>
                                          <SendVideoIcon className="mike" />
                                          <PlayIcon className="play" onClick={playVideo} />
                                        </div>,
                                        3: <div className="audio-list">
                                          <MikeIconWhite className="mike" />
                                          <PlayIcon className="play" onClick={playAudio} />
                                          <p className="selector">Select</p>
                                        </div>,
                                      }[messageType]
                                    }
                                    <div className="p-0 m-0 my-3 d-flex justify-content-between align-items-center">
                                      <div className="d-flex">
                                        <VideoIconActive onClick={toggleVideoModal} />
                                        <AudioIconInactive className="ml-2" onClick={toggleAudioModal} />
                                      </div>
                                    </div>
                                  </form>
                                  <div className="d-flex justify-content-end">
                                    <button className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none shadow-none" onClick={textMessageCancelHandler} >Cancel</button>
                                    <button type="submit" className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none shadow-none" onClick={onSubmit}>Proceed</button>
                                  </div>
                                </>
                            }
                          </div>
                      }

                    </div>
                  }

                  {
                    checkVideoIsUploaded === true &&
                    <div className="d-flex flex-column">
                      <button
                        type="button"
                        className="w-25 close font-14 font-sofia-pro-regular color-black-pearl text-right pb-1"
                        onClick={uploadedVideoCloseBtnHandler}
                      >
                        Close
                      </button>
                      {fileData?.fileCategory === 'Video' && (
                        <div
                          className="w-25 h-80 d-flex justify-content-center align-items-center upload-video-list border-pale-pink"
                          style={{
                            backgroundImage: `url(${fileData ? fileData.imagePreviewPath + '?h=120&w=120' : ''})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                          }}
                        >
                          <PlayIcon className="play cursor-pointer" onClick={playUploadedVideo} />
                        </div>
                      )}
                      {fileData?.fileCategory === 'Audio' && (
                        <div className="w-25 h-80 d-flex justify-content-center align-items-center audio-list border-pale-pink">
                          <PlayIcon className="play" onClick={playUploadedVideo} />
                        </div>
                      )}
                      <div className="d-flex justify-content-end">
                        <button
                          className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                          onClick={uploadedVideoCloseBtnHandler}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none shadow-none"
                          onClick={onSubmit}
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  }

                  {existingMediaData !== undefined && (
                    <div>
                      {existingMediaData?.fileCategory === 'Audio' && (
                        <div>
                          <Close className="audio-close-icon" onClick={closeAudioFile} />
                          <div className="audio-list">
                            <MikeIconWhite className="mike" />
                            <PlayIcon className="play" onClick={showMediaModalHandler} />
                          </div>
                        </div>
                      )}
                      {existingMediaData?.fileCategory === 'Video' && (
                        <div>
                          <Close className="audio-close-icon" onClick={closeVideoFile} />
                          <div className="video-list">
                            <SendVideoIcon className="mike" />
                            <PlayIcon className="play" onClick={showMediaModalHandler} />
                          </div>
                        </div>
                      )}
                      <div className="p-0 m-0 mt-5 d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                          <VideoIconActive onClick={toggleVideoModal} />
                          <AudioIconInactive className="ml-2" onClick={toggleAudioModal} />
                        </div>
                        <div className="d-flex ">
                          <button
                            className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                            onClick={textMessageCancelHandler}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="w-180 btn bg-primary-color color-white h-60 font-sofia-pro-semi-bold font-16 outline-none shadow-none"
                            onClick={onSubmit}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <Modal
                    className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
                    show={mediaModal}
                    onHide={hideMediaModalHandler}
                    animation={false}
                  >
                    <Modal.Header className="p-0 m-0 border-0">
                      <button
                        type="button"
                        className="close switch-to-account-modal__close__icon"
                        onClick={hideMediaModalHandler}
                      >
                        <Close />
                      </button>
                    </Modal.Header>
                    <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                      {existingMediaData?.fileCategory === 'Audio' && (
                        <audio controls preload="none">
                          <source src={existingMediaData?.url} type="audio/mpeg" />
                        </audio>
                      )}

                      {existingMediaData?.fileCategory === 'Video' && (
                        <video width="320" height="240" controls>
                          <source src={existingMediaData?.url} type="video/mp4" />
                        </video>
                      )}
                    </Modal.Body>
                  </Modal>
                  <Modal
                    className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
                    show={uploadModal}
                    onHide={hideUploadModalHandler}
                    animation={false}
                  >
                    <Modal.Header className="p-0 m-0 border-0">
                      <button
                        type="button"
                        className="close switch-to-account-modal__close__icon"
                        onClick={hideUploadModalHandler}
                      >
                        <Close />
                      </button>
                    </Modal.Header>
                    <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                      <div className="mt-2">
                        <div className="d-flex justify-content-between">
                          <div>
                            <label className="p-0 m-0 font-sofia-pro-regular color-charcoal font-16">Browse file</label>
                          </div>
                        </div>
                        <div className="p-0 m-0 card w-100 border-0">
                          <label htmlFor="file-input">
                            <div className="invite__employee-upload-style">
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="font-14 font-sofia-pro-regular color-grey-blue">Upload your favourite Tik Tok or a marketing video. Make sure you save your videos to a folder on your desktop.</div>
                                <FolderIcon />
                                {fileName === null && (
                                  <div>
                                    <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                      <span className="font-16 font-sofia-pro-regular color-primary">
                                        <u>Browse file </u>
                                      </span>
                                    </p>
                                    <span className="font-14 font-sofia-pro-regular color-grey-blue">
                                      Supports MP4, MP3, MOV, AVI, WEBM, WAV, MPEG, AVC
                                      <p>(Only 15 Sev Videos)</p>
                                    </span>
                                  </div>
                                )}
                                {fileName !== null && (
                                  <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                    <u>{fileName}</u>
                                  </p>
                                )}
                              </div>
                              <input
                                id="file-input"
                                type="file"
                                accept="audio/*,video/*"
                                onChange={getusersListFile}
                                onClick={event => {
                                  event.target.value = null;
                                }}
                              />
                            </div>
                          </label>
                          <div className="d-flex justify-content-end">
                            <button
                              className="p-0 m-0 mr-4 border-0 bg-transparent font-14 color-gunmetal font-sofia-pro-medium outline-none shadow-none"
                              onClick={hideUploadModalHandler}
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="w-150 btn bg-primary-color color-white h-40 font-sofia-pro-semi-bold font-14 outline-none shadow-none"
                              disabled={disableUploadBtn}
                              onClick={uploadBtnHandler}
                            >
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>

                  <Modal
                    className="border-0 modal-background-shadow switch-to-account-modal"
                    show={showUploadVideoPlayModal}
                    onHide={hideUploadVideoPlayModalHandler}
                    animation={false}
                  >
                    <Modal.Header className="p-0 m-0 py-2 border-0">
                      <button
                        type="button"
                        className="close switch-to-account-modal__close__icon"
                        onClick={hideUploadVideoPlayModalHandler}
                      >
                        <Close />
                      </button>
                    </Modal.Header>
                    <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
                      {fileData?.fileCategory === 'Video' && (
                        <video width="320" height="240" controls>
                          <source src={fileData?.url} type="video/mp4" />
                        </video>
                      )}

                      {fileData?.fileCategory === 'Audio' && (
                        <audio controls preload="none">
                          <source src={fileData?.url} type="audio/mpeg" />
                        </audio>
                      )}
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4 mt-md-4 mt-sm-3">
            <div className="card border-0  border-radius-5">
              <div className="card-body d-flex flex-column">
                <span className="color-gunmetal font-16 font-sofia-pro-medium">Available balance</span>
                <span className="font-56 color-black-pearl font-sofia-pro-medium">{availableBalance}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RecordAudio
        ref={audioModalRef}
        selectAudio={selectAudio}
        audioModel={audioModel}
        toggleAudioModal={toggleAudioModal}
        toggleVideoModal={toggleVideoModal}
        audioResponse={audioResponse}
      />
      <RecordVideo
        ref={videoModalRef}
        selectVideo={selectVideo}
        videoModel={videoModel}
        toggleAudioModal={toggleAudioModal}
        toggleVideoModal={toggleVideoModal}
        videoResponse={videoResponse}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    getRecipientInformation: state.sendThanks.sendThanksData,
    searchData: state.header.searchData,
  };
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    recipientInformation: data => dispatch(sendThanksDetails(data)),
    resetData: () => dispatch(resetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForewardThanks);
