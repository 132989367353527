/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as ReactBootstrap from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import './InviteEmployees.scss';
import Toast from '../../../../services/ToasterService';
import Button from '../../../../common/Button/Button';
import Stepper from '../../../../common/Stepper/Stepper';
import OrganisationService from '../../../../services/OrganisationService';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import IconsCheckFilledImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as FolderIcon } from '../../../../assets/images/common/icons-folder.svg';
import SampleCSVFormatImage from '../../../../assets/images/common/sample_csv_staff.png';
import CSV from '../../../../common/csv/employees.csv'
import BackIcon from '../../../../assets/images/common/icons-back.png';

const initialValues = {
  email: null,
};

function OrganisationInviteEmployeesPage(props) {
  const history = useHistory();

  const [showLoader, setShowLoader] = useState(false);

  const [fileName, setFileName] = useState(null);

  const [checkFileList, setCheckFileList] = useState(false);

  const [fileData, setFileData] = useState(null);

  const [show, setShow] = useState(false);

  const [steps, setSteps] = useState([
    {
      title: 'Organisation name',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Invite Employees',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Payment',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Finish',
      icon: IconsCheckFilledImage,
    },
  ]);

  const [currentStep, setCurrentStep] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const onSubmit = values => {
    toast.dismiss();

    if (values.email !== null || fileData !== null) {
      let data = {
        csvemail: null,
        csvfilebase64: null,
      };
      if (checkFileList === false) {
        data.csvemail = values.email;
        data.csvfilebase64 = null;
      } else if (checkFileList === true) {
        data.csvemail = null;
        data.csvfilebase64 = fileData;
      }

      OrganisationService.uploadEmployeesFileData(data)
        .then(response => {
          if (response) {
            history.push({
              pathname: '/loading',
              state: {
                role: 'Organisation',
              },
            });
          }
        })
        .catch(error => {
          if (error) {
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (error) {
              let errorResponse = error.response['data'];
              console.log('error response field errors ', errorResponse);
            }
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors?.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  Toast(msg.message, 'error');
                });
              }
            }
          }
        });
    } else {
      Toast('Either text csv or file csv must be submitted', 'error');
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    setShowLoader(false);
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };
  const getEmployeesListFile = e => {
    setFileName(e.target.files[0].name);
    let base64Data = '';
    getBase64(e.target.files[0], result => {
      base64Data = result.split(',')[1];
      setFileData(base64Data);
    });
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const skipBtnHandler = () => {
    history.push({
      pathname: '/loading',
      state: {
        role: 'Organisation',
      },
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <div className="bg-alice-blue">
      <div className="p-2">
        <Logo />
      </div>
      <div className="mx-lg-5 mx-md-2 my-2 p-lg-4 p-md-2 bg-white">
        <div className="p-0 m-0 row">
          <div className="col-md-2 col-lg-1">&nbsp;</div>
          <div className="col-sm-12 col-md-8 col-lg-10">
            <div className="mb-3">
              <Link to="/organisation/plans">
                <button className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none">
                  <img src={BackIcon} height="30px" />
                  Back
                </button>
              </Link>
            </div>
            <div>
              <Stepper steps={steps} activeStep={currentStep} />
            </div>

            <div>
              <div className="p-0 m-0 p-3 card">
                <span className="font-sofia-pro-semi-bold font-36 color-black-pearl">Invite your employees</span>
                <form className="mt-3" onSubmit={formik.handleSubmit}>
                  <p className="font-sofia-pro-light font-16 color-gunmetal">
                    Discover the benefits of Appreci by inviting employees, Allocate thanks to your employees and start
                    appreciating today!
                  </p>
                  <div className="invite__employees">
                    <ReactBootstrap.Tabs
                      defaultActiveKey="cafeDetails"
                      id="uncontrolled-tab-example"
                      className="invite__employees-tabs"
                    >
                      <ReactBootstrap.Tab
                        className="font-16"
                        eventKey="cafeDetails"
                        title="Add list of email addresses"
                        onClick={() => {
                          setCheckFileList(false);
                          setFileData(null);
                          setFileName(null);
                        }}
                      >
                        <div className="p-0 m-3 p-3 card border-0 bg-alice-blue">
                          <textarea
                            cols="20"
                            rows="8"
                            className="border-0 form-control font-sofia-pro-light invite__employees-textarea-input"
                            placeholder="name@example.com, name2@example.com"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          ></textarea>
                        </div>
                        <span className="my-1 pl-3 font-14 font-sofia-pro-light color-gunmetal">
                          Note: Separate the email using comma( , )
                        </span>
                      </ReactBootstrap.Tab>
                      <ReactBootstrap.Tab
                        className="font-16"
                        eventKey="transactions"
                        title="Upload from file"
                        onClick={() => {
                          setCheckFileList(true);
                          formik.setFieldValue('email', '');
                        }}
                      >
                        <p className="mt-3 pl-3 font-14 font-sofia-pro-light color-gunmetal">
                          You can upload CSV file. Include column headers firstname, lastname,email and role.
                          <a className="color-primary cursor-pointer" onClick={handleShow} href={CSV} download='cvs staff'>
                            Here is the sample file format.
                          </a>
                        </p>
                        <div className="p-0 m-3 card border-0">
                          <label htmlFor="file-input">
                            <div className="invite__employee-upload-style">
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <FolderIcon />
                                {fileName === null && (
                                  <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                    Drag and drop your file here or{' '}
                                    <span className="font-16 font-sofia-pro-regular color-primary">
                                      <u>Choose file </u>
                                    </span>
                                  </p>
                                )}
                                {fileName !== null && (
                                  <p className="p-0 m-0 font-16 font-sofia-pro-light color-black-pearl">
                                    <u>{fileName}</u>
                                  </p>
                                )}
                              </div>
                              <input
                                id="file-input"
                                type="file"
                                accept=".csv"
                                onChange={getEmployeesListFile}
                                onClick={event => {
                                  event.target.value = null;
                                }}
                              />
                            </div>
                          </label>
                        </div>
                      </ReactBootstrap.Tab>
                    </ReactBootstrap.Tabs>
                  </div>
                  <div className="d-flex">
                    <Button
                      classNameValue="ml-3 my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                      children="Submit"
                      showLoader={showLoader}
                      onClick={() => onSubmit}
                    />
                    <button
                      className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-transparent font-14 font-sofia-pro-regular color-gunmetal"
                      onClick={skipBtnHandler}
                    >
                      Skip for now
                    </button>
                  </div>
                </form>
              </div>
            </div>

            <Modal
              className="pt-4 border-0 modal-background-shadow switch-to-account-modal"
              show={show}
              onHide={handleClose}
              animation={false}
            >
              <Modal.Header className="p-0 m-0 border-0">
                <button type="button" className="close switch-to-account-modal__close__icon" onClick={handleClose}>
                  <Close />
                </button>
              </Modal.Header>
              <Modal.Body className="p-0 m-0 px-1 pb-5 border-0 text-center">
                <h4 className="p-2 font-sofia-pro-regular color-black-pearl">Sample CSV format</h4>
                <div className="mt-2">
                  <img src={SampleCSVFormatImage} className='mw-100'/>
                </div>
              </Modal.Body>
            </Modal>
          </div>
          <div className="col-md-2 col-lg-1">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}

export default OrganisationInviteEmployeesPage;
