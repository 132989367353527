import React, {useState, useEffect} from 'react';
import Button from '../../../common/Button/Button';
import CustomDropdown from '../../../common/CustomDropDown/CustomDropdown';
import Summary from './Summary';
import QuantityBox from './QuantityBox.js';


const Preferences = ({formik, showLoader, handleBack, handleShowSummary}) => {
    const [oneTime, setOneTime] = useState(true);

    const [recurring, setRecurring] = useState(false);
    const [active, setActive] = useState(0);


    const options = [
        "When I cancel it"
    ]

    useEffect(()=> {
      if(oneTime == true) {
        formik.setFieldValue('giftType', 'One Time');
        formik.setFieldValue('repeatCount', 1);
      } else if(recurring == true) {
        formik.setFieldValue('giftType', 'Recurring');
        formik.setFieldValue('repeatCount', null);
      } else {
        formik.setFieldValue('repeatCount', null);
      }
    }, [oneTime, recurring])
  return (
      <>
      {formik.values.quantity ? <QuantityBox quantity={formik.values.quantity} total={formik.values.total} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
      <div className='d-flex flex-column position-relative' style={{marginTop: '80px'}}>
      <span className="font-sofia-pro-regular font-32 color-black-pearl text-center">Setup your gifting preferences</span>
      <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
      Select how you would like your gifts to be sent.
      </p>
      </div>
      <div className='mt-3 mb-5 d-flex justify-content-center align-items-center flex-column flex-wrap'>
      <div className='d-flex coffee-box-style justify-content-center'>
          <div className='d-flex flex-column align-items-start flex-wrap'>
          <form className="mt-3" onSubmit={formik.handleSubmit}>
              <div className="w-100 p-0 b-0 pb-1 form-group">
              <label className="font-sofia-pro-regular color-charcoal font-16">Schedule type</label>
              <div className="organisation__user-tabs">
                <div className={`d-flex ${oneTime? 'left-gift-active' : 'left-gift'} font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center color-gunmetal`}
                onClick={() => {
                  formik.setFieldValue('giftType', 'One Time');
                  formik.setFieldValue('repeatCount', 1);
                  setOneTime(true);
                  setRecurring(false);
                }}>
                  {/* Left content */}
                <span>One time</span>
                </div>
                <div className={`d-flex ${recurring? 'right-gift-active' : 'right-gift'} font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center color-gunmetal`}
                onClick={() => {
                  formik.setFieldValue('giftType', 'Recurring');
                  formik.setFieldValue('repeatCount', null);
                  setRecurring(true);
                  setOneTime(false);
                }}>
                  {/* Right content */}
                  <span>Recurring</span>
                </div>
              </div>
              </div>
              {recurring && <>
              <div className="w-100 p-0 b-0 pb-1 form-group">
              <label className="font-sofia-pro-regular color-charcoal font-16">Gift frequency</label>
              <div className="organisation__user-tabs">
                <div className={`d-flex left-tab ${active == 1 ? 'tab-active' : 'tab'} font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center color-gunmetal`}
                onClick={() => {
                  formik.setFieldValue('giftFrequency', 'Weekly');
                  setActive(1);
                }}>
                  {/* Left content */}
                <span>Weekly</span>
                </div>
                <div className={`d-flex ${active == 2 ? 'tab-active' : 'tab'} font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center color-gunmetal`} 
                onClick={() => {
                  formik.setFieldValue('giftFrequency', 'Fortnightly');
                  setActive(2);
                }}>
                  <span>Fortnightly</span>
                </div>
                <div className={`d-flex ${active == 3 ? 'tab-active' : 'tab'} font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center color-gunmetal`}
                onClick={() => {
                  formik.setFieldValue('giftFrequency', 'Monthly');
                  setActive(3);
                }}>
                  {/* Right content */}
                  <span>Monthly</span>
              </div>
                <div className={`d-flex right-tab ${active == 4 ? 'tab-active' : 'tab'} font-sofia-pro-semi-bold font-16 cursor-pointer justify-content-center align-items-center color-gunmetal`}
                onClick={() => {
                  formik.setFieldValue('giftFrequency', 'Yearly');
                  setActive(4);
                }}>
                  {/* Right content */}
                  <span>Yearly</span>
                </div>
              </div>
              </div>
              <div className="p-0 b-0 pb-1 form-group">
                  <label className="font-sofia-pro-regular color-charcoal font-16">Ending</label>
                  <CustomDropdown 
                    options={options} 
                    name="ending"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ending}
                  />
              </div></>}
              <div className="d-flex justify-content-between w-100">
              <Button
              classNameValue="twoBrand-back-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn font-16 font-sofia-pro-semi-bold"
              children="Back"
              showLoader={showLoader}
              onClick={handleBack}
              />
              <Button
              classNameValue="twoBrand-coffee my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
              children="Next"
              showLoader={showLoader}
              onClick={handleShowSummary}
              />
          </div>
          </form>
          </div>
      </div>
      </div>
    </>
  );
};

export default Preferences;
