/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import SendThanksService from '../../../../services/SendThanksService';
import './SelectProduct.scss';
import IconsBackImage from '../../../../assets/images/common/icons-back.png';
import ItemCheckedImage from '../../../../assets/images/common/icons-check-filled-pink.png';

const SelectProduct = () => {
  const [product, setProduct] = useState([]);
  const [indexValue, setIndexValue] = useState();
  const [productId, setProductId] = useState(null);
  const priceId = sessionStorage.getItem('priceId');

  toast.configure({
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    SendThanksService.getListOfProducts('AU')
      .then(res => {
        res.data.map((val, index) => {
            const rangeWithPriceId = val.ranges.filter(elem => {
              if (elem.priceId === priceId ){
                return elem
              }
            })
            if (rangeWithPriceId.length){
              setProduct(rangeWithPriceId);
            }
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  const BeerSelected = index => {
    setIndexValue(index);
    setProductId(product[index]['id']);
    sessionStorage.setItem('GiftProductId', product[index]['id']);
    sessionStorage.setItem(
      'GiftSuccessDetails',
      JSON.stringify({
        Url: product[index]['imagePath'],
        Name: product[index]['name'],
      })
    );
  };

  const ProceedBtn = () => {
    if (productId != null) {
      history.push('/redeem-details');
    } else {
      toast.error('Please choose a product type');
    }
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0  col-lg-2 col-sm-12 my-4  ">
        <SideBar menu="Redeemthanks" />
      </div>
      <div className=" p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half">
        <Header value="Redeemed" />
        <div className="d-flex">
          <Link to="/redeemThanks">
            <span className="p-2 m-4 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular">
              <img src={IconsBackImage} height="30px" width="30px" alt="" />
              Back
            </span>
          </Link>
        </div>
        <div className="">
          <span className="selectproduct-text_Select a product font-sofia-pro-light mx-4">Select a product</span>
          <div className=""></div>
        </div>

        <div className="selectproduct-rectangle__list m-3">
          <div className=" row  m-1">
            {product.map((val, index) => {
              return (
                <button className="col-12   col-lg-4 border-0 bg-transparent " onClick={BeerSelected.bind(this, index)}>
                  <div
                    key={index}
                    className={
                      indexValue === index
                        ? 'd-flex flex-column selectproduct-beer__selected position-relative my-2'
                        : 'd-flex flex-column position-relative selectproduct-beer__unselected '
                    }
                  >
                    {index === indexValue ? (
                      <img
                        src={ItemCheckedImage}
                        height="25px"
                        width="25px"
                        className='select-item__checked_icon'
                        alt=""
                      />
                    ) : null}

                    <div className="d-flex flex-column">
                      <div className=" border-0 selectproduct-image__background mx-2 d-flex justify-content-center align-items-center my-2">
                        <img src={val['imagePath'] + '?h=120&w=120'} alt="" />
                      </div>
                      <span className="mx-3 selectproduct-text__beername font-sofia-pro-medium d-flex">
                        {val['name']}
                      </span>
                      <span className=" selectproduct-text__beerdiscription font-sofia-pro-light ">
                        {val['description']}
                      </span>
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
          <button
            className={
              productId != null
                ? 'border-0 selectproduct-button__proceed m-4'
                : 'border-0 unselectproduct-button__proceed m-4'
            }
            onClick={ProceedBtn}
          >
            <span
              className={
                productId != null
                  ? 'redeem-text__proceed font-sofia-pro-semi-bold'
                  : 'selectproduct-text__proceedunselected font-sofia-pro-semi-bold'
              }
            >
              Proceed
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectProduct;
