import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import HttpService from '../../services/HttpService';
import commonService from '../../services/CommonService';
import * as RegexPatterns from '../../utils/RegexPatterns';
import IconClose from '../../assets/images/common/icons-close-red.png';

function loopFieldErrors(data) {
  for (var i in data) {
    var itemSubArray = data[i];
    for (var j in itemSubArray) {
      toast.error(itemSubArray[j]);
    }
  }
}

async function verifyUsername(username) {
  let data = {
    search: null,
    email: null,
    phone: null,
    userName: username,
    region: 'AU',
  };
  try {
    const response = await HttpService.post(`/users/search`, data);
    if (response) {
      let res = response['data'];
      if (res?.length > 0) {
        return true;
      } else {
        toast.error('User not found');
        return false;
      }
    }
  } catch (error) {
    if (error) {
      let errorResponseFieldErrors = error.response['data'].fieldErrors;
      let errorResponseGlobalErrors = error.response['data'].globalErrors;
      let errorResponse = error.response['data'];
      console.log('error response field errors ', errorResponse);
      loopFieldErrors(errorResponseFieldErrors);
      if (errorResponseGlobalErrors) {
        if (errorResponseGlobalErrors.length > 0) {
          errorResponseGlobalErrors.forEach(msg => {
            toast.error(msg.message);
          });
        }
      }
    }
    return false;
  }
}

async function verifyPhoneNumber(phoneNumber) {
  let data = {
    region: 'AU',
    numbers: [phoneNumber],
  };

  const response = await commonService.ValidateAUMobileNumber(data);
  if (response) {
    let data = response['data'];
    if (data[phoneNumber] === true) {
      return true;
    } else {
      toast.error('Please enter a valid mobile number');
      return false;
    }
  }
}

async function parseRecipient(recipientText) {
  const recipientTextTrimmed = recipientText.trim();
  if (recipientTextTrimmed.startsWith('@')) {
    const recipientUsername = recipientTextTrimmed.slice(1);
    const verified = await verifyUsername(recipientUsername);
    if (verified) {
      return recipientUsername;
    } else {
      return null;
    }
  }
  if (RegexPatterns.MobileRegexValue.test(recipientTextTrimmed)) {
    const mobileNumber = '+61' + recipientTextTrimmed;
    const verified = await verifyPhoneNumber(mobileNumber);
    if (verified) {
      return mobileNumber;
    } else {
      return null;
    }
  }
  if (RegexPatterns.EmailRegexValue.test(recipientTextTrimmed)) {
    return recipientTextTrimmed;
  } else {
    toast.error('Please enter a valid email or mobile number or username.');
    return null;
  }
}

export default function RecipientsInput({ tags, onChange, type }) {
  const [error, setError] = useState(false);

  let tagInputRef = useRef('');

  const removeTag = i => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    onChange?.(newTags);
  };

  /** Check if the new recipient is ready to be added to the list */
  // const validateNewRecipient = recipient => {
  //   let userData = JSON.parse(localStorage.getItem('userData'));
  //   if (userData?.email === recipient || userData?.phone === recipient || userData?.userName === recipient) {
  //     toast.warning('Purchaser may not be a gift recipient');
  //     return false;
  //   }
  //   if (tags.find(tag => tag.toLowerCase() === recipient.toLowerCase())) {
  //     toast.warning('Already added');
  //     return false;
  //   }
  //   return true;
  // };

  /** Try to add new recipient to the list based on the input text. */
  const tryToAddNewRecipient = async recipientText => {
    if (recipientText.length === 0) {
      return false;
    }

    const recipient = await parseRecipient(recipientText);
    if (!recipient) {
      return false;
    }

    // const recipientIsValid = validateNewRecipient(recipient);
    // if (!recipientIsValid) {
    //   return false;
    // }

    onChange?.([...tags, recipient]);
    return true;
  };

  const handleInputKeyDown = async e => {
    if ([' ', 'Tab', 'Enter', ',', ':', ';'].includes(e.key)) {
      e.preventDefault();

      toast.dismiss();

      const recipientText = e.target.value;
      const newRecipientAdded = await tryToAddNewRecipient(recipientText);
      if (newRecipientAdded) {
        e.target.value = '';
        setError(false);
      } else {
        setError(true);
      }
    } else if (e.key === 'Backspace' && e.target.value.length === 0) {
      e.preventDefault();
      // removeTag(tags.length - 1);
    }
  };

  const handleInputBlur = async e => {
    const recipientText = e.target.value;
    if (recipientText.length > 0) {
      const newRecipientAdded = await tryToAddNewRecipient(recipientText);
      if (newRecipientAdded) {
        e.target.value = '';
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  return (
    <div
      // className={`w-100 p-2 h-40 bg-white font-sofia-pro-regular font-14 d-table  z-index-1 recipient-information__input__tag ${
      //   type ? 'border-none border-radius-100 ' : 'border-radius-8'
      // }${error ? 'error' : ''}`}
    >
      <ul className="recipient-information__input__tag__tags">
        {tags?.map((tag, i) => (
          <li className="p-2 mr-2 mb-3 bg-alice-blue border-radius-5 d-flex justify-content-between" key={tag} style={{width: '100%'}}>
            <span>{ tag?.length > 30 ?  tag?.slice(0,30) + '...' : tag}</span>
            <span
              className="ml-4 cursor-pointer"
              onClick={() => {
                removeTag(i);
              }}
            >
              <img src={IconClose} height="18px" alt="" />
            </span>
          </li>
        ))}
        <li className="recipient-information__input__tag__tags__input">
          <input
            className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none font-14"
            type="text"
            placeholder={
              tags?.length === 0 ? (type ? '' : 'Enter Email or mobile number or username with prefix @') : ''
            }
            onKeyDown={handleInputKeyDown}
            ref={c => {
              tagInputRef.current = c;
            }}
            onBlur={handleInputBlur}
          />
        </li>
      </ul>
    </div>
  );
}
