/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import { useLocation, Link } from 'react-router-dom';
import RedeemEmpty from '../../../../../assets/images/dashboard/image/components-illustrations-empty-state-2.png';
import { ReactComponent as SentActive } from '../../../../../assets/images/dashboard/icons/icons-main-navigation-sent-active.svg';
import SideBar from '../../../../../common/Sidebar/Sidebar';
import Header from '../../../../../common/Header/Header';
import SendThanksService from '../../../../../services/SendThanksService';
import '../../../../../components/individual/dashboard/DashBoard.scss';
import './Redeemthanks.scss';
import httpService from '../../../../../services/HttpService';
import { ReactComponent as Remove } from '../../../../../assets/images/redeem_thanks/icons/icons-remove-16-px (1).svg';
import Add from '../../../../../assets/images/redeem_thanks/icons/icons-add-pink.png';
import RedeemModal from '../../Redeem/RedeemModal';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../../../../../assets/images/dashboard/icons/icons-close-white.svg';
import CommonService from '../../../../../services/CommonService';
import useWindowDimensions from '../../../../../common/Responsive/WindowDimensions';
import Toast from '../../../../../services/ToasterService';
import GiftMessageVideo from '../../../../common/GiftMessageVideo';
import { MikeIconWhite, PlayIcon } from '../../../../../assets/images';
import ModalVideoAudio from '../../../../../common/component/modal/ModalVideoAudio';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RedeemThanks = props => {
  const { width } = useWindowDimensions();
  let query = useQuery();

  const CafeName = sessionStorage.getItem('CafeName');
  const [redeemList, setRedeemList] = useState([]);
  const [product, setProduct] = useState([]);
  const [productType, setProductType] = useState(null);
  const [redeemData, setRedeemData] = useState([]);
  const [audioVideoUrl, setAudioVideoUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [quantityValue, setQuantityValue] = useState(1);
  let [redeePagecount, setRedemPageCount] = useState(1);
  const [availableQuantity, setAvailableQuantity] = useState('');
  const [quantityModalShow, setQuantityModalShow] = useState(false);
  const [anonymousShow, setAnonymousShow] = useState(false);
  const LocationId = query.get('locationId');
  const SearchCafeName = sessionStorage.getItem('CafeName');

  useEffect(() => {
    SendServiceServiceList();
    RedeemServiceList(redeePagecount);
    window.scrollTo(0, 0);
  }, []);

  const RedeemServiceList = count => {
    httpService
      .get(`Gifts?pageNumber=${count}`)
      .then(res => {
        const data = res.data['data'];

        if (count > 1) {
          Array.prototype.push.apply(redeemList, data);
        } else {
          setRedeemList(data);
        }
        setRedeemData(res.data);
      })
      .catch(error => {
        if (error) {
        }
      });
  };
  const PropsOnHide = () => {
    setQuantityModalShow(false);
    setQuantityValue(1);
  };
  const SkipCode = index => {
    navigator.clipboard.writeText(redeemList[index]['redeemCode']);
    setTimeout(() => {
      window.location.assign(redeemList[index]['redeemUrl'])
    }, 1000);
    Toast('Skip code copied', 'success');
  };
  const ProceedBtn = () => {
    sessionStorage.setItem('SelectedQuantity', quantityValue);
    return (
      <>
        <Link to='/select-product'>
          <button className='border-0 redeem-button__proceed my-3'>
            <span className='redeem-text__proceed font-sofia-pro-semi-bold'>Proceed</span>
          </button>
        </Link>
      </>
    );
  };

  const AnnonymousError = props => {
    return (
      <>
        <Modal {...props} size='md' centered className='redeem-background_popup' animation={false} backdrop='static'>
          <Modal.Body>
            <div className='font-sofia-pro-medium d-flex justify-content-center align-items-center mx-3'>
              To claim this gift, you should signup using the same email/phone number on which you received it.
            </div>
            <div className='d-flex my-3 align-items-center justify-content-center'>
              <button className='border-0 bg-primary-color ' onClick={props.onHide}>
                <span className='redeemthanks-text__Cancel  font-sofia-pro-medium'>OK</span>
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const SelectQuantity = props => {
    return (
      <Modal {...props} size='md' centered className='redeem-background_popup' animation={false} backdrop='static'>
        <Modal.Body className='d-flex flex-column align-items-center '>
          <button className='border-0 bg-transparent redeem-quantity__cancel ' onClick={PropsOnHide}>
            <Close />
          </button>
          <span className='redeem-text__How-many-coffees-wo font-sofia-pro-medium'>
            How many {productType !== null ? productType : ''} would you like to redeem?
          </span>
          <div className='row justify-content-center my-4'>
            <button
              className='border-0 bg-transparent'
              onClick={() => (quantityValue > 1 ? setQuantityValue(quantityValue - 1) : '')}
            >
              <Remove />
            </button>
            <div className='redeem-box__selectedvalue d-flex justify-content-center align-items-center'>
              <span>{quantityValue}</span>
            </div>
            <button
              className='border-0 bg-transparent '
              onClick={() => (quantityValue < availableQuantity ? setQuantityValue(quantityValue + 1) : '')}
            >
              <img src={Add} />
            </button>
          </div>

          <ProceedBtn />
          <button className='border-0 bg-transparent' onClick={props.onHide}>
            <span className='redeem-text__Cancel font-sofia-pro-medium'>Cancel</span>
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  const SendServiceServiceList = () => {
    SendThanksService.getListOfProducts('AU').then(res => {
      setProduct(
        res.data.map(val => {
          return val;
        })
      );
    });
  };

  product.map(value => {
    redeemList.map((val, index) => {
      value.prices.map(item => {
        if (item.priceId === val.priceId) {
          val['type'] = value['type'];
          val['range'] = value['ranges'];
        }
      })
      if (value?.features === 'Exchange') {
        redeemList[index]['features'] = value?.features
        redeemList[index]['exchangePriceId'] = value?.prices[0]?.priceId;
      }
      else if (value?.redemptionType == "Location"){
        redeemList[index]['features'] = "Exchange";
      }
      else if (redeemList[index]['redeemCode'] == null && redeemList[index]['redeemUrl'] !== null){
        redeemList[index]['features'] = value?.features;
      }
    });
  });
  const TransactionIdpass = index => {
    JSON.stringify(sessionStorage.setItem('TransactionId', redeemList[index]['id']));
    JSON.stringify(sessionStorage.setItem('redeemInfo', JSON.stringify({
      features: redeemList[index]?.features,
      exchangePriceId: redeemList[index]?.exchangePriceId
    })));
    JSON.stringify(sessionStorage.setItem('Quantity', redeemList[index]['quantity']));
    JSON.stringify(sessionStorage.setItem('Type', redeemList[index]['type']));
    JSON.stringify(sessionStorage.setItem('priceId', redeemList[index]['priceId']));
    if ((redeemList['type'] = 'wine')) {
      JSON.stringify(sessionStorage.setItem('FromName', redeemList[index]['from']['fullName']));
    }
    setAvailableQuantity(redeemList[index]['quantity']);
    if ((redeemList['type'] = 'beer')) {
      setQuantityModalShow(true);
    }
    setProductType(redeemList[index]['type']);
  };

  const ProductTypePass = index => {
    let data = {
      Type: redeemList[index]['type'],
      PriceId: redeemList[index]['priceId']
    };
    sessionStorage.setItem('ProductType', JSON.stringify(data));
    JSON.stringify(sessionStorage.setItem('TransactionId', redeemList[index]['id']));
  };
  const Page = () => {
    setRedemPageCount((redeePagecount = redeePagecount + 1));
    RedeemServiceList(redeePagecount);
  };

  const AudioMessageShow = index => {
    setShowModal(true);
    let guid = redeemList[index].message?.storedFile;
    let fileSecret = redeemList[index].message?.storedFileSecret;

    CommonService.getMediaFile(fileSecret, guid).then(response => {
      if (response) {
        let data = response['data'];
        setAudioVideoUrl(data);
      }
    });
  };

  return (
    <div className=' m-0 p-0 row '>
      <div className='p-0 m-0 card border-0  col-lg-2 col-sm-12 my-4  '>
        <SideBar menu='Redeemthanks' />
      </div>
      <div
        className={
          redeemList.length > 3
            ? ' p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half'
            : 'h-100vh p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half'
        }
      >
        <Header value='Redeemed' />
        {LocationId !== null ? (
          <span className='m-3 font-sofia-pro-light redeem-searchcafename   '>
            Redeemable products at {SearchCafeName}
          </span>
        ) : null}

        {redeemList.length > 0 ? (
          <>
            {width > 700 ? (
              <div
                className='mx-lg-3  card dashboard-card__redeemthanks my-3  table-responsive-md table-responsive-sm border-0 '>
                <table className='table dashboard-table_headcolor '>
                  <thead style={{ backgroundColor: '#fff2fa ' }}>
                  <tr className='mx-2'>
                    <td scope='col' className='font-sofia-pro-semi-bold'>
                      From
                    </td>
                    <td scope='col' className='font-sofia-pro-semi-bold  '>
                      Message
                    </td>
                    <td scope='col' className='font-sofia-pro-semi-bold  '>
                      Received On
                    </td>

                    <td scope='col' className='font-sofia-pro-semi-bold '>
                      Actions
                    </td>
                    <td scope='col' className='font-sofia-pro-semi-bold '>
                      Product
                    </td>
                    <td scope='col' className='font-sofia-pro-semi-bold '>
                      Quantity
                    </td>
                  </tr>
                  </thead>
                  <tbody>
                  {redeemList.map((value, index) => {
                    const date = value.redeemedOn || value.createdOn;
                    let stilUtc = Moment.utc(date).toDate();
                    let local = Moment(stilUtc).local();

                    if (LocationId !== null && value['type'] === 'Coffee') {
                      return (
                        <tr key={index} className='dashboard-row_nthchild'>
                          <td className='font-sofia-pro-semi-bold '>
                            {value['from'] === '' ? 'Unknown' : value['from']['fullName']}
                          </td>
                          {value['message'] != null ? (
                            value['message']['type'] === 'Text' && value['message']['message'].trim().length !== 0 ? (
                              <td className=' font-sofia-pro-regular text-break '>{value['message']['message']}</td>
                            ) : value['message']['type'] === 'Video' ? (
                              <td className=' font-sofia-pro-regular text-break '>
                                <GiftMessageVideo
                                  fileId={value['message']['storedFile']}
                                  fileSecret={value['message']['storedFileSecret']}
                                  onClick={AudioMessageShow.bind(this, index)}
                                />
                              </td>
                            ) : value['message']['type'] === 'Audio' ? (
                              <td className=' font-sofia-pro-regular text-break '>
                                <div className='audio-list-class audio-list-class_100'>
                                  <MikeIconWhite className='mike' />
                                  <PlayIcon className='play' onClick={AudioMessageShow.bind(this, index)} />
                                </div>
                              </td>
                            ) : (
                              <td className='font-sofia-pro-regular text-break'>Unknown</td>
                            )
                          ) : (
                            <td className='font-sofia-pro-regular text-break'>No message</td>
                          )}
                          <td className=' font-sofia-pro-regular'>{Moment(local).format('DD/MM/YYYY')}</td>

                          <td>
                            <div d-flex flex-column>
                              <div>
                                {
                                  <RedeemModal
                                    AvailableQuantity={value['quantity']}
                                    TransactionId={value['id']}
                                    LocationId={LocationId}
                                    CoffeShopName={CafeName}
                                    menu='redeemthanks'
                                    Path='RedeemTanksScreen'
                                  />
                                }
                              </div>
                              <div></div>
                              {value['type'] !== 'GiftCard' ? (
                                <div className='my-2'>
                                  <Link style={{ textDecoration: 'none' }} to='payitforward'>
                                    <button
                                      className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                      onClick={ProductTypePass.bind(this, index)}
                                    >
                                      <span className='font-sofia-pro-medium dashboard-text_redeem'>
                                        Pay it forward
                                      </span>
                                    </button>
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td className=' font-sofia-pro-regular'>
                            {value['features'] === 'Exchange' && value['redeemCode'] ? 'Skip' : value['type']}
                          </td>
                          <td className=' font-sofia-pro-regular'>{value['quantity']}</td>
                        </tr>
                      );
                    } else if (LocationId === null) {
                      return (
                        <tr key={index} className='dashboard-row_nthchild'>
                          <td className='font-sofia-pro-semi-bold '>
                            {value['from'] === '' ? 'Unknown' : value['from']['fullName']}
                          </td>
                          {value['features'] === 'Exchange' && value['redeemCode'] ? (
                            <span className='font-sofia-pro-light mx-2'>
                              You received
                              <span className='font-sofia-pro-bold'> SKIP code {value['redeemCode']}</span>
                            </span>
                          ) : value['message'] !== null ? (
                            value['message']['type'] === 'Text' ? (
                              value['message']['message'] !== null ? (
                                value['message']['message'].trim().length !== 0 ? (
                                  <td className=' font-sofia-pro-regular text-break '>{value['message']['message']}</td>
                                ) : (
                                  <td className=' font-sofia-pro-regular text-break '>No message</td>
                                )
                              ) : (
                                <td className=' font-sofia-pro-regular text-break '>No message</td>
                              )
                            ) : value['message']['type'] === 'Video' ? (
                              <td className=' font-sofia-pro-regular text-break '>
                                <GiftMessageVideo
                                  fileId={value['message']['storedFile']}
                                  fileSecret={value['message']['storedFileSecret']}
                                  onClick={AudioMessageShow.bind(this, index)}
                                />
                              </td>
                            ) : value['message']['type'] === 'Audio' ? (
                              <td className=' font-sofia-pro-regular text-break '>
                                <div className='audio-list-class audio-list-class_100'>
                                  <MikeIconWhite className='mike' />
                                  <PlayIcon className='play' onClick={AudioMessageShow.bind(this, index)} />
                                </div>
                              </td>
                            ) : (
                              <td className='font-sofia-pro-regular text-break'>Unknown</td>
                            )
                          ) : (
                            <td className='font-sofia-pro-regular text-break'>No message</td>
                          )}

                          <td className=' font-sofia-pro-regular'>{Moment(local).format('DD/MM/YYYY')}</td>

                          <td>
                            <div d-flex flex-column>
                              <div>
                                {value['type'] === 'Coffee' ? (
                                  <Link to='/redeem' style={{ textDecoration: 'none' }}>
                                    <button
                                      className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                      onClick={TransactionIdpass.bind(this, index)}
                                    >
                                      <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                    </button>
                                  </Link>
                                ) : value['type'] === 'Wine' ? (
                                  <Link to='/redeemwine' style={{ textDecoration: 'none' }}>
                                    <button
                                      className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                      onClick={TransactionIdpass.bind(this, index)}
                                    >
                                      <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                    </button>
                                  </Link>
                                ) : value['type'] === 'GiftCard' && value['features'] !== 'Exchange' ? (
                                  <a href={value['redeemUrl']} target='next'>
                                    <button
                                      className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'>
                                      <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                    </button>
                                  </a>
                                ) : value['features'] === 'Exchange' && value['redeemCode'] ? (
                                  <button
                                    className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                    onClick={SkipCode.bind(this, index)}
                                  >
                                    <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                  </button>
                                ) : (
                                  <div style={{ textDecoration: 'none' }}>
                                    <button
                                      className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                      onClick={TransactionIdpass.bind(this, index)}
                                    >
                                      <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                    </button>
                                  </div>
                                )}
                              </div>
                              <div>
                              </div>
                              {value['type'] !== 'GiftCard' ? (
                                <div className='my-2'>
                                  <Link style={{ textDecoration: 'none' }} to='payitforward'>
                                    <button
                                      className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                      onClick={ProductTypePass.bind(this, index)}
                                    >
                                      <span className='font-sofia-pro-medium dashboard-text_redeem'>
                                        Pay it forward
                                      </span>
                                    </button>
                                  </Link>
                                </div>
                              ) : null}
                            </div>
                          </td>
                          <td className=' font-sofia-pro-regular'>
                            {value['features'] === 'Exchange' && value['redeemCode'] ? 'Skip' : value['type']}
                          </td>
                          <td className=' font-sofia-pro-regular'>{value['quantity']}</td>
                        </tr>
                      );
                    }
                  })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className=''>
                {redeemList.map((value, index) => {
                  const date = value.redeemedOn || value.createdOn;
                  let stilUtc = Moment.utc(date).toDate();
                  let local = Moment(stilUtc).local();

                  if (LocationId !== null && value['type'] === 'Coffee') {
                    return (
                      <div key={index} className='redeemthanks-mobile__redeemscreen m-3'>
                        <div className='d-flex justify-content-between m-3'>
                          <span className='font-sofia-pro-semi-bold '>
                            {value['from'] === '' ? 'Unknown' : value['from']['fullName']}
                          </span>
                          <span className=' font-sofia-pro-regular'>{Moment(local).format('DD/MM/YYYY')}</span>
                        </div>
                        {value['message'] != null ? (
                          value['message']['type'] === 'Text' && value['message']['type'].trim() ? (
                            <span
                              className=' font-sofia-pro-regular text-break mx-3'>{value['message']['message']}</span>
                          ) : value['message']['type'] === 'Video' ? (
                            <div className='m-3'>
                              <GiftMessageVideo
                                fileId={value['message']['storedFile']}
                                fileSecret={value['message']['storedFileSecret']}
                                onClick={AudioMessageShow.bind(this, index)}
                              />
                            </div>
                          ) : value['message']['type'] === 'Audio' ? (
                            <span className=' font-sofia-pro-regular text-break '>
                              <div className='audio-list-class audio-list-class_100'>
                                <MikeIconWhite className='mike' />
                                <PlayIcon className='play' onClick={AudioMessageShow.bind(this, index)} />
                              </div>
                            </span>
                          ) : (
                            <span className='font-sofia-pro-regular text-break mx-3'>Unknown</span>
                          )
                        ) : (
                          <span className='font-sofia-pro-regular text-break mx-3'>No message</span>
                        )}

                        <div>
                          <div className='mx-3'>
                            <span className=' font-sofia-pro-regular'>{value['quantity']} </span>
                            <span className=' font-sofia-pro-regular'>
                              {value['features'] === 'Exchange' && value['redeemCode'] ? 'Skip' : value['type']}
                            </span>
                          </div>
                          <div className='d-flex flex-row  align-items-center mx-2'>
                            <div>
                              {
                                <RedeemModal
                                  AvailableQuantity={value['quantity']}
                                  TransactionId={value['id']}
                                  LocationId={LocationId}
                                  CoffeShopName={CafeName}
                                  menu='redeemthanks'
                                  Path='RedeemTanksScreen'
                                />
                              }
                            </div>
                            <div></div>
                            {value['type'] !== 'GiftCard' ? (
                              <div className='m-2'>
                                <Link style={{ textDecoration: 'none' }} to='payitforward'>
                                  <button
                                    className=' dashboard-button_redeem px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                    onClick={ProductTypePass.bind(this, index)}
                                  >
                                    <span className='font-sofia-pro-medium dashboard-text_redeem'>Pay it forward</span>
                                  </button>
                                </Link>
                              </div>
                            ) : null }
                          </div>
                        </div>
                      </div>
                    );
                  } else if (LocationId === null) {
                    return (
                      <div key={index} className='redeemthanks-mobile__redeemscreen m-3'>
                        <div className='d-flex justify-content-between m-3'>
                          <span className='font-sofia-pro-semi-bold '>
                              {value['from'] === '' ? 'Unknown' : value['from']['fullName']}
                          </span>
                          <span className=' font-sofia-pro-regular'>{Moment(local).format('DD/MM/YYYY')}</span>
                        </div>

                        {value['message'] != null ? (
                          value['message']['type'] === 'Text' && value['message']['type'].trim() ? (
                            value['message']['message'] !== null ? (
                              <span className=' font-sofia-pro-regular text-break mx-3'>
      {value['message']['message']}
        </span>
                            ) : (
                              <span className=' font-sofia-pro-regular text-break '>No message</span>
                            )
                          ) : value['message']['type'] === 'Video' ? (
                            <div className='m-3'>
                              <GiftMessageVideo
                                fileId={value['message']['storedFile']}
                                fileSecret={value['message']['storedFileSecret']}
                                onClick={AudioMessageShow.bind(this, index)}
                              />
                            </div>
                          ) : value['message']['type'] === 'Audio' ? (
                            <span className=' font-sofia-pro-regular text-break '>
        <div className='audio-list-class audio-list-class_100'>
        <MikeIconWhite className='mike' />
        <PlayIcon className='play' onClick={AudioMessageShow.bind(this, index)} />
        </div>
        </span>
                          ) : (
                            <span className='font-sofia-pro-regular text-break'>Unknown</span>
                          )
                        ) : (
                          <span className='font-sofia-pro-regular text-break mx-3'>No message</span>
                        )}

                        <div>
                          <div className='mx-3'>
                            <span className=' font-sofia-pro-regular'>{value['quantity']} </span>
                            <span className=' font-sofia-pro-regular'>
      {value['features'] === 'Exchange' && value['redeemCode'] ? 'Skip' : value['type']}
        </span>
                          </div>
                          <div className='d-flex flex-row  align-items-center mx-2'>
                            <div>
                              {value['type'] === 'Coffee' ? (
                                <Link to='/redeem' style={{ textDecoration: 'none' }}>
                                  <button
                                    className=' dashboard-button_redeemmobile px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                    onClick={TransactionIdpass.bind(this, index)}
                                  >
                                    <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                  </button>
                                </Link>
                              ) : value['type'] === 'Wine' ? (
                                <Link to='/redeemwine' style={{ textDecoration: 'none' }}>
                                  <button
                                    className=' dashboard-button_redeemmobile px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                    onClick={TransactionIdpass.bind(this, index)}
                                  >
                                    <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                  </button>
                                </Link>
                              ) : value['type'] === 'GiftCard' && value['features'] !== 'Exchange' ? (
                                <a href={value['redeemUrl']} target='next'>
                                  <button
                                    className=' dashboard-button_redeem px-lg-2 px-md-1 py-1  my-3 dashboard-button border-0'>
                                    <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                  </button>
                                </a>
                              ) : value['features'] === 'Exchange' && value['redeemCode'] ? (
                                <button
                                  className=' dashboard-button_redeem px-lg-2 px-md-1 py-1  my-3 dashboard-button border-0'
                                  onClick={SkipCode.bind(this, index)}
                                >
                                  <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                </button>
                              ) : (
                                <div style={{ textDecoration: 'none' }}>
                                  <button
                                    className=' dashboard-button_redeemmobile px-lg-2 px-md-1 py-1 dashboard-button border-0'
                                    onClick={TransactionIdpass.bind(this, index)}
                                  >
                                    <span className='font-sofia-pro-medium dashboard-text_redeem'>Redeem</span>
                                  </button>
                                </div>
                              )}
                            </div>
                            <div>
                            </div>
                            {value['type'] !== 'GiftCard' ? (
                              <div className='my-2'>
                                <Link style={{ textDecoration: 'none' }} to='payitforward'>
                                  <button
                                    className=' dashboard-button_redeemmobile  mx-1 dashboard-button border-0'
                                    onClick={ProductTypePass.bind(this, index)}
                                  >
                                    <span className='font-sofia-pro-medium dashboard-text_redeem'>Pay it forward</span>
                                  </button>
                                </Link>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </>
        ) : (
          <div className='d-flex flex-column align-items-center redeem-emptystate'>
            <img src={RedeemEmpty} alt='Empty' className='redeem-image__emptystate' />

            <span className='redeem-text__Start-by-sending-an font-sofia-pro-regular'>
              Start by sending an appreciation to your mate
            </span>
            <Link to='/recipientInformation'>
              <button className='redeem-button_view my-3'>
                <SentActive />
                <span className='redeem-text__view font-sofia-pro-regular'>Send</span>
              </button>
            </Link>
          </div>
        )}

        <div>
          {redeemData['pageCount'] > 1 && redeePagecount < redeemData['pageCount'] ? (
            <button onClick={Page} className='bg-transparent border-0 color m-3'>
              <span className='transactionhistory-text__Show-more font-sofia-pro-medium'>show more</span>
            </button>
          ) : null }
        </div>

        <ModalVideoAudio
          show={showModal}
          onHide={() => setShowModal(false)}
          audioVideoUrl={audioVideoUrl}
          setAudioVideoUrl={setAudioVideoUrl}
        />
        <SelectQuantity
          show={quantityModalShow}
          onHide={() => {
            setQuantityModalShow(false);
          }}
        />
        <AnnonymousError show={anonymousShow} onHide={() => setAnonymousShow(false)} />
      </div>
    </div>
  );
};
export default RedeemThanks;
