/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
const VideoProvider = videoRef =>
  new Promise(async resolve => {
    let stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    var options = {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000,
      mimeType: 'video/webm',
    };

    const mediaRecorder = new MediaRecorder(stream, options);
    let videoChunks = [];

    mediaRecorder.addEventListener('dataavailable', event => {
      videoChunks.push(event.data);
    });

    const clearChunk = () => (videoChunks = []);

    const pause = () => mediaRecorder.pause(); //video.pause();

    const resume = () => mediaRecorder.resume();

    const start = () => {
      if (mediaRecorder.state === 'inactive') mediaRecorder.start();
    };

    const stop = () =>
      new Promise(resolve => {
        mediaRecorder.addEventListener('stop', () => {
          const videoBlob = new Blob(videoChunks, { type: 'video/mpeg' });
          const videoUrl = URL.createObjectURL(videoBlob);
          // const videoObject = new Video(videoUrl);
          // const play = () => videoObject.play();
          resolve({ videoBlob, videoUrl });
        });
        // stream.getTracks().forEach(function (track) {
        //   track.stop();
        // });
        if (mediaRecorder.current) {
          // setStatus('stopping');
          mediaRecorder.current.stop();
          // not sure whether to place clean up in useEffect?
          // If placed in useEffect the handler functions become dependencies of useEffect
          mediaRecorder.current.removeEventListener('dataavailable', start);
          mediaRecorder.current.removeEventListener('stop', stop);
          mediaRecorder.current.removeEventListener('error', handleError);
          mediaRecorder.current = null;
          clearMediaStream();
        }
        mediaRecorder.stop();
      });

    resolve({ start, pause, stop, resume, clearChunk });
  });

const clearMediaStream = () => {
  // if (mediaRecorder.current) {
  //   mediaRecorder.current.getTracks().forEach(track => track.stop());
  //   mediaRecorder.current = null;
  // }
};

const handleError = e => {};

export default VideoProvider;
