import { Overlay, Tooltip } from 'react-bootstrap';
import React, { useRef } from 'react';
import useWindowDimensions from '../Responsive/WindowDimensions';

const TooltipProfile = ({children, body, id, placement, clicked, setClicked}) => {
  const target = useRef(null);

  const { width } = useWindowDimensions();

  return (
    <>
      <button className='border-0 bg-transparent' ref={target} onClick={()=>setClicked(true)}>
        {children}
      </button>
      <Overlay onHide={()=>  setClicked(false)} target={target.current} show={clicked} placement={width > 500 ? placement : "top"} rootClose={true}>
        <Tooltip id={id} className="custom-tooltip-profile p-0 border-0">
          {body}
        </Tooltip>
      </Overlay>
    </>
  )
}

export default TooltipProfile;