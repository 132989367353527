import React, { useState, useEffect } from 'react';
import CommonService from '../../services/CommonService';
import styles from './GiftMessageVideo.module.css';
import { PlayIcon, SendVideoIcon } from '../../assets/images';

export default function GiftMessageVideo(props) {
  const [loading, setLoading] = useState(true)
  const [imageUrl, setImageUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const {
    size = 'small',
    videoSize = '?h=120&w=120'
  } =  props

  useEffect(() => {
    // TODO: Use some sort of caching. For example react-query.
    const fetchThumbnailImage = async () => {
      try {
        const response = await CommonService.getMediaFile(props.fileSecret, props.fileId);
        const imagePreviewPath = response.data.imagePreviewPath + videoSize;
        setImageUrl(imagePreviewPath);
        setVideoUrl(response.data.url);
        setLoading(false)
      } catch (e) {
        console.error('Error loading thumbnail for a video message', e);
        setLoading(false)
      }
    };
    fetchThumbnailImage();
  }, []);

  const handleThumbnailClick = () => {
    setVideoPlaying(true);
  };

  const play = ()=>{
    props.onClick()
  }
  if (loading) {
    return <span>Loading...</span>;
  }

  return (
    <div>
      {size === "middle" ?
        videoPlaying ? (
          <div className={styles.outer}>
            <video className={styles.video} width="240" height="240" controls autoplay="true">
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        ) : (
          <div className={styles.thumbnail} onClick={handleThumbnailClick}>
            <img src={imageUrl} alt="Video message thumbnail" className={styles.image}></img>
            <div className={styles.playIconContainer}>
              <PlayIcon></PlayIcon>
            </div>
          </div>
        ): size === 'small' ? (
          <div className="video-list" style={{
            backgroundImage: `url(${imageUrl ? imageUrl : ''})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}>
            <SendVideoIcon className="mike" />
            <PlayIcon className="play cursor-pointer" onClick={play} />
          </div>
        ) :
          null
      }
    </div>
  );
};
