/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Moment from 'react-moment';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import merchantService from '../../../../services/MerchantService';
import SendThanksService from '../../../../services/SendThanksService';
import Toast from '../../../../services/ToasterService';
import './Details.scss';
import { ReactComponent as EmptyDataImage } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';
import BackIcon from '../../../../assets/images/common/icons-back.png';

function MerchantPayoutDetailsPage(props) {
  const id = props.match.params.id;
  const payoutId = props.match.params.payoutId;
  const lastPaidOn = props.match.params.date;

  const [payoutDetails, setPayoutDetails] = useState([]);
  const [payoutTotalAmount, setPayoutTotalAmount] = useState(0);
  let [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    getPayoutDetails(id, pageNumber);
    window.scrollTo(0, 0);
  }, []);

  // Get payout details
  const getPayoutDetails = (id, pageNumber) => {
    merchantService
      .getPayoutDetails(id, pageNumber)
      .then(response => {
        if (response) {
          let responseData = response['data'];

          if (pageNumber > 1) {
            Array.prototype.push.apply(payoutDetails, responseData);
          }
          let totalAmount = 0;
          responseData?.data.forEach((payout, index) => {
            totalAmount += payout.amount;
          });

          setPayoutTotalAmount(totalAmount);

          SendThanksService.getListOfProducts('AU')
            .then(response => {
              if (response) {
                let productData = response['data'];
                if (productData?.length > 0) {
                  productData.forEach((product, index) => {
                    responseData?.data.forEach((transaction, index) => {
                      if (product?.price?.priceId === transaction?.priceId) {
                        transaction['type'] = product?.type;
                      }
                    });
                  });
                }

                setPayoutDetails(responseData);
              }
            })
            .catch(error => {
              console.log('error ', error);
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }

              loopFieldErrors(errorResponseFieldErrors);

              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    Toast(msg.message, 'error');
                  });
                }
              }
            });
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  // Loadmore data
  const LoadMoreData = () => {
    setPageNumber((pageNumber = pageNumber + 1));
    getPayoutDetails(id, pageNumber);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  // Check data is available
  const checkPayoutListDataAvailaleOrNot = () => {
    if (payoutDetails?.data?.length > 0) {
      return (
        <div className="p-0 m-0 row mt-3">
          <div className="mx-3 d-flex flex-column">
            <Link to="/merchant/payout">
              <button className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none bg-white">
                <img src={BackIcon} height="30px" />
                Back
              </button>
            </Link>
            <span className="mt-3 font-24 font-sofia-pro-light color-dark-pearl">Payout</span>
          </div>
          <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12">
            <div className="p-0 m-0 mt-4 row">
              <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="card border-0">
                  <div className="p-3 mb-3 card-body d-flex flex-column">
                    <span className="font-18 font-sofia-pro-regular color-black-pearl">Payout ID</span>
                    <span className="font-14 font-sofia-pro-light color-charcoal-grey">{payoutId}</span>

                    <div className="mt-3 d-flex flex-column">
                      <span className="font-18 font-sofia-pro-regular color-black-pearl">Paid on</span>
                      <span className="font-14 font-sofia-pro-light color-charcoal-grey">
                        <Moment format="DD/MM/YYYY">{lastPaidOn}</Moment>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="pb-1 card border-0">
                  <div className="card-body d-flex flex-column">
                    <span className="color-purple-brown font-20 font-sofia-pro-medium">Total Payout</span>
                    <span className="font-56 color-black-pearl font-sofia-pro-medium">${payoutTotalAmount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 col-sm-12 col-md-12 col-lg-12">
            <span className="font-sofia-pro-light font-24 color-black-pearl">Payouts</span>
            <div className="my-2">
              <div className="card dashboard-card__redeemthanks my-3 table-responsive border-0 ">
                <table className="table dashboard-table_headcolor border-bottom-pale-grey">
                  <thead style={{ backgroundColor: '#fff2fa ' }}>
                    <tr className="mx-2">
                      <td scope="col" className="font-sofia-pro-semi-bold">
                        Transaction ID
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Date
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Location
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Product
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Price
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Quantity
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Total
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {payoutDetails?.data?.map((transaction, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              {transaction.transferId}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              <Moment format="DD/M/YYYY">{transaction.redeemedOn}</Moment>
                            </span>
                          </td>
                          <td>
                            <Link
                              className="color-primary text-decoration-none"
                              to={`/merchant/transaction/${transaction.locationId}`}
                            >
                              <span className="font-14 font-sofia-pro-regular color-primary">
                                {transaction.location}
                              </span>
                            </Link>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">{transaction.type}</span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              ${transaction.amount}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              {transaction.quantity}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              ${transaction.total}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {payoutDetails?.totalCount >= 50 && (
                <span
                  className="font-14 color-primary border-bottom-primary-color font-sofia-pro-medium"
                  onClick={LoadMoreData}
                >
                  Show more
                </span>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-0 m-0 mt-3">
          <div className="mx-3 d-flex flex-column">
            <Link to="/merchant/payout">
              <button className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none bg-white">
                <img src={BackIcon} height="30px" />
                Back
              </button>
            </Link>
          </div>
          <div className="p-0 m-0 row mt-3 h-100 d-flex flex-column justify-content-center align-items-center">
            <EmptyDataImage />
            <span className="my-2 font-14 font-sofia-pro-medium">Uh-oh, no payout details found.</span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar menu="Merchant" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half h-100vh">
        <Header menu="Merchant" />

        {checkPayoutListDataAvailaleOrNot()}
      </div>
    </div>
  );
}

export default MerchantPayoutDetailsPage;
