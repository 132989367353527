/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { toast } from 'react-toastify';
import useWindowDimensions from '../../../common/Responsive/WindowDimensions';
import '../../individual/dashboard/DashBoard.scss';
import AccountDetailsServices from '../../../services/AccountDetailsServices';
import merchantService from '../../../services/MerchantService';
import './organisationsidebar.scss';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as SendThanks } from '../../../assets/images/dashboard/icons/icons-main-navigation-sent-inactive.svg';
import { ReactComponent as Redeemthanks } from '../../../assets/images/dashboard/icons/icons-main-navigation-redeem-inactive.svg';
import { ReactComponent as CaretDown } from '../../../assets/images/dashboard/icons/icons-caret-down.svg';
import { ReactComponent as RedeemthanksActive } from '../../../assets/images/dashboard/icons/icons-main-navigation-redeem-active.svg';
import { ReactComponent as SendThanksActive } from '../../../assets/images/dashboard/icons/icons-main-navigation-sent-active.svg';
import HomeIconInactive from '../../../assets/images/dashboard/image/icons-main-navigation-home-inactive.png';
import HomeIcon from '../../../assets/images/dashboard/image/icons-main-navigation-home-active.png';
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import { ReactComponent as ProfileIcon } from '../../../assets/images/common/user-icon.svg';
import { ReactComponent as BuildingIcon } from '../../../assets/images/common/building-icon.svg';
import { ReactComponent as TopUpInactive } from '../../../assets/images/common/icons-main-navigation-top-up-inactive.svg';
import { ReactComponent as UserInactive } from '../../../assets/images/common/np_users_inactive.svg';
import { ReactComponent as TopUpActive } from '../../../assets/images/common/icons-main-navigation-top-up-active.svg';
import { ReactComponent as UserActive } from '../../../assets/images/common/np_users.svg';
import { ReactComponent as AllocateActive } from '../../../assets/images/common/icons-main-navigation-redeem-credits-active.svg';
import { ReactComponent as AllocateInactive } from '../../../assets/images/common/icons-main-navigation-redeem-credits-inactive (1).svg';
import { ReactComponent as MerchantDownCaret } from '../../../assets/images/dashboard/icons/icons-caret-filled.svg';
import MarketingIcon from '../../../assets/images/dashboard/icons/MarketingIcon.png';
import MarketingIconActive from '../../../assets/images/dashboard/icons/MarketingIconActive.png';
import MenuIcon from '../../../assets/images/common/menu.png';
import CloseIcon from '../../../assets/images/common/close.png';
import TooltipProfile from '../../../common/Notification/TooltipProfile';
import PopoverProfile from '../../../common/component/Sidebar/PopoverProfile';
import ItemSideBar from '../../../common/component/Sidebar/ItemSideBar';
import BurgerLogo from '../../../common/component/Sidebar/BurgerLogo';
import { ReactComponent as MilestoneActive } from '../../../assets/images/common/milestone-active.svg';
import { ReactComponent as MilestoneInActive } from '../../../assets/images/common/milestone-inactive.svg';
import DoxAILogo from '../../../assets/images/logo/doxAI-logo.png';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const OrganisationSidebar = props => {
  const { width } = useWindowDimensions();
  const history = useHistory();

  const [companyDetails, setCompanyDetails] = useState();
  const [userData, setUserData] = useState([]);
  const [isOpen, setIsopen] = useState(false);
  const [isOpenMobile, setIsopenMobile] = useState(false);

  const currentPath = window.location.pathname;
  const OrganisationAdminCheck = sessionStorage.getItem('OrganisationAdmin');
  let userData1 = JSON.parse(localStorage.getItem('userData'));

  let logoFileImage = localStorage.getItem('profilePic');

  if (userData?.profilePhotoPath !== null) {
    logoFileImage = undefined;
  }

  useEffect(() => {
    if (userData1 === undefined || userData1 === null) {
      AccountData();
    } else {
      setUserData(userData1);
      userData1.roles.forEach((data, index) => {
        if (data === 'CompanyAdmin') {
          getCompanyDetails();
        }
      });
    }

    if (OrganisationAdminCheck === 'true') {
      getCompanyDetails();
      sessionStorage.setItem('OrganisationAdmin', false);
    }
  }, []);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };
  const ToggleSidebarMobile = () => {
    isOpenMobile === true ? setIsopenMobile(false) : setIsopenMobile(true);
  };

  // Get user data
  const AccountData = () => {
    AccountDetailsServices.GetACCOuntDetails()
      .then(res => {
        if (res) {
          setUserData(res['data']);
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  // Get company data
  const getCompanyDetails = () => {
    merchantService
      .getCompanyDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCompanyDetails(data);
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response?.data.globalErrors;
        if (error) {
          let errorResponse = error.response?.data;
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const navigateToIndividual = () => {
    history.push('/dashboard');
  };

  // Merchant Popup button
  function MerchantPopupButton() {
    const [clicked, setClicked] = useState(false);
    function Button() {
      return (
        <button
          className={`px-2 d-flex flex-row align-items-center border-0 dashboard-button profile__popup-position dashboard-rectangle-bottom${
            clicked ? '_clicked' : ''
          }`}
        >
          {companyDetails === undefined ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : companyDetails['logoPath'] === null ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : (
            <img src={companyDetails['logoPath'] + '?h=80&w=80'} className="rounded-circle dashboard_oval" />
          )}

          <span className="pl-1 font-12 font-sofia-pro-regular">Profile</span>
          <CaretDown className="dashboard-icons__caretdown" />
        </button>
      );
    }

    return (
      <div>
        <TooltipProfile
          clicked={clicked}
          setClicked={setClicked}
          id="AddMyStoreInfoCorporate"
          placement="top"
          body={<PopoverProfile toAccount={'/organisation/admin/profile'} toHistory={'/admin/transactionhistory'} />}
        >
          <Button />
        </TooltipProfile>
      </div>
    );
  }

  function PopupButton() {
    const [clicked, setClicked] = useState(false);
    function Button() {
      return (
        <button
          className={`px-2 d-flex flex-row align-items-center border-0 dashboard-button profile__popup-position dashboard-rectangle-bottom${
            clicked ? '_clicked' : ''
          }`}
        >
          {logoFileImage === undefined && userData?.profilePhotoPath !== null ? (
            <img className="rounded-circle dashboard_oval" src={userData?.profilePhotoPath + '?h=80&w=80'} />
          ) : logoFileImage === undefined && userData?.profilePhotoPath === null ? (
            <ProfilePic height="80px" width="80px" />
          ) : logoFileImage !== undefined ? (
            <img src={logoFileImage} height="80px" width="80px" className="rounded-circle dashboard_oval" />
          ) : null}
          {/* {userData['profilePhotoPath'] === null ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : (
            <img src={userData['profilePhotoPath'] + '?h=80&w=80'} className="rounded-circle dashboard_oval" />
          )} */}

          <span className="pl-1 font-12 font-sofia-pro-regular">{clicked ? userData['fullName'] : 'Profile'}</span>
          <CaretDown className="dashboard-icons__caretdown" />
        </button>
      );
    }

    return (
      <div>
        <TooltipProfile
          clicked={clicked}
          setClicked={setClicked}
          id="AddMyStoreInfoCorporate"
          placement="top"
          body={<PopoverProfile toHistory={'/employee/transactionhistory'} />}
        >
          <Button />
        </TooltipProfile>
      </div>
    );
  }

  function SideBarMobile() {
    if (props.menu === 'Organisation') {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebarMobile} />
          <div className={`sidebar ${isOpenMobile === true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebarMobile}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0  my-4">
                <OrganisationMenu />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebar} />
          <div className={`sidebar ${isOpenMobile === true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebarMobile}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0  my-4">
                <div className="dashboard-sidebar_hieght d-flex flex-column justify-content-between">
                  <div>
                    <Home />
                    <div className="my-4">
                      <SendThanksMenu />
                    </div>
                    <div className="my-4">
                      <RedeemThanksMenu />
                    </div>
                    <Credits />
                    {userData1?.isDoxAI == true && (
                      <img src={DoxAILogo} className="cursor-pointer" style={{ width: '90px', height: '90px' }} onClick={()=> window.open("https://portal-staging.appreci.io/services/sso/doxaiout", "_blank")}/>
                    )}
                  </div>

                  <AccountEmployee />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  function Home() {
    if (props.menu === 'Home') {
      return (
        <div>
          <Link to="/employee/dashboard" style={{ textDecoration: 'none' }}>
            <div className="m-0 p-0 w-100 d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-content-center">
                <img src={HomeIcon} alt="Home" className="dashboard-icons dashboard-navigation__home__active" />
                <span className="pl-2 dashboard-home font-sofia-pro-semi-bold">Home</span>
              </div>
              <div className="dashboard-menu_selected" />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className=" w-100">
          <Link to="/employee/dashboard" style={{ textDecoration: 'none' }}>
            <img src={HomeIconInactive} alt="Home" />
            <span className="pl-2 dashboard-text__inActive  font-sofia-pro-regular">Home</span>
          </Link>
        </div>
      );
    }
  }

  function SendThanksMenu() {
    if (props.menu === 'Sendthanks') {
      return (
        <div>
          <Link style={{ textDecoration: 'none' }} to="/organisation/employee/selectItem">
            <div className=" w-100 d-flex justify-content-between ">
              <div className="d-flex justify-content-center align-content-center">
                <SendThanksActive />
                <span className="pl-2 dashboard-home  font-sofia-pro-semi-bold">Send</span>
              </div>
              <div className="dashboard-menu_selected  " />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className=" w-100">
          <Link style={{ textDecoration: 'none' }} to="/organisation/employee/selectItem">
            <SendThanks className="dashboard-icons dashboard-menu__sendthanksinactive" />
            <span className="pl-2 dashboard-text__inActive  font-sofia-pro-regular">Send</span>
          </Link>
        </div>
      );
    }
  }
  function OrganisationSendThanksMenu() {
    return (
      <div className=" w-100">
        <Link style={{ textDecoration: 'none' }} to="/organisation/send/selectItem">
          {currentPath.includes('/organisation/send') ? (
            <SendThanksActive />
          ) : (
            <SendThanks className="dashboard-icons dashboard-menu__sendthanksinactive" />
          )}
          <span
            className={
              currentPath.includes('/organisation/send')
                ? 'pl-2 dashboard-home font-sofia-pro-semi-bold'
                : 'pl-2 dashboard-text__inActive  font-sofia-pro-regular'
            }
          >
            Send
          </span>
        </Link>
      </div>
    );
  }
  function RedeemThanksMenu() {
    if (props.menu === 'Redeemthanks') {
      return (
        <div>
          <Link to="/organisation/redeemthanks" style={{ textDecoration: 'none' }}>
            <div className="w-100 d-flex justify-content-between">
              <div className="d-flex justify-content-center align-content-center">
                <RedeemthanksActive />
                <span className="pl-2 dashboard-home font-sofia-pro-semi-bold">Redeem </span>
              </div>
              <div className="dashboard-menu_selected  " />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="w-100">
          <Link to="/organisation/redeemthanks" style={{ textDecoration: 'none' }}>
            <Redeemthanks />
            <span className="pl-2 dashboard-text__inActive font-sofia-pro-regular">Redeem</span>
          </Link>
        </div>
      );
    }
  }
  function Credits() {
    if (props.menu === 'Credits') {
      return (
        <div>
          <Link to="/organisation/allocate/recipientInformation" style={{ textDecoration: 'none' }}>
            {/* <div className="m-0 p-0 w-100 d-flex justify-content-between ">

              <div className="d-flex justify-content-center align-content-center">
                <img
                  src={HomeIcon}
                  alt="Home"
                  className="dashboard-icons dashboard-navigation__home__active"
                />
                <span className="pl-2 dashboard-home font-sofia-pro-semi-bold">
                  Credits
                </span>
              </div>
              <div className="dashboard-menu_selected" />

            </div> */}
            <Accordion className="w-100" defaultActiveKey="0" eventKey="0">
              <Accordion.Toggle
                className="p-0 m-0  w-100 font-sofia-pro-regular merchant__store outline-none color-primary   border-0 bg-transparent"
                variant="link"
                eventKey="0"
              >
                <div className="my-2 w-100 d-flex align-items-center">
                  <AllocateActive className="mr-2 dashboard-icons dashboard-navigation__home__active" />
                  <span>Credits</span>

                  <div className="pl-5">
                    <MerchantDownCaret className="merchant__store-dropdown-caret" />
                  </div>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <div className="w-100 ">
                  <div className="mt-1 pl-4 w-100 d-flex justify-content-between align-items-center">
                    <Link className="mt-1 text-decoration-none" to="/organisation/allocate/recipientInformation">
                      <span
                        className={
                          currentPath === '/organisation/allocate/review' ||
                          currentPath === '/organisation/allocate/recipientInformation' ||
                          currentPath === '/organisation/allocate/success'
                            ? 'pl-2 pt-2  dashboard-home  font-sofia-pro-semi-bold'
                            : 'pl-2 pt-2 dashboard-text__inActive font-sofia-pro-regular'
                        }
                      >
                        Allocate
                      </span>
                    </Link>
                    <div
                      className={
                        currentPath === '/organisation/allocate/review' ||
                        currentPath === '/organisation/allocate/recipientInformation' ||
                        currentPath === '/organisation/allocate/success'
                          ? 'dashboard-menu_selected'
                          : ''
                      }
                    ></div>
                  </div>
                  <div className="mt-1 pl-4 w-100 d-flex justify-content-between  align-items-center">
                    <Link className="mt-1 text-decoration-none" to="/organisation/redeem/selectItem">
                      <span
                        className={
                          currentPath === '/organisation/redeem/selectItem' ||
                          currentPath === '/organisation/redeem/review' ||
                          currentPath === '/organisation/redeem/thanksSuccess'
                            ? 'pl-2 pt-2  dashboard-home  font-sofia-pro-semi-bold'
                            : 'pl-2 pt-2 dashboard-text__inActive font-sofia-pro-regular'
                        }
                      >
                        Redeem
                      </span>
                    </Link>
                    <div
                      className={
                        currentPath === '/organisation/redeem/selectItem' ||
                        currentPath === '/organisation/redeem/review' ||
                        currentPath === '/organisation/redeem/thanksSuccess'
                          ? 'dashboard-menu_selected'
                          : ''
                      }
                    ></div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </Link>
        </div>
      );
    } else {
      return (
        <div className=" w-100">
          <Link to="/organisation/allocate/recipientInformation" style={{ textDecoration: 'none' }}>
            <Accordion className="w-100" defaultActiveKey="0" eventKey="1">
              <Accordion.Toggle
                className="p-0 m-0  w-100 font-sofia-pro-regular merchant__store outline-none color-primary   border-0 bg-transparent"
                variant="link"
                eventKey="0"
              >
                <div className="my-2 w-100 d-flex align-items-center">
                  <AllocateInactive className="mr-2 dashboard-icons dashboard-navigation__home__active" />
                  <span className="color-cloudy-blue">Credits</span>

                  <div className="pl-5">
                    <MerchantDownCaret className="merchant__store-dropdown-caret" />
                  </div>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="1">
                <div className="w-100 ">
                  <div className="mt-1  pl-4  w-100 d-flex justify-content-between align-items-center">
                    <Link className="mt-1 text-decoration-none" to="/organisation/allocate/recipientInformation">
                      <span
                        className={
                          currentPath === '/organisation/allocate/review' ||
                          currentPath === '/organisation/allocate/recipientInformation' ||
                          currentPath === '/organisation/allocate/success'
                            ? 'pl-2 pt-2  dashboard-home  font-sofia-pro-semi-bold'
                            : 'pl-2 pt-2 dashboard-text__inActive font-sofia-pro-regular'
                        }
                      >
                        Allocate
                      </span>
                    </Link>
                    <div
                      className={
                        currentPath === '/organisation/allocate/review' ||
                        currentPath === '/organisation/allocate/recipientInformation' ||
                        currentPath === '/organisation/allocate/success'
                          ? 'dashboard-menu_selected'
                          : ''
                      }
                    ></div>
                  </div>
                  <div className="mt-1 pl-4  w-100 d-flex justify-content-between  align-items-center">
                    <Link className="mt-1 text-decoration-none" to="/organisation/redeem/selectItem">
                      <span
                        className={
                          currentPath === '/organisation/redeem/selectItem' ||
                          currentPath === '/organisation/redeem/review' ||
                          currentPath === '/organisation/redeem/thanksSuccess'
                            ? 'pl-2 pt-2  dashboard-home  font-sofia-pro-semi-bold'
                            : 'pl-2 pt-2 dashboard-text__inActive font-sofia-pro-regular'
                        }
                      >
                        Redeem
                      </span>
                    </Link>
                    <div
                      className={
                        currentPath === '/organisation/redeem/selectItem' ||
                        currentPath === '/organisation/redeem/review' ||
                        currentPath === '/organisation/redeem/thanksSuccess'
                          ? 'dashboard-menu_selected'
                          : ''
                      }
                    ></div>
                  </div>
                </div>
              </Accordion.Collapse>
            </Accordion>
          </Link>
        </div>
      );
    }
  }

  function AccountCorporate() {
    return (
      <div className="my-1">
        <div className="mb-3">
          <span className="p-2 font-14 color-gunmetal font-sofia-pro-light">Accounts</span>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                onClick={navigateToIndividual}
              >
                <ProfileIcon style={{ fill: '#b5c3cc' }} className="rounded-circle dashboard_oval" />
                <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                  <span className="font-12 color-cloudy-blue font-sofia-pro-light">Personal</span>
                </div>
              </button>
              <button
                className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                // onClick={navigateToIndividual}
              >
                <BuildingIcon style={{ fill: '#e20077' }} className="dashboard_icon" />
                <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                  <span className="font-12 font-sofia-pro-light" style={{ color: '#e20077' }}>
                    Corporate
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <MerchantPopupButton />
      </div>
    );
  }
  function AccountEmployee() {
    return (
      <div className="my-1">
        <div className="mb-3">
          <span className="p-2 font-14 color-gunmetal font-sofia-pro-light">Accounts</span>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <button
                className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                onClick={navigateToIndividual}
              >
                <ProfileIcon style={{ fill: '#b5c3cc' }} className="rounded-circle dashboard_oval" />
                <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                  <div className="d-flex">
                    <span className="font-12 color-cloudy-blue font-sofia-pro-light">Personal</span>
                  </div>
                </div>
              </button>
              <button
                className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                // onClick={navigateToEmployee}
              >
                <BuildingIcon style={{ fill: '#e20077' }} className="dashboard_icon" />
                <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                  <div className="d-flex">
                    <span className="font-12 font-sofia-pro-light" style={{ color: '#e20077' }}>
                      Employee
                    </span>
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
        <PopupButton />
      </div>
    );
  }

  function OrganisationMenu() {
    if (props.menu === 'Organisation') {
      return (
        <div className="my-4">
          <div className="w-100 d-flex justify-content-between">
            <div
              className="w-100 position-relative dashboard-sidebar_hieght d-flex flex-column justify-content-between overflow-auto"
              style={{ scrollbarWidth: 'none', maxHeight: '100vh' }}
            >
              <div>
                <ItemSideBar
                  to={'/organisation/dashboard'}
                  logic={currentPath === '/organisation/dashboard'}
                  iconActive={HomeIcon}
                  icon={HomeIconInactive}
                  name={'My Organisation'}
                />
                <div className="my-4">
                  <OrganisationSendThanksMenu />
                </div>
                <div className="my-4">
                  <ItemSideBar
                    to={'/organisation/users'}
                    logic={currentPath === '/organisation/users'}
                    iconActive={<UserActive className="dashboard-icons dashboard-navigation__home__active" />}
                    icon={<UserInactive />}
                    name={'Users'}
                  />
                </div>
                <div className="my-4">
                  <ItemSideBar
                    to={'/organisation/topup'}
                    logic={
                      currentPath === '/organisation/topup' ||
                      currentPath === '/organisation/paymentOptions' ||
                      currentPath === '/organisation/review' ||
                      currentPath === '/topup/success'
                    }
                    iconActive={<TopUpActive className="dashboard-icons dashboard-navigation__home__active" />}
                    icon={<TopUpInactive />}
                    name={'Top up'}
                  />
                </div>
                <div className="my-4">
                  <ItemSideBar
                    to={'/organisation/admin/allocate/recipientInformation'}
                    logic={currentPath.includes('/organisation/admin/allocate')}
                    iconActive={<AllocateActive className="dashboard-icons dashboard-navigation__home__active" />}
                    icon={<AllocateInactive />}
                    name={'Allocate Credits'}
                  />
                </div>
                <div className="my-4">
                  <ItemSideBar
                    to={'/organisation/milestones'}
                    logic={currentPath === '/organisation/milestones'}
                    iconActive={<MilestoneActive className="dashboard-icons dashboard-navigation__home__active" />}
                    icon={<MilestoneInActive />}
                    name={'Milestone gifting'}
                  />
                </div>
                <div className="">
                  <ItemSideBar
                    to={'/organisation/marketing'}
                    logic={currentPath === '/organisation/marketing'}
                    iconActive={MarketingIconActive}
                    icon={MarketingIcon}
                    name={'Apps'}
                    comingSoon={false}
                  />
                </div>
                {userData1?.isDoxAI == true && (
                  <img src={DoxAILogo} className="cursor-pointer" style={{ width: '90px', height: '90px' }} onClick={()=> window.open("https://portal-staging.appreci.io/services/sso/doxaiout", "_blank")}/>
                )}
              </div>
              <AccountCorporate />
            </div>
          </div>
        </div>
      );
    }
  }

  function LoadMenu() {
    return (
      <div className="topnav navbar-collapse dashboard-menubar_mobile pl-3 ">
        <div className="m-0 p-0 dashboard-menu my-4">
          {props.menu === 'Organisation' ? (
            <OrganisationMenu />
          ) : (
            <div className="dashboard-sidebar_hieght d-flex flex-column justify-content-between">
              <div>
                <Home />
                <div className="my-4">
                  <SendThanksMenu />
                </div>
                <div className="my-4">
                  <RedeemThanksMenu />
                </div>
                {userData1?.isDoxAI == true && (
                  <img src={DoxAILogo} className="cursor-pointer" style={{ width: '90px', height: '90px' }} onClick={()=> window.open("https://portal-staging.appreci.io/services/sso/doxaiout", "_blank")}/>
                )}
              </div>
              <AccountEmployee />
            </div>
          )}
        </div>
      </div>
    );
  }

  function SidebarForTablet() {
    if (props.menu === 'Organisation') {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebar} />
          <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebar}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">
                <OrganisationMenu />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebar} />
          <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebar}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">
                <div className="dashboard-sidebar_hieght d-flex flex-column justify-content-between">
                  <div>
                    <Home />
                    <div className="my-4">
                      <SendThanksMenu />
                    </div>
                    <div className="my-4">
                      <RedeemThanksMenu />
                    </div>
                    <Credits />
                    {userData1?.isDoxAI == true && (
                      <img src={DoxAILogo} className="cursor-pointer" style={{ width: '90px', height: '90px' }} onClick={()=> window.open("https://portal-staging.appreci.io/services/sso/doxaiout", "_blank")}/>
                    )}
                  </div>

                  <AccountEmployee />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return width >= 700 && width <= 991 ? (
    <div>
      <SidebarForTablet />
    </div>
  ) : width < 700 ? (
    <div>
      <SideBarMobile />
    </div>
  ) : (
    <div className="px-2 d-flex justify-content-center align-items-center dashboar-sidebar_fixed dashboard-sidebar">
      <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
      <LoadMenu />
    </div>
  );
};
export default OrganisationSidebar;
