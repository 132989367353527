/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';

const TransactionHistorySent = page => {
  return httpService.get(`TransactionHistories/current?type=${'GiftSent'}&pageNumber=${page}`);
};

const TransactionHistoryRecive = page => {
  return httpService.get(`TransactionHistories/current?type=${'GiftReceived'}&pageNumber=${page}`);
};

const TransactionHistoryRedeem = page => {
  return httpService.get(`TransactionHistories/current?type=${'GiftRedeemed'}&pageNumber=${page}`);
};

// Merchant
const getRedemptionsList = page => {
  return httpService.get(`Merchants/current/redemptions`);
};

const getNotificationsList = () => {
  return httpService.get(`transactionhistories/current?type=notificationunread`);
};

const changeNotificationStatus = id => {
  return httpService.put(`TransactionHistories/${id}/read`);
};

// Admin

const getNotificationsListOfAdmin = () => {
  return httpService.get(`transactionhistories/current?type=notificationunread&asCompany=true`);
};

// const changeNotificationStatus = (id) => {
//   return httpService.put(`TransactionHistories/${id}/read`)
// }

export default {
  TransactionHistorySent,
  TransactionHistoryRecive,
  TransactionHistoryRedeem,
  getRedemptionsList,
  getNotificationsList,
  changeNotificationStatus,
  getNotificationsListOfAdmin,
};
