/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../common/Button/Button';
import { EmailRegexValue } from '../../../utils/RegexPatterns';
import Toast from '../../../services/ToasterService';
import './Users.scss';
import ErrorImage from '../../../assets/images/error-icons/icons-error-info.png';
import OrganisationService from '../../../services/OrganisationService';
import { ReactComponent as Cake } from '../../../assets/images/common/cake.svg';
import { ReactComponent as EditMilestone } from '../../../assets/images/common/edit-user.svg';
import { ReactComponent as ClosePopup } from '../../../assets/images/common/close-popup.svg';
import { ReactComponent as Date } from '../../../assets/images/common/date-picker.svg';
import { DatePicker } from 'antd';
import CustomDropdown from '../../../common/CustomDropDown/CustomDropdown';
import moment from 'moment';
import dayjs from 'dayjs';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  milestones: [{ id: 0, title: '', date: '' }],
};

const validate = values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.length > 25) {
    errors.firstName = 'Characters length should not exceed 25';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.length > 25) {
    errors.lastName = 'Characters length should not exceed 25';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!EmailRegexValue.test(values.email)) {
    errors.email = 'Invalid email format';
  }

  return errors;
};

const EditUserPage = props => {
  const [editModalShow, setEditModalShow] = useState(false);

  const [showMilestonePopup, setShowMilestonePopup] = useState(false);
  const [showEditMilestonePopup, setShowEditMilestonePopup] = useState(false);
  const [showEditMilestoneIndex, setShowEditMilestoneIndex] = useState();
  const [clickCount, setClickCount] = useState(0);
  const [modals, setModals] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [titleOptions, setTitleOptions] = useState([]);
  const [milestones, setMilestones] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('');

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  useEffect(() => {
    getUserData(props.id);
    getMilestonesData();
  }, []);

  const onSubmit = values => {
    toast.dismiss();

    setShowLoader(true);

    let data = values;

    OrganisationService.updateUser(props.id, data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          props.closeModal();
          props.loadUsersList();
          Toast('Your changes have been saved successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const onOpenMileStonePopup = () => {
    setClickCount(prevCount => prevCount + 1);
    setModals(prevCount => [...prevCount, {}]);
    // setShowMilestonePopup(true);
  };

  const onMileStonePopup = index => {
    // setClickCount(prevCount => prevCount + 1);
    // setModals(prevCount =>([ ...prevCount , {}]))
    setShowEditMilestoneIndex(index);
    setShowEditMilestonePopup(true);
  };

  const onCloseMileStonePopup = index => {
    setShowEditMilestonePopup(false);
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate,
  });

  const getUserData = id => {
    OrganisationService.getUser(props.id).then(response => {
      if (response) {
        let data = response['data'];
        handleEditModalShow();

        formik.setFieldValue('firstName', data.firstName);
        formik.setFieldValue('lastName', data.lastName);
        formik.setFieldValue('email', data.email);
        formik.setFieldValue('phone', data.phone);
        if(data.role == "CompanyUser") {
          formik.setFieldValue('role', 'Employee');
        } else {
          formik.setFieldValue('role', 'Admin');
        }
        formik.setFieldValue('milestones', data.milestones);
        // formik.setFieldValue('title', data.title);
        // formik.setFieldValue('date', data.date);
      }
    });
  };

  const getMilestonesData = () => {
    OrganisationService.getMilestones().then(response => {
      if (response) {
        let data = response['data'];
        setMilestones(data);
        data?.map(d => {
          titleOptions.push(d?.title);
        });
      }
    });
  };

  const handleModalClose = index => {
    const updatedMilestones = [...formik.values.milestones];
    updatedMilestones.splice(index + 1, 1);

    if (updatedMilestones.length === 1) {
      // Update the formik values with the updated milestones array
      formik.setFieldValue('milestones', updatedMilestones);
    } else {
      // Don't delete the last item, you can show a message or handle this case as needed
      console.log('Cannot delete the last milestone.');
    }

    // Close the specific modal by filtering out the clicked index
    setModals(prevModals => prevModals.filter((_, i) => i !== index));
    if (modals.length === 1) {
      // Close the modal if it was the last one open
      setShowMilestonePopup(false);
    }
  };

  const handleMilestoneClose = () => {
    setShowEditMilestonePopup(false);
  };
  // Array of modals based on the click count

  const roleOptions = ['Admin', 'Employee'];

  const handleMilestoneChange = (index, field, value) => {
    const updatedMilestones = [...formik.values?.milestones];
    if (updatedMilestones[index-1]) {
      alert(index-1)
      // Check if the specified field exists in the object
      if (!updatedMilestones[index-1].hasOwnProperty(field)) {
        // If the field doesn't exist, create it and assign the value
        updatedMilestones[index-1][field] = value;
      } else {
        // If the field already exists, update its value
        updatedMilestones[index-1][field] = value;
      }
    }
    formik.setFieldValue('milestones', updatedMilestones);
  };

  const handleDateChange = (index, dateString) => {
    const utcDate = moment.tz(dateString.format('YYYY-MM-DD HH:mm:ss'), 'Australia/Sydney').toISOString();

    // Call the existing handleMilestoneChange function with the converted date
    handleMilestoneChange(index, 'date', utcDate);
  };
  const disabledDate = current => {
    // Disable dates before today
    return current && current < moment().startOf('day');
  };

  return (
    <div className=" m-0 p-0 row">
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12">
        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="p-0 m-0 row">
            <div className="p-0 m-0 pr-2 col-sm-12 col-md-6 col-lg-6">
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">First name</label>
                <input
                  type="text"
                  className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle shadow-none"
                  name="firstName"
                  placeholder="Eg: John"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="p-0 m-0 pr-2 col-sm-12 col-md-6 col-lg-6">
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">Last Name</label>
                <input
                  type="text"
                  className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                  name="lastName"
                  placeholder="Eg: Doe"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Email</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
              name="email"
              placeholder="Eg: test@gmail.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Role</label>
            <CustomDropdown
              options={roleOptions}
              name="role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
            />
          </div>
          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Mobile</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
              name="phone"
              placeholder="0478983456"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.phone}
              </div>
            ) : null}
          </div>
          <hr style={{ marginTop: '2rem', marginBottom: '2rem' }} />
          <label className="font-sofia-pro-regular color-charcoal font-16">Milestone</label>
          <div className="container">
            {formik.values.milestones.map((m, index) => {
              return (
                <>
                  <div className="row mb-3 milestone-row">
                    <Cake />
                    <span className="font-sofia-pro-regular color-charcoal font-16 milestone-title">{m?.title}</span>
                    <span className="font-sofia-pro-regular color-charcoal font-16 milestone-date">
                      {dayjs(m?.date).format('DD/MM')}
                    </span>
                    <EditMilestone
                      className="milestone-edit cursor-pointer"
                      onClick={() => {
                        onMileStonePopup(index);
                      }}
                    />
                  </div>
                  {showEditMilestonePopup && showEditMilestoneIndex == index && (
                    <div
                      className="row position-relative"
                      style={{ background: '#F8F9FA', borderRadius: '5px', marginTop: '2%' }}
                    >
                      <ClosePopup
                        className="position-absolute"
                        style={{ top: '10px', right: '12px', cursor: 'pointer', zIndex: '3' }}
                        onClick={() => {
                          handleMilestoneClose();
                        }}
                      />
                      <div className="col-1"></div>
                      <div
                        className="col-md-6 col-lg-6 my-2 p-0 b-0 pb-1 form-group position-absolute"
                        style={{ left: '21px' }}
                      >
                        <label className="font-sofia-pro-regular color-charcoal title-label">
                          What should the title be?
                        </label>
                        <CustomDropdown
                          options={titleOptions}
                          name={`milestones[${index}].title`}
                          placeholder="e.g. Birthday"
                          onChange={event => {
                            formik.handleChange(event);
                            const milestone = milestones.find(m => m.title === event.target.value);
                            formik.setFieldValue(`milestones[${index}].id`, milestone.id);
                          }}
                          onBlur={formik.handleBlur}
                          value={m?.title || 'e.g. Birthday'}
                          defaultValue={'e.g. Birthday'}
                          className="form-control title-input"
                        />
                      </div>
                      <div className="col-5 my-2 p-0 b-0 pb-1 form-group" style={{ right: '-47%' }}>
                        <label className="font-sofia-pro-regular color-charcoal title-label">Date</label>
                        <div className="form-control-wrapper">
                          <DatePicker
                            disabledDate={disabledDate}
                            format="DD/MM"
                            name={`milestones[${index}].date`}
                            value={dayjs(m.date)}
                            placeholder=""
                            className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                            suffixIcon={<Date className="date-icon" />}
                            onChange={val => handleDateChange(index, val)}
                            allowClear={false}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            })}

            {modals.map((modal, index) => {
              const milestoneLength = formik.values.milestones.length;
              const milestone = formik.values.milestones[index + milestoneLength];
              return (
                <div
                  className="row position-relative"
                  style={{ background: '#F8F9FA', borderRadius: '5px', marginTop: '2%' }}
                >
                  <ClosePopup
                    className="position-absolute"
                    style={{ top: '10px', right: '12px', cursor: 'pointer', zIndex: '3' }}
                    onClick={() => {
                      handleModalClose(index);
                    }}
                  />
                  <div className="col-1"></div>
                  <div
                    className="col-md-6 col-lg-6 my-2 p-0 b-0 pb-1 form-group position-absolute"
                    style={{ left: '21px' }}
                  >
                    <label className="font-sofia-pro-regular color-charcoal title-label">
                      What should the title be?
                    </label>

                    <CustomDropdown
                      options={titleOptions}
                      name={`milestones[${index + milestoneLength}].title`}
                      placeholder="e.g. Birthday"
                      onChange={event => {
                        formik.handleChange(event);
                        const milestone = milestones.find(m => m.title === event.target.value);
                        formik.setFieldValue(`milestones[${index + milestoneLength}].id`, milestone.id);
                      }}
                      onBlur={formik.handleBlur}
                      value={milestone?.title || 'e.g. Birthday'}
                      defaultValue={'e.g. Birthday'}
                      className="form-control title-input"
                    />
                    {/* {milestone?.title && milestone?.title ? (
                 <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                   <img className="mr-1" src={ErrorImage} /> {milestone?.title}
                 </div>
               ) : null} */}
                  </div>
                  <div className="col-5 my-2 p-0 b-0 pb-1 form-group" style={{ right: '-47%' }}>
                    <label className="font-sofia-pro-regular color-charcoal title-label">Date</label>
                    <div className="form-control-wrapper">
                      <DatePicker
                        disabledDate={disabledDate}
                        format="DD/MM"
                        name={`milestone.date`}
                        placeholder=""
                        className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                        suffixIcon={<Date className="date-icon" />}
                        onChange={val => handleDateChange(index + milestoneLength, val)}
                        allowClear={false}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="row">
              <span
                className="font-sofia-pro-bold font-16 my-1"
                style={{ color: '#E10177', cursor: 'pointer' }}
                onClick={onOpenMileStonePopup}
              >
                +Add another milestone
              </span>
            </div>
          </div>
          <div className="w-100">
            <Button
              classNameValue="w-100 my-3 shadow-none d-flex justify-content-center align-items-center h-60  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
              children="Save"
              showLoader={showLoader}
              onClick={() => onSubmit}
            />
          </div>
          <span
            className="d-flex mt-2 justify-content-center align-items-center font-sofia-pro-bold"
            style={{ color: '#C44440', cursor: 'pointer' }}
            onClick={props.deleteEmployeeData.bind(this, props.index)}
          >
            Remove Employee
          </span>
        </form>
      </div>
    </div>
  );
};

export default EditUserPage;
