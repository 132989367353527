import React, { useRef, useState, useEffect, useCallback } from 'react';
import './Timer.scss';

const Timer = ({ recordState, storeTimer, pausedTime, confirmStopRecording }) => {
  if (pausedTime === 0) {
    pausedTime = 15;
  }

  const timerRef = useRef(null);

  useEffect(() => {
    let timerStart = pausedTime;
    const interval = setInterval(_ => {
      timerRef.current.innerHTML = timerStart.toString().length === 1 ? `00:00:0${timerStart}` : `00:00:${timerStart}`;
      if (timerStart === 1) {
        confirmStopRecording();
      }
      timerStart -= 1;
    }, 1000);
    return _ => {
      storeTimer(timerStart);
      clearInterval(interval);
    };
  }, []);

  return <span className="timer-count" ref={timerRef}></span>;
};

export default Timer;
