/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import RedeemCoffeeService from '../../../../services/RedeemCoffeeService';
import '../../../individual/dashboard/DashBoard.scss';
import './SuspendCoffee.scss';
import '../../redeemThanks/Redeem/Redeem.scss';
import { ReactComponent as SendActive } from '../../../../assets/images/dashboard/icons/icons-location.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/images/dashboard/icons/icons-search.svg';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';

const SuspendCoffee = () => {
  const [availableLocation, setavailableLocation] = useState([]);
  const [search, setSearch] = useState('');
  const [locationId, setLocationId] = useState();
  const [coffeShopName, setCoffeShopName] = useState();
  const [modalShow, setModalShow] = useState(false);

  const getMyLocation = () => {
    const geolocation = window.navigator && window.navigator.geolocation;

    if (geolocation) {
      geolocation.getCurrentPosition(
        position => {
          getAvalilableLocation(position.coords.latitude, position.coords.longitude);
        },

        error => {
          console.log(error);
        }
      );
    }
  };
  const YesRedeem = () => {
    return (
      <>
        <button
          className="border-0 dashboard-button__yesredeem"
          onClick={() => {
            '';
          }}
        >
          <span className="dashboard-text__yesredeem font-sofia-pro-semi-bold">Yes, suspend</span>
        </button>
      </>
    );
  };

  const MyVerticallyCenteredModal = props => {
    return (
      <Modal
        {...props}
        size="md"
        centered
        className="dashboard-background_popup redeem-coffee__modal"
        animation={false}
        backdrop="static"
      >
        <Modal.Header className="border-0 dashboard-popup_heading">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="dashboard-text_Manly-Guild-Cafe font-sofia-pro-medium  "
          >
            {coffeShopName}
          </Modal.Title>
          <button className="border-0 dashboard-button_cancelicon" onClick={props.onHide}>
            <Close />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div></div>
          <span className="suspend-text__Are-you-sure font-sofia-pro-medium">Are you sure?</span>
          <div>
            <span className="suspend-coffee__Your-coffee-will-be font-sofia-pro-light">
              Your coffee will be sent to the selected cafe and can be given to anyone in need by the cafe owner.
            </span>
          </div>
          <div className="d-flex flex-row ">
            <YesRedeem />
            <button className="border-0 dashboard-button_cancel " onClick={props.onHide}>
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  const getAvalilableLocation = (latitude, longitude) => {
    RedeemCoffeeService.getListOfAvailableLocations(latitude, longitude, 1)
      .then(res => setavailableLocation(res.data['data']))
      .catch(error => {
        if (error) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getMyLocation();
  }, []);

  const SearchFilterd = availableLocation.filter(val => {
    return val['name'].toLowerCase().includes(search.toLowerCase());
  });
  const GetDirection = index => {
    showMap(availableLocation[index]['latitude'], availableLocation[index]['longitude']);
  };
  function showMap(lat, lng) {
    var url = 'https://maps.google.com/?q=' + lat + ',' + lng;
    window.open(url);
  }
  const RedeemedId = index => {
    setLocationId(availableLocation[index]['id']);
    setCoffeShopName(availableLocation[index]['name']);
    setModalShow(true);
  };
  return (
    <div>
      <div className=" m-0 p-0 row ">
        <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column ">
          <SideBar menu="Redeemthanks" />
        </div>
        <div className=" p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half">
          <Header />
          <div className="card flex-column m-3 border-0">
            <div className="d-flex flex-row m-2 justify-content-between">
              <span className="suspendcoffee-text_suspendcoff font-sofia-pro-medium mx-1">Suspend coffee</span>
              <div className=" d-flex flex-row suspendcoffee-search  align-items-center  mx-lg-0 mx-1">
                <SearchIcon className=" mx-1" />

                <input
                  className="border-0 suspendcoffee-input_searchcaff font-sofia-pro-light suspendcoffee-input__searchcaffecolor   "
                  placeholder="Search cafe"
                  onChange={e => setSearch(e.target.value)}
                />

                <SendActive className=" mx-2 d-flex " />
              </div>
            </div>
            <span className="suspendcoffe-text__A-suspended-coffee-i font-sofia-pro-light mx-3">
              A suspended coffee is the advance purchase of a coffee for someone who needs it, no matter why.
            </span>
            <span className="font-sofia-pro-bold suspendcoffe-text-NEARBY-LOCATIONS m-3">NEARBY LOCATIONS</span>
            <div>
              {SearchFilterd.map((value, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex flex-row mx-2 justify-content-between my-2">
                      <div className="d-flex flex-row">
                        {value['merchant']['logoUrl'] === null || '' ? (
                          <div className="dashboard-oval__availablelocation rounded-circle mx-1" />
                        ) : (
                          <div className=".dashboard-oval__availablelocationimage rounded-circle mx-1">
                            <img src={value['merchant']['logoUrl'] + '?h=80&w=80'} />
                          </div>
                        )}
                        <div className="d-flex flex-column justify-content-center">
                          <span className="dashboard-teaxt_manlyguild font-sofia-pro-semi-bold mx-2">
                            {value['name']}
                          </span>
                          <span className="dashboard-text_thecorsomanly20 font-sofia-pro-regular mx-2">
                            {value['address']}
                          </span>
                          <span className="dashboard-text__kmawayGetDi font-sofia-pro-light mx-2">
                            {value['distanceInMetres'] < 1000
                              ? value['distanceInMetres'] + ' metre away'
                              : (value['distanceInMetres'] / 1000.0).toFixed(1) + ' km away'}{' '}
                            <span className="dashboard-text_dot">.</span>
                            <button className="border-0 bg-transparent" onClick={GetDirection.bind(this, index)}>
                              <span className="dashboard-text__kmawayGetDitextstyle-1 font-sofia-pro-regular">
                                Get Directions
                              </span>
                            </button>
                          </span>
                        </div>
                      </div>
                      <div>
                        <button
                          className="dashboard-button_select border-0 dashboard-button"
                          onClick={RedeemedId.bind(this, index)}
                        >
                          <span className="dashboard-text_select font-sofia-pro-semi-bold">Select</span>
                        </button>
                        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
                      </div>
                    </div>
                    <div className="dashboard-line my-1" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuspendCoffee;
