/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import 'moment-timezone';
import { Modal } from 'react-bootstrap';
import Tour from 'reactour';
import OrganisationClaimCodePage from './organisationclaimcode';
import OrganisationSearchBar from './OrganisationSearchBar';
import * as RegexPattern from '../../../utils/RegexPatterns';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { searchDataDetails } from '../../../store/reducers/header/header';
import { connect } from 'react-redux';
import httpService from '../../../services/HttpService';
import merchantService from '../../../services/MerchantService';
import MerchantService from '../../../services/MerchantService';
import TransactionHistoryService from '../../../services/TransactionHistoryService';
import { ReactComponent as ProfilePic } from '../../../assets/images/common/user-profile.svg';
import { ReactComponent as Notification } from '../../../assets/images/dashboard/icons/icons-notifications-new.svg';
import { ReactComponent as NoNotification } from '../../../assets/images/dashboard/icons/icons-notifications-no.svg';
import { ReactComponent as Close } from '../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as MiscSwitchImage } from '../../../assets/images/common/icons-misc-switch.svg';
import { ReactComponent as SendThanksIcon } from '../../../assets/images/send_thanks/icons-top-actions-send-thanks.svg';
import { ReactComponent as SwitchImage } from '../../../assets/images/account/components-illustrations-switch-account.svg';
import CommonService from '../../../services/CommonService';
import SendThanksService from '../../../services/SendThanksService';
import ReactGA from 'react-ga';

const usePathname = () => {
  const location = useLocation();
  return location.pathname;
};

const tourConfig = [
  {
    selector: '[data-tut="reactour__iso"]',
    content: () => (
      <div className="d-flex flex-column">
        <SwitchImage />
        <span className="font-sofia-pro-medium font-16 color-black-pearl">Switch between accounts</span>
        <p className="font-sofia-pro-light font-14 color-black-pearl">
          You can always switch between your personal and organisational accounts from here.
        </p>
      </div>
    ),
  },
];

const OrganisationHeader = props => {
  const [showSearchList, setShowSearchList] = useState(false);
  const [searchUser, setSearchUser] = useState('');

  // const [availableUsernameList, setAvaliableUsernameList] = useState([]);
  const [recentSearchList, setRecentSearchList] = useState([]);

  const [showSwitchToAccountModal, setShowSwitchToAccountModal] = useState(false);

  const [recentSearchPlace, setRecentSearchPlace] = useState([]);

  const [userData, setUserData] = useState([]);

  const [companyData, setCompanyData] = useState();

  const [recentNotifications, setRecentNotifications] = useState([]);

  const [isNotificationListLoaded, setIsNotificationListLoaded] = useState(false);

  const [notificationIconClicked, setNotificationIconClicked] = useState(false);

  const [showNotificationMediaModal, setShowNotificationMediaModal] = useState(false);

  const [mediaData, setMediaData] = useState([]);

  const [isTourOpen, setIsTourOpen] = useState(false);

  const [productList, setProductList] = useState([]);

  const [companyDetails, setCompanyDetails] = useState();

  const [asCompany, setAsCompany] = useState(false);

  const hideSwitchToAccountModalHandler = () => setShowSwitchToAccountModal(false);

  const showNotificationMediaModalHandler = () => setShowNotificationMediaModal(true);
  const hideNotificationMediaModalHandler = () => setShowNotificationMediaModal(false);

  let currentPath = usePathname();
  const history = useHistory();

  const closeTour = () => {
    setIsTourOpen(false);
  };

  const openTour = () => {
    setIsTourOpen(true);
  };

  const accentColor = '#5cb7b7';

  let userData1 = JSON.parse(localStorage.getItem('userData'));

  let logoFileImage = localStorage.getItem('profilePic');

  if (userData?.profilePhotoPath !== null) {
    logoFileImage = undefined;
  }

  useEffect(() => {
    console.log('called');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [currentPath]);

  useEffect(() => {
    if (currentPath === '/appreci-locations') {
      showPlceSearchList();
    }
    if (!currentPath.startsWith('/merchant')) {
      showUserSearchList();
    }
    if (isNotificationListLoaded === false) {
      getReceivedTransactions();
    }
    setIsNotificationListLoaded(true);
    return () => {};
  }, []);

  const showPlceSearchList = () => {
    httpService.get('Locations/history').then(res => {
      setRecentSearchPlace(res['data']);
    });
  };
  const showUserSearchList = () => {
    if (showSearchList === false) {
      httpService
        .get('/users/search')
        .then(response => {
          setRecentSearchList(response['data']);
        })
        .catch(error => {
          let errorResponseFieldErrors = error.response?.data.fieldErrors;
          let errorResponseGlobalErrors = error.response?.data.globalErrors;
          if (error) {
            let errorResponse = error.response?.data;
            console.log('error response field errors ', errorResponse);
          }
          console.log('error filed errors ', errorResponseFieldErrors);
          if (errorResponseFieldErrors.length > 0) {
            errorResponseFieldErrors.forEach(msg => {
              toast.error(msg);
            });
          }
          if (errorResponseGlobalErrors) {
            if (errorResponseGlobalErrors.length > 0) {
              errorResponseGlobalErrors.forEach(msg => {
                toast.error(msg.message);
              });
            }
          }
        });
      setShowSearchList(false);
    }
  };

  const getMerchantDetails = () => {
    MerchantService.getMerchantDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCompanyData(data);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        console.log('error filed errors ', errorResponseFieldErrors);
        if (errorResponseFieldErrors.length > 0) {
          errorResponseFieldErrors.forEach(msg => {
            toast.error(msg);
          });
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      try {
        if (searchUser) {
          let regexEmail = RegexPattern.EmailRegexValue;
          let regexMobile = RegexPattern.MobileRegexValue;

          let url = '';

          let data = {
            search: '',
            email: '',
            phone: '',
            userName: '',
          };

          if (regexEmail.test(searchUser)) {
            data.email = searchUser;
            data.search = '';
            data.phone = '';
            data.userName = '';
            url = httpService.post(`/users/search`, data);
          } else if (regexMobile.test(searchUser)) {
            data.email = '';
            data.search = '';
            data.phone = searchUser;
            data.userName = '';
            url = httpService.post(`/users/search`, data);
          } else {
            data.email = '';
            data.search = searchUser;
            data.phone = '';
            data.userName = '';
            url = httpService.post(`/users/search`, data);
          }

          const result = url
            .then(response => {
              // setShowSearchList(true);
              // setAvaliableUsernameList(response['data']);
            })
            .catch(error => {
              let errorResponseFieldErrors = error.response['data'].fieldErrors;
              let errorResponseGlobalErrors = error.response['data'].globalErrors;
              if (error) {
                let errorResponse = error.response['data'];
                console.log('error response field errors ', errorResponse);
              }
              console.log('error filed errors ', errorResponseFieldErrors);
              if (errorResponseFieldErrors.length > 0) {
                errorResponseFieldErrors.forEach(msg => {
                  toast.error(msg);
                });
              }
              if (errorResponseGlobalErrors) {
                if (errorResponseGlobalErrors.length > 0) {
                  errorResponseGlobalErrors.forEach(msg => {
                    toast.error(msg.message);
                  });
                }
              }
            });
        }
      } catch (error) {
        console.log('error response');
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchUser]);

  useEffect(() => {
    if (userData1 === undefined || userData1 === null) {
      getCurrentUser();
    } else {
      setUserData(userData1);
      userData1?.roles.forEach((data, index) => {
        if (data === 'CompanyAdmin') {
          getMerchantDetails();
          getCompanyDetails();
          setAsCompany(true)
        }
      });
    }

    let checkMerchantIsOnboarded = JSON.parse(sessionStorage.getItem('merchantOnboard'));
    if (Boolean(checkMerchantIsOnboarded) === true) {
      openTour();
    }

    SendThanksService.getListOfProducts('AU')
      .then(response => {
        if (response) {
          setProductList(response['data']);
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response?.data.globalErrors;
        if (error) {
          let errorResponse = error.response?.data;
          console.log('error response field errors ', errorResponse);
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });

    return () => {};
  }, []);

  // Get company data
  const getCompanyDetails = () => {
    merchantService
      .getCompanyDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setCompanyDetails(data);
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response?.data.globalErrors;
        if (error) {
          let errorResponse = error.response?.data;
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const getCurrentUser = () => {
    merchantService
      .getCurrentUserFromBackend()
      .then(response => {
        if (response) {
          let data = response['data'].roles;
          setUserData(response['data']);
          data.forEach((data, index) => {
            if (data === 'CompanyAdmin') {
              getMerchantDetails();
              getCompanyDetails();
              setAsCompany(true);
            }
          });
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const getReceivedTransactions = () => {
    TransactionHistoryService.getNotificationsListOfAdmin()
      .then(response => {
        if (response) {
          let data = response['data']?.data;
          if (props.role === 'admin') {
            data.forEach((value, index) => {
              productList.forEach((product, index) => {
                if (product?.price?.priceId === value.priceId) {
                  if (value?.type === 'GiftReceived') {
                    value['statusMessage'] = `You received ${product?.name} from`;
                  } else if (value?.type === 'GiftRedeemed') {
                    value['statusMessage'] = `Your ${product?.name} redeemed by`;
                  } else if (value?.type === 'GiftCancelled') {
                    value['statusMessage'] = `Your ${product?.name} cancelled by`;
                  } else if (value?.type === 'GiftSent') {
                    value['statusMessage'] = `You sent a ${product?.name} to`;
                  } else if (value?.type === 'Credit') {
                    value['statusMessage'] = `You received ${value?.quantity} from`;
                  }
                }
              });
            });
            setRecentNotifications(data);
            setIsNotificationListLoaded(true);
          } else if (props.role === 'employee') {
            data.forEach((value, index) => {
              productList.forEach((product, index) => {
                if (product?.price?.priceId === value.priceId) {
                  if (value?.type === 'GiftReceived') {
                    value['statusMessage'] = `You received ${product?.name} from`;
                  } else if (value?.type === 'GiftRedeemed') {
                    value['statusMessage'] = `Your ${product?.name} redeemed by`;
                  } else if (value?.type === 'GiftCancelled') {
                    value['statusMessage'] = `Your ${product?.name} cancelled by`;
                  } else if (value?.type === 'GiftSent') {
                    value['statusMessage'] = `You sent a ${product?.name} to`;
                  }
                }
              });
            });
            setRecentNotifications(data);
            setIsNotificationListLoaded(true);
          }
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const notificationClickHandler = index => {
    let id = recentNotifications[index].id;
    TransactionHistoryService.changeNotificationStatus(id)
      .then(response => {
        if (response) {
          // console.log('response ', response)
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const toggleNotificationPopup = () => {
    if (notificationIconClicked === false) {
      getReceivedTransactions();
    }
    setNotificationIconClicked(!notificationIconClicked);
  };

  const switchToAccountBtnHandler = () => {
    history.push('/dashboard');
  };

  const getNewMediaFile = index => {
    showNotificationMediaModalHandler();
    let guid = recentNotifications[index].message?.storedFile;
    let fileSecret = recentNotifications[index].message?.storedFileSecret;

    CommonService.getMediaFile(fileSecret, guid).then(response => {
      if (response) {
        let data = response['data'];
        setMediaData(data);
      }
    });
  };

  const innerRef = useOuterClick(ev => {
    if (ev.target.value === undefined) {
      setNotificationIconClicked(false);
    }
  });

  return (
    <div>
      {props.menu !== 'Merchant' && (
        <div className=" d-flex  flex-column ">
          <div
            className={
              currentPath === '/forwardthanks'
                ? ' d-flex flex-row justify-content-end dashboard-search__rectanglebig'
                : ' d-flex flex-row justify-content-between dashboard-search__rectanglebig'
            }
          >
            {currentPath === '/profile' || currentPath === '/merchant/profile' || currentPath === '/forwardthanks' ? (
              ''
            ) : (
              <OrganisationSearchBar SearchedList={recentSearchList} PlaceSearchedList={recentSearchPlace} />
            )}

            <div className=" d-flex flex-row justify-content-between align-items-center">
              {/* <div className="d-flex"> */}
                {currentPath === '/redeemThanks' ? (
                  <div className="header-button-claimcode my-2 d-flex flex-row mx-2">
                    <OrganisationClaimCodePage />
                  </div>
                ) : (
                  ''
                )}
                {currentPath !== '/organisation/send/selectItem' && asCompany == true ?
                <Link style={{ textDecoration: 'none' }} to="/organisation/send/selectItem">
                  <button
                    className="mr-2 my-2 shadow-none d-flex justify-content-center align-items-center w-120 h-44 btn  bg-primary-color font-sofia-pro-semi-bold text-white btn_cancel_invite send-milestone-btn"
                    type="submit"
                  >
                    Send gift
                  </button>
                </Link>
                : currentPath !== '/organisation/employee/selectItem' && <Link style={{ textDecoration: 'none' }} to="/organisation/employee/selectItem">
                    <button
                      className="mr-2 my-2 shadow-none d-flex justify-content-center align-items-center w-120 h-44 btn  bg-primary-color font-sofia-pro-semi-bold text-white btn_cancel_invite send-milestone-btn"
                      type="submit"
                    >
                      Send gift
                    </button>
                  </Link>}
                {props.role === 'admin' || 'employee' && (
                  <div className="d-flex flex-column mt-1" ref={innerRef} onClick={toggleNotificationPopup}>
                    <button
                      className="mt-2 mr-1 border-0 dashboard-notification_button dashboard-button"
                      onClick={toggleNotificationPopup}
                    >
                      {recentNotifications?.length > 0 && (
                        <Notification className="dashboard-icons__notificationnew " />
                      )}
                      {recentNotifications?.length === 0 && (
                        <NoNotification className="dashboard-icons__notificationnew " />
                      )}
                    </button>
                  </div>
                )}
                {notificationIconClicked === true && (
                  <div>
                    <div className="p-0 m-0 w-350 h-350 card border-0 header-notifications__popup__card">
                      <div className="p-2 w-100 d-flex">
                        <div className="w-100 d-flex flex-column align-items-start">
                          <div className="w-100 d-flex justify-content-between align-items-center">
                            <span className="mb-2 font-18 font-sofia-pro-semi-bold color-black-pearl">
                              New notifications
                            </span>
                            {/* <small className="font-12 font-sofia-pro-regular color-primary">Clear all</small> */}
                          </div>
                          {recentNotifications.length > 0 &&
                            recentNotifications.map((notification, index) => {
                              return (
                                <div
                                  className="p-0 m-0 w-100 mt-1 p-1 h-120 h-min-145 card bg-pink-closest border-pale-mavue-1 d-flex flex-row"
                                  key={index}
                                  onClick={notificationClickHandler.bind(this, index)}
                                >
                                  {notification.user !== null &&
                                    notification.user.profilePhotoUrl !== '' &&
                                    notification.user.profilePhotoUrl !== null && (
                                      <img
                                        className="rounded-circle"
                                        src={notification.user.profilePhotoUrl + '?h=30'}
                                        height="35px"
                                        width="35px"
                                      />
                                    )}
                                  {notification.user === null ||
                                    notification.user.profilePhotoUrl === '' ||
                                    (notification.user.profilePhotoUrl === null && <ProfilePic height="35px" />)}

                                  <div className="pl-1">
                                    <div className="d-flex flex-column">
                                      <Link
                                        className="text-decoration-none"
                                        to={
                                          notification.type === 'GiftReceived'
                                            ? '/redeemThanks'
                                            : notification.type === 'GiftRedeemed'
                                            ? '/transactionhistory'
                                            : ''
                                        }
                                      >
                                        <span className="font-16 font-sofia-pro-light color-black-pearl">
                                          <span className="font-16 font-sofia-pro-regular">
                                            {notification?.type !== undefined
                                              ? notification?.type === 'GiftReceived'
                                                ? 'You just received a gift from'
                                                : 'You sent a product to'
                                              : ''}
                                            &nbsp;
                                            {/* {
                                              notification.type !== 'GiftSent' && */}
                                            <span>
                                              {notification.user.fullName !== null
                                                ? notification.user.fullName
                                                : notification.user.userName}
                                            </span>
                                            {/* } */}
                                          </span>
                                        </span>
                                      </Link>
                                      <span className="font-12 color-gunmetal font-sofia-pro-light">
                                        {notification?.message?.type === 'Text' && (
                                          <span>Message: {notification?.message?.message}</span>
                                        )}
                                        {notification?.message?.type === 'Audio' && (
                                          <span
                                            className="cursor-pointer view-text-style"
                                            onClick={getNewMediaFile.bind(this, index)}
                                          >
                                            View Audio
                                          </span>
                                        )}
                                        {notification?.message?.type === 'Video' && (
                                          <span
                                            className="cursor-pointer view-text-style"
                                            onClick={getNewMediaFile.bind(this, index)}
                                          >
                                            View Video
                                          </span>
                                        )}
                                      </span>
                                      <p className="p-0 m-0 pl-1 font-12 font-sofia-pro-regular">
                                        <Moment format=" Do MMM h:mm a" utc local>
                                          {notification?.date}
                                        </Moment>
                                      </p>
                                    </div>

                                    {notification.type === 'GiftReceived' && (
                                      <div className="cursor-pointer">
                                        {/* <span onClick={navigateToSendThanks.bind(this, index)}> */}
                                        {props.role === 'admin' && (
                                          <Link
                                            to={{
                                              pathname: '/organisation/send/recipientInformation',
                                              state: {
                                                searchName: [recentNotifications[index].user?.userName],
                                              },
                                            }}
                                          >
                                            <SendThanksIcon height="25px" />
                                            <span className="font-14 font-sofia-pro-light color-primary">
                                              Reply thanks
                                            </span>
                                          </Link>
                                        )}
                                        {props.role === 'employee' && (
                                          <Link
                                            to={{
                                              pathname: '/organisation/employee/recipientInformation',
                                              state: {
                                                searchName: [recentNotifications[index].user?.userName],
                                              },
                                            }}
                                          >
                                            <SendThanksIcon height="25px" />
                                            <span className="font-14 font-sofia-pro-light color-primary">
                                              Reply thanks
                                            </span>
                                          </Link>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          {recentNotifications.length === 0 && (
                            <div className="w-100 pb-2 pt-4 text-center">
                              <span className="font-14 text-center color-black-pearl fonr-sofia-pro-regular">
                                No recent notifications found
                              </span>
                            </div>
                          )}
                          <div className="p-2 w-100 text-center">
                            {props.role === 'admin' && (
                              <Link to={'/admin/transactionhistory'}>
                                <small className="font-16 font-sofia-pro-regular color-primary">
                                  View all notifications
                                </small>
                              </Link>
                            )}
                            {props.role === 'employee' && (
                              <Link to={'/employee/transactionhistory'}>
                                <small className="font-16 font-sofia-pro-regular color-primary">
                                  View all notifications
                                </small>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {companyDetails !== undefined && companyDetails !== null ? 
                  (<img src={companyDetails['logoPath'] + '?h=80&w=80'} className="mr-3 mt-2 w-44 h-44" />)
                : userData?.profilePhotoPath !== undefined && userData?.profilePhotoPath !== null ? 
                  (<img src={userData?.profilePhotoPath + '?h=80&w=80'} className="mr-3 mt-2 w-44 h-44" style={{borderRadius: '50px'}} />)
                : logoFileImage !== undefined ? 
                  (<img src={logoFileImage} height="44px" width="44px" className="mr-3 mt-2" style={{borderRadius: '50px'}}/>)
                : <ProfilePic className="mr-3 mt-2 w-44 h-44" />}
              {/* </div> */}
            </div>
          </div>
        </div>
      )}

      {props.menu === 'Merchant' && (
        <div className="w-100 p-3 bg-white d-flex justify-content-between">
          <span className="font-sofia-pro-light color-black-pearl font-18">{companyData?.company?.legalName}</span>
          <span
            className="font-sofia-pro-regular color-black-pearl font-14 cursor-pointer"
            onClick={() => setShowSwitchToAccountModal(true)}
            data-tut="reactour__iso"
          >
            <MiscSwitchImage className="pr-2" />
            Switch to {userData['fullName']}
          </span>

          <Modal
            className="pt-4 border-0
         modal-background-shadow switch-to-account-modal"
            show={showSwitchToAccountModal}
            onHide={hideSwitchToAccountModalHandler}
            animation={false}
          >
            <Modal.Header className="p-0 m-0 border-0">
              <button
                type="button"
                className="close switch-to-account-modal__close__icon"
                onClick={hideSwitchToAccountModalHandler}
              >
                <Close />
              </button>
            </Modal.Header>
            <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
              <p className="font-16 font-sofia-pro-semi-bold color-black-pearl">Switch to {userData['fullName']}?</p>
              <div className="w-100 p-0 m-0 d-flex justify-content-start align-items-center">
                <button
                  type="submit"
                  className="btn bg-transparent color-primary border-primary-color font-sofia-pro-semi-bold font-16 outline-none  shadow-none"
                  onClick={switchToAccountBtnHandler}
                >
                  Switch
                </button>
                <button
                  className="p-0 m-0 ml-2 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium outline-none  shadow-none"
                  onClick={hideSwitchToAccountModalHandler}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
          <Tour
            className="d-flex justify-content-center align-items-center border-radius-11"
            onRequestClose={closeTour}
            steps={tourConfig}
            isOpen={isTourOpen}
            showNumber={false}
            disableDotsNavigation={false}
            showButtons={false}
            showNavigationNumber={false}
            showCloseButton={false}
            showNavigation={false}
          />
        </div>
      )}

      {notificationIconClicked === true && (
        <Modal
          className="pt-4 border-0
        modal-background-shadow switch-to-account-modal"
          show={showNotificationMediaModal}
          onHide={hideNotificationMediaModalHandler}
          animation={false}
        >
          <Modal.Header className="p-0 m-0 border-0">
            <button
              type="button"
              className="close switch-to-account-modal__close__icon"
              onClick={hideNotificationMediaModalHandler}
            >
              <Close />
            </button>
          </Modal.Header>
          <Modal.Body className="p-0 m-0 px-3 pb-2 border-0 text-center">
            {mediaData?.fileCategory === 'Audio' && (
              <audio controls preload="none">
                <source src={mediaData?.url} type="audio/mpeg" />
              </audio>
            )}

            {mediaData?.fileCategory === 'Video' && (
              // <audio controls preload="none">
              //   <source src={mediaData?.url} type="audio/mpeg" />
              // </audio>
              <video width="320" height="240" controls>
                <source src={mediaData?.url} type="video/mp4" />
              </video>
            )}
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

// DISPATCH  - STORE

const mapDispatchToProps = dispatch => {
  return {
    searchData: data => dispatch(searchDataDetails(data)),
  };
};

export default connect(null, mapDispatchToProps)(OrganisationHeader);

function useOuterClick(callback) {
  const callbackRef = useRef(); // initialize mutable callback ref
  const innerRef = useRef(); // returned to client, who sets the "border" element

  // update callback on each render, so second useEffect has most recent callback
  useEffect(() => {
    callbackRef.current = callback;
  });
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
    function handleClick(e) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) callbackRef.current(e);
    }
  }, []); // no dependencies -> stable click listener

  return innerRef; // convenience for client (doesn't need to init ref himself)
}
