/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const SEARCH_DATA = 'SEARCH_DATA';

export const searchDataDetails = data => ({
  type: SEARCH_DATA,
  payload: {
    data,
  },
});

let initialState = {
  searchData: '',
};

export const headerReducer = persistReducer(
  {
    storage,
    key: 'appreci-header',
    whitelist: ['searchData'],
  },
  (state = initialState, action) => {
    switch (action.type) {
      case 'SEARCH_DATA':
        const searchData = action.payload.data;
        return {
          searchData,
        };
      default:
        return state;
    }
  }
);
