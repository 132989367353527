/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

function LogoDropZone(props) {
  const maxSize = 10485760;

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      props.rejectedFiles(rejectedFiles);
    }

    if (acceptedFiles.length > 0) {
      props.acceptedFiles(acceptedFiles);
    }
  }, []);

  const { isDragActive, getRootProps, getInputProps, isDragReject, acceptedFiles, rejectedFiles, getFilesFromEvent } =
    useDropzone({
      onDrop,
      minSize: 0,
      maxSize,
      noClick: true,
      noKeyboard: true,
      accept: 'image/png, image/jpeg',
      multiple: props.multiple,
    });

  const isFileTooLarge = rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

  const getFiles = getFilesFromEvent?.map(file => {
    console.log('file ', file);
  });

  return (
    <div>
      <div {...getRootProps()}>
        <input {...getInputProps()} onClick={e => (e.target.value = null)} />
        <div className="d-flex flex-column align-items-center">
        {isDragActive && !isDragReject ? (
            <span className="font-14 font-sofia-pro-semi-bold color-gunmetal">
              Drop your file here...
            </span>
          ): !isDragReject && (
            <span className="font-14 font-sofia-pro-semi-bold color-gunmetal">
              Drag and drop your file here, or
              <span className="font-14 font-sofia-pro-semi-bold color-primary">&nbsp;Choose file</span>
            </span>
          )}
          <span className="font-12 font-sofia-pro-medium color-bouquet">{props.supportText}</span>
          <input {...getInputProps()} onClick={e => (e.target.value = null)} />
          {isDragReject && (
            <span className="mt-3 pl-3 font-14 font-sofia-pro-light color-gunmetal">
              "File type must be png or jpeg!"
            </span>
          )}
          {isFileTooLarge && (
            <div className="font-16 font-sofia-pro-semi-bold color-thatch-green">File is too large.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogoDropZone;
