import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import React from 'react';

function PopoverProfile({ toAccount, toHistory }) {
  const history = useHistory();
  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    toast.success('You’ve been logged out successfully.');
    history.push('/login');
  };
  return (
    <div className="d-flex flex-column">
      {toAccount &&
        <Link to={toAccount} style={{ textDecoration: 'none' }} className="p-2">
          <span className="pl-2 font-14 font-sofia-pro-regular color-black-pearl border-0">Account</span>
        </Link>
      }
      {toHistory &&
        <Link to={toHistory} style={{ textDecoration: 'none' }} className="p-2">
          <span className="pl-2 font-14 font-sofia-pro-regular color-black-pearl border-0">
            Transaction history
          </span>
        </Link>
      }

      <div className="dashboard-account_bar"></div>
      <span
        className="p-2 pl-4 font-14 font-sofia-pro-light color-reddish border-0  cursor-pointer"
        onClick={logout}
      >
        Logout
      </span>
    </div>
  );
}

export default PopoverProfile;