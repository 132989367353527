/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Merchant onboarding schema
export const merchantOnboardingData = {
  requirementStatus: '',
  company: {
    legalName: '',
    webSite: '',
    taxId: '',
    taxIdProvided: true,
    phone: '',
    classification: '',
    address: {
      latitude: 0,
      longitude: 0,
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
    verificationFileBase64: '',
    verificationStatus: 'None',
    logoFileBase64: '',
  },
  payout: {
    transfersEnabled: true,
    transferSchedule: '',
    weekDaySchedule: '',
    dayOfMonthSchedule: 0,
    products: [
      {
        priceId: '',
        payout: 0,
        agreementOn: '',
        hasAgreement: true,
      },
    ],
  },
  bankAccount: {
    token: '',
    display: '',
  },
  representative: {
    id: '',
    isOwner: true,
    role: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phone: '',
    idNumber: '',
    idNumberProvided: true,
    address: {
      latitude: 0,
      longitude: 0,
      street: '',
      suburb: '',
      state: '',
      postcode: '',
      country: '',
    },
    photoId: {
      frontFileBase64: '',
      backFileBase64: '',
    },
    addressVerification: {
      frontFileBase64: '',
      backFileBase64: null,
    },
    verificationStatus: 'None',
  },
};
