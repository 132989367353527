/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';
import { ReactComponent as Donate } from '../../../../assets/images/dashboard/icons/components-illustrations-donate.svg';
import Charity1 from '../../../../assets/images/redeem_thanks/image/group-7.png';
import Charity2 from '../../../../assets/images/redeem_thanks/image/group-9.png';
import Charity3 from '../../../../assets/images/redeem_thanks/image/group-11.png';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import '../../../../components/individual/dashboard/DashBoard.scss';
import './Donate.scss';
import { BrowserRouter as Router, Link } from 'react-router-dom';
const DonatePage = () => {
  let Balance = 7;
  let Amount = 29.95;
  const [modalShow, setModalShow] = React.useState(false);

  function DonateModal(props) {
    return (
      <div>
        <Modal
          {...props}
          size="md"
          centered
          animation={false}
          backdrop="static"
          className="dashboard-donate-background_popup"
        >
          <Modal.Body>
            <div className="d-flex flex-column">
              <span className="font-sofia-pro-semi-bold dahboard-text_Donate-thanks-to-Bey ">
                Donate thanks to Beyond Blue
              </span>

              <span className="dashboard-text__Small-gestures-like">Small gestures like this make a big impact</span>

              <button className="border-0 dashboard-donate-button_cancelicon" onClick={props.onHide}>
                <Close />
              </button>
            </div>
            <div className="d-flex flex-column justify-content-center ">
              <span className="font-sofia-pro-semi-bold dashboard-text_Available-Thanks-Bal my-2">
                Available Thanks Balance: {Balance}
              </span>
              <div className="d-flex flex-row justify-content-center">
                <div
                  className={
                    Balance < 10 && Balance >= 5 ? 'dashboard-card__activequntity ' : 'dashboard-card__nonactivequntity'
                  }
                >
                  <span
                    className={
                      Balance < 10 && Balance >= 5
                        ? 'font-sofia-pro-semi-bold dashboard-text_number d-flex justify-content-center my-2'
                        : 'font-sofia-pro-regular dashboard-text_inactive d-flex justify-content-center my-2'
                    }
                  >
                    5
                  </span>
                </div>
                <div
                  className={
                    Balance < 25 && Balance >= 10
                      ? 'dashboard-card__activequntity mx-2'
                      : 'dashboard-card__nonactivequntity mx-2'
                  }
                >
                  <span
                    className={
                      Balance < 25 && Balance >= 10
                        ? 'font-sofia-pro-semi-bold dashboard-text_number d-flex justify-content-center my-2'
                        : 'font-sofia-pro-regular dashboard-text_inactive d-flex justify-content-center my-2'
                    }
                  >
                    10
                  </span>
                </div>
                <div
                  className={
                    Balance < 50 && Balance >= 25
                      ? 'dashboard-card__activequntity mx-2'
                      : 'dashboard-card__nonactivequntity mx-2'
                  }
                >
                  <span
                    className={
                      Balance < 50 && Balance >= 25
                        ? 'font-sofia-pro-semi-bold dashboard-text_number d-flex justify-content-center my-2'
                        : 'font-sofia-pro-regular dashboard-text_inactive d-flex justify-content-center my-2'
                    }
                  >
                    25
                  </span>
                </div>
                <div
                  className={
                    Balance >= 50 ? 'dashboard-card__activequntity mx-2' : 'dashboard-card__nonactivequntity mx-2'
                  }
                >
                  <span
                    className={
                      Balance >= 50
                        ? 'font-sofia-pro-semi-bold dashboard-text_number d-flex justify-content-center my-2'
                        : 'font-sofia-pro-regular dashboard-text_inactive d-flex justify-content-center my-2'
                    }
                  >
                    50
                  </span>
                </div>
                <div
                  className={
                    Balance >= 50 ? 'dashboard-card__activequntity mx-2' : 'dashboard-card__nonactivequntity mx-2'
                  }
                >
                  <span
                    className={
                      Balance >= 50
                        ? 'font-sofia-pro-semi-bold dashboard-text_number d-flex justify-content-center my-2'
                        : 'font-sofia-pro-regular dashboard-text_inactive d-flex justify-content-center my-2'
                    }
                  >
                    other
                  </span>
                </div>
              </div>
              <span className="font-sofia-pro-regular dashboard-text__Total-payable-amount d-flex justify-content-center my-3">
                Total payable amount: ${Amount}
              </span>
              <div className="d-flex justify-content-center">
                <ProceedButton />
              </div>
              <div className="d-flex justify-content-center my-2">
                <button className="border-0 bg-transparent" onClick={props.onHide}>
                  <span className="font-sofia-pro-light ">Cancel</span>
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
  function DonateItem(props) {
    return (
      <Modal
        {...props}
        size="md"
        centered
        animation={false}
        backdrop="static"
        className="dashboard-donate-background_popup"
      >
        <div className="donate-donatemorewidth">
          <Modal.Body className="doante_successful ">
            <button
              className="border-0 donate-button__canceliconsucees"
              onClick={
                (props.onHide,
                () => {
                  setModalShow(false);
                })
              }
            >
              <Close />
            </button>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <Donate />
              <span className="font-sofia-pro-semi-bold donate-text_donationsuccessful my-2">Donation successful</span>
              <span className="font-sofia-pro-light donate-text_Giving-is-not-about my-2">
                Giving is not about just the donation, it’s about making a difference
              </span>
              <div className="d-flex flex-row my-2">
                <button
                  className="doanate-button_donatemore border-0"
                  onClick={
                    (props.onHide,
                    () => {
                      setModalShow(false);
                    })
                  }
                >
                  <span className="font-sofia-pro-semi-bold donate-text__donatemore">Donate more</span>
                </button>

                <Link to="/dashboard">
                  <button className="donate-button__gohome border-0 mx-2">
                    <span className="font-sofia-pro-semi-bold donate-text__gohome">Go home</span>
                  </button>
                </Link>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    );
  }

  function Proceed(props) {
    return (
      <div>
        <Modal
          {...props}
          size="md"
          centered
          animation={false}
          backdrop="static"
          className="dashboard-donate-background_popup"
        >
          <Modal.Body className="d-flex flex-column  ">
            <div className="donate-proceed_width">
              <button
                className="border-0 donate-button__canceliconpayment"
                onClick={
                  (props.onHide,
                  () => {
                    setModalShow(false);
                  })
                }
              >
                <Close />
              </button>
              <div className="d-flex flex-column">
                <span className="font-sofia-pro-semi-bold dashboard-text__Select-your-payment">
                  Select your payment method
                </span>
                <span className="font-sofia-pro-light dashboard-text__Pay-using-your-credit">
                  Pay using your credit or debit cards
                </span>
              </div>
              <div className="my-4">
                <span className="font-sofia-pro-medium dashboard-text__Your-saved-credit-an my-3">
                  Your saved credit and debit cards
                </span>
                <div className="d-flex flex-row justify-content-between ">
                  <div className="payment-options-radio__item">
                    <input type="radio" checked={true} readOnly />
                    <label className="font-14 font-sofia-pro-light dashboard-text_VISA  ">
                      <span> VISA •••• •••• •••• 4242 | 12/21</span>
                    </label>
                  </div>
                  <div>
                    <button type="button" className="mr-2 p-2 btn btn-sm border-pale-pink ">
                      <span className=" font-sofia-pro-regular dashboard-donate-text_edit">Edit</span>
                    </button>
                    <button type="button" className="p-2 btn btn-sm border-pale-pink ">
                      <span className="font-14 font-sofia-pro-regular color-primary">Delete</span>
                    </button>
                  </div>
                </div>
              </div>
              <span className="dashboard-text_addpayment font-sofia-pro-medium">Add a Payment Method</span>
              <div>
                <div className="d-flex flex-row pl-2 ">
                  <div className="my-1">
                    <input
                      type="radio"
                      checked={false}
                      readOnly
                      className="dashboard-donate-radio__Iconsradiounselected "
                    />
                  </div>
                  <div className="pl-2">
                    <span className="font-sofia-pro-light dashboard-donate__Add-a-Debit-or-credi ">
                      Add a Debit or credit card
                    </span>
                  </div>
                </div>
                <div className="my-3">
                  <DonatePay />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  function DonateButton() {
    return (
      <>
        <button className="dashboard-button__donatechar border-0 my-4 " onClick={() => setModalShow(true)}>
          <span className="dashboard-text_donate font-sofia-pro-semi-bold">Donate</span>
        </button>
        <DonateModal show={modalShow} onHide={() => setModalShow(false)} />
      </>
    );
  }

  function ProceedButton() {
    const [modalProceed, setModalProceed] = React.useState(false);
    return (
      <>
        <button className="border-0 dashboard-button_Proceed" onClick={() => setModalProceed(true)}>
          <span className="font-sofia-pro-semi-bold dashboard-text_Proceed">Proceed</span>
        </button>
        <Proceed show={modalProceed} onHide={() => setModalProceed(false)} />
      </>
    );
  }
  function DonatePay() {
    const [modalDonate, setModalDonate] = React.useState(false);
    return (
      <>
        <button className="donate-button__donate border-0" onClick={() => setModalDonate(true)}>
          <span className="donate-text__donate font-sofia-pro-semi-bold">Donate</span>
        </button>
        <DonateItem show={modalDonate} onHide={() => setModalDonate(false)} />
      </>
    );
  }

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar menu="Redeemthanks" />
      </div>
      <div className="h-100vh p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half">
        <Header />
        <div className="m-4 ">
          <span className="font-sofia-pro-light dashboard-donate__topcharities ">Top charities</span>
        </div>
        <div className="row  p-0 m-0">
          <div className=" col-sm-12 col-md-4 col-lg-4 ">
            <div className=" card  dashboard-donate_card  border-radius-17 px-2">
              <img src={Charity1} alt="Charity" className="dashboard-donate__image my-2 mx-1" />
              <span className="dashboard-text__We-are-an-Australian font-sofia-pro-light my-lg-3 mx-lg-1 my-md-5 ">
                We are an Australian not for profit that run life saving and life changing projects in developing
                countries.
              </span>

              <DonateButton />
            </div>
          </div>
          <div className=" col-sm-12 col-md-4 col-lg-4 ">
            <div className="card px-2 dashboard-donate_card  border-radius-17 ">
              <img src={Charity2} alt="Charity" className="dashboard-donate__image my-2 mx-1" />
              <span className="dashboard-text__We-are-an-Australian font-sofia-pro-light my-2 mx-1">
                The Good Chat Foundation would like to acknowledge Aboriginal and Torres Strait Islander people as
                Australia’s First People and Traditional Custodians.
              </span>
              <DonateButton />
            </div>
          </div>
          <div className=" col-sm-12 col-md-4 col-lg-4 ">
            <div className="card  px-2  dashboard-donate_card border-radius-17  ">
              <img src={Charity3} alt="Charity" className="dashboard-donate__image my-2 mx-1" />
              <span className="dashboard-text__We-are-an-Australian font-sofia-pro-light my-2 ">
                Beyond Blue provides information and support to help everyone in Australia achieve their best possible
                mental health, whatever their age and wherever they live.
              </span>
              <DonateButton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonatePage;
