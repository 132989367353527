/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import './Details.scss';
import LogoDropZone from '../../../../common/Upload/LogoDropezone';
import Toast from '../../../../services/ToasterService';
import Stepper from '../../../../common/Stepper/Stepper';
import OrganisationService from '../../../../services/OrganisationService';
import CommonService from '../../../../services/CommonService';
import { ReactComponent as Logo } from '../../../../assets/images/dashboard/icons/logo-colour.svg';
import IconsCheckFilledImage from '../../../../assets/images/common/icons-check-filled-pink.png';
import BackIcon from '../../../../assets/images/common/icons-back.png';

const initialValues = {
  name: '',
};

const validate = values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Required';
  }

  return errors;
};

function OrganisationDetailsPage(props) {
  const history = useHistory();

  const [showLoader, setShowLoader] = useState(false);

  const [steps, setSteps] = useState([
    {
      title: 'Organisation name',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Invite Employees',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Payment',
      icon: IconsCheckFilledImage,
    },
    {
      title: 'Finish',
      icon: IconsCheckFilledImage,
    },
  ]);

  const [currentStep, setCurrentStep] = useState(0);
  const [merchantData, setMerchantData] = useState();
  const [checkUserHasCompnayAdminRole, setCheckUserHasCompnayAdminRole] = useState(false);
  const [logoFileImage, setLogoFileImage] = useState();
  const [logoFileData, setLogoFileData] = useState();
  const [ProofFileImage, setProofFileImage] = useState();
  const [proofFileData, setProofFileData] = useState();
  const [logoFileName, setLogoFileName] = useState();
  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const logoChangeHandler = files => {
    let logoBase64 = '';
    let fileName = files[0].name;
    let checkValidImage = CommonService.validateImage(fileName);

    setLogoFileName(fileName);

    if (checkValidImage) {
      let logoImageUrl = URL.createObjectURL(files[0]);
      setLogoFileImage(logoImageUrl);
      getBase64(files[0], result => {
        logoBase64 = result.split(',')[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData(logoBase64);
      });
    } else {
      Toast('Only jpg/jpeg and png files are allowed!', 'warning');
    }
  };

  const rejectedProofFilesHandler = rejectedFiles => {
    if (rejectedFiles?.length > 0) {
      rejectedFiles.forEach((rejectedFile, index) => {
        rejectedFile.errors.forEach(error => {
          Toast(error.code + ' - ' + error.message, 'error');
        });
      });
    }
  };
  const clearProofImage = () => {
    setProofFileData();
    setProofFileImage('');
  };
  const proofChangeHandler = files => {
    let fileName = files[0].name;
    let checkValidFile = CommonService.validateFile(fileName);
    let verificationBase64 = '';

    if (checkValidFile) {
      let proofImageUrl = URL.createObjectURL(files[0]);
      setProofFileImage(files[0].name);

      getBase64(files[0], result => {
        verificationBase64 = result.split(',')[1];
        verificationBase64 = /,(.+)/.exec(result)[1];
        setProofFileData(verificationBase64);
      });
    } else {
      Toast('Only pdf, docx, jpg and png files are allowed!', 'warning');
    }
  };
  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData'));

    if (userData?.roles.length > 0) {
      userData?.roles.forEach((role, index) => {
        if (role === 'CompanyAdmin') {
          setCheckUserHasCompnayAdminRole(true);
        }
      });
    }
  }, []);

  const onSubmit = values => {
    let data = {
      name: values.name,
      features: '',
      logoFileBase64: logoFileData,
      logoFileName: logoFileName,
    };

    let payload = {
      monthlyBudget: sessionStorage.getItem('budget'),
      monthlyAutoTopUp: sessionStorage.getItem('autoTopUp')
    };

    if (checkUserHasCompnayAdminRole) {
      data.features = 'Organisation';
      OrganisationService.updateOrganisation(data)
        .then(response => {
          if (response) {
            history.push('/organisation/invite');
            sessionStorage.setItem('OrganisationAdmin', true);
          }
        })
        .catch(error => {
          if (error) {
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (error) {
              let errorResponse = error.response['data'];
              console.log('error response field errors ', errorResponse);
            }
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors?.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  Toast(msg.message, 'error');
                });
              }
            }
          }
        });
      OrganisationService.manageCreditBudget(payload);
    } else if (!checkUserHasCompnayAdminRole) {
      data.features = 'Organisation';
      OrganisationService.createOrganisation(data)
        .then(response => {
          if (response) {
            OrganisationService.manageCreditBudget(payload);
            history.push('/organisation/invite');
            sessionStorage.setItem('OrganisationAdmin', true);
          }
        })
        .catch(error => {
          if (error) {
            let errorResponseFieldErrors = error.response['data'].fieldErrors;
            let errorResponseGlobalErrors = error.response['data'].globalErrors;
            if (error) {
              let errorResponse = error.response['data'];
              console.log('error response field errors ', errorResponse);
            }
            loopFieldErrors(errorResponseFieldErrors);
            if (errorResponseGlobalErrors) {
              if (errorResponseGlobalErrors?.length > 0) {
                errorResponseGlobalErrors.forEach(msg => {
                  Toast(msg.message, 'error');
                });
              }
            }
          }
        });
    }
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    setShowLoader(false);
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };
  const clearLogoImage = () => {
    setLogoFileData();
    setLogoFileImage();
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
    enableReinitialize: true,
  });

  const barStyle = {
    height: '100px',
  };

  return (
    <div className="bg-alice-blue">
      <div className="p-2">
        <Logo />
      </div>
      <div className="mx-lg-5 mx-md-2 my-2 p-lg-4 p-md-2 bg-white">
        <div className="p-0 m-0 row">
          <div className="col-md-2 col-lg-1">&nbsp;</div>
          <div className="col-sm-12 col-md-8 col-lg-10">
            <div className="mb-3">
              <Link to="/organisation/plans">
                <button className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none">
                  <img src={BackIcon} height="30px" />
                  Back
                </button>
              </Link>
            </div>
            <div>
              <Stepper barStyle={barStyle} steps={steps} activeStep={currentStep} />
            </div>

            <div>
              {/* {
                                errors?.length > 0 &&
                                <div className="p-2 my-1 border-radius-5 border-grey-blue">

                                    <span className="font-sofia-pro-regular color-reddish font-16">Note</span>

                                    <ul>
                                        {
                                            errors?.map((msg, index) => {
                                                return (
                                                    <li className="font-sofia-pro-regular color-reddish font-14">{msg}</li>
                                                )
                                            })
                                        }
                                    </ul>

                                </div>
                            } */}
              <div className="p-0 m-0 p-3 card">
                <span className="font-sofia-pro-semi-bold font-36 color-black-pearl">
                  What do you want to call your organisation?
                </span>
                <form className="mt-3" onSubmit={formik.handleSubmit}>
                  <div className="p-0 b-0 pb-1 form-group">
                    <label className="font-sofia-pro-regular color-charcoal font-16">
                      Enter your organisation name
                    </label>
                    <input
                      type="text"
                      className="form-control shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle"
                      name="name"
                      placeholder="Eg: Google"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                  </div>
                  <div className="p-0 b-0 pb-1 mt-2 form-group">
                    <label className="font-sofia-pro-regular color-black-pearl font-16">
                      Logo
                      <span className="d-block font-sofia-pro-light font-14 color-black-pearl">
                        This will be displayed to customers on the mobile and web application (Public)
                      </span>
                    </label>

                    <label className="position-relative w-100">
                      <div className="upload-file-style">
                        <LogoDropZone
                          acceptedFiles={logoChangeHandler}
                          rejectedFiles={rejectedProofFilesHandler}
                          maxLength={1}
                          supportText={'Supports: PNG, JPEG file'}
                          multiple={false}
                        ></LogoDropZone>
                      </div>
                    </label>

                    {logoFileImage && (
                      <div className="mt-2 d-flex justify-content-between">
                        <img src={logoFileImage} height="35px" />
                        <button
                          type="button"
                          className="ml-2 shadow-none btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                          onClick={clearLogoImage}
                        >
                          Clear
                        </button>
                      </div>
                    )}
                  </div>

                  {/* <Button classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white" children="Next" showLoader={showLoader} onClick={() => onSubmit} /> */}
                  <button
                    className="my-2 shadow-none d-flex justify-content-center align-items-center w-180 h-60 btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                    type="submit"
                  >
                    Next
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-lg-1">&nbsp;</div>
        </div>
      </div>
    </div>
  );
}

export default OrganisationDetailsPage;
