/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';

const getDashboardData = () => {
  return httpService.get('Dashboards/individual');
};
const getGraphDataMonthly = () => {
  return httpService.get('dashboards/individual/charts?frequency=monthly');
};
const getGraphDataYearly = () => {
  return httpService.get('dashboards/individual/charts?frequency=yearly');
};
const getGraphDataDaily = data => {
  return httpService.get(`dashboards/individual/charts?frequency=${data}`);
};

// Merchant
const getMerchantDashboardData = () => {
  return httpService.get('/Dashboards/merchant');
};

const getMerchantDashboardWidget = (from, to) => {
  return httpService.get(`/Dashboards/merchant/widgets?from=${from}&to=${to}`);
};

const getMerchantDashboardGraphData = data => {
  return httpService.get(`/Dashboards/merchant/charts?frequency=${data}`);
};

//organisation

const organisationGetGraphDataDaily = data => {
  return httpService.get(`/Dashboards/organisation/charts?frequency=${data}`);
};

export default {
  getDashboardData,
  getGraphDataDaily,
  getGraphDataMonthly,
  getGraphDataYearly,
  getMerchantDashboardData,
  getMerchantDashboardWidget,
  getMerchantDashboardGraphData,
  organisationGetGraphDataDaily,
};
