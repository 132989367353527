/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import { Link } from 'react-router-dom';
import OrganisationSidebar from '../../organisationsidebar/organisationsidebar';
import OrganisationHeader from '../../organisationheader/organisationHeader';

const OrganisationBeerRedeemd = () => {
  const BeerData = JSON.parse(sessionStorage.getItem('GiftSuccessDetails'));
  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0  col-lg-2 col-sm-12 my-4  ">
        <OrganisationSidebar menu="Redeemthanks" />
      </div>
      <div className=" p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10  d-flex flex-column right_half h-100vh">
        <OrganisationHeader role="employee" value="Redeemed" />
        <div className="d-flex align-items-center justify-content-center m-5 ">
          <div className="d-flex flex-column redeemedbeer-rectangle__big align-items-center  ">
            <img src={BeerData['Url'] + '?h=150&w=150'} alt="" className="my-3" />
            <span className="redeemedbeer-text__You-have-successfull font-sofia-pro-regular">
              Your {BeerData['Name']} is on the way
            </span>
            <span className="redeemedbeer-text__To-cancel-this-trans font-sofia-pro-light my-3">
              We appreciate you taking the time for sharing your details. We’ll send you <br />a confirmation email
              about this order.
            </span>
            <div className="">
              <Link to="/organisation/employee/recipientInformation">
                <button className="border-0 redeemedbeer-button__senagain m-3">
                  <span className="redeem-text__proceed font-sofia-pro-semi-bold">Send thanks</span>
                </button>
              </Link>
              <Link to="/employee/dashboard">
                <button className=" redeemedbeer-button-Home bg-transparent" onClick={''}>
                  <span className=" redeemedbeer-text__home font-sofia-pro-semi-bold">Home</span>
                </button>
              </Link>
            </div>
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
};

export default OrganisationBeerRedeemd;
