/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../../common/Button/Button';
import MerchantService from '../../../../services/MerchantService';
import { EmailRegexValue } from '../../../../utils/RegexPatterns';
import Toast from '../../../../services/ToasterService';
import './Employees.scss';
import ErrorImage from '../../../../assets/images/error-icons/icons-error-info.png';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
};

const validate = values => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  } else if (values.firstName.length > 25) {
    errors.firstName = 'Characters length should not exceed 25';
  }

  if (!values.lastName) {
    errors.lastName = 'Required';
  } else if (values.lastName.length > 25) {
    errors.lastName = 'Characters length should not exceed 25';
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!EmailRegexValue.test(values.email)) {
    errors.email = 'Invalid email format';
  }

  return errors;
};

const EditEmployeePage = props => {
  const [editModalShow, setEditModalShow] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  useEffect(() => {
    getEmployeeData(props.id);
    window.scrollTo(0, 0);
  }, []);

  // Updating user details
  const onSubmit = values => {
    toast.dismiss();

    setShowLoader(true);

    let data = values;

    data['role'] = 'CompanyAdmin';

    MerchantService.updateCompanyUser(props.id, data)
      .then(response => {
        if (response) {
          setShowLoader(false);
          props.closeModal();
          props.loadEmployeesList();
          Toast('Your changes have been saved successfully.', 'success');
        }
      })
      .catch(error => {
        setShowLoader(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors?.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit,
    validate,
  });

  // Get employee data
  const getEmployeeData = id => {
    MerchantService.getCompanyUser(props.id).then(response => {
      if (response) {
        let data = response['data'];
        handleEditModalShow();

        formik.setFieldValue('firstName', data.firstName);
        formik.setFieldValue('lastName', data.lastName);
        formik.setFieldValue('email', data.email);
        formik.setFieldValue('phone', data.phone);
      }
    });
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12">
        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="p-0 m-0 row">
            <div className="p-0 m-0 pr-2 col-sm-12 col-md-6 col-lg-6">
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">First name *</label>
                <input
                  type="text"
                  className="form-control h-60 font-sofia-pro-regular login-email__placeholder border-light-periwinkle shadow-none"
                  name="firstName"
                  placeholder="Eg: John"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className="pt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.firstName}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="p-0 m-0 pr-2 col-sm-12 col-md-6 col-lg-6">
              <div className="p-0 b-0 pb-1 form-group">
                <label className="font-sofia-pro-regular color-charcoal font-16">Last Name *</label>
                <input
                  type="text"
                  className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
                  name="lastName"
                  placeholder="Eg: Doe"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                    <img className="mr-1" src={ErrorImage} /> {formik.errors.lastName}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="p-0 b-0 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Email *</label>
            <input
              type="text"
              className="form-control h-60 font-sofia-pro-regular border-light-periwinkle shadow-none"
              name="email"
              placeholder="Eg: test@gmail.com"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="mt-2 d-flex align-items-center color-nero font-12 font-sofia-pro-regular">
                <img className="mr-1" src={ErrorImage} /> {formik.errors.email}
              </div>
            ) : null}
          </div>
          <div className="d-flex mt-2">
            <Button
              classNameValue="my-2 shadow-none d-flex justify-content-center align-items-center w-180  btn  bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
              children="Save"
              showLoader={showLoader}
              onClick={() => onSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEmployeePage;
