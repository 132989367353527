const RecordAudioStore = (state, action) => {
  switch (action.type) {
    case 'toggle_modal':
      state.showModal = action.data;
      return { ...state };
      break;
    case 'record_state_start':
      state.recordState = action.data.state;
      state.recorder = action.data.recorder;
      return { ...state };
      break;
    case 'record_state_stop':
      state.recordState = action.data.state;
      state.audio = action.data.audio;
      state.stopModal = action.data.stopModal;
      return { ...state };
      break;
    case 'record_stop_confirmation':
      state.stopModal = action.data.modal;
      state.recordState = action.data.recordState;
      return { ...state };
      break;
    case 'toggle_audioState':
      state.audioState = action.data;
      return { ...state };
      break;
    case 'select_audio':
      state.recordState = action.data.recordState;
      return { ...state };
      break;
    case 'trigger_loader':
      state.loading = action.data;
      return { ...state };
      break;
    case 'upload_completed':
      state.uploadedFile = action.data;
      state.loading = false;
      return { ...state };
      break;
    default:
      break;
  }
};

export default RecordAudioStore;
