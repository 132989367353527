/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React from 'react';
import { Bar } from 'react-chartjs-2';
import './RoundedBarCorners';

const StackedBar = props => {
  // data.datasets[0].data = props.data;
  // data.labels = props.labels;

  // console.log('data ', data);

  const Graph = () => {
    const data = {
      labels: props.labels,

      datasets: [
        {
          data: props.data,
          backgroundColor: '#db9cc1',
        },
      ],
    };

    const options = {
      hover: { mode: null },
      barRoundness: 1,
      scales: {
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              padding: 10,
            },
            gridLines: {
              color: '#e9ebf1',
              drawTicks: false,
            },
          },
        ],
        xAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              padding: 10,
              fontSize: 12,
            },
            gridLines: {
              color: '#e9ebf1',
              drawTicks: false,
            },
          },
        ],
      },
      legend: false,
      animation: false,
      cornerRadius: 12,
      tooltips: {
        backgroundColor: '#ffffff',
        titleFontColor: '#231f20',
        titleFontStyle: 'font-sofia-pro-regular',
        bodyFontColor: '#8597a0',
        bodyFontStyle: 'font-sofia-pro-light',
        titleFontSize: 14,

        callbacks: {
          title: function (tooltipItems, data) {
            return tooltipItems[0]['value'];
          },
          label: function (tooltipItems, data) {
            return data.datasets[tooltipItems['datasetIndex']]['label'];
          },
        },
      },
    };

    return (
      <div>
        <Bar data={data} options={options} height={262} width={686} />
      </div>
    );
  };

  return (
    <div>
      {/* <Bar data={data} options={options} height={262} width={686} /> */}
      {Graph()}
    </div>
  );
};

export default StackedBar;
