/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import IconsInfoGrey from '../../assets/images/common/icons-info-grey.png';
import { ReactComponent as Logo } from '../../assets/images/dashboard/icons/logo-colour.svg';
import { ReactComponent as SendThanks } from '../../assets/images/dashboard/icons/icons-main-navigation-sent-inactive.svg';
import { ReactComponent as Redeemthanks } from '../../assets/images/dashboard/icons/icons-main-navigation-redeem-inactive.svg';
import { ReactComponent as CaretDown } from '../../assets/images/dashboard/icons/icons-caret-down.svg';
import { ReactComponent as RedeemthanksActive } from '../../assets/images/dashboard/icons/icons-main-navigation-redeem-active.svg';
import { ReactComponent as SendThanksActive } from '../../assets/images/dashboard/icons/icons-main-navigation-sent-active.svg';
import { ReactComponent as Contactthanks } from '../../assets/images/common/np_users_inactive.svg';
import { ReactComponent as ContactThanksActive } from '../../assets/images/common/np_users.svg';
import { ReactComponent as MerchantTransactionsInactive } from '../../assets/images/dashboard/icons/transactions-inactive.svg';
import { ReactComponent as MerchantTransactionsActive } from '../../assets/images/dashboard/icons/merchant-transactions-active.svg';
import { ReactComponent as MerchantPayoutInactive } from '../../assets/images/dashboard/icons/merchant-payouts-inactive.svg';
import { ReactComponent as MerchantStoreInactive } from '../../assets/images/dashboard/icons/merchant-store-inactive.svg';
import { ReactComponent as MerchantDownCaret } from '../../assets/images/dashboard/icons/icons-caret-filled.svg';
import { ReactComponent as MerchantStoreActive } from '../../assets/images/dashboard/icons/icons-merchant-store-active.svg';
import { ReactComponent as MerchantPayoutActive } from '../../assets/images/dashboard/icons/icons-merchant-payouts-active.svg';
import { ReactComponent as MapView } from '../../assets/images/dashboard/icons/icons-map-map-view.svg';
import { ReactComponent as MapViewUnselected } from '../../assets/images/dashboard/icons/icons-map-viewunselected.svg';
import HomeIconInactive from '../../assets/images/dashboard/image/icons-main-navigation-home-inactive.png';
import HomeIcon from '../../assets/images/dashboard/image/icons-main-navigation-home-active.png';
import { ReactComponent as ProfilePic } from '../../assets/images/common/user-profile.svg';
import { ReactComponent as AccountSwitcher } from '../../assets/images/common/icons-account-switcher.svg';
import MenuIcon from '../../assets/images/common/menu.png';
import CloseIcon from '../../assets/images/common/close.png';
import { ErrorImage } from '../../assets/images';
import useWindowDimensions from '../../common/Responsive/WindowDimensions';
import '../../components/individual/dashboard/DashBoard.scss';
import './Sidebar.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountDetailsServices from '../../services/AccountDetailsServices';
import merchantService from '../../services/MerchantService';
import { ReactComponent as ProfileIcon } from '../../assets/images/common/user-icon.svg';
import { ReactComponent as ShopIcon } from '../../assets/images/common/shop-icon.svg';
import { ReactComponent as BuildingIcon } from '../../assets/images/common/building-icon.svg';
import { AddMyOrganisationInfo, AddMyStoreInfo } from '../../utils/utils';
import TooltipInfo from '../Notification/TooltipInfo';
import TooltipProfile from '../Notification/TooltipProfile';
import PopoverProfile from '../component/Sidebar/PopoverProfile';
import ItemSideBar from '../component/Sidebar/ItemSideBar';
import BurgerLogo from '../component/Sidebar/BurgerLogo';

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

const SideBar = props => {
  const { width } = useWindowDimensions();

  const [userData, setUserData] = useState([]);

  const [checkUserHaveMerchantAccount, setCheckUserHaveMerchantAccount] = useState(false);

  const [checkUserHaveAdminAccount, setUserHaveAdminAccount] = useState(false);

  const [checkUserHaveEmployeeAccount, setUserHaveEmployeeAccount] = useState(false);

  const [requirementStatus, setRequirementStatus] = useState();

  const [checkPersonalInfoAvailable, setCheckPersonalInfoAvailable] = useState(false);

  const [merchantData, setMerchantData] = useState();

  const [showMerchantCompletionError, setShowMerchantCompletionError] = useState(false);

  const [checkOrganisationAccount, setCheckOrganisationAccount] = useState(false);

  const [companyDetails, setCompanyDetails] = useState();

  const [isOpen, setIsopen] = useState(false);
  const [isOpenMobile, setIsopenMobile] = useState(false);

  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
  };

  const ToggleSidebarMobile = () => {
    isOpenMobile === true ? setIsopenMobile(false) : setIsopenMobile(true);
  };
  let userData1 = JSON.parse(localStorage.getItem('userData'));

  let logoFileImage = localStorage.getItem('profilePic');

  if (userData?.profilePhotoPath !== null) {
    logoFileImage = undefined;
  }

  let data = { fullname: userData1?.fullName, type: 'FullName' };

  const Name = data.fullname;

  const currentPath = window.location.pathname;

  const history = useHistory();

  useEffect(() => {
    if (userData1 === undefined || userData1 === null) {
      AccountData();
    } else {
      setUserData(userData1);
      userData1.roles.forEach((data, index) => {
        if (data === 'CompanyAdmin') {
          setCheckUserHaveMerchantAccount(true);
          getMerchantDetails();
          getCompanyDetails();
        } else if (data === 'CompanyAdmin') {
          setUserHaveAdminAccount(true);
          getCompanyDetails();
        } else if (data === 'CompanyUser') {
          setUserHaveEmployeeAccount(true);
        }
      });
    }
    // AccountData()
    // getCurrentUser();
  }, []);

  const AccountData = () => {
    AccountDetailsServices.GetACCOuntDetails()
      .then(res => {
        if (res) {
          let data = res['data'].roles;
          setUserData(res['data']);
          data.forEach((data, index) => {
            if (data === 'CompanyAdmin') {
              setCheckUserHaveMerchantAccount(true);
              getMerchantDetails();
            }
          });
        }
      })
      .catch(error => {
        setCheckUserHaveMerchantAccount(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        // loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const getMerchantDetails = () => {
    merchantService
      .getMerchantDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setRequirementStatus(data.requirementStatus);
          if (data.requirementStatus === 'Complete') {
            setShowMerchantCompletionError(false);
          } else if (data.requirementStatus === 'Payment' && checkPersonalInfoAvailable === false) {
            setShowMerchantCompletionError(true);
          } else if (data.requirementStatus === 'Terms') {
            setShowMerchantCompletionError(true);
          } else if (data.requirementStatus === 'Company') {
            setShowMerchantCompletionError(true);
          } else if (data.requirementStatus === 'Representative') {
            setShowMerchantCompletionError(true);
          }

          let representativeDataAvailableOrNot = data?.representative?.id !== null ? true : false;
          setCheckPersonalInfoAvailable(representativeDataAvailableOrNot);
          setMerchantData(data);
        }
      })
      .catch(error => {
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };

  const getCompanyDetails = () => {
    merchantService
      .getCompanyDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          const features = data.features.split(',');
          if (features.length > 1) {
            setCheckUserHaveMerchantAccount(true);
            setCheckOrganisationAccount(true);
          } else if (features[0] === 'Merchant') {
            setCheckUserHaveMerchantAccount(true);
          } else if (features[0] === 'Organisation') {
            setCheckOrganisationAccount(true);
            setCheckUserHaveMerchantAccount(false);
          } else if (features[0] === 'CompanyAdmin') {
            setUserHaveEmployeeAccount(true);
            setCheckUserHaveMerchantAccount(false);
          } else if (features[0] === 'CompanyUser') {
            setUserHaveEmployeeAccount(true);
            setCheckUserHaveMerchantAccount(false);
          } else {
            setCheckUserHaveMerchantAccount(false);
            setCheckOrganisationAccount(false);
          }
          setCompanyDetails(data);
        }
      })
      .catch(error => {
        setCheckUserHaveMerchantAccount(false);
        setCheckOrganisationAccount(false);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              toast.error(msg.message);
            });
          }
        }
      });
  };
  const navigateToEmployee = () => {
    history.push('/employee/dashboard');
  };
  const navigateToMerchant = () => {
    if (requirementStatus === 'Complete') {
      history.push('/merchant/dashboard');
      setShowMerchantCompletionError(false);
    } else if (requirementStatus === 'Payment' && checkPersonalInfoAvailable === false) {
      history.push({
        pathname: '/merchant/onboarding/personal',
        state: { errors: merchantData?.errors },
      });
      setShowMerchantCompletionError(true);
    } else if (requirementStatus === 'Representative' && checkPersonalInfoAvailable === false) {
      history.push({
        pathname: '/merchant/onboarding/personal',
        state: { errors: merchantData?.errors },
      });
      setShowMerchantCompletionError(true);
    } else if (requirementStatus === 'Payment' && checkPersonalInfoAvailable === true) {
      history.push({
        pathname: '/merchant/onboarding/payment',
        state: { errors: merchantData?.errors },
      });
      setShowMerchantCompletionError(true);
    } else if (requirementStatus === 'Terms') {
      history.push({
        pathname: '/merchant/onboarding/business',
        state: { errors: merchantData?.errors },
      });
      setShowMerchantCompletionError(true);
    } else if (requirementStatus === 'Representative') {
      history.push({
        pathname: '/merchant/onboarding/personal',
        state: { errors: merchantData?.errors },
      });
      setShowMerchantCompletionError(true);
    } else if (requirementStatus === 'Company') {
      history.push({
        pathname: '/merchant/onboarding/business',
        state: { errors: merchantData?.errors },
      });
      setShowMerchantCompletionError(true);
    }
  };

  const navigateToIndividual = () => {
    history.push('/dashboard');
  };

  const navigateToOrganisation = () => {
    userData?.roles.forEach((data, index) => {
      if (data === 'CompanyAdmin') {
        history.push('/organisation/dashboard');
      } else if (data === 'CompanyUser') {
        history.push('/employee/dashboard');
      }
    });
  };

  // Merchant Popup button
  function MerchantPopupButton() {
    const [clicked, setClicked] = useState(false);

    function Button() {
      return (
        <button
          className={`px-2 d-flex flex-row align-items-center border-0 dashboard-button profile__popup-position dashboard-rectangle-bottom${
            clicked ? '_clicked' : ''
          }`}
        >
          {companyDetails === undefined ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : companyDetails['logoPath'] === null ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : (
            <img src={companyDetails['logoPath'] + '?h=80&w=80'} className="rounded-circle dashboard_oval" />
          )}

          <span className="pl-1 font-12 font-sofia-pro-regular">Profile</span>
          <CaretDown className="dashboard-icons__caretdown" />
        </button>
      );
    }

    return (
      <div>
        <TooltipProfile
          clicked={clicked}
          setClicked={setClicked}
          id="AddMyStoreInfoCorporate"
          placement="top"
          body={<PopoverProfile toAccount={'/merchant/profile'} />}
        >
          <Button />
        </TooltipProfile>
      </div>
    );
  }

  function PopupButton() {
    const [clicked, setClicked] = useState(false);
    useEffect(() => {
      if (sessionStorage.getItem('greeting') === 'true') {
        setClicked(false);
      }
    });
    function Button() {
      return (
        <button
          className={`px-2 d-flex flex-row align-items-center border-0 dashboard-button dashboard-rectangle-bottom${
            clicked ? '_clicked' : ''
          } ${
            checkUserHaveMerchantAccount === false &&
            checkOrganisationAccount === false &&
            checkUserHaveEmployeeAccount === false
              ? '   profile__popup-positionnewaccount'
              : ''
          }`}
        >
          {logoFileImage === undefined && userData?.profilePhotoPath !== null ? (
            <img className="rounded-circle dashboard_oval" src={userData?.profilePhotoPath + '?h=80&w=80'} />
          ) : logoFileImage === undefined && userData?.profilePhotoPath === null ? (
            <ProfilePic height="80px" width="80px" />
          ) : logoFileImage !== undefined ? (
            <img src={logoFileImage} height="80px" width="80px" className="rounded-circle dashboard_oval" />
          ) : null}
          {/* {userData['profilePhotoPath'] === null ? (
            <ProfilePic className="rounded-circle dashboard_oval" />
          ) : (
            <img src={userData['profilePhotoPath'] + '?h=80&w=80'} className="rounded-circle dashboard_oval" />
          )} */}

          <span className="pl-1 font-12 font-sofia-pro-regular">
            <span>Profile</span>
          </span>
          <CaretDown className="dashboard-icons__caretdown" />
        </button>
      );
    }

    return (
      <div>
        <TooltipProfile
          clicked={clicked}
          setClicked={setClicked}
          id="AddMyStoreInfoCorporate"
          placement="top"
          body={<PopoverProfile toAccount={'/profile'} toHistory={'/transactionhistory'} />}
        >
          <Button />
        </TooltipProfile>
      </div>
    );
  }

  function SideBarMobile() {
    useEffect(() => {
      if (sessionStorage.getItem('greeting') === 'true') {
        setIsopenMobile(false);
      }
    });

    if (props.menu === 'Merchant') {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebarMobile} />
          <div className={`sidebar ${isOpenMobile == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebarMobile}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">
                <MerchantMenu />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebarMobile} />
          <div className={`sidebar ${isOpenMobile === true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebarMobile}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">
                <div className="d-flex dashboard-sidebar_hieght  flex-column justify-content-between">
                  <div className="mb-5">
                    <Home />
                    <div className="my-4">
                      <SendThanksMenu />
                    </div>
                    <div className="my-4">
                      <RedeemThanksMenu />
                    </div>
                    {/* <ContactThanksMenu /> */}
                    {/* {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="my-4">
                          <AddMyStore />
                        </div>
                      )} */}
                    {/* {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="my-4">
                          <AddMyOrganisation />
                        </div>
                      )} */}
                  </div>
                  <div>
                    {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="mb-1">
                          <AddMyOrganisation />
                        </div>
                      )}
                    {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="">
                          <AddMyStore />
                        </div>
                      )}
                    <Accounts />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // return (
    //   <div>
    //     <div className="form-inline ml-auto">
    //       <div className="btn btn-primary" onClick={ToggleSidebar} >
    //         <i className="fa fa-bars"></i>
    //       </div>
    //     </div>
    //     <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
    //       <div className="sd-header">
    //         <Logo
    //           className={
    //             width > 700
    //               ? " dashboard-logo_mobile  mx-3"
    //               : " dashboard-logo_mobile  mx-5"
    //           }
    //         />
    //         <div className="btn btn-primary" onClick={ToggleSidebar}><i className="fa fa-times"></i></div>
    //       </div>
    //       <div className="sd-body">

    //       </div>
    //     </div>
    //     <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
    //   </div>
    // )
  }
  function Home() {
    if (props.menu === 'Home') {
      return (
        <div>
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <div className={width > 700 ? 'w-100 d-flex justify-content-between' : 'd-flex justify-content-between'}>
              <div className="d-flex justify-content-center align-content-start">
                <img src={HomeIcon} alt="Home" className="dashboard-icons dashboard-navigation__home__active" />
                <span className="pl-2 dashboard-home font-sofia-pro-semi-bold">Home</span>
              </div>
              <div className="dashboard-menu_selected" />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className=" w-100">
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <img src={HomeIconInactive} alt="Home" />
            <span className="pl-2 dashboard-text__inActive  font-sofia-pro-regular">Home</span>
          </Link>
        </div>
      );
    }
  }

  function SendThanksMenu() {
    if (props.menu === 'Sendthanks') {
      return (
        <div>
          <Link to="/selectitem" style={{ textDecoration: 'none' }}>
            <div className={width > 700 ? 'w-100 d-flex justify-content-between' : 'd-flex justify-content-between'}>
              <div className="d-flex justify-content-center align-content-start">
                <SendThanksActive />
                <span className="pl-2 dashboard-home  font-sofia-pro-semi-bold">Send</span>
              </div>
              <div className="dashboard-menu_selected  " />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className=" w-100">
          <Link to="/selectitem" style={{ textDecoration: 'none' }}>
            <SendThanks className="dashboard-icons dashboard-menu__sendthanksinactive" />
            <span className="pl-2 dashboard-text__inActive  font-sofia-pro-regular">Send</span>
          </Link>
        </div>
      );
    }
  }
  function RedeemThanksMenu() {
    if (props.menu === 'Redeemthanks') {
      return (
        <div>
          <Link to="/redeemthanks" style={{ textDecoration: 'none' }}>
            <div className={width > 700 ? 'w-100 d-flex justify-content-between' : 'd-flex justify-content-between'}>
              <div className="d-flex justify-content-center align-content-start">
                <RedeemthanksActive />
                <span className="pl-2 dashboard-home font-sofia-pro-semi-bold">Redeem </span>
              </div>
              <div className="dashboard-menu_selected  " />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="w-100">
          <Link to="/redeemthanks" style={{ textDecoration: 'none' }}>
            <Redeemthanks />
            <span className="pl-2 dashboard-text__inActive font-sofia-pro-regular">Redeem</span>
          </Link>
        </div>
      );
    }
  }

  function ContactThanksMenu() {
    console.log('props.menu:', props.menu);
    if (props.menu === 'Contacts') {
      return (
        <div>
          <Link to="/contactthanks" style={{ textDecoration: 'none' }}>
            <div className={width > 700 ? 'w-100 d-flex justify-content-between' : 'd-flex justify-content-between'}>
              <div className="d-flex justify-content-center align-content-start">
                <ContactThanksActive />
                <span className="pl-2 dashboard-home font-sofia-pro-semi-bold">Contacts </span>
              </div>
              <div className="dashboard-menu_selected  " />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className="w-100">
          <Link to="/contactthanks" style={{ textDecoration: 'none' }}>
            <Contactthanks />
            <span className="pl-2 dashboard-text__inActive font-sofia-pro-regular">Contacts</span>
          </Link>
        </div>
      );
    }
  }

  function Map() {
    if (props.menu === 'Map') {
      return (
        <div>
          <Link to="/appreci-locations" style={{ textDecoration: 'none' }}>
            <div
              className={width > 700 ? 'w-100 d-flex justify-content-between' : 'w-150 d-flex justify-content-between'}
            >
              <div className="d-flex justify-content-center align-content-center">
                <MapView />
                <span className="pl-2 dashboard-home font-sofia-pro-semi-bold">Appreci Locations</span>
              </div>
              <div className="dashboard-menu_selected " />
            </div>
          </Link>
        </div>
      );
    } else {
      return (
        <div className=" w-100">
          <Link to="/appreci-locations" style={{ textDecoration: 'none' }}>
            <MapViewUnselected />
            <span className="pl-2 dashboard-text__inActive  font-sofia-pro-regular">Appreci Locations</span>
          </Link>
        </div>
      );
    }
  }

  function AddMyStore() {
    return (
      // <div className="w-100 d-flex justify-content-lg-between align-items-start position-relative">
      //   <div>
      //     <MerchantStoreInactive />
      //     <Link to="/merchant/plans" style={{ textDecoration: 'none' }}>
      //       <span className="pl-2 dashboard-text__inActive  font-sofia-pro-regular">Add My Store</span>
      //     </Link>
      //   </div>
      //   <TooltipInfo body={<AddMyStoreInfo />} id="AddMyStoreInfo" placement="right">
      //     <img src={IconsInfoGrey} height="20px" width="20px" />
      //   </TooltipInfo>
      // </div>
      <Link to="/merchant/plans" style={{ textDecoration: 'none' }}>
        <div className="sidebar-card_store w-100 dashboard-button">
          <span className="dashboard__sendthanks font-sofia-pro-medium">Add my products / store</span>
        </div>
      </Link>
    );
  }

  function AddMyOrganisation() {
    return (
      <>
        {/* <div className="w-100 d-flex justify-content-lg-between align-items-start">
          <div>
            <MerchantStoreInactive />
            <Link to="/organisation/plans" style={{ textDecoration: 'none' }}>
              <span className="pl-2 dashboard-text__inActive  font-sofia-pro-regular">Add My Organisation</span>
            </Link>
          </div>
          <TooltipInfo body={<AddMyOrganisationInfo />} id="AddMyStoreInfo" placement="right">
            <img src={IconsInfoGrey} height="20px" width="20px" />
          </TooltipInfo>
        </div> */}
        <Link to="/organisation/plans" style={{ textDecoration: 'none' }}>
          <div className="sidebar-card_organisation w-100 dashboard-button">
            <span className="dashboard__sendthanks font-sofia-pro-medium">Add my organisation</span>
          </div>
        </Link>
      </>
    );
  }

  function MerchantMenu() {
    if (props.menu === 'Merchant') {
      return (
        <div className="my-4">
          <div className="w-100 d-flex justify-content-between">
            <div className="w-100 position-relative dashboard-sidebar_hieght d-flex flex-column justify-content-between">
              <div>
                <ItemSideBar
                  to={'/merchant/dashboard'}
                  logic={currentPath === '/merchant/dashboard'}
                  iconActive={HomeIcon}
                  icon={HomeIconInactive}
                  name={'Home'}
                />

                <div className="mt-4">
                  <ItemSideBar
                    to={'/merchant/transactions'}
                    logic={currentPath === '/merchant/transactions'}
                    iconActive={
                      <MerchantTransactionsActive className="dashboard-icons dashboard-menu__sendthanksinactive" />
                    }
                    icon={
                      <MerchantTransactionsInactive className="dashboard-icons dashboard-menu__sendthanksinactive" />
                    }
                    name={'Transactions'}
                  />
                </div>

                <div className="mt-4">
                  <ItemSideBar
                    to={'/merchant/payout'}
                    logic={currentPath === '/merchant/payout'}
                    iconActive={<MerchantPayoutActive className="dashboard-icons dashboard-menu__sendthanksinactive" />}
                    icon={<MerchantPayoutInactive className="dashboard-icons dashboard-menu__sendthanksinactive" />}
                    name={'Payouts'}
                  />
                </div>

                <div className=" w-100">
                  {/* <Link to="/recipientInformation" style={{ textDecoration: "none" }}> */}
                  <div className="d-flex justify-content-between position-relative cursor-pointer">
                    <div className="w-100 d-flex">{storeMenu()}</div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
              <div className="my-1">
                <div className="mb-3">
                  <span className="p-2 font-14 color-gunmetal font-sofia-pro-light">Accounts</span>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <button
                        className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                        onClick={navigateToIndividual}
                      >
                        <ProfileIcon style={{ fill: '#b5c3cc' }} className="rounded-circle dashboard_oval" />
                        <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                          <span className="font-12 color-cloudy-blue  font-sofia-pro-light">Personal</span>
                        </div>
                      </button>
                      <button
                        className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                        // onClick={navigateToMerchant}
                      >
                        <ShopIcon style={{ fill: '#e20077' }} className="dashboard_icon" />
                        <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                          <div className="d-flex">
                            <span className="font-12 font-sofia-pro-light" style={{ color: '#e20077' }}>
                              My store
                            </span>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <MerchantPopupButton />
              </div>
            </div>
            {/* <div className="dashboard-menu_selected  " /> */}
          </div>
        </div>
      );
    }
  }

  function storeMenu() {
    if (currentPath === '/merchant/employees' || currentPath === '/merchant/locations') {
      return (
        <div className="mt-4 w-100 d-flex">
          <MerchantStoreActive className="dashboard-icons dashboard-menu__sendthanksinactive merchant__dashboard-store-icon" />
          <Accordion className="w-100" defaultActiveKey="0">
            <Accordion.Toggle
              className="p-0 m-0 pl-2 w-100 font-sofia-pro-regular merchant__store outline-none color-primary   border-0 bg-transparent"
              variant="link"
              eventKey="0"
            >
              <div className="my-2 w-100 d-flex align-items-center">
                <span>Store</span>

                <div className="pl-5">
                  <MerchantDownCaret className="merchant__store-dropdown-caret" />
                </div>
              </div>
            </Accordion.Toggle>

            <Accordion.Collapse eventKey="0">
              <div className="w-100 ">
                <div className="mt-2 w-100 d-flex justify-content-between  align-items-center">
                  <ItemSideBar
                    to={'/merchant/locations'}
                    logic={currentPath === '/merchant/locations'}
                    name={'Locations'}
                  />
                </div>

                <div className="mt-2 w-100 d-flex justify-content-between  align-items-center">
                  <ItemSideBar
                    to={'/merchant/employees'}
                    logic={currentPath === '/merchant/employees'}
                    name={'Employees'}
                  />
                </div>
              </div>
            </Accordion.Collapse>
          </Accordion>
        </div>
      );
    } else {
      return (
        <div className="mt-4 w-100 d-flex">
          <MerchantStoreInactive className="dashboard-icons dashboard-menu__sendthanksinactive merchant__dashboard-store-icon" />
          <Accordion>
            {/* <Link className="text-decoration-none" to="/merchant/locations"> */}
            <Accordion.Toggle
              className="p-0 m-0 pl-2 w-100 font-sofia-pro-regular merchant__store outline-none
          text-decoration-none dashboard-text__inActive border-0 bg-transparent"
              eventKey="0"
            >
              <div className="my-2 w-100 d-flex align-items-center">
                <span>Store</span>
                <div className="pl-5">
                  <MerchantDownCaret />
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="mt-2 d-flex flex-column">
                <Link className="mt-1 text-decoration-none" to="/merchant/locations">
                  <span className="pl-2 pt-2 dashboard-text__inActive font-sofia-pro-regular">Locations</span>
                </Link>
                <Link className="mt-2 text-decoration-none" to="/merchant/employees">
                  <span className="pl-2 pt-2 dashboard-text__inActive font-sofia-pro-regular">Employees</span>
                </Link>
              </div>
            </Accordion.Collapse>
            {/* </Link> */}
          </Accordion>
        </div>
      );
    }
  }

  function Accounts() {
    return (
      <div>
        <div className="mb-3">
          <div className="my-1 mt-sm-2">
            {(checkUserHaveMerchantAccount === true ||
              checkOrganisationAccount === true ||
              checkUserHaveEmployeeAccount === true) && (
              <span className="p-2 font-14 color-gunmetal font-sofia-pro-light">Accounts</span>
            )}
            {checkUserHaveMerchantAccount === true &&
              checkOrganisationAccount === false &&
              checkUserHaveEmployeeAccount === false && (
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <button
                      className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                      // onClick={navigateToIndividual}
                    >
                      <ProfileIcon style={{ fill: '#e20077' }} className="rounded-circle dashboard_oval" />
                      <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                        <span className="font-12 font-sofia-pro-light" style={{ color: '#e20077' }}>
                          Personal
                        </span>
                      </div>
                    </button>
                    <button
                      className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                      onClick={navigateToMerchant}
                    >
                      <ShopIcon style={{ fill: '#b5c3cc' }} className="dashboard_icon" />
                      <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                        <div className="d-flex">
                          <span className="font-12 color-cloudy-blue font-sofia-pro-light">My store</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              )}
            {checkUserHaveMerchantAccount === false &&
              checkOrganisationAccount === false &&
              checkUserHaveEmployeeAccount === true && (
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <button
                      className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                      // onClick={navigateToIndividual}
                    >
                      <ProfileIcon style={{ fill: '#e20077' }} className="rounded-circle dashboard_oval" />
                      <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                        <span className="font-12 font-sofia-pro-light" style={{ color: '#e20077' }}>
                          Personal
                        </span>
                      </div>
                    </button>
                    <button
                      className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                      onClick={navigateToEmployee}
                    >
                      <BuildingIcon style={{ fill: '#b5c3cc' }} className="dashboard_icon" />
                      <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                        <div className="d-flex">
                          <span className="font-12 color-cloudy-blue font-sofia-pro-light">Employee</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              )}
          </div>

          {checkUserHaveMerchantAccount === false &&
            checkOrganisationAccount === true &&
            checkUserHaveEmployeeAccount === false && (
              <div className="my-1">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <button
                      className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                      // onClick={navigateToIndividual}
                    >
                      <ProfileIcon style={{ fill: '#e20077' }} className="rounded-circle dashboard_oval" />
                      <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                        <span className="font-12 font-sofia-pro-light" style={{ color: '#e20077' }}>
                          Personal
                        </span>
                      </div>
                    </button>
                    <button
                      className="dashboard-rectangle-bottom d-flex flex-row align-items-center border-0 dashboard-button"
                      onClick={navigateToOrganisation}
                    >
                      <BuildingIcon style={{ fill: '#b5c3cc' }} className="dashboard_icon" />
                      <div className="pl-2 d-flex flex-column justify-content-start align-items-start  font-12 font-sofia-pro-regular">
                        <div className="d-flex">
                          <span className="font-12 color-cloudy-blue font-sofia-pro-light">Corporate</span>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            )}
        </div>
        <PopupButton />
      </div>
    );
  }

  function LoadMenu() {
    if (props.menu === 'Merchant') {
      return (
        <div className="pl-3 ">
          <div className="m-0 p-0 dashboard-menu my-4">
            <MerchantMenu />
          </div>
        </div>
      );
    } else {
      return (
        <div className="topnav navbar-collapse dashboard-menubar_mobile">
          <div className="m-0 p-0 dashboard-menu my-4">
            <div className="dashboard-sidebar_hieght d-flex flex-column justify-content-between">
              <div>
                <Home />
                <div className="my-4">
                  <SendThanksMenu />
                </div>
                <div className="my-4">
                  <RedeemThanksMenu />
                </div>
                {/* <ContactThanksMenu /> */}
                {/* commented before */}
                {/* {checkUserHaveMerchantAccount === false &&
                  checkOrganisationAccount === false &&
                  checkUserHaveEmployeeAccount === false && (
                    <div className="my-4">
                      <AddMyStore />
                    </div>
                  )} */}
                {/* commented after */}
                {/* {checkUserHaveMerchantAccount === false &&
                  checkOrganisationAccount === false &&
                  checkUserHaveEmployeeAccount === false && (
                    <div className="my-4">
                      <AddMyOrganisation />
                    </div>
                  )} */}
              </div>
              <div>
                {checkUserHaveMerchantAccount === false &&
                  checkOrganisationAccount === false &&
                  checkUserHaveEmployeeAccount === false && (
                    <div className="mb-2">
                      <AddMyOrganisation />
                    </div>
                  )}
                {checkUserHaveMerchantAccount === false &&
                  checkOrganisationAccount === false &&
                  checkUserHaveEmployeeAccount === false && (
                    <div className="">
                      <AddMyStore />
                    </div>
                  )}
                <Accounts />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  function SidebarForTablet() {
    useEffect(() => {
      if (sessionStorage.getItem('greeting') === 'true') {
        setIsopen(false);
      }
    });

    if (props.menu === 'Merchant') {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebar} />
          <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebar}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="sd-body">
              <div className="m-0 p-0 dashboard-menu my-4">
                <MerchantMenu />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <BurgerLogo toggle={ToggleSidebar} />
          <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
            <div className="sd-header">
              <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
              <div className="mx-3" onClick={ToggleSidebar}>
                <img src={CloseIcon} height="30px" width="30px" />
              </div>
            </div>
            <div className="topnav navbar-collapse dashboard-menubar_mobile pl-3 ">
              <div className="m-0 p-0 dashboard-menu my-4">
                <div className="dashboard-sidebar_hieght d-flex flex-column justify-content-between">
                  <div>
                    <Home />
                    <div className="my-4">
                      <SendThanksMenu />
                    </div>
                    <div className="my-4">
                      <RedeemThanksMenu />
                    </div>
                    {/* <ContactThanksMenu /> */}
                    {/* {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="my-4">
                          <AddMyStore />
                        </div>
                      )} */}
                    {/* {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="my-4">
                          <AddMyOrganisation />
                        </div>
                      )} */}
                  </div>
                  <div>
                    {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="mb-2">
                          <AddMyOrganisation />
                        </div>
                      )}
                    {checkUserHaveMerchantAccount === false &&
                      checkOrganisationAccount === false &&
                      checkUserHaveEmployeeAccount === false && (
                        <div className="">
                          <AddMyStore />
                        </div>
                      )}
                    <Accounts />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    // return (
    //   <div>
    //     <div className="form-inline ml-auto">
    //       <div className="btn btn-primary" onClick={ToggleSidebar} >
    //         <i className="fa fa-bars"></i>
    //       </div>
    //     </div>
    //     <div className={`sidebar ${isOpen == true ? 'active' : ''}`}>
    //       <div className="sd-header">
    //         <Logo
    //           className={
    //             width > 700
    //               ? " dashboard-logo_mobile  mx-3"
    //               : " dashboard-logo_mobile  mx-5"
    //           }
    //         />
    //         <div className="btn btn-primary" onClick={ToggleSidebar}><i className="fa fa-times"></i></div>
    //       </div>
    //       <div className="sd-body">

    //       </div>
    //     </div>
    //     <div className={`sidebar-overlay ${isOpen == true ? 'active' : ''}`} onClick={ToggleSidebar}></div>
    //   </div>
    // )
  }

  return width >= 700 && width <= 991 ? (
    <div>
      <SidebarForTablet />
    </div>
  ) : width < 700 ? (
    <div>
      <SideBarMobile />
    </div>
  ) : (
    <div className="px-2 d-flex justify-content-center align-items-center dashboar-sidebar_fixed dashboard-sidebar">
      <Logo className={width > 700 ? ' dashboard-logo_mobile  mx-3' : ' dashboard-logo_mobile  mx-5'} />
      <LoadMenu />
    </div>
  );
};
export default SideBar;
