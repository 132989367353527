/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import OrganisationService from '../../../../../services/OrganisationService';
import Toast from '../../../../../services/ToasterService';
import './AdminAccountDetails.scss'


const initialValues = {
  name: '',
};

const validate = values => {
  let errors = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 25) {
    errors.name = 'Characters length should not exceed 25';
  }

  return errors;
};

function OrganisationAdminProfileAccessKeyDetails(props) {

  const getAccessKeyHandle = () => {
    OrganisationService.getAccessKey()
      .then(response => {
        if (response) {
          formik.setFieldValue('name', response?.data.accessKey !== undefined ? response?.data.accessKey : null);
          console.log(response?.data.accessKey);
        }
      })
      .catch(error => {
        console.log('error global response message ', error);
        Toast(error, 'error');
      });
  };
  useEffect(() => {
    getAccessKeyHandle();
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
  });

  const asteriskString = '*'.repeat(Math.max(0, formik.values.name.length - 4));

  const handleCopy = e => {
    // Set clipboard data to the complete access key from the hidden input
    e.clipboardData.setData('text/plain', formik.values.name);
    // Prevent the default copy behavior (which would copy the selected text)
    e.preventDefault();
    // Display a message or perform any other action to indicate successful copy
    Toast('Access Key copied to clipboard', 'success');
  };

  const handleCopyButtonClick = () => {
    // Create a temporary input element to copy the complete access key
    const tempInput = document.createElement('input');
    tempInput.value = formik.values.name;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    // Show a toast or any other indication that the access key has been copied
    Toast('Access Key copied to clipboard', 'success');
  };

  return (
    <div>
      <div className="p-0 m-0 p-4 card border-0 h-100">
        <div className="d-flex justify-content-start align-items-center">
          <span className="font-sofia-pro-semi-bold font-24 color-black-pearl">Company's Access Key</span>
        </div>

        <form className="mt-3" onSubmit={formik.handleSubmit}>
          <div className="p-0 b-0 mt-2 pb-1 form-group">
            <label className="font-sofia-pro-regular color-charcoal font-16">Access Key</label>
            <div className='pr-4 access-key-input shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle'>
              <input
                type="text"
                className="form-control border-0 "
                name="name"
                placeholder="Eg: Appreci"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={`${asteriskString}${formik.values.name.slice(-4)}`}
                onCopy={handleCopy}
                readOnly={true}
              />
              <button
                className="w-120 btn bg-primary-color color-white h-44 font-sofia-pro-semi-bold font-16 outline-none"
                type="button"
                onClick={handleCopyButtonClick}
              >
                Copy
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default OrganisationAdminProfileAccessKeyDetails;
