/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect, useRef } from 'react';
import './PlacesSearch.scss';

function SearchLocationInput(props) {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);

  let autoComplete;

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  };

  function handleScriptLoad(updateQuery, autoCompleteRef, props) {
    updateQuery(props.data, props);

    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
      componentRestrictions: { country: ['au'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });
    // autoComplete.setFields(["address_components", "formatted_address"]);
    // autoComplete.setComponentRestrictions({
    //     country: ["au"],
    //     types: ["address"]
    // });
    autoComplete.addListener('place_changed', () => handlePlaceSelect(updateQuery, props));
  }

  async function handlePlaceSelect(updateQuery, props) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    props.loadAddress(addressObject);
  }

  const handleOnChangeEvent = event => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (props?.data !== undefined) {
      setQuery(props?.data);
    }
  }, [props?.data]);

  useEffect(() => {
    // if (props?.setIsMapLoaded === true) {
    //     handleScriptLoad(setQuery, autoCompleteRef, props)
    // } else {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef, props)
    );
    // }
  }, []);

  return (
    <div className="google__places__search-location-input">
      <input
        className="form-control shadow-none h-60 font-sofia-pro-regular business-details__placeholder color-black-pearl border-light-periwinkle"
        ref={autoCompleteRef}
        onChange={handleOnChangeEvent}
        placeholder="Ex: 6, The Corso, Manly, Sydney, NSW, 2122"
        value={query}
        // onKeyPress={event => { setQuery(event.target.value); }}
      />
    </div>
  );
}

export default SearchLocationInput;
