/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import moment from 'moment';
import Moment from 'react-moment';
import { Modal } from 'react-bootstrap';
import * as ReactBootstrap from 'react-bootstrap';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import EditStoreLocationPage from './EditLocation';
import SideBar from '../../../../common/Sidebar/Sidebar';
import Header from '../../../../common/Header/Header';
import StackedBar from '../../../individual/dashboard/StackChart';
import './Details.scss';
import DashboardService from '../../../../services/DashboardService';
import Toast from '../../../../services/ToasterService';
import MerchantService from '../../../../services/MerchantService';
import TransactionService from '../../../../services/TransactionHistoryService';
import SendThanksService from '../../../../services/SendThanksService';
import { ReactComponent as Components1 } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-2.svg';
import { ReactComponent as Components2 } from '../../../../assets/images/dashboard/icons/components-illustrations-empty-state-1.svg';
import BackIcon from '../../../../assets/images/common/icons-back.png';
import { ReactComponent as Close } from '../../../../assets/images/dashboard/icons/icons-close-white.svg';

const MerchantTransactionDetailPage = props => {
  const id = props.match.params.id;

  const [productsList, setProductsList] = useState();

  const [grpahData, setGraphData] = useState([]);

  const [selectedGraphFilterValue, setSelectedGraphFilterValue] = useState();

  const [graphLabel, selectedGraphLabel] = useState([]);

  const [transactionList, setTransactionList] = useState();

  const [storeLocationData, setStoreLocationData] = useState();

  const [editModalShow, setEditModalShow] = useState(false);

  const handleEditModalClose = () => setEditModalShow(false);
  const handleEditModalShow = () => setEditModalShow(true);

  let markers = [];

  const [markerLocation, setMarkerLocation] = useState({
    lat: -33.86736585831316,
    lng: 151.2096683067536,
  });

  const [defaultCenter, setDefaultCenter] = useState({
    lat: -33.86736585831316,
    lng: 151.2096683067536,
  });

  const mapStyles = {
    height: '60vh',
    width: '100%',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTransactionDetails(id);
    getGraphData();
    loadTransactionList();
    getStoreData(id);
    return () => {};
  }, []);

  const getTransactionDetails = id => {
    MerchantService.getTransactionDetails(id)
      .then(response => {
        if (response) {
          // let responseData = response['data'];
          // // setPayoutDetails([1]);
          // let totalAmount = 0;
          // responseData?.data.forEach((payout, index) => {
          //     totalAmount += payout.amount;
          // })
          // setPayoutTotalAmount(totalAmount);
          // console.log('response ', response);
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }

        // loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const getStoreData = id => {
    MerchantService.getStore(id)
      .then(response => {
        if (response) {
          let data = response['data'];
          setMarkerLocation({
            lat: data.latitude,
            lng: data.longitude,
          });
          setDefaultCenter({
            lat: data.latitude,
            lng: data.longitude,
          });
          setStoreLocationData(data);
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const loadTransactionList = () => {
    TransactionService.getRedemptionsList(1)
      .then(response => {
        if (response) {
          let responseData = response['data'];

          SendThanksService.getListOfProducts('AU').then(response => {
            if (response) {
              let productData = response['data'];
              setProductsList(responseData);
              if (productData?.length > 0) {
                productData.forEach((product, index) => {
                  responseData?.data.forEach((transaction, index) => {
                    if (product?.price?.priceId === transaction?.priceId) {
                      transaction['type'] = product?.type;
                    }
                  });
                });
              }

              setTransactionList(responseData);
            }
          });
        }
      })
      .catch(error => {
        console.log('error ', error);
        let errorResponseFieldErrors = error.response['data'].fieldErrors;
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (error) {
          let errorResponse = error.response['data'];
          console.log('error response field errors ', errorResponse);
        }
        loopFieldErrors(errorResponseFieldErrors);

        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  };

  const getGraphData = e => {
    let type = e === undefined ? 'Daily' : e.target.value;
    setSelectedGraphFilterValue(type);
    DashboardService.getMerchantDashboardGraphData(type).then(response => {
      if (response) {
        let data = response['data'];
        setGraphData(
          data.map(val => {
            return val['number'];
          })
        );
        // setGraphData(data);

        let labels = [];
        if (type === 'Daily') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('dddd'));
          });
        } else if (type === 'Yearly') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('YYYY'));
          });
        } else if (type === 'Monthly') {
          data.forEach((value, index) => {
            labels.push(moment(value.date).format('MMMM'));
          });
        }

        selectedGraphLabel(labels);
      }
    });
  };

  const countArraysInAllItems = obj => {
    var size = 0,
      key;
    for (key in obj) {
      if (obj.hasOwnProperty(key)) size++;
    }
    return size;
  };

  const loopFieldErrors = data => {
    var allItemsLength = countArraysInAllItems(data);
    for (var i in data) {
      var itemSubArray = data[i];
      for (var j in itemSubArray) {
        Toast(itemSubArray[j], 'error');
      }
    }
  };

  const Transaction = () => {
    if (transactionList?.length === 0) {
      return (
        <div className="p-0 m-0 row mx-3 mb-3">
          <div className=" col-sm-12 col-md-12 col-lg-12 ">
            <div className="card border-0">
              <span className="dashboard__recenttransaction p-2 font-sofia-pro-medium mx-2">Recent transactions</span>
              <div className="dashboard-components1">
                <Components2 />
              </div>
              <div className="dashboard-components1 my-2">
                <span className="dashboard__trysendingyourfir font-sofia-pro-medium my-2">
                  Try sending your first transaction,
                  <span className="dashboard-text__style1">Try now</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-0 m-0 row mb-3">
          <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-12 ">
            <div className="card border-0 dashboard-transaction_card">
              <div className="table-responsive">
                <table className="table dashboard-table_headcolor border-bottom-pale-grey">
                  <thead style={{ backgroundColor: '#fff2fa ' }}>
                    <tr className="mx-2">
                      <td scope="col" className="font-sofia-pro-semi-bold">
                        Transaction ID
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Date
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold  ">
                        Location
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Product
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Price
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Quantity
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Total
                      </td>
                      <td scope="col" className="font-sofia-pro-semi-bold ">
                        Payout Id
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionList?.data?.map((transaction, index) => {
                      return (
                        <tr key={index}>
                          <td scope="row">
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              {transaction.transferId}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              <Moment format="DD/M/YYYY">{transaction.redeemedOn}</Moment>
                            </span>
                          </td>
                          <td>
                            <Link
                              className="color-primary text-decoration-none"
                              to={`/merchant/transaction/${transaction.locationId}`}
                            >
                              <span className="font-14 font-sofia-pro-regular color-primary">
                                {transaction.location}
                              </span>
                            </Link>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">{transaction.type}</span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              ${transaction.amount}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              {transaction.quantity}
                            </span>
                          </td>
                          <td>
                            <span className="font-14 font-sofia-pro-regular color-black-pearl">
                              ${transaction.total}
                            </span>
                          </td>
                          <td>
                            <Link
                              className="color-primary text-decoration-none"
                              to={`/merchant/payout/${transaction.payoutId}/${transaction.redeemedOn}/${transaction.payoutStripeId}`}
                            >
                              <span className="font-14 font-sofia-pro-regular color-primary">
                                {transaction.payoutStripeId !== null ? transaction.payoutStripeId : 'Not Available'}
                              </span>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  function ChartState() {
    if (grpahData?.length === 0) {
      return (
        <div>
          <div className="row mx-2 ">
            <span className="dashboard-redeemed__thankschar p-2 font-sofia-pro-medium ">Redeemed Chart</span>
          </div>
          <div className="dashboard-components1">
            <Components1 />
          </div>
          <div className="dashboard-components1">
            <span className="dashboard__uhohnochartsyet font-sofia-pro-medium">Uh-oh,no charts yet!</span>
            <div className="my-2">
              <span className="dashboard__trysendingthanks font-sofia-pro-light">
                Try sending thanks to your mate <span>&#128521;</span>
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="p-2">
          <div className="p-0 m-0 row justify-content-between align-items-center">
            <span className="p-0 m-0 col-lg-6 col-md-10 font-sofia-pro-medium font-20 ">Daily Payout</span>
            <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-4 d-flex justify-content-end ">
              <div className="my-1">
                <div className="my-1">
                  <select
                    className="form-control shadow-none border-cloudy-blue font-14 font-sofia-pro-light color-grey-blue"
                    value={selectedGraphFilterValue}
                    onChange={getGraphData}
                  >
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-chart">
            <StackedBar data={grpahData} labels={graphLabel} />
          </div>
        </div>
      );
    }
  }

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0  border-0 col-lg-2 col-sm-12 my-4 d-flex  flex-column ">
        <SideBar menu="Merchant" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 d-flex flex-column right_half ">
        <Header menu="Merchant" />
        <div className="m-3 d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <Link to="/merchant/transactions">
              <button className="btn border-light-periwinkle font-sofia-pro-regular font-14  color-gunmetal shadow-none bg-white">
                <img src={BackIcon} height="30px" />
                Back
              </button>
            </Link>
            <button
              className="btn p-2 w-90 border-primary-color font-sofia-pro-regular font-14  color-gunmetal shadow-none bg-lavender-blush-1"
              onClick={handleEditModalShow}
            >
              Edit
            </button>
          </div>
        </div>
        <div className="h-100vh">
          <ReactBootstrap.Tabs
            defaultActiveKey="cafeDetails"
            id="uncontrolled-tab-example"
            className="transaction__details-tabs"
          >
            <ReactBootstrap.Tab eventKey="cafeDetails" title=" Cafe Details">
              <div className="p-0 m-4 p-3 card border-0">
                <div className="p-0 m-0 row">
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-7">
                    <div className="py-2 d-flex flex-column">
                      <span className="font-sofia-pro-regular font-18 color-black-pearl">Cafe Name</span>
                      <span className="font-14 font-sofia-pro-light color-charcoal-grey">
                        {storeLocationData?.name}
                      </span>
                    </div>
                    <div className="py-2 d-flex flex-column">
                      <span className="font-sofia-pro-regular font-18 color-black-pearl">Address</span>
                      <span className="font-14 font-sofia-pro-light color-charcoal-grey">
                        {storeLocationData?.address}
                      </span>
                    </div>
                    <div className="py-2 d-flex flex-column">
                      <span className="font-sofia-pro-regular font-18 color-black-pearl">Redeem Thanks </span>
                      <span
                        className={
                          storeLocationData?.isActive === true
                            ? 'p-2 d-flex justify-content-center align-items-center w-90 text-center bg-cool-green-light border-radius-5 font-14 font-sofia-pro-light color-black-pearl'
                            : 'p-2 d-flex justify-content-center align-items-center w-90 text-center bg-platinum  border-radius-5 font-14 font-sofia-pro-light color-black-pearl'
                        }
                      >
                        <p
                          className={
                            storeLocationData?.isActive === true
                              ? 'p-0 m-0 mr-1 transaction__details-status-dot bg-cool-green'
                              : 'p-0 m-0 mr-1 transaction__details-status-dot bg-platinum'
                          }
                        ></p>
                        {storeLocationData?.isActive === true ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                    <div className="py-2 d-flex flex-column">
                      <span className="font-sofia-pro-regular font-18 color-black-pearl">Location on map</span>
                      <span
                        className={
                          storeLocationData?.isPublic === true
                            ? 'p-2 d-flex justify-content-center align-items-center w-90 text-center bg-cool-green-light border-radius-5 font-14 font-sofia-pro-light color-black-pearl'
                            : 'p-2 d-flex justify-content-center align-items-center w-90 text-center bg-platinum  border-radius-5 font-14 font-sofia-pro-light color-black-pearl'
                        }
                      >
                        <p
                          className={
                            storeLocationData?.isPublic === true
                              ? 'p-0 m-0 mr-1 transaction__details-status-dot bg-cool-green'
                              : 'p-0 m-0 mr-1 transaction__details-status-dot bg-platinum  '
                          }
                        ></p>
                        {storeLocationData?.isPublic === true ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                  <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-5">
                    <div style={{ width: '100%' }}>
                      <LoadScript googleMapsApiKey="AIzaSyAkhdWo1SX8xUPGcXbuzDHHT-gv_vUClKs">
                        <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={defaultCenter}>
                          <Marker position={markerLocation} />
                        </GoogleMap>
                      </LoadScript>
                    </div>
                  </div>
                </div>
                <Modal
                  className="pt-4 border-0 modal-background-shadow merchant-store__location__modal"
                  size="lg"
                  show={editModalShow}
                  onHide={handleEditModalClose}
                  animation={false}
                >
                  <Modal.Header className="p-0 m-0 px-3 pt-3 pb-2 border-0">
                    <div className="d-flex flex-column">
                      <span className="font-24 color-black-pearl font-sofia-pro-semi-bold">Edit existing location</span>
                      <span className="font-14 color-black-pearl font-sofia-pro-regular">
                        Please provide accurate information about your cafe
                      </span>
                    </div>
                    <button
                      type="button"
                      className="border-0 merchant-store-modal__close__icon"
                      onClick={handleEditModalClose}
                    >
                      <Close />
                    </button>
                  </Modal.Header>
                  <Modal.Body className="p-0 m-0 px-3 pb-2 border-0">
                    <EditStoreLocationPage
                      id={id}
                      closeModal={handleEditModalClose}
                      loadStoreData={getStoreData.bind(this, id)}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </ReactBootstrap.Tab>
            <ReactBootstrap.Tab eventKey="transactions" title="Transactions">
              <div className=" mx-3">
                <div className="row">
                  <div className="ml-3 bg-white col-sm-12 col-md-8 col-lg-6 my-4">
                    <div className="card border-0 ">
                      <ChartState />
                    </div>
                  </div>
                </div>
                <div>
                  <Transaction />
                </div>
              </div>
            </ReactBootstrap.Tab>
          </ReactBootstrap.Tabs>
        </div>
      </div>
    </div>
  );
};

export default MerchantTransactionDetailPage;
