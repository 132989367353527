import MenuIcon from '../../../assets/images/common/menu.png';
import { ReactComponent as Logo } from '../../../assets/images/dashboard/icons/logo-colour.svg';
import React from 'react';
import useWindowDimensions from '../../Responsive/WindowDimensions';

const BurgerLogo = ({ toggle }) => {
  const { width } = useWindowDimensions();

  return (
    <div className="form-inline ml-auto position-relative d-flex  ">
      <div className="mx-3 position-absolute" onClick={toggle}>
        <img src={MenuIcon} height="30px" width="30px" />
      </div>
      <Logo className={`${width < 500 ? "w-100" : 'w-200'}`} />
    </div>
  )
};

export default BurgerLogo;