/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';

// Payment cards
const addNewCard = (data, asCompany) => {
  if (asCompany) {
    return httpService.post('/PaymentMethods', { ...data, asCompany });
  }
  return httpService.post('/PaymentMethods', data);
};

const getListOfCards = (asCompany) => {
  if (asCompany) {
    return httpService.get('/PaymentMethods?asCompany=true');
  }
  return httpService.get('/PaymentMethods');
};

const updateCard = (id, data, asCompany) => {
  if (asCompany) {
    return httpService.put('/PaymentMethods/' + id, { ...data, asCompany });
  }
  return httpService.put('/PaymentMethods/' + id, data);
};

const deleteCard = (id, asCompany) => {
  if (asCompany) {
    return httpService.delete(`/PaymentMethods/${id}?asCompany=true`);
  }
  return httpService.delete('/PaymentMethods/' + id);
};

const deleteCards = (asCompany) => {
  if (asCompany) {
    return httpService.delete('/PaymentMethods?asCompany=true');
  }
  return httpService.delete('/PaymentMethods?asCompany=false');
    // return httpService.delete('/PaymentMethods', asCompany);
};

export default {
  addNewCard,
  getListOfCards,
  updateCard,
  deleteCard,
  deleteCards
};
