/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import OrganisationHeader from '../../../../organisationheader/organisationHeader';
import OrganisationSidebar from '../../../../organisationsidebar/organisationsidebar';
import SendThanksService from '../../../../../../services/SendThanksService';
import {
  orgSendThanksDetails,
  orgResetSendThanksDetails,
} from '../../../../../../store/reducers/organisation/sendThanks';
import sendThanksSchema, * as SendThanksSchema from '../../../../../../utils/Schema/sendThanksSchema';
import './AllocateSelectItem.scss';
import ItemCheckedImage from '../../../../../../assets/images/common/icons-check-filled-pink.png';
import { ReactComponent as Close } from '../../../../../../assets/images/dashboard/icons/icons-close-white.svg';
import IconsInfoGrey from '../../../../../../assets/images/common/icons-info-grey.png';
import IconsErrorInfo from '../../../../../../assets/images/error-icons/icons-error-info.png';

const OrganisationRedeemCreditSelectItemPage = props => {
  const [selectedProductOption, setSelectedProductOption] = useState(null);

  const [checkQuantityIsGreaterThanZero, setCheckQuantityIsGreaterThanZero] = useState(false);

  const [checkQuantityIsMultiplesOfTen, setCheckQuantityIsMultiplesOfTen] = useState(true);

  const [checkGiftCardQuantityValue, setCheckGiftCardQuantityValue] = useState(true);

  const [productsList, setProductsList] = useState([]);

  const [showWineListModal, setWineListModal] = useState(false);

  const [showBeerListModal, setBeerListModal] = useState(false);

  const [wineList, setWineList] = useState({});

  const [beerList, setBeerList] = useState({});

  const [productQuantityValue, setProductQuantityValue] = useState();

  const [nextPathValue, setNextPathValue] = useState();

  const [checkGiftCardIsClicked, setCheckGiftCardIsClicked] = useState(false);

  const [selectedGiftCardIndex, setselectedGiftCardIndex] = useState();

  const [selectedGiftcardValue, setSelectedGiftcardValue] = useState();

  const [checkCustomOptionIsClicked, setCheckCustomOptionIsClicked] = useState(false);

  let selectedProduct = props.getProductInformation;

  let giftCardValues = [
    {
      id: 0,
      amount: 20,
      checked: false,
    },
    {
      id: 1,
      amount: 50,
      checked: false,
    },
    {
      id: 2,
      amount: 100,
      checked: false,
    },
    {
      id: 3,
      amount: 'Custom',
      checked: false,
    },
  ];

  const showModal = () => {
    setWineListModal(true);
  };
  const hideModal = () => {
    setWineListModal(false);
  };

  const showBeerModal = () => {
    setBeerListModal(true);
  };
  const hideBeerModal = () => {
    setBeerListModal(false);
  };

  const history = useHistory();

  const selectedItem = index => {
    if (productsList[index].type !== 'GiftCard') {
      setCheckGiftCardIsClicked(false);
      setSelectedProductOption(index);
      let sendThanksPriceDetails = sendThanksSchema.sendThanksData;
      sendThanksPriceDetails.priceId = productsList[index].price.priceId;
      sendThanksPriceDetails.amount = productsList[index].price.price;
    } else if (productsList[index].type === 'GiftCard') {
      setCheckGiftCardIsClicked(true);
      setSelectedProductOption(index);
    }
  };

  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^[0-9]$/.test(keyValue)) {
      event.preventDefault();
    }

    var max_chars = 3;

    if (event.target.value.length > max_chars) {
      event.target.value = event.target.value.substr(0, max_chars);
    }
  };

  const handleOnChange = event => {
    toast.dismiss();
    setProductQuantityValue('');
    if (event.target.value !== '' && event.target.value > 0 && event.target.value <= 100) {
      setCheckQuantityIsGreaterThanZero(true);
      let sendThanksPriceDetails = sendThanksSchema.sendThanksData;
      sendThanksPriceDetails.quantity = event.target.value;
      setProductQuantityValue(sendThanksPriceDetails.quantity);
    } else {
      setCheckQuantityIsGreaterThanZero(false);
      toast.error('Please enter values between 1 to 100');
    }
  };

  const handleProceed = () => {
    toast.dismiss();

    if (productsList[selectedProductOption]?.type !== undefined) {
      sessionStorage.setItem('PaymentType', 'availableCredit');

      if (productsList[selectedProductOption].type !== 'GiftCard') {
        if (productQuantityValue <= 0 || productQuantityValue !== undefined) {
          SendThanksSchema.sendThanksData.priceId = productsList[selectedProductOption].price.priceId;
          SendThanksSchema.sendThanksData.amount = selectedGiftcardValue;
          SendThanksSchema.sendThanksData.amount =
            Number(SendThanksSchema.sendThanksData.amount) + Number(SendThanksSchema.sendThanksData.amount * 0.05);
          props.priceDetails(SendThanksSchema.sendThanksData);

          let priceDetails = {
            productType: productsList[selectedProductOption].type,
            priceId: productsList[selectedProductOption].price.priceId,
          };

          sessionStorage.setItem('price', JSON.stringify(productsList[selectedProductOption].price.price));
          sessionStorage.setItem('productDetails', JSON.stringify(priceDetails));
          sessionStorage.setItem('giftCardValue', JSON.stringify(SendThanksSchema.sendThanksData.amount));

          if (nextPathValue !== '' && nextPathValue !== undefined) {
            props.history.push({
              pathname: nextPathValue,
              state: {
                productType: productsList[selectedProductOption].type,
                sendThanksData: SendThanksSchema.sendThanksData,
                priceId: productsList[selectedProductOption].price.priceId,
                price:
                  productsList[selectedProductOption].type === 'GiftCard'
                    ? SendThanksSchema.sendThanksData.amount
                    : productsList[selectedProductOption].price.price,
              },
            });
          } else {
            props.history.push({
              pathname: '/organisation/redeem/review',
              state: {
                productType: productsList[selectedProductOption].type,
                sendThanksData: SendThanksSchema.sendThanksData,
                priceId: productsList[selectedProductOption].price.priceId,
                price:
                  productsList[selectedProductOption].type === 'GiftCard'
                    ? SendThanksSchema.sendThanksData.amount
                    : productsList[selectedProductOption].price.price,
              },
            });
          }
        } else {
          toast.error('Please add quantity value');
        }
      } else if (productsList[selectedProductOption].type === 'GiftCard') {
        if (selectedGiftcardValue === undefined) {
          toast.error('Choose gift card value');
          return;
        }

        SendThanksSchema.sendThanksData.priceId = productsList[selectedProductOption].price.priceId;
        SendThanksSchema.sendThanksData.amount = selectedGiftcardValue;
        SendThanksSchema.sendThanksData.amount =
          Number(SendThanksSchema.sendThanksData.amount) + Number(SendThanksSchema.sendThanksData.amount * 0.05);
        props.priceDetails(SendThanksSchema.sendThanksData);

        let priceDetails = {
          productType: productsList[selectedProductOption].type,
          priceId: productsList[selectedProductOption].price.priceId,
        };

        sessionStorage.setItem('price', JSON.stringify(productsList[selectedProductOption].price.price));
        sessionStorage.setItem('productDetails', JSON.stringify(priceDetails));
        sessionStorage.setItem('giftCardValue', JSON.stringify(SendThanksSchema.sendThanksData.amount));

        if (nextPathValue !== '' && nextPathValue !== undefined) {
          props.history.push({
            pathname: nextPathValue,
            state: {
              productType: productsList[selectedProductOption].type,
              sendThanksData: SendThanksSchema.sendThanksData,
              priceId: productsList[selectedProductOption].price.priceId,
              price:
                productsList[selectedProductOption].type === 'GiftCard'
                  ? SendThanksSchema.sendThanksData.amount
                  : productsList[selectedProductOption].price.price,
            },
          });
        } else {
          props.history.push({
            pathname: '/organisation/redeem/review',
            state: {
              productType: productsList[selectedProductOption].type,
              sendThanksData: SendThanksSchema.sendThanksData,
              priceId: productsList[selectedProductOption].price.priceId,
              price:
                productsList[selectedProductOption].type === 'GiftCard'
                  ? SendThanksSchema.sendThanksData.amount
                  : productsList[selectedProductOption].price.price,
            },
          });
        }
      } else {
        toast.error('Choose a product');
      }
    } else {
      toast.error('Choose a product');
    }
  };

  const loadProducts = async () => {
    SendThanksService.getListOfProducts('AU', 'Unknown', true)
      .then(res => {
        let data = res['data'];
        let purchaseData = [];

        data?.forEach(value => {
          if (value.features === 'Purchase') {
            purchaseData.push(value);
          }
        });

        setProductsList(purchaseData);
        if (data.length > 0) {
          let wineListData = data.filter(res => {
            return res.type === 'Wine';
          });
          setWineList(wineListData[0]);

          let beerListData = data.filter(res => {
            return res.type === 'Beer';
          });
          setBeerList(beerListData[0]);
        }
      })
      .catch(error => {
        console.log('error ', error);
      });
  };

  const checkIfUserSelectedExistingProduct = () => {
    selectedProduct = props.getProductInformation;

    if (selectedProduct?.quantity > 0 && selectedProduct?.amount > 0 && selectedProduct?.recipients?.length > 0) {
      let existingGiftCardValue = selectedProduct?.amount / 1.05;
      existingGiftCardValue = existingGiftCardValue / selectedProduct?.recipients?.length;
      setSelectedGiftcardValue(existingGiftCardValue);
      giftCardValues?.forEach((value, index) => {
        if (value.amount === existingGiftCardValue) {
          setselectedGiftCardIndex(index);
          setCheckCustomOptionIsClicked(false);
        } else if (existingGiftCardValue > 110 && value.amount !== existingGiftCardValue && value.amount === 'Custom') {
          setSelectedGiftcardValue(existingGiftCardValue);
          setCheckCustomOptionIsClicked(true);
        }
      });
    }

    if (selectedProduct) {
      setProductQuantityValue(selectedProduct.quantity);
    }

    if (productQuantityValue > 0) {
      setCheckQuantityIsGreaterThanZero(true);
    }
    productsList.forEach((product, index) => {
      if (product?.price?.priceId === selectedProduct?.priceId) {
        selectedItem(index);
      }
    });
  };

  const cancelBtnHandler = () => {
    props.resetData();
    props.history.push({
      pathname: '/employee/dashboard',
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (props.history.location) {
      setNextPathValue(props?.history?.location?.state?.nextPath);
    }
    loadProducts();
    return {};
  }, []);
  useEffect(() => {
    checkIfUserSelectedExistingProduct();
  }, [productsList]);

  const selectedGiftCardItem = index => {
    if (giftCardValues[index].amount !== 'Custom') {
      setselectedGiftCardIndex(index);
      setCheckCustomOptionIsClicked(false);
      giftCardValues[index].checked = true;
      setCheckQuantityIsGreaterThanZero(true);
      SendThanksSchema.sendThanksData.amount = giftCardValues[index].amount;
      setSelectedGiftcardValue(giftCardValues[index].amount);
    } else {
      setCheckCustomOptionIsClicked(true);
      setCheckQuantityIsGreaterThanZero(false);
    }
  };

  const giftCardCustomhandleOnChange = event => {
    toast.dismiss();
    setSelectedGiftcardValue();

    if (event.target.value !== '' && event.target.value > 0 && event.target.value <= 100) {
      setCheckGiftCardQuantityValue(false);
      setSelectedGiftcardValue(event.target.value);
      if (event.target.value % 10 === 0) {
        setCheckQuantityIsGreaterThanZero(true);
        setCheckQuantityIsMultiplesOfTen(false);
        SendThanksSchema.sendThanksData.amount = event.target.value;
      } else {
        setCheckQuantityIsMultiplesOfTen(true);
        setCheckQuantityIsGreaterThanZero(false);
        setCheckGiftCardQuantityValue(true);
      }
    } else {
      setCheckQuantityIsGreaterThanZero(false);
      toast.error('Please enter values between 1 to 100');
      setCheckGiftCardQuantityValue(true);
      setCheckQuantityIsMultiplesOfTen(true);
    }
  };

  return (
    <div className=" m-0 p-0 row ">
      <div className="p-0 m-0 card border-0 col-lg-2 col-sm-12 my-4 d-flex h-100 flex-column ">
        <OrganisationSidebar menu="Credits" />
      </div>
      <div className="p-0 m-0 col-sm-12 col-md-12 col-lg-10 col-xl-10 mb-4 d-flex flex-column right_half">
        <OrganisationHeader role="employee" />

        <span className="px-3 ml-2 my-3 font-sofia-pro-light color-charcoal-grey font-24">Select the item </span>
        <div className="p-2 m-2 mx-4 card border-0 border-radius-5">
          <div className="card-body">
            <div className="p-0 m-0 row">
              {productsList.length !== 0 &&
                productsList.map((product, index) => {
                  return (
                    <div
                      className="p-0 m-0 my-2 px-2 col-sm-12 col-md-6 col-lg-3  d-flex flex-column position-relative cursor-pointer"
                      key={index}
                      onClick={selectedItem.bind(this, index)}
                    >
                      {index === selectedProductOption ? (
                        <img src={ItemCheckedImage} height="25px" width="25px" className="select-item__checked_icon" />
                      ) : null}
                      <img
                        className={
                          index === selectedProductOption
                            ? 'border-primary-color-2 border-radius-11'
                            : 'border-white border-radius-17'
                        }
                        src={product.imageUrl + '?h=200'}
                        height="200px"
                        width="200px"
                      />

                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <p className="p-0 m-0 mt-2 color-purple-brown font-16 font-sofia-pro-regular">{product.type}</p>
                        {index === selectedProductOption && product.type === 'Wine' ? (
                          <img
                            className="mt-2 select-item-wine__info__icon"
                            src={IconsInfoGrey}
                            height="20px"
                            width="20px"
                            onClick={showModal}
                          />
                        ) : null}
                        {index === selectedProductOption && product.type === 'Beer' ? (
                          <img
                            className="mt-2 select-item-wine__info__icon"
                            src={IconsInfoGrey}
                            height="20px"
                            width="20px"
                            onClick={showBeerModal}
                          />
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              {productsList.length === 0 && (
                <p className="p-0 m-0 w-100 font-24 text-center color-black-pearl font-sofia-pro-regular">
                  No products found.
                </p>
              )}
            </div>
          </div>
        </div>

        {checkGiftCardIsClicked === false && (
          <div className="p-0 m-0 mx-3 my-4">
            <span className="ml-2 font-sofia-pro-light color-charcoal-grey font-24">Enter quantity </span>
            <div className="p-2 m-2 mt-3 card border-0 border-radius-5">
              <div className="card-body">
                <div className="p-0 m-0 row">
                  <div className="p-0 m-0 px-2 col-sm-12 col-md-8 col-lg-8">
                    <div className="form-group">
                      <label className="font-16 color-charcoal font-sofia-pro-regular">Quantity</label>
                      <input
                        type="number"
                        onKeyPress={onKeyPress.bind(this)}
                        onChange={handleOnChange.bind(this)}
                        className="form-control font-sofia-pro-light font-14 h-60 select-item__quantity_placeholder shadow-none"
                        placeholder="Enter the quantity of thanks"
                        value={productQuantityValue}
                        id="quantity"
                        pattern="\d{3}"
                        required
                        onWheel={e => e.target.blur()}
                      />
                    </div>
                    <div className="p-0 m-0 text-right">
                      <button
                        className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium"
                        onClick={cancelBtnHandler}
                      >
                        Cancel
                      </button>

                      {productQuantityValue > 100 && (
                        <button
                          type="submit"
                          className="btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16 shadow-none"
                          disabled
                        >
                          Proceed
                        </button>
                      )}
                      <button
                        type="submit"
                        className="btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16 shadow-none"
                        disabled={productQuantityValue <= 0 ? true : false}
                        onClick={handleProceed.bind(this)}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {checkGiftCardIsClicked === true && (
          <div className="p-0 m-0 mx-3 my-4">
            <span className="ml-2 font-sofia-pro-light color-charcoal-grey font-24">Gift card amount</span>
            <div className="p-2 m-2 mt-3 card border-0 border-radius-5">
              <div className="card-body">
                <div className="p-0 m-0 row mt-3">
                  <div className="p-0 m-0 px-2 col-sm-12 col-md-8 col-lg-7">
                    {checkCustomOptionIsClicked === false && (
                      <div className="p-0 m-0 row">
                        {giftCardValues.map((value, index) => {
                          return (
                            <div className="col-sm-12 col-md-6 col-lg-3" key={index}>
                              <span
                                className={
                                  index === selectedGiftCardIndex
                                    ? 'p-3 bg-white-smoke font-sofia-pro-semi-bold color-primary border-primary-color cursor-pointer'
                                    : 'p-3 bg-white-smoke font-sofia-pro-regular color-approx-charcoal cursor-pointer'
                                }
                                onClick={selectedGiftCardItem.bind(this, index)}
                              >
                                {value.amount !== 'Custom' ? '$ ' + value.amount : value.amount}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {checkCustomOptionIsClicked === true && (
                      <div className="form-group mt-2">
                        <input
                          type="number"
                          onKeyPress={onKeyPress.bind(this)}
                          onChange={giftCardCustomhandleOnChange.bind(this)}
                          className="form-control font-sofia-pro-light font-14 h-60 select-item__quantity_placeholder shadow-none"
                          placeholder="Enter custom amount (min $10 | max $100)"
                          value={selectedGiftcardValue}
                          onWheel={e => e.target.blur()}
                          min="10"
                          max="100"
                          step="1"
                        />
                        <div className="d-flex align-items-center">
                          <img src={IconsInfoGrey} height="15px" width="15px" />
                          <p className="p-0 m-0 font-sofia-pro-regular font-12 color-grey-blue">
                            Enter amount between 10$ -100$ (accepts multiples of 10).
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="p-0 m-0 mt-5 d-flex justify-content-end">
                      {checkCustomOptionIsClicked === false && (
                        <button
                          className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium"
                          disabled={!checkQuantityIsGreaterThanZero ? true : false}
                          onClick={cancelBtnHandler}
                        >
                          Cancel
                        </button>
                      )}
                      {checkCustomOptionIsClicked === true && (
                        <button
                          className="p-0 m-0 mr-4 border-0 bg-transparent font-16 color-gunmetal font-sofia-pro-medium"
                          onClick={() => {
                            setCheckCustomOptionIsClicked(false);
                          }}
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        type="submit"
                        className="w-25 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16 shadow-none"
                        disabled={
                          !checkQuantityIsGreaterThanZero && checkQuantityIsMultiplesOfTen === true ? true : false
                        }
                        onClick={handleProceed.bind(this)}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        className="border-0 modal-background-shadow "
        size="md"
        show={showWineListModal}
        onHide={hideModal}
        animation={false}
      >
        <button type="button" className="close select-item-modal__close__icon" onClick={hideModal}>
          <Close />
        </button>
        {wineList !== undefined && (
          <Modal.Body className="p-0 m-0">
            <img
              className="img-fluid select-item-modal__banner__image"
              src={wineList.imageUrl + '?h=250'}
              height="250px"
              width="498px"
            />
            <p
              className="p-0 m-0 px-4 font-18 font-sofia-pro-regular color-nero"
              dangerouslySetInnerHTML={{ __html: wineList.description }}
            />
            <div className="p-0 m-0 mt-2 px-4">
              <span className="mt-3 font-24 font-sofia-pro-bold color-dark-charcoal">Available wines</span>
              <div className="p-0 m-0 mt-3 row">
                {productsList.length !== 0 &&
                  wineList?.ranges !== undefined &&
                  wineList?.ranges.map((data, index) => {
                    return (
                      <div className="p-0 m-0 mt-1 col-sm-12 col-md-6 col-lg-4" key={index}>
                        <img
                          src={data.imagePath + '?h=130&&w=130'}
                          style={{
                            backgroundColor: data?.color !== null ? data?.color : '#ffe1f4',
                          }}
                          className="p-2"
                          height="130px"
                        />
                        <div className="pr-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex flex-column">
                            <span className="p-0 m-0 font-14 font-sofia-pro-bold color-nero">{data.name}</span>
                            <span className="font-12 font-sofia-pro-regular color-nobel">Australian</span>
                          </div>
                          <span className="font-18 color-nero font-sofia-pro-light">${wineList?.price?.price}</span>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="my-4 p-3 bg-white-smoke border-radius-5">
                <p className="p-0 m-0 mb-2 font-14 color-charcoal-grey font-sofia-pro-light">
                  <img className="select-item__error__info" src={IconsErrorInfo} height="15px" width="15px" />
                  Your receiver can select one of these {wineList.length} wines, you’ll also receive a confirmation once
                  your thanks is redeemed.
                </p>
                <span className="p-0 m-0 font-14 color-charcoal-grey font-sofia-pro-semi-bold">
                  AVAILABLE IN METRO AREAS ONLY
                </span>
              </div>
              <div className="w-100 py-3 text-center">
                <button
                  type="submit"
                  className="w-50 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                  onClick={hideModal}
                >
                  Ok, got it
                </button>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>

      <Modal
        className="border-0 modal-background-shadow "
        size="md"
        show={showBeerListModal}
        onHide={hideBeerModal}
        animation={false}
      >
        <button type="button" className="close select-item-modal__close__icon" onClick={hideBeerModal}>
          <Close />
        </button>
        {beerList !== undefined && (
          <Modal.Body className="p-0 m-0">
            <img
              className="img-fluid select-item-modal__banner__image"
              src={beerList.imageUrl + '?h=250'}
              height="250px"
              width="498px"
            />
            <p
              className="p-0 m-0 px-4 font-18 font-sofia-pro-regular color-nero"
              dangerouslySetInnerHTML={{ __html: beerList.description }}
            />
            <div className="p-0 m-0 mt-2 px-4">
              <span className="mt-3 font-24 font-sofia-pro-bold color-dark-charcoal">Available beers</span>
              <div className="p-0 m-0 mt-3 row">
                {productsList.length !== 0 &&
                  beerList?.ranges !== undefined &&
                  beerList?.ranges.map((data, index) => {
                    return (
                      <div className="p-0 m-0 mt-1 col-sm-12 col-md-6 col-lg-4" key={index}>
                        <img
                          src={data.imagePath + '?h=130&&w=130'}
                          style={{
                            backgroundColor: data?.color !== null ? data?.color : '#ffe1f4',
                          }}
                          className="p-2"
                        />
                        <div className="pr-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex flex-column">
                            <span className="p-0 m-0 font-14 font-sofia-pro-bold color-nero">{data.name}</span>
                            <span className="font-12 font-sofia-pro-regular color-nobel">Australian</span>
                          </div>
                          <span className="font-18 color-nero font-sofia-pro-light">${beerList?.price?.price}</span>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="my-4 p-3 bg-white-smoke border-radius-5">
                <p className="p-0 m-0 mb-2 font-14 color-charcoal-grey font-sofia-pro-light">
                  <img className="select-item__error__info" src={IconsErrorInfo} height="15px" width="15px" />
                  Your receiver can select one of these {beerList.length} beers, you’ll also receive a confirmation once
                  your thanks is redeemed.
                </p>
                <span className="p-0 m-0 font-14 color-charcoal-grey font-sofia-pro-semi-bold">
                  AVAILABLE IN METRO AREAS ONLY
                </span>
              </div>
              <div className="w-100 py-3 text-center">
                <button
                  type="submit"
                  className="w-50 btn bg-primary-color h-60 color-white font-sofia-pro-semi-bold font-16"
                  onClick={hideBeerModal}
                >
                  Ok, got it
                </button>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
};

// MAPPING - SEND THANKS DATA

const mapStateToProps = state => {
  return {
    getProductInformation: state.organisationSendThanks.orgSendThanksData,
  };
};

// DISPATCH  - SEND THANKS DATA

const mapDispatchToProps = dispatch => {
  return {
    priceDetails: data => dispatch(orgSendThanksDetails(data)),
    resetData: () => dispatch(orgResetSendThanksDetails(undefined)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationRedeemCreditSelectItemPage);
