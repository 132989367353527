/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/

import axios from 'axios';
import { toast } from 'react-toastify';

// import { getLocalStorageData } from './getLocalStorage';

// // let userData

// async function init() {
//  const  {userData} = await getLocalStorageData();
//  console.log("LOCAL Storage===>",userData)
//   // Use the data here to set up the API headers
// }
// init();

toast.configure({
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common['ApiKey'] = process.env.REACT_APP_API_KEY;

let data = JSON.parse(localStorage.getItem('userData'));
if (data) {
  axios.defaults.headers.common['userKey'] = data.userKey;
} else {
  axios.defaults.headers.common['userKey'] = '';
}

// axios.interceptors.request.use(config => {
//     // config.headers['ApiKey'] = 'aebf3068-17a5-46fc-83eb-4a660c488ce8';
//     return config;
// }, error => {
//     console.log(error)
//     return error;
// });

axios.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error.response.statue >= 500;
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
  }
  // if (error.response) {
  //     switch (error.response.status) {
  //         case 400:
  //             toast.error("The server cannot or will not process the request.")
  //             break;
  //         case 401:
  //             toast.error('The request has not been applied due to lacks valid authentication credentials.');
  //             break;
  //         case 403:
  //             toast.error('The server understood the request but refuses to authorize it.');
  //             break;
  //         case 404:
  //             toast.error('The server can\'t find the requested resource.');
  //             break;
  //         case 417:
  //             toast.error("The expectation given in the request's Expect header could not be met.")
  //             break;
  //         case 422:
  //             toast.error("The expectation given in the request's Expect header could not be met.")
  //             break;
  //         case 500:
  //             toast.error("An unexpected server error occured.")
  //             break;
  //         default:
  //             break;
  //     }
  // }

  return Promise.reject(error);
});

export default {
  axios,
};
