/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
import httpService from './HttpService';

// Validate Mobile Number
const ValidateAUMobileNumber = data => {
  return httpService.post('/Validations/phone', data);
};

const validateImage = data => {
  var idxDot = data.lastIndexOf('.') + 1;
  var extFile = data.substr(idxDot, data.length).toLowerCase();
  if (extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png') {
    return true;
  } else {
    return false;
  }
};

const validateFile = data => {
  var idxDot = data.lastIndexOf('.') + 1;
  var extFile = data.substr(idxDot, data.length).toLowerCase();
  if (
    extFile == 'jpg' ||
    extFile == 'jpeg' ||
    extFile == 'png' ||
    extFile == 'pdf' ||
    extFile == 'docx' ||
    extFile == 'doc'
  ) {
    return true;
  } else {
    return false;
  }
};

// Get Media File
const getMediaFile = (fileSecret, guid) => {
  return httpService.get(`/StoredFiles/${guid}`, {
    headers: {
      fileSecret: `${fileSecret}`,
    },
  });
};

export default {
  ValidateAUMobileNumber,
  validateImage,
  validateFile,
  getMediaFile,
};
