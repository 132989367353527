/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
// Common reducer for sendthanks (Organisation admin, Organisation employee)
import { persistReducer, PURGE, REHYDRATE, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as sendThanksDataSchema from '../../../utils/Schema/sendThanksSchema';

const ORG_SEND_THANKS_DETAILS = 'ORG_SEND_THANKS_DETAILS';
const PRODUCTS_LIST = 'PRODUCTS_LIST';

const RESET_ORG_SEND_THANKS_DETAILS = 'RESET_ORG_SEND_THANKS_DETAILS';

export const orgSendThanksDetails = data => ({
  type: ORG_SEND_THANKS_DETAILS,
  payload: {
    data,
  },
});

export const orgSetProductsList = data => ({
  type: PRODUCTS_LIST,
  payload: {
    data,
  },
});

export const orgResetSendThanksDetails = data => ({
  type: RESET_ORG_SEND_THANKS_DETAILS,
  payload: {
    data,
  },
});

const orgEmptySendThanksSchema = {
  priceId: '',
  quantity: 1,
  amount: 0,
  paymentSource: {
    token: '',
    cardid: '',
    setCardAsDefault: false,
  },
  recipients: [],
  data: {
    guid: '',
    externalId: '',
    channel: '',
    redeemUrl: '',
    delivery: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      address: {
        street: '',
        suburb: '',
        state: '',
        postcode: '',
        country: '',
      },
    },
  },
};

let initialState = {
  orgSendThanksData: orgEmptySendThanksSchema,
};

let orgSendThanksData;

export const organisationSendThanksReducer = persistReducer(
  {
    storage,
    key: 'appreci-org-send-thanks',
    whitelist: ['orgSendThanksData'],
    // blacklist: ['sendThanksData']
  },
  (state = initialState, action) => {
    // console.log('state ', state, action);
    switch (action.type) {
      case 'ORG_SEND_THANKS_DETAILS':
        const sendThanksPriceDetails = action.payload.data;
        orgSendThanksData = {
          ...state.orgSendThanksData,
        };

        orgSendThanksData.recipients = sendThanksPriceDetails.recipients;
        orgSendThanksData.priceId = sendThanksPriceDetails.priceId;
        orgSendThanksData.amount = sendThanksPriceDetails.amount;
        orgSendThanksData.quantity = sendThanksPriceDetails.quantity;
        orgSendThanksData.message = sendThanksPriceDetails.message;

        // console.log('send thanks details ', orgSendThanksData);

        if (
          orgSendThanksData?.paymentSource !== undefined &&
          sendThanksPriceDetails?.sendThanksData?.paymentSource !== undefined
        ) {
          orgSendThanksData.paymentSource.cardid = sendThanksPriceDetails.sendThanksData.paymentSource.cardid;
        }
        return {
          orgSendThanksData,
        };
      case 'PRODUCTS_LIST':
        const products = action.payload.data;
        return {
          products,
        };
      case 'RESET_ORG_SEND_THANKS_DETAILS':
        sendThanksDataSchema.sendThanksData.recipients = [];
        sendThanksDataSchema.sendThanksData.priceId = '';
        sendThanksDataSchema.sendThanksData.amount = '';
        sendThanksDataSchema.sendThanksData.quantity = 1;

        if (sendThanksDataSchema.sendThanksData?.paymentSource !== undefined) {
          sendThanksDataSchema.sendThanksData.paymentSource.cardid = '';
        }

        storage.removeItem('persist:appreci-org-send-thanks');
        state = undefined;

        return { state };
      default:
        return state;
    }
  }
);
