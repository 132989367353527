import React, { useState, useEffect } from 'react';
import Button from '../../../common/Button/Button';
import { ReactComponent as EditIcon } from '../../../assets/images/common/edit-user.svg';
import { ReactComponent as Tick } from '../../../assets/images/common/tick-box.svg';
import { ReactComponent as TickEmpty } from '../../../assets/images/common/tick-empty.svg';
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from '../../../assets/images/common/close-modal.svg';
import { Link } from 'react-router-dom';
import OrganisationService from '../../../services/OrganisationService';
import { toast } from 'react-toastify';
import BudgetRange from './BudgetRange';
import Toast from '../../../services/ToasterService';
import QuantityBox from './QuantityBox.js';
import sendThanksSchema, * as SendThanksSchema from '../../../utils/Schema/sendThanksSchema';
import paymentService from '../../../services/PaymentService';



const Summary = ({formik, showLoader, handleBack, tileWidth, setSelectedCategory, setShowSummary, setShowMessageScreen, setAddRecipientsShow, setIncludeMsg, setSelectedOption, setShowPreferences, onSubmit}) => {

  const [subTotal, setSubTotal] = useState(formik.values.total.substring(1) * formik.values.recipients.length);
  const [itemImagePath, setItemImagePath] = useState(`${formik.values.item.imagePath}?h=${tileWidth}`);
  const [brandImagePath, setBrandImagePath] = useState(`${formik.values.brand.imageUrl}?h=${tileWidth}`);

  const [tickClick, setTickClick] = useState(false);
  const [topup, setTopUp] = useState(false);

  const [availableCreditBalance, setAvailableCreditBalance] = useState(localStorage.getItem('avaialbleBalance'));
  const [autoTopUpSelected, setAutoTopUpSelected] = useState(null);
  const [userData, setUserData] =useState({});

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=> {
    localStorage.setItem("productImg", Object.keys(formik.values.item).length !== 0 ? formik.values.item.imagePath: formik.values.brand.imageUrl);
    localStorage.setItem("productName", Object.keys(formik.values.item).length !== 0 ? formik.values.item.name: formik.values.brand.name);
    OrganisationService.getOrganisationDetails()
      .then(response => {
        if (response) {
          let data = response['data'];
          setUserData(data);
          formik.setFieldValue("amount", data?.monthlyBudget || '');
        }
      })
      .catch(error => {
        let errorResponseGlobalErrors = error.response['data'].globalErrors;
        if (errorResponseGlobalErrors) {
          if (errorResponseGlobalErrors.length > 0) {
            errorResponseGlobalErrors.forEach(msg => {
              Toast(msg.message, 'error');
            });
          }
        }
      });
  }, [])

  // const loadExistingCardsList = () => {
  //   let googlePayCard = sessionStorage.getItem('PaymentType') ? sessionStorage.getItem('PaymentType') : 'Card';
  //   if (googlePayCard === 'GooglePay') {
  //     setGooglepaySelect(true);
  //   } else if (googlePayCard === 'Card') {
  //     setGooglepaySelect(false);
  //   }

  //   paymentService
  //     .getListOfCards()
  //     .then(response => {
  //       if (response) {
  //         let data = response['data'];
  //         sessionStorage.setItem('PaymentType', 'Card');
  //         setPaymentType('Card');
  //         setSavedCardsList(data);
  //         let defaultCardData = data.filter(data => {
  //           return data.isDefault === true;
  //         });
  //         for (let i = 0; i < data.length; i++) {
  //           if (data[i].isDefault) {
  //             setSelectedCardsDetails({
  //               ...selectedCardDetails,
  //               cardLastNumber: data[i].last4,
  //               choosedCardId: data[i].id,
  //               cardExpiryDate: data[i].expiresOn,
  //               productDetails: SendThanksSchema.sendThanksData,
  //               productType: details?.type,
  //               priceId: priceId,
  //               amount: subTotal,
  //               brand: data[i].brand,
  //             });
  //           }
  //         }
  //         if (
  //           (defaultCardData.length > 0 && sendThanksSchema?.sendThanksData?.paymentSource?.cardid === '') ||
  //           sendThanksSchema?.sendThanksData?.paymentSource?.cardid === undefined
  //         ) {
  //           if (googlePayCard === 'Card') {
  //             setChoosenCardId(defaultCardData[0].id);
  //           }
  //         }
  //         setDefaultCardList(defaultCardData);
  //       }
  //     })
  //     .catch(error => {
  //       if (error) {
  //         let errorResponse = error.response['data'];
  //         let errorMessage = errorResponse.globalErrors[0].message;
  //         Toast(errorMessage, 'error');
  //       }
  //     });
  // };

  const goToGift = () => {
    setSelectedCategory("allProducts");
    setShowSummary(false);
    setShowMessageScreen(false);
  }

  const goToRecipients = () => {
    setAddRecipientsShow(true);
    setShowSummary(false);
    setShowMessageScreen(false);
  }

  const goToMsg = () => {
    setSelectedOption('');
    setShowPreferences(false);
    setIncludeMsg(true);
  }

  const handleCheckbox = () => {
    setTickClick(!tickClick);
  }

  const handleTopUp = () => {
    setTopUp(true);
  }

  const handleClose = () => {
    setTopUp(false);
  }

  // entered amount validated from 0 to 100000
  const onKeyPress = event => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) {
      event.preventDefault();
    }

    var max_chars = 5;

    if (event.target.value.length > max_chars) {
      event.target.value = event.target.value.substr(0, max_chars);
    }
  };

  return (
    <div>
      {windowWidth <= 767 ? (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh',
              padding: '0 20px',
              width: '408px',
            }}
          >
            <div>
              <div>
                <div className="d-flex flex-column position-relative" style={{ marginTop: '50px', marginRight: '10%'}}>
                  <span className="font-sofia-pro-regular font-18 color-black-pearl text-center">
                    Your milestone gifting summary
                  </span>
                  <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
                    Does everything look good?
                  </p>
                </div>
                <div className="d-flex justify-content-center flex-wrap">
                  <div className="mt-2 d-flex flex-column mr-3"></div>
                  <div className="d-flex flex-column summary-box-style ml-3">
                    <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Summary</label>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Gift</span>
                        <span className="ml-2 font-sofia-pro-regular font-14 color-primary"  onClick={goToGift}>Edit</span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{formik.values.brand?.type}</span>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <span className="font-sofia-pro-regular color-gunmetal font-14">Cost per gift</span>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{formik.values.total}</span>
                    </div>

                    <hr className="w-100" style={{ color: '#F1F4F7' }} />

                    <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Recipients</span>
                        <span className="ml-2 font-sofia-pro-regular font-14 color-primary" onClick={goToRecipients}>Edit</span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{`x${formik.values.recipients.length}`}</span>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Gifting preferences</span>
                        <span
                          className="ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer"
                          onClick={handleBack}
                        >
                          Edit
                        </span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{`${formik.values.giftType}, ${formik.values.giftFrequency}`}</span>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <div className="d-flex justify-content-start">
                        <span className="font-sofia-pro-regular color-gunmetal font-14">Message</span>
                        <span className="ml-2 font-sofia-pro-regular font-14 color-primary" onClick={goToMsg}>Edit</span>
                      </div>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">
                        {formik.values.message.message
                        ? formik.values.message.message.length > 25
                          ? `${formik.values.message.message.slice(0, 25)}...`
                          : formik.values.message.message
                        : formik.values.message.type === "video"
                        ? formik.values.message.type
                        : "No Message"}
                      </span>
                    </div>
                    <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Purchase summary</label>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <span className="font-sofia-pro-regular color-gunmetal font-14">Subtotal</span>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">{`$${subTotal}`}</span>
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <span className="font-sofia-pro-regular color-gunmetal font-14">Available thanks balance</span>
                      <span className="font-sofia-pro-regular color-gunmetal font-14">${availableCreditBalance}</span>
                    </div>
                    <div className="mt-4 mb-0 d-flex align-items-center justify-content-between summary-label-box-style">
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Total (AUD)</label>
                      <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">{`$${subTotal}`}</label>
                    </div>
                  </div>
                  <div
                    className="mt-3 d-flex flex-column summary-box-style justify-content-start"
                    style={{ marginLeft: '18px' }}
                  >
                    <label className="font-sofia-pro-regular color-charcoal font-16 mb-0">Paying by</label>
                    <div className="mt-2 px-3 d-flex align-items-center justify-content-between pay-field-box-style">
                      <span className="font-sofia-pro-regular color-charcoal font-14">Credit card ending with 360</span>
                      <EditIcon className="cursor-pointer" />
                    </div>
                    {availableCreditBalance < subTotal ?
                  <>
                  <div className='mt-2 d-flex align-items-center'>
                    <TickEmpty className='cursor-pointer' onClick={handleCheckbox}/>
                    <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>You do not have enough credit. </span>
                    <Link to={"/organisation/topup"} style={{textDecoration: 'none'}}>
                      <span className='ml-2 font-sofia-pro-regular color-primary font-14 cursor-pointer' 
                      onClick={handleTopUp}
                      >
                        Top up</span>
                    </Link>
                  </div>
                  <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
                  </>
                    : <>
                    <div className='mt-2 d-flex align-items-center'>
                    <Tick className='cursor-pointer' onClick={handleCheckbox}/>
                    <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>Use Available credit</span>
                  </div>
                  <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
                  </>}
                  </div>
                </div>
                <div className="mt-4 mb-0 d-flex justify-content-center w-100 ml-1">
                {/* <Link to="/organisation/milestones" className="w-100" style={{ textDecoration: 'none' }}> */}
                    <Button
                      classNameValue="summary-coffee shadow-none d-flex justify-content-center align-items-center h-60 btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
                      children="Next"
                      showLoader={showLoader}
                      // onClick={formik.submitForm}
                      onClick={() => {
                        onSubmit(formik.values);
                      }}
                      disabled={availableCreditBalance < subTotal}
                      style={{width: '86%'}}
                    />
                  {/* </Link> */}
                </div>
                <div
                  className="mt-2 mb-5 d-flex justify-content-center w-100"
                  // style={{ height: '40px', marginLeft: '25px' }}
                >
                  <span className="font-sofia-pro-regular color-charcoal font-12 terms">
                    By setting up a milestone, you agree to the appreci{' '}
                    <a
                      href="https://appreci.io/terms/"
                      target="blank"
                      className="color-primary font-12 font-sofia-pro-medium"
                    >
                      terms and conditions{' '}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {formik.values.quantity ? <QuantityBox quantity={formik.values.quantity} total={formik.values.total} balance={localStorage.getItem('avaialbleBalance')}/>: ''}
          <div className="d-flex flex-column position-relative" style={{ marginTop: '80px' }}>
            <span className="font-sofia-pro-regular font-32 color-black-pearl text-center">
              Your milestone gifting summary
            </span>
            <p className="mt-2 font-sofia-pro-regular font-16 color-gunmetal invite_paragraph">
              Does everything look good?
            </p>
          </div>
          <div className="d-flex justify-content-center flex-wrap">
            <div className="mt-2 d-flex flex-column mr-3">
              <div className="d-flex summary-box-style justify-content-center">
                <div className="d-flex align-items-center justify-content-center summary-label-box-style1">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="img-item">
                      <img className={`w-100 h-100 category-img`} src={Object.keys(formik.values.item).length !== 0 ? itemImagePath: brandImagePath} style={{opacity: '0.5'}}/>
                    </div>
                    <span className="font-sofia-pro-regular color-gunmetal font-14">
                      Your {formik.values.item.name ? formik.values.item.name?.split(",")[0]: formik.values.brand.name?.split(",")[0]} is on the way.
                    </span>
                    <Link to="/previewgift" target='_blank' style={{textDecoration: 'none'}}>
                      <Button
                        classNameValue={`w-100 mt-3 color-primary preview-btn shadow-none d-flex justify-content-center align-items-center h-56 btn font-14 font-sofia-pro-light cursor-pointer`}
                        children="Preview gift experience"
                        showLoader={showLoader}

                        // onClick={()=> {handleCategoryClicked("allProducts")}}
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex flex-column summary-box-style justify-content-start">
                <label className="font-sofia-pro-regular color-charcoal font-16 mb-0">Paying by</label>
                {/* <div className="mt-2 px-3 d-flex align-items-center justify-content-between pay-field-box-style">
                  <span className="font-sofia-pro-regular color-charcoal font-14">Credit card ending with 360</span>
                  <EditIcon className="cursor-pointer" />
                </div> */}
                {availableCreditBalance < subTotal ?
          <>
          <div className='mt-2 d-flex align-items-center'>
            <TickEmpty className='cursor-pointer' onClick={handleCheckbox}/>
            <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>You do not have enough credit. </span>
            <Link to={"/organisation/topup"} style={{textDecoration: 'none'}}>
              <span className='ml-2 font-sofia-pro-regular color-primary font-14 cursor-pointer' 
              onClick={handleTopUp}
              >
                Top up</span>
            </Link>
          </div>
          <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
          </>
            : <>
            <div className='mt-2 d-flex align-items-center'>
            <Tick className='cursor-pointer' onClick={handleCheckbox}/>
            <span className='ml-2 font-sofia-pro-regular color-charcoal font-14'>Use Available credit</span>
          </div>
          <span className='ml-4 font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
          </>}
              </div>
            </div>
            <div className="d-flex flex-column summary-box-style ml-3">
              <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Summary</label>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Gift</span>
                  <span className='ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={goToGift}>Edit</span>
                  </div>
                  <span className="font-sofia-pro-regular color-gunmetal font-14">{formik.values.brand?.type}</span>
              </div>
              <div className="d-flex justify-content-between p-2">
                <span className="font-sofia-pro-regular color-gunmetal font-14">Cost per gift</span>
                <span className="font-sofia-pro-regular color-gunmetal font-14">{formik.values.total}</span>
              </div>
              {/* <div className='w-100'> */}

              <hr className="w-100" style={{ color: '#F1F4F7' }} />
              {/* </div> */}
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Recipients</span>
                  <span className='ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={goToRecipients}>Edit</span>
                </div>
                <span className="font-sofia-pro-regular color-gunmetal font-14">{`x${formik.values.recipients.length}`}</span>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Gifting preferences</span>
                  <span
                    className="ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer"
                    onClick={handleBack}
                  >
                    Edit
                  </span>
                </div>
                <span className="font-sofia-pro-regular color-gunmetal font-14">{`${formik.values.giftType}, ${formik.values.giftFrequency}`}</span>
              </div>
              <div className="d-flex justify-content-between p-2">
                <div className="d-flex justify-content-start">
                  <span className="font-sofia-pro-regular color-gunmetal font-14">Message</span>
                  <span className='ml-2 font-sofia-pro-regular font-14 color-primary cursor-pointer' onClick={goToMsg}>Edit</span>
                </div>
                <span className="font-sofia-pro-regular color-gunmetal font-14">
                  {formik.values.message.message
                  ? formik.values.message.message.length > 25
                    ? `${formik.values.message.message.slice(0, 25)}...`
                    : formik.values.message.message
                  : formik.values.message.type === "video"
                  ? formik.values.message.type
                  : "No Message"}
                </span>
              </div>
              <div className="mt-3 mb-2 d-flex align-items-center justify-content-start summary-label-box-style">
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Purchase summary</label>
              </div>
              <div className="d-flex justify-content-between p-2">
                <span className="font-sofia-pro-regular color-gunmetal font-14">Subtotal</span>
                <span className="font-sofia-pro-regular color-gunmetal font-14">{`$${subTotal}`}</span>
              </div>
              <div className="d-flex justify-content-between p-2">
                <span className="font-sofia-pro-regular color-gunmetal font-14">Available thanks balance</span>
                <span className="font-sofia-pro-regular color-gunmetal font-14">${availableCreditBalance}</span>
              </div>
              <div className="mt-4 mb-0 d-flex align-items-center justify-content-between summary-label-box-style">
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">Total (AUD)</label>
                <label className="font-sofia-pro-regular color-charcoal font-18 mb-0">{`$${subTotal}`}</label>
              </div>
            </div>
          </div>
          <div className="mt-4 mb-0 d-flex justify-content-center w-100">
            <Button
              classNameValue="twoBrand-back-coffee shadow-none d-flex justify-content-center align-items-center h-60 btn font-16 font-sofia-pro-semi-bold"
              children="Back"
              showLoader={showLoader}
              onClick={handleBack}
            />
           {/* <Link to="/organisation/milestones" style={{textDecoration: 'none'}}> */}
            <Button
            classNameValue="summary-coffee shadow-none d-flex justify-content-center align-items-center h-60 btn bg-primary-color font-16 font-sofia-pro-semi-bold text-white"
            children="Next"
            showLoader={showLoader}
            // onClick={formik.submitForm}
            onClick={() => {onSubmit(formik.values)}}
            disabled={availableCreditBalance < subTotal}
            />
          {/* </Link> */}
          </div>
          <div className="mt-2 mb-5 d-flex justify-content-center">
            <span className="font-sofia-pro-regular color-charcoal font-12">
              By setting up a milestone, you agree to the appreci{' '}
              <a
                href="https://appreci.io/terms/"
                target="blank"
                className="color-primary font-12 font-sofia-pro-medium"
              >
                terms and conditions{' '}
              </a>
            </span>
          </div>
          <Modal
        className="pt-4 border-0 modal-background-shadow contactss-modal"
        size="xs"
        backdrop="static"
        show={topup}
        onHide={handleClose}
        animation={false}
      >
        <div className="d-flex flex-column justify-content-center mt-4" style={{ marginLeft: '42px' }}>
          <div className="position-relative">
            <button
              type="button"
              className="border-0 position-absolute top-0"
              style={{ background: 'transparent', top: '7px', right: '20px' }}
              onClick={handleClose}
            >
              <Close />
            </button>
            <div className="font-sofia-pro contact-heading">Top-up account</div>
          </div>
          <div className="modal-body">
            <div className="container">
            <div className="col-md-12 d-flex flex-column w-100 mb-3">
                  <label className="organisation-topup__Enter-amount font-sofia-pro-regular">Default payment method</label>
                  <div className="input-group my-1 d-flex align-items-center budget-section">
                    <span className="h-60 input-group-text border-light-periwinkle h-60 border-right-0">$</span>
                      <input
                        autoComplete="off"
                        onKeyPress={onKeyPress.bind(this)}
                        type="number"
                        pattern="\d{3}"
                        required
                        onWheel={e => e.target.blur()}
                        // defaultValue={parseInt(availableCreditBalance)}
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        name="amount"
                        className="form-control w-auto shadow-none font-sofia-pro-regular color-charcoal-grey border-light-periwinkle budget-input h-60"
                        placeholder="Please enter the topup amount"
                      />
                  </div>
                  <span className='font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
              </div>
              <div className="col-md-12 d-flex flex-column w-100 mb-3">
                  <label className="organisation-topup__Enter-amount font-sofia-pro-regular">Enter amount</label>
                  <div className="input-group my-1 d-flex align-items-center budget-section">
                    <span className="h-60 input-group-text border-light-periwinkle h-60 border-right-0">$</span>
                      <input
                        autoComplete="off"
                        onKeyPress={onKeyPress.bind(this)}
                        type="number"
                        pattern="\d{3}"
                        required
                        onWheel={e => e.target.blur()}
                        // defaultValue={parseInt(availableCreditBalance)}
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        name="amount"
                        className="form-control w-auto shadow-none font-sofia-pro-regular color-charcoal-grey border-light-periwinkle budget-input h-60"
                        placeholder="Please enter the topup amount"
                      />
                  </div>
                  <span className='font-sofia-pro-regular font-14' style={{color: '#B5C3CC'}}>Available balance: ${availableCreditBalance}</span>
              </div>
              <div className="topup-slide">
                  <BudgetRange
                    autoTopUp={userData?.monthlyAutoTopUp}
                    budget={userData?.monthlyBudget}
                    topUpCreate={false}
                    formik={formik}
                    setAutoTopUpSelected={setAutoTopUpSelected}
                  />
                </div>
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <button
                  className="ml-3 my-2 shadow-none d-flex justify-content-center align-items-center h-60 btn  bg-transparent font-16 font-sofia-pro color-gunmetal btn_cancel_invite"
                  style={{ border: '1px solid #B5C3CC', color: '#B5C3CC', width: '130px' }}
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <Button
                  classNameValue="ml-3 my-2 shadow-none d-flex justify-content-center align-items-center h-60 btn  bg-primary-color font-16 font-sofia-pro text-white btn_cancel_invite"
                  children="TopUp"
                  showLoader={showLoader}
                  style={{ width: '130px', marginRight: '15px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
        </div>
      )}
    </div>
  );
};
export default Summary;
