import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import IconsBackImage from '../../assets/images/common/icons-back.png';


const ButtonBack = ({from, to}) => {
  const history = useHistory();
  const currentPath = window.location.pathname

  const handleBack = () => {
    const replacerPath = currentPath.replace(from, to)
    history.push(replacerPath);
  }

  return (
    <div className={`d-flex my-3 justify-content-between`}>
      <Link onClick={handleBack}>
        <span className='back-btn pr-2 pt-2 pb-2 m-4 pl-0 d-flex justify-content-center align-items-center bg-white border-light-periwinkle border-radius-5 color-gunmetal font-14 font-sofia-pro-regular'>
          <img src={IconsBackImage} height='30px' width='30px' />
          Back
        </span>
      </Link>
    </div>
  )
};

export default ButtonBack