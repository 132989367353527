/******************************************************************************
 * Copyright (C) 2021 Lakeba Corporation Pty Ltd. All Rights Reserved.
 *
 * This file is part of the Appreci Project.
 *
 * Any code files that form part of the Appreci Project cannot be copied and/or distributed without the express written permission of Lakeba Corporation Pty Ltd.
 *
 ********************************************************************************/
//Reference website:
//https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API
//https://github.com/mdn/voice-change-o-matic-float-data/blob/gh-pages/scripts/app.js
//https://codepen.io/Sambego/pen/VmBJJN
//https://stackoverflow.com/questions/26447932/javascript-web-audio-cannot-properly-decode-audio-data

const AudioProvider = () =>
  new Promise(async resolve => {
    var options = {
      mimeType: 'audio/webm',
    };

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream, options);
    let audioChunks = [];

    mediaRecorder.addEventListener('dataavailable', event => {
      audioChunks.push(event.data);
    });

    const getStream = () => {
      return stream;
    };

    const clearChunk = () => (audioChunks = []);

    const pause = () => mediaRecorder.pause();

    const resume = () => mediaRecorder.resume();

    const start = () => {
      if (mediaRecorder.state === 'inactive') mediaRecorder.start();
    };

    const stop = () =>
      new Promise(resolve => {
        mediaRecorder.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunks, { type: 'audio/mp3' });
          const audioUrl = URL.createObjectURL(audioBlob);
          const audioObject = new Audio(audioUrl);
          const play = () => audioObject.play();
          resolve({ audioBlob, audioUrl, play, audioObject });
        });
        mediaRecorder.stop();
        stream.getTracks().forEach(track => track.stop());
      });
    resolve({ start, pause, resume, stop, clearChunk, getStream });
  });

export default AudioProvider;
